import {
  Grid,
  InputLabel,
  ListItem,
  Select,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Button, InputText, VSpacer } from '@sm-highway-web/react-components';
import lodash from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import CheckmarkIcon from '../../../assets/svg/CheckmarkIcon';
import { IUserData } from '../../../interfaces/users';
import { InfoError, User } from '../../../redux/actions';
import { getResetPassword } from '../../../services/requests';
import AvatarManager from '../../AvatarManager';
import FormattedMessage from '../../FormattedMessageCustom';
import {
  ContainerInputs,
} from '../SettingsStyled';

const styles = {};

type OwnAccountProfileProps = {
  classes: any;
  actions: {
    userEdit: (...args: any[]) => any;
    uploadAvatar: (...args: any[]) => any;
    removeAvatar: (...args: any[]) => any;
    sendStatusToSnackbar: (...args: any[]) => any;
  };
  user?: IUserData;
  isDirtyState: (...args: any[]) => any;
};

type AccountProfileProps = OwnAccountProfileProps;

const AccountProfile = ({ user, isDirtyState, actions }: AccountProfileProps) => {

  const [firstName, setFirstName] = useState<string | undefined>('');
  const [lastName, setLastName] = useState<string | undefined>('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState<string | undefined>('');
  const [language, setLanguage] = useState('');
  const [success, setSuccess] = useState(false);
  const [dirtyState, setDirtyState] = useState(false);

  useEffect(() => {
    if (user) {
      setFirstName(user.first_name);
      setLastName(user.last_name);
      setEmail(user.email);
      setPhone(user.phone);
      setLanguage(user.language);
    }
  }, [user]);

  useEffect(() => {
    if (user) {
      if (
        !lodash.isEqual(firstName, user.first_name) ||
        !lodash.isEqual(lastName, user.last_name) ||
        !lodash.isEqual(phone, user.phone) ||
        !lodash.isEqual(language, user.language)
      ) {
        setDirtyState(true);
      } else {
        setDirtyState(false);
      }
    }
  }, [user, firstName, lastName, phone, language]);

  useEffect(() => {
    isDirtyState(dirtyState);
  }, [dirtyState, isDirtyState]);

  const handleEditUser = () => {
    actions.userEdit(firstName, lastName, phone, language);
    setSuccess(true);
  };

  const handleResetPassword = async () => {
    try {
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      await getResetPassword(user.email);
      actions.sendStatusToSnackbar('infoerror.passwordemailsended', 'success');
    } catch (error) {
      actions.sendStatusToSnackbar('infoerror.usernotfound', 'error');
    }
  };

  if (!user) return null;

  return (
    <Grid container direction="column">
      <Grid item>
        <Typography variant="h2">
          <FormattedMessage id="settings.account.profile.title" />
        </Typography>
      </Grid>
      <VSpacer />
      <Grid item>
        <Typography variant="h4">
          <FormattedMessage id="settings.account.profile.profile_picture" />
        </Typography>
      </Grid>
      <VSpacer medium />
      <Grid item>
        <AvatarManager
          avatarUrl={user.avatar_url}
          onRemoveAvatar={() => actions.removeAvatar()}
          onAvatarUpload={(fileName: string, imageType: string, image: Blob) => actions.uploadAvatar(fileName, imageType, image)}
        />
      </Grid>

      <VSpacer medium />
      <VSpacer medium />

      <Grid item>
        <Typography variant="h4">
          <FormattedMessage id="settings.account.profile.contact_info" />
        </Typography>
      </Grid>

      <VSpacer medium />

      <Grid item>
        <ContainerInputs>
          <InputText
            id="first_name"
            label={<FormattedMessage id="settings.account.profile.inputname" />}
            value={firstName}
            onChange={(value: any) => setFirstName(value)}
            margin="none"
            validators={[]}
          />
          <InputText
            id="last_name"
            label={<FormattedMessage id="settings.account.profile.inputlastname" />}
            value={lastName}
            onChange={(value: any) => setLastName(value)}
            margin="none"
            validators={[]}
          />
          <InputText
            id="email"
            label={<FormattedMessage id="settings.account.profile.inputemail" />}
            value={email}
            onChange={(value: any) => setEmail(value)}
            margin="none"
            disabled
            validators={[]}
          />
          <InputText
            id="phone"
            label={<FormattedMessage id="settings.account.profile.inputphone" />}
            value={phone}
            onChange={(value: any) => setPhone(value)}
            margin="none"
            validators={[]}
          />
        </ContainerInputs>
      </Grid>

      <VSpacer medium />

      <Grid item>
        <Typography variant="h4">
          <FormattedMessage id="settings.account.profile.change_password" />
        </Typography>
      </Grid>

      <VSpacer medium />

      <Grid item>
        <Grid container>
          <Grid item lg={12}>
            <Typography variant="body1">
              <FormattedMessage id="settings.account.profile.changepassadvice" />
            </Typography>
            <VSpacer medium />
            <Button onClick={handleResetPassword}>
              <FormattedMessage id="settings.account.profile.changepass" />
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <VSpacer medium />
      <VSpacer medium />

      <Grid item>
        <Typography variant="h4">
          <FormattedMessage id="settings.account.profile.profile_config" />
        </Typography>
      </Grid>
      <VSpacer medium />
      <Grid item>
        <Grid container>
          <Grid item lg={6}>
            <Grid container direction="column">
              <InputLabel htmlFor="language-select">
                <FormattedMessage id="settings.account.profile.inputlanguage" />
              </InputLabel>
              <Select
                value={language || 'en-us'}
                onChange={(evt) => {
                  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
                  setLanguage(evt.target.value);
                }}
                fullWidth
                inputProps={{
                  id: 'language-select',
                }}
              >
                <ListItem key="en" value="en-us">
                  <FormattedMessage id="settings.account.profile.en" />
                </ListItem>
                <ListItem key="es" value="es-es">
                  <FormattedMessage id="settings.account.profile.es" />
                </ListItem>
              </Select>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      <VSpacer />

      <Grid item>
        <Button variant={dirtyState || success ? 'contained' : 'outlined'} onClick={handleEditUser}>
          {success ? (
            <>
              <CheckmarkIcon />
              <FormattedMessage id="settings.buttonupdated" />
            </>
          ) : (
            <FormattedMessage id="settings.buttonupdate" />
          )}
        </Button>
      </Grid>
    </Grid>
  );
};

AccountProfile.defaultProps = {
  user: undefined,
};

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    userEdit: (firstName: any, lastName: any, phone: any, language: any) =>
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 8 arguments, but got 4.
      dispatch(User.editUser(firstName, lastName, phone, language)),
    uploadAvatar: (fileName: any, mimeType: any, file: any) =>
      dispatch(User.uploadAvatar(fileName, mimeType, file)),
    removeAvatar: () => dispatch(User.removeAvatar()),
    sendStatusToSnackbar: (message: any, type: any) =>
      dispatch(InfoError.sendStatusToSnackbar(message, type)),
  },
});

const mapStateToProps = (state: any) => {
  return { user: state.user.user };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AccountProfile));
