import { Divider, Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { CardElement } from '@stripe/react-stripe-js';
import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { HSpacer, VSpacer } from '@sm-highway-web/react-components';
import LockIcon from '../../assets/svg/LockIcon';
import FormattedMessage from '../FormattedMessageCustom';

const styles = {
  redColor: { color: 'red', fontSize: '16px', lineHeight: '1' },
};

const CardPaymentForm = () => {
  return (
    <>
      <VSpacer medium />
      {/* @ts-expect-error ts-migrate(2322) FIXME: Type '{ options: { iconStyle: "solid"; }; hidePost... Remove this comment to see the full error message */}
      <CardElement options={{ iconStyle: 'solid' }} hidePostalCode />
      <VSpacer small />
      <Divider />
      <VSpacer small />
      <VSpacer medium />
      <Grid container>
        <LockIcon style={{ color: 'rgb(73,85,120)' }} />
        <HSpacer small />
        <Typography>
          <FormattedMessage id="settings.billing.payment.dialog.encrypted" />
        </Typography>
      </Grid>
      <VSpacer small />
      <Typography>
        <FormattedMessage
          id="settings.billing.payment.dialog.agreepayment"
          values={{
            a: (chunks: any) => (
              <a href="https://smartmonkey.io/terms/" target="_blank" rel="noopener noreferrer">
                {chunks}
              </a>
            ),
          }}
        />
      </Typography>
      <VSpacer medium />
    </>
  );
};

CardPaymentForm.propTypes = {
  classes: PropTypes.shape({
    redColor: PropTypes.string.isRequired,
  }).isRequired,
};

export default withStyles(styles)(CardPaymentForm);
