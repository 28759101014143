import SvgIcon from '@material-ui/core/SvgIcon';
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon';
import React from 'react';

const planning = createSvgIcon(
  <>
    <path d="m20.6 5h-13.1c-0.3-1.2-1.4-2.1-2.7-2.1-1.5 0-2.8 1.2-2.8 2.8v12.1c0 1.5 1.2 2.8 2.8 2.8h15.8c0.8 0 1.4-0.6 1.4-1.4v-12.8c0-0.7-0.6-1.4-1.4-1.4zm-17.3 0.7c0-0.8 0.7-1.5 1.5-1.5s1.5 0.7 1.5 1.5v9.8c-0.4-0.3-1-0.5-1.5-0.5s-1.1 0.2-1.5 0.5v-9.8zm17.5 13.5c0 0.1-0.1 0.2-0.2 0.2h-15.8c-0.8 0-1.5-0.7-1.5-1.5s0.7-1.5 1.5-1.5 1.5 0.7 1.5 1.5c0 0.3 0.3 0.6 0.6 0.6s0.6-0.3 0.6-0.6v-11.6h13c0.1 0 0.2 0.1 0.2 0.2v12.7z" />
    <path d="m13 13.2h-3.3c-0.4 0-0.7 0.3-0.7 0.7s0.3 0.7 0.7 0.7h3.3c0.4 0 0.7-0.3 0.7-0.7s-0.3-0.7-0.7-0.7z" />
    <path d="m19 13.2h-3.3c-0.4 0-0.7 0.3-0.7 0.7s0.3 0.7 0.7 0.7h3.3c0.4 0 0.7-0.3 0.7-0.7s-0.3-0.7-0.7-0.7z" />
    <path d="m13 16.3h-3.3c-0.4 0-0.7 0.3-0.7 0.7s0.3 0.7 0.7 0.7h3.3c0.4 0 0.7-0.3 0.7-0.7s-0.3-0.7-0.7-0.7z" />
    <path d="m19 16.3h-3.3c-0.4 0-0.7 0.3-0.7 0.7s0.3 0.7 0.7 0.7h3.3c0.4 0 0.7-0.3 0.7-0.7s-0.3-0.7-0.7-0.7z" />
    <path d="m11 9.7h-1.3c-0.4 0-0.7 0.3-0.7 0.7s0.3 0.7 0.7 0.7h1.3c0.4 0 0.7-0.3 0.7-0.7s-0.3-0.7-0.7-0.7z" />
    <path d="m19 9.7h-1.3c-0.4 0-0.7 0.3-0.7 0.7s0.3 0.7 0.7 0.7h1.3c0.4 0 0.7-0.3 0.7-0.7s-0.3-0.7-0.7-0.7z" />
    <path d="m14.5 7.2c-0.9 0-1.7 0.8-1.7 1.7 0 0.3 0.1 0.6 0.2 0.8l1.3 2.1c0 0.1 0.1 0.1 0.1 0.1s0.1 0 0.1-0.1l1.3-2.1c0.1-0.2 0.2-0.6 0.2-0.9 0.1-0.9-0.6-1.6-1.5-1.6zm-0.1 2.2c-0.3 0-0.5-0.2-0.5-0.5s0.2-0.5 0.5-0.5 0.5 0.2 0.5 0.5-0.2 0.5-0.5 0.5z" />
  </>,
  'PlanStatusPlanning'
);

const inProgress = createSvgIcon(
  <>
    <path d="m4 8.8 1.7 1.5c0.9-1 2.1-1.5 3.4-1.5s2.5 0.5 3.4 1.5l1.7-1.5c-1.3-1.4-3.2-2.2-5.1-2.2s-3.8 0.8-5.1 2.2zm5.1-4.3c2.5 0 4.9 1.1 6.6 2.9l1.7-1.5c-2.1-2.3-5.2-3.6-8.3-3.6s-6.2 1.3-8.3 3.6l1.7 1.5c1.7-1.9 4.1-2.9 6.6-2.9zm10.1 14.6c-1 0-1.8 0.8-1.8 1.8s0.8 1.8 1.8 1.8 1.8-0.8 1.8-1.8-0.8-1.8-1.8-1.8zm-13 0c-1 0-1.8 0.8-1.8 1.8s0.8 1.8 1.8 1.8 1.8-0.8 1.8-1.8-0.8-1.8-1.8-1.8zm15.4-2.9c-0.7-0.4-2.9-0.8-2.9-0.8 0.2 0.1-1.4-1.7-2.6-2.6s-2.9-1.1-3-1.1h-10.7c-0.2 0-0.4 0.2-0.4 0.4v8.1c0 0.2 0.2 0.4 0.4 0.4h1.1c0.2 0 0.4-0.1 0.4-0.4 0.2-1.3 1.2-2 2.4-2s2.2 0.8 2.4 2c0 0.2 0.2 0.4 0.4 0.4h7.3c0.2 0 0.4-0.1 0.4-0.4 0.2-1.3 1.2-2 2.4-2s2.2 0.8 2.4 2c0 0.2 0.2 0.4 0.4 0.4h0.4c0.2 0 0.4-0.2 0.4-0.4v-3.1s-0.7-0.6-1.2-0.9zm-4.6-0.5h-3.6c-0.2 0-0.4-0.2-0.4-0.4v-2.2c0-0.2 0.2-0.4 0.4-0.4 0 0 0.8 0.2 1.6 0.5 0.8 0.4 2.3 1.8 2.3 1.8 0.2 0.3 0 0.7-0.3 0.7z" />
  </>,
  'PlanStatusInProgress'
);

const finished = createSvgIcon(
  <>
    <path d="m11 13.2h-1.3c-0.4 0-0.7 0.3-0.7 0.7s0.3 0.7 0.7 0.7h1.3c0.4 0 0.7-0.3 0.7-0.7s-0.3-0.7-0.7-0.7z" />
    <path d="m19 13.2h-3.3c-0.4 0-0.7 0.3-0.7 0.7s0.3 0.7 0.7 0.7h3.3c0.4 0 0.7-0.3 0.7-0.7s-0.3-0.7-0.7-0.7z" />
    <path d="m11 16.3h-1.3c-0.4 0-0.7 0.3-0.7 0.7s0.3 0.7 0.7 0.7h1.3c0.4 0 0.7-0.3 0.7-0.7s-0.3-0.7-0.7-0.7z" />
    <path d="m11 9.7h-1.3c-0.4 0-0.7 0.3-0.7 0.7s0.3 0.7 0.7 0.7h1.3c0.4 0 0.7-0.3 0.7-0.7s-0.3-0.7-0.7-0.7z" />
    <path d="m19 9.7h-1.3c-0.4 0-0.7 0.3-0.7 0.7s0.3 0.7 0.7 0.7h1.3c0.4 0 0.7-0.3 0.7-0.7s-0.3-0.7-0.7-0.7z" />
    <path d="m14.5 7.2c-0.9 0-1.7 0.8-1.7 1.7 0 0.3 0.1 0.6 0.2 0.8l1.3 2.1c0 0.1 0.1 0.1 0.1 0.1s0.1 0 0.1-0.1l1.3-2.1c0.1-0.2 0.2-0.6 0.2-0.9 0.1-0.9-0.6-1.6-1.5-1.6zm-0.1 2.2c-0.3 0-0.5-0.2-0.5-0.5s0.2-0.5 0.5-0.5 0.5 0.2 0.5 0.5-0.2 0.5-0.5 0.5z" />
    <path d="m22 14.6v-8.2c0-0.7-0.6-1.4-1.4-1.4h-13.1c-0.3-1.2-1.4-2.1-2.7-2.1-1.5 0-2.8 1.2-2.8 2.8v12.1c0 1.5 1.2 2.8 2.8 2.8h8.3c1 1.4 2.6 2.3 4.4 2.3 3 0 5.4-2.4 5.4-5.4-0.1-1.1-0.4-2.1-0.9-2.9zm-18.7-8.9c0-0.8 0.7-1.5 1.5-1.5s1.5 0.7 1.5 1.5v9.8c-0.4-0.3-1-0.5-1.5-0.5s-1.1 0.2-1.5 0.5v-9.8zm8.8 11.7c0 0.7 0.1 1.3 0.4 1.9h-7.7c-0.8 0-1.5-0.7-1.5-1.5s0.7-1.5 1.5-1.5 1.5 0.7 1.5 1.5c0 0.3 0.3 0.6 0.6 0.6s0.6-0.3 0.6-0.6v-11.5h13c0.1 0 0.2 0.1 0.2 0.2v6.8c-0.9-0.7-2.1-1.2-3.3-1.2-2.9 0-5.3 2.4-5.3 5.3zm9.2-0.9-3.6 3.6c-0.3 0.3-0.8 0.3-1.2 0l-1.8-1.8c-0.3-0.3-0.3-0.8 0-1.2 0.3-0.3 0.8-0.3 1.2 0l1.1 1.1c0.1 0.1 0.2 0.1 0.3 0l2.9-2.9c0.3-0.3 0.8-0.3 1.2 0 0.2 0.2 0.2 0.4 0.2 0.6l-0.3 0.6z" />
  </>,
  'PlanStatusFinished'
);

export const colors = {
  planning: '#FF8200',
  in_progress: '#FB4A59',
  finished: '#006841',
  planningBG: '#FDE7CD',
  in_progressBG: '#FFE4E7',
  finishedBG: '#DDFDEE',
};

export default { planning, in_progress: inProgress, finished } as {[key: string]: typeof SvgIcon};
