import { Badge, Grid, Input, Tab, Tabs, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Add, Delete } from '@material-ui/icons';
import { Button, HSpacer, ScrollableDialog, VSpacer } from '@sm-highway-web/react-components';
import { IProjectData } from 'highway-api/dist/common/interfaces/projects';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import TypeIcons from '../assets/svg/TypeIcons';
import { colors } from '../assets/svg/VehicleIcons';
import DataTable from '../components/datatable/DataTable';
import ExcelUpload from '../components/ExcelUpload';
import FormIcon from '../components/form-fields/FormIcon';
import FormLocation from '../components/form-fields/FormLocation';
import FormattedMessage from '../components/FormattedMessageCustom';
import { getVehicleFieldsArray } from '../helpers/Excel';
import { Vehicles } from '../redux/actions';
import eventsPanel from '../services/events';

const vehicleMapping = [
  {
    column: 'icon',
    name: 'Type',
    sortable: true,
    formatter: (doc: any) => {
      let Test = TypeIcons.normal;
      if (doc && TypeIcons[doc]) {
        Test = TypeIcons[doc];
        return (
          <div>
            <Test style={{ color: colors[doc] }} />
          </div>
        );
      }
      return (
        <div>
          <Test style={{ color: colors[doc] }} />
        </div>
      );
    },
  },
  {
    column: 'label',
    name: 'Name',
    sortable: true,
  },
  {
    column: 'location',
    name: 'Location',
    computed: (doc: any) => {
      if (doc.location) {
        return doc.location.label || `${doc.location.lat}, ${doc.location.lng}`;
      }
      return '-';
    },
  },
  {
    column: 'phone',
    name: 'Phone',
  },
  {
    column: 'comments',
    name: 'Notes',
    formatter: (doc: any) => (
      <Typography style={{ maxWidth: '700px' }} noWrap>
        {doc}
      </Typography>
    ),
  },
];

const styles = {
  icons: {
    margin: '1px',
    width: '32px',
    height: '32px',
  },
  divicons: {
    width: '34px',
    height: '34px',
    borderRadius: '17px',
  },
  white: {
    color: 'white',
  },
  badge: {
    width: '100%',
  },
  padding: {
    padding: '16px 0px',
  },
  width100: {
    width: '100%',
  },
  gridnumroutes: {
    width: '24px',
    height: '24px',
    backgroundColor: '#FF8200',
    borderRadius: '12px',
  },
  typonumroutes: {
    width: '100%',
    color: 'white',
  },
  typonumroutes2: {
    color: '#FF8200',
    paddingLeft: '8px',
  },
  errorQuota: {
    backgroundColor: 'rgba(255,130,0,0.1)',
    color: '#D0021B',
    padding: '8px',
    borderRadius: '16px',
  },
};

const TabPanel = ({ value, index, children }: any) => {
  return value === index ? children : null;
};

type OwnAddVehiclessDialogProps = {
  classes: {
    icons: string;
    divicons: string;
    white: string;
    badge: string;
    padding: string;
    gridnumroutes: string;
    typonumroutes: string;
    typonumroutes2: string;
    width100: string;
    errorQuota: string;
  };
  open: boolean;
  onClose: (...args: any[]) => any;
  flatVehicles?: {}[];
  actions: {
    createVehicles: (...args: any[]) => any;
  };
  activeProject: IProjectData;
};

type AddVehiclessDialogProps = OwnAddVehiclessDialogProps;

const AddVehiclessDialog = ({
  classes,
  open,
  onClose,
  actions,
  activeProject,
  flatVehicles,
}: AddVehiclessDialogProps) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [vehiclesToAdd1, setvehiclesToAdd1] = useState([]);
  const [vehiclesToAdd2, setvehiclesToAdd2] = useState([]);

  const nCurrentVehicles = flatVehicles ? flatVehicles.length : 0;
  const resultingQuota = () =>
    nCurrentVehicles +
    vehiclesToAdd1.length +
    vehiclesToAdd2.length -
    (activeProject && activeProject.limits ? activeProject.limits.max_vehicles : 0);
  const onCloseDialog = () => {
    setvehiclesToAdd1([]);
    setvehiclesToAdd2([]);
    onClose();
  };

  const handleChangeVehicles1 = (idx: any, label: any, value: any) => {
    const newArray = Object.assign([], vehiclesToAdd1);
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'any' is not assignable to type 'never'.
    newArray[idx][label] = value;
    setvehiclesToAdd1(newArray);
  };

  return (
    <ScrollableDialog
      modal
      disableBackdropClick
      scroll="body"
      onClose={onCloseDialog}
      open={open}
      dialogTitle={<FormattedMessage id="vehicles.vehiclesdialog.addvehicles" />}
      dialogContent={
        <>
          <Tabs
            value={tabIndex}
            onChange={(e, v) => setTabIndex(v)}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab
              label={(
                <Badge
                  className={classes.badge}
                  badgeContent={vehiclesToAdd1.length}
                  color="primary"
                  classes={{ colorPrimary: classes.white }}
                >
                  <Typography align="center" className={classes.width100}>
                    <FormattedMessage id="vehicles.vehiclesdialog.new" />
                  </Typography>
                </Badge>
              )}
              value={0}
            />
            <Tab
              label={(
                <Badge
                  className={classes.badge}
                  badgeContent={vehiclesToAdd2.length}
                  color="primary"
                  classes={{ colorPrimary: classes.white }}
                >
                  <Typography align="center" className={classes.width100}>
                    <FormattedMessage id="plan.servicesdialog.upload" />
                  </Typography>
                </Badge>
              )}
              value={1}
            />
          </Tabs>
          <div style={{ height: '70vh' }}>
            <TabPanel value={tabIndex} index={0}>
              <VSpacer medium />
              <Typography>
                <FormattedMessage id="vehicles.vehiclesdialog.new.explanation" />
              </Typography>

              <Grid container direction="column">
                {[...vehiclesToAdd1].map((vehicle, idx) => {
                  const helperKey = `vehicles${idx}`;
                  return (
                    <Grid
                      // @ts-expect-error ts-migrate(2339) FIXME: Property 'empty' does not exist on type 'never'.
                      key={`newvehicles-1-${vehicle.empty + idx}`}
                      style={{ paddingTop: '16px' }}
                      container
                      direction="row"
                      alignItems="flex-end"
                    >
                      <FormIcon
                        // @ts-expect-error ts-migrate(2339) FIXME: Property 'icon' does not exist on type 'never'.
                        fieldValue={vehicle.icon}
                        onUpdate={(icon: any) => {
                          handleChangeVehicles1(idx, 'icon', icon);
                        }}
                        type="vehicle"
                      />
                      <HSpacer medium key={`${vehicle}hsp1`} />
                      <Input
                        id={`label-${idx}`}
                        // @ts-expect-error ts-migrate(2339) FIXME: Property 'label' does not exist on type 'never'.
                        value={vehicle.label}
                        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
                        label={<FormattedMessage id="plan.servicesdialog.label" />}
                        onChange={(evt) => handleChangeVehicles1(idx, 'label', evt.target.value)}
                        margin="none"
                        hintSpace={false}
                      />
                      <HSpacer medium key={`${vehicle}hsp2`} />

                      <Grid item style={{ width: '500px' }}>
                        <FormLocation
                          key={`location${helperKey}`}
                          label={<FormattedMessage id="services.details.start_location" />}
                          fieldValue={
                            // @ts-expect-error ts-migrate(2339) FIXME: Property 'default_start_location' does not exist o... Remove this comment to see the full error message
                            vehicle.default_start_location && vehicle.default_start_location.label
                          }
                          onUpdate={(location: any) => {
                            handleChangeVehicles1(idx, 'default_start_location', location);
                            handleChangeVehicles1(idx, 'default_end_location', location);
                          }}
                          countryCode={
                            activeProject.optimizer_config
                              ? activeProject.optimizer_config.operation_country
                              : null
                          }
                        />
                      </Grid>

                      <HSpacer medium key={`${vehicle}hsp3`} />
                      <Button
                        key={`${vehicle}btn`}
                        color="secondary"
                        variant="contained"
                        onClick={() => {
                          const obj = Object.assign([], vehiclesToAdd1);
                          obj.splice(idx, 1);
                          setvehiclesToAdd1(obj);
                        }}
                      >
                        <Delete />
                      </Button>
                    </Grid>
                  );
                })}
                <VSpacer />
                <Grid item>
                  <Button
                    variant="contained"
                    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{}[]' is not assignable to param... Remove this comment to see the full error message
                    onClick={() => setvehiclesToAdd1([...vehiclesToAdd1, {}])}
                  >
                    <Add />
                  </Button>
                </Grid>
              </Grid>
            </TabPanel>
            <TabPanel value={tabIndex} index={1}>
              {vehiclesToAdd2.length === 0 && (
                <ExcelUpload
                  sampleUrl="https://static.smartmonkey.io/examples/services_clients.xlsx"
                  intlPreffix="vehicles.data.details"
                  fieldsArray={getVehicleFieldsArray()}
                  onAdd={setvehiclesToAdd2}
                  countryCode={
                    activeProject && activeProject.optimizer_config
                      ? activeProject.optimizer_config.operation_country
                      : null
                  }
                />
              )}
              {vehiclesToAdd2.length > 0 && (
                <DataTable
                  maxHeight="60vh"
                  noFooter
                  docs={vehiclesToAdd2}
                  total={vehiclesToAdd2.length}
                  mapping={vehicleMapping}
                />
              )}
            </TabPanel>
          </div>
        </>
      }
      dialogActions={
        <Grid container justify="space-between" style={{ paddingLeft: '16px' }}>
          <Grid item>
            <Grid container direction="column" justify="center" className={classes.gridnumroutes}>
              <Typography className={classes.typonumroutes} align="center">
                {vehiclesToAdd1.length + vehiclesToAdd2.length}
              </Typography>
              <Typography variant="h6" className={classes.typonumroutes2} align="center">
                <FormattedMessage id="vehicles.vehicles" />
              </Typography>
            </Grid>
          </Grid>
          {resultingQuota() > 0 && (
            <Grid item>
              <Grid container alignItems="center" className={classes.errorQuota}>
                <FormattedMessage
                  id="vehicles.vehiclesdialog.quota_exceeded"
                  values={{
                    maxVehicles:
                      activeProject && activeProject.limits ? activeProject.limits.max_vehicles : 0,
                    surplus: resultingQuota(),
                  }}
                />
              </Grid>
            </Grid>
          )}
          <Grid item>
            <Grid container justify="flex-end">
              <Grid item>
                <Button
                  onClick={() => {
                    onCloseDialog();
                  }}
                >
                  <FormattedMessage id="plan.servicesdialog.cancel" />
                </Button>
              </Grid>
              <HSpacer large />
              <Grid item>
                <Button
                  variant="contained"
                  style={{ boxShadow: 'none', color: 'white', width: '200px' }}
                  disabled={
                    resultingQuota() > 0 || vehiclesToAdd1.length + vehiclesToAdd2.length <= 0
                  }
                  onClick={() => {
                    actions.createVehicles([...vehiclesToAdd1, ...vehiclesToAdd2]);
                    const via = vehiclesToAdd2.length > 0 ? 'Subir' : 'Nuevo';
                    eventsPanel.addVehicle(via);
                    onCloseDialog();
                  }}
                >
                  <FormattedMessage id="plan.servicesdialog.create" />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      }
      maxWidth="lg"
      fullWidth
    />
  );
};

AddVehiclessDialog.defaultProps = {
  flatVehicles: undefined,
};

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    createVehicles: (vehicles: any) => dispatch(Vehicles.createVehicles(vehicles)),
  },
});

const mapStateToProps = (state: any) => ({
  flatVehicles: state.vehicles.flatVehicles,
  activeProject: state.projects.activeProject,
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AddVehiclessDialog));
