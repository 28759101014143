/**
 * SmartMonkey Dashboard
 *
 * Copyright © 2018-present SmartMonkey Scalable Computing S.L.. All rights reserved.
 *
 * @license This file is covered by the LICENSE.txt file in the root directory of this source tree.
 */
import * as mixpanel from 'mixpanel-browser';
import store from '../redux/store/index';
import configuration from './configuration';

export const PageViewPages = {
  login: 'Login',
  register: 'Register',
  plans: 'Plans',
  clients: 'Clients',
  vehicles: 'Vehicles',
  settings: 'Configuration',
  administration: 'Administration',
};

const isMixpanelEnabled = () => window.location.hostname !== 'localhost';

class EventsPanel {
  identified: any;

  initialized = false;

  // @ts-expect-error ts-migrate(2300) FIXME: Duplicate identifier 'token'.
  token = configuration.MIXPANEL_API_KEY;

  // @ts-expect-error ts-migrate(2300) FIXME: Duplicate identifier 'token'.
  token() {
    return this.token;
  }

  init() {
    if (isMixpanelEnabled()) {
      mixpanel.init(this.token, {
        loaded: () => {
          // eslint-disable-next-line
          console.log('Mixpanel is loaded');
        },
      });
      this.initialized = true;
    }
  }

  identify(user: any) {
    try {
      if (!this.initialized) this.init();
      if (!this.identified) {
        mixpanel.people.set({
          $email: user.email,
          $name: `${user.first_name} ${user.last_name}`,
          $phone: user.phone,
          $id: user.id,
          'Organization ID': user.organization_id,
        });
        mixpanel.identify(user.id);
        this.identified = true;
      }
    } catch (_) {
      // I want application to not crush, but don't care about the message
    }
  }

  validate() {
    if (isMixpanelEnabled()) {
      try {
        const { user, asUser } = store.getState().user;
        if (!this.initialized) this.init();
        if (!asUser) {
          if (user) this.identify(user);
          return true;
        }
        return false;
      } catch (_) {
        return false;
      }
    }
    return false;
  }

  pageView(pageName: any, pageVariant = null, extraContent = {}) {
    try {
      if (this.validate()) {
        mixpanel.register({ Platform: 'web' });
        mixpanel.track('Page View', {
          'Page Name': pageName,
          ...(pageVariant && pageVariant !== '' ? { 'Page Variant': pageVariant } : {}),
          ...extraContent,
        });
      }
    } catch (_) {
      // I want application to not crush, but don't care about the message
    }
  }

  login() {
    try {
      if (this.validate()) {
        mixpanel.track('Login', { 'Login Method': 'E-mail' });
      }
    } catch (_) {
      // I want application to not crush, but don't care about the message
    }
  }

  // This functions complements the above login function.
  completeLogin = (planType = 'Free') => {
    try {
      mixpanel.register({ 'Organization Subscription Type': planType });
      mixpanel.people.set({ 'Organization Subscription Type': planType });
    } catch (_) {
      // I want application to not crush, but don't care about the message
    }
  }

  logout() {
    if (isMixpanelEnabled()) {
      try {
        if (this.initialized) mixpanel.reset();
        this.initialized = false;
        this.identified = false;
      } catch (_) {
      // I want application to not crush, but don't care about the message
      }
    }
  }

  registerOrganization(
    name: any,
    country: any,
    email: any,
    phone: any,
    truckNumber: any,
    organizationName: any,
    organizationId: any,
    ownerId: any,
    createdAt: any
  ) {
    try {
      if (!this.initialized) this.init();
      mixpanel.register({ 'Number of Vehicles': truckNumber });
      mixpanel.people.append({
        $name: name,
        $email: email,
        $id: ownerId,
        $phone: phone,
        Country: country,
        'Number of Vehicles': truckNumber,
        'Organization Name': organizationName,
        'Organization ID': organizationId,
        'Registered at': createdAt,
      });
      mixpanel.track('Register');
    } catch (_) {
      // I want application to not crush, but don't care about the message
    }
  }

  planCreated() {
    try {
      if (this.validate()) mixpanel.track('Plan Created');
    } catch (_) {
      // I want application to not crush, but don't care about the message
    }
  }

  planUpdated(status: any) {
    try {
      if (this.validate()) mixpanel.track('Plan Updated', { Status: status });
    } catch (_) {
      // I want application to not crush, but don't care about the message
    }
  }

  planDispatch() {
    try {
      if (this.validate()) mixpanel.track('Plan Dispatching');
    } catch (_) {
      // I want application to not crush, but don't care about the message
    }
  }

  planMenu(_view: any) {
    try {
      if (this.validate()) {
        let view;
        switch (_view) {
          case 'list':
            view = 'List';
            break;
          case 'map':
            view = 'Map';
            break;
          case 'live':
            view = 'Live';
            break;
          default:
            break;
        }

        if (view) mixpanel.track('Plan Menu', { View: view });
      }
    } catch (_) {
      // I want application to not crush, but don't care about the message
    }
  }

  planOptimized(numServices: any, numRoutes: any, distance: any, duration: any) {
    try {
      if (this.validate()) {
        mixpanel.track('Plan Optimized', {
          '# of Services': numServices,
          '# of Rutas': numRoutes,
          Distance: distance,
          'Duration (min)': duration / 60,
        });
      }
    } catch (_) {
      // I want application to not crush, but don't care about the message
    }
  }

  routeOptimized(numServices: any, distance: any, duration: any) {
    try {
      if (this.validate()) {
        mixpanel.track('Route Optimized', {
          '# of Services': numServices,
          Distance: distance,
          'Duration (min)': duration / 60,
        });
      }
    } catch (_) {
      // I want application to not crush, but don't care about the message
    }
  }

  importRoutes(via: any) {
    try {
      if (this.validate()) mixpanel.track('Import Routes', { Via: via });
    } catch (_) {
      // I want application to not crush, but don't care about the message
    }
  }

  importServices(via: any) {
    try {
      if (this.validate()) mixpanel.track('Import Services', { Via: via });
    } catch (_) {
      // I want application to not crush, but don't care about the message
    }
  }

  addClient(via: any) {
    try {
      if (this.validate()) mixpanel.track('Add Client', { Via: via });
    } catch (_) {
      // I want application to not crush, but don't care about the message
    }
  }

  addVehicle(via: any) {
    try {
      if (this.validate()) mixpanel.track('Add Vehicle', { Via: via });
    } catch (_) {
      // I want application to not crush, but don't care about the message
    }
  }

  changeSubscriptionPlan(
    oldPlan: any,
    newPlan: any,
    oldVehicles: any,
    newVehicles: any,
    remainingDays: any
  ) {
    try {
      if (this.validate()) {
        mixpanel.register({
          'Organization Subscription Type': newPlan,
          'Number of Vehicles': newVehicles,
        });

        mixpanel.people.set({
          'Organization Subscription Type': newPlan,
          'Days Until Trial Ends': remainingDays,
        });

        let change = null;
        if (oldPlan !== newPlan) {
          change =
            (oldPlan === 'monkey' && (newPlan === 'gorilla' || newPlan === 'kong')) ||
            (oldPlan === 'gorilla' && newPlan === 'kong')
              ? 'Upgrade'
              : 'Downgrade';
        } else if (oldVehicles !== newVehicles) {
          change = newVehicles > oldVehicles ? 'Upgrade' : 'Downgrade';
        }

        mixpanel.track('Change Subscription', {
          'Old Subscription': oldPlan,
          'New Subscription': newPlan,
          'Old Number of Vehicles': oldVehicles,
          'New Number of Vehicles': newVehicles,
          ...(change !== null ? { 'Subscription Change': change } : {}),
        });
      }
    } catch (_) {
      // I want application to not crush, but don't care about the message
    }
  }

  cancelSubscriptionPlan(reasons: any, planType: any) {
    try {
      if (this.validate()) {
        mixpanel.track('Cancel Subscription', {
          'Cancelation Method': 'Manual',
          'Cancelation Reason': reasons,
          'Canceled Subscription Type': planType,
        });
        mixpanel.people.set({ 'Organization Subscription Type': planType });
      }
    } catch (_) {
      // I want application to not crush, but don't care about the message
    }
  }
}

export default new EventsPanel();
