import {
  CircularProgress,
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  withStyles,
} from '@material-ui/core';
import { Done } from '@material-ui/icons';
import DeleteIcon from '@material-ui/icons/Delete';
import { Button, HSpacer, Map, Marker, VSpacer } from '@sm-highway-web/react-components';
import * as _ from 'lodash';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import readXlsxFile from 'read-excel-file';
import { isValidService } from '../helpers/Directions';
import { normalizeString } from '../helpers/Excel';
import { InfoError } from '../redux/actions';
import CustomDropzone from './CustomDropzone';
import FormattedMessage from './FormattedMessageCustom';

const styles = {
  accordionPaper: {
    boxShadow: 'none',
    borderTop: '1px solid #E7E7E7',
  },
  accordionSummary: {
    textTransform: 'uppercase',
    color: '#8194B8',
    fontWeight: 600,
  },
  select: {
    backgroundColor: '#EBF0F8',
    marginTop: '6px',
    paddingLeft: '6px',
    borderRadius: '4px',
    '&:focus': {
      borderRadius: '4px',
      backgroundColor: '#FDF6ED',
    },
  },
};

type ExcelUploadProps = {
  classes: {
    accordionPaper: string;
    select: string;
    accordionSummary: string;
  };
  intlPreffix: string;
  fieldsArray: any[];
  onAdd: (...args: any[]) => any;
  sampleUrl: string;
  actions: {
    sendStatusToSnackbar: (...args: any[]) => any;
  };
  countryCode?: string | null;
};

const ExcelUpload = ({
  fieldsArray,
  intlPreffix,
  onAdd,
  classes,
  sampleUrl,
  actions,
  countryCode,
}: ExcelUploadProps) => {
  const [uploadedData, setUploadedData] = useState([]);
  const [dataHeaders, setDataHeaders] = useState({});
  const [dataMapping, setDataMapping] = useState({});
  const [dataDisponibility, setDataDisponibility] = useState({});
  const [clients, setClients] = useState([]);
  const [loadingFile, setLoadingFile] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [fileName, setFileName] = useState(undefined);
  const [stage, setStage] = useState(0);

  const Legend = ({ onNext, onBack }: any) => (
    <Grid container direction="row" justify="space-between" style={{ paddingBottom: '12px' }}>
      <Grid container style={{ width: 'calc(100% - 200px)' }}>
        <Grid container style={{ paddingTop: '10px' }}>
          <Grid item style={{ paddingLeft: '24px', paddingRight: '8px', color: '#5A6B86' }}>
            {fileName}
          </Grid>
          <DeleteIcon
            style={{
              paddingRight: '12px',
              paddingLeft: '12px',
              backgroundColor: 'rgba(129,129,129,0.1)',
              borderRadius: '16px',
              color: '#8194b8',
              cursor: 'pointer',
            }}
            onClick={() => {
              setUploadedData([]);
              setDataHeaders({});
              setClients([]);
              setLoading(false);
              setFileName(undefined);
              setDataDisponibility({});
              setDataMapping({});
              setStage(0);
            }}
          />
          <Grid item style={{ paddingLeft: '12px' }}>
            <FormattedMessage tagName="span" id={`${intlPreffix}.text.read_amount`} />
:
            {' '}
            {uploadedData && uploadedData.length}
          </Grid>
        </Grid>
      </Grid>
      {onBack && (
        <Grid item>
          <Button variant="contained" onClick={onBack}>
            <FormattedMessage id="uploadexcel.actions.back" />
          </Button>
        </Grid>
      )}
      {onNext && (
        <Grid item>
          <Button variant="contained" disabled={loading} onClick={onNext}>
            <FormattedMessage id="uploadexcel.actions.next" />
          </Button>
        </Grid>
      )}
    </Grid>
  );

  Legend.propTypes = {
    onNext: PropTypes.func.isRequired,
    onBack: PropTypes.func,
  };

  Legend.defaultProps = {
    onBack: () => {},
  };

  const isAnyFieldLoading = () => {
    const loadingFields = Object.keys(dataDisponibility).filter(
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      (key) => dataDisponibility[key] === 'loading'
    );
    return loadingFields.length > 0;
  };

  return (
    <>
      {stage === 0 && (
        <>
          <VSpacer small />
          <CustomDropzone
            // @ts-expect-error ts-migrate(2322) FIXME: Type '{ sampleUrl: string; style: { height: string... Remove this comment to see the full error message
            sampleUrl={sampleUrl}
            style={{ height: '100%' }}
            acceptedFiles={['.xlsx']}
            text={
              <FormattedMessage
                id={`${intlPreffix}.text.description`}
                values={{
                  a_routes_sample: (chunks: any) => (
                    <a href="https://static.smartmonkey.io/examples/routes_vehicles.xlsx">
                      {chunks}
                    </a>
                  ),
                  a_services_sample: (chunks: any) => (
                    <a href="https://static.smartmonkey.io/examples/services_clients.xlsx">
                      {chunks}
                    </a>
                  ),
                }}
              />
            }
            onDrop={async (val: any) => {
              setLoadingFile(true);

              const [dataHeadersTmp, ...uploadeDataTmp] = await readXlsxFile(val[0]);
              const validDataHeaders = {};

              // Excel must have columns and data inside. Must follow the example file format.
              if (dataHeadersTmp && uploadeDataTmp && uploadeDataTmp.length > 0) {
                /**
                 * This code prevents excels with thousands of empty
                 * columns to crash. When 50 empty columns are found
                 * the code stops searching
                 */
                let emptyCount = 0;
                dataHeadersTmp.some((value: any, idx: any) => {
                  if (value === '') {
                    emptyCount += 1;
                  }
                  if (emptyCount === 50) {
                    return true;
                  }
                  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                  validDataHeaders[value] = idx;
                  return false;
                });

                setFileName(val[0].path);
                setUploadedData(uploadeDataTmp);
                setDataHeaders(validDataHeaders);
                setStage(1);

                /**
                 * Here we are going to parse all the existing columns.
                 * If a column is called equal as one of the given by
                 * the SmartMonkey.io example file, it will be mapped.
                 * */
                setTimeout(() => {
                  let temporalDataMapping = {};
                  let tempDataDisponibility = {};

                  const fields = fieldsArray
                    .map((groups: any) => groups[1].map((groupField: any) => groupField))
                    .reduce((a: any, b: any) => a.concat(b));

                  // @ts-expect-error ts-migrate(7031) FIXME: Binding element 'field' implicitly has an 'any' ty... Remove this comment to see the full error message
                  fields.map(async ([field, parseFunction, columnKeys, _text]) => {
                    // Checking if columnKeys is not empty.
                    if (columnKeys) {
                      // Retriving the first entry that has the specified columnKeys.
                      const foundAutofillField = Object.entries(validDataHeaders).find(
                        (header: any) => columnKeys.indexOf(normalizeString(header[0])) > -1
                      );

                      // If a field is found. It will be used for mapping it's value.
                      if (foundAutofillField) {
                        tempDataDisponibility = { ...tempDataDisponibility, [field]: 'loading' };

                        setDataDisponibility({ ...tempDataDisponibility });

                        temporalDataMapping = {
                          ...temporalDataMapping,
                          [field]: foundAutofillField[1],
                        };

                        setDataMapping({ ...temporalDataMapping });

                        const allValues = await Promise.all(
                          uploadeDataTmp.map((value: any) =>
                            // @ts-expect-error ts-migrate(2538) FIXME: Type 'unknown' cannot be used as an index type.
                            parseFunction(value[foundAutofillField[1]], { countryCode })
                          )
                        );

                        tempDataDisponibility = { ...tempDataDisponibility, [field]: allValues };

                        setDataDisponibility({ ...tempDataDisponibility });
                      }
                    }
                  });

                  setLoadingFile(false);
                });
              } else {
                actions.sendStatusToSnackbar('infoerror.upload_excel_template_error', 'error');
              }
            }}
          />
          <VSpacer small />
        </>
      )}

      {stage === 1 && loadingFile && (
        <Grid
          container
          direction="column"
          style={{ height: '100%' }}
          justify="center"
          alignItems="center"
        >
          <span style={{ color: '#6f6f6f' }}>
            <FormattedMessage id={`${intlPreffix}.mapping`} />
          </span>
        </Grid>
      )}

      {stage === 1 && !loadingFile && (
        <Grid container direction="column">
          <Legend
            onNext={() => {
              setClients(
                // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'any[]' is not assignable to para... Remove this comment to see the full error message
                uploadedData.map((elem, idx) =>
                // Object.entries(dataMapping).reduce(
                //   (prev, curr) => ({
                //     ...prev,
                //     ...(curr ? { [curr[0]]: dataDisponibility[curr[0]][idx] } : {})
                //   }),
                //   {}
                // )

                  Object.entries(dataMapping).reduce((prev: any, curr: any) => {
                    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                    if (curr && curr[0] && dataDisponibility[curr[0]]) {
                      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                      return _.set(prev, curr[0], dataDisponibility[curr[0]][idx]);
                    }
                    return prev;
                  }, {})
                )
              );
              setStage(2);
            }}
          />
          {fieldsArray.map((groups: any) => (
            <ExpansionPanel
              key={`expansionpanel${groups[0]}`}
              defaultExpanded
              style={{ width: '100%' }}
              classes={{ root: classes.accordionPaper }}
            >
              <ExpansionPanelSummary>
                <div className={classes.accordionSummary}>
                  <FormattedMessage id={`${intlPreffix}.${groups[0]}`} />
                </div>
              </ExpansionPanelSummary>
              <ExpansionPanelDetails>
                {/* Create a grid for content */}
                <Grid container style={{ width: '100%' }}>
                  {/* Iterate to render each group */}
                  {/* @ts-expect-error ts-migrate(7031) FIXME: Binding element 'field' implicitly has an 'any' ty... Remove this comment to see the full error message */}
                  {groups[1].map(([field, parseFunction, _columnKeys, text]) => {
                    const setMapping = async (value: any) => {
                      setLoading(true);
                      setDataDisponibility({
                        ...dataDisponibility,
                        [field]: 'loading',
                      });
                      setDataMapping({ ...dataMapping, [field]: value });
                      const allValues = await Promise.all(
                        uploadedData.map((val) => parseFunction(val[value], { countryCode }))
                      );
                      setDataDisponibility({
                        ...dataDisponibility,
                        [field]: allValues,
                      });
                      setLoading(false);
                    };

                    return (
                      <Grid
                        container
                        style={{ width: '350px' }}
                        alignItems="flex-end"
                        key={`grid${field}`}
                      >
                        <Grid
                          container
                          direction="column"
                          style={{ margin: '6px', width: 'auto' }}
                          key={`gridc${field}`}
                        >
                          <InputLabel
                            id={`datamapping-match-${field}`}
                            style={{
                              color: '#8B8B8B',
                              fontWeight: 600,
                              textTransform: 'uppercase',
                            }}
                          >
                            {text ? (
                              <span>{text}</span>
                            ) : (
                              <FormattedMessage id={`${intlPreffix}.${field}`} />
                            )}
                          </InputLabel>

                          <Select
                            disableUnderline
                            classes={{ root: classes.select }}
                            // labelId={`datamapping-match-${field}`}
                            disabled={isAnyFieldLoading()}
                            placeholder=" "
                            onChange={async (evt) => {
                              const { value } = evt.target;
                              if (value === undefined || value === 'noselect') {
                                // @ts-expect-error ts-migrate(2538) FIXME: Type 'any' cannot be used as an index type.
                                const { [field]: valux, ...other } = dataDisponibility;
                                // @ts-expect-error ts-migrate(2538) FIXME: Type 'any' cannot be used as an index type.
                                const { [field]: valux2, ...other2 } = dataMapping;
                                setDataDisponibility({
                                  ...other,
                                });

                                setDataMapping({ ...other2 });
                                return;
                              }
                              setMapping(value);
                            }}
                            // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                            value={`${dataMapping[field]}` || 'noselect'}
                            style={{ width: '200px' }}
                          >
                            <MenuItem value="noselect" key="menuitemnoselect">
                              -
                            </MenuItem>
                            {Object.entries(dataHeaders).map((header: any) => (
                              <MenuItem
                                value={`${header[1]}`}
                                key={`menuItem${header[1]}${header[0]}`}
                              >
                                {header[0]}
                              </MenuItem>
                            ))}
                          </Select>
                        </Grid>
                        <HSpacer small />
                        <Grid style={{ marginBottom: '10px' }}>
                          <Grid container>
                            {(() => {
                              // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                              if (!dataDisponibility[field] || dataDisponibility[field] === null) {
                                return <></>;
                              }
                              // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                              if (dataDisponibility[field] === 'loading') {
                                return <CircularProgress size={32} />;
                              }
                              // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                              const incorrectFields = dataDisponibility[field].filter(
                                (v: any) =>
                                  v === null || (typeof v === typeof [1, 2, 3] && v.length === 0)
                              ).length;
                              // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                              const blankFields = dataDisponibility[field].filter(
                                (v: any) => v === undefined
                              ).length;
                              if (incorrectFields > 0 || blankFields > 0) {
                                return (
                                  <>
                                    {incorrectFields > 0 && (
                                      <Tooltip
                                        title={(
                                          <FormattedMessage
                                            id={`${intlPreffix}.error.missing`}
                                            values={{ incorrectFields }}
                                          />
                                        )}
                                      >
                                        <div
                                          style={{
                                            cursor: 'help',
                                            padding: '3px',
                                            backgroundColor: '#D21310',
                                            textAlign: 'center',
                                            borderRadius: '50%',
                                            color: 'white',
                                            // @ts-expect-error ts-migrate(2322) FIXME: Type '"700"' is not assignable to type 'number | "... Remove this comment to see the full error message
                                            fontWeight: '700',
                                            minHeight: '18px',
                                            minWidth: '18px',
                                          }}
                                        >
                                          {incorrectFields}
                                        </div>
                                      </Tooltip>
                                    )}
                                    {blankFields > 0 && (
                                      <Tooltip
                                        title={(
                                          <FormattedMessage
                                            id={`${intlPreffix}.error.blank`}
                                            values={{ blankFields }}
                                          />
                                        )}
                                      >
                                        <div
                                          style={{
                                            cursor: 'help',
                                            padding: '3px',
                                            backgroundColor: 'lightblue',
                                            textAlign: 'center',
                                            borderRadius: '50%',
                                            color: 'white',
                                            // @ts-expect-error ts-migrate(2322) FIXME: Type '"700"' is not assignable to type 'number | "... Remove this comment to see the full error message
                                            fontWeight: '700',
                                            minHeight: '18px',
                                            minWidth: '18px',
                                          }}
                                        >
                                          {blankFields}
                                        </div>
                                      </Tooltip>
                                    )}
                                  </>
                                );
                              }
                              return <Done style={{ color: 'green' }} />;
                            })()}
                            {/* @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message */}
                            {dataDisponibility[field] && dataDisponibility[field] !== 'loading' && (
                              <div>{}</div>
                            )}
                          </Grid>
                        </Grid>
                      </Grid>
                    );
                  })}
                </Grid>
              </ExpansionPanelDetails>
            </ExpansionPanel>
          ))}
        </Grid>
      )}
      {stage === 2 && (
        <Grid container direction="column">
          <Legend
            onBack={() => {
              setStage(1);
              setClients([]);
            }}
            onNext={() => {
              onAdd(clients);
            }}
          />
          <Grid item>
            <Map key="map">
              {clients.filter(isValidService).map((c, idx) => (
                <Marker
                  id={idx}
                  key={idx}
                  // @ts-expect-error ts-migrate(2339) FIXME: Property 'location' does not exist on type 'never'... Remove this comment to see the full error message
                  lat={c.location.lat}
                  // @ts-expect-error ts-migrate(2339) FIXME: Property 'location' does not exist on type 'never'... Remove this comment to see the full error message
                  lng={c.location.lng}
                  // @ts-expect-error ts-migrate(2339) FIXME: Property 'icon' does not exist on type 'never'.
                  kind={c.icon ? c.icon : 'normal'}
                />
              ))}
            </Map>
          </Grid>
        </Grid>
      )}
    </>
  );
};

ExcelUpload.defaultProps = {
  countryCode: null,
};

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    sendStatusToSnackbar: (message: any, type: any) =>
      dispatch(InfoError.sendStatusToSnackbar(message, type)),
  },
});

// @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ accordionPaper: { boxShadow: s... Remove this comment to see the full error message
export default connect(null, mapDispatchToProps)(withStyles(styles)(ExcelUpload));
