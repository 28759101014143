import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  withStyles,
} from '@material-ui/core';
import { Checkbox } from '@sm-highway-web/react-components';
import React, { useEffect, useState } from 'react';
import DataTableRow from './DataTableRow';
import TableCellHeader from './TableCellHeader';

const styles = {
  checkboxColumn: {
    width: '36px',
    padding: '6px 12px 6px 12px',
  },
  tableHead: {
    backgroundColor: 'white',
    fontSize: '14px',
    textTransform: 'uppercase',
    color: '#8194B8',
    fontWeight: 500,
  },
  row: {
    maxHeight: '36px',
    height: '36px',
  },
};

type DataTableProps = {
  docs?: {}[];
  noFooter?: boolean;
  loading?: boolean;
  total?: number;
  page?: number;
  limit?: number;
  sortColumn?: string;
  sortOrder?: string;
  orderBy?: string;
  docsIndex?: string;
  mapping: {
    style?: any;
    align?: any;
    column?: any;
    name?: any;
    computed?: any;
    formatter?: any;
  }[];
  emptyMessage?: React.ReactElement | string;
  onChangePage?: (...args: any[]) => any;
  onChangeRowsPerPage?: (...args: any[]) => any;
  onRowSelected?: (...args: any[]) => any;
  onMultipleSelection?: (...args: any[]) => any;
  onColumnSort?: (...args: any[]) => any;
  onRowDoubleClick?: (...args: any[]) => any;
  maxHeight?: string;
  classes: {
    tableHead: string;
    checkboxColumn: string;
    row: string;
  };
  selectedByProps?: {
    id: string;
  }[];
  hover?: boolean;
  handleOnContextMenu?: (...args: any[]) => any;
};

const DataTable = ({
  docs,
  total,
  page,
  limit,
  mapping,
  orderBy,
  loading,
  noFooter,
  emptyMessage,
  onChangePage,
  maxHeight,
  sortColumn,
  sortOrder,
  onChangeRowsPerPage,
  onRowSelected,
  onMultipleSelection,
  onColumnSort,
  docsIndex,
  selectedByProps,
  onRowDoubleClick,
  classes,
  hover,
  handleOnContextMenu,
}: DataTableProps) => {
  const [_selected, _setSelected] = useState(
    selectedByProps
      ? selectedByProps.reduce((curr: any, next: any) => ({ [next.id]: next, ...curr }), {})
      : {}
  );
  useEffect(() => {
    if (selectedByProps) {
      _setSelected((sel: any) =>
        selectedByProps.reduce((curr: any, next: any) => ({ [next.id]: next, ...curr }), sel)
      );
    }
  }, [selectedByProps]);

  /**
   * Function is called when a single row is selected
   * @param {Object} doc
   */
  const handleRowSelect = async (doc: any) => {
    // @ts-expect-error ts-migrate(2538) FIXME: Type 'undefined' cannot be used as an index type.
    const newSelection = { [doc[docsIndex]]: doc };
    _setSelected(newSelection);
    // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    onRowSelected(doc);

    // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    onMultipleSelection(Object.values(newSelection));
  };

  /**
   * Check if an element is already selected
   * @param {*} index
   */
  const isSelected = (index: any) => index in _selected;

  /**
   * This function is called when the checkbox is clicked
   * @param {Object} doc
   */
  const handleCheckboxSelect = (doc: any) => {
    let newSelection;
    // @ts-expect-error ts-migrate(2538) FIXME: Type 'undefined' cannot be used as an index type.
    if (isSelected(doc[docsIndex])) {
      // @ts-expect-error ts-migrate(2538) FIXME: Type 'undefined' cannot be used as an index type.
      const { [doc[docsIndex]]: toRemove, ...other } = _selected;
      newSelection = other;
    } else {
      // @ts-expect-error ts-migrate(2538) FIXME: Type 'undefined' cannot be used as an index type.
      newSelection = { ..._selected, [doc[docsIndex]]: doc };
    }
    _setSelected(newSelection);
    // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    onMultipleSelection(Object.values(newSelection));
  };

  /**
   * Select each single element on the current client page
   */
  const handleSelectAll = () => {
    let newSelection;
    if (Object.keys(_selected).length > 0) {
      newSelection = {};
    } else {
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      newSelection = docs.reduce(
        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        (prev: any, curr: any) => ({ ...prev, [curr[docsIndex]]: curr }),
        {}
      );
    }
    _setSelected(newSelection);
    // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    onMultipleSelection(Object.values(newSelection));
  };

  return (
    <div
      style={{
        position: 'relative',
        display: 'flex',
        height: '100%',
        flexDirection: 'column',
        ...(maxHeight ? { maxHeight } : {}),
      }}
    >
      <Grid item style={{ overflow: 'auto', flexGrow: 1 }}>
        <Table stickyHeader component="div">
          <TableHead component="div">
            <TableRow component="div" className={classes.row}>
              {/* RENDER OPTIONAL CHECKBOX COLUMN */}
              {docs && docs.length > 0 && docsIndex && (
                <TableCell
                  component="div"
                  className={classes.checkboxColumn}
                  classes={{ head: classes.tableHead }}
                >
                  <Checkbox
                    checked={Object.keys(_selected).length === docs.length}
                    onChange={handleSelectAll}
                    indeterminate={
                      Object.keys(_selected).length < docs.length &&
                      Object.keys(_selected).length > 0
                    }
                  />
                </TableCell>
              )}
              {/* RENDER EVERY COLUMN MAPPED */}
              {mapping.map((entry: any) => {
                return (
                  <TableCellHeader
                    key={`table-head-${entry.column}`}
                    entry={entry}
                    // @ts-expect-error ts-migrate(2322) FIXME: Type '((...args: any[]) => any) | undefined' is no... Remove this comment to see the full error message
                    onColumnSort={onColumnSort}
                    // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
                    order={sortColumn === entry.column ? sortOrder : undefined}
                    align={entry.align}
                    style={entry.style}
                  />
                );
              })}
            </TableRow>
          </TableHead>
          {docs !== undefined && docs.length > 0 && (
            <TableBody component="div">
              {docs.map((document: any, index: any) => {
                // Check if this row is selected
                return (
                  <DataTableRow
                    key={`row-${(docsIndex && document[docsIndex]) || index}`}
                    docsIndex={docsIndex}
                    isSelected={isSelected}
                    // @ts-expect-error ts-migrate(2322) FIXME: Type '{ style?: any; }[]' is not assignable to typ... Remove this comment to see the full error message
                    mapping={mapping}
                    document={document}
                    handleCheckboxSelect={handleCheckboxSelect}
                    handleRowSelect={handleRowSelect}
                    // @ts-expect-error ts-migrate(2322) FIXME: Type '((...args: any[]) => any) | undefined' is no... Remove this comment to see the full error message
                    handleOnContextMenu={handleOnContextMenu}
                    // @ts-expect-error ts-migrate(2322) FIXME: Type '((...args: any[]) => any) | undefined' is no... Remove this comment to see the full error message
                    onRowDoubleClick={onRowDoubleClick}
                    index={index}
                    // @ts-expect-error ts-migrate(2322) FIXME: Type 'boolean | undefined' is not assignable to ty... Remove this comment to see the full error message
                    hover={hover}
                  />
                );
              })}
            </TableBody>
          )}
        </Table>
      </Grid>
      <div
        style={{
          ...(noFooter
            ? {}
            : {
                position: 'relative',
                bottom: '0',
                width: '100%',
                paddingBottom: '8px',
                borderTop: 'solid 1px rgba(224, 224, 224, 1)',
              }),
        }}
      >
        {!noFooter && !((docs === undefined || docs.length === 0) && emptyMessage !== undefined) && (
          <Grid container justify="flex-end">
            {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
            <TablePagination
              component="div"
              count={total}
              rowsPerPage={limit}
              // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
              page={page - 1}
              backIconButtonProps={{
                'aria-label': 'Previous Page',
              }}
              nextIconButtonProps={{
                'aria-label': 'Next Page',
              }}
              // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
              onChangePage={(_, newPage) => onChangePage(limit * newPage, limit)}
              // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
              onChangeRowsPerPage={(evt) => onChangePage(0, evt.target.value)}
            />
          </Grid>
        )}
      </div>
    </div>
  );
};

const defaultFunction = () => {};

DataTable.defaultProps = {
  docs: undefined,
  loading: false,
  total: undefined,
  page: undefined,
  limit: undefined,
  sortColumn: undefined,
  sortOrder: undefined,
  orderBy: undefined,
  docsIndex: undefined,
  onChangePage: defaultFunction,
  onChangeRowsPerPage: defaultFunction,
  onRowSelected: defaultFunction,
  onColumnSort: defaultFunction,
  onMultipleSelection: defaultFunction,
  emptyMessage: '',
  onRowDoubleClick: defaultFunction,
  noFooter: false,
  selectedByProps: undefined,
  maxHeight: undefined,
  hover: false,
  handleOnContextMenu: defaultFunction,
};

// @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ checkboxColumn: { width: strin... Remove this comment to see the full error message
export default withStyles(styles)(DataTable);
