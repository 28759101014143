
export const PLAN_TYPE = {
  MONKEY: 'monkey',
  GORILLA: 'gorilla',
  KONG: 'kong',
} as { [key: string]: string };

export const PLAN_TYPE_ICON = {
  MONKEY: '🐵',
  GORILLA: '🦍',
  KONG: '👑',
} as { [key: string]: string };

export const PLAN_TYPE_TO_SHOW = {
  MONKEY: '🐵 Monkey',
  GORILLA: '🦍 Gorilla',
  KONG: '👑 Kong',
} as { [key: string]: string };
