import { Box, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Button, HSpacer } from '@sm-highway-web/react-components';
import React, { useCallback, useState } from 'react';
import Cropper from 'react-easy-crop';
import FormattedMessage from '../components/FormattedMessageCustom';
import getCroppedImg, { IArea } from '../helpers/Images';

const styles = {
  cropContainer: {
    height: '300px',
    margin: '20px',
  },
};

type ImageCropDialogProps = {
  classes: {
    cropContainer: string;
  };
  image?: string;
  imageType?: string;
  open: boolean;
  title?: React.ReactElement | {} | string;
  onCloseDialog: (...args: any[]) => any;
  onCrop: (...args: any[]) => any;
};

// const CONTAINER_HEIGHT = 300;

const ImageCropDialog = ({
  classes,
  image,
  imageType,
  open,
  onCloseDialog,
  title,
  onCrop,
}: ImageCropDialogProps) => {

  const [loading, setLoading] = useState<boolean>(false);
  const [crop, setCrop] = useState<{x: number, y: number}>({ x: 0, y: 0 });
  const [zoom, setZoom] = useState<number>(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<IArea | undefined>(undefined);

  const onCropComplete = useCallback((_croppedArea: IArea, _croppedAreaPixels: IArea) => {
    setCroppedAreaPixels(_croppedAreaPixels);
  }, []);

  const endCropImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(image as string, croppedAreaPixels as IArea, imageType);
      setLoading(false);
      onCrop(croppedImage);
    } catch (e) {
      console.error(e);
    }
  }, [croppedAreaPixels, image, imageType, onCrop]);
  
  return (
    <Dialog
      fullWidth
      maxWidth="sm"
      disableEscapeKeyDown
      open={open}
      onClose={onCloseDialog}
    >
      <DialogTitle>
        {title || <FormattedMessage id="image.cropdialog.title" />}
      </DialogTitle>

      <DialogContent>
        <Grid container direction="column">
          <Box
            className={classes.cropContainer}
            display="flex"
            position="relative"
            borderRadius="5px"
            overflow="hidden"
          >
            {image ? <Cropper
              aspect={1}
              image={image}
              crop={crop}
              zoom={zoom}
              onCropChange={setCrop}
              onZoomChange={setZoom}
              onCropComplete={onCropComplete}
              // onMediaLoaded={(mediaSize) => {
              //   // Adapt zoom based on media size to fit max height
              //   // setCrop({x: 0, y: 0});
              //   setZoom(CONTAINER_HEIGHT / mediaSize.naturalHeight)
              // }}
            />:null}
          </Box>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button color="secondary" variant="text" onClick={onCloseDialog}>
          <FormattedMessage id="cancel" />
        </Button>
        <HSpacer small />
        <Button
          variant="contained"
          onClick={() => {            
            setLoading(true);
            endCropImage();
          }}
        >
          <>
            <FormattedMessage id="ok" />
            {loading ? <CircularProgress size={16} /> : null}
          </>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default withStyles(styles)(ImageCropDialog);
