import { calculateTimeLeft } from '../../helpers/TimeDistance';
import eventsPanel from '../../services/events';
import { deleteQuery, get, post, put } from '../../services/requests';
import constants from '../constants';
import store from '../store/index';
import { getOrganization } from './Organization';
import { details } from './User';

export const getSubscription = () => {
  return (dispatch: any) => {
    return get(`/subscription`, undefined, {
      Authorization: `Bearer ${store.getState().user.token}`,
    })
      .then((response) => {
        dispatch({
          type: constants.Payment.PAYMENT_GET_SUBSCRIPTION_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: constants.Payment.PAYMENT_GET_SUBSCRIPTION_ERROR,
          payload: error,
        });
      });
  };
};

export const getSubscriptionCard = () => {
  return (dispatch: any) => {
    return get(`/subscription/card`, undefined, {
      Authorization: `Bearer ${store.getState().user.token}`,
    })
      .then((response) => {
        dispatch({
          type: constants.Payment.PAYMENT_GET_SUBSCRIPTION_CARD_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: constants.Payment.PAYMENT_GET_SUBSCRIPTION_CARD_ERROR,
          payload: error,
        });
      });
  };
};

export const postPaymendCardToken = (token: any) => {
  return (dispatch: any) => {
    return post(
      `/subscription/token`,
      { token },
      { Authorization: `Bearer ${store.getState().user.token}` }
    )
      .then((response) => {
        dispatch({
          type: constants.Payment.PAYMENT_POST_PAYMENT_METHOD_SUCCESS,
          payload: response.data,
        });
        dispatch(getSubscriptionCard());
      })
      .catch((error) => {
        dispatch({
          type: constants.Payment.PAYMENT_POST_PAYMENT_METHOD_ERROR,
          payload: error,
        });
      });
  };
};

export const getBillingAddress = () => {
  return (dispatch: any) => {
    return get(`/subscription/billing-address`, undefined, {
      Authorization: `Bearer ${store.getState().user.token}`,
    })
      .then((response) => {
        dispatch({
          type: constants.Payment.PAYMENT_GET_BILLING_ADDRESS_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: constants.Payment.PAYMENT_GET_BILLING_ADDRESS_ERROR,
          payload: error,
        });
      });
  };
};

export const postBillingAddress = (info: any) => {
  return (dispatch: any) => {
    return post(
      `/subscription/billing-address`,
      { ...info },
      { Authorization: `Bearer ${store.getState().user.token}` }
    )
      .then((response) => {
        dispatch({
          type: constants.Payment.PAYMENT_POST_BILLING_ADDRESS_SUCCESS,
          payload: response.data,
        });
        dispatch(getBillingAddress());
      })
      .catch((error) => {
        dispatch({
          type: constants.Payment.PAYMENT_POST_BILLING_ADDRESS_ERROR,
          payload: error,
        });
      });
  };
};

export const postSubscriptionRestore = () => {
  return (dispatch: any) => {
    return post(
      `/subscription/plan/reactivate`,
      {},
      { Authorization: `Bearer ${store.getState().user.token}` }
    )
      .then((response) => {
        dispatch({
          type: constants.Payment.PAYMENT_GET_SUBSCRIPTION_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: constants.Payment.PAYMENT_POST_SUBSCRIPTION_PLAN_ERROR,
          payload: error,
        });
      });
  };
};

export const cancelSubscriptionPlan = (reasons: any) => {
  return (dispatch: any) => {
    return deleteQuery(`/subscription/plan`, {
      Authorization: `Bearer ${store.getState().user.token}`,
    })
      .then((response) => {
        dispatch({
          type: constants.Payment.PAYMENT_CANCEL_SUBSCRIPTON_PLAN_SUCCESS,
          payload: response.data,
        });

        const cancelReasons = Object.keys(reasons).map((key) => {
          if (typeof reasons[key] === 'string') {
            return reasons[key];
          }
          if (reasons[key] === true) {
            return key;
          }
          return null;
        });

        eventsPanel.cancelSubscriptionPlan(
          cancelReasons.filter((item) => item !== null),
          response.data.plan.type
        );

        dispatch(details());
        dispatch(getOrganization());
      })
      .catch((error) => {
        dispatch({
          type: constants.Payment.PAYMENT_CANCEL_SUBSCRIPTON_PLAN_ERROR,
          payload: error,
        });
      });
  };
};

export const manageSubscriptionPlan = (plan: any, vehicles: any) => {
  const currentOrganization = store.getState().organization.organization;

  return (dispatch: any) => {
    return put(
      `/subscription/plan`,
      { plan, vehicles },
      { Authorization: `Bearer ${store.getState().user.token}` }
    )
      .then(() => {
        eventsPanel.changeSubscriptionPlan(
          currentOrganization.subscription.plan.type,
          plan,
          currentOrganization.subscription.plan.vehicles,
          vehicles,
          currentOrganization &&
            currentOrganization.subscription.status === 'trialing' &&
            calculateTimeLeft(currentOrganization.subscription.valid) > 0
            ? calculateTimeLeft(currentOrganization.subscription.valid)
            : 0
        );

        dispatch(details());
        dispatch(getOrganization());
      })
      .catch((error) => {
        dispatch({
          type: constants.Payment.PAYMENT_MANAGE_SUBSCRIPTON_PLAN_ERROR,
          payload: error,
        });
      });
  };
};

export const deletePlan = () => {
  return { type: constants.Payment.DELETE_PLAN };
};
