import store from '../store/index';
import constants from '../constants';
import { User } from './index';

export const initialize = (tutorialId: any, initialStep: any) => {
  return (dispatch: any) => {
    dispatch({
      type: constants.Tutorial.TUTORIAL_INITIALIZE,
      payload: {
        id: tutorialId,
        step: initialStep,
      },
    });
  };
};

export const setStep = (tutorialId: any, step: any) => {
  return (dispatch: any) => {
    const tutorialIsActive = store.getState().tutorial.isActive;

    if (tutorialIsActive) {
      const userGuide = store.getState().user.user.guide;
      const newUserGuide = { ...userGuide };
      newUserGuide.tutorials[tutorialId].current_step = step;

      dispatch(User.editGuide(newUserGuide));

      dispatch({
        type: constants.Tutorial.TUTORIAL_SET_STEP,
        payload: newUserGuide.tutorials[tutorialId],
      });
    }
  };
};

export const end = (tutorialId: any) => {
  return (dispatch: any) => {
    const tutorialIsActive = store.getState().tutorial.isActive;

    if (tutorialIsActive) {
      const userGuide = store.getState().user.user.guide;
      const newUserGuide = { ...userGuide };
      newUserGuide.tutorials[tutorialId].is_finished = true;

      dispatch(User.editGuide(newUserGuide));

      dispatch({
        type: constants.Tutorial.TUTORIAL_END,
        payload: { tutorialId },
      });
    }
  };
};
