import { Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Button, VSpacer } from '@sm-highway-web/react-components';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import WarningIcon from '../../../assets/svg/WarningIcon';
import PaymentDialog from '../../../dialogs/PaymentDialog';
import { IOrganizationData } from '../../../interfaces/organizations';
import { Common, Payment } from '../../../redux/actions';
import CardInfo from '../../CardInfo';
import FormattedMessage from '../../FormattedMessageCustom';

const styles = {
  enclosedDiv: {
    padding: '16px',
    width: '560px',
    border: '1px solid #8194B8',
    borderRadius: '8px',
    backgroundColor: 'rgba(175,192,224,0.05)',
    boxShadow: '0 2px 4px 0 ·#EAEAEA',
  },
  noPaymentDiv: {
    border: '1px solid #FCCA46',
    borderRadius: '8px',
    backgroundColor: 'rgba(248,231,28,0.1)',
    padding: '8px',
  },
};

type OwnAccountPaymentMethodProps = {
  classes: {
    enclosedDiv: string;
    noPaymentDiv: string;
  };
  actions: {
    getSubscription: (...args: any[]) => any;
    getSubscriptionCard: (...args: any[]) => any;
    postPaymendCardToken: (...args: any[]) => any;
    openPaymentModal: (...args: any[]) => any;
  };
  organization?: IOrganizationData;
  card?: {
    brand?: string;
    name?: string;
    last4?: string;
    'exp_month'?: number;
    'exp_year'?: number;
  };
};

type AccountPaymentMethodState = any;

type AccountPaymentMethodProps = OwnAccountPaymentMethodProps;

class AccountPaymentMethod extends Component<AccountPaymentMethodProps, AccountPaymentMethodState> {
  static defaultProps: any;

  state = {
    paymentModal: false,
  };

  componentDidMount = () => {
    const { actions } = this.props;
    actions.getSubscriptionCard();
  };

  componentDidUpdate = (prevProps: any) => {
    const { organization, actions } = this.props;
    if (!prevProps.organization && organization) {
      actions.getSubscriptionCard();
    }
  };

  handleOpenPaymentModal = () => {
    this.setState((prevState: any) => ({
      paymentModal: !prevState.paymentModal,
    }));
  };

  handleSubmitSubscription = (token: any) => {
    const { actions } = this.props;
    this.setState({ paymentModal: false });
    actions.postPaymendCardToken(token);
  };

  handleOpenRunway = () => {
    const { actions } = this.props;
    actions.openPaymentModal();
  };

  render() {
    const { classes, card } = this.props;
    const { paymentModal } = this.state;
    return (
      <>
        <Grid container direction="column">
          <Grid item>
            <Typography variant="h2">
              <FormattedMessage id="settings.billing.payment.title" />
            </Typography>
          </Grid>
          <VSpacer />
          {!card ? (
            <>
              <Grid container>
                <Grid item className={classes.noPaymentDiv} sm={5}>
                  <WarningIcon style={{ float: 'left', marginRight: '16px', color: '#FCCA46' }} />
                  <Typography style={{ float: 'left' }}>
                    <FormattedMessage id="settings.billing.payment.nopayment" />
                  </Typography>
                </Grid>
              </Grid>
              <VSpacer />
              <Grid container>
                <Button variant="contained" onClick={this.handleOpenRunway}>
                  <FormattedMessage id="userprofilesummary.upgradeaccount" />
                </Button>
              </Grid>
            </>
          ) : (
            <Grid item>
              <Grid container className={classes.enclosedDiv} direction="column">
                <CardInfo card={card} />
                <VSpacer />
                <Grid item>
                  <Grid container justify="flex-end">
                    <Button
                      onClick={() => {
                        this.handleOpenPaymentModal();
                      }}
                    >
                      {card ? (
                        <FormattedMessage id="settings.billing.payment.buttonchangepayment" />
                      ) : (
                        <FormattedMessage id="settings.billing.payment.buttonaddpayment" />
                      )}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          )}
        </Grid>
        {/* MODAL PAYMENT */}
        <PaymentDialog
          open={paymentModal}
          handleOpenCloseDialog={this.handleOpenPaymentModal}
          handleSubmitSubscription={this.handleSubmitSubscription}
        />
      </>
    );
  }
}

AccountPaymentMethod.defaultProps = {
  organization: undefined,
  card: undefined,
};

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    getSubscription: () => dispatch(Payment.getSubscription()),
    getSubscriptionCard: () => dispatch(Payment.getSubscriptionCard()),
    postPaymendCardToken: (token: any) => dispatch(Payment.postPaymendCardToken(token)),
    openPaymentModal: () => dispatch(Common.openPaymentModal()),
  },
});

const mapStateToProps = (state: any) => {
  return { organization: state.organization.organization, card: state.payment.card };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
  null
)(withStyles(styles)(AccountPaymentMethod));
