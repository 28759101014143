import constants from '../constants/index';

const initialState = {
  organization: undefined,
  users: [],
  webhooks: undefined,
};

function organization(state = initialState, action: any) {
  let currentUser;
  switch (action.type) {
    case constants.Organization.ORGANIZATION_GET_WEBHOOKS_SUCCESS:
      return { ...state, webhooks: action.payload };
    case constants.Payment.PAYMENT_GET_SUBSCRIPTION_SUCCESS:
      // @ts-expect-error ts-migrate(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
      return { ...state, organization: { ...state.organization, subscription: action.payload } };
    case constants.Organization.ORGANIZATION_DETAILS_SUCCESS:
      return {
        ...state,
        users: action.payload.users,
        organization: action.payload.organization,
      };
    case constants.Organization.ORGANIZATION_EDIT_SUCCESS:
      // @ts-expect-error ts-migrate(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
      return { ...state, organization: { ...state.organization, ...action.payload } };
    case constants.Organization.ORGANIZATION_NEW_USER_SUCCESS:
      return { ...state, users: [...state.users, action.payload] };
    case constants.Organization.ORGANIZATION_RESEND_INVITATION_SUCCESS:
      // TODO
      return state;
    case constants.Organization.ORGANIZATION_RESTORE_USER_SUCCESS:
      // Updates the users array updating the restored user.
      return {
        ...state,
        users: [
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'user_id' does not exist on type 'never'.
          ...state.users.filter((user) => user.user_id !== action.payload.user_id),
          {
            ...action.payload,
          },
        ],
      };
    case constants.Organization.ORGANIZATION_DELETE_USER_SUCCESS:
      if (action.payload.solftDelete) {
        // Getting the current saved user.
        currentUser = state.users.find((user: any) => user.user_id === action.payload.user_id);

        // Updates the users array setting the deleted user with deleted true.
        return {
          ...state,
          users: [
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'user_id' does not exist on type 'never'.
            ...state.users.filter((user) => user.user_id !== action.payload.user_id),
            {
              ...action.payload,
            },
          ],
        };
      }
      return {
        ...state,
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'user_id' does not exist on type 'never'.
        users: [...state.users.filter((user) => user.user_id !== action.payload.user_id)],
      };

    case constants.Organization.ORGANIZATION_EDIT_USER_SUCCESS:
      // Getting the current saved user.
      currentUser = state.users.find((user: any) => user.user_id === action.payload.user_id);

      // Updates the users array setting the deleted user with deleted true.
      return {
        ...state,
        users: [
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'user_id' does not exist on type 'never'.
          ...state.users.filter((user) => user.user_id !== action.payload.user_id),
          {
            // @ts-expect-error ts-migrate(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
            ...currentUser,
            role: action.payload.role,
          },
        ],
      };
    case constants.User.USER_LOGOUT:
      return initialState;
    case constants.Organization.ORGANIZATION_UPLOAD_AVATAR_SUCCESS:
      return {
        ...state,
        organization: {
          // @ts-expect-error ts-migrate(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
          ...state.organization,
          avatar: action.payload.id,
          avatar_url: action.payload.avatar_url,
          avatar_url_public: action.payload.avatar_url_public,
        },
      };
    case constants.Organization.ORGANIZATION_REMOVE_AVATAR_SUCCESS:
      return {
        ...state,
        // @ts-expect-error ts-migrate(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
        organization: { ...state.organization, avatar: undefined, avatar_url: undefined },
      };
    default:
      return state;
  }
}

export default organization;
