import { Badge, withStyles } from '@material-ui/core';
import memoize from 'memoize-one';
import React from 'react';
import { Draggable, Droppable } from 'react-beautiful-dnd';
import AutoSizer from 'react-virtualized-auto-sizer';
import { areEqual, FixedSizeList as List } from 'react-window';
import { getItemStyle, styles } from '../../../../assets/css/PlanRouteMapCss';
import ItemList from './ItemList';
import ItemListMissing from './ItemListMissing';

function getStyle({ provided, style, isDragging }: any) {
  // If you don't want any spacing between your items
  // then you could just return this.
  // I do a little bit of magic to have some nice visual space
  // between the row items
  const combined = {
    ...style,
    ...provided.draggableProps.style,
    ...getItemStyle(isDragging, style),
  };

  const marginBottom = 8;

  return {
    ...combined,
    height: combined.height - marginBottom,
    // width: 'auto'
  };
}

type OwnItemProps = {
  classes: any;
  item: any;
  provided: any;
  style?: any;
  index: number;
  panel: number;
  type: 'missing' | 'normal';
  selectedPoi: string[];
  onClickPoi: (...args: any[]) => any;
  onDoubleClickPoi: (...args: any[]) => any;
  selectedDiv: any;
  routeSelected?: number;
  isDragging?: boolean;
  route?: any;
};

type ItemProps = OwnItemProps;

const Item = ({
  provided,
  item,
  style,
  isDragging,
  classes,
  index,
  type,
  panel,
  route,
  routeSelected,
  selectedPoi,
  onClickPoi,
  onDoubleClickPoi,
  selectedDiv,
}: ItemProps) => (
  <div
    ref={provided.innerRef}
    {...provided.draggableProps}
    {...provided.dragHandleProps}
    style={getStyle({ provided, style, isDragging })}
  >
    <Badge
      className={classes.badge}
      badgeContent={isDragging && selectedPoi.length > 1 ? selectedPoi.length : undefined}
      color="primary"
      classes={{ colorPrimary: classes.white }}
      style={{ width: '100%' }}
    >
      {type === 'missing' ? (
        <ItemListMissing
          step={item}
          idx={index}
          panel={panel}
          selectedPoi={selectedPoi}
          onClickPoi={onClickPoi}
          onDoubleClickPoi={onDoubleClickPoi}
          selectedDiv={selectedDiv}
        />
      ) : (
        <ItemList
          step={item}
          idx={index}
          panel={panel}
          route={route}
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
          routeSelected={routeSelected}
          selectedPoi={selectedPoi}
          onClickPoi={onClickPoi}
          onDoubleClickPoi={onDoubleClickPoi}
          selectedDiv={selectedDiv}
        />
      )}
    </Badge>
  </div>
);

Item.defaultProps = {
  route: undefined,
  isDragging: false,
  routeSelected: 0,
  style: {},
};

// @ts-expect-error ts-migrate(2345) FIXME: Argument of type '() => { divMap: { position: stri... Remove this comment to see the full error message
const StledItem = withStyles(styles)(Item);

const Row = React.memo(function Row(props) {
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'data' does not exist on type '{ children... Remove this comment to see the full error message
  const { data, index, style } = props;
  const item = data.items[index];

  return (
    <Draggable
      isDragDisabled={data.isDragDisabled}
      draggableId={`${data.panel}-draggable-${data.type}-${item.id}`}
      index={index}
      key={item.id}
    >
      {(provided: any) => (
        <StledItem
          provided={provided}
          item={item}
          style={{ ...style, width: 'calc(100% - 16px)' }}
          isDragging={false}
          classes={{}}
          index={index}
          type={data.type}
          panel={data.panel}
          route={data.route}
          routeSelected={data.routeSelected}
          selectedPoi={data.selectedPoi}
          onClickPoi={data.onClickPoi}
          onDoubleClickPoi={data.onDoubleClickPoi}
          selectedDiv={data.selectedDiv}
        />
      )}
    </Draggable>
  );
}, areEqual);

const reorderMissing = (array: any) => {
  array.sort((a: any, b: any) => (a.type === 'pickup' && b.type === 'delivery' ? -1 : 1));
  return array;
};

const MIN_TABLE_WIDTH = 400;

const createItemData = memoize(
  (
    items,
    type,
    panel,
    route,
    isDragDisabled,
    routeSelected,
    selectedPoi,
    onClickPoi,
    onDoubleClickPoi,
    selectedDiv
  ) => ({
    items,
    type,
    panel,
    route,
    isDragDisabled,
    routeSelected,
    selectedPoi,
    onClickPoi,
    onDoubleClickPoi,
    selectedDiv,
  })
);

type OwnVirtualizedDraggableListProps = {
  id: string;
  panel: number;
  listItems: {}[];
  type: 'missing' | 'normal';
  selectedPoi: string[];
  onClickPoi: (...args: any[]) => any;
  onDoubleClickPoi: (...args: any[]) => any;
  selectedDiv: any;
  routeSelected?: number;
  isDragDisabled?: boolean;
  route?: any;
};

type VirtualizedDraggableListProps = OwnVirtualizedDraggableListProps;

const VirtualizedDraggableList = ({
  id,
  isDragDisabled,
  listItems,
  type,
  panel,
  selectedPoi,
  onClickPoi,
  onDoubleClickPoi,
  selectedDiv,
  routeSelected,
  route,
}: VirtualizedDraggableListProps) => {
  let items = listItems;
  if (type === 'missing') {
    items = reorderMissing(listItems);
  }

  const itemData = createItemData(
    items,
    type,
    panel,
    route,
    isDragDisabled,
    routeSelected,
    selectedPoi,
    onClickPoi,
    onDoubleClickPoi,
    selectedDiv
  );

  return (
    <div style={{ height: '100%' }}>
      <Droppable
        droppableId={id}
        mode="virtual"
        renderClone={(provided: any, snapshot: any, rubric: any) => (
          <StledItem
            provided={provided}
            item={items[rubric.source.index]}
            style={provided.style}
            isDragging={snapshot.isDragging}
            classes={{}}
            index={rubric.source.index}
            type={type}
            panel={panel}
            route={route}
            routeSelected={routeSelected}
            selectedPoi={selectedPoi}
            onClickPoi={onClickPoi}
            onDoubleClickPoi={onDoubleClickPoi}
            selectedDiv={selectedDiv}
          />
        )}
      >
        {(provided: any, snapshot: any) => (
          <div
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={{
              borderRadius: '4px',
              minHeight: 'calc(100% - 13px)',
              padding: '8px 0px 1px 0px',
              backgroundColor: snapshot.isDraggingOver ? 'rgba(255,183,0,0.4)' : 'transparent',
            }}
          >
            <AutoSizer>
              {({ height, width }: any) => (
                <List
                  itemCount={items.length}
                  itemSize={64 + 8}
                  height={height}
                  width={width < MIN_TABLE_WIDTH ? MIN_TABLE_WIDTH : width}
                  outerRef={provided.innerRef}
                  itemData={itemData}
                >
                  {Row}
                </List>
              )}
            </AutoSizer>
          </div>
        )}
      </Droppable>
    </div>
  );
};

VirtualizedDraggableList.defaultProps = {
  isDragDisabled: false,
  routeSelected: 0,
  route: undefined,
};

// @ts-expect-error ts-migrate(2345) FIXME: Argument of type '() => { divMap: { position: stri... Remove this comment to see the full error message
export default withStyles(styles)(VirtualizedDraggableList);
