export const ROUTES_UPDATE_ROUTE = 'ROUTES_UPDATE_ROUTE';
export const ROUTES_UPDATE_ROUTE_SUCCESS = 'ROUTES_UPDATE_ROUTE_SUCCESS';
export const ROUTES_UPDATE_ROUTE_ERROR = 'ROUTES_UPDATE_ROUTE_ERROR';

export const ROUTES_OPTIMIZE_ROUTE = 'ROUTES_OPTIMIZE_ROUTE';
export const ROUTES_OPTIMIZE_ROUTE_SUCCESS = 'ROUTES_OPTIMIZE_ROUTE_SUCCESS';
export const ROUTES_OPTIMIZE_ROUTE_ERROR = 'ROUTES_OPTIMIZE_ROUTE_ERROR';

export const ROUTES_DELETE_ROUTE = 'ROUTES_DELETE_ROUTE';
export const ROUTES_DELETE_ROUTE_SUCCESS = 'ROUTES_DELETE_ROUTE_SUCCESS';
export const ROUTES_DELETE_ROUTE_ERROR = 'ROUTES_DELETE_ROUTE_ERROR';

export const ROUTES_UPDATE_ROUTES = 'ROUTES_UPDATE_ROUTES';
export const ROUTES_UPDATE_ROUTES_SUCCESS = 'ROUTES_UPDATE_ROUTES_SUCCESS';
export const ROUTES_UPDATE_ROUTES_ERROR = 'ROUTES_UPDATE_ROUTES_ERROR';

export const ROUTES_SET_TABLE_COLUMNS = 'ROUTES_SET_TABLE_COLUMNS';

export const ROUTES_DEFAULT_TABLE_COLUMNS : { [key: string]: boolean } = {
  icon: true,
  label: true,
  alerts: true,
  status: true,
  services: true,
  start_location: true,
  timewindow: true,
  duration: true,
  total_distance: true,
  max_weight: true,
  max_volume: true,
  external_id: false,
  plate: false,
  comments: false,
  email: false,
  phone: false,
  webpage: false,
  total_price: false,
  custom_fields: false,
};
