import { Grid, MenuItem, Popover } from '@material-ui/core';
import { PopoverOrigin } from '@material-ui/core/Popover';
import { withStyles } from '@material-ui/core/styles';
import { Button } from '@sm-highway-web/react-components';
import { IClientData } from 'highway-api/dist/common/interfaces/clients';
import { IProjectData } from 'highway-api/dist/common/interfaces/projects';
import React, { useState } from 'react';
import More3Points from '../../assets/svg/More3Points';
import CustomFields from '../custom-fields/CustomFields';
import FormIcon from '../form-fields/FormIcon';
import FormText from '../form-fields/FormText';
import FormattedMessage from '../FormattedMessageCustom';
import Block from './Block';
import {
  checkR,
  popoverConfig,
  renderArray,
  renderFormText,
  renderFormTextArea,
  renderLocation,
  renderMinutes,
  renderTimewindows,
  styles,
} from './Common';

type ClientHeaderProps = {
  classes: {
    slimbutton: string;
    menuItem: string;
  };
  data: IClientData;
  onUpdate?: (...args: any[]) => any;
  options?: {
    handleDeleteClient: (...args: any[]) => any;
  };
};

export const ClientHeader = withStyles(styles)(({ data, onUpdate, options, classes }: ClientHeaderProps) => {
  const [anchorEl, setAnchorEl] = useState(null);
  if (data) {
    return (
      <>
        <Grid item>
          <FormIcon
            fieldValue={data.icon}
            onUpdate={(icon: any) => {
              if(onUpdate)onUpdate(data.id, { icon });
            }}
          />
        </Grid>
        {options && (
          <Grid item>
            <Button
              aria-describedby={anchorEl !== null ? 'simple-popover' : undefined}
              color="secondary"
              variant="text"
              className={classes.slimbutton}
              onClick={(e: any) => setAnchorEl(e.currentTarget)}
            >
              <More3Points />
            </Button>
            <Popover
              id={anchorEl !== null ? 'simple-popover' : undefined}
              open={Boolean(anchorEl)}
              anchorEl={anchorEl}
              onClose={() => setAnchorEl(null)}
              anchorOrigin={popoverConfig.anchorOrigin as PopoverOrigin}
              transformOrigin={popoverConfig.transformOrigin as PopoverOrigin}
            >
              <MenuItem
                onClick={() => {
                  options.handleDeleteClient();
                  setAnchorEl(null);
                }}
                className={classes.menuItem}
              >
                <FormattedMessage id="rightpanel.delete" />
              </MenuItem>
            </Popover>
          </Grid>
        )}
      </>
    );
  }
  return null;
});

type ClientProps = {
  classes: {
    box: string;
  };
  data: IClientData;
  onUpdate?: (...args: any[]) => any;
  activeProject: IProjectData;
  countryCode?: string | null;
};

const Client = withStyles(styles)(({ data, onUpdate, classes, activeProject, countryCode } : ClientProps) => {
  const handleUpdate = (label: string, val: any) => {
    if (onUpdate) {
      onUpdate(data.id, { [label]: val });
    }
  };
  const preffix = 'clients.data.details';
  const { custom_fields: customFields, units, view } = activeProject;
  // @ts-expect-error
  const { constraints, client } = view;
  return (
    <>
      <Grid container className={classes.box}>
        <FormText
          variant="title"
          fieldValue={data.label}
          handleEnter={(val: any) => {
            handleUpdate('label', val);
          }}
        />
        {checkR(client.external_id) &&
          renderFormText(preffix, 'external_id', undefined, handleUpdate, data)}
        {renderLocation(preffix, 'location', handleUpdate, data, countryCode)}
        {checkR(client.location_details) &&
          renderFormText(preffix, 'location_details', undefined, handleUpdate, data)}
      </Grid>
      <Block title={<FormattedMessage id="clients.details.group.constraints" />}>
        {checkR(constraints.weight) &&
          renderFormText(
            preffix,
            'default_weight',
            'number',
            handleUpdate,
            data,
            units?.weight
          )}
        {renderMinutes(preffix, 'default_duration', handleUpdate, data)}
        {checkR(constraints.volume) &&
          renderFormText(
            preffix,
            'default_volume',
            'number',
            handleUpdate,
            data,
            units?.volume
          )}
        {checkR(constraints.timewindows) &&
          renderTimewindows(preffix, 'default_timewindows', handleUpdate, data)}
        {checkR(constraints.provide_requires) &&
          renderArray(preffix, 'default_requires', handleUpdate, data)}
      </Block>
      <Block title={<FormattedMessage id="clients.details.group.contact" />}>
        {checkR(client.reference_person) &&
          renderFormText(preffix, 'reference_person', undefined, handleUpdate, data)}
        {checkR(client.phone) && renderFormText(preffix, 'phone', undefined, handleUpdate, data)}
        {renderFormText(preffix, 'email', 'email', handleUpdate, data)}
        {checkR(client.webpage) && renderFormText(preffix, 'website', 'url', handleUpdate, data)}
        {checkR(client.comments) &&
          renderFormTextArea(preffix, 'comments', undefined, handleUpdate, data)}
      </Block>

      {customFields && customFields.client.length > 0 ? (
        <Block title={<FormattedMessage id="custom_fields.title" />}>
          <CustomFields
            onUpdate={(id: any, value: any) => handleUpdate('custom_fields', { [id]: value })}
            customFields={data.custom_fields}
            projectCustomFields={customFields.client}
          />
        </Block>
      ) : null}
    </>
  );
});

export default withStyles(styles)(Client);
