import { Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Button, VSpacer } from '@sm-highway-web/react-components';
// ASSETS
import { Common } from '../redux/actions';
import FormattedMessage from './FormattedMessageCustom';

const styles = {};

type OwnShallNotPassComponentProps = {
  classes: any;
  actions: {
    openPaymentModal: (...args: any[]) => any;
  };
};

type ShallNotPassComponentProps = OwnShallNotPassComponentProps;

class ShallNotPassComponent extends PureComponent<ShallNotPassComponentProps> {
  static defaultProps = {};

  handleOpenRunway = () => {
    const { actions } = this.props;
    actions.openPaymentModal();
  };

  render() {
    return (
      <Grid container justify="center">
        <Grid item md={4}>
          <Grid container direction="column">
            <VSpacer />
            <VSpacer />
            <Typography variant="h1" align="center">
              <FormattedMessage id="shallnotpass.title" />
            </Typography>
            <VSpacer />
            <VSpacer />
            <Typography variant="h3" align="center">
              <FormattedMessage id="shallnotpass.subtitle" />
            </Typography>
            <VSpacer />
            <VSpacer />
            <Grid container justify="center">
              <Grid item md={8}>
                <Button variant="contained" onClick={this.handleOpenRunway} extraFontSize fullWidth>
                  <FormattedMessage id="shallnotpass.button" />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    openPaymentModal: () => dispatch(Common.openPaymentModal()),
  },
});

export default connect(null, mapDispatchToProps)(withStyles(styles)(ShallNotPassComponent));
