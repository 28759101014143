import { Dialog, DialogActions, DialogContent, DialogContentText, Grid } from '@material-ui/core';
import React from 'react';
import { Button } from '@sm-highway-web/react-components';
import styled from 'styled-components';
import FloatingLogo from '../assets/animations/FloatingLogo';
import FormattedMessage from '../components/FormattedMessageCustom';

const LinkButton = styled.button`
  border: none;
  color: #aaaaaa;
  text-decoration: underline;
  outline: none;
  cursor: pointer;
  font-size: 12px;
`;

const Title = styled.h2`
  color: #333940;
  font-size: 40px;
  text-align: center;
  font-weight: 'bold';
  text-transform: uppercase;
`;

const Info1 = styled(DialogContentText)`
  font-size: 20px;
  line-height: 1.5em;
`;

const Info2 = styled(DialogContentText)`
  font-size: 18px;
  font-weight: 400;
  color: #5a6b86;
  text-align: center;
  max-width: 400px;
  line-height: 1.2em;
`;

type Props = {
  open: boolean;
  step: number;
  onSkip: (...args: any[]) => any;
  onNextStep: (...args: any[]) => any;
  onClose: (...args: any[]) => any;
};

const OnboardingTutorialDialog = ({ open, step, onSkip, onNextStep, onClose }: Props) => (
  // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
  <Dialog open={open} closable="false" onClose={onClose}>
    <Title>
      <FormattedMessage id={`tutorials.onboarding.step-${step}.title`} />
    </Title>

    <DialogContent>
      <Grid container direction="column">
        <Grid container direction="row" justify="center" alignItems="center" wrap="nowrap">
          <FloatingLogo />
          <Info1>
            <FormattedMessage
              id={`tutorials.onboarding.step-${step}.info1`}
              values={{
                orange_span: (chunks: any) => <span style={{ color: '#FF8200' }}>{chunks}</span>,
              }}
            />
          </Info1>
        </Grid>

        <Grid
          container
          direction="row"
          justify="center"
          alignItems="center"
          style={{ margin: '10px 0' }}
        >
          <Info2>
            <FormattedMessage
              id={`tutorials.onboarding.step-${step}.info2`}
              values={{
                youtube_training_link: (chunks: any) => (
                  <a
                    style={{ color: '#FF8200' }}
                    href="https://www.youtube.com/channel/UCWQsHUjPbmt0BqqhkRM8m3A"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {chunks}
                  </a>
                ),
              }}
            />
          </Info2>
        </Grid>
      </Grid>
    </DialogContent>

    <DialogActions style={{ justifyContent: 'space-between', padding: '24px' }}>
      <LinkButton onClick={onSkip}>
        <FormattedMessage id="tutorials.skip" />
      </LinkButton>
      <Button variant="contained" onClick={onNextStep} color="primary">
        <FormattedMessage id={`tutorials.onboarding.step-${step}.done-button`} />
      </Button>
    </DialogActions>
  </Dialog>
);

export default OnboardingTutorialDialog;
