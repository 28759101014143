export const blobToDataURL = async(blob: any) : Promise<string | undefined> => {
  return new Promise((resolve: any) => {
    const freader = new FileReader();
    freader.onload = (e) => {
      resolve(e.target?.result);
    };
    freader.readAsDataURL(blob);
  });
};

export default {};
