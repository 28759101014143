import { Icon, Typography } from '@material-ui/core';
import { SideBar } from '@sm-highway-web/react-components';
import { IProjectData } from 'highway-api/dist/common/interfaces/projects';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import AddProjectIcon from '../assets/img/AddProjectIcon.svg';
import BugIcon from '../assets/img/bug.svg';
import ChatIcon from '../assets/img/chat.svg';
import Logo from '../assets/img/logo.svg';
import ProjectIcon from '../assets/img/ProjectIcon.svg';
import SupportIcon from '../assets/img/support.svg';
import * as routing from '../constants/Routing';
import PaymentRunwayDialog from '../dialogs/PaymentRunwayDialog';
import history from '../helpers/History';
import { IOrganizationData } from '../interfaces/organizations';
import { Common, Organization, Projects, User } from '../redux/actions';
import { projectHelpers, userHelpers } from '../redux/helpers';
import Froged from '../services/froged';
import Usersnap from '../services/usersnap';
import Avatar from './Avatar';
import FormattedMessage from './FormattedMessageCustom';
import UserProfileSummary from './UserProfileSummary';

type OwnLeftBarProps = {
  actions: {
    logout: (...args: any[]) => any;
    details: (...args: any[]) => any;
    getOrganization: (...args: any[]) => any;
    getProject: (...args: any[]) => any;
    getProjects: (...args: any[]) => any;
    closePaymentModal: (...args: any[]) => any;
    backToRoot: (...args: any[]) => any;
  };
  user?: any;
  organization: IOrganizationData;
  projectsCallback?: (...args: any[]) => any;
  projects?: IProjectData[];
  token?: string;
  paymentRunwayModal: boolean;
  asUser?: string;
  menuItems?: {}[];
  activeProject: IProjectData;
};

type LeftBarProps = OwnLeftBarProps;

const LeftBar = ({
  actions,
  paymentRunwayModal,
  user,
  asUser,
  menuItems,
  organization,
  projects,
  activeProject,
  token,
  projectsCallback,
}: LeftBarProps) => {
  const [inspectedUser, setInspectedUser] = useState(undefined);

  useEffect(() => {
    if ((!user && token) || asUser !== inspectedUser) {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
      setInspectedUser(asUser);
      (async () => {
        await actions.details(!user && token ? token : undefined);
        await actions.getOrganization(!user && token ? token : undefined);
        await actions.getProjects();
      })();
    }
  }, [asUser, actions, user, token, inspectedUser, setInspectedUser]);

  const handleCloseAsUser = () => {
    history.push(routing.ADMINISTRATION.USERS);
    actions.backToRoot();
  };

  const handleLogOut = () => {
    actions.logout();
    history.push(routing.LOGOUT);
  };

  const helpActions = [
    {
      id: 'help.support',
      name: 'help.support',
      labelOverlayed: false,
      label: (
        <div style={{ width: '100%' }}>
          <Typography align="center">
            <FormattedMessage id="help.support" />
          </Typography>
        </div>
      ),
      avatar: SupportIcon,
      actionHandler: () => {
        window.open(routing.EXTERNAL.DOCS);
      },
    },
    {
      id: 'help.chat',
      name: 'help.chat',
      labelOverlayed: false,
      label: (
        <div style={{ width: '100%' }}>
          <Typography align="center">
            <FormattedMessage id="help.chat" />
          </Typography>
        </div>
      ),
      avatar: ChatIcon,
      actionHandler: () => {
        Froged.open();
      },
    },
    ...user ? 
        [{
          id: 'help.bug',
          name: 'help.bug',
          labelOverlayed: false,
          label: (
            <div style={{ width: '100%' }}>
              <Typography align="center">
                <FormattedMessage id="help.bug" />
              </Typography>
            </div>
          ),
          avatar: BugIcon,
          actionHandler: () => {
            Usersnap.open(user.id, user.email);
          },
        }] : [],
  ];
  
  const projectActions = [
    ...(user && projects
      ? [
          ...(projects.length !== 0
            ? [
                ...projects.map((project: IProjectData) => ({
                  id: `${project.id}`,

                  name: `${projectHelpers
                    .getProjectLabel(project.label, organization.label)
                    .toLowerCase()}`,

                  label: `${projectHelpers.getProjectLabel(project.label, organization.label)}`,

                  avatar: (
                    <Avatar
                      data={project as any}
                      label={`${projectHelpers.getProjectLabel(project.label, organization.label)}`}
                      labelOverlayed
                      size={32}
                    />
                  ),

                  actionHandler: async () => {
                    await actions.getProject(project.id);
                    history.push(`${routing.PLANS.replace(':projectId', project.id)}`);
                  },
                })),
              ]
            : [
                {
                  type: 'text',
                  text: <FormattedMessage id="projects.not_assigned_projects.leftbar" />,
                  textAlign: 'center',
                },
              ]),
          {
            type: 'divider',
          },
          {
            id: 'manage-projects',
            name: 'manage-projects',
            labelOverlayed: false,
            label: (
              <div style={{ width: '100%' }}>
                <Typography align="center">
                  <FormattedMessage id="projects.manage_all" />
                </Typography>
              </div>
            ),
            avatar: ProjectIcon,
            actionHandler: () => {
              // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
              projectsCallback('manage-projects');
            },
          },
          {
            id: 'add-project',
            name: 'add-project',
            labelOverlayed: false,
            label: (
              <div style={{ width: '100%' }}>
                <Typography align="center">
                  <FormattedMessage id="projects.add_new" />
                </Typography>
              </div>
            ),
            avatar: AddProjectIcon,
            actionHandler: () => {
              // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
              projectsCallback('add-project');
            },
          },
        ]
      : []),
  ];

  const userActions = [
    ...(user
      ? [
          {
            name: 'profilesumary',
            id: 'profilesumary',
            label: <UserProfileSummary user={user} organization={organization} />,
            actionHandler: () => {
              history.push(routing.SETTINGS.ACCOUNT.PROFILE);
            },
          },
          {
            type: 'divider',
          },
          ...(userHelpers.currentUser.isRoot() || userHelpers.currentUser.isAdmin()
            ? [
                {
                  name: 'administration',
                  id: 'administration',
                  label: (
                    <div style={{ width: '100%' }}>
                      <Typography align="center">
                        <FormattedMessage id="userprofilesummary.admin" />
                      </Typography>
                    </div>
                  ),
                  actionHandler: () => {
                    history.push(routing.ADMINISTRATION.USERS);
                  },
                },
              ]
            : []),
          ...(userHelpers.currentUser.isRoot() || userHelpers.currentUser.isAdmin()
            ? [
                {
                  type: 'divider',
                },
              ]
            : []),
          {
            name: 'settings',
            id: 'settings',
            label: (
              <div style={{ width: '100%' }}>
                <Typography align="center">
                  <FormattedMessage id="userprofilesummary.settings" />
                </Typography>
              </div>
            ),
            actionHandler: () => {
              history.push(routing.SETTINGS.ACCOUNT.PROFILE);
            },
          },
          {
            name: 'help',
            id: 'help',
            label: (
              <div style={{ width: '100%' }}>
                <Typography align="center">
                  <FormattedMessage id="userprofilesummary.help" />
                </Typography>
              </div>
            ),
            actionHandler: () => window.open(routing.EXTERNAL.DOCS),
          },
          {
            type: 'divider',
          },
          ...(!!asUser && asUser !== ''
            ? [
                {
                  name: 'backroot',
                  id: 'backroot',
                  label: (
                    <div style={{ width: '100%', padding: '0px 50px' }}>
                      <Typography align="center">Back to root</Typography>
                    </div>
                  ),
                  actionHandler: handleCloseAsUser,
                },
              ]
            : []),
          {
            name: 'logout',
            id: 'logout',
            label: (
              <div style={{ width: '100%' }}>
                <Typography align="center">
                  <FormattedMessage id="userprofilesummary.logout" />
                </Typography>
              </div>
            ),
            actionHandler: handleLogOut,
          },
        ]
      : []),
  ];

  return (
    <>
      <SideBar
        color="#050D1E"
        logo={
          <Icon style={{ width: '32px', height: '32px' }}>
            <img src={Logo} alt="" />
          </Icon>
        }
        onClickLogo={() => {
          if(activeProject?.id) {
            history.push(`${routing.PLANS.replace(':projectId', activeProject.id)}`);
          } else {
            history.push(`${routing.PRIVATEHIGHWAY}`);}
        }
        }
        activeProject={
          activeProject && {
            id: activeProject.id,
            label: `${projectHelpers.getProjectLabel(activeProject.label, organization.label)}`,
            avatar: (
              <Avatar
                data={activeProject as any}
                label={`${projectHelpers.getProjectLabel(activeProject.label, organization.label)}`}
                labelOverlayed
                size={32}
              />
            ),
          }
        }
        projectActions={projectActions}
        userActions={userActions}
        menuItems={menuItems}
        user={
          user
            ? {
                ...user,
                avatar: <Avatar data={user} labelOverlayed={false} size={36} />,
              }
            : undefined
        }
        helpActions={helpActions}
        asUser={!!asUser && asUser !== ''}
      />
      {paymentRunwayModal && <PaymentRunwayDialog onClose={actions.closePaymentModal} />}
    </>
  );
};

LeftBar.defaultProps = {
  user: undefined,
  organization: undefined,
  projects: undefined,
  projectsCallback: undefined,
  token: undefined,
  asUser: undefined,
  menuItems: [],
};

const mapStateToProps = (state: any) => {
  return {
    isLogged: state.user.isLogged,
    user: state.user.user,
    organization: state.organization.organization,
    activeProject: state.projects.activeProject,
    projects:
      state.projects.projects && state.user.user
        ? projectHelpers.filterAccessibleProjects(state.projects.projects, state.user.user.id)
        : undefined,
    token: state.user.token,
    paymentRunwayModal: state.common.paymentModal,
    asUser: state.user.asUser,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    logout: () => dispatch(User.logout()),
    details: (token: any) => dispatch(User.details(token)),
    getOrganization: (token: any) => dispatch(Organization.getOrganization(token)),
    getProject: (projectId: any) => dispatch(Projects.getProject(projectId)),
    getProjects: () => dispatch(Projects.getProjects()),
    closePaymentModal: () => dispatch(Common.closePaymentModal()),
    backToRoot: () => dispatch(User.removeAsUser()),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(LeftBar);
