import constants from '../constants/index';
// import { intl } from '../helpers/IntGlobalProvider';

const normalSuccess = {
  type: 'success',
  closeByClickAway: false,
  autoHideDuration: 2000,
};

const initialState = {
  type: undefined,
  autoHideDuration: undefined,
  message: undefined,
  closeByClickAway: undefined,
  response: undefined,
};

function infoerror(state = initialState, action: any) {
  let messageId;
  switch (action.type) {
    case constants.ActivePlan.ACTIVE_PLAN_CREATE_PLAN_ERROR:
      return {
        ...state,
        message: `infoerror.${action.payload.messageId}`,
        type: 'error',
        closeByClickAway: false,
        autoHideDuration: 2000,
      };
    case constants.Organization.ORGANIZATION_NEW_USER_ERROR:
      return {
        ...state,
        message: `infoerror.${action.payload.response.data.messageId}`,
        type: 'error',
        closeByClickAway: false,
        autoHideDuration: 2000,
      };
    // case constants.Routes.ROUTES_UPDATE_ROUTE_ERROR:
    //   return {
    //     ...state,
    //     message: `${action.payload.messageId}`,
    //     type: 'error',
    //     closeByClickAway: false,
    //     autoHideDuration: 5000
    //   };
    case constants.InfoError.INFOERROR_CLOSE_SNACKBAR:
      return { ...initialState };
    case constants.InfoError.INFOERROR_SEND_STATUS:
      return { ...state, ...action.payload };
    case constants.User.USER_VALIDATE_SUCCESS:
    case constants.User.ADMIN_VALIDATE_USER_SUCCESS:
      return {
        ...state,
        message: 'infoerror.validationsucces',
        ...normalSuccess,
      };
    case constants.Plans.PLANS_DELETE_PLAN_SUCCESS:
      return {
        ...state,
        message: 'infoerror.deleteplansuccess',
        ...normalSuccess,
      };
    case constants.ActivePlan.ACTIVE_PLAN_GET_PLAN_ERROR:
      return {
        ...state,
        message: 'infoerror.plannotfound',
        type: 'error',
        closeByClickAway: false,
        autoHideDuration: 2000,
      };
    case constants.ActivePlan.ACTIVE_PLAN_OPTIMIZE_PLAN_ERROR:
      return {
        ...state,
        message: 'infoerror.optimizererror',
        type: 'error',
        closeByClickAway: false,
        autoHideDuration: 2000,
      };
    case constants.ActivePlan.ACTIVE_PLAN_OPTIMIZE_PLAN_SUCCESS:
      return {
        ...state,
        message: `infoerror.${
          action.payload.finished === false ? 'optimizerinprogress' : 'optimizersucces'
        }`,
        ...normalSuccess,
        autoHideDuration: action.payload.finished === false ? 6000 : 2000,
      };
    case constants.User.USER_LOGIN_SUCCESS:
      return {
        ...state,
        message: 'infoerror.loginsuccess',
        ...normalSuccess,
      };
    case constants.Services.SERVICES_DELETE_SERVICE_SUCCESS:
      return {
        ...state,
        message: 'infoerror.deleteservicesucces',
        ...normalSuccess,
      };
    case constants.Vehicles.VEHICLES_ROUTE_AS_VEHICLE_SUCCESS:
      return {
        ...state,
        message: 'infoerror.routeasvehiclesucces',
        ...normalSuccess,
      };
    case constants.Clients.CLIENTS_SERVICE_AS_CLIENT_SUCCESS:
      return {
        ...state,
        message: 'infoerror.servicesasclientsucces',
        ...normalSuccess,
      };
    case constants.Routes.ROUTES_DELETE_ROUTE_SUCCESS:
      return {
        ...state,
        message: 'infoerror.deleteroutesuccess',
        ...normalSuccess,
      };
    case constants.Services.SERVICES_UPDATE_SERVICES_SUCCESS:
      return {
        ...state,
        message: 'infoerror.updateservicesuccess',
        ...normalSuccess,
      };
    case constants.Routes.ROUTES_UPDATE_ROUTES_SUCCESS:
      return {
        ...state,
        message: 'infoerror.updateroutessuccess',
        ...normalSuccess,
      };
    case constants.Organization.ORGANIZATION_REGISTER_SUCCESS:
      return {
        ...state,
        message: 'infoerror.organizationregistersuccess',
        ...normalSuccess,
      };
    case constants.Payment.PAYMENT_CANCEL_SUBSCRIPTON_PLAN_ERROR:
    case constants.Payment.PAYMENT_MANAGE_SUBSCRIPTON_PLAN_ERROR:
      messageId =
        action.payload.response &&
        action.payload.response.data &&
        action.payload.response.data.messageId &&
        action.payload.response.data.messageId !== ''
          ? action.payload.response.data.messageId
          : 'unexpectedresult';
      return {
        ...state,
        message: `infoerror.${messageId}`,
        type: 'error',
        closeByClickAway: false,
        autoHideDuration: 2000,
      };
    case constants.User.USER_LOGIN_ERROR:
      messageId =
        action.payload.response && action.payload.response.status === 401
          ? 'highway.user.error.invalid'
          : 'unexpectedresult';
      return {
        ...state,
        message: `infoerror.${messageId}`,
        type: 'error',
        closeByClickAway: false,
        autoHideDuration:
          action.payload.response && action.payload.response.status === 401 ? undefined : 5000,
        response: action.payload,
      };
    case constants.User.USER_DETAILS_ERROR:
    case constants.User.USER_REGISTER_ERROR:
    case constants.User.USER_RESENDEMAIL_ERROR:
    case constants.User.USER_VALIDATE_ERROR:
    case constants.User.ADMIN_VALIDATE_USER_ERROR:
      return {
        ...state,
        message: `${
          action.payload.response
            ? action.payload.response.data.message
            : 'infoerror.unexpectedresult'
        }${
          action.payload.response && action.payload.response.status === 401 ? '. Log in again.' : ''
        }`,
        type: 'error',
        closeByClickAway: false,
        autoHideDuration:
          action.payload.response && action.payload.response.status === 401 ? undefined : 5000,
        response: action.payload,
      };
    case constants.Payment.PAYMENT_POST_PAYMENT_METHOD_ERROR:
      return {
        ...state,
        message: `infoerror.${action.payload.response.data.messageId}`,
        type: 'error',
        closeByClickAway: false,
        autoHideDuration: 2000,
      };
    case constants.Vehicles.VEHICLES_ROUTE_AS_VEHICLE_ERROR:
      return {
        ...state,
        message: `infoerror.${action.payload.messageId}`,
        type: 'error',
        closeByClickAway: false,
        autoHideDuration: 2000,
      };
    case constants.Projects.PROJECTS_CREATE_CUSTOM_FIELD_ERROR:
    case constants.Projects.PROJECTS_EDIT_CUSTOM_FIELD_ERROR:
      messageId = action.payload && action.payload.messageId && action.payload.messageId !== '' ? action.payload.messageId : 'unexpectedresult';
      return {
        ...state,
        message: `infoerror.${messageId}`,
        type: 'error',
        closeByClickAway: false,
        autoHideDuration: 4000,
      };
    default:
      if (action && action.payload) {
        if (action.payload.message === 'Network Error') {
          messageId = 'network_error';
        } else if (action.payload.messageId && action.payload.messageId !== '') {
          messageId = action.payload.messageId;
        } else if (
          action.payload.response &&
          action.payload.response.data &&
          action.payload.response.data.messageId &&
          action.payload.response.data.messageId !== ''
        ) {
          messageId = action.payload.response.data.messageId;
        } else {
          return state;
        }

        return {
          ...state,
          message: `infoerror.${messageId}`,
          type: 'error',
          closeByClickAway: false,
          autoHideDuration: 5000,
        };
      }

      return state;
  }
}

export default infoerror;
