/**
 * Creates the public link to the plan route.
 * @param {publicKey} - Authenticated user public key.
 * @param {routeId} - Plan route link.
 * @return {string} - Public link.
 */
export const getRoutePublicLink = (publicKey: any, routeId: any): string => {
  
  const { hostname, protocol } = window.location;
  const path = `public/mobile?&public_key=${publicKey}&route=${routeId}`;
  if(hostname.includes('localhost')) {
    return `${protocol}//${hostname}:3002/${path}`;
  }
  if(hostname.includes('highwaytest.')) {
    return `${protocol}//${hostname.replace('highwaytest.', 'webapptest.')}/${path}`;
  }
  if(hostname.includes('highway.')) {
    return `${protocol}//${hostname.replace('highway.', 'webapp.')}/${path}`;
  }

  return '';
};

export default {};
