import { IRouteDataExtended } from 'highway-api/dist/common/interfaces/routes';
import { IVehicleData } from 'highway-api/dist/common/interfaces/vehicles';

const colors = [
  '#2196F3',
  '#CDDC39',
  '#d2190b',
  '#607D8B',
  '#af94e8',
  '#795548',
  '#FFEB3B',
  '#ace4fe',
  '#2830C9',
  '#009688',
  '#F44336',
  '#673AB7',
  '#D39C11',
  '#9927C9',
  '#2E8938',
  '#FFC107',
  '#02BCD4',
  '#511414',
  '#F906CB',
  '#5B96FF',
  '#2b387c',
  '#8BC34A',

  '#ffb8a2',
  '#d1d077',
  '#f4df96',
];
export default colors;

const getColor = (elementData: IRouteDataExtended | IVehicleData, elements: IRouteDataExtended[] | IVehicleData[]) => {
  let colorFound = colors[0];
  if(elementData) {
    if(elementData.color) {
      colorFound = elementData.color;
    } else {
      const routeIndex = elements.findIndex((r: any) => r.id === elementData.id);
      colorFound = colors[routeIndex];
    }
  }
  return colorFound;
};

export const getRouteColor = (routeData: IRouteDataExtended, planRoutes: IRouteDataExtended[]) => getColor(routeData, planRoutes);

export const getVehicleColor = (vehicleData: IVehicleData, vehicles: IVehicleData[]) => getColor(vehicleData, vehicles);
