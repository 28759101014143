import createSvgIcon from '@material-ui/icons/utils/createSvgIcon';
import React from 'react';

export default createSvgIcon(
  <>
    <path d="M11.3,13.3v6.2H2.9V6.9h8.5v3.6l1.4-0.9V4.7c0-1.1-0.9-2-2-2H3.4c-1.1,0-2,0.9-2,2V19c0,1.1,0.9,2,2,2h7.4   c1.1,0,2-0.9,2-2v-4.6L11.3,13.3z" />
    <path d="m19.4 11.2h-5.1l1.2-0.8c0.4-0.2 0.5-0.7 0.2-1-0.2-0.3-0.7-0.5-1-0.2l-3.1 2c-0.2 0.1-0.3 0.4-0.3 0.6s0.1 0.5 0.3 0.6l3.2 2.3c0.1 0.1 0.3 0.1 0.4 0.1 0.2 0 0.5-0.1 0.6-0.3 0.2-0.3 0.2-0.8-0.2-1l-1.1-0.8h4.9c0.4 0 0.8-0.3 0.8-0.8s-0.4-0.7-0.8-0.7z" />
  </>,
  'ListCloseIcon'
);
