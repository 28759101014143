import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { VSpacer } from '@sm-highway-web/react-components';
import styled from 'styled-components';
import { twoDigits } from '../helpers/TimeDistance';
import FormattedMessage from './FormattedMessageCustom';

const Label = styled(Typography)`
  color: #8a8a8a;
  text-transform: uppercase;
  font-size: 9px;
`;

type OwnProps = {
  card?: {
    brand?: string;
    name?: string;
    last4?: string;
    'exp_month'?: number;
    'exp_year'?: number;
  };
};

type Props = OwnProps;

const CardInfo = ({ card }: Props) => {
  return (
    <>
      <Grid item>
        {card && card.brand ? (
          <Typography variant="h6">{card.brand}</Typography>
        ) : (
          <Typography variant="h6">
            <FormattedMessage id="settings.billing.payment.nopaymentavailable" />
          </Typography>
        )}
      </Grid>
      <VSpacer small />
      <Grid item>
        <Grid container>
          <Grid item lg={5}>
            <Label>
              <FormattedMessage id="settings.billing.payment.cardnumber" />
            </Label>
            <Typography>{card && card.last4 ? `**** **** **** ${card.last4}` : '-'}</Typography>
          </Grid>
          <Grid item lg={5}>
            <Label>
              <FormattedMessage id="settings.billing.payment.cardowner" />
            </Label>
            <Typography>{card && card.name ? card.name : '-'}</Typography>
          </Grid>
          <Grid item lg={2}>
            <Label>
              <FormattedMessage id="settings.billing.payment.cardexpires" />
            </Label>
            <Typography>
              {card && card.exp_month && card.exp_year
                ? `${twoDigits(card.exp_month)}/${card.exp_year}`
                : '-'}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

CardInfo.defaultProps = {
  card: undefined,
};

export default CardInfo;
