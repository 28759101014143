export const vehicles_CL = [
  {
    end_location: {
      lat: -33.4067,
      lng: -70.58403,
      country: 'Chile',
      country_code: 'CHL',
      state: 'Región Metropolitana de Santiago',
      county: 'Santiago',
      postal_code: '7550000',
      label: 'Calle Los Talaveras, 7550000 Las Condes, Región Metropolitana de Santiago, Chile',
      city: 'Las Condes',
      district: 'Escuela Militar',
      street: 'Calle Los Talaveras',
    },
    label: 'Manuel',
    max_services: 17,
    start_location: {
      lat: -33.4067,
      lng: -70.58403,
      country: 'Chile',
      country_code: 'CHL',
      state: 'Región Metropolitana de Santiago',
      county: 'Santiago',
      postal_code: '7550000',
      label: 'Calle Los Talaveras, 7550000 Las Condes, Región Metropolitana de Santiago, Chile',
      city: 'Las Condes',
      district: 'Escuela Militar',
      street: 'Calle Los Talaveras',
    },
    timewindow: [32400, 57600],
  },
  {
    end_location: {
      lat: -33.39712,
      lng: -70.56453,
      country: 'Chile',
      country_code: 'CHL',
      state: 'Región Metropolitana de Santiago',
      county: 'Santiago',
      house_number: '805',
      postal_code: '7550000',
      label: 'Calle Benito Juárez 805, 7550000 Las Condes, Región Metropolitana de Santiago, Chile',
      city: 'Las Condes',
      district: 'Las Condes',
      street: 'Calle Benito Juárez',
    },
    label: 'Jose',
    max_services: 17,
    start_location: {
      lat: -33.39712,
      lng: -70.56453,
      country: 'Chile',
      country_code: 'CHL',
      state: 'Región Metropolitana de Santiago',
      county: 'Santiago',
      house_number: '805',
      postal_code: '7550000',
      label: 'Calle Benito Juárez 805, 7550000 Las Condes, Región Metropolitana de Santiago, Chile',
      city: 'Las Condes',
      district: 'Las Condes',
      street: 'Calle Benito Juárez',
    },
    timewindow: [39600, 64800],
  },
  {
    end_location: {
      lat: -33.41152,
      lng: -70.51932,
      country: 'Chile',
      country_code: 'CHL',
      state: 'Región Metropolitana de Santiago',
      county: 'Santiago',
      house_number: '11132',
      postal_code: '7550000',
      label:
        'Calle Carlos Peña Otaegui 11132, 7550000 Las Condes, Región Metropolitana de Santiago, Chile',
      city: 'Las Condes',
      district: 'Las Condes',
      street: 'Calle Carlos Peña Otaegui',
    },
    label: 'Pedro',
    max_services: 17,
    start_location: {
      lat: -33.41152,
      lng: -70.51932,
      country: 'Chile',
      country_code: 'CHL',
      state: 'Región Metropolitana de Santiago',
      county: 'Santiago',
      house_number: '11132',
      postal_code: '7550000',
      label:
        'Calle Carlos Peña Otaegui 11132, 7550000 Las Condes, Región Metropolitana de Santiago, Chile',
      city: 'Las Condes',
      district: 'Las Condes',
      street: 'Calle Carlos Peña Otaegui',
    },
    timewindow: [32400, 57600],
  },
  {
    end_location: {
      lat: -33.41436,
      lng: -70.59074,
      country: 'Chile',
      country_code: 'CHL',
      state: 'Región Metropolitana de Santiago',
      county: 'Santiago',
      house_number: '3816',
      postal_code: '7550000',
      label: 'Calle Bernardita 3816, 7550000 Las Condes, Región Metropolitana de Santiago, Chile',
      city: 'Las Condes',
      district: 'El Golf',
      street: 'Calle Bernardita',
    },
    label: 'Pablo',
    max_services: 17,
    start_location: {
      lat: -33.41436,
      lng: -70.59074,
      country: 'Chile',
      country_code: 'CHL',
      state: 'Región Metropolitana de Santiago',
      county: 'Santiago',
      house_number: '3816',
      postal_code: '7550000',
      label: 'Calle Bernardita 3816, 7550000 Las Condes, Región Metropolitana de Santiago, Chile',
      city: 'Las Condes',
      district: 'El Golf',
      street: 'Calle Bernardita',
    },
    timewindow: [39600, 64800],
  },
];

export const services_CL = [
  {
    label: 'RESTAURANTE MAIDO',
    location: {
      lat: -33.41231,
      lng: -70.51843,
      country: 'Chile',
      country_code: 'CHL',
      state: 'Región Metropolitana de Santiago',
      county: 'Santiago',
      house_number: '11218',
      postal_code: '7550000',
      label: 'Calle Atalaya 11218, 7550000 Las Condes, Región Metropolitana de Santiago, Chile',
      city: 'Las Condes',
      district: 'Las Condes',
      street: 'Calle Atalaya',
    },
    duration: 300,
    timewindows: [[28800, 36000]],
  },
  {
    label: 'RESTAURANTE CENTRAL',
    location: {
      lat: -33.39663,
      lng: -70.56366,
      country: 'Chile',
      country_code: 'CHL',
      state: 'Región Metropolitana de Santiago',
      county: 'Santiago',
      house_number: '6707',
      postal_code: '7550000',
      label:
        'Calle Hermanos Cabot 6707, 7550000 Las Condes, Región Metropolitana de Santiago, Chile',
      city: 'Las Condes',
      district: 'Las Condes',
      street: 'Calle Hermanos Cabot',
    },
    duration: 300,
    timewindows: [[28800, 46800]],
  },
  {
    label: 'RESTAURANTE PUJOL',
    location: {
      lat: -33.41657,
      lng: -70.59553,
      country: 'Chile',
      country_code: 'CHL',
      state: 'Región Metropolitana de Santiago',
      county: 'Santiago',
      postal_code: '7550000',
      label: 'Las Condes, Región Metropolitana de Santiago, Chile',
      city: 'Las Condes',
    },
    duration: 300,
    timewindows: [[28800, 46800]],
  },
  {
    label: 'RESTAURANTE DON JULIO',
    location: {
      lat: -33.40093,
      lng: -70.56275,
      country: 'Chile',
      country_code: 'CHL',
      state: 'Región Metropolitana de Santiago',
      county: 'Santiago',
      house_number: '6678',
      postal_code: '7550000',
      label:
        'Calle Cerro El Plomo 6678, 7550000 Las Condes, Región Metropolitana de Santiago, Chile',
      city: 'Las Condes',
      district: 'Las Condes',
      street: 'Calle Cerro El Plomo',
    },
    duration: 300,
    timewindows: [[28800, 46800]],
  },
  {
    label: 'RESTAURANTE BORAGO',
    location: {
      lat: -33.40745,
      lng: -70.58358,
      country: 'Chile',
      country_code: 'CHL',
      state: 'Región Metropolitana de Santiago',
      county: 'Santiago',
      house_number: '569',
      postal_code: '7550000',
      label:
        'Calle Los Estandartes 569, 7550000 Las Condes, Región Metropolitana de Santiago, Chile',
      city: 'Las Condes',
      district: 'Escuela Militar',
      street: 'Calle Los Estandartes',
    },
    duration: 300,
    timewindows: [[28800, 45000]],
  },
  {
    label: 'RESTAURANTE A CASA DO PORCO',
    location: {
      lat: -33.4133,
      lng: -70.59078,
      country: 'Chile',
      country_code: 'CHL',
      state: 'Región Metropolitana de Santiago',
      county: 'Santiago',
      house_number: '159',
      postal_code: '7550000',
      label:
        'Calle Nuestra Señora de los Ángeles 159, 7550000 Las Condes, Región Metropolitana de Santiago, Chile',
      city: 'Las Condes',
      district: 'El Golf',
      street: 'Calle Nuestra Señora de los Ángeles',
    },
    duration: 600,
    timewindows: [[28800, 45000]],
  },
  {
    label: 'RESTAURANTE EL CHATO',
    location: {
      lat: -33.42062,
      lng: -70.53825,
      country: 'Chile',
      country_code: 'CHL',
      state: 'Región Metropolitana de Santiago',
      county: 'Santiago',
      house_number: '8916',
      postal_code: '7550000',
      label:
        'Calle Alonso de Camargo 8916, 7550000 Las Condes, Región Metropolitana de Santiago, Chile',
      city: 'Las Condes',
      district: 'Las Condes',
      street: 'Calle Alonso de Camargo',
    },
    duration: 300,
    timewindows: [[28800, 45000]],
  },
  {
    label: 'RESTAURANTE LEO',
    location: {
      lat: -33.40875,
      lng: -70.57113,
      country: 'Chile',
      country_code: 'CHL',
      state: 'Región Metropolitana de Santiago',
      county: 'Santiago',
      house_number: '32',
      postal_code: '7550000',
      label: 'Calle Rosario Norte 32, 7550000 Las Condes, Región Metropolitana de Santiago, Chile',
      city: 'Las Condes',
      district: 'Las Condes',
      street: 'Calle Rosario Norte',
    },
    duration: 300,
    timewindows: [[28800, 45000]],
  },
  {
    label: 'RESTAURANTE OSSO',
    location: {
      lat: -33.42662,
      lng: -70.56285,
      country: 'Chile',
      country_code: 'CHL',
      state: 'Región Metropolitana de Santiago',
      county: 'Santiago',
      house_number: '1699',
      postal_code: '7550000',
      label:
        'Calle Hernando de Magallanes 1699, 7550000 Las Condes, Región Metropolitana de Santiago, Chile',
      city: 'Las Condes',
      district: 'Las Condes',
      street: 'Calle Hernando de Magallanes',
    },
    duration: 300,
    timewindows: [[28800, 46800]],
  },
  {
    label: 'RESTAURANTE D.O.M.',
    location: {
      lat: -33.39745,
      lng: -70.50708,
      country: 'Chile',
      country_code: 'CHL',
      state: 'Región Metropolitana de Santiago',
      county: 'Santiago',
      house_number: '1451',
      postal_code: '7550000',
      label: 'Avenida La Plaza 1451, 7550000 Las Condes, Región Metropolitana de Santiago, Chile',
      city: 'Las Condes',
      district: 'Las Condes',
      street: 'Avenida La Plaza',
    },
    duration: 600,
    timewindows: [[28800, 46800]],
  },
  {
    label: 'RESTAURANTE QUINTONIL',
    location: {
      lat: -33.40901,
      lng: -70.50645,
      country: 'Chile',
      country_code: 'CHL',
      state: 'Región Metropolitana de Santiago',
      county: 'Santiago',
      house_number: '2880',
      postal_code: '7550000',
      label:
        'Avenida San Carlos de Apoquindo 2880, 7550000 Las Condes, Región Metropolitana de Santiago, Chile',
      city: 'Las Condes',
      district: 'Las Condes',
      street: 'Avenida San Carlos de Apoquindo',
    },
    duration: 300,
    timewindows: [[28800, 46800]],
  },
  {
    label: 'RESTAURANTE ISOLINA',
    location: {
      lat: -33.42228,
      lng: -70.58178,
      country: 'Chile',
      country_code: 'CHL',
      state: 'Región Metropolitana de Santiago',
      county: 'Santiago',
      house_number: '920',
      postal_code: '7550000',
      label: 'Calle Alicante 920, 7550000 Las Condes, Región Metropolitana de Santiago, Chile',
      city: 'Las Condes',
      district: 'Las Condes',
      street: 'Calle Alicante',
    },
    duration: 420,
    timewindows: [[28800, 46800]],
  },
  {
    label: 'RESTAURANTE ASTRID Y GASTON',
    location: {
      lat: -33.37895,
      lng: -70.49716,
      country: 'Chile',
      country_code: 'CHL',
      state: 'Región Metropolitana de Santiago',
      county: 'Santiago',
      house_number: '1250',
      postal_code: '7550000',
      label:
        'Calle San José de la Sierra 1250, 7550000 Las Condes, Región Metropolitana de Santiago, Chile',
      city: 'Las Condes',
      district: 'Las Condes',
      street: 'Calle San José de la Sierra',
    },
    duration: 480,
    timewindows: [[28800, 46800]],
  },
  {
    label: 'RESTAURANTE ALCALDE',
    location: {
      lat: -33.37203,
      lng: -70.51545,
      country: 'Chile',
      country_code: 'CHL',
      state: 'Región Metropolitana de Santiago',
      county: 'Santiago',
      house_number: '12587',
      postal_code: '7550000',
      label:
        'Avenida Las Condes 12587, 7550000 Las Condes, Región Metropolitana de Santiago, Chile',
      city: 'Las Condes',
      district: 'Las Condes',
      street: 'Avenida Las Condes',
    },
    duration: 300,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE PANGEA',
    location: {
      lat: -33.39357,
      lng: -70.55521,
      country: 'Chile',
      country_code: 'CHL',
      state: 'Región Metropolitana de Santiago',
      county: 'Santiago',
      house_number: '7799',
      postal_code: '7550000',
      label:
        'Calle Hermanos Cabot 7799, 7550000 Las Condes, Región Metropolitana de Santiago, Chile',
      city: 'Las Condes',
      district: 'Las Condes',
      street: 'Calle Hermanos Cabot',
    },
    duration: 300,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE SUD 777',
    location: {
      lat: -33.41543,
      lng: -70.5605,
      country: 'Chile',
      country_code: 'CHL',
      state: 'Región Metropolitana de Santiago',
      county: 'Santiago',
      house_number: '6611',
      postal_code: '7550000',
      label:
        'Calle Martín de Zamora 6611, 7550000 Las Condes, Región Metropolitana de Santiago, Chile',
      city: 'Las Condes',
      district: 'Las Condes',
      street: 'Calle Martín de Zamora',
    },
  },
  {
    label: 'RESTAURANTE MAITO',
    location: {
      lat: -33.41802,
      lng: -70.56823,
      country: 'Chile',
      country_code: 'CHL',
      state: 'Región Metropolitana de Santiago',
      county: 'Santiago',
      house_number: '1035',
      postal_code: '7550000',
      label:
        'Calle Domingo Bondi 1035, 7550000 Las Condes, Región Metropolitana de Santiago, Chile',
      city: 'Las Condes',
      district: 'Las Condes',
      street: 'Calle Domingo Bondi',
    },
    duration: 300,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE MANI',
    location: {
      lat: -33.41745,
      lng: -70.55539,
      country: 'Chile',
      country_code: 'CHL',
      state: 'Región Metropolitana de Santiago',
      county: 'Santiago',
      house_number: '974',
      postal_code: '7550000',
      label:
        'Calle Robinson Crusóe 974, 7550000 Las Condes, Región Metropolitana de Santiago, Chile',
      city: 'Las Condes',
      district: 'Las Condes',
      street: 'Calle Robinson Crusóe',
    },
    duration: 600,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE RAFAEL',
    location: {
      lat: -33.41849,
      lng: -70.57019,
      country: 'Chile',
      country_code: 'CHL',
      state: 'Región Metropolitana de Santiago',
      county: 'Santiago',
      house_number: '5267',
      postal_code: '7550000',
      label:
        'Avenida Cristóbal Colón 5267, 7550000 Las Condes, Región Metropolitana de Santiago, Chile',
      city: 'Las Condes',
      district: 'Las Condes',
      street: 'Avenida Cristóbal Colón',
    },
    duration: 300,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE MISHIGUENE',
    location: {
      lat: -33.41191,
      lng: -70.56932,
      country: 'Chile',
      country_code: 'CHL',
      state: 'Región Metropolitana de Santiago',
      county: 'Santiago',
      house_number: '5700',
      postal_code: '7550000',
      label: 'Calle del Inca 5700, 7550000 Las Condes, Región Metropolitana de Santiago, Chile',
      city: 'Las Condes',
      district: 'Las Condes',
      street: 'Calle del Inca',
    },
    duration: 300,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE KJOLLE',
    location: {
      lat: -33.4293,
      lng: -70.56214,
      country: 'Chile',
      country_code: 'CHL',
      state: 'Región Metropolitana de Santiago',
      county: 'Santiago',
      house_number: '1897',
      postal_code: '7550000',
      label: 'Calle El Toqui 1897, 7550000 Las Condes, Región Metropolitana de Santiago, Chile',
      city: 'Las Condes',
      district: 'Las Condes',
      street: 'Calle El Toqui',
    },
    duration: 300,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE HARRY SASSON',
    location: {
      lat: -33.38142,
      lng: -70.50354,
      country: 'Chile',
      country_code: 'CHL',
      state: 'Región Metropolitana de Santiago',
      county: 'Santiago',
      house_number: '13231',
      postal_code: '7550000',
      label:
        'Avenida Los Tuliperos 13231, 7550000 Las Condes, Región Metropolitana de Santiago, Chile',
      city: 'Las Condes',
      district: 'Las Condes',
      street: 'Avenida Los Tuliperos',
    },
    duration: 600,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE OTEQUE',
    location: {
      lat: -33.41657,
      lng: -70.59553,
      country: 'Chile',
      country_code: 'CHL',
      state: 'Región Metropolitana de Santiago',
      county: 'Santiago',
      postal_code: '7550000',
      label: 'Las Condes, Región Metropolitana de Santiago, Chile',
      city: 'Las Condes',
    },
    duration: 300,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE LASAI',
    location: {
      lat: -33.38953,
      lng: -70.49742,
      country: 'Chile',
      country_code: 'CHL',
      state: 'Región Metropolitana de Santiago',
      county: 'Santiago',
      house_number: '570',
      postal_code: '7550000',
      label: 'Avenida Plaza 570, 7550000 Las Condes, Región Metropolitana de Santiago, Chile',
      city: 'Las Condes',
      district: 'Las Condes',
      street: 'Avenida Plaza',
    },
    duration: 540,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE TEGUI',
    location: {
      lat: -33.39254,
      lng: -70.53191,
      country: 'Chile',
      country_code: 'CHL',
      state: 'Región Metropolitana de Santiago',
      county: 'Santiago',
      house_number: '9351',
      postal_code: '7550000',
      label:
        'Avenida Charles Hamilton 9351, 7550000 Las Condes, Región Metropolitana de Santiago, Chile',
      city: 'Las Condes',
      district: 'Las Condes',
      street: 'Avenida Charles Hamilton',
    },
    duration: 600,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE LA MAR',
    location: {
      lat: -33.41132,
      lng: -70.51845,
      country: 'Chile',
      country_code: 'CHL',
      state: 'Región Metropolitana de Santiago',
      county: 'Santiago',
      house_number: '2860',
      postal_code: '7550000',
      label: 'La Fuente 2860, 7550000 Las Condes, Región Metropolitana de Santiago, Chile',
      city: 'Las Condes',
      district: 'Las Condes',
      street: 'La Fuente',
    },
    duration: 300,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE ROSSETTA',
    location: {
      lat: -33.40301,
      lng: -70.56892,
      country: 'Chile',
      country_code: 'CHL',
      state: 'Región Metropolitana de Santiago',
      county: 'Santiago',
      house_number: '555',
      postal_code: '7550000',
      label:
        'Avenida Manquehue Norte 555, 7550000 Las Condes, Región Metropolitana de Santiago, Chile',
      city: 'Las Condes',
      district: 'Las Condes',
      street: 'Avenida Manquehue Norte',
    },
    duration: 300,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE MAXIMO BISTROT',
    location: {
      lat: -33.42708,
      lng: -70.58092,
      country: 'Chile',
      country_code: 'CHL',
      state: 'Región Metropolitana de Santiago',
      county: 'Santiago',
      house_number: '4193',
      postal_code: '7550000',
      label:
        'Calle Fernando de Aragón 4193, 7550000 Las Condes, Región Metropolitana de Santiago, Chile',
      city: 'Las Condes',
      district: 'Las Condes',
      street: 'Calle Fernando de Aragón',
    },
    duration: 300,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE CHILA',
    location: {
      lat: -33.4065,
      lng: -70.5634,
      country: 'Chile',
      country_code: 'CHL',
      state: 'Región Metropolitana de Santiago',
      county: 'Santiago',
      house_number: '6445',
      postal_code: '7550000',
      label: 'Calle Ibsen 6445, 7550000 Las Condes, Región Metropolitana de Santiago, Chile',
      city: 'Las Condes',
      district: 'Las Condes',
      street: 'Calle Ibsen',
    },
    duration: 300,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE AMBROSIA',
    location: {
      lat: -33.39431,
      lng: -70.52817,
      country: 'Chile',
      country_code: 'CHL',
      state: 'Región Metropolitana de Santiago',
      county: 'Santiago',
      house_number: '9998',
      postal_code: '7550000',
      label: 'Camino Las Flores 9998, 7550000 Las Condes, Región Metropolitana de Santiago, Chile',
      city: 'Las Condes',
      district: 'Las Condes',
      street: 'Camino Las Flores',
    },
    duration: 300,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE NICOS',
    location: {
      lat: -33.41276,
      lng: -70.60005,
      country: 'Chile',
      country_code: 'CHL',
      state: 'Región Metropolitana de Santiago',
      county: 'Santiago',
      house_number: '2970',
      postal_code: '7550000',
      label:
        'Calle San Sebastián 2970, 7550000 Las Condes, Región Metropolitana de Santiago, Chile',
      city: 'Las Condes',
      district: 'El Golf',
      street: 'Calle San Sebastián',
    },
    duration: 600,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE LE CHIQUE',
    location: {
      lat: -33.40495,
      lng: -70.56333,
      country: 'Chile',
      country_code: 'CHL',
      state: 'Región Metropolitana de Santiago',
      county: 'Santiago',
      house_number: '6595',
      postal_code: '7550000',
      label: 'Calle Noruega 6595, 7550000 Las Condes, Región Metropolitana de Santiago, Chile',
      city: 'Las Condes',
      district: 'Las Condes',
      street: 'Calle Noruega',
    },
    duration: 300,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE PARADOR LA HUELLA',
    location: {
      lat: -33.398,
      lng: -70.56511,
      country: 'Chile',
      country_code: 'CHL',
      state: 'Región Metropolitana de Santiago',
      county: 'Santiago',
      house_number: '756',
      postal_code: '7550000',
      label: 'Calle Óscar Tenhann 756, 7550000 Las Condes, Región Metropolitana de Santiago, Chile',
      city: 'Las Condes',
      district: 'Las Condes',
      street: 'Calle Óscar Tenhann',
    },
    duration: 300,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE LE PATIO',
    location: {
      lat: -33.37384,
      lng: -70.50548,
      country: 'Chile',
      country_code: 'CHL',
      state: 'Región Metropolitana de Santiago',
      county: 'Santiago',
      house_number: '479',
      postal_code: '7550000',
      label:
        'Pasaje Fernández Concha 479, 7550000 Las Condes, Región Metropolitana de Santiago, Chile',
      city: 'Las Condes',
      district: 'Las Condes',
      street: 'Pasaje Fernández Concha',
    },
    duration: 300,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE OLIMPE',
    location: {
      lat: -33.4243,
      lng: -70.54941,
      country: 'Chile',
      country_code: 'CHL',
      state: 'Región Metropolitana de Santiago',
      county: 'Santiago',
      house_number: '7946',
      postal_code: '7550000',
      label:
        'Calle Pintor José Gil de Castro 7946, 7550000 Las Condes, Región Metropolitana de Santiago, Chile',
      city: 'Las Condes',
      district: 'Las Condes',
      street: 'Calle Pintor José Gil de Castro',
    },
    duration: 600,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE MIL',
    location: {
      lat: -33.409,
      lng: -70.50638,
      country: 'Chile',
      country_code: 'CHL',
      state: 'Región Metropolitana de Santiago',
      county: 'Santiago',
      house_number: '2882',
      postal_code: '7550000',
      label:
        'Avenida San Carlos de Apoquindo 2882, 7550000 Las Condes, Región Metropolitana de Santiago, Chile',
      city: 'Las Condes',
      district: 'Las Condes',
      street: 'Avenida San Carlos de Apoquindo',
    },
    duration: 300,
    timewindows: [[46800, 57600]],
  },
  {
    label: 'RESTAURANTE 040',
    location: {
      lat: -33.41078,
      lng: -70.57638,
      country: 'Chile',
      country_code: 'CHL',
      state: 'Región Metropolitana de Santiago',
      county: 'Santiago',
      house_number: '34',
      postal_code: '7550000',
      label: 'Calle La Gloria 34, 7550000 Las Condes, Región Metropolitana de Santiago, Chile',
      city: 'Las Condes',
      district: 'Escuela Militar',
      street: 'Calle La Gloria',
    },
    duration: 660,
    timewindows: [[46800, 57600]],
  },
  {
    label: 'RESTAURANTE LA DOCENA',
    location: {
      lat: -33.41445,
      lng: -70.60389,
      country: 'Chile',
      country_code: 'CHL',
      state: 'Región Metropolitana de Santiago',
      county: 'Santiago',
      house_number: '2808',
      postal_code: '7550000',
      label: 'Avenida Vitacura 2808, 7550000 Las Condes, Región Metropolitana de Santiago, Chile',
      city: 'Las Condes',
      district: 'Tajamar',
      street: 'Avenida Vitacura',
    },
    duration: 720,
    timewindows: [[46800, 57600]],
  },
  {
    label: 'RESTAURANTE MANU',
    location: {
      lat: -33.41657,
      lng: -70.60309,
      country: 'Chile',
      country_code: 'CHL',
      state: 'Región Metropolitana de Santiago',
      county: 'Santiago',
      house_number: '212',
      postal_code: '7550000',
      label: 'Calle Encomenderos 212, 7550000 Las Condes, Región Metropolitana de Santiago, Chile',
      city: 'Las Condes',
      district: 'Tajamar',
      street: 'Calle Encomenderos',
    },
    duration: 300,
    timewindows: [[46800, 57600]],
  },
  {
    label: 'RESTAURANTE MOCOTO',
    location: {
      lat: -33.42118,
      lng: -70.53962,
      country: 'Chile',
      country_code: 'CHL',
      state: 'Región Metropolitana de Santiago',
      county: 'Santiago',
      house_number: '8903',
      postal_code: '7550000',
      label:
        'Calle Alonso de Camargo 8903, 7550000 Las Condes, Región Metropolitana de Santiago, Chile',
      city: 'Las Condes',
      district: 'Las Condes',
      street: 'Calle Alonso de Camargo',
    },
    duration: 300,
    timewindows: [[46800, 57600]],
  },
  {
    label: 'RESTAURANTE OSAKA',
    location: {
      lat: -33.41684,
      lng: -70.57178,
      country: 'Chile',
      country_code: 'CHL',
      state: 'Región Metropolitana de Santiago',
      county: 'Santiago',
      house_number: '5315',
      postal_code: '7550000',
      label:
        'Calle Martín de Zamora 5315, 7550000 Las Condes, Región Metropolitana de Santiago, Chile',
      city: 'Las Condes',
      district: 'Las Condes',
      street: 'Calle Martín de Zamora',
    },
    duration: 300,
    timewindows: [[46800, 57600]],
  },
  {
    label: 'RESTAURANTE ELENA',
    location: {
      lat: -33.39253,
      lng: -70.50593,
      country: 'Chile',
      country_code: 'CHL',
      state: 'Región Metropolitana de Santiago',
      county: 'Santiago',
      house_number: '1044',
      postal_code: '7550000',
      label:
        'Calle Santa Verónica 1044, 7550000 Las Condes, Región Metropolitana de Santiago, Chile',
      city: 'Las Condes',
      district: 'Las Condes',
      street: 'Calle Santa Verónica',
    },
    duration: 300,
    timewindows: [[46800, 57600]],
  },
  {
    label: 'RESTAURANTE GRAN DABBANG',
    location: {
      lat: -33.35607,
      lng: -70.51149,
      country: 'Chile',
      country_code: 'CHL',
      state: 'Región Metropolitana de Santiago',
      county: 'Santiago',
      house_number: '1551',
      postal_code: '7690000',
      label:
        'Calle María Angélica Fernández 1551, 7690000 Lo Barnechea, Región Metropolitana de Santiago, Chile',
      city: 'Lo Barnechea',
      district: 'Lo Barnechea',
      street: 'Calle María Angélica Fernández',
    },
    duration: 300,
    timewindows: [[46800, 57600]],
  },
  {
    label: 'RESTAURANTE 99',
    location: {
      lat: -33.41621,
      lng: -70.58744,
      country: 'Chile',
      country_code: 'CHL',
      state: 'Región Metropolitana de Santiago',
      county: 'Santiago',
      house_number: '140',
      postal_code: '7550000',
      label: 'Calle Burgos 140, 7550000 Las Condes, Región Metropolitana de Santiago, Chile',
      city: 'Las Condes',
      district: 'El Golf',
      street: 'Calle Burgos',
    },
    duration: 600,
    timewindows: [[46800, 57600]],
  },
  {
    label: 'RESTAURANTE MALABAR',
    location: {
      lat: -33.39521,
      lng: -70.55792,
      country: 'Chile',
      country_code: 'CHL',
      state: 'Región Metropolitana de Santiago',
      county: 'Santiago',
      house_number: '853',
      postal_code: '7550000',
      label:
        'Calle Capitán Crosbie 853, 7550000 Las Condes, Región Metropolitana de Santiago, Chile',
      city: 'Las Condes',
      district: 'Las Condes',
      street: 'Calle Capitán Crosbie',
    },
    duration: 300,
    timewindows: [[46800, 57600]],
  },
  {
    label: 'RESTAURANTE MAYTA',
    location: {
      lat: -33.41239,
      lng: -70.57514,
      country: 'Chile',
      country_code: 'CHL',
      state: 'Región Metropolitana de Santiago',
      county: 'Santiago',
      house_number: '164',
      postal_code: '7550000',
      label:
        'Calle Rosa O\'Higgins 164, 7550000 Las Condes, Región Metropolitana de Santiago, Chile',
      city: 'Las Condes',
      district: 'Las Condes',
      street: 'Calle Rosa O\'Higgins',
    },
    duration: 300,
    timewindows: [[46800, 57600]],
  },
  {
    label: 'RESTAURANTE NARDA COMEDOR',
    location: {
      lat: -33.41166,
      lng: -70.50882,
      country: 'Chile',
      country_code: 'CHL',
      state: 'Región Metropolitana de Santiago',
      county: 'Santiago',
      house_number: '12116',
      postal_code: '7550000',
      label:
        'Calle Arlos Peña Otaegui 12116, 7550000 Las Condes, Región Metropolitana de Santiago, Chile',
      city: 'Las Condes',
      district: 'Las Condes',
      street: 'Calle Arlos Peña Otaegui',
    },
    duration: 300,
    timewindows: [[46800, 57600]],
  },
  {
    label: 'HOTEL NH',
    location: {
      lat: -33.39097,
      lng: -70.54282,
      country: 'Chile',
      country_code: 'CHL',
      state: 'Región Metropolitana de Santiago',
      county: 'Santiago',
      house_number: '9235',
      postal_code: '7550000',
      label: 'Calle Las Verbenas 9235, 7550000 Las Condes, Región Metropolitana de Santiago, Chile',
      city: 'Las Condes',
      district: 'Las Condes',
      street: 'Calle Las Verbenas',
    },
    duration: 600,
    timewindows: [[46800, 57600]],
  },
  {
    label: 'HOTEL MARIA BONITA',
    location: {
      lat: -33.42357,
      lng: -70.58895,
      country: 'Chile',
      country_code: 'CHL',
      state: 'Región Metropolitana de Santiago',
      county: 'Santiago',
      house_number: '3728',
      postal_code: '7550000',
      label:
        'Calle Martín de Zamora 3728, 7550000 Las Condes, Región Metropolitana de Santiago, Chile',
      city: 'Las Condes',
      district: 'El Golf',
      street: 'Calle Martín de Zamora',
    },
    duration: 300,
    timewindows: [[46800, 57600]],
  },
  {
    label: 'HOTEL W',
    location: {
      lat: -33.40609,
      lng: -70.57192,
      country: 'Chile',
      country_code: 'CHL',
      state: 'Región Metropolitana de Santiago',
      county: 'Santiago',
      house_number: '399',
      postal_code: '7550000',
      label: 'Calle Rosario Norte 399, 7550000 Las Condes, Región Metropolitana de Santiago, Chile',
      city: 'Las Condes',
      district: 'Las Condes',
      street: 'Calle Rosario Norte',
    },
    duration: 780,
    timewindows: [[46800, 57600]],
  },
  {
    label: 'HOTEL SHERATON',
    location: {
      lat: -33.42175,
      lng: -70.54985,
      country: 'Chile',
      country_code: 'CHL',
      state: 'Región Metropolitana de Santiago',
      county: 'Santiago',
      house_number: '1222',
      postal_code: '7550000',
      label: 'Calle Tezcuco 1222, 7550000 Las Condes, Región Metropolitana de Santiago, Chile',
      city: 'Las Condes',
      district: 'Las Condes',
      street: 'Calle Tezcuco',
    },
    duration: 840,
    timewindows: [[46800, 57600]],
  },
  {
    label: 'BAR AMSTERDAM',
    location: {
      lat: -33.42501,
      lng: -70.56298,
      country: 'Chile',
      country_code: 'CHL',
      state: 'Región Metropolitana de Santiago',
      county: 'Santiago',
      house_number: '6200',
      postal_code: '7550000',
      label:
        'Avenida Isabel la Católica 6200, 7550000 Las Condes, Región Metropolitana de Santiago, Chile',
      city: 'Las Condes',
      district: 'Las Condes',
      street: 'Avenida Isabel la Católica',
    },
    duration: 300,
    timewindows: [[46800, 57600]],
  },
  {
    label: 'RESTAURANTE COLOMBIA PAN Y SABOR',
    location: {
      lat: -33.4098,
      lng: -70.57448,
      country: 'Chile',
      country_code: 'CHL',
      state: 'Región Metropolitana de Santiago',
      county: 'Santiago',
      house_number: '47',
      postal_code: '7550000',
      label: 'Calle Warren Smith 47, 7550000 Las Condes, Región Metropolitana de Santiago, Chile',
      city: 'Las Condes',
      district: 'Escuela Militar',
      street: 'Calle Warren Smith',
    },
    duration: 300,
    timewindows: [[46800, 57600]],
  },
  {
    label: 'RESTAURANTE LA VIETNAMITA',
    location: {
      lat: -33.40538,
      lng: -70.51136,
      country: 'Chile',
      country_code: 'CHL',
      state: 'Región Metropolitana de Santiago',
      county: 'Santiago',
      house_number: '2334',
      postal_code: '7550000',
      label:
        'Calle Los Algarrobos 2334, 7550000 Las Condes, Región Metropolitana de Santiago, Chile',
      city: 'Las Condes',
      district: 'Las Condes',
      street: 'Calle Los Algarrobos',
    },
    duration: 300,
    timewindows: [[28800, 46800]],
  },
  {
    label: 'RESTAURANTE EL TLAXCAL',
    location: {
      lat: -33.4098,
      lng: -70.57448,
      country: 'Chile',
      country_code: 'CHL',
      state: 'Región Metropolitana de Santiago',
      county: 'Santiago',
      house_number: '47',
      postal_code: '7550000',
      label: 'Calle Warren Smith 47, 7550000 Las Condes, Región Metropolitana de Santiago, Chile',
      city: 'Las Condes',
      district: 'Escuela Militar',
      street: 'Calle Warren Smith',
    },
    duration: 300,
    timewindows: [[28800, 46800]],
  },
  {
    label: 'RESTAURANTE LA CHILANGUITA',
    location: {
      lat: -33.41551,
      lng: -70.59097,
      country: 'Chile',
      country_code: 'CHL',
      state: 'Región Metropolitana de Santiago',
      county: 'Santiago',
      house_number: '3794',
      postal_code: '7550000',
      label: 'Avenida Apoquindo 3794, 7550000 Las Condes, Región Metropolitana de Santiago, Chile',
      city: 'Las Condes',
      district: 'El Golf',
      street: 'Avenida Apoquindo',
    },
    duration: 600,
    timewindows: [[28800, 46800]],
  },
  {
    label: 'RESTAURANTE LA COSA NOSTRA',
    location: {
      lat: -33.41902,
      lng: -70.55332,
      country: 'Chile',
      country_code: 'CHL',
      state: 'Región Metropolitana de Santiago',
      county: 'Santiago',
      house_number: '1075',
      postal_code: '7550000',
      label: 'Avenida Tomás Moro 1075, 7550000 Las Condes, Región Metropolitana de Santiago, Chile',
      city: 'Las Condes',
      district: 'Las Condes',
      street: 'Avenida Tomás Moro',
    },
    duration: 300,
    timewindows: [[28800, 46800]],
  },
  {
    label: 'RESTAURANTE FISHERS',
    location: {
      lat: -33.41634,
      lng: -70.52029,
      country: 'Chile',
      country_code: 'CHL',
      state: 'Región Metropolitana de Santiago',
      county: 'Santiago',
      postal_code: '7550000',
      label: 'Calle Camino Gran Vista, 7550000 Las Condes, Región Metropolitana de Santiago, Chile',
      city: 'Las Condes',
      district: 'Las Condes',
      street: 'Calle Camino Gran Vista',
    },
    duration: 300,
    timewindows: [[28800, 46800]],
  },
  {
    label: 'RESTAURANTE DE TAPAS',
    location: {
      lat: -33.41856,
      lng: -70.57159,
      country: 'Chile',
      country_code: 'CHL',
      state: 'Región Metropolitana de Santiago',
      county: 'Santiago',
      house_number: '5180',
      postal_code: '7550000',
      label:
        'Avenida Cristóbal Colón 5180, 7550000 Las Condes, Región Metropolitana de Santiago, Chile',
      city: 'Las Condes',
      district: 'Las Condes',
      street: 'Avenida Cristóbal Colón',
    },
    duration: 300,
    timewindows: [[28800, 46800]],
  },
  {
    label: 'RESTAURANTE LAS CAZUELAS',
    location: {
      lat: -33.40602,
      lng: -70.55748,
      country: 'Chile',
      country_code: 'CHL',
      state: 'Región Metropolitana de Santiago',
      county: 'Santiago',
      house_number: '7039',
      postal_code: '7550000',
      label:
        'Calle General Carol Urzúa 7039, 7550000 Las Condes, Región Metropolitana de Santiago, Chile',
      city: 'Las Condes',
      district: 'Las Condes',
      street: 'Calle General Carol Urzúa',
    },
    duration: 600,
    timewindows: [[28800, 46800]],
  },
  {
    label: 'RESTAURANTE PALOMARES',
    location: {
      lat: -33.41098,
      lng: -70.57871,
      country: 'Chile',
      country_code: 'CHL',
      state: 'Región Metropolitana de Santiago',
      county: 'Santiago',
      house_number: '99',
      postal_code: '7550000',
      label: 'Calle Orinoco 99, 7550000 Las Condes, Región Metropolitana de Santiago, Chile',
      city: 'Las Condes',
      district: 'Escuela Militar',
      street: 'Calle Orinoco',
    },
    duration: 300,
    timewindows: [[28800, 46800]],
  },
  {
    label: 'RESTAURANTE SOUL',
    location: {
      lat: -33.40478,
      lng: -70.52098,
      country: 'Chile',
      country_code: 'CHL',
      state: 'Región Metropolitana de Santiago',
      county: 'Santiago',
      house_number: '1973',
      postal_code: '7550000',
      label: 'Calle Las Tórtolas 1973, 7550000 Las Condes, Región Metropolitana de Santiago, Chile',
      city: 'Las Condes',
      district: 'Las Condes',
      street: 'Calle Las Tórtolas',
    },
    duration: 900,
    timewindows: [[28800, 46800]],
  },
  {
    label: 'RESTAURANTE CLORODMIRA',
    location: {
      lat: -33.37965,
      lng: -70.51249,
      country: 'Chile',
      country_code: 'CHL',
      state: 'Región Metropolitana de Santiago',
      county: 'Santiago',
      house_number: '12368',
      postal_code: '7550000',
      label: 'Camino La Viña 12368, 7550000 Las Condes, Región Metropolitana de Santiago, Chile',
      city: 'Las Condes',
      district: 'Las Condes',
      street: 'Camino La Viñ,a',
    },
    duration: 960,
    timewindows: [
      [32400, 50400],
      [61200, 72000],
    ],
  },
];
