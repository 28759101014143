import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Button, DefaultAvatar, Form, VSpacer } from '@sm-highway-web/react-components';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { intl } from '../../../helpers/IntGlobalProvider';
import { InfoError, Projects } from '../../../redux/actions';
import { projectHelpers } from '../../../redux/helpers';
import FormattedMessage from '../../FormattedMessageCustom';

const bulletColors = [
  '#005082',
  '#CDDC39',
  '#2196F3',
  'rgb(140, 138, 254)',
  'rgb(3, 77, 82)',
  'rgb(50, 232, 162)',
  'rgb(178, 71, 29)',
];

const styles = {
  dialogRoot: {
    maxWidth: '700px',
    width: '100%',
  },
  listProjects: {
    maxHeight: '250px',
    overflowY: 'auto',
    flexWrap: 'unset',
  },
  bullet: {
    borderRadius: '12px',
    height: '50px',
    width: '100%',
    display: 'inline-flex',
    backgroundColor: '#F1F1F1',
  },
  bulletItem: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    minWidth: '8px',
  },
  projectsList: {
    padding: '8px',
  },
  projectsListItem: {
    padding: '8px',
    borderTop: '1px solid lightgray',
  },
};

type OwnOrganizationManageResourcesDialogProps = {
  classes: {
    dialogRoot: string;
    bullet: string;
    bulletItem: string;
    projectsList: string;
    projectsListItem: string;
  };
  organizationTotalVehicles: number;
  organizationProjects: {}[];
  actions: {
    editProjectsResources: (...args: any[]) => any;
    sendStatusToSnackbar?: (...args: any[]) => any;
  };
  onClose: (...args: any[]) => any;
  isOpen?: boolean;
  organizationLabel?: string;
};

type OrganizationManageResourcesDialogProps = OwnOrganizationManageResourcesDialogProps;

const OrganizationManageResourcesDialog = ({
  classes,
  organizationTotalVehicles,
  organizationProjects,
  organizationLabel,
  isOpen,
  onClose,
  actions,
}: OrganizationManageResourcesDialogProps) => {
  const [projects, setProjects] = useState({});
  const [disabledSubmitButton, setDisabledSubmitButton] = useState(false);

  useEffect(() => {
    if (!!organizationProjects && organizationProjects.length !== Object.keys(projects).length) {
      setProjects(
        organizationProjects.reduce(
          (old: any, next: any) => ({
            ...old,
            [next.id]: {
              id: next.id,
              label: next.label,
              max_vehicles: next.limits ? next.limits.max_vehicles : 0,
            },
          }),
          {}
        )
      );
    }
  }, [organizationProjects, projects, setProjects]);

  const closeDialog = () => {
    setProjects({});
    setDisabledSubmitButton(false);
    onClose();
  };

  const updateResources = async () => {
    setDisabledSubmitButton(true);

    const projectsMap: any = [];
    Object.keys(projects).forEach((id) => {
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      projectsMap.push({ id, limits: { max_vehicles: projects[id].max_vehicles } });
    });

    await actions.editProjectsResources(projectsMap);

    setTimeout(() => closeDialog(), 500);
  };

  const checkIfProjectIsModified = (project: any) => {
    return !!organizationProjects.find(
      (pro: any) => pro.id === project.id && pro.limits?.max_vehicles !== project.max_vehicles
    );
  };

  const isModified = () => {
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const projectsArray = Object.keys(projects).map((id) => projects[id]);
    return projectsArray.filter((project) => checkIfProjectIsModified(project)).length > 0;
  };

  const getUsedVehicles = () => {
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const projectsArray = Object.keys(projects).map((id) => projects[id]);
    return projectsArray.reduce((old, next) => next.max_vehicles + old, 0);
  };

  return (
    <Dialog classes={{ paper: classes.dialogRoot }} open={!!isOpen} onClose={closeDialog}>
      <DialogTitle>
        <Grid container direction="column" spacing={1}>
          <Grid item>
            <FormattedMessage id="settings.organization.projects.resources.manage.title" />
          </Grid>

          <Grid item>
            <Typography>
              <FormattedMessage
                id="settings.organization.projects.resources.manage.info"
                values={{
                  used: organizationProjects
                    ? organizationProjects.reduce(
                        (old: any, next: any) => (next.limits ? next.limits.max_vehicles : 0) + old,
                        0
                      )
                    : 0,
                  total: organizationTotalVehicles,
                }}
              />
            </Typography>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <div className={classes.bullet}>
          {Object.keys(projects).map((id, idx) => {
            // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
            const project = projects[id];
            return (
              <Tooltip
                key={`bullet-${project.id}`}
                title={
                  <Grid container justify="center" direction="column">
                    <Typography align="center" style={{ color: 'white', fontSize: '12px' }}>
                      {`${projectHelpers.getProjectLabel(project.label, organizationLabel)}`}
                    </Typography>

                    <Typography align="center" style={{ color: 'white', fontSize: '12px' }}>
                      <FormattedMessage
                        id="settings.organization.projects.resources.manage.vehicles"
                        values={{
                          vehicles: project.max_vehicles,
                        }}
                      />
                    </Typography>
                  </Grid>
                }
              >
                <div
                  className={classes.bulletItem}
                  style={{
                    width: `${(project.max_vehicles / organizationTotalVehicles) * 100}%`,
                    backgroundColor: bulletColors[idx % bulletColors.length],
                    borderTopLeftRadius: `${idx === 0 ? '12px' : 'none'}`,
                    borderBottomLeftRadius: `${idx === 0 ? '12px' : 'none'}`,
                    borderTopRightRadius: `${
                      idx === Object.keys(projects).length - 1 ? '12px' : 'none'
                    }`,
                    borderBottomRightRadius: `${
                      idx === Object.keys(projects).length - 1 ? '12px' : 'none'
                    }`,
                  }}
                >
                  <Typography
                    style={{
                      display: `${
                        (project.max_vehicles / organizationTotalVehicles) * 100 < 8
                          ? 'none'
                          : 'block'
                      }`,
                      margin: '0 20px',
                      color: 'white',
                      textAlign: 'center',
                      width: '100%',
                    }}
                    noWrap
                  >
                    {`${projectHelpers.getProjectLabel(project.label, organizationLabel)}`}
                  </Typography>
                </div>
              </Tooltip>
            );
          })}
        </div>

        <VSpacer large />

        <Grid container direction="column">
          <Form
            lazyValidation
            onSubmit={updateResources}
            direction="column"
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'listProjects' does not exist on type '{ ... Remove this comment to see the full error message
            className={classes.listProjects}
          >
            {Object.keys(projects).map((id, idx) => {
              // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
              const project = projects[id];
              return (
                <Grid
                  item
                  container
                  style={
                    idx === Object.keys(projects).length - 1
                      ? { borderBottom: '1px solid lightgray' }
                      : {}
                  }
                  className={classes.projectsListItem}
                  justify="space-between"
                  key={`project-${project.id}`}
                >
                  <Grid item md={10} container alignItems="center" style={{ flexWrap: 'unset' }}>
                    <Grid item>
                      <DefaultAvatar
                        labelOverlayed
                        key={`avatar${project.id}`}
                        label={`${projectHelpers.getProjectLabel(
                          project.label,
                          organizationLabel
                        )}`}
                        avatar={project.avatar}
                      />
                    </Grid>

                    <Grid item style={{ width: '100%' }}>
                      <Tooltip
                        title={`${projectHelpers.getProjectLabel(
                          project.label,
                          organizationLabel
                        )}`}
                      >
                        <Typography style={{ margin: '0 8px' }} noWrap>
                          {`${projectHelpers.getProjectLabel(project.label, organizationLabel)}`}
                        </Typography>
                      </Tooltip>
                    </Grid>
                  </Grid>

                  <Grid item md={2} container justify="flex-end">
                    <TextField
                      type="number"
                      placeholder={intl.formatMessage({
                        id: 'settings.organization.projects.new.max_vehicles_ph',
                      })}
                      style={{ width: '80px', borderRadius: '8px', backgroundColor: '#E9E9E9' }}
                      onChange={(evt) => {
                        const currentProject = { ...project };
                        currentProject.max_vehicles = parseInt(evt.target.value, 10);
                        setProjects({
                          ...projects,
                          [project.id]: currentProject,
                        });
                      }}
                      value={project.max_vehicles}
                      InputProps={{
                        disableUnderline: true,
                        inputProps: {
                          min: 0,
                          // max: organizationTotalVehicles - usedVehicles,
                          max:
                            project.max_vehicles + (organizationTotalVehicles - getUsedVehicles()),
                          style: { textAlign: 'end' },
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              );
            })}

            <VSpacer large />
          </Form>
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={closeDialog} color="secondary">
          <FormattedMessage id="settings.buttoncancel" />
        </Button>
        <Button
          onClick={updateResources}
          color="primary"
          disabled={disabledSubmitButton || !isModified()}
        >
          <FormattedMessage id="settings.buttonupdate" />
        </Button>
      </DialogActions>
      <VSpacer small />
    </Dialog>
  );
};

OrganizationManageResourcesDialog.defaultProps = {
  isOpen: false,
  organizationLabel: '',
};

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    editProjectsResources: (projectsResources: any) =>
      dispatch(Projects.editProjectsResources(projectsResources)),
    sendStatusToSnackbar: (msg: any, type: any) =>
      dispatch(InfoError.sendStatusToSnackbar(msg, type)),
  },
});

const mapStateToProps = (state: any) => {
  return {
    organizationLabel: state.organization.organization && state.organization.organization.label,
    organizationProjects: projectHelpers.orderProjects(state.projects.projects),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ dialogRoot: { maxWidth: string... Remove this comment to see the full error message
)(withStyles(styles)(OrganizationManageResourcesDialog));
