import { Grid, IconButton, withStyles } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { HSpacer, SearchInput, VSpacer } from '@sm-highway-web/react-components';
import { IPlanData } from 'highway-api/dist/common/interfaces/plans';
import { IProjectData } from 'highway-api/dist/common/interfaces/projects';
import { IRouteDataExtended } from 'highway-api/dist/common/interfaces/routes';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import EmptyView from '../../../assets/img/ic-new-route.svg';
import VirtualizedMaterialDataTable from '../../datatable/VirtualizedDataTable';
import FormattedMessage from '../../FormattedMessageCustom';
import RouteMenu from '../../menus/RouteMenu';
import PopoverMore3Points from '../../PopoverMore3Points';
import FormCreateField from './FormCreateField';
import getColumns from './RoutesListColumns';

const styles = (_theme: any) => ({
  rootMenu: {
    width: '240px',
  },
  menuItem: {
    height: '42px',
  },
  grid: {
    width: '100%',
    maxHeight: 'calc(100% - 96px)',
    display: 'flex',
  },
  slimbutton: {
    padding: 0,
    height: '26px',
    boxShadow: 'none',
    color: 'white',
  },
  slimbuttonnowhite: { padding: 0, height: '24px', boxShadow: 'none' },
  centered: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  addElementContainer: {
    minHeight: '40px',
    maxHeight: '50px',
    height: '100%',
    width: '100%',
    position: 'absolute',
    bottom: 0,
    '&:hover': { backgroundColor: '#FDF6ED' },
  },
});

type RoutesListProps = {
  classes: {
    rootMenu: string;
    menuItem: string;
    grid: string;
    slimbutton: string;
    slimbuttonnowhite: string;
    centered: string;
    addElementContainer: string;
  };
  plan: IPlanData;
  activeProject: IProjectData;
  openRoutesModal: (...args: any[]) => any;
  setDialog: (...args: any[]) => any;
  setRightPanelVisible: (...args: any[]) => any;
  setRoute: (...args: any[]) => any;
  updateRoutes: (...args: any[]) => any;
  addRoutesToAPlan: (...args: any[]) => any;
  saveRouteAsVehicle: (...args: any[]) => any;
  selectedRoutes: IRouteDataExtended[];
  tableColumns: { [key: string]: boolean };
  setListSelectedRoutes: (...args: any[]) => any;
};

const RoutesList = ({
  classes,
  plan,
  openRoutesModal,
  activeProject,
  setDialog,
  setRightPanelVisible,
  setRoute,
  updateRoutes,
  addRoutesToAPlan,
  saveRouteAsVehicle,
  selectedRoutes,
  setListSelectedRoutes,
  tableColumns,
}: RoutesListProps) => {
  const [filterText, setFilterText] = useState('');
  const [filteredRoutes, setFilteredRoutes] = useState<IRouteDataExtended[] | undefined>(undefined);
  const [contextAnchorTableRoutes, setContextAnchorTableRoutes] = useState<{ screenPosition: { top: number; left: number }; doc?: IRouteDataExtended } | undefined>(undefined);

  const handleSearchRoutes = useCallback(() => {
    if (filterText !== '') {
      const regexx = new RegExp(`${filterText.toLowerCase()}`, `ig`);
      const routesFiltered: IRouteDataExtended[] = plan.routes.filter(
        (r: any) =>
          r.label.match(regexx) ||
          (r.start_location && r.start_location.label && r.start_location.label.match(regexx)) ||
          (r.end_location && r.end_location.label && r.end_location.label.match(regexx))
      );
      setFilteredRoutes(routesFiltered);
    } else {
      setFilteredRoutes(undefined);
    }
  }, [plan, filterText]);

  useEffect(() => {
    if (plan && plan.routes) {
      handleSearchRoutes();
    }
  }, [plan, handleSearchRoutes]);

  return (
    <Grid container direction="column" style={{ height: '100%', position: 'relative' }}>
      <Grid item style={{ height: '48px', width: '100%', paddingRight: '6px' }}>
        <Grid container style={{ height: '100%' }} justify="flex-end">
          {plan && (
            <Grid item style={{ width: '300px' }}>
              <Grid container direction="column" justify="center" style={{ height: '100%' }}>
                <SearchInput
                  onTextChange={_.debounce((text: any) => {
                    setFilterText(text);
                    handleSearchRoutes();
                  }, 300)}
                  searchLive
                />
              </Grid>
            </Grid>
          )}
          <HSpacer medium />
          <Grid item className={classes.centered}>
            <PopoverMore3Points
              large
              idButton="routespopover"
              arrayActions={[
                {
                  close: true,
                  onClick: openRoutesModal,
                  formattedMessage: 'routes.actions.importroutes',
                },
                {
                  close: true,
                  onClick: () => setDialog('configureColumnsRoutes'),
                  formattedMessage: 'routes.actions.configure_columns',
                },
                null,
                {
                  close: true,
                  disabled: selectedRoutes.length === 0,
                  onClick: () => setDialog('constraintsRoutes'),
                  formattedMessage: 'routes.actions.change_constraints',
                },
                {
                  close: true,
                  disabled: !(selectedRoutes.length === 1 && !selectedRoutes[0].vehicle_id),
                  onClick: () => {
                    saveRouteAsVehicle(selectedRoutes[0]);
                  },
                  formattedMessage: 'routes.actions.saveasvehicle',
                },
                {
                  close: true,
                  disabled:
                    selectedRoutes.length === 0 ||
                    selectedRoutes.filter((route: any) => route.is_locked).length ===
                      selectedRoutes.length,
                  onClick: () => {
                    selectedRoutes.forEach((route: any) => (route.is_locked = true));
                    updateRoutes(selectedRoutes, { is_locked: true });
                  },
                  formattedMessage: 'routes.actions.lock_route',
                },
                {
                  close: true,
                  disabled:
                    selectedRoutes.length === 0 ||
                    selectedRoutes.filter((route: any) => !route.is_locked).length ===
                      selectedRoutes.length,
                  onClick: () => {
                    selectedRoutes.forEach((route: any) => (route.is_locked = false));
                    updateRoutes(selectedRoutes, { is_locked: false });
                  },
                  formattedMessage: 'routes.actions.unlock_route',
                },
                {
                  close: true,
                  disabled:
                    selectedRoutes.length === 0 ||
                    selectedRoutes.filter((route: any) => route.is_locked).length ===
                      selectedRoutes.length,
                  onClick: () => setDialog('deleteRoutes'),
                  formattedMessage: 'routes.actions.delete_route',
                },
              ]}
            />
          </Grid>
        </Grid>
      </Grid>
      {plan && (
        <Grid
          item
          className={classes.grid}
          style={{
            overflowX: 'auto',
            overflowY: 'hidden',
            height: '100%',
          }}
        >
          {plan.routes && plan.routes.length === 0 && (
            <Grid
              container
              direction="column"
              alignItems="center"
              justify="center"
              style={{ padding: '32px', overflowY: 'auto' }}
              wrap="nowrap"
            >
              <img
                alt="Upload xlsx"
                src={EmptyView}
                width="180px"
                onClick={() => openRoutesModal(true)}
                style={{
                  cursor: 'pointer',
                }}
              />
              <VSpacer medium />
              <div style={{ maxWidth: '480px', textAlign: 'center', color: '#707070' }}>
                <FormattedMessage id="routes.noclients.content" />
              </div>
            </Grid>
          )}
          {plan.routes && plan.routes.length > 0 && (
            <VirtualizedMaterialDataTable
              docs={filteredRoutes || plan.routes}
              showCheckboxes
              onRowDoubleClick={() => setRightPanelVisible(true)}
              onRowSelected={(doc: any) => {
                setRoute(doc);
              }}
              handleOnContextMenu={(e: any, doc: any) => {
                e.preventDefault();
                setContextAnchorTableRoutes({
                  screenPosition: {
                    top: e.clientY,
                    left: e.clientX,
                  },
                  doc,
                });
              }}
              onMultipleSelection={(documents: any) => setListSelectedRoutes(documents)}
              selectedDocs={selectedRoutes}
              mapping={[
                ...getColumns(activeProject, plan.routes, tableColumns, (e, doc) => {
                  e.stopPropagation();
                  setRoute(doc);
                  setRightPanelVisible(true);
                }),
                {
                  column: 'id',
                  align: 'right',
                  width: 100,
                  flex: 1,
                  formatter: (id: string, doc: any, i: any, hover: any) => (
                    <Grid container justify="flex-end">
                      {hover && (
                        <>
                          <IconButton
                            className={classes.slimbuttonnowhite}
                            onClick={(e) => {
                              e.stopPropagation();
                              setRoute(doc);
                              setRightPanelVisible(true);
                            }}
                          >
                            <Edit style={{ height: '22px' }} />
                          </IconButton>

                          <HSpacer small />

                          <RouteMenu
                            type="popover"
                            plan={plan}
                            route={doc}
                            setRightPanelVisible={setRightPanelVisible}
                          />
                        </>
                      )}
                    </Grid>
                  ),
                },
              ]}
            />
          )}
        </Grid>
      )}
      <Grid item className={classes.addElementContainer}>
        {plan && (
          <FormCreateField
            entity="route"
            actualLength={plan.routes.length}
            maxLength={activeProject?.limits ? activeProject.limits.max_vehicles : 0}
            handleCreate={(route) => addRoutesToAPlan(plan.id, [route])}
            countryCode={
              plan.optimizer_config ? plan.optimizer_config.operation_country : undefined
            }
            searchMenuPlacement="top"
          />
        )}
      </Grid>

      {contextAnchorTableRoutes && contextAnchorTableRoutes.doc && (
        <RouteMenu
          plan={plan}
          route={contextAnchorTableRoutes.doc}
          contextAnchor={contextAnchorTableRoutes}
          setContextAnchor={setContextAnchorTableRoutes}
          setRightPanelVisible={setRightPanelVisible}
        />
      )}
    </Grid>
  );
};

const mapStateToProps = (state: any) => {
  return {
    activeProject: state.projects.activeProject,
    tableColumns: state.routes.tableColumns,
  };
};

// @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ rootMenu: { width: string; }; ... Remove this comment to see the full error message
export default connect(mapStateToProps, null)(withStyles(styles)(RoutesList));
