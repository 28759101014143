import React, { useEffect, useState } from 'react';
import { BlockPicker, ColorResult } from 'react-color';
import styled from 'styled-components';
import { Label as StyledLabel } from './FormFields.styled';

type ColorSampleProps = {
  color?: string;
}

const ColorSample = styled.div`
  height: 20px;
  width: 20px;
  ${(props: ColorSampleProps) =>
    `background-color: ${props.color ? props.color : 'transparent'};`}
  border: 1px solid #eff0f3;
  border-radius: 6px;
  cursor: pointer;
`;

type FormColorPickerProps = {
  color?: string;
  label?: string | React.ReactElement | {};
  presetColors?: string[];
  onUpdate: (...args: any[]) => any;
};

const FormColorPicker = ({ color, label, presetColors, onUpdate }: FormColorPickerProps) => {
  
  const [currentColor, setCurrentColor] = useState<string | undefined>();
  const [showColorPicker, setShowColorPicker] = useState<boolean>(false);

  useEffect(() => {
    if(color) {
      setCurrentColor(color);
    }
  }, [color]);

  const handleColorChange = ({ hex } : ColorResult) => {
    onUpdate(hex);
    setShowColorPicker(false);
  };

  return (
    <>
      {label && <StyledLabel>{label}</StyledLabel>}
      
      <div style={{ position: 'relative' }}>
        <ColorSample
          onClick={() => {
            setShowColorPicker(!showColorPicker);
          }}
          color={currentColor}
        />

        {showColorPicker ? (
          <div
            style={{
              position: 'absolute',
              top: '100%',
              left: '50%',
              transform: 'translateX(-50%)',
              marginTop: 15,
              zIndex: 1,
              boxShadow: 'rgba(0, 0, 0, 0.16) 0px 3px 6px, rgba(0, 0, 0, 0.23) 0px 3px 6px',
              borderRadius: '6px',
            }}
          >
            <BlockPicker
              color={currentColor}
              colors={presetColors || []}
              onChangeComplete={handleColorChange}
            />
          </div>
        ): null}
      </div>
    </>
  );
};

export default FormColorPicker;
