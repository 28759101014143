import { CircularProgress, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Button, Form, InputText, VSpacer } from '@sm-highway-web/react-components';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import logoblack from '../assets/img/logo_black.svg';
import * as routes from '../constants/Routing';
import { User } from '../redux/actions';
import eventsPanel, { PageViewPages } from '../services/events';
import FormattedMessage from './FormattedMessageCustom';
import Link from './Link';

const styles = {
  logo: {
    width: '70%',
  },
};

type LoginContainerProps = {
  classes: {
    logo?: string;
  };
  actions: {
    login: (...args: any[]) => any;
  };
  loginError: boolean;
};

const LoginContainer = ({ actions, classes, loginError }: LoginContainerProps) => {
  const [isLoaded, setIsLoaded] = useState<boolean>(false);
  const [email, setEmail] = useState<string>('');
  const [password, setPassword] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean | undefined>(false);

  useEffect(() => {
    if (!isLoaded) {
      eventsPanel.pageView(PageViewPages.login);
      setIsLoaded(true);
    }
  }, [isLoaded]);

  useEffect(() => {
    if(loginError) {
      setTimeout(() => {
        setIsLoading(false);
      }, 800);
    }
  }, [loginError]);

  const handleLogin = async () => {
    if(!isLoading) {
      setIsLoading(true);
      actions.login(email.toLowerCase(), password);
    }
  };

  return (
    <Grid container direction="column" justify="space-between" style={{ height: '100%' }}>
      <Grid item>
        <Grid container direction="column">
          <Grid container justify="center">
            <img src={logoblack} alt="" className={classes.logo} />
          </Grid>
          <VSpacer />
          <VSpacer />
          <Grid item>
            <Typography variant="h2" align="center">
              <FormattedMessage id="login.login" />
            </Typography>
          </Grid>
          <Grid item>
            <Form lazyValidation onSubmit={handleLogin} direction="column">
              <VSpacer className="form-ignore" />
              <InputText
                id="email"
                type="email"
                label={<FormattedMessage id="login.email" />}
                value={email}
                onChange={(mail: any) => setEmail(mail)}
                margin="none"
                validators={['required', 'email']}
              />
              <InputText
                id="password"
                type="password"
                label={<FormattedMessage id="login.password" />}
                value={password}
                onChange={(psw: any) => setPassword(psw)}
                margin="none"
                validators={['required']}
                customValidator={(text: any) => {
                  if (text.length < 8) {
                    return <FormattedMessage id="password.errorhint" />;
                  }
                  return undefined;
                }}
              />
              <VSpacer small className="form-ignore" />
              {/* <FormCheckbox label={<FormattedMessage id="login.remember" />} /> */}
              <VSpacer className="form-ignore" />
              {isLoading ? (
                <Grid container justify="center">
                  <CircularProgress className="form-ignore" />
                </Grid>
              ) : (
                <Button fullWidth type="submit" variant="contained">
                  <FormattedMessage id="login.login" />
                </Button>
              )}
            </Form>
          </Grid>
        </Grid>
      </Grid>
      <VSpacer small />
      <Grid item>
        <Grid container justify="space-between">
          <Grid item>
            <Typography>
              <Link to={routes.REGISTER}>
                <FormattedMessage id="login.register" />
              </Link>
            </Typography>
          </Grid>
          <Grid item>
            <Typography>
              <Link to={routes.RESETPASSWORD}>
                <FormattedMessage id="login.forgottenpass" />
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state: any) => {
  return {
    loginError: state.user.loginError,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    login: (email: any, password: any) => dispatch(User.logIn(email, password)),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(LoginContainer));
