import {
  Grid,
} from '@material-ui/core';
import { Map, Marker, VSpacer } from '@sm-highway-web/react-components';
import { IProjectData, IServiceTrackingEmail } from 'highway-api/dist/common/interfaces/projects';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import LocationIcon from '../../../../assets/img/custom_field-location.svg';
import NumericalIcon from '../../../../assets/img/custom_field-numerical.svg';
import TextIcon from '../../../../assets/img/custom_field-text.svg';
import { intl } from '../../../../helpers/IntGlobalProvider';
import { ObjectOfAny } from '../../../../interfaces/common';
import { IUserData } from '../../../../interfaces/users';
import { Projects } from '../../../../redux/actions';
import ProjectCustomField from '../../../custom-fields/ProjectCustomField';
import FormattedMessage from '../../../FormattedMessageCustom';
import MobileMockup from '../../../mobile-mockup/MobileMockup';
import { CustomLink } from './ProjectCommunication.styled';

const calculateTimeLeft = (date: any) => {
  const now = moment(new Date());
  const updatedAt = moment(new Date(date));
  const diffDays = now.diff(updatedAt, 'days');
  if (diffDays > 0) {
    return `${diffDays} ${intl.formatMessage({ id: 'days' })}`;
  }
  const diffHours = now.diff(updatedAt, 'hours');
  if (diffHours > 0) {
    return `${diffHours} ${intl.formatMessage({ id: 'hours' })}`;
  }
  const diffMinutes = now.diff(updatedAt, 'minutes');
  if (diffMinutes > 0) {
    return `${diffMinutes} ${intl.formatMessage({ id: 'minutes' })}`;
  }
  const diffSeconds = now.diff(updatedAt, 'seconds');
  if (diffSeconds > 0) {
    return `${diffSeconds} ${intl.formatMessage({ id: 'seconds' })}`;
  }
  return '';
};

const FAKE_CUSTOMER_DATA = {
  label: 'Bar El Tomas de Sarria',
  updated_at: '2021-01-02T12:31:45.000Z',
  service_location: {
    lat: 41.39758,
    lng: 2.12306,
  },
  route_real_track: {
    lat: 41.38506,
    lng: 2.1734,
  },
  eta: '18:20-18:50',
  stops_left: 5,
  planned_arrival_time: 62763,
  planned_departure_time: 63363,
  phone: '00000000',
};

type PreviewMapProps = {
  driverPosition?: {
    lat: number;
    lng: number;
  },
  servicePosition: {
    lat: number;
    lng: number;
  }
};

const PreviewMap = ({ driverPosition, servicePosition }: PreviewMapProps) => {
  useEffect(() => {
    // Hack for setting the designs border-radius.
    const elements = document.getElementsByClassName('leaflet-container');
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'style' does not exist on type 'Element'.
    if (elements[0]) elements[0].style.borderRadius = '15px';
  }, []);

  return <Map height="100%" zoomControl={false} scrollWheelZoom={false}>
    {driverPosition && <Marker key="route-position" lat={driverPosition.lat} lng={driverPosition.lng} kind="truck" />}
    <Marker key="service-position" lat={servicePosition.lat} lng={servicePosition.lng} />
  </Map>;
};

const sectionsDescriptionFields: ObjectOfAny = {
  service_tracker: {
    label: {
      iconSrc: TextIcon,
    } as ObjectOfAny,
    driver_position: {
      iconSrc: LocationIcon,
    } as ObjectOfAny,
    estimated_time_arrival: {
      iconSrc: NumericalIcon,
    } as ObjectOfAny,
    stops_left: {
      iconSrc: NumericalIcon,
    } as ObjectOfAny,
    phone_call: {
      iconSrc: NumericalIcon,
    } as ObjectOfAny,
  } as IServiceTrackingEmail,
};

type ProjectCommunicationWebappProps = {
  actions: {
    editProject: (...args: any[]) => any;
    editCommunication: (...args: any[]) => any;
  };
  user?: IUserData;
  project: IProjectData;
};

const ProjectCommunicationWebapp = ({
  actions,
  user,
  project,
}: ProjectCommunicationWebappProps) => {
  // eslint-disable-next-line. For the moment we only have the 'service_tracker' section.
  const [section, setSection] = useState<string>(Object.keys(sectionsDescriptionFields)[0]);
  const [descriptionFields, setDescriptionFields] = useState<ObjectOfAny>({ service_tracker: {} });

  useEffect(() => {
    if (project?.view) {
      setDescriptionFields({
        service_tracker: project.view.service_tracker || {},
      });
    }
  }, [project]);

  if (!project) return null;

  return (
    <Grid container direction="row" wrap="nowrap" style={{ height: '100%' }}>
      <Grid item container direction="column" style={{ padding: '12px 8px', width: '350px' }}>
        <Grid item>
          {Object.keys(sectionsDescriptionFields[section]).map((setting) => (
            <ProjectCustomField
              maxWidth={320}
              key={`gridconitem${section}${setting}`}
              field={{
                label: (
                  <FormattedMessage
                    id={`settings.organization.projects.edit.communication.webapp.${section}.${setting}`}
                  />
                ),
                description: (
                  <FormattedMessage
                    id={`settings.organization.projects.edit.communication.webapp.${section}.${setting}.description`}
                  />
                ),
                type: 'boolean',
                enabled: descriptionFields[section][setting] || false,
              }}
              iconSrc={sectionsDescriptionFields[section][setting].iconSrc}
              onUpdate={(value: any) => {
                actions.editProject(project.id, {
                  view: {
                    ...project.view,
                    [section]: {
                      ...descriptionFields[section],
                      ...{ [`${setting}`]: value.enabled },
                    },
                  },
                });
              }}
            />
          ))}
        </Grid>
        <Grid item container justify="center">
          <Grid item style={{ textAlign: 'center' }}>
            <span style={{ color: '#707070', fontSize: '11px' }}>
              <FormattedMessage tagName="span" id="settings.organization.projects.edit.communication.webapp.help_message" />
            </span>
          </Grid>

          <Grid item>
            <CustomLink 
              style={{ fontSize: '11px' }}
              onClick={() => window.open(`https://support.smartmonkey.io/docs/${user?.language === 'es-es' ? 'es': 'en'}/98932623-comunicacion-con-clientes`, '_blank')}
            >
              <FormattedMessage tagName="span" id="settings.organization.projects.edit.communication.visit_help_page" />
            </CustomLink>
          </Grid>
        </Grid>
      </Grid>
      <Grid item container direction="column" style={{ flex: '1', padding: '12px 8px', backgroundColor: '#F1F1F1' }}>
        <Grid item>
          <MobileMockup
            content={<div style={{ padding: '12px' }}>
              <Grid container>
                {project.view?.service_tracker.label && (
                  <span style={{ color: '#333940', fontSize: '14px', cursor: 'default' }}>{FAKE_CUSTOMER_DATA.label}</span>
                )}
                <span style={{ color: '#707070', fontSize: '12px', cursor: 'default' }}>
                  <FormattedMessage
                    tagName="span"
                    id="settings.organization.projects.edit.communication.webapp.preview.updated_at"
                    values={{ timeAgo: calculateTimeLeft(FAKE_CUSTOMER_DATA.updated_at) }}
                  />
                </span>
              </Grid>

              <VSpacer small />

              <div style={{ borderRadius: '12px', width: '100%', height: '180px' }}>
                <PreviewMap driverPosition={project.view?.service_tracker.driver_position ? FAKE_CUSTOMER_DATA.route_real_track : undefined} servicePosition={FAKE_CUSTOMER_DATA.service_location} />
              </div>
              <VSpacer small />

              {project.view?.service_tracker.estimated_time_arrival && (
                <>
                  <div
                    style={{
                      borderRadius: '12px',
                      width: '100%',
                      height: '35px',
                      backgroundColor: 'rgb(233, 233, 233)',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      cursor: 'default',
                    }}
                  >
                    {FAKE_CUSTOMER_DATA.eta}
                  </div>
                  <VSpacer small />
                </>
              )}
              {project.view?.service_tracker.stops_left && (
                <>
                  <div
                    style={{
                      borderRadius: '12px',
                      width: '100%',
                      height: '35px',
                      backgroundColor: 'rgb(233, 233, 233)',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      cursor: 'default',
                    }}
                  >
                    <FormattedMessage
                      tagName="span"
                      id="settings.organization.projects.edit.communication.webapp.preview.stops_left"
                      values={{ number: FAKE_CUSTOMER_DATA.stops_left }}
                    />
                  </div>
                  <VSpacer small />
                </>
              )}
              {project.view?.service_tracker.phone_call && (
                <>
                  <div
                    style={{
                      borderRadius: '8px',
                      color: 'white',
                      width: '100%',
                      height: '35px',
                      backgroundColor: '#FF8200',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      cursor: 'pointer',
                    }}
                  >
                    <FormattedMessage
                      tagName="span"
                      id="settings.organization.projects.edit.communication.webapp.preview.phone_call"
                    />
                  </div>
                  <VSpacer small />
                </>
              )}
            </div>}
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

ProjectCommunicationWebapp.defaultProps = {};

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    editProject: (projectId: string, projectData: any) =>
      dispatch(Projects.editProject(projectId, projectData)),
    editCommunication: (projectId: string, communicationData: any) =>
      dispatch(Projects.editCommunication(projectId, communicationData)),
  },
});

const mapStateToProps = (state: any) => {
  return { user: state.user.user };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProjectCommunicationWebapp);
