
export const FrogedEvents = {
  user: { on_sign_up: `on_sign_up` },
  plan: {
    created: `plan_created`,
    optimized: `plan_optimized`,
    dispatched: `plan_dispatched`,
    service: {
      created: `plan_service_created`,
    },
    route: {
      created: `plan_route_created`,
      optimized: `plan_route_optimized`,
    },
  },
  assets: {
    vehicles: {
      created: `assets_vehicle_created`,
    },
    clients:{
      created: `assets_client_created`,
    },
  },
};

export const initIntercom = () => {
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'frogedSettings' does not exist on type '... Remove this comment to see the full error message
  window.Intercom(`boot`, {
    app_id: `fmsiz9kk`,
  });
};

/**
 * Intercom JS API: https://developers.intercom.com/installing-intercom/docs/intercom-javascript
 */
export default class Intercom {
  static boot = () => {
    initIntercom();
  };

  static set = (data: any) => {
    // @ts-expect-error
    window.intercomSettings = {
      app_id: `fmsiz9kk`,
      ...data,
    };
  };

  static track = (evt: string, meta: any = {}) => {
    // @ts-expect-error
    window.Intercom(`trackEvent`, evt, meta);
  };

  static logout = () => {
    // @ts-expect-error
    window.Intercom(`shutdown`);
  };

  /**
   * // Open the intercom widget
   * 
   * @param param string
   */
  static open = (param = ``) => {
    // @ts-expect-error
    if (param !== ``) window.Intercom(`show`, param); else window.Intercom(`show`);
  };

  /**
   * The widget will be opened and a message will be automatically
   * sent on behalf of the user of your website 
   * @param textMessage Message to send via Froged chat.
   */
  static message = (textMessage: string) => {
    // @ts-expect-error
    window.Intercom(`showNewMessage`, textMessage);
  };
}
