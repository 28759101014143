import { Grid, IconButton, ListItem, Tooltip, Typography, withStyles } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { HSpacer, VSpacer } from '@sm-highway-web/react-components';
import { IPlanData } from 'highway-api/dist/common/interfaces/plans';
import { IServiceDataExtended } from 'highway-api/dist/common/interfaces/services';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { styles } from '../../../../assets/css/PlanRouteMapCss';
import DeliveryIcon from '../../../../assets/svg/DeliveryIcon';
import PickupIcon from '../../../../assets/svg/PickupIcon';
import TypeIcons, { colors } from '../../../../assets/svg/TypeIcons';
import WarningIcon from '../../../../assets/svg/WarningIcon';
import { SERVICE_STATUS } from '../../../../constants/Services';
import { calculateDuration } from '../../../../helpers/TimeDistance';
import { ActivePlan } from '../../../../redux/actions';
import FormattedMessage from '../../../FormattedMessageCustom';
import ServiceMenu from '../../../menus/ServiceMenu';
import OptimizationWarningsTooltip from '../../../OptimizationWarningsTooltip';

const getStepBackgroundColor = (step: any, selected: boolean) => {
  if(selected)return 'transparent';

  switch(step.status) {
    case SERVICE_STATUS.completed:
      return '#cbffb2';
    case SERVICE_STATUS.canceled:
      return '#ffb2b2';
    default:
      return 'transparent';
  }
};

type OwnItemListMissingProps = {
  classes: {
    allWidth: string;
    allHeight: string;
    subtitle: string;
    root: string;
    selected: string;
    smallbutton: string;
  };
  panel: number;
  selectedPoi: string[];
  idx: number;
  step: IServiceDataExtended;
  onClickPoi: (...args: any[]) => any;
  onDoubleClickPoi: (...args: any[]) => any;
  actions: {
    setService: (...args: any[]) => any;
  };
  selectedDiv: any;
  plan?: IPlanData;
  pickups: any;
};

type ItemListMissingProps = OwnItemListMissingProps;

const ItemListMissing = ({
  classes,
  panel,
  selectedPoi,
  idx,
  step,
  onClickPoi,
  onDoubleClickPoi,
  selectedDiv,
  actions,
  pickups,
  plan,
}: ItemListMissingProps) => {
  const selected = selectedPoi.includes(step.id);
  const [isHovering, setHovering] = useState(false);
  const [anchorMissingStep, setAnchorMissingStep] = useState();
  const Element = step.icon ? TypeIcons[step.icon] : TypeIcons.normal;
  const color = step.icon ? colors[step.icon] : colors.normal;

  return (
    <ListItem
      key={`listitem-${step.id}`}
      selected={selected}
      classes={{ root: classes.root, selected: classes.selected }}
      className="noselect"
      onClick={(e) => onClickPoi(e, step, 'missing', panel)}
      onDoubleClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        actions.setService(step);
        onDoubleClickPoi(e, step);
      }}
      onFocus={() => {}}
      style={{
        backgroundColor: getStepBackgroundColor(step, selected),
      }}
      onMouseEnter={() => setHovering(true)}
      onMouseLeave={() => setHovering(false)}
      onContextMenu={(e) => {
        e.preventDefault();
        e.stopPropagation();
        e.persist();
        setAnchorMissingStep({
          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ screenPosition: { top: number;... Remove this comment to see the full error message
          screenPosition: {
            top: e.clientY,
            left: e.clientX,
          },
        });
      }}
    >
      {selected && selectedPoi.length > 0 && selectedPoi[selectedPoi.length - 1] === step.id && (
        <div id={`${panel}-selected-div`} ref={selectedDiv} />
      )}
      <Grid container>
        <Grid item md={1}>
          <Grid container direction="column" justify="center" className={classes.allHeight}>
            <Typography align="center" style={{ width: '24px' }} key={`service-colors${step.id}`}>
              {idx + 1}
            </Typography>
          </Grid>
        </Grid>
        <Grid item md={11}>
          <Grid container direction="column">
            <Grid item className={classes.allWidth}>
              <Grid container style={{ height: '24px' }}>
                <Grid item md={2}>
                  <Grid
                    container
                    direction="column"
                    justify="center"
                    className={classes.allHeight}
                  />
                </Grid>
                <Grid item style={{ width: 'calc( 83.333% - 24px)' }}>
                  <Grid container alignItems="center" className={classes.allHeight}>
                    <Grid item style={{ height: '18px' }}>
                      <Element style={{ height: '18px', color }} />
                    </Grid>
                    <HSpacer small />
                    <Grid item style={{ width: 'calc(100% - 32px)' }}>
                      <Typography
                        key={`typname-${step.id}`}
                        noWrap
                        style={{ textOverflow: 'ellipsis' }}
                      >
                        {step.label ? step.label : step.id}
                      </Typography>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item>
                  {isHovering && (
                    // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
                    <IconButton
                      variant="text"
                      style={{ height: '24px', width: '24px', padding: '0' }}
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        actions.setService(step);
                      }}
                    >
                      <Edit style={{ width: '20px', height: '20px' }} />
                    </IconButton>
                  )}
                </Grid>
              </Grid>
            </Grid>

            <VSpacer small />
            <Grid item className={classes.allWidth}>
              <Grid container>
                <Grid item md={2}>
                  <Typography
                    key={`typhour-${step.id}`}
                    align="center"
                    className={classes.subtitle}
                  >
                    {`${calculateDuration(
                      step.planned_arrival_time && step.planned_departure_time
                        ? step.planned_departure_time - step.planned_arrival_time
                        : 0
                    )} m`}
                  </Typography>
                </Grid>
                <Grid item md={8}>
                  <Typography key={`typname-${step.id}`} noWrap className={classes.subtitle}>
                    {step.location &&
                      (step.location.label || (step.location.lat && step.location.lng)) && (
                      <>
                        {step.location.label
                          ? `${step.location.label}`
                          : 
                          `${step.location.lat?.toFixed(4)},${step.location.lng?.toFixed(4)}`}
                      </>
                    )}
                  </Typography>
                </Grid>
                <Grid item container md={2} justify="flex-end">
                  {plan?.routes &&
                    <Grid item>
                      <OptimizationWarningsTooltip planRoutes={plan?.routes} service={step}>
                        <WarningIcon style={{ color: '#D0021B', fontSize: '24px' }} />
                      </OptimizationWarningsTooltip>
                    </Grid>
                  }
                  <Grid item>
                    {step.type === 'pickup' && (
                      <Tooltip
                        title={
                          <FormattedMessage
                            id="thisisthepickup"
                            values={{ label: pickups[step.id].label }}
                          />
                        }
                      >
                        <Grid
                          style={{ marginLeft: '3px', height: '20px', width: '20px', padding: '2px' }}
                        >
                          <PickupIcon style={{ width: '20px', height: '20px', color: 'gray' }} />
                        </Grid>
                      </Tooltip>
                    )}
                    {step.type === 'delivery' && step.pickup && (
                      <Tooltip
                        title={
                          <FormattedMessage
                            id="thisisthedelivery"
                            values={{ label: step.pickup.label }}
                          />
                        }
                      >
                        <Grid
                          style={{ marginLeft: '3px', height: '20px', width: '20px', padding: '2px' }}
                        >
                          <DeliveryIcon style={{ width: '20px', height: '20px', color: 'gray' }} />
                        </Grid>
                      </Tooltip>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {anchorMissingStep && (
        <ServiceMenu
          plan={plan}
          service={step}
          contextAnchor={anchorMissingStep}
          setContextAnchor={setAnchorMissingStep}
          setRightPanelVisible={(visible: any) => {
            actions.setService(visible ? step : undefined);
          }}
        />
      )}
    </ListItem>
  );
};

ItemListMissing.defaultProps = {
  plan: undefined,
};

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    setService: (service: any) => dispatch(ActivePlan.setDetailView('service', service)),
  },
});

const mapStateToProps = (state: any) => {
  return {
    plan: state.activePlan.plan,
    pickups: state.activePlan.pickups,
  };
};

// @ts-expect-error ts-migrate(2345) FIXME: Argument of type '() => { divMap: { position: stri... Remove this comment to see the full error message
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ItemListMissing));
