import { Grid, TextareaAutosize, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import { Button, InputText, VSpacer } from '@sm-highway-web/react-components';
import { IProjectData } from 'highway-api/dist/common/interfaces/projects';
import lodash from 'lodash';
import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { intl } from '../../../helpers/IntGlobalProvider';
import { Projects } from '../../../redux/actions';
import { projectHelpers, userHelpers } from '../../../redux/helpers';
import { getLocationFromLatLng, getUploadAsBase64 } from '../../../services/requests';
import AvatarManager from '../../AvatarManager';
import LocationSearch from '../../form-fields/LocationSearch';
import FormattedMessage from '../../FormattedMessageCustom';
import {
  ContainerInputs,
} from '../SettingsStyled';

const styles = {
  textAreaInput: {
    fontFamily: 'Work Sans',
    fontSize: '16px',
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    borderColor: '#949494',
    outline: 'unset',
  },
  locationLabel: {
    borderBottom: '1px solid #949494',
    '&:hover': {
      backgroundColor: '#EEEFF2',
    },
    borderRadius: '2px',
    padding: '2px 4px',
  },
};

export interface IProjectDataWithAvatarUrl extends IProjectData {
  // eslint-disable-next-line camelcase
  avatar_url?: string;
}

type OrganizationProjectsEditGeneralProps = {
  classes: {
    textAreaInput: string;
    locationLabel: string;
  };
  actions: {
    uploadAvatar: (...args: any[]) => any;
    removeAvatar: (...args: any[]) => any;
    editProject: (...args: any[]) => any;
  };
  project: IProjectDataWithAvatarUrl;
  isDirtyState: (...args: any[]) => any;
  countryCode?: string | null;
};

const OrganizationProjectsEditGeneral = ({
  classes,
  project,
  isDirtyState,
  actions,
  countryCode,
}: OrganizationProjectsEditGeneralProps) => {

  const [isLoadingImage, setIsLoadingImage] = useState(false);
  const [isUploadingImage, setIsUploadingImage] = useState(false);

  const [label, setLabel] = useState(project.label);
  const [description, setDescription] = useState(project.description);
  const [avatarImage, setAvatarImage] = useState<string | null>('');
  const [location, setLocation] = useState(null);
  const [locationLabel, setLocationLabel] = useState('');
  const [locationEditable, setLocationEditable] = useState(false);
  const [dirtyState, setDirtyState] = useState(false);

  const locationFormRef = useRef();

  useEffect(() => {
    if (project) {
      setLabel(project.label);
      setDescription(project.description);

      (async () => {
        if (project.avatar_url) {
          setIsLoadingImage(true);
          const avatar = await getUploadAsBase64(project.avatar_url);
          setAvatarImage(avatar);
          setIsLoadingImage(false);
        }
      })();

      if (project.location) {
        (async () => {
          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ lat: number; lng: number; } | ... Remove this comment to see the full error message
          setLocation(project.location);

          // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
          const result = await getLocationFromLatLng(project.location.lat, project.location.lng);

          let value = '';
          if (result.label) {
            value = result.label;
          } else if (result.lng && result.lat) {
            value = `${result.lat}, ${result.lng}`;
          }

          setLocationLabel(value);
        })();
      }
    }
  }, [project]);

  useEffect(() => {
    if (project) {
      if (
        !lodash.isEqual(label, project.label) ||
        !lodash.isEqual(description, project.description)
      ) {
        setDirtyState(true);
      } else if (
        !lodash.isEqual(location, project.location) &&
        !(project.location === undefined && location === null)
      ) {
        setDirtyState(true);
      } else {
        setDirtyState(false);
      }
    }
  }, [project, label, description, location]);

  useEffect(() => {
    if (
      userHelpers.currentUser.isOrganizationAdmin() ||
      userHelpers.currentUser.isOrganizationManager() ||
      projectHelpers.currentUser.isManagerInProject(project)
    ) {
      isDirtyState(dirtyState);
    } else {
      isDirtyState(false);
    }
  }, [project, dirtyState, isDirtyState]);

  const handleClick = (evt: any) => {
    if (
      locationFormRef &&
      locationFormRef.current &&
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      locationFormRef.current.contains(evt.target)
    ) {
      setLocationEditable(true);
    } else {
      setLocationEditable(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  const handleEditProjectProfile = () => {
    actions.editProject(project.id, { label, description, location: location || null });
  };

  const handleKeyPress = async (event: any) => {
    switch (event.key) {
      case 'Escape':
        setLocationEditable(false);
        break;
      default:
        break;
    }
  };

  if (!project) return null;

  return (
    <>
      <Grid container direction="column">
        <Grid item container style={{ padding: '8px', borderBottom: '1px solid #D7DAE2' }}>
          <Grid
            item
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            wrap="nowrap"
          >
            <Grid item container wrap="nowrap">
              <Typography variant="h2">
                <FormattedMessage id="settings.organization.projects.edit.general.profile.title" />
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <VSpacer large />

        <Grid item>
          <AvatarManager
            avatarUrl={project.avatar_url}
            onRemoveAvatar={() => actions.removeAvatar(project.id)}
            onAvatarUpload={(fileName: string, imageType: string, image: Blob) => actions.uploadAvatar(project.id, fileName, imageType, image)}
          />
        </Grid>

        <VSpacer large />

        <Grid item>
          <ContainerInputs>
            {userHelpers.currentUser.isOrganizationAdmin() ||
            userHelpers.currentUser.isOrganizationManager() ||
            projectHelpers.currentUser.isManagerInProject(project) ? (
                  <InputText
                    id="label"
                    label={
                      <FormattedMessage id="settings.organization.projects.edit.general.profile.label" />
                    }
                    value={label}
                    onChange={(value: any) => setLabel(value)}
                    margin="none"
                    validators={[]}
                  />
                ) : (
                  <Grid item>
                    <span style={{ fontSize: '12px' }}>
                      <FormattedMessage id="settings.organization.projects.edit.general.profile.label" />
                    </span>
                    <VSpacer medium />
                    <Typography
                      style={{
                        fontSize: '16px',
                        borderBottom: '1px solid rgba(0, 0, 0, 0.87)',
                        paddingBottom: '8px',
                      }}
                    >
                      {label}
                    </Typography>
                  </Grid>
                )}
          </ContainerInputs>
        </Grid>

        <Grid item>
          <ContainerInputs>
            {userHelpers.currentUser.isOrganizationAdmin() ||
            userHelpers.currentUser.isOrganizationManager() ||
            projectHelpers.currentUser.isManagerInProject(project) ? (
                  <>
                    <Typography style={{ marginBottom: '12px' }}>
                      <FormattedMessage tagName="span" id="settings.organization.projects.edit.general.profile.description" />
                    </Typography>

                    <TextareaAutosize
                      value={description}
                      className={classes.textAreaInput}
                      maxLength={512}
                      // rowsMin={2} // TODO: NEED UPGRADE DE MATERIAL!!
                      rowsMax={4}
                      aria-label="Project description"
                      placeholder={intl.formatMessage({
                        id: 'settings.organization.projects.edit.general.profile.description_ph',
                      })}
                      onChange={(evt) => setDescription(evt.target.value)}
                    />
                  </>
                ) : (
                  <Grid item>
                    <span style={{ fontSize: '12px' }}>
                      <FormattedMessage id="settings.organization.projects.edit.general.profile.description" />
                    </span>
                    <VSpacer medium />
                    <Typography
                      style={{
                        fontSize: '16px',
                        borderBottom: '1px solid rgba(0, 0, 0, 0.87)',
                        paddingBottom: '8px',
                      }}
                    >
                      {description || <FormattedMessage id="none" />}
                    </Typography>
                  </Grid>
                )}
          </ContainerInputs>
        </Grid>

        <VSpacer large />

        <Grid item>
          <Grid
            container
            direction="row"
            style={{
              width: '60%',
              ...(locationLabel ? { paddingRight: '24px', position: 'relative' } : {}),
            }}
          >
            {userHelpers.currentUser.isOrganizationAdmin() ||
            userHelpers.currentUser.isOrganizationManager() ||
            projectHelpers.currentUser.isManagerInProject(project) ? (
                  <>
                    {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
                    <Grid container direction="column" ref={locationFormRef}>
                      <Typography style={{ marginBottom: '12px' }}>
                        <FormattedMessage tagName="span" id="settings.organization.projects.edit.general.profile.location" />
                      </Typography>

                      {locationEditable ? (
                        <LocationSearch
                          placeholder={intl.formatMessage({
                            id: 'settings.organization.projects.edit.general.profile.location_ph',
                          })}
                          onKeyDown={handleKeyPress}
                          onBlur={() => setLocationEditable(false)}
                          onUpdate={(loc: any) => {
                            setLocation(
                              loc
                                ? {
                                    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ lat: any; lng: any; } | null' ... Remove this comment to see the full error message
                                    lat: loc.lat,
                                    lng: loc.lng,
                                  }
                                : null
                            );
                            setLocationLabel(loc ? loc.label : '');
                            setLocationEditable(false);
                          }}
                          countryCode={countryCode}
                        />
                      ) : (
                        <Grid
                          container
                          alignItems="center"
                          className={classes.locationLabel}
                          style={{ padding: 0, minHeight: '32px' }}
                          onClick={(e) => {
                            setLocationEditable(true);
                          }}
                        >
                          {locationLabel || intl.formatMessage({ id: 'none' })}
                        </Grid>
                      )}
                    </Grid>

                    {!locationEditable && locationLabel && (
                      <ClearIcon
                        id="clear-icon"
                        style={{ position: 'absolute', right: 0, top: '24px', cursor: 'pointer' }}
                        onClick={(e) => {
                          setLocationEditable(false);
                          setLocation(null);
                          setLocationLabel('');
                        }}
                      />
                    )}
                  </>
                ) : (
                  <Grid item style={{ width: '100%' }}>
                    <span style={{ fontSize: '12px' }}>
                      <FormattedMessage id="settings.organization.projects.edit.general.profile.label" />
                    </span>
                    <VSpacer medium />
                    <Typography
                      style={{
                        fontSize: '16px',
                        borderBottom: '1px solid rgba(0, 0, 0, 0.87)',
                        paddingBottom: '8px',
                      }}
                    >
                      {locationLabel || intl.formatMessage({ id: 'none' })}
                    </Typography>
                  </Grid>
                )}
          </Grid>
        </Grid>

        <VSpacer />

        <Grid item style={{ position: 'fixed', bottom: '40px', right: '40px' }}>
          <Button onClick={handleEditProjectProfile} disabled={!dirtyState}>
            <FormattedMessage id="settings.buttonupdate" />
          </Button>
        </Grid>
        <VSpacer />
        <VSpacer />
      </Grid>
    </>
  );
};

OrganizationProjectsEditGeneral.defaultProps = {
  countryCode: undefined,
};

const mapStateToProps = (state: any) => {
  return {
    countryCode: state.user.userLocation && state.user.userLocation.country,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    uploadAvatar: (projectId: any, fileName: any, mimeType: any, file: any) =>
      dispatch(Projects.uploadAvatar(projectId, fileName, mimeType, file)),
    removeAvatar: (projectId: any) =>
      dispatch(Projects.removeAvatar(projectId)),
    editProject: (projectId: any, projectData: any) =>
      dispatch(Projects.editProject(projectId, projectData)),
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(OrganizationProjectsEditGeneral));
