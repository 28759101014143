export const EU_COUNTRIES : { [key: string]: { label: string; vatExample: string } }= {
  AT: {
    label: 'Austria',
    vatExample: 'ATU12345678',
  },
  BE: {
    label: 'Belgium',
    vatExample: 'BE0123456789',
  },
  BG: {
    label: 'Bulgaria',
    vatExample: 'BG0123456789',
  },
  HR: {
    label: 'Croatia',
    vatExample: 'HR12345678912',
  },
  CY: {
    label: 'Cyprus',
    vatExample: 'CY12345678Z',
  },
  CZ: {
    label: 'Czech Republic',
    vatExample: 'CZ1234567890',
  },
  DK: {
    label: 'Denmark',
    vatExample: 'DK12345678',
  },
  EE: {
    label: 'Estonia',
    vatExample: 'EE123456789',
  },
  FI: {
    label: 'Finland',
    vatExample: 'FI12345678',
  },
  FR: {
    label: 'France',
    vatExample: 'FRAB123456789',
  },
  DE: {
    label: 'Germany',
    vatExample: 'DE123456789',
  },
  GR: {
    label: 'Greece',
    vatExample: 'EL123456789',
  },
  HU: {
    label: 'Hungary',
    vatExample: 'HU12345678',
  },
  IE: {
    label: 'Ireland',
    vatExample: 'IE1234567AB',
  },
  IT: {
    label: 'Italy',
    vatExample: 'IT12345678912',
  },
  LV: {
    label: 'Latvia',
    vatExample: 'LV12345678912',
  },
  LT: {
    label: 'Lithuania',
    vatExample: 'LT123456789123',
  },
  LU: {
    label: 'Luxembourg',
    vatExample: 'LU12345678',
  },
  MT: {
    label: 'Malta',
    vatExample: 'MT12345678',
  },
  NL: {
    label: 'Netherlands',
    vatExample: 'NL123456789B12',
  },
  PL: {
    label: 'Poland',
    vatExample: 'PL1234567890',
  },
  PT: {
    label: 'Portugal',
    vatExample: 'PT123456789',
  },
  RO: {
    label: 'Romania',
    vatExample: 'RO1234567891',
  },
  SK: {
    label: 'Slovakia',
    vatExample: 'SK1234567891',
  },
  SI: {
    label: 'Slovenia',
    vatExample: 'SI12345678',
  },
  ES: {
    label: 'Spain',
    vatExample: 'ESA1234567Z',
  },
  SE: {
    label: 'Sweden',
    vatExample: 'SE123456789123',
  },
};

export const getTaxAmountByUE = (country: any, taxValue: any) =>
  Object.keys(EU_COUNTRIES).includes(country) ? taxValue : null;

const countries : {
  [key: string]: {
    label: string;
    coordinates: {
      latitude: number;
      longitude: number;
    };
    mapZoomLevel?: number;
  };
} = {
  AD: {
    label: 'Andorra',
    coordinates: {
      latitude: 42.546245,
      longitude: 1.601554,
    },
  },
  AE: {
    label: 'United Arab Emirates',
    coordinates: {
      latitude: 23.424076,
      longitude: 53.847818,
    },
  },
  AF: {
    label: 'Afghanistan',
    coordinates: {
      latitude: 33.93911,
      longitude: 67.709953,
    },
  },
  AG: {
    label: 'Antigua and Barbuda',
    coordinates: {
      latitude: 17.060816,
      longitude: -61.796428,
    },
  },
  AI: {
    label: 'Anguilla',
    coordinates: {
      latitude: 18.220554,
      longitude: -63.068615,
    },
  },
  AL: {
    label: 'Albania',
    coordinates: {
      latitude: 41.153332,
      longitude: 20.168331,
    },
  },
  AM: {
    label: 'Armenia',
    coordinates: {
      latitude: 40.069099,
      longitude: 45.038189,
    },
  },
  AN: {
    label: 'Netherlands Antilles',
    coordinates: {
      latitude: 12.226079,
      longitude: -69.060087,
    },
  },
  AO: {
    label: 'Angola',
    coordinates: {
      latitude: -11.202692,
      longitude: 17.873887,
    },
  },
  AQ: {
    label: 'Antarctica',
    coordinates: {
      latitude: -75.250973,
      longitude: -0.071389,
    },
  },
  AR: {
    label: 'Argentina',
    coordinates: {
      latitude: -38.416097,
      longitude: -63.616672,
    },
    mapZoomLevel: 6,
  },
  AS: {
    label: 'American Samoa',
    coordinates: {
      latitude: -14.270972,
      longitude: -170.132217,
    },
  },
  AT: {
    label: 'Austria',
    coordinates: {
      latitude: 47.516231,
      longitude: 14.550072,
    },
  },
  AU: {
    label: 'Australia',
    coordinates: {
      latitude: -25.274398,
      longitude: 133.775136,
    },
  },
  AW: {
    label: 'Aruba',
    coordinates: {
      latitude: 12.52111,
      longitude: -69.968338,
    },
  },
  AZ: {
    label: 'Azerbaijan',
    coordinates: {
      latitude: 40.143105,
      longitude: 47.576927,
    },
  },
  BA: {
    label: 'Bosnia and Herzegovina',
    coordinates: {
      latitude: 43.915886,
      longitude: 17.679076,
    },
  },
  BB: {
    label: 'Barbados',
    coordinates: {
      latitude: 13.193887,
      longitude: -59.543198,
    },
  },
  BD: {
    label: 'Bangladesh',
    coordinates: {
      latitude: 23.684994,
      longitude: 90.356331,
    },
  },
  BE: {
    label: 'Belgium',
    coordinates: {
      latitude: 50.503887,
      longitude: 4.469936,
    },
  },
  BF: {
    label: 'Burkina Faso',
    coordinates: {
      latitude: 12.238333,
      longitude: -1.561593,
    },
  },
  BG: {
    label: 'Bulgaria',
    coordinates: {
      latitude: 42.733883,
      longitude: 25.48583,
    },
  },
  BH: {
    label: 'Bahrain',
    coordinates: {
      latitude: 25.930414,
      longitude: 50.637772,
    },
  },
  BI: {
    label: 'Burundi',
    coordinates: {
      latitude: -3.373056,
      longitude: 29.918886,
    },
  },
  BJ: {
    label: 'Benin',
    coordinates: {
      latitude: 9.30769,
      longitude: 2.315834,
    },
  },
  BM: {
    label: 'Bermuda',
    coordinates: {
      latitude: 32.321384,
      longitude: -64.75737,
    },
  },
  BN: {
    label: 'Brunei Darussalam',
    coordinates: {
      latitude: 4.535277,
      longitude: 114.727669,
    },
  },
  BO: {
    label: 'Bolivia, Plurinational State of',
    coordinates: {
      latitude: -16.290154,
      longitude: -63.588653,
    },
  },
  BR: {
    label: 'Brazil',
    coordinates: {
      latitude: -14.235004,
      longitude: -51.92528,
    },
  },
  BS: {
    label: 'Bahamas',
    coordinates: {
      latitude: 25.03428,
      longitude: -77.39628,
    },
  },
  BT: {
    label: 'Bhutan',
    coordinates: {
      latitude: 27.514162,
      longitude: 90.433601,
    },
  },
  BV: {
    label: 'Bouvet Island',
    coordinates: {
      latitude: -54.423199,
      longitude: 3.413194,
    },
  },
  BW: {
    label: 'Botswana',
    coordinates: {
      latitude: -22.328474,
      longitude: 24.684866,
    },
  },
  BY: {
    label: 'Belarus',
    coordinates: {
      latitude: 53.709807,
      longitude: 27.953389,
    },
  },
  BZ: {
    label: 'Belize',
    coordinates: {
      latitude: 17.189877,
      longitude: -88.49765,
    },
  },
  CA: {
    label: 'Canada',
    coordinates: {
      latitude: 56.130366,
      longitude: -106.346771,
    },
  },
  CC: {
    label: 'Cocos (Keeling) Islands',
    coordinates: {
      latitude: -12.164165,
      longitude: 96.870956,
    },
  },
  CD: {
    label: 'Congo, the Democratic Republic of the',
    coordinates: {
      latitude: -4.038333,
      longitude: 21.758664,
    },
  },
  CF: {
    label: 'Central African Republic',
    coordinates: {
      latitude: 6.611111,
      longitude: 20.939444,
    },
  },
  CG: {
    label: 'Congo',
    coordinates: {
      latitude: -0.228021,
      longitude: 15.827659,
    },
  },
  CH: {
    label: 'Switzerland',
    coordinates: {
      latitude: 46.818188,
      longitude: 8.227512,
    },
  },
  CI: {
    label: 'Côte d\'Ivoire',
    coordinates: {
      latitude: 7.539989,
      longitude: -5.54708,
    },
  },
  CK: {
    label: 'Cook Islands',
    coordinates: {
      latitude: -21.236736,
      longitude: -159.777671,
    },
  },
  CL: {
    label: 'Chile',
    coordinates: {
      latitude: -35.675147,
      longitude: -71.542969,
    },
    mapZoomLevel: 7,
  },
  CM: {
    label: 'Cameroon',
    coordinates: {
      latitude: 7.369722,
      longitude: 12.354722,
    },
  },
  CN: {
    label: 'China',
    coordinates: {
      latitude: 35.86166,
      longitude: 104.195397,
    },
  },
  CO: {
    label: 'Colombia',
    coordinates: {
      latitude: 4.570868,
      longitude: -74.297333,
    },
    mapZoomLevel: 10,
  },
  CR: {
    label: 'Costa Rica',
    coordinates: {
      latitude: 9.748917,
      longitude: -83.753428,
    },
  },
  CU: {
    label: 'Cuba',
    coordinates: {
      latitude: 21.521757,
      longitude: -77.781167,
    },
  },
  CV: {
    label: 'Cape Verde',
    coordinates: {
      latitude: 16.002082,
      longitude: -24.013197,
    },
  },
  CX: {
    label: 'Christmas Island',
    coordinates: {
      latitude: -10.447525,
      longitude: 105.690449,
    },
  },
  CY: {
    label: 'Cyprus',
    coordinates: {
      latitude: 35.126413,
      longitude: 33.429859,
    },
  },
  CZ: {
    label: 'Czech Republic',
    coordinates: {
      latitude: 49.817492,
      longitude: 15.472962,
    },
  },
  DE: {
    label: 'Germany',
    coordinates: {
      latitude: 51.165691,
      longitude: 10.451526,
    },
  },
  DJ: {
    label: 'Djibouti',
    coordinates: {
      latitude: 11.825138,
      longitude: 42.590275,
    },
  },
  DK: {
    label: 'Denmark',
    coordinates: {
      latitude: 56.26392,
      longitude: 9.501785,
    },
  },
  DM: {
    label: 'Dominica',
    coordinates: {
      latitude: 15.414999,
      longitude: -61.370976,
    },
  },
  DO: {
    label: 'Dominican Republic',
    coordinates: {
      latitude: 18.735693,
      longitude: -70.162651,
    },
  },
  DZ: {
    label: 'Algeria',
    coordinates: {
      latitude: 28.033886,
      longitude: 1.659626,
    },
  },
  EC: {
    label: 'Ecuador',
    coordinates: {
      latitude: -1.831239,
      longitude: -78.183406,
    },
    mapZoomLevel: 8,
  },
  EE: {
    label: 'Estonia',
    coordinates: {
      latitude: 58.595272,
      longitude: 25.013607,
    },
  },
  EG: {
    label: 'Egypt',
    coordinates: {
      latitude: 26.820553,
      longitude: 30.802498,
    },
  },
  EH: {
    label: 'Western Sahara',
    coordinates: {
      latitude: 24.215527,
      longitude: -12.885834,
    },
  },
  ER: {
    label: 'Eritrea',
    coordinates: {
      latitude: 15.179384,
      longitude: 39.782334,
    },
  },
  ES: {
    label: 'Spain',
    coordinates: {
      latitude: 40.463667,
      longitude: -3.74922,
    },
    mapZoomLevel: 7,
  },
  ET: {
    label: 'Ethiopia',
    coordinates: {
      latitude: 9.145,
      longitude: 40.489673,
    },
  },
  FI: {
    label: 'Finland',
    coordinates: {
      latitude: 61.92411,
      longitude: 25.748151,
    },
  },
  FJ: {
    label: 'Fiji',
    coordinates: {
      latitude: -16.578193,
      longitude: 179.414413,
    },
  },
  FK: {
    label: 'Falkland Islands (Malvinas)',
    coordinates: {
      latitude: -51.796253,
      longitude: -59.523613,
    },
  },
  FM: {
    label: 'Micronesia, Federated States of',
    coordinates: {
      latitude: 7.425554,
      longitude: 150.550812,
    },
  },
  FO: {
    label: 'Faroe Islands',
    coordinates: {
      latitude: 61.892635,
      longitude: -6.911806,
    },
  },
  FR: {
    label: 'France',
    coordinates: {
      latitude: 46.227638,
      longitude: 2.213749,
    },
  },
  GA: {
    label: 'Gabon',
    coordinates: {
      latitude: -0.803689,
      longitude: 11.609444,
    },
  },
  GB: {
    label: 'United Kingdom',
    coordinates: {
      latitude: 55.378051,
      longitude: -3.435973,
    },
  },
  GD: {
    label: 'Grenada',
    coordinates: {
      latitude: 12.262776,
      longitude: -61.604171,
    },
  },
  GE: {
    label: 'Georgia',
    coordinates: {
      latitude: 42.315407,
      longitude: 43.356892,
    },
  },
  GF: {
    label: 'French Guiana',
    coordinates: {
      latitude: 3.933889,
      longitude: -53.125782,
    },
  },
  GG: {
    label: 'Guernsey',
    coordinates: {
      latitude: 49.465691,
      longitude: -2.585278,
    },
  },
  GH: {
    label: 'Ghana',
    coordinates: {
      latitude: 7.946527,
      longitude: -1.023194,
    },
  },
  GI: {
    label: 'Gibraltar',
    coordinates: {
      latitude: 36.137741,
      longitude: -5.345374,
    },
  },
  GL: {
    label: 'Greenland',
    coordinates: {
      latitude: 71.706936,
      longitude: -42.604303,
    },
  },
  GM: {
    label: 'Gambia',
    coordinates: {
      latitude: 13.443182,
      longitude: -15.310139,
    },
  },
  GN: {
    label: 'Guinea',
    coordinates: {
      latitude: 9.945587,
      longitude: -9.696645,
    },
  },
  GP: {
    label: 'Guadeloupe',
    coordinates: {
      latitude: 16.995971,
      longitude: -62.067641,
    },
  },
  GQ: {
    label: 'Equatorial Guinea',
    coordinates: {
      latitude: 1.650801,
      longitude: 10.267895,
    },
  },
  GR: {
    label: 'Greece',
    coordinates: {
      latitude: 39.074208,
      longitude: 21.824312,
    },
  },
  GS: {
    label: 'South Georgia and the South Sandwich Islands',
    coordinates: {
      latitude: -54.429579,
      longitude: -36.587909,
    },
  },
  GT: {
    label: 'Guatemala',
    coordinates: {
      latitude: 15.783471,
      longitude: -90.230759,
    },
  },
  GU: {
    label: 'Guam',
    coordinates: {
      latitude: 13.444304,
      longitude: 144.793731,
    },
  },
  GW: {
    label: 'Guinea-Bissau',
    coordinates: {
      latitude: 11.803749,
      longitude: -15.180413,
    },
  },
  GY: {
    label: 'Guyana',
    coordinates: {
      latitude: 4.860416,
      longitude: -58.93018,
    },
  },
  GZ: {
    label: 'Gaza Strip',
    coordinates: {
      latitude: 31.354676,
      longitude: 34.308825,
    },
  },
  HK: {
    label: 'Hong Kong',
    coordinates: {
      latitude: 22.396428,
      longitude: 114.109497,
    },
  },
  HM: {
    label: 'Heard Island and McDonald Islands',
    coordinates: {
      latitude: -53.08181,
      longitude: 73.504158,
    },
  },
  HN: {
    label: 'Honduras',
    coordinates: {
      latitude: 15.199999,
      longitude: -86.241905,
    },
  },
  HR: {
    label: 'Croatia',
    coordinates: {
      latitude: 45.1,
      longitude: 15.2,
    },
  },
  HT: {
    label: 'Haiti',
    coordinates: {
      latitude: 18.971187,
      longitude: -72.285215,
    },
  },
  HU: {
    label: 'Hungary',
    coordinates: {
      latitude: 47.162494,
      longitude: 19.503304,
    },
  },
  ID: {
    label: 'Indonesia',
    coordinates: {
      latitude: -0.789275,
      longitude: 113.921327,
    },
  },
  IE: {
    label: 'Ireland',
    coordinates: {
      latitude: 53.41291,
      longitude: -8.24389,
    },
  },
  IL: {
    label: 'Israel',
    coordinates: {
      latitude: 31.046051,
      longitude: 34.851612,
    },
  },
  IM: {
    label: 'Isle of Man',
    coordinates: {
      latitude: 54.236107,
      longitude: -4.548056,
    },
  },
  IN: {
    label: 'India',
    coordinates: {
      latitude: 20.593684,
      longitude: 78.96288,
    },
  },
  IO: {
    label: 'British Indian Ocean Territory',
    coordinates: {
      latitude: -6.343194,
      longitude: 71.876519,
    },
  },
  IQ: {
    label: 'Iraq',
    coordinates: {
      latitude: 33.223191,
      longitude: 43.679291,
    },
  },
  IR: {
    label: 'Iran, Islamic Republic of',
    coordinates: {
      latitude: 32.427908,
      longitude: 53.688046,
    },
  },
  IS: {
    label: 'Iceland',
    coordinates: {
      latitude: 64.963051,
      longitude: -19.020835,
    },
  },
  IT: {
    label: 'Italy',
    coordinates: {
      latitude: 41.87194,
      longitude: 12.56738,
    },
  },
  JE: {
    label: 'Jersey',
    coordinates: {
      latitude: 49.214439,
      longitude: -2.13125,
    },
  },
  JM: {
    label: 'Jamaica',
    coordinates: {
      latitude: 18.109581,
      longitude: -77.297508,
    },
  },
  JO: {
    label: 'Jordan',
    coordinates: {
      latitude: 30.585164,
      longitude: 36.238414,
    },
  },
  JP: {
    label: 'Japan',
    coordinates: {
      latitude: 36.204824,
      longitude: 138.252924,
    },
  },
  KE: {
    label: 'Kenya',
    coordinates: {
      latitude: -0.023559,
      longitude: 37.906193,
    },
  },
  KG: {
    label: 'Kyrgyzstan',
    coordinates: {
      latitude: 41.20438,
      longitude: 74.766098,
    },
  },
  KH: {
    label: 'Cambodia',
    coordinates: {
      latitude: 12.565679,
      longitude: 104.990963,
    },
  },
  KI: {
    label: 'Kiribati',
    coordinates: {
      latitude: -3.370417,
      longitude: -168.734039,
    },
  },
  KM: {
    label: 'Comoros',
    coordinates: {
      latitude: -11.875001,
      longitude: 43.872219,
    },
  },
  KN: {
    label: 'Saint Kitts and Nevis',
    coordinates: {
      latitude: 17.357822,
      longitude: -62.782998,
    },
  },
  KP: {
    label: 'Korea, Democratic People\'s Republic of',
    coordinates: {
      latitude: 40.339852,
      longitude: 127.510093,
    },
  },
  KR: {
    label: 'Korea, Republic of',
    coordinates: {
      latitude: 35.907757,
      longitude: 127.766922,
    },
  },
  KW: {
    label: 'Kuwait',
    coordinates: {
      latitude: 29.31166,
      longitude: 47.481766,
    },
  },
  KY: {
    label: 'Cayman Islands',
    coordinates: {
      latitude: 19.513469,
      longitude: -80.566956,
    },
  },
  KZ: {
    label: 'Kazakhstan',
    coordinates: {
      latitude: 48.019573,
      longitude: 66.923684,
    },
  },
  LA: {
    label: 'Lao People\'s Democratic Republic',
    coordinates: {
      latitude: 19.85627,
      longitude: 102.495496,
    },
  },
  LB: {
    label: 'Lebanon',
    coordinates: {
      latitude: 33.854721,
      longitude: 35.862285,
    },
  },
  LC: {
    label: 'Saint Lucia',
    coordinates: {
      latitude: 13.909444,
      longitude: -60.978893,
    },
  },
  LI: {
    label: 'Liechtenstein',
    coordinates: {
      latitude: 47.166,
      longitude: 9.555373,
    },
  },
  LK: {
    label: 'Sri Lanka',
    coordinates: {
      latitude: 7.873054,
      longitude: 80.771797,
    },
  },
  LR: {
    label: 'Liberia',
    coordinates: {
      latitude: 6.428055,
      longitude: -9.429499,
    },
  },
  LS: {
    label: 'Lesotho',
    coordinates: {
      latitude: -29.609988,
      longitude: 28.233608,
    },
  },
  LT: {
    label: 'Lithuania',
    coordinates: {
      latitude: 55.169438,
      longitude: 23.881275,
    },
  },
  LU: {
    label: 'Luxembourg',
    coordinates: {
      latitude: 49.815273,
      longitude: 6.129583,
    },
  },
  LV: {
    label: 'Latvia',
    coordinates: {
      latitude: 56.879635,
      longitude: 24.603189,
    },
  },
  LY: {
    label: 'Libyan Arab Jamahiriya',
    coordinates: {
      latitude: 26.3351,
      longitude: 17.228331,
    },
  },
  MA: {
    label: 'Morocco',
    coordinates: {
      latitude: 31.791702,
      longitude: -7.09262,
    },
  },
  MC: {
    label: 'Monaco',
    coordinates: {
      latitude: 43.750298,
      longitude: 7.412841,
    },
  },
  MD: {
    label: 'Moldova, Republic of',
    coordinates: {
      latitude: 47.411631,
      longitude: 28.369885,
    },
  },
  ME: {
    label: 'Montenegro',
    coordinates: {
      latitude: 42.708678,
      longitude: 19.37439,
    },
  },
  MG: {
    label: 'Madagascar',
    coordinates: {
      latitude: -18.766947,
      longitude: 46.869107,
    },
  },
  MH: {
    label: 'Marshall Islands',
    coordinates: {
      latitude: 7.131474,
      longitude: 171.184478,
    },
  },
  MK: {
    label: 'Macedonia, the former Yugoslav Republic of',
    coordinates: {
      latitude: 41.608635,
      longitude: 21.745275,
    },
  },
  ML: {
    label: 'Mali',
    coordinates: {
      latitude: 17.570692,
      longitude: -3.996166,
    },
  },
  MM: {
    label: 'Myanmar',
    coordinates: {
      latitude: 21.913965,
      longitude: 95.956223,
    },
  },
  MN: {
    label: 'Mongolia',
    coordinates: {
      latitude: 46.862496,
      longitude: 103.846656,
    },
  },
  MO: {
    label: 'Macao',
    coordinates: {
      latitude: 22.198745,
      longitude: 113.543873,
    },
  },
  MP: {
    label: 'Northern Mariana Islands',
    coordinates: {
      latitude: 17.33083,
      longitude: 145.38469,
    },
  },
  MQ: {
    label: 'Martinique',
    coordinates: {
      latitude: 14.641528,
      longitude: -61.024174,
    },
  },
  MR: {
    label: 'Mauritania',
    coordinates: {
      latitude: 21.00789,
      longitude: -10.940835,
    },
  },
  MS: {
    label: 'Montserrat',
    coordinates: {
      latitude: 16.742498,
      longitude: -62.187366,
    },
  },
  MT: {
    label: 'Malta',
    coordinates: {
      latitude: 35.937496,
      longitude: 14.375416,
    },
  },
  MU: {
    label: 'Mauritius',
    coordinates: {
      latitude: -20.348404,
      longitude: 57.552152,
    },
  },
  MV: {
    label: 'Maldives',
    coordinates: {
      latitude: 3.202778,
      longitude: 73.22068,
    },
  },
  MW: {
    label: 'Malawi',
    coordinates: {
      latitude: -13.254308,
      longitude: 34.301525,
    },
  },
  MX: {
    label: 'Mexico',
    coordinates: {
      latitude: 23.634501,
      longitude: -102.552784,
    },
    mapZoomLevel: 6,
  },
  MY: {
    label: 'Malaysia',
    coordinates: {
      latitude: 4.210484,
      longitude: 101.975766,
    },
  },
  MZ: {
    label: 'Mozambique',
    coordinates: {
      latitude: -18.665695,
      longitude: 35.529562,
    },
  },
  NA: {
    label: 'Namibia',
    coordinates: {
      latitude: -22.95764,
      longitude: 18.49041,
    },
  },
  NC: {
    label: 'New Caledonia',
    coordinates: {
      latitude: -20.904305,
      longitude: 165.618042,
    },
  },
  NE: {
    label: 'Niger',
    coordinates: {
      latitude: 17.607789,
      longitude: 8.081666,
    },
  },
  NF: {
    label: 'Norfolk Island',
    coordinates: {
      latitude: -29.040835,
      longitude: 167.954712,
    },
  },
  NG: {
    label: 'Nigeria',
    coordinates: {
      latitude: 9.081999,
      longitude: 8.675277,
    },
  },
  NI: {
    label: 'Nicaragua',
    coordinates: {
      latitude: 12.865416,
      longitude: -85.207229,
    },
  },
  NL: {
    label: 'Netherlands',
    coordinates: {
      latitude: 52.132633,
      longitude: 5.291266,
    },
  },
  NO: {
    label: 'Norway',
    coordinates: {
      latitude: 60.472024,
      longitude: 8.468946,
    },
  },
  NP: {
    label: 'Nepal',
    coordinates: {
      latitude: 28.394857,
      longitude: 84.124008,
    },
  },
  NR: {
    label: 'Nauru',
    coordinates: {
      latitude: -0.522778,
      longitude: 166.931503,
    },
  },
  NU: {
    label: 'Niue',
    coordinates: {
      latitude: -19.054445,
      longitude: -169.867233,
    },
  },
  NZ: {
    label: 'New Zealand',
    coordinates: {
      latitude: -40.900557,
      longitude: 174.885971,
    },
  },
  OM: {
    label: 'Oman',
    coordinates: {
      latitude: 21.512583,
      longitude: 55.923255,
    },
  },
  PA: {
    label: 'Panama',
    coordinates: {
      latitude: 8.537981,
      longitude: -80.782127,
    },
  },
  PE: {
    label: 'Peru',
    coordinates: {
      latitude: -9.189967,
      longitude: -75.015152,
    },
    mapZoomLevel: 6,
  },
  PF: {
    label: 'French Polynesia',
    coordinates: {
      latitude: -17.679742,
      longitude: -149.406843,
    },
  },
  PG: {
    label: 'Papua New Guinea',
    coordinates: {
      latitude: -6.314993,
      longitude: 143.95555,
    },
  },
  PH: {
    label: 'Philippines',
    coordinates: {
      latitude: 12.879721,
      longitude: 121.774017,
    },
  },
  PK: {
    label: 'Pakistan',
    coordinates: {
      latitude: 30.375321,
      longitude: 69.345116,
    },
  },
  PL: {
    label: 'Poland',
    coordinates: {
      latitude: 51.919438,
      longitude: 19.145136,
    },
  },
  PM: {
    label: 'Saint Pierre and Miquelon',
    coordinates: {
      latitude: 46.941936,
      longitude: -56.27111,
    },
  },
  PN: {
    label: 'Pitcairn',
    coordinates: {
      latitude: -24.703615,
      longitude: -127.439308,
    },
  },
  PR: {
    label: 'Puerto Rico',
    coordinates: {
      latitude: 18.220833,
      longitude: -66.590149,
    },
  },
  PS: {
    label: 'Palestinian Territory, Occupied',
    coordinates: {
      latitude: 31.952162,
      longitude: 35.233154,
    },
  },
  PT: {
    label: 'Portugal',
    coordinates: {
      latitude: 39.399872,
      longitude: -8.224454,
    },
  },
  PW: {
    label: 'Palau',
    coordinates: {
      latitude: 7.51498,
      longitude: 134.58252,
    },
  },
  PY: {
    label: 'Paraguay',
    coordinates: {
      latitude: -23.442503,
      longitude: -58.443832,
    },
  },
  QA: {
    label: 'Qatar',
    coordinates: {
      latitude: 25.354826,
      longitude: 51.183884,
    },
  },
  RE: {
    label: 'Réunion',
    coordinates: {
      latitude: -21.115141,
      longitude: 55.536384,
    },
  },
  RO: {
    label: 'Romania',
    coordinates: {
      latitude: 45.943161,
      longitude: 24.96676,
    },
  },
  RS: {
    label: 'Serbia',
    coordinates: {
      latitude: 44.016521,
      longitude: 21.005859,
    },
  },
  RU: {
    label: 'Russian Federation',
    coordinates: {
      latitude: 61.52401,
      longitude: 105.318756,
    },
  },
  RW: {
    label: 'Rwanda',
    coordinates: {
      latitude: -1.940278,
      longitude: 29.873888,
    },
  },
  SA: {
    label: 'Saudi Arabia',
    coordinates: {
      latitude: 23.885942,
      longitude: 45.079162,
    },
  },
  SB: {
    label: 'Solomon Islands',
    coordinates: {
      latitude: -9.64571,
      longitude: 160.156194,
    },
  },
  SC: {
    label: 'Seychelles',
    coordinates: {
      latitude: -4.679574,
      longitude: 55.491977,
    },
  },
  SD: {
    label: 'Sudan',
    coordinates: {
      latitude: 12.862807,
      longitude: 30.217636,
    },
  },
  SE: {
    label: 'Sweden',
    coordinates: {
      latitude: 60.128161,
      longitude: 18.643501,
    },
  },
  SG: {
    label: 'Singapore',
    coordinates: {
      latitude: 1.352083,
      longitude: 103.819836,
    },
  },
  SH: {
    label: 'Saint Helena, Ascension and Tristan da Cunha',
    coordinates: {
      latitude: -24.143474,
      longitude: -10.030696,
    },
  },
  SI: {
    label: 'Slovenia',
    coordinates: {
      latitude: 46.151241,
      longitude: 14.995463,
    },
  },
  SJ: {
    label: 'Svalbard and Jan Mayen',
    coordinates: {
      latitude: 77.553604,
      longitude: 23.670272,
    },
  },
  SK: {
    label: 'Slovakia',
    coordinates: {
      latitude: 48.669026,
      longitude: 19.699024,
    },
  },
  SL: {
    label: 'Sierra Leone',
    coordinates: {
      latitude: 8.460555,
      longitude: -11.779889,
    },
  },
  SM: {
    label: 'San Marino',
    coordinates: {
      latitude: 43.94236,
      longitude: 12.457777,
    },
  },
  SN: {
    label: 'Senegal',
    coordinates: {
      latitude: 14.497401,
      longitude: -14.452362,
    },
  },
  SO: {
    label: 'Somalia',
    coordinates: {
      latitude: 5.152149,
      longitude: 46.199616,
    },
  },
  SR: {
    label: 'Suriname',
    coordinates: {
      latitude: 3.919305,
      longitude: -56.027783,
    },
  },
  ST: {
    label: 'Sao Tome and Principe',
    coordinates: {
      latitude: 0.18636,
      longitude: 6.613081,
    },
  },
  SV: {
    label: 'El Salvador',
    coordinates: {
      latitude: 13.794185,
      longitude: -88.89653,
    },
  },
  SY: {
    label: 'Syrian Arab Republic',
    coordinates: {
      latitude: 34.802075,
      longitude: 38.996815,
    },
  },
  SZ: {
    label: 'Swaziland',
    coordinates: {
      latitude: -26.522503,
      longitude: 31.465866,
    },
  },
  TC: {
    label: 'Turks and Caicos Islands',
    coordinates: {
      latitude: 21.694025,
      longitude: -71.797928,
    },
  },
  TD: {
    label: 'Chad',
    coordinates: {
      latitude: 15.454166,
      longitude: 18.732207,
    },
  },
  TF: {
    label: 'French Southern Territories',
    coordinates: {
      latitude: -49.280366,
      longitude: 69.348557,
    },
  },
  TG: {
    label: 'Togo',
    coordinates: {
      latitude: 8.619543,
      longitude: 0.824782,
    },
  },
  TH: {
    label: 'Thailand',
    coordinates: {
      latitude: 15.870032,
      longitude: 100.992541,
    },
  },
  TJ: {
    label: 'Tajikistan',
    coordinates: {
      latitude: 38.861034,
      longitude: 71.276093,
    },
  },
  TK: {
    label: 'Tokelau',
    coordinates: {
      latitude: -8.967363,
      longitude: -171.855881,
    },
  },
  TL: {
    label: 'Timor-Leste',
    coordinates: {
      latitude: -8.874217,
      longitude: 125.727539,
    },
  },
  TM: {
    label: 'Turkmenistan',
    coordinates: {
      latitude: 38.969719,
      longitude: 59.556278,
    },
  },
  TN: {
    label: 'Tunisia',
    coordinates: {
      latitude: 33.886917,
      longitude: 9.537499,
    },
  },
  TO: {
    label: 'Tonga',
    coordinates: {
      latitude: -21.178986,
      longitude: -175.198242,
    },
  },
  TR: {
    label: 'Turkey',
    coordinates: {
      latitude: 38.963745,
      longitude: 35.243322,
    },
  },
  TT: {
    label: 'Trinidad and Tobago',
    coordinates: {
      latitude: 10.691803,
      longitude: -61.222503,
    },
  },
  TV: {
    label: 'Tuvalu',
    coordinates: {
      latitude: -7.109535,
      longitude: 177.64933,
    },
  },
  TW: {
    label: 'Taiwan, Province of China',
    coordinates: {
      latitude: 23.69781,
      longitude: 120.960515,
    },
  },
  TZ: {
    label: 'Tanzania, United Republic of',
    coordinates: {
      latitude: -6.369028,
      longitude: 34.888822,
    },
  },
  UA: {
    label: 'Ukraine',
    coordinates: {
      latitude: 48.379433,
      longitude: 31.16558,
    },
  },
  UG: {
    label: 'Uganda',
    coordinates: {
      latitude: 1.373333,
      longitude: 32.290275,
    },
  },
  UM: {
    label: 'United States Minor Outlying Islands',
    coordinates: {
      latitude: 19.2833,
      longitude: 166.6,
    },
  },
  US: {
    label: 'United States',
    coordinates: {
      latitude: 37.09024,
      longitude: -95.712891,
    },
  },
  UY: {
    label: 'Uruguay',
    coordinates: {
      latitude: -32.522779,
      longitude: -55.765835,
    },
  },
  UZ: {
    label: 'Uzbekistan',
    coordinates: {
      latitude: 41.377491,
      longitude: 64.585262,
    },
  },
  VA: {
    label: 'Holy See (Vatican City State)',
    coordinates: {
      latitude: 41.902916,
      longitude: 12.453389,
    },
  },
  VC: {
    label: 'Saint Vincent and the Grenadines',
    coordinates: {
      latitude: 12.984305,
      longitude: -61.287228,
    },
  },
  VE: {
    label: 'Venezuela, Bolivarian Republic of',
    coordinates: {
      latitude: 6.42375,
      longitude: -66.58973,
    },
  },
  VG: {
    label: 'Virgin Islands, British',
    coordinates: {
      latitude: 18.420695,
      longitude: -64.639968,
    },
  },
  VI: {
    label: 'Virgin Islands, U.S.',
    coordinates: {
      latitude: 18.335765,
      longitude: -64.896335,
    },
  },
  VN: {
    label: 'Viet Nam',
    coordinates: {
      latitude: 14.058324,
      longitude: 108.277199,
    },
  },
  VU: {
    label: 'Vanuatu',
    coordinates: {
      latitude: -15.376706,
      longitude: 166.959158,
    },
  },
  WF: {
    label: 'Wallis and Futuna',
    coordinates: {
      latitude: -13.768752,
      longitude: -177.156097,
    },
  },
  WS: {
    label: 'Samoa',
    coordinates: {
      latitude: -13.759029,
      longitude: -172.104629,
    },
  },
  XK: {
    label: 'Kosovo',
    coordinates: {
      latitude: 42.602636,
      longitude: 20.902977,
    },
  },
  YE: {
    label: 'Yemen',
    coordinates: {
      latitude: 15.552727,
      longitude: 48.516388,
    },
  },
  YT: {
    label: 'Mayotte',
    coordinates: {
      latitude: -12.8275,
      longitude: 45.166244,
    },
  },
  ZA: {
    label: 'South Africa',
    coordinates: {
      latitude: -30.559482,
      longitude: 22.937506,
    },
  },
  ZM: {
    label: 'Zambia',
    coordinates: {
      latitude: -13.133897,
      longitude: 27.849332,
    },
  },
  ZW: {
    label: 'Zimbabwe',
    coordinates: {
      latitude: -19.015438,
      longitude: 29.154857,
    },
  },
};

export default countries;
