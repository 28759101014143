import createSvgIcon from '@material-ui/icons/utils/createSvgIcon';
import React from 'react';

export default createSvgIcon(
  <>
    <path d="M20,4v16H3.9V4H20 M20,2H3.9c-1.1,0-2,0.9-2,2v16c0,1.1,0.9,2,2,2H20c1.1,0,2-0.9,2-2V4C22,2.9,21.1,2,20,2L20,2z" />
    <polygon points="13 9 11 9 11 17 13 17" />
    <polygon points="16 12 8 12 8 14 16 14" />
    <path d="M14,6H9.9V2h-1v4c0,0.5,0.5,1,1,1H14c0.5,0,1-0.5,1-1V2h-1V6z" />
  </>,
  'PickupIconIcon'
);
