import React from 'react';
import CustomFieldFormBoolean from './CustomFieldFormBoolean';
import CustomFieldFormCategorical from './CustomFieldFormCategorical';
import CustomFieldFormText from './CustomFieldFormText';

type OwnProps = {
  onUpdate?: (...args: any[]) => any;
  customFields: any;
  projectCustomFields: any[];
  readOnly?: boolean;
  onlyValues?: boolean;
};

type Props = OwnProps;

// @ts-expect-error ts-migrate(2339) FIXME: Property 'size' does not exist on type 'OwnProps'.
const CustomFields = ({ customFields, onUpdate, projectCustomFields, readOnly, onlyValues = false, size }: Props) => {
  const handleUpdate = (fieldId: any, val: any) => {
    if (onUpdate) {
      onUpdate(fieldId, val);
    }
  };

  return (
    <>
      {projectCustomFields.map((projectField: any) => {
        switch (projectField.type) {
          case 'text':
          case 'numerical':
            return (
              <CustomFieldFormText
                key={projectField.id}
                projectField={projectField}
                fieldValue={customFields[projectField.id] ? customFields[projectField.id] : ''}
                readOnly={readOnly}
                onlyValues={onlyValues}
                onUpdate={(value: any) => handleUpdate(projectField.id, value)}
                size={size}
              />
            );
          case 'boolean':
            return (
              <CustomFieldFormBoolean
                key={projectField.id}
                projectField={projectField}
                fieldValue={customFields[projectField.id] ? customFields[projectField.id] : false}
                readOnly={readOnly}
                onlyValues={onlyValues}
                onUpdate={(value: any) => handleUpdate(projectField.id, value)}
                size={size}
              />
            );
          case 'categorical':
            return (
              <CustomFieldFormCategorical
                key={projectField.id}
                projectField={projectField}
                fieldValue={customFields[projectField.id] ? customFields[projectField.id] : []}
                readOnly={readOnly}
                onlyValues={onlyValues}
                onUpdate={(value: any) => handleUpdate(projectField.id, value)}
                size={size}
              />
            );
          default:
            return null;
        }
      })}
    </>
  );
};

CustomFields.defaultProps = {
  readOnly: false,
  onUpdate: () => {},
  size: 'medium',
};

export default CustomFields;
