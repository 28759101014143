/* eslint-disable no-underscore-dangle */
import { applyMiddleware, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import errorMiddleWare from '../middlewares/Error';
import highwayreducers from '../reducers';

let enhancers;

// Check if the environment is development
// and the extension is enabled

const middlewares = applyMiddleware(thunk, errorMiddleWare);

// @ts-expect-error ts-migrate(2580) FIXME: Cannot find name 'process'. Do you need to install... Remove this comment to see the full error message
if (process.env.NODE_ENV === 'development' && window.__REDUX_DEVTOOLS_EXTENSION__) {
  // @ts-expect-error ts-migrate(2339) FIXME: Property '__REDUX_DEVTOOLS_EXTENSION__' does not e... Remove this comment to see the full error message
  enhancers = compose(middlewares, window.__REDUX_DEVTOOLS_EXTENSION__());
} else {
  enhancers = compose(middlewares);
}

const store = createStore(highwayreducers, enhancers);

export default store;
