import { Grid } from '@material-ui/core';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

export const ContainerInputs = styled(({ ...all }) => (
  <Grid container direction="column" style={{ width: '60%' }} {...all} />
))``;

const CenteredContainer = ({ ...all }) => (
  <Grid container justify="center" alignItems="center" {...all} />
);

const RoundPrimitiveButton = styled(CenteredContainer)`
  border-radius: 50%;
  cursor: pointer;
  color: white;
`;

export const ClearButton = styled(RoundPrimitiveButton)`
  height: 24px;
  width: 24px;
  background-color: #333940;
  color: white;
`;

export const ImageContainerLoader = styled(CenteredContainer)`
  height: 120px;
  width: 120px;
  border-radius: 16px;
  border: 1px solid #ababab;
`;

export const ImageContainer = styled(CenteredContainer)`
  height: 120px;
  width: 120px;
  border-radius: 16px;
  ${(props: any) =>
    props.imgUri ? `background: url(${props.imgUri}) no-repeat center center;` : ``}
  background-size: cover;
  border: 1px solid #ababab;
`;

ImageContainer.propTypes = {
  imgUri: PropTypes.string,
};

ImageContainer.defaultProps = {
  imgUri: '',
};

export const AddImageContainer = styled(ImageContainer)`
  background-color: #8194b8;
  color: white;
  margin-right: 10px;
  cursor: pointer;
  ${({ limitReached }: any) => (limitReached ? 'display: none' : '')}
`;

AddImageContainer.propTypes = {
  limitReached: PropTypes.bool,
};

AddImageContainer.defaultProps = {
  limitReached: false,
};
