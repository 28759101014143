import { Divider, Grid, Input, Paper, Typography } from '@material-ui/core';
import { Button, VSpacer } from '@sm-highway-web/react-components';
import { IBillingInfo } from 'highway-api/dist/common/interfaces/subscriptions';
import moment from 'moment';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';
import TruckIcon from '../../assets/svg/TruckIcon';
import { PLAN_TYPE_ICON } from '../../constants/Subscriptions';
import { planPrice } from '../../helpers/Plans';
import FormattedMessage from '../FormattedMessageCustom';

const PLANS = [
  {
    key: 'monkey',
    emoji: (
      <span role="img" aria-label="monkey" style={{ fontSize: '18px', marginRight: '8px' }}>
        {PLAN_TYPE_ICON.MONKEY}
      </span>
    ),
  },
  {
    key: 'gorilla',
    emoji: (
      <span role="img" aria-label="gorilla" style={{ fontSize: '18px', marginRight: '8px' }}>
        {PLAN_TYPE_ICON.GORILLA}
      </span>
    ),
  },
  {
    key: 'kong',
    emoji: (
      <span role="img" aria-label="kong" style={{ fontSize: '18px', marginRight: '8px' }}>
        {PLAN_TYPE_ICON.KONG}
      </span>
    ),
  },
];

const PlanOption = styled(Paper)`
  padding: 20px 12px;
  cursor: pointer;
  min-height: 250px;
  ${(props: any) => `background-color: ${props.isActive ? '#ffb46652' : 'transparent'};`}
  &:hover {
    ${(props: any) => `background-color: ${props.isActive ? '#ffb46652' : '#ffe7ce66'};`}
  }
  ul {
    margin-top: 14px;
    padding-inline-start: 20px;
    list-style: none;
    li {
      margin-bottom: 10px;
      &::before {
        content: '🍌 ';
        margin-left: -25px;
      }
    }
  }
`;

PlanOption.propTypes = {
  // @ts-expect-error ts-migrate(2322) FIXME: Type '{ isActive: PropTypes.Requireable<boolean>; ... Remove this comment to see the full error message
  isActive: PropTypes.bool,
};

PlanOption.defaultProps = {
  // @ts-expect-error ts-migrate(2322) FIXME: Type '{ isActive: boolean; }' is not assignable to... Remove this comment to see the full error message
  isActive: false,
};

type OwnProps = {
  data?: {
    plan?: string;
    vehicles?: number;
    billingAddress?: IBillingInfo;
    card?: any;
  };
  nextButtonIntl?: string;
  onNextStep: (...args: any[]) => any;
};

type Props = OwnProps;

const PaymentPlanSelector = ({ data, nextButtonIntl, onNextStep }: Props) => {
  // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
  const [vehicles, setVehicles] = useState(data.vehicles || 5);
  const [plan, setPlan] = useState(
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    PLANS.find((planELement: any) => planELement.key === data.plan) || PLANS[0]
  );

  return (
    <>
      {/* <Typography variant="h2" align="center">
        <FormattedMessage id="paymentrunwaydialog.step1.title" />
      </Typography> */}
      <VSpacer />

      {/* Plan selector */}
      <Typography variant="h6">
        <FormattedMessage id="paymentrunwaydialog.step1.subsection0.title" />
      </Typography>

      <VSpacer medium />

      <Grid container direction="row" spacing={2} justify="space-between" alignItems="stretch">
        {PLANS.map((planElement) => (
          <Grid key={`plan-type-${planElement.key}`} item xs={6} md={6} lg={4}>
            <PlanOption
              // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
              isActive={plan.key === planElement.key}
              onClick={() => setPlan(planElement)}
            >
              <Typography variant="h3" style={{ color: '#FF8200' }}>
                {planElement.emoji}
                <FormattedMessage id={`paymentrunwaydialog.step1.plan.${planElement.key}.title`} />
              </Typography>
              <Grid container direction="column">
                <FormattedMessage
                  id={`paymentrunwaydialog.step1.plan.${planElement.key}.description`}
                />
              </Grid>
            </PlanOption>
          </Grid>
        ))}
      </Grid>

      <VSpacer large />

      {/* Vehicle number container */}
      <Typography variant="h6">
        <FormattedMessage id="paymentrunwaydialog.step1.subsection1.title" />
      </Typography>

      <VSpacer medium />

      <Paper style={{ padding: '20px 12px' }}>
        <Grid container direction="row">
          <Grid item lg={2}>
            <TruckIcon />
          </Grid>
          <Grid item lg={4}>
            <Input
              value={vehicles}
              type="number"
              inputProps={{ min: '1', max: '1000000', step: '1' }}
              onChange={(evt) => {
                if (evt.target.value) {
                  setVehicles(parseInt(evt.target.value, 10));
                }
              }}
            />
          </Grid>
        </Grid>

        <VSpacer medium />

        <Divider />

        <VSpacer small />

        <Grid container>
          <Grid item lg={2}>
            <Grid container direction="column" justify="center">
              <Typography variant="h6">Total</Typography>
            </Grid>
          </Grid>
          <Grid item lg={10}>
            <Grid container direction="column" justify="center">
              <Typography variant="h6" align="right">
                <FormattedMessage
                  id="settings.billing.subscriptions.pricemonth"
                  values={{
                    value: planPrice(plan.key, vehicles),
                  }}
                />
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Paper>

      <VSpacer large />

      <Typography variant="h6">
        <FormattedMessage id="paymentrunwaydialog.step1.subsection2.title" />
      </Typography>

      <VSpacer medium />

      <Typography>
        <FormattedMessage
          id="paymentrunwaydialog.step1.subsection2.text"
          values={{
            value: planPrice(plan.key, vehicles),
            date: moment().add(30, 'days').format('MMMM DD, YYYY'),
          }}
        />
      </Typography>

      <VSpacer medium />

      <VSpacer />

      <Grid container justify="center">
        <Grid item md={6}>
          <Button
            variant="contained"
            fullWidth
            onClick={() => onNextStep({ vehicles, plan: plan.key })}
          >
            <FormattedMessage id={nextButtonIntl} />
          </Button>
        </Grid>
      </Grid>
      <VSpacer large />
    </>
  );
};

PaymentPlanSelector.defaultProps = {
  data: {},
  nextButtonIntl: 'paymentrunwaydialog.buttonnext',
};

export default PaymentPlanSelector;
