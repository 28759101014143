export const ROOT = '/';
export const NOMATCHES = '*';
export const LOGIN = '/login';
export const REGISTER = '/register';
export const RESETPASSWORD = '/reset-password';
export const USERVALIDATION = '/user/validate/:token';
export const ORGANIZATION_ACCEPT_USER_INVITATION = '/organization/invitation/:token';
export const NEWPASSWORD = '/user/new-password/:token';
export const LOGOUT = '/logout';
export const PUBLICROUTEVIEW = '/public/view';
export const PUBLICROUTEEDIT = '/public/edit';
export const PUBLICMOBILEVIEW = '/public/mobile';
export const PUBLICSUCCESSROUTE = '/public/success';
export const PUBLICERRORROUTE = '/public/error';
export const PRIVATEHIGHWAY = '/h';
export const CLIENTS = '/h/:projectId/clients';
export const VEHICLES = '/h/:projectId/vehicles';
export const PLANS = '/h/:projectId/plans';
export const NO_PROJECTS = '/h/no_projects';

export const COOKIESPOLICY = '/docs/cookies_policy.pdf';

export const EXTERNAL = {
  DOCS: 'http://support.smartmonkey.io/',
  SMARTMONKEY: 'https://smartmonkey.io/',
};

export const ProjectEditViews = {
  general: 'general',
  members: 'members',
  fields: 'fields',
  settings: 'settings',
  communication: 'communication',
};

export const PlanViews = {
  list: 'list',
  map: 'map',
  live: 'live',
};

export const PlanListSubviews = {
  routes: 'routes',
  services: 'services',
};

export const SETTINGS = {
  ROOT: '/h/settings',
  ACCOUNT: {
    ROOT: '/h/settings/account',
    PROFILE: '/h/settings/account/profile',
  },
  ORGANIZATION: {
    ROOT: '/h/settings/organization',
    PROFILE: '/h/settings/organization/profile',
    USERS: '/h/settings/organization/users',
    PROJECTS: {
      ROOT: '/h/settings/organization/projects',
      EDIT: `/h/settings/organization/projects/:projectId/:view?`,
    },
  },
  BILLING: {
    ROOT: '/h/settings/billing',
    INFO: '/h/settings/billing/info',
    PAYMENT: '/h/settings/billing/payment',
    SUBSCRIPTION: '/h/settings/billing/subscription',
    INVOICES: '/h/settings/billing/invoices',
  },
  INTEGRATIONS: {
    ROOT: '/h/settings/integrations',
    INPUT_HOOKS: '/h/settings/integrations/keys',
    OUTPUT_HOOKS: '/h/settings/integrations/webhooks',
  },
};

export const ADMINISTRATION = {
  ROOT: '/h/administration',
  USERS: '/h/administration/users',
  ACQUISITION: '/h/administration/acquisition',
  USAGE: '/h/administration/usage',
  SUBSCRIPTIONS: '/h/administration/subscriptions',
};
