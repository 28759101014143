export const PROJECTS_GET_PROJECTS = 'PROJECTS_GET_PROJECTS';
export const PROJECTS_GET_PROJECTS_SUCCESS = 'PROJECTS_GET_PROJECTS_SUCCESS';
export const PROJECTS_GET_PROJECTS_ERROR = 'PROJECTS_GET_PROJECTS_ERROR';

export const SET_ACTIVE_PROJECT_SUCCESS = 'SET_ACTIVE_PROJECT_SUCCESS';

export const PROJECTS_GET_PROJECT = 'PROJECTS_GET_PROJECT';
export const PROJECTS_GET_PROJECT_SUCCESS = 'PROJECTS_GET_PROJECT_SUCCESS';
export const PROJECTS_GET_PROJECT_ERROR = 'PROJECTS_GET_PROJECT_ERROR';

export const PROJECTS_CREATE_PROJECT = 'PROJECTS_CREATE_PROJECT';
export const PROJECTS_CREATE_PROJECT_SUCCESS = 'PROJECTS_CREATE_PROJECT_SUCCESS';
export const PROJECTS_CREATE_PROJECT_ERROR = 'PROJECTS_CREATE_PROJECT_ERROR';

export const PROJECTS_EDIT_PROJECT = 'PROJECTS_EDIT_PROJECT';
export const PROJECTS_EDIT_PROJECT_SUCCESS = 'PROJECTS_EDIT_PROJECT_SUCCESS';
export const PROJECTS_EDIT_PROJECT_ERROR = 'PROJECTS_EDIT_PROJECT_ERROR';

export const PROJECTS_EDIT_PROJECTS_RESOURCES = 'PROJECTS_EDIT_PROJECTS_RESOURCES';
export const PROJECTS_EDIT_PROJECTS_RESOURCES_SUCCESS = 'PROJECTS_EDIT_PROJECTS_RESOURCES_SUCCESS';
export const PROJECTS_EDIT_PROJECTS_RESOURCES_ERROR = 'PROJECTS_EDIT_PROJECTS_RESOURCES_ERROR';

export const PROJECTS_DELETE_PROJECT = 'PROJECTS_DELETE_PROJECT';
export const PROJECTS_DELETE_PROJECT_SUCCESS = 'PROJECTS_DELETE_PROJECT_SUCCESS';
export const PROJECTS_DELETE_PROJECT_ERROR = 'PROJECTS_DELETE_PROJECT_ERROR';

export const PROJECTS_CREATE_PROJECT_USER = 'PROJECTS_CREATE_PROJECT_USER';
export const PROJECTS_CREATE_PROJECT_USER_SUCCESS = 'PROJECTS_CREATE_PROJECT_USER_SUCCESS';
export const PROJECTS_CREATE_PROJECT_USER_ERROR = 'PROJECTS_CREATE_PROJECT_USER_ERROR';

export const PROJECTS_EDIT_PROJECT_USER = 'PROJECTS_EDIT_PROJECT_USER';
export const PROJECTS_EDIT_PROJECT_USER_SUCCESS = 'PROJECTS_EDIT_PROJECT_USER_SUCCESS';
export const PROJECTS_EDIT_PROJECT_USER_ERROR = 'PROJECTS_EDIT_PROJECT_USER_ERROR';

export const PROJECTS_DELETE_PROJECT_USER = 'PROJECTS_DELETE_PROJECT_USER';
export const PROJECTS_DELETE_PROJECT_USER_SUCCESS = 'PROJECTS_DELETE_PROJECT_USER_SUCCESS';
export const PROJECTS_DELETE_PROJECT_USER_ERROR = 'PROJECTS_DELETE_PROJECT_USER_ERROR';

export const PROJECTS_UPLOAD_PROJECT_AVATAR_SUCCESS = 'PROJECTS_UPLOAD_PROJECT_AVATAR_SUCCESS';
export const PROJECTS_UPLOAD_PROJECT_AVATAR_ERROR = 'PROJECTS_UPLOAD_PROJECT_AVATAR_ERROR';

export const PROJECTS_REMOVE_PROJECT_AVATAR_SUCCESS = 'PROJECTS_REMOVE_PROJECT_AVATAR_SUCCESS';
export const PROJECTS_REMOVE_PROJECT_AVATAR_ERROR = 'PROJECTS_REMOVE_PROJECT_AVATAR_ERROR';

export const PROJECTS_CREATE_CUSTOM_FIELD_SUCCESS = 'PROJECTS_CREATE_CUSTOM_FIELD_SUCCESS';
export const PROJECTS_CREATE_CUSTOM_FIELD_ERROR = 'PROJECTS_CREATE_CUSTOM_FIELD_ERROR';

export const PROJECTS_EDIT_CUSTOM_FIELD_SUCCESS = 'PROJECTS_EDIT_CUSTOM_FIELD_SUCCESS';
export const PROJECTS_EDIT_CUSTOM_FIELD_ERROR = 'PROJECTS_EDIT_CUSTOM_FIELD_ERROR';

export const PROJECTS_REMOVE_CUSTOM_FIELD_SUCCESS = 'PROJECTS_REMOVE_CUSTOM_FIELD_SUCCESS';
export const PROJECTS_REMOVE_CUSTOM_FIELD_ERROR = 'PROJECTS_REMOVE_CUSTOM_FIELD_ERROR';
