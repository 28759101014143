import createSvgIcon from '@material-ui/icons/utils/createSvgIcon';
import React from 'react';

export default createSvgIcon(
  <>
    <path
      fill="#263877"
      d="M17.551,11.3835c0,0-0.0704,1.1732-0.305,2.6514s-0.61,6.1239-0.61,6.1239l-1.0089-1.4782l0.0939-6.382
	l1.2436-0.8916L17.551,11.3835z"
    />
    <path
      fill="#263877"
      d="M6.1746,11.2896c0,0,0.0704,1.1732,0.305,2.6514c0.2346,1.4782,0.61,6.1239,0.61,6.1239l1.0089-1.4782
	l-0.0939-6.382l-1.2436-0.8916L6.1746,11.2896z"
    />
    <polygon
      fill="#98765C"
      points="14.4807,12.2751 11.5712,13.0729 8.8964,12.0874 9.2718,10.6796 10.7265,9.6472 14.246,10.1165 "
    />
    <path
      fill="#E47725"
      d="M5.2361,15.4192c0,0,0.9385-3.5664,1.6424-3.5664s1.1732,1.2201,1.1732,1.2201l0.0469,4.2234l-2.0178,2.1586
	L5.2361,15.4192z"
    />
    <path
      fill="#E47725"
      d="M18.2348,15.4192c0,0-0.9385-3.5664-1.6424-3.5664s-1.1732,1.2201-1.1732,1.2201l-0.0469,4.2234l2.0178,2.2525
	L18.2348,15.4192z"
    />
    <path
      fill="#B08867"
      d="M19.5487,13.9175l0.5631-2.1117c0,0,1.6424-0.3285,2.1117-0.7978c0.4693-0.4693,0.7039-1.924,0.7039-1.924
	s-0.6657-0.3416-0.8916-0.2816c-0.4012,0.1066-1.1262,1.2201-1.1262,1.2201s-1.1262-0.0939-1.5486,0.657
	c-0.4223,0.7508-0.7508,3.2849-0.7508,3.2849L19.5487,13.9175z"
    />
    <path
      fill="#F59825"
      d="M18.3756,12.1813l2.1586,0.2816c0,0-0.0219,6.5983-0.9855,7.2267c-1.0793,0.7039-2.581,0.7978-2.8625-0.657
	S18.3756,12.1813,18.3756,12.1813z"
    />
    <path
      fill="#B08867"
      d="M3.7814,13.5421l-0.2346-1.5486c0,0-1.7363-0.657-1.924-1.4078s-0.61-1.5486-0.61-1.5486
	s0.9819-0.3098,1.267-0.1936c0.4179,0.1704,0.7508,1.4137,0.7508,1.4137s1.2201-0.0939,1.5955,0.7508
	c0.3754,0.8447,0.2346,2.581,0.2346,2.581L3.7814,13.5421z"
    />
    <path
      fill="#F59825"
      d="M3.0306,12.369l2.3463,0.1877c0,0,1.3609,4.5519,1.3609,5.1619c0,0.61,0,1.8771-0.7039,2.0648
	s-2.2056,0.2346-2.4871-0.61S3.0306,12.369,3.0306,12.369z"
    />
    <path
      fill="#F59825"
      d="M9.6003,11.7589c0,0,1.7363,0.4223,2.0648,0.4223s2.2525-0.2346,2.2525-0.2346l-0.657,6.6636l-3.191,0.2816
	L9.6003,11.7589z"
    />
    <path
      fill="#293C8F"
      d="M11.5243,15.7946l-1.1732-4.4111l-0.657-1.5486c0,0-1.5955-0.2346-2.1117,0.0469
	c-0.5162,0.2816-0.3285,0.9855-0.1877,1.1262c0.1408,0.1408,1.4547,0.657,1.4547,0.657l0.0939,4.8804L11.5243,15.7946z"
    />
    <path
      fill="#293C8F"
      d="M11.9818,15.7946l1.1732-4.4111l0.657-1.5486c0,0,1.5955-0.2346,2.1117,0.0469
	c0.5162,0.2816,0.3285,0.9855,0.1877,1.1262c-0.1408,0.1408-1.4547,0.657-1.4547,0.657l-0.0939,4.8804L11.9818,15.7946z"
    />
    <path
      fill="#293C8F"
      d="M6.1746,11.2896c0,0,0.5631,0.0939,0.8447,1.7832c0.2816,1.6894,0.3754,5.1619,0.3754,5.3496
	c0,0.1877-0.3754,0.61-0.3754,0.61l-0.2816,3.9418c0,0,0.4693,0.4693,0.7978,0.4693s9.2445,0,9.2445,0s0.3285-0.3754,0.3285-0.61
	s-0.657-4.0826-0.657-4.0826l-0.3285-0.7508c0,0,0.1877-4.2234,0.3285-4.9273s1.0793-1.6894,1.0793-1.6894l-3.2379-0.3754
	l-1.1732,3.1441l-0.8916,1.6894l-1.1262-0.2346l-1.5017-4.3642l-0.7039-0.3754L6.1746,11.2896z"
    />
    <path
      fill="#B08867"
      d="M15.0569,6.7378c0.5617-3.367-1.3703-4.7865-3.3919-4.7865S7.9109,3.1244,8.2732,6.7378
	c0.2637,2.6303,1.797,4.7422,3.3919,4.7865C13.3544,11.5712,14.6219,9.3452,15.0569,6.7378z"
    />
    <path
      fill="#B08867"
      d="M9.2873,7.0833C9.4437,7.672,9.1643,8.257,8.6634,8.3901C8.1624,8.5232,7.6802,7.866,7.5238,7.2774
	C7.3674,6.6888,7.5813,6.2066,8.097,6.2586C8.6618,6.3154,9.1309,6.4947,9.2873,7.0833z"
    />
    <path
      fill="#B08867"
      d="M13.9985,7.0833c-0.1564,0.5886,0.1229,1.1737,0.6239,1.3068c0.501,0.1331,0.9832-0.5241,1.1396-1.1127
	s-0.0575-1.0708-0.5732-1.0189C14.624,6.3154,14.1549,6.4947,13.9985,7.0833z"
    />
    <path
      fill="#98765C"
      d="M15.0027,5.8344c0,0-0.7567-1.2494-3.2907-1.179c-2.534,0.0704-3.3259,1.2142-3.3259,1.2142l0.1232-1.0734
	l1.0734-1.0734l2.1279-0.4693l2.4123,0.6277l0.7919,1.2494L15.0027,5.8344z"
    />
    <g>
      <path
        fill="#181946"
        d="M8.7908,4.849c0,0-0.1848,0.3519-0.1848,0.7391S8.2732,6.7378,8.2732,6.7378S8.1221,5.8696,8.0517,5.4121
		C7.9813,4.9546,8.7908,4.849,8.7908,4.849z"
      />
      <path
        fill="#181946"
        d="M14.5612,4.849c0,0,0.2655,0.3168,0.2655,0.7039s0.2521,1.1849,0.2521,1.1849s0.1511-0.8681,0.2215-1.3257
		C15.3707,4.9546,14.5612,4.849,14.5612,4.849z"
      />
      <path
        fill="#3B4C9D"
        d="M15.3019,5.3857c0.019-0.103,0.0877-0.5127,0.0616-0.7303c-0.0693-0.5782-0.3349-1.4584-0.4247-1.7727
		c-0.3698-1.2944-1.4437-2.1293-3.2972-2.222C9.7414,0.5658,8.826,1.6286,8.3509,2.8605C8.2671,3.0776,8.0492,3.9962,7.9989,4.6642
		C7.9736,5.0005,8.0546,5.4004,8.0605,5.3945c0.8032-0.8032,1.7597-2.2261,3.6501-2.141
		C15.0188,3.4023,14.1048,5.3973,15.3019,5.3857z"
      />
      <path
        fill="#5066AF"
        d="M11.6633,3.5819c1.5533,0,2.8774,0.6071,3.221,1.9544c0,0,0.055,0.1133,0.1627,0.0869
		c0.1296-0.0318,0.2782-0.3607,0.2782-0.3607c-0.2578-1.4662-2.0164-2.3023-3.6603-2.3023c-1.6138,0-3.3413,0.8051-3.6368,2.2319
		c0,0,0.0708,0.3526,0.2782,0.3695c0.1077,0.0088,0.2249-0.0992,0.2249-0.0992C8.9031,4.1525,10.1386,3.5819,11.6633,3.5819z"
      />
    </g>
  </>,
  'NoDriverIcon'
);
