import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import { Button, Dialog, HSpacer, InputText, VSpacer } from '@sm-highway-web/react-components';
import { colors } from '../assets/svg/TypeIcons';
import VehicleIcons from '../assets/svg/VehicleIcons';
import FormattedMessage from '../components/FormattedMessageCustom';

const styles = {
  icons: {
    margin: '1px',
    width: '32px',
    height: '32px',
  },
  divicons: {
    width: '34px',
    height: '34px',
    borderRadius: '17px',
  },
};

type OwnProps = {
  classes: {
    icons: string;
    divicons: string;
  };
  location?: any;
  open: boolean;
  handleOpenCloseDialog: (...args: any[]) => any;
  createVehicle: (...args: any[]) => any;
  title?: React.ReactElement | {} | string;
};

type Props = OwnProps;

const AddVehicleDialog = ({
  classes,
  open,
  location,
  handleOpenCloseDialog,
  createVehicle,
  title,
}: Props) => {
  const [labelVehicle, setVehicleLabel] = useState(undefined);
  const [typeVehicle, setTypeVehicle] = useState('truck');
  return (
    <Dialog modal onClose={handleOpenCloseDialog} open={open} title={title || 'Create vehicle'}>
      <InputText
        id="label"
        label={<FormattedMessage id="vehicles.data.details.label" />}
        value={labelVehicle || ''}
        onChange={(e: any) => {
          setVehicleLabel(e);
        }}
        margin="none"
        validators={[]}
        fullWidth
      />
      <Grid container>
        {Object.keys(VehicleIcons).map((icon: string) => {
          const Ficon = VehicleIcons[icon];
          return (
            <div id={icon} key={icon}>
              <div
                className={classes.divicons}
                style={{
                  backgroundColor: icon === typeVehicle ? '#ffb466' : 'white',
                }}
              >
                <Ficon
                  className={classes.icons}
                  style={{ color: colors[icon] }}
                  onClick={() => setTypeVehicle(icon)}
                />
              </div>
              <HSpacer small />
              <VSpacer small />
            </div>
          );
        })}
      </Grid>
      <VSpacer medium />
      <Grid container justify="flex-end">
        <Button color="secondary" variant="text" onClick={handleOpenCloseDialog}>
          <FormattedMessage id="cancel" />
        </Button>
        <HSpacer small />
        <Button
          variant="contained"
          onClick={() => {
            createVehicle(labelVehicle, typeVehicle, location);
            handleOpenCloseDialog();
          }}
          disabled={labelVehicle === undefined || labelVehicle === ''}
        >
          <FormattedMessage id="ok" />
        </Button>
      </Grid>
      <VSpacer />
    </Dialog>
  );
};

AddVehicleDialog.defaultProps = {
  location: undefined,
  title: undefined,
};

export default withStyles(styles)(AddVehicleDialog);
