import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  MenuItem,
  Select,
  Typography,
  withStyles,
} from '@material-ui/core';
import React, { Fragment, useEffect, useState } from 'react';
import { Button, VSpacer } from '@sm-highway-web/react-components';
import FormBoolean from '../components/form-fields/FormBoolean';
import FormText from '../components/form-fields/FormText';
import FormTimewindows from '../components/form-fields/FormTimewindows';
import FormattedMessage from '../components/FormattedMessageCustom';
import * as routing from '../constants/Routing';
import history from '../helpers/History';

const styles = {
  select: {
    backgroundColor: '#EBF0F8',
    marginTop: '6px',
    paddingLeft: '6px',
    borderRadius: '4px',
    '&:focus': {
      borderRadius: '4px',
      backgroundColor: '#FDF6ED',
    },
  },
  label: {
    fontSize: '9px',
    color: '#8B8B8B',
    textTransform: 'uppercase',
    marginBottom: '4px',
  },
};

type OwnProps = {
  classes: {
    select?: string;
    label?: string;
  };
  open: boolean;
  constraintsMap: {}[];
  data?: any;
  numberOfElements?: number;
  typeData: string;
  setDialog: (...args: any[]) => any;
  handleSetConstraints: (...args: any[]) => any;
  projectData?: {
    id?: string;
    label?: string;
  };
};

type Props = OwnProps;

const ConstraintsDialog = ({
  open,
  setDialog,
  numberOfElements,
  data,
  constraintsMap,
  typeData,
  handleSetConstraints,
  projectData,
  classes,
}: Props) => {
  const [constraints, setConstraints] = useState({});

  useEffect(() => {
    if (data) {
      setConstraints(data);
    }
  }, [data]);

  return (
    <Dialog
      disableBackdropClick
      disableEscapeKeyDown
      open={open}
      onClose={() => setDialog(undefined)}
    >
      <DialogTitle>
        <Grid container direction="column">
          <Grid item>
            <Typography variant="h5">
              <FormattedMessage id={`${typeData}.constraintsdialog.title`} />
            </Typography>
          </Grid>

          {projectData && (
            <>
              <VSpacer medium />

              <Grid item container direction="row">
                <p style={{ fontSize: '14px', fontWeight: 'normal' }}>
                  <FormattedMessage
                    tagName="span"
                    id={`${typeData}.constraintsdialog.description`}
                    values={{
                      label: projectData.label,
                    }}
                  />
                  <span
                    style={{
                      cursor: 'pointer',
                      color: '#FF8200',
                    }}
                    onClick={() => {
                      history.push(
                        `${routing.SETTINGS.ORGANIZATION.PROJECTS.ROOT}/${projectData.id}/${routing.ProjectEditViews.settings}`
                      );
                    }}
                  >
                    <FormattedMessage id={`${typeData}.constraintsdialog.description_button`} />
                  </span>
                </p>
              </Grid>
            </>
          )}
        </Grid>
      </DialogTitle>
      <DialogContent>
        {/* @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'. */}
        {numberOfElements > 0 && (
          <DialogContentText id="alert-dialog-description">
            <FormattedMessage
              id={`${typeData}.constraintsdialog.content`}
              values={{ data: numberOfElements }}
            />
          </DialogContentText>
        )}
        <Grid container direction="column">
          {constraintsMap.map((c: any) => {
            switch (c.type) {
              case 'number':
                return (
                  <FormText
                    key={`${c.type}${c.id}`}
                    label={<FormattedMessage id={`${typeData}.details.${c.id}`} />}
                    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                    fieldValue={constraints[c.id]}
                    handleEnter={(value: any) =>
                      setConstraints((prevConstraints) => ({ ...prevConstraints, [c.id]: value }))
                    }
                    type="number"
                  />
                );
              case 'select':
                return (
                  <Fragment key={`fragment${c.id}`}>
                    <p className={classes.label} key={`plabel${c.id}`}>
                      <FormattedMessage id={`${typeData}.details.${c.id}`} />
                    </p>
                    <Select
                      key={`select${c.id}`}
                      disableUnderline
                      classes={{ root: classes.select }}
                      onChange={async (evt) => {
                        const { value } = evt.target;
                        if (value === undefined || value === 'noselect') {
                          setConstraints((prevConstraints) => ({
                            ...prevConstraints,
                            [c.id]: null,
                          }));
                        } else {
                          setConstraints((prevConstraints) => ({
                            ...prevConstraints,
                            [c.id]: value,
                          }));
                        }
                      }}
                      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                      value={constraints[c.id] || 'noselect'}
                    >
                      <MenuItem value="noselect" key={`${c.id}menuItemnoselect`}>
                        {c.no_text_id ? (
                          <FormattedMessage id={`${c.no_text_id}`} />
                        ) : c.no_text ? (
                          `${c.no_text}`
                        ) : (
                          '-'
                        )}
                      </MenuItem>
                      {c.options.map((option: any) => (
                        <MenuItem value={`${option.value}`} key={`${c.id}menuItem${option.value}`}>
                          {option.label_id ? (
                            <FormattedMessage id={`${option.label_id}`} />
                          ) : option.text ? (
                            option.text
                          ) : option.label ? (
                            option.label
                          ) : (
                            ''
                          )}
                        </MenuItem>
                      ))}
                    </Select>
                  </Fragment>
                );
              case 'minutes':
                return (
                  <FormText
                    key={`${c.type}${c.id}`}
                    label={<FormattedMessage id={`${typeData}.details.${c.id}`} />}
                    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                    fieldValue={constraints[c.id] ? constraints[c.id] / 60 : undefined}
                    handleEnter={(value: any) => {
                      setConstraints((prevConstraints) => ({
                        ...prevConstraints,
                        [c.id]: value * 60,
                      }));
                    }}
                    suffix="min"
                    type="number"
                  />
                );
              case 'boolean':
                return (
                  <FormBoolean
                    // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type '(string &... Remove this comment to see the full error message
                    key={`${c.type}${c.id}`}
                    label={<FormattedMessage id={`${typeData}.details.${c.id}`} />}
                    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                    checked={constraints[c.id]}
                    onChange={(event: any) => {
                      const { checked } = event.target;
                      setConstraints((prevConstraints) => ({
                        ...prevConstraints,
                        [c.id]: checked,
                      }));
                    }}
                    color="primary"
                  />
                );
              case 'timewindows':
                return (
                  <FormTimewindows
                    key={`${c.type}${c.id}`}
                    label={<FormattedMessage id={`${typeData}.details.${c.id}`} />}
                    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                    timewindows={constraints[c.id]}
                    onTimewindowsChange={(value: any) =>
                      setConstraints((prevConstraints) => ({ ...prevConstraints, [c.id]: value }))
                    }
                  />
                );
              case 'timewindow':
                return (
                  <FormTimewindows
                    key={`${c.type}${c.id}`}
                    label={<FormattedMessage id={`${typeData}.details.${c.id}`} />}
                    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                    timewindows={constraints[c.id]}
                    onTimewindowsChange={(value: any) => {
                      setConstraints((prevConstraints) => ({ ...prevConstraints, [c.id]: value }));
                    }}
                    single
                  />
                );

              default:
                return (
                  <FormText
                    key={`${c.type}${c.id}`}
                    label={<FormattedMessage id={`${typeData}.details.${c.id}`} />}
                    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                    fieldValue={constraints[c.id]}
                    handleEnter={(value: any) =>
                      setConstraints((prevConstraints) => ({ ...prevConstraints, [c.id]: value }))
                    }
                  />
                );
            }
          })}
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={() => setDialog(undefined)} color="primary">
          <FormattedMessage id={`${typeData}.constraintsdialog.cancel`} />
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            handleSetConstraints(constraints);
          }}
          color="primary"
          autoFocus
        >
          <FormattedMessage id={`${typeData}.constraintsdialog.confirm`} />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

ConstraintsDialog.defaultProps = {
  data: undefined,
  numberOfElements: 0,
  projectData: undefined,
};

// @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ select: { backgroundColor: str... Remove this comment to see the full error message
export default withStyles(styles)(ConstraintsDialog);
