import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import AdministrationAcquisition from '../components/AdministrationAcquisition';
import AdministrationLast10 from '../components/AdministrationLast10';
import AdministrationUsage from '../components/AdministrationUsage';
import AdministrationUsers from '../components/AdministrationUsers';
import SettingsLeftMenu from '../components/settings/SettingsLeftMenu';
import adminItems from '../constants/AdministrationItems';
import * as routing from '../constants/Routing';
import eventsPanel, { PageViewPages } from '../services/events';

const styles = {
  allHeight: {
    height: '100vh',
  },
  leftMenu: {
    padding: '24px',
    backgroundColor: 'rgba(234,234,234,0.2)',
    boxShadow: 'inset -1px 0 1px 1px #EAEAEA',
  },
  rightContainer: {
    // padding: '24px',
    height: '100%',
  },
};

type Props = {
  classes: {
    allHeight: string;
    rightContainer: string;
    leftMenu: string;
  };
  location: {
    pathname: string;
  };
  history: {
    push: (...args: any[]) => any;
  };
};

class Administration extends PureComponent<Props> {
  componentDidMount = () => {
    eventsPanel.pageView(PageViewPages.administration);
  };

  render() {
    const { classes, location } = this.props;
    return (
      <Grid container className={classes.allHeight}>
        <Grid item lg={2} md={2} className={classes.leftMenu}>
          <SettingsLeftMenu
            itemsMenu={adminItems()}
            // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
            selected={location.pathname.split('/').pop()}
          />
        </Grid>
        <Grid item lg={10} md={10} className={classes.rightContainer}>
          <Switch>
            <Route path={`${routing.ADMINISTRATION.USERS}`} component={AdministrationUsers} />
            <Route
              path={`${routing.ADMINISTRATION.ACQUISITION}`}
              component={AdministrationAcquisition}
            />
            <Route path={`${routing.ADMINISTRATION.USAGE}`} component={AdministrationUsage} />
            <Route
              path={`${routing.ADMINISTRATION.SUBSCRIPTIONS}`}
              component={AdministrationLast10}
            />
          </Switch>
        </Grid>
      </Grid>
    );
  }
}

export default connect(null, null)(withStyles(styles)(Administration));
