import { IProjectData } from 'highway-api/dist/common/interfaces/projects';
import constants from '../constants/index';

const initialState = {
  projects: undefined,
  activeProject: undefined,
};

function projects(state = initialState, action: any) {
  let currentProject;
  let updatedActiveProject;
  let newProjects;
  switch (action.type) {
    case constants.Projects.SET_ACTIVE_PROJECT_SUCCESS:
      return {
        ...state,
        activeProject: action.payload,
      };
    case constants.Projects.PROJECTS_GET_PROJECT_SUCCESS:
      return {
        ...state,
        activeProject: action.payload,
      };
    case constants.Projects.PROJECTS_GET_PROJECTS_SUCCESS:
      return {
        ...state,
        activeProject: action.payload.activeProject,
        projects: action.payload.projects,
      };
    case constants.Projects.PROJECTS_CREATE_PROJECT_SUCCESS:
      return {
        ...state,
        // @ts-expect-error ts-migrate(2461) FIXME: Type 'undefined' is not an array type.
        projects: [...state.projects, action.payload],
      };
    case constants.Projects.PROJECTS_EDIT_PROJECTS_RESOURCES_SUCCESS:
      updatedActiveProject = action.payload.find(
        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        (project: IProjectData) => project.id === state.activeProject.id
      );

      // Updates the projects resources.
      return {
        ...state,
        activeProject: updatedActiveProject,
        projects: action.payload,
      };
    case constants.Projects.PROJECTS_EDIT_PROJECT_SUCCESS:
    case constants.Projects.PROJECTS_CREATE_CUSTOM_FIELD_SUCCESS:
    case constants.Projects.PROJECTS_EDIT_CUSTOM_FIELD_SUCCESS:
    case constants.Projects.PROJECTS_REMOVE_CUSTOM_FIELD_SUCCESS:
      // Updates the projects array setting the updated project.
      return {
        ...state,
        activeProject:
          // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
          state.activeProject.id === action.payload.id ? action.payload : state.activeProject,
        projects: [
          // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
          ...state.projects.filter((project: any) => project.id !== action.payload.id),
          action.payload,
        ],
      };
    case constants.Projects.PROJECTS_DELETE_PROJECT_SUCCESS:
      // Updates the projects array removing the deleted project.
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      newProjects = state.projects.filter((project: any) => project.id !== action.payload.id);

      return {
        ...state,
        activeProject:
          // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
          state.activeProject.id === action.payload.id ? newProjects[0] : state.activeProject,
        projects: [...newProjects],
      };
    case constants.Projects.PROJECTS_CREATE_PROJECT_USER_SUCCESS:
    case constants.Projects.PROJECTS_EDIT_PROJECT_USER_SUCCESS:
    case constants.Projects.PROJECTS_DELETE_PROJECT_USER_SUCCESS:
      // Getting the current saved project.
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      currentProject = state.projects.find(
        (project: any) => project.id === action.payload.projectId
      );

      // Updates the projects array setting the updated user in the especified project.
      return {
        ...state,
        activeProject:
          // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
          state.activeProject.id === action.payload.projectId
            ? {
                // @ts-expect-error ts-migrate(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
                ...state.activeProject,
                users: action.payload.projectUsers,
              }
            : state.activeProject,
        projects: [
          // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
          ...state.projects.filter((project: any) => project.id !== action.payload.projectId),
          {
            ...currentProject,
            users: action.payload.projectUsers,
          },
        ],
      };
    case constants.User.USER_LOGOUT:
      return initialState;
    case constants.Projects.PROJECTS_UPLOAD_PROJECT_AVATAR_SUCCESS:
      // Getting the current saved project.
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      currentProject = state.projects.find(
        (project: any) => project.id === action.payload.projectId
      );

      // Updates the projects array setting the new avatar in the especified project.
      return {
        ...state,
        activeProject:
          // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
          state.activeProject.id === action.payload.projectId
            ? {
                // @ts-expect-error ts-migrate(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
                ...state.activeProject,
                avatar: action.payload.id,
                avatar_url: action.payload.avatar_url,
                avatar_url_public: action.payload.avatar_url_public,
              }
            : state.activeProject,
        projects: [
          // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
          ...state.projects.filter((project: any) => project.id !== action.payload.projectId),
          {
            ...currentProject,
            avatar: action.payload.id,
            avatar_url: action.payload.avatar_url,
            avatar_url_public: action.payload.avatar_url_public,
          },
        ],
      };
    case constants.Projects.PROJECTS_REMOVE_PROJECT_AVATAR_SUCCESS:
      // Getting the current saved project.
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      currentProject = state.projects.find(
        (project: any) => project.id === action.payload.projectId
      );

      // Updates the projects array removing the avatar in the especified project.
      return {
        ...state,
        activeProject:
          // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
          state.activeProject.id === action.payload.projectId
            ? {
                // @ts-expect-error ts-migrate(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
                ...state.activeProject,
                avatar: undefined,
                avatar_url: undefined,
              }
            : state.activeProject,
        projects: [
          // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
          ...state.projects.filter((project: any) => project.id !== action.payload.projectId),
          {
            ...currentProject,
            avatar: undefined,
            avatar_url: undefined,
          },
        ],
      };
    default:
      return state;
  }
}

export default projects;
