import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  TextareaAutosize,
  TextField,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Button, Form, FormControl, InputText, VSpacer } from '@sm-highway-web/react-components';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { intl } from '../../../helpers/IntGlobalProvider';
import { Projects } from '../../../redux/actions';
import LocationSearch from '../../form-fields/LocationSearch';
import FormattedMessage from '../../FormattedMessageCustom';

const styles = {
  dialogRoot: {
    maxWidth: '700px',
    minWidth: '350px',
  },
  textAreaInput: {
    fontFamily: 'Work Sans',
    fontSize: '16px',
    borderTop: 'none',
    borderLeft: 'none',
    borderRight: 'none',
    borderColor: '#949494',
    outline: 'unset',
  },
};

type OwnOrganizationNewProjectDialogProps = {
  classes: {
    dialogRoot: string;
    textAreaInput: string;
  };
  availableVehicles: number;
  actions: {
    createProject: (...args: any[]) => any;
  };
  onClose: (...args: any[]) => any;
  isOpen?: boolean;
  countryCode?: string | null;
};

type OrganizationNewProjectDialogProps = OwnOrganizationNewProjectDialogProps;

const OrganizationNewProjectDialog = ({
  classes,
  countryCode,
  availableVehicles,
  isOpen,
  onClose,
  actions,
}: OrganizationNewProjectDialogProps) => {
  const [label, setLabel] = useState<string>('');
  const [description, setDescription] = useState<string>('');
  const [location, setLocation] = useState<{ lat: number; lng: number } | null>(null);
  const [maxVehicles, setMaxVehicles] = useState<number>();
  const [disabledSubmitButton, setDisabledSubmitButton] = useState(false);

  useEffect(() => {
    if (availableVehicles !== undefined && availableVehicles !== maxVehicles) {
      setMaxVehicles(availableVehicles);
    }
  }, [availableVehicles, maxVehicles, setMaxVehicles]);

  const closeDialog = () => {
    setLabel('');
    setDescription('');
    setLocation(null);
    setMaxVehicles(availableVehicles);
    setDisabledSubmitButton(false);
    onClose();
  };

  const handleCreateUser = async () => {
    setDisabledSubmitButton(true);
    await actions.createProject(label, description, location, { max_vehicles: maxVehicles });
    setTimeout(() => closeDialog(), 500);
  };

  return (
    <Dialog classes={{ paper: classes.dialogRoot }} open={!!isOpen} onClose={closeDialog}>
      <DialogTitle>
        <FormattedMessage id="settings.organization.projects.new.title" />
      </DialogTitle>
      <DialogContent>
        <Grid container direction="column">
          <Form lazyValidation onSubmit={handleCreateUser} direction="column">
            <InputText
              id="label"
              type="text"
              label={<FormattedMessage id="settings.organization.projects.new.label" />}
              value={label}
              onChange={(value: any) => setLabel(value)}
              margin="none"
              validators={['required']}
              errorMessages={[
                <FormattedMessage
                  key="settings.error.field_mandatory"
                  id="settings.error.field_mandatory"
                />,
              ]}
            />

            <Grid item container direction="column">
              <Typography style={{ marginBottom: '12px' }}>
                <FormattedMessage id="settings.organization.projects.new.description" />
              </Typography>

              <TextareaAutosize
                value={description}
                className={classes.textAreaInput}
                maxLength={512}
                // rowsMin={2} // TODO: NEED UPGRADE DE MATERIAL!!
                rowsMax={4}
                aria-label="Project description"
                placeholder={intl.formatMessage({
                  id: 'settings.organization.projects.new.description_ph',
                })}
                onChange={(evt) => setDescription(evt.target.value)}
              />
            </Grid>

            <VSpacer large />

            <Grid item container direction="column">
              <Typography style={{ marginBottom: '12px' }}>
                <FormattedMessage id="settings.organization.projects.new.location" />
              </Typography>

              <LocationSearch
                placeholder={intl.formatMessage({
                  id: 'settings.organization.projects.new.location_ph',
                })}
                onUpdate={(newLocation: any) => {
                  setLocation(
                    newLocation
                      ? {
                          lat: newLocation.lat,
                          lng: newLocation.lng,
                        }
                      : null
                  );
                }}
                countryCode={countryCode}
              />
            </Grid>

            <VSpacer large />

            <Grid item container direction="column">
              <Typography style={{ marginBottom: '12px' }}>
                <FormattedMessage id="settings.organization.projects.new.max_vehicles" />
              </Typography>

              <TextField
                type="number"
                placeholder={intl.formatMessage({
                  id: 'settings.organization.projects.new.max_vehicles_ph',
                })}
                onChange={(evt) => setMaxVehicles(parseInt(evt.target.value, 10))}
                value={maxVehicles}
                InputProps={{
                  endAdornment: (
                    <div>
                      <span>/</span>
                      <span>{`${availableVehicles}`}</span>
                    </div>
                  ),
                  inputProps: {
                    min: 0,
                    max: availableVehicles,
                  },
                }}
              />
            </Grid>

            <VSpacer large />

            <FormControl alignItems="center" direction="row" justify="space-between">
              <Button onClick={closeDialog} color="secondary">
                <FormattedMessage id="settings.buttoncancel" />
              </Button>
              <Button type="submit" color="primary" disabled={disabledSubmitButton}>
                <FormattedMessage id="settings.buttonupcreate" />
              </Button>
            </FormControl>

            <VSpacer small />
          </Form>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

OrganizationNewProjectDialog.defaultProps = {
  isOpen: false,
  countryCode: undefined,
};

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    createProject: (label: any, description: any, location: any, limits: any) =>
      dispatch(Projects.createProject(label, description, location, limits)),
  },
});

const mapStateToProps = (state: any) => {
  return {
    countryCode: state.user.userLocation && state.user.userLocation.country,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(OrganizationNewProjectDialog));
