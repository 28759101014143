import { Grid, Menu, MenuItem, Slide, Tab, Tabs, Tooltip } from '@material-ui/core';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import { Lock, ReportProblemOutlined } from '@material-ui/icons';
import { HSpacer, Map, Marker, Route } from '@sm-highway-web/react-components';
import { IPlanData } from 'highway-api/dist/common/interfaces/plans';
import { IProjectData } from 'highway-api/dist/common/interfaces/projects';
import { IRouteDataExtended } from 'highway-api/dist/common/interfaces/routes';
import { IServiceDataExtended } from 'highway-api/dist/common/interfaces/services';
import * as qs from 'query-string';
import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import RoutesIconOrange from '../../../assets/img/routes-icon-orange.svg';
import RoutesIconWhite from '../../../assets/img/routes-icon-white.svg';
import ServicesIconOrange from '../../../assets/img/services-icon-orange.svg';
import ServicesIconWhite from '../../../assets/img/services-icon-white.svg';
import WarningIcon from '../../../assets/svg/WarningIcon';
import { getRouteColor } from '../../../constants/Colors';
import * as routing from '../../../constants/Routing';
import AddClientDialog from '../../../dialogs/AddClientDialog';
import AddVehicleDialog from '../../../dialogs/AddVehicleDialog';
import ConfigureTableColumnsDialog from '../../../dialogs/ConfigureTableColumnsDialog';
import ConfirmDeleteDialog from '../../../dialogs/ConfirmDeleteDialog';
import ConstraintsDialog from '../../../dialogs/ConstraintsDialog';
import InteractServicesDialog from '../../../dialogs/InteractServicesDialog';
import { isValidLocation, isValidService } from '../../../helpers/Directions';
import history from '../../../helpers/History';
import { IUserLocation } from '../../../interfaces/common';
import { ActivePlan, Routes, Services } from '../../../redux/actions';
import { getLocationFromLatLng } from '../../../services/requests';
import BrokenConstraintsTooltip from '../../BrokenConstraintsTooltip';
import FormattedMessage from '../../FormattedMessageCustom';
import Loader from '../../Loader';
import RouteMenu from '../../menus/RouteMenu';
import ServiceMenu from '../../menus/ServiceMenu';
import OptimizationWarningsTooltip from '../../OptimizationWarningsTooltip';
import RoutesList from './RoutesList';
import ServicesList from './ServicesList';
import RightPanelInfo from '../../right-panel-info/RightPanelInfo';

const styles = ({ breakpoints }: Theme) => createStyles({
  tabContainer: {
    backgroundColor: '#333940',
  },

  tabsroot: {
    minHeight: 'unset',
    color: 'white',
  },

  tabroot: {
    padding: '0px 12px',
    height: '100%',
    width: '200px',
    color: 'white',
    fontWidth: 500,
  },

  divInside: {
    height: 'calc(100vh - 96px)',
    width: '100%',
  },

  divHeader: {
    height: '48px',
    borderBottom: '1px solid #CACACA',
  },

  divContainer: {
    height: '100%',
    position: 'relative',
  },

  divContainerData: {
    width: 'calc(100% - 400px)',
    height: '100%',
    [breakpoints.down('sm')]: {
      width: '100%',
    },
  },

  divContainerMap: {
    width: '400px',
    [breakpoints.down('sm')]: {
      zIndex: '-100',
    },
  },

  grid: {
    maxHeight: 'calc(100% - 48px)',
    width: '100%',
  },

  divTable: {},

  allFull: {
    height: '100%',
    width: '100%',
  },

  rightPanelInfo: {
    position: 'absolute',
    right: '0px',
    top: '0px',
    height: '100%',
    zIndex: 1200,
    width: '400px',
    backgroundColor: 'white',
    overflow: 'hidden',
  },

  rootMenu: {
    width: '240px',
  },

  menuItem: {
    height: '42px',
  },

  highlighted: {
    backgroundColor: '#EEF0F2',
    color: '#050D1E',
    textAlign: 'center',
    borderRadius: '4px',
    padding: '2px 8px 2px 8px',
  },

  centered: {
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'center',
  },
});

const TabPanel = ({ value, index, children }: any) => {
  return value === index ? children : null;
};

type ComponentRouteMatchProps = {
  planId?: string;
  view?: string;
}

type ComponentRouteSearchProps = {
  subView?: string;
}

interface PlanListProps extends RouteComponentProps<ComponentRouteMatchProps, {}, ComponentRouteSearchProps> {
  classes: {
    divInside: string;
    divHeader: string;
    divContainer: string;
    divContainerData: string;
    divContainerMap: string;
    allFull: string;
    rightPanelInfo: string;
    rootMenu: string;
    menuItem: string;
    grid: string;
    highlighted: string;
    tabroot: string;
    tabContainer: string;
    tabsroot: string;
    centered: string;
    divTable: string;
  };
  plan: IPlanData;
  actions: {
    cleanDetailView: (...args: any[]) => any;
    services: {
      setService: (...args: any[]) => any;
      updateService: (...args: any[]) => any;
      createServicesToAPlan: (...args: any[]) => any;
      deleteServices: (...args: any[]) => any;
      updateServices: (...args: any[]) => any;
      setListSelectedServices: (...args: any[]) => any;
      saveServiceAsClient: (...args: any[]) => any;
    };
    routes: {
      setRoute: (...args: any[]) => any;
      setListSelectedRoutes: (...args: any[]) => any;
      updateRoute: (...args: any[]) => any;
      updateRoutes: (...args: any[]) => any;
      updateMultipleRoutes: (...args: any[]) => any;
      deleteRoutes: (...args: any[]) => any;
      addRoutesToAPlan: (...args: any[]) => any;
      saveRouteAsVehicle: (...args: any[]) => any;
    };
  };
  detailView?: {
    type: 'service' | 'route';
    data?: IServiceDataExtended | IRouteDataExtended;
  };
  selectedRoutes: IRouteDataExtended[];
  selectedServices: IServiceDataExtended[];
  openServicesModal: (...args: any[]) => any;
  openRoutesModal: (...args: any[]) => any;
  projectMaxServices: number;
  userLocation?: IUserLocation;
  activeProject: IProjectData;
}

const PlanList = ({
  match,
  classes,
  location,
  actions,
  plan,
  detailView,
  selectedServices,
  selectedRoutes,
  openServicesModal,
  openRoutesModal,
  projectMaxServices,
  activeProject,
  userLocation,
}: PlanListProps) => {
  
  const [contextAnchor, setContextAnchor] = useState(undefined);

  const [selectedLocation, setSelectedLocation] = useState<{ [key: string]: any } | [] | undefined>(undefined);

  const [tabSelected, setTabSelected] = useState<string | undefined>(undefined);

  // This fragment enables the browser goBack action.
  useEffect(() => {
    const queryParams = qs.parse(location?.search || '');
    if(tabSelected && !queryParams?.subView) history.goBack();
  }, [location.search, tabSelected]);

  const [createServiceModal, setCreateServiceModal] = useReducer((prevCreateServiceModal) => {
    if (prevCreateServiceModal) {
      setSelectedLocation(undefined);
    }
    return !prevCreateServiceModal;
  }, false);

  const [createRouteModal, setCreateRouteModal] = useReducer((prevCreateRouteModal) => {
    if (prevCreateRouteModal) {
      setSelectedLocation(undefined);
    }
    return !prevCreateRouteModal;
  }, false);

  const [rightPanelVisible, setRightPanelVisible] = useState<boolean>(false);
  const [dialog, setDialog] = useState<
  'deleteRoutes' |
  'deleteRoute' |
  'deleteServices' |
  'deleteService' |
  'constraintsRoutes' |
  'constraintsServices' |
  'assignServices' |
  'configureColumnsRoutes' |
  'configureColumnsServices' |
  undefined>(undefined);

  const setLocationView = useCallback(
    (subView) => {
      if (activeProject && subView !== tabSelected && Object.keys(routing.PlanListSubviews).indexOf(subView) > -1) {
        setTabSelected(subView);
        history.push(
          `${routing.PLANS.replace(':projectId', activeProject.id)}/${match.params.planId}/${
            match.params.view
          }?subView=${subView}`
        );
      }
    },
    [tabSelected, activeProject, setTabSelected, match.params]
  );

  useEffect(() => {
    
    if(match.params.view) {
      
      const queryParams = qs.parse(location?.search || '');
      const subView = String(queryParams?.subView);

      if (
        subView &&
        Object.keys(routing.PlanListSubviews).indexOf(subView) > -1
      ) {
        actions.cleanDetailView();
        setTabSelected(subView);
      } else {
        setLocationView(routing.PlanListSubviews.routes);
      }
    }
  }, [setLocationView, tabSelected, setTabSelected, match.params, actions, location]);

  const handleChangeRouteSelected = (route: any) => {
    actions.routes.setRoute(route);
  };

  const handleChangeServiceSelected = (service: any) => {
    actions.services.setService(service);
  };

  const handleDeleteRoutes = () => {
    setDialog(undefined);
    actions.routes.setRoute(undefined);
    actions.routes.deleteRoutes(selectedRoutes.filter((route: any) => !route.is_locked));
    setRightPanelVisible(false);
  };

  const handleDeleteServices = () => {
    setDialog(undefined);
    actions.services.setService(undefined);
    actions.services.deleteServices(selectedServices);
    setRightPanelVisible(false);
  };

  const handleChangeConstraintsRoutes = (constraints: any) => {
    setDialog(undefined);
    setRightPanelVisible(false);
    actions.routes.setRoute(undefined);
    actions.routes.updateRoutes(
      selectedRoutes.filter((route: any) => !route.is_locked),
      constraints
    );
  };

  const handleChangeConstraintsServices = (constraints: any) => {
    setDialog(undefined);
    actions.services.setService(undefined);
    actions.services.updateServices(selectedServices, constraints);
  };

  const handleAssignServices = (destinationRoute: any) => {
    setDialog(undefined);

    if (destinationRoute === 'missing') {
      const servicesToUpdate = selectedServices.filter((service: any) => service.route_id);
      const servicesToUpdateIds = servicesToUpdate.map((service: any) => service.route_id);
      const routesToUpdate = plan.routes.filter(
        (route: any) => !route.is_locked && servicesToUpdateIds.includes(route.id)
      );

      const routesDataToUpdate = routesToUpdate.map((route: any) => {
        const routeServices = servicesToUpdate
          .filter((service: any) => service.route_id === route.id)
          .map((service: any) => service.id);
        const routeNewServices = route.services
          .filter((routeService: any) => !routeServices.includes(routeService.id))
          .map((s: any) => s.id);
        return {
          id: route.id,
          services: routeNewServices,
        };
      });

      actions.routes.updateMultipleRoutes(routesDataToUpdate);
    } else {
      const currentRouteServices = destinationRoute.services.map((service: any) => service.id);
      const newRouteServices = selectedServices
        .filter((service: any) => !currentRouteServices.includes(service.id))
        .map((service: any) => service.id);
      if (newRouteServices.length > 0) {
        actions.routes.updateRoute(destinationRoute.id, {
          services: [...currentRouteServices, ...newRouteServices],
        });
      }
    }
  };

  return (
    <div className={classes.divInside}>
      <Grid container className={classes.divContainer}>
        <Grid container className={classes.divContainerData} direction="column">
          <Grid item>
            <Grid container alignItems="center" className={classes.tabContainer}>
              <Tabs
                id="plan-list-tabs"
                value={tabSelected}
                onChange={(e, v) => {
                  setLocationView(v);

                  // User is navigating between tabs, so uncheck all the previous selected routes or services.
                  if (v === routing.PlanListSubviews.routes) {
                    actions.services.setListSelectedServices([]);
                  } else if (v === routing.PlanListSubviews.services) {
                    actions.routes.setListSelectedRoutes([]);
                  }
                }}
                indicatorColor="primary"
                textColor="primary"
                classes={{ root: classes.tabsroot }}
              >
                <Tab
                  label={
                    <Grid container justify="center" alignItems="center">
                      <img
                        height="18"
                        src={
                          tabSelected === routing.PlanListSubviews.routes
                            ? RoutesIconOrange
                            : RoutesIconWhite
                        }
                        alt=""
                      />
                      <HSpacer small />
                      <FormattedMessage id="plans.create.table1.title" />
                      <HSpacer small />
                      <span>{plan && `(${plan.routes.length})`}</span>
                    </Grid>
                  }
                  value={routing.PlanListSubviews.routes}
                  classes={{ root: classes.tabroot }}
                />
                <Tab
                  label={
                    <Grid container justify="center" alignItems="center">
                      <img
                        height="18"
                        src={
                          tabSelected === routing.PlanListSubviews.services
                            ? ServicesIconOrange
                            : ServicesIconWhite
                        }
                        alt=""
                      />
                      <HSpacer small />
                      <FormattedMessage id="plans.create.table2.title" />
                      <HSpacer small />
                      <span>{plan && `(${plan.services.length})`}</span>
                    </Grid>
                  }
                  value={routing.PlanListSubviews.services}
                  classes={{ root: classes.tabroot }}
                />
              </Tabs>
            </Grid>
          </Grid>

          <Grid container style={{ height: 'calc(100% - 48px)' }} direction="column">
            <TabPanel value={tabSelected} index={routing.PlanListSubviews.routes}>
              {/* @ts-expect-error */}
              <RoutesList
                plan={plan}
                openRoutesModal={openRoutesModal}
                setDialog={setDialog}
                setRightPanelVisible={setRightPanelVisible}
                setRoute={actions.routes.setRoute}
                updateRoutes={actions.routes.updateRoutes}
                addRoutesToAPlan={actions.routes.addRoutesToAPlan}
                saveRouteAsVehicle={actions.routes.saveRouteAsVehicle}
                selectedRoutes={selectedRoutes}
                setListSelectedRoutes={actions.routes.setListSelectedRoutes}
              />
            </TabPanel>
            <TabPanel value={tabSelected} index={routing.PlanListSubviews.services}>
              {/* @ts-expect-error */}
              <ServicesList
                plan={plan}
                openServicesModal={openServicesModal}
                setDialog={setDialog}
                setRightPanelVisible={setRightPanelVisible}
                setService={actions.services.setService}
                createServicesToAPlan={actions.services.createServicesToAPlan}
                saveServiceAsClient={actions.services.saveServiceAsClient}
                selectedServices={selectedServices}
                setListSelectedServices={actions.services.setListSelectedServices}
              />
            </TabPanel>
          </Grid>
        </Grid>
        <Grid className={classes.divContainerMap}>
          <Map
            center={
              !plan || plan.services.length === 0 || plan.routes.length === 0
                ? userLocation
                    ? [userLocation.latitude, userLocation.longitude]
                    : undefined
                : undefined
            }
            zoomLevel={(!plan || plan.services.length === 0 || plan.routes.length === 0) && userLocation ? userLocation.mapZoomLevel : undefined}
            height="100%"
            onContextualMenu={async (event: any) => {
              try {
                const result = await getLocationFromLatLng(event.latlng.lat, event.latlng.lng);
                setSelectedLocation(result);
              } catch (error) {
                // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
                setSelectedLocation(null);
              }
              setContextAnchor({
                // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ screenPosition: { top: any; le... Remove this comment to see the full error message
                screenPosition: {
                  top: event.originalEvent.y,
                  left: event.originalEvent.x,
                },
                coordinates: {
                  lat: event.latlng.lat,
                  lng: event.latlng.lng,
                },
              });
            }}
          >
            {contextAnchor && (
              <Menu
                onClose={() => {
                  setContextAnchor(undefined);
                  setSelectedLocation(undefined);
                }}
                // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
                open={contextAnchor}
                anchorReference="anchorPosition"
                // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
                anchorPosition={contextAnchor && contextAnchor.screenPosition}
                classes={{ list: classes.rootMenu }}
              >
                {selectedLocation === undefined && <Loader noMonkey />}
                {selectedLocation === null && (
                  <p style={{ padding: '16px' }}>Nothing found in this location</p>
                )}
                {selectedLocation && (
                  <>
                    <p style={{ padding: '16px', fontSize: '11px' }}>{(selectedLocation as { [key: string]: any })?.label}</p>
                    {detailView?.type === 'service' && detailView?.data && (
                      <MenuItem
                        className={classes.menuItem}
                        onClick={() => {
                          actions.services.updateService((detailView?.data as IServiceDataExtended)?.id, {
                            location: selectedLocation,
                          });
                          setContextAnchor(undefined);
                        }}
                      >
                        <FormattedMessage id="services.details.map.add_to_current" />
                      </MenuItem>
                    )}
                    {detailView?.type === 'route' && detailView?.data && (
                      <>
                        <MenuItem
                          className={classes.menuItem}
                          onClick={() => {
                            actions.routes.updateRoute((detailView?.data as IRouteDataExtended)?.id, {
                              start_location: selectedLocation,
                            });
                            setContextAnchor(undefined);
                          }}
                        >
                          <FormattedMessage id="routes.details.map.add_to_current_start" />
                        </MenuItem>
                        <MenuItem
                          className={classes.menuItem}
                          onClick={() => {
                            actions.routes.updateRoute((detailView?.data as IRouteDataExtended)?.id, {
                              end_location: selectedLocation,
                            });
                            setContextAnchor(undefined);
                          }}
                        >
                          <FormattedMessage id="routes.details.map.add_to_current_end" />
                        </MenuItem>
                      </>
                    )}
                    <MenuItem
                      className={classes.menuItem}
                      disabled={plan && plan.services?.length >= projectMaxServices}
                      onClick={() => {
                        setContextAnchor(undefined);
                        setCreateServiceModal();
                      }}
                    >
                      <FormattedMessage id="services.details.map.create_new" />
                    </MenuItem>
                    <MenuItem
                      className={classes.menuItem}
                      disabled={plan && plan.services?.length >= projectMaxServices}
                      onClick={() => {
                        setContextAnchor(undefined);
                        setCreateRouteModal();
                      }}
                    >
                      <FormattedMessage id="routes.details.map.create_new" />
                    </MenuItem>
                  </>
                )}
              </Menu>
            )}
            {plan &&
              plan.services.filter(isValidService).map((s: any) => (
                <Marker
                  id={`marker${s.id}`}
                  key={`marker${s.id}`}
                  lat={s.location.lat}
                  lng={s.location.lng}
                  selected={
                    detailView?.type === 'service' &&
                    detailView?.data &&
                    detailView?.data.id === s.id
                  }
                  onClick={() => handleChangeServiceSelected(s)}
                  kind={s.icon ? s.icon : 'normal'}
                />
              ))}
            {plan &&
              plan.routes.map((r: IRouteDataExtended) => {
                const routeSelected =
                  detailView &&
                  detailView.type === 'route' &&
                  detailView.data &&
                  detailView.data.id === r.id;

                const roluteColor = getRouteColor(r, plan.routes);

                return r.planned_track ? (
                  <Route
                    id={`route-${r.id}`}
                    key={`route-${r.id}`}
                    path={r.planned_track}
                    color={roluteColor}
                    selected={routeSelected}
                    onClick={() => handleChangeRouteSelected(r)}
                  >
                    {isValidLocation(r.start_location) ? (
                      <Marker
                        id={`start${r.id}`}
                        key={`start${r.id}`}
                        lat={r.start_location?.lat}
                        lng={r.start_location?.lng}
                        kind="start"
                        color={roluteColor}
                      />
                    ) : null}
                    {isValidLocation(r.end_location) ? (
                      <Marker
                        id={`end${r.id}`}
                        key={`end${r.id}`}
                        lat={r.end_location?.lat}
                        lng={r.end_location?.lng}
                        kind="end"
                        color={roluteColor}
                      />
                    ) : null}
                  </Route>
                ) : null;
              })}
          </Map>
        </Grid>

        {rightPanelVisible && detailView?.type === 'service' && detailView?.data && (
          <Slide
            in={Boolean(detailView?.type === 'service' && detailView?.data)}
            direction="left"
            mountOnEnter
          >
            <Grid className={classes.rightPanelInfo}>
              <RightPanelInfo
                key={`${detailView?.data?.id}`}
                type="service"
                onClose={() => {
                  actions.services.setService(undefined);
                  setRightPanelVisible(false);
                }}
                onUpdate={actions.services.updateService}
                data={detailView?.data as IServiceDataExtended}
                warning={
                  <>
                    <OptimizationWarningsTooltip planRoutes={plan.routes} service={detailView?.data as IServiceDataExtended}>
                      <WarningIcon style={{ color: '#D0021B', fontSize: '24px' }} />
                    </OptimizationWarningsTooltip>
                    <BrokenConstraintsTooltip
                      type="service"
                      route={
                        plan?.routes?.find((route: any) => route.id === (detailView?.data as IServiceDataExtended)?.route_id)
                      }
                      service={detailView?.data as IServiceDataExtended}
                    >
                      <ReportProblemOutlined style={{ textAlign: 'center', color: 'orange', fontSize: '24px' }} />
                    </BrokenConstraintsTooltip>
                  </>
                }
                options={
                  <ServiceMenu
                    type="popover"
                    plan={plan}
                    service={detailView?.data as IServiceDataExtended}
                    showViewDetails={false}
                    setRightPanelVisible={setRightPanelVisible}
                  />
                }
                countryCode={plan?.optimizer_config?.operation_country}
              />
            </Grid>
          </Slide>
        )}
        {rightPanelVisible && detailView?.type === 'route' && detailView?.data && (
          <Slide
            in={Boolean(detailView.type === 'route' && detailView?.data)}
            direction="left"
            mountOnEnter
          >
            <Grid className={classes.rightPanelInfo}>
              <RightPanelInfo
                key={`${detailView?.data && detailView?.data.id}`}
                type="route"
                warning={
                  <Grid container alignItems="center">
                    {detailView?.data && (detailView.data as IRouteDataExtended).is_locked && (
                      <Tooltip
                        title={
                          <FormattedMessage id="planoverview.route.table.header.locked_tooltip" />
                        }
                      >
                        <Lock style={{ marginRight: '8px', fontSize: '18px', color: '#56637b' }} />
                      </Tooltip>
                    )}
                    <BrokenConstraintsTooltip type="route" route={detailView?.data as IRouteDataExtended}>
                      <ReportProblemOutlined style={{ textAlign: 'center', color: 'orange', fontSize: '24px' }} />
                    </BrokenConstraintsTooltip>
                  </Grid>
                }
                onClose={() => {
                  actions.routes.setRoute(undefined);
                  setRightPanelVisible(false);
                }}
                onUpdate={actions.routes.updateRoute}
                data={{
                  ...detailView?.data,
                  color: getRouteColor(detailView?.data as IRouteDataExtended, plan.routes),
                } as IRouteDataExtended}
                options={
                  <RouteMenu
                    type="popover"
                    plan={plan}
                    route={detailView?.data as IRouteDataExtended}
                    showViewDetails={false}
                    setRightPanelVisible={setRightPanelVisible}
                  />
                }
                countryCode={plan.optimizer_config ? plan.optimizer_config.operation_country : null}
              />
            </Grid>
          </Slide>
        )}
      </Grid>
      <AddClientDialog
        title={<FormattedMessage id="services.details.map.create_new" />}
        open={createServiceModal}
        location={selectedLocation}
        handleOpenCloseDialog={setCreateServiceModal}
        createClient={(label: any, icon: any, _location: any) =>
          actions.services.createServicesToAPlan(plan.id, [{ label, icon, location: _location }])
        }
      />
      <AddVehicleDialog
        title={<FormattedMessage id="routes.details.map.create_new" />}
        open={createRouteModal}
        location={selectedLocation}
        handleOpenCloseDialog={setCreateRouteModal}
        createVehicle={(label: any, icon: any, _location: any) =>
          actions.routes.addRoutesToAPlan(plan.id, [
            { label, icon, start_location: _location, end_location: _location },
          ])
        }
      />
      <ConfirmDeleteDialog
        typeData="routes"
        open={dialog === 'deleteRoutes'}
        setDialog={setDialog}
        data={selectedRoutes.filter((route: any) => !route.is_locked)}
        handleDelete={handleDeleteRoutes}
      />
      <ConfirmDeleteDialog
        typeData="routes"
        open={dialog === 'deleteRoute'}
        setDialog={setDialog}
        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        data={detailView.type === 'route' && detailView.data ? detailView.data : {}}
        handleDelete={handleDeleteRoutes}
      />
      <ConfirmDeleteDialog
        typeData="services"
        open={dialog === 'deleteServices'}
        setDialog={setDialog}
        data={selectedServices}
        handleDelete={handleDeleteServices}
      />
      <ConfirmDeleteDialog
        typeData="services"
        open={dialog === 'deleteService'}
        setDialog={setDialog}
        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        data={detailView.type === 'service' && detailView.data ? detailView.data : {}}
        handleDelete={handleDeleteServices}
      />
      {activeProject && (
        <>
          <ConstraintsDialog
            typeData="routes"
            open={dialog === 'constraintsRoutes'}
            constraintsMap={[
              // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
              ...(activeProject.view.constraints.timewindows
                ? [{ id: 'timewindow', type: 'timewindow' }]
                : []),
              // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
              ...(activeProject.view.constraints.distance
                ? [{ id: 'max_distance', type: 'number' }]
                : []),
              // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
              ...(activeProject.view.constraints.weight
                ? [{ id: 'max_weight', type: 'number' }]
                : []),
              // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
              ...(activeProject.view.constraints.volume
                ? [{ id: 'max_volume', type: 'number' }]
                : []),
              // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
              ...(activeProject.view.constraints.max_services
                ? [{ id: 'max_services', type: 'number' }]
                : []),
            ]}
            setDialog={setDialog}
            numberOfElements={selectedRoutes.filter((route: any) => !route.is_locked).length}
            handleSetConstraints={handleChangeConstraintsRoutes}
          />
          <ConstraintsDialog
            typeData="services"
            open={dialog === 'constraintsServices'}
            constraintsMap={[
              ...(activeProject?.view?.constraints.timewindows
                ? [{ id: 'timewindows', type: 'timewindows' }]
                : []),
              ...(activeProject?.view?.constraints.timewindows
                ? [{ id: 'weight', type: 'number' }]
                : []),
              ...(activeProject?.view?.constraints.timewindows
                ? [{ id: 'volume', type: 'number' }]
                : []),
              { id: 'duration', type: 'minutes' },
              // { id: 'cluster', type: 'text' }
            ]}
            setDialog={setDialog}
            numberOfElements={selectedServices.length}
            handleSetConstraints={handleChangeConstraintsServices}
          />
        </>
      )}
      <InteractServicesDialog
        type="assign"
        open={dialog === 'assignServices'}
        routes={plan && plan.routes}
        setDialog={setDialog}
        handleInteractServices={handleAssignServices}
      />
      <ConfigureTableColumnsDialog
        typeData="routes"
        open={dialog === 'configureColumnsRoutes'}
        setDialog={setDialog}
        projectView={activeProject?.view}
      />
      <ConfigureTableColumnsDialog
        typeData="services"
        open={dialog === 'configureColumnsServices'}
        setDialog={setDialog}
        projectView={activeProject?.view}
      />
    </div>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    cleanDetailView: () => dispatch(ActivePlan.cleanDetailView()),
    services: {
      setService: (service: any) => dispatch(ActivePlan.setDetailView('service', service)),
      setListSelectedServices: (services: any) =>
        dispatch(ActivePlan.setListSelectedServices(services)),
      updateService: (serviceId: any, service: any) =>
        dispatch(Services.updateService(serviceId, service)),
      updateServices: (services: any, changes: any) =>
        dispatch(Services.updateServices(services, changes)),
      createServicesToAPlan: (planId: any, service: any) =>
        dispatch(Services.createServicesToAPlan(service, planId)),
      saveServiceAsClient: (service: any) => dispatch(Services.saveServiceAsClient(service)),
      deleteServices: (services: any) => dispatch(Services.deleteServices(services)),
    },
    routes: {
      setRoute: (route: any) => dispatch(ActivePlan.setDetailView('route', route)),
      setListSelectedRoutes: (routes: any) => dispatch(ActivePlan.setListSelectedRoutes(routes)),
      updateRoute: (routeId: any, services: any) => dispatch(Routes.updateRoute(routeId, services)),
      updateRoutes: (routes: any, changes: any) => dispatch(Routes.updateRoutes(routes, changes)),
      updateMultipleRoutes: (routes: any) => dispatch(Routes.updateMultipleRoutes(routes)),
      addRoutesToAPlan: (planId: any, routes: any) =>
        dispatch(ActivePlan.createRoutes(routes, planId)),
      saveRouteAsVehicle: (route: any) => dispatch(Routes.saveRouteAsVehicle(route)),
      deleteRoutes: (routes: any) => dispatch(Routes.deleteRoutes(routes)),
    },
  },
});

const mapStateToProps = (state: any) => {
  return {
    plan: state.activePlan.plan,
    detailView: state.activePlan.detailView,
    selectedServices: state.activePlan.viewList.services || [],
    selectedRoutes: state.activePlan.viewList.routes || [],
    projectMaxServices:
      state.projects.activeProject && state.projects.activeProject.limits.max_services_plan ? state.projects.activeProject.limits.max_services_plan : 0,
    userLocation: state.user.userLocation,
    activeProject: state.projects.activeProject,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withRouter(PlanList)));
