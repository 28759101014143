import axios from 'axios';
import configuration from '../../services/configuration';
import eventsPanel from '../../services/events';
import Froged from '../../services/froged';
import getHighway from '../../services/highway';
import { deleteQuery, get, post, put } from '../../services/requests';
import constants from '../constants';
import store from '../store/index';
import { Projects } from './index';
import * as User from './User';

export const getOrganization = (token?: any) => {
  return (dispatch: any) => {
    return get(`/organization`, undefined, {
      Authorization: `Bearer ${token || store.getState().user.token}`,
    })
      .then((response) => {
        const { users, ...organization } = response.data;
        dispatch({
          type: constants.Organization.ORGANIZATION_DETAILS_SUCCESS,
          payload: {
            users,
            organization,
          },
        });
        eventsPanel.completeLogin(
          response.data.subscription && 
          response.data.subscription.plan &&
          response.data.subscription.status === 'active' ? 
              response.data.subscription.plan.type
            : null
        );

        if(organization.country)User.setUserLocationByCountry(organization.country);
      })
      .catch((error) => {
        dispatch({
          type: constants.Organization.ORGANIZATION_DETAILS_ERROR,
          payload: error,
        });
      });
  };
};

export const registerOrganization = (
  email: any,
  password: any,
  firstName: any,
  lastName: any,
  company: any,
  phone: any,
  truckNumber: any,
  country: any
) => {
  return (dispatch: any) => {
    return axios
      .post(`${configuration.HIGHWAY_BACK_END}/organization`, {
        email,
        password,
        first_name: firstName,
        last_name: lastName,
        company,
        phone,
        truck_number: truckNumber,
        language: store.getState().user.language,
        country,
      })
      .then((response) => {
        dispatch({
          type: constants.Organization.ORGANIZATION_REGISTER_SUCCESS,
          payload: response.data,
        });

        Froged.boot();
        Froged.set({
          email,
          firstname: firstName,
          lastname: lastName,
          company,
          phone,
          truck_number: truckNumber,
          language: store.getState().user.language,
        });

        dispatch({
          type: constants.User.USER_REGISTER_SUCCESS,
        });

        User.setUserLocationByCountry(country);

        eventsPanel.registerOrganization(
          `${firstName} ${lastName}`,
          country,
          email,
          phone,
          truckNumber,
          company,
          response.data.id,
          response.data.owner_id,
          response.data.created_at
        );
      })
      .catch((error) => {
        dispatch({
          type: constants.Organization.ORGANIZATION_REGISTER_ERROR,
          payload: error,
        });
      });
  };
};

export const editOrganization = (projectData: any) => {
  const organizationId = store.getState().organization.organization.id;
  return (dispatch: any) => {
    return put(`/organization/${organizationId}`, projectData, {
      Authorization: `Bearer ${store.getState().user.token}`,
    })
      .then((response) => {
        dispatch({
          type: constants.Organization.ORGANIZATION_EDIT_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: constants.Organization.ORGANIZATION_EDIT_ERROR,
          payload: error,
        });
      });
  };
};

// USERS

export const createOrganizationUser = (
  email: any,
  firstName: any,
  lastName: any,
  role: any,
  projects: any,
  refreshProjects = false
) => {
  const organizationId = store.getState().organization.organization.id;
  const { language } = store.getState().user.user;
  return (dispatch: any) => {
    return post(
      `/organization/${organizationId}/invite`,
      {
        email,
        first_name: firstName,
        last_name: lastName,
        language,
        role,
        projects,
      },
      {
        Authorization: `Bearer ${store.getState().user.token}`,
      }
    )
      .then((response) => {
        dispatch({
          type: constants.Organization.ORGANIZATION_NEW_USER_SUCCESS,
          payload: response.data,
        });

        if (refreshProjects) dispatch(Projects.getProjects());
      })
      .catch((error) => {
        dispatch({
          type: constants.Organization.ORGANIZATION_NEW_USER_ERROR,
          payload: error,
        });
      });
  };
};

export const resendOrganizationUserInvitation = (userId: any) => {
  const organizationId = store.getState().organization.organization.id;
  return (dispatch: any) => {
    return put(
      `/organization/${organizationId}/invite`,
      { user_id: userId },
      {
        Authorization: `Bearer ${store.getState().user.token}`,
      }
    )
      .then((response) => {
        dispatch({
          type: constants.Organization.ORGANIZATION_RESEND_INVITATION_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: constants.Organization.ORGANIZATION_RESEND_INVITATION_ERROR,
          payload: error,
        });
      });
  };
};

export const restoreOrganizationUser = (userId: any) => {
  const organizationId = store.getState().organization.organization.id;
  return (dispatch: any) => {
    return post(
      `/organization/${organizationId}/users/${userId}/restore`,
      {},
      {
        Authorization: `Bearer ${store.getState().user.token}`,
      }
    )
      .then((response) => {
        dispatch({
          type: constants.Organization.ORGANIZATION_RESTORE_USER_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: constants.Organization.ORGANIZATION_RESTORE_USER_ERROR,
          payload: error,
        });
      });
  };
};

export const deleteOrganizationUser = (userId: any) => {
  const organizationId = store.getState().organization.organization.id;
  return (dispatch: any) => {
    return deleteQuery(`/organization/${organizationId}/users/${userId}`, {
      Authorization: `Bearer ${store.getState().user.token}`,
    })
      .then((response) => {
        dispatch({
          type: constants.Organization.ORGANIZATION_DELETE_USER_SUCCESS,
          payload: response.data
            ? { ...response.data, solftDelete: true }
            : { user_id: userId, solftDelete: false },
        });
      })
      .catch((error) => {
        dispatch({
          type: constants.Organization.ORGANIZATION_DELETE_USER_ERROR,
          payload: error,
        });
      });
  };
};

export const editOrganizationUser = (userId: any, role: any) => {
  const organizationId = store.getState().organization.organization.id;
  return (dispatch: any) => {
    return put(
      `/organization/${organizationId}/users/${userId}`,
      { role },
      {
        Authorization: `Bearer ${store.getState().user.token}`,
      }
    )
      .then((response) => {
        dispatch({
          type: constants.Organization.ORGANIZATION_EDIT_USER_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: constants.Organization.ORGANIZATION_EDIT_USER_ERROR,
          payload: error,
        });
      });
  };
};

// WEBHOOKS
export const getWebhooks = (projectId: any) => {
  return (dispatch: any) => {
    const { user } = store.getState();
    const highway = getHighway(user.token);
    highway.webhook
      .getAll(projectId)
      .then((response) => {
        dispatch({
          type: constants.Organization.ORGANIZATION_GET_WEBHOOKS_SUCCESS,
          payload: response,
        });
      })
      .catch((error) => {
        dispatch({
          type: constants.Organization.ORGANIZATION_GET_WEBHOOKS_ERROR,
          payload: error,
        });
      });
  };
};

export const postWebhook = (webhook: any, projectId: any) => {
  return (dispatch: any) => {
    const { user } = store.getState();
    const highway = getHighway(user.token);
    highway.webhook
      .create(webhook, projectId)
      .then((response) => {
        dispatch({
          type: constants.Organization.ORGANIZATION_CREATE_WEBHOOK_SUCCESS,
          payload: response,
        });
        dispatch(getWebhooks(projectId));
      })
      .catch((error) => {
        dispatch({
          type: constants.Organization.ORGANIZATION_CREATE_WEBHOOK_ERROR,
          payload: error,
        });
      });
  };
};

export const updateWebhook = (webhookId: any, webhookData: any) => {
  return (dispatch: any) => {
    const { user } = store.getState();
    const highway = getHighway(user.token);
    highway.webhook
      .update(webhookId, webhookData)
      .then((response) => {
        dispatch({
          type: constants.Organization.ORGANIZATION_EDIT_WEBHOOK_SUCCESS,
          payload: response,
        });
        dispatch(getWebhooks(response.project_id));
      })
      .catch((error) => {
        dispatch({
          type: constants.Organization.ORGANIZATION_EDIT_WEBHOOK_ERROR,
          payload: error,
        });
      });
  };
};

export const testWebhook = (webhookId: any, eventType: any) => {
  return (dispatch: any) => {
    const { user } = store.getState();
    const highway = getHighway(user.token);
    highway.webhook
      .test(webhookId, eventType)
      .then((response) => {
        dispatch({
          type: constants.Organization.ORGANIZATION_TEST_WEBHOOK_SUCCESS,
          payload: response,
        });
      })
      .catch((error) => {
        dispatch({
          type: constants.Organization.ORGANIZATION_TEST_WEBHOOK_ERROR,
          payload: error,
        });
      });
  };
};

export const deleteWebhook = (webhookId: any, projectId: any) => {
  return (dispatch: any) => {
    const { user } = store.getState();
    const highway = getHighway(user.token);
    highway.webhook
      .delete(webhookId)
      .then((response) => {
        dispatch({
          type: constants.Organization.ORGANIZATION_DELETE_WEBHOOK_SUCCESS,
          payload: response,
        });
        dispatch(getWebhooks(projectId));
      })
      .catch((error) => {
        dispatch({
          type: constants.Organization.ORGANIZATION_DELETE_WEBHOOK_ERROR,
          payload: error,
        });
      });
  };
};

export const uploadAvatar = (fileName: any, mimeType: any, file: any) => {
  const formData = new FormData();
  formData.append('name', fileName);
  formData.append('mime', mimeType);
  formData.append('data', file);
  return (dispatch: any) => {
    return post(`/organization/avatar`, formData, {
      'content-type': 'multipart/form-data',
      Authorization: `Bearer ${store.getState().user.token}`,
    })
      .then((response) => {
        dispatch({
          type: constants.Organization.ORGANIZATION_UPLOAD_AVATAR_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: constants.Organization.ORGANIZATION_UPLOAD_AVATAR_ERROR,
          payload: error,
        });
      });
  };
};

export const removeAvatar = () => {
  return (dispatch: any) => {
    return deleteQuery(`/organization/avatar`, {
      Authorization: `Bearer ${store.getState().user.token}`,
    })
      .then((response) => {
        dispatch({
          type: constants.Organization.ORGANIZATION_REMOVE_AVATAR_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: constants.Organization.ORGANIZATION_REMOVE_AVATAR_ERROR,
          payload: error,
        });
      });
  };
};
