import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { IPlanData } from 'highway-api/dist/common/interfaces/plans';
import { IRouteDataExtended } from 'highway-api/dist/common/interfaces/routes';
import { IServiceDataExtended } from 'highway-api/dist/common/interfaces/services';
import React from 'react';
import FormattedMessage from './FormattedMessageCustom';

const CustomTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: 'rgba(5, 13, 30, 0.89)',
    maxWidth: '320px',
  },
}))(Tooltip);

/**
 * @description Validates if service of a route has optimization warnings.
 * @param {*} route
 */
export const routeServicesHasAnyWarnings = (route?: IRouteDataExtended) : boolean => {
  return route?.services ? route.services.some((service: IServiceDataExtended) => hasAnyWarning(service, [route])) : false;
};

/**
 * @description Validates if any service has optimization warnings.
 * @param {*} plan
 */
export const anyServiceHasAnyWarnings = (plan?: IPlanData) : boolean => {
  return plan ? plan.services.some((service: IServiceDataExtended) => hasAnyWarning(service, plan.routes)) : false;
};

/**
 * @description Validates if there are any service optimization warnings.
 * @param {*} service
 * @param {*} planRoutes
 */
const hasAnyWarning = (service: IServiceDataExtended, planRoutes: IRouteDataExtended[]) : boolean => {
  const optimizationWarnings = getOptimizationWarnings(service, planRoutes);
  const optimizationArray = Object.keys(optimizationWarnings).map((key: string) => optimizationWarnings[key]);
  return optimizationArray.some(((item: boolean) => item === true));
};

/**
 * @description Validates if there are any service optimization warnings.
 * @param {*} service
 * @param {*} planRoutes
 */
const getOptimizationWarnings = (service: IServiceDataExtended, planRoutes: IRouteDataExtended[]) : { [key: string]: any } => {
  return {
    short_timewindows: isInvalidDuration(service),
    weight_exceeds: isInvalidWeight(service, planRoutes),
    volume_exceeds: isInvalidVolume(service, planRoutes),
    no_timewidows_match: isInvalidServiceTimewindows(service, planRoutes),
    no_timewidows_and_duration_match: isInvalidServiceTimewindowsAndDuration(service, planRoutes),
    pickup_after_delivery: isInvalidPickup(service),
    no_requires_match: isInvalidRequires(service, planRoutes),
  };
};

/**
 * @description Validates if the service timewindows are shorter than the duration.
 * @param {*} service
 */
const isInvalidDuration = (service: IServiceDataExtended) : boolean => {
  if (Boolean(service?.timewindows?.length) && Boolean(service?.duration)) {
    return !service?.timewindows?.some((timewindow: any) =>
      !service.duration || timewindow[1] - timewindow[0] > service.duration
    );
  }
  return false;
};

/**
 * @description Validates if there are vehicles with weight capacity that fits the service weight.
 * @param {*} service
 * @param {*} planRoutes
 */
const isInvalidWeight = (service: IServiceDataExtended, planRoutes: IRouteDataExtended[]) : boolean => {

  if(!service.weight) {
    return false;
  }

  if(planRoutes.length === 0) {
    return false;
  }

  // Checking if any route satisfies the service weight restriction.
  const anyValid = planRoutes.some((route: any) => {
    if(!route.max_weight) return false;
    if(service.weight) {
      return route.max_weight >= service.weight;
    }
    return true;
  });
  return !anyValid;
};

/**
 * @description Validates if there are vehicles with volume capacity that fits the service volume.
 * @param {*} service
 * @param {*} planRoutes
 */
const isInvalidVolume = (service: IServiceDataExtended, planRoutes: IRouteDataExtended[]) : boolean => {

  if(!service.volume) {
    return false;
  }

  if(planRoutes.length === 0) {
    return false;
  }

  // Checking if any route satisfies the service volume restriction.
  const anyValid = planRoutes.some((route: any) => {
    if(!route.max_volume) return false;
    if(service.volume) {
      return route.max_volume >= service.volume;
    }
    return true;
  });
  return !anyValid;
};

/**
 * @description Validates if there are vehicles with timewindows that match the service timewindows.
 * @param {*} service
 * @param {*} planRoutes
 */
const isInvalidServiceTimewindows = (service: IServiceDataExtended, planRoutes: IRouteDataExtended[]) : boolean => {
  if(planRoutes.length === 0) {
    return false;
  }

  if (service.timewindows?.length === 0) return false;
  return !planRoutes.some((route: IRouteDataExtended) => {      
    if (!route.timewindow) return true;
    return service.timewindows && service.timewindows.some((serviceTimewindow: any) => {
      return route.timewindow !== undefined &&
        ((serviceTimewindow[1] >= route.timewindow[0] && serviceTimewindow[1] <= route.timewindow[1] ||
        serviceTimewindow[0] >= route.timewindow[0] && serviceTimewindow[0] <= route.timewindow[1]));
    });
  });
};

/**
 * @description Validates if there are vehicles with timewindows that match the service timewindows and has enought time due the service duration.
 * @param {*} service
 * @param {*} planRoutes
 */
const isInvalidServiceTimewindowsAndDuration = (service: IServiceDataExtended, planRoutes: IRouteDataExtended[]) : boolean => {
  if(planRoutes.length === 0) {
    return false;
  }

  if (service.timewindows?.length === 0) return false;
  return !planRoutes.some((route: IRouteDataExtended) => {
    if (!route.timewindow) return true;
    return service.timewindows && service.timewindows.some((serviceTimewindow: any) => {
      if(route.timewindow !== undefined && service.duration !== undefined) {
        const startOverlap = serviceTimewindow[0] <= route.timewindow[0] ? route.timewindow[0] : serviceTimewindow[0];
        const endOverlap = serviceTimewindow[1] <= route.timewindow[1] ? serviceTimewindow[1] : route.timewindow[1];
        return service.duration <= (endOverlap - startOverlap);
      }
      return true;
    });
  });
};

/**
 * @description Validates if the service pickup is after the service delivery.
 * Invalid if there is not any pickup timewindow earlier or equal to any delivery timewindow.
 * @param {*} service
 */
const isInvalidPickup = (service: IServiceDataExtended) : boolean => {
  if(!service.pickup || !service.pickup.timewindows || service.pickup.timewindows.length === 0) {
    return false;
  }

  const isValid = service.pickup.timewindows.some((pickupTimewindow: any) => {
    if(!service.timewindows || service.timewindows.length === 0) {
      return true;
    }

    return service.timewindows.some((deliveryTimewindow: any) => {
      return (pickupTimewindow[0] < deliveryTimewindow[1]);
    });
  });

  return !isValid;
};

/**
 * @description Validates if there are vehicles with provides that match the service requires.
 * @param {*} service
 * @param {*} planRoutes
 */
const isInvalidRequires = (service: IServiceDataExtended, planRoutes: IRouteDataExtended[]) : boolean => {
  if(planRoutes.length === 0) {
    return false;
  }
  
  return service.requires !== undefined && !planRoutes.some((route: IRouteDataExtended) => {
    if(!route.provides) return false;
    return !service?.requires?.some((r: any) => !route?.provides?.includes(r));
  });
};

type OptimizationWarningsTooltipProps = {
  service?: IServiceDataExtended;
  planRoutes: IRouteDataExtended[];
  children: React.ReactElement;
};

const OptimizationWarningsTooltip = ({
  service,
  planRoutes,
  children,
}: OptimizationWarningsTooltipProps) => {

  if(!service || !hasAnyWarning(service, planRoutes)) return null;

  const optimizationWarnings = getOptimizationWarnings(service, planRoutes);

  return (
    <CustomTooltip
      title={
        <div style={{ margin: '10px', fontSize: '14px', lineHeight: '1.6em' }}>
          <FormattedMessage tagName="p" id="service.optimizationwarnings.info" />

          {Object.keys(optimizationWarnings).map((constraintKey: string) => {
            if (optimizationWarnings[constraintKey]) {
              return (
                <p style={{ marginLeft: '8px' }} key={constraintKey}>
                  <span>- </span>
                  <FormattedMessage tagName="span" id={`service.optimizationwarnings.${constraintKey}`} />
                </p>
              );
            }
            return null;
          })}
        </div>
      }
    >
      {children}
    </CustomTooltip>
  );
};

export default OptimizationWarningsTooltip;
