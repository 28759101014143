export const ORGANIZATION_REGISTER = 'ORGANIZATION_REGISTER';
export const ORGANIZATION_REGISTER_SUCCESS = 'ORGANIZATION_REGISTER_SUCCESS';
export const ORGANIZATION_REGISTER_ERROR = 'ORGANIZATION_REGISTER_ERROR';

export const ORGANIZATION_DETAILS = 'ORGANIZATION_DETAILS';
export const ORGANIZATION_DETAILS_SUCCESS = 'ORGANIZATION_DETAILS_SUCCESS';
export const ORGANIZATION_DETAILS_ERROR = 'ORGANIZATION_DETAILS_ERROR';

export const ORGANIZATION_EDIT = 'ORGANIZATION_EDIT';
export const ORGANIZATION_EDIT_SUCCESS = 'ORGANIZATION_EDIT_SUCCESS';
export const ORGANIZATION_EDIT_ERROR = 'ORGANIZATION_EDIT_ERROR';

export const ORGANIZATION_NEW_USER = 'ORGANIZATION_NEW_USER';
export const ORGANIZATION_NEW_USER_SUCCESS = 'ORGANIZATION_NEW_USER_SUCCESS';
export const ORGANIZATION_NEW_USER_ERROR = 'ORGANIZATION_NEW_USER_ERROR';

export const ORGANIZATION_EDIT_USER = 'ORGANIZATION_EDIT_USER';
export const ORGANIZATION_EDIT_USER_SUCCESS = 'ORGANIZATION_EDIT_USER_SUCCESS';
export const ORGANIZATION_EDIT_USER_ERROR = 'ORGANIZATION_EDIT_USER_ERROR';

export const ORGANIZATION_DELETE_USER = 'ORGANIZATION_DELETE_USER';
export const ORGANIZATION_DELETE_USER_SUCCESS = 'ORGANIZATION_DELETE_USER_SUCCESS';
export const ORGANIZATION_DELETE_USER_ERROR = 'ORGANIZATION_DELETE_USER_ERROR';

export const ORGANIZATION_RESTORE_USER = 'ORGANIZATION_RESTORE_USER';
export const ORGANIZATION_RESTORE_USER_SUCCESS = 'ORGANIZATION_RESTORE_USER_SUCCESS';
export const ORGANIZATION_RESTORE_USER_ERROR = 'ORGANIZATION_RESTORE_USER_ERROR';

export const ORGANIZATION_RESEND_INVITATION = 'ORGANIZATION_RESEND_INVITATION';
export const ORGANIZATION_RESEND_INVITATION_SUCCESS = 'ORGANIZATION_RESEND_INVITATION_SUCCESS';
export const ORGANIZATION_RESEND_INVITATION_ERROR = 'ORGANIZATION_RESEND_INVITATION_ERROR';

export const ORGANIZATION_GET_WEBHOOKS = 'ORGANIZATION_GET_WEBHOOKS';
export const ORGANIZATION_GET_WEBHOOKS_SUCCESS = 'ORGANIZATION_GET_WEBHOOKS_SUCCESS';
export const ORGANIZATION_GET_WEBHOOKS_ERROR = 'ORGANIZATION_GET_WEBHOOKS_ERROR';

export const ORGANIZATION_CREATE_WEBHOOK = 'ORGANIZATION_CREATE_WEBHOOK';
export const ORGANIZATION_CREATE_WEBHOOK_ERROR = 'ORGANIZATION_CREATE_WEBHOOK_SUCCESS';
export const ORGANIZATION_CREATE_WEBHOOK_SUCCESS = 'ORGANIZATION_CREATE_WEBHOOK_ERROR';

export const ORGANIZATION_EDIT_WEBHOOK = 'ORGANIZATION_EDIT_WEBHOOK';
export const ORGANIZATION_EDIT_WEBHOOK_SUCCESS = 'ORGANIZATION_EDIT_WEBHOOK_SUCCESS';
export const ORGANIZATION_EDIT_WEBHOOK_ERROR = 'ORGANIZATION_EDIT_WEBHOOK_ERROR';

export const ORGANIZATION_TEST_WEBHOOK = 'ORGANIZATION_TEST_WEBHOOK';
export const ORGANIZATION_TEST_WEBHOOK_SUCCESS = 'ORGANIZATION_TEST_WEBHOOK_SUCCESS';
export const ORGANIZATION_TEST_WEBHOOK_ERROR = 'ORGANIZATION_TEST_WEBHOOK_ERROR';

export const ORGANIZATION_DELETE_WEBHOOK = 'ORGANIZATION_DELETE_WEBHOOK';
export const ORGANIZATION_DELETE_WEBHOOK_SUCCESS = 'ORGANIZATION_DELETE_WEBHOOK_SUCCESS';
export const ORGANIZATION_DELETE_WEBHOOK_ERROR = 'ORGANIZATION_DELETE_WEBHOOK_ERROR';

export const ORGANIZATION_UPLOAD_AVATAR_SUCCESS = 'ORGANIZATION_UPLOAD_AVATAR_SUCCESS';
export const ORGANIZATION_UPLOAD_AVATAR_ERROR = 'ORGANIZATION_UPLOAD_AVATAR_ERROR';

export const ORGANIZATION_REMOVE_AVATAR_SUCCESS = 'ORGANIZATION_REMOVE_AVATAR_SUCCESS';
export const ORGANIZATION_REMOVE_AVATAR_ERROR = 'ORGANIZATION_REMOVE_AVATAR_ERROR';
