import React from 'react';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';
import App from './App';
import './index.css';
import Froged from './services/froged';
import * as serviceWorker from './serviceWorker';

// Note: this doesn't exist anymore, nose xq servia.
// addLocaleData([...localeEn, ...localeEs]);

ReactDOM.render(<App />, document.getElementById('root'));
Froged.boot();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
