import { Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Button, Pill, VSpacer } from '@sm-highway-web/react-components';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { IOrganizationUsers } from '../../../interfaces/organizations';
import { Organization } from '../../../redux/actions';
import { organizationHelpers, userHelpers } from '../../../redux/helpers';
import Avatar from '../../Avatar';
import CustomSelect from '../../CustomSelect';
import FormattedMessage from '../../FormattedMessageCustom';
import OrganizationNewUserDialog from './OrganizationNewUserDialog';

const styles = {
  listUsers: {
    flexWrap: 'unset',
  },
  listUsersItem: {
    marginBottom: '20px',
    marginRight: '8px',
  },
};

type OwnOrganizationUsersProps = {
  classes: {
    listUsers: string;
    listUsersItem: string;
  };
  actions: {
    deleteOrganizationUser: (...args: any[]) => any;
    editOrganizationUser: (...args: any[]) => any;
    resendOrganizationUserInvitation: (...args: any[]) => any;
  };
  organizationUsers?: IOrganizationUsers[];
  subscriptionPlanType: string;
};

type OrganizationUsersProps = OwnOrganizationUsersProps;

const OrganizationUsers = ({
  classes,
  actions,
  organizationUsers,
  subscriptionPlanType,
}: OrganizationUsersProps) => {
  const [resendInvitationLoading, setResendInvitationLoading] = useState('');
  const [newUserDialogOpen, setNewUserDialogOpen] = useState(false);

  if (!organizationUsers) {
    return null;
  }

  return (
    <>
      <Grid container direction="column">
        <Grid item container style={{ padding: '8px', borderBottom: '1px solid #D7DAE2' }}>
          <Grid item container direction="row" justify="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h2">
                <FormattedMessage id="settings.organization.users.title" />
              </Typography>
            </Grid>

            {subscriptionPlanType !== 'monkey' &&
              (userHelpers.currentUser.isOrganizationAdmin() ||
                userHelpers.currentUser.isOrganizationManager()) && (
              <Grid item>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => setNewUserDialogOpen(true)}
                >
                  <FormattedMessage id="settings.organization.users.buttons.create" />
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>

        <VSpacer large />

        <Grid item>
          <Grid container direction="column" className={classes.listUsers}>
            {organizationUsers.map((user: any) => (
              <Grid item key={user.user_id} className={classes.listUsersItem}>
                <Pill
                  avatar={<Avatar data={user} labelOverlayed={false} size={50} />}
                  info={
                    <Grid container direction="column">
                      <Grid item>
                        <Typography
                          variant="h3"
                          style={{
                            fontSize: '22px',
                            ...(user.deleted ? { textDecoration: 'line-through' } : {}),
                            ...(!user.validated ? { color: '#76727B' } : {}),
                          }}
                        >
                          {`${user.first_name} ${user.last_name}`}
                          {user.validated &&
                            userHelpers.otherUser.isOrganizationOwner(user.user_id) && (
                            <span style={{ fontSize: '16px' }}>
                              {' '}
                                (
                              <FormattedMessage
                                tagName="span"
                                id="settings.organization.users.owner"
                              />
                                )
                            </span>
                          )}
                          {!user.validated && !user.deleted && (
                            <span style={{ fontSize: '16px' }}>
                              {' '}
                              (
                              <FormattedMessage tagName="span" id="settings.organization.users.pending" />
)
                            </span>
                          )}
                          {user.validated &&
                            !userHelpers.otherUser.isOrganizationOwner(user.user_id) &&
                            userHelpers.otherUser.isSameAsLogged(user.user_id) && (
                            <span style={{ fontSize: '16px' }}>
                              {' '}
                                (
                              <FormattedMessage tagName="span" id="settings.organization.users.myself" />
)
                            </span>
                          )}
                        </Typography>
                      </Grid>
                      <Grid item>
                        <Typography
                          variant="h5"
                          style={{
                            fontSize: '16px',
                            ...(!user.validated ? { color: '#76727B' } : {}),
                          }}
                        >
                          {user.email}
                        </Typography>
                      </Grid>
                    </Grid>
                  }
                  actions={[
                    <CustomSelect
                      width={250}
                      value={user.role}
                      disabled={
                        user.deleted ||
                        userHelpers.otherUser.isOrganizationOwner(user.user_id) ||
                        userHelpers.otherUser.isSameAsLogged(user.user_id) ||
                        subscriptionPlanType === 'monkey' ||
                        (!userHelpers.currentUser.isOrganizationAdmin() &&
                          !userHelpers.currentUser.isOrganizationManager())
                      }
                      selectItems={[
                        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ value: string; title: JSX.Element; info: J... Remove this comment to see the full error message
                        {
                          value: organizationHelpers.OrganizationRoles.organization_administrator,
                          title: (
                            <FormattedMessage id="settings.organization.users.roles.administrator" />
                          ),
                          info: (
                            <FormattedMessage id="settings.organization.users.roles.administrator.description" />
                          ),
                        },
                        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ value: string; title: JSX.Element; info: J... Remove this comment to see the full error message
                        {
                          value: organizationHelpers.OrganizationRoles.organization_manager,
                          title: (
                            <FormattedMessage id="settings.organization.users.roles.manager" />
                          ),
                          info: (
                            <FormattedMessage id="settings.organization.users.roles.manager.description" />
                          ),
                        },
                        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ value: string; title: JSX.Element; info: J... Remove this comment to see the full error message
                        {
                          value: organizationHelpers.OrganizationRoles.organization_user,
                          title: <FormattedMessage id="settings.organization.users.roles.user" />,
                          info: (
                            <FormattedMessage id="settings.organization.users.roles.user.description" />
                          ),
                        },
                      ]}
                      onChange={(value: any) => {
                        actions.editOrganizationUser(user.user_id, value);
                      }}
                    />,

                    ...(subscriptionPlanType !== 'monkey' &&
                    (userHelpers.currentUser.isOrganizationAdmin() ||
                      userHelpers.currentUser.isOrganizationManager()) &&
                    !user.validated &&
                    !user.deleted
                      ? [
                          <Button
                            disabled={resendInvitationLoading === user.user_id}
                            color="primary"
                            variant="contained"
                            onClick={() => {
                              setResendInvitationLoading(user.user_id);
                              actions.resendOrganizationUserInvitation(user.user_id);
                              setTimeout(() => setResendInvitationLoading(''), 1500);
                            }}
                          >
                            <FormattedMessage id="settings.organization.users.buttons.resend" />
                          </Button>,
                        ]
                      : []),

                    ...(subscriptionPlanType !== 'monkey' &&
                    (userHelpers.currentUser.isOrganizationAdmin() ||
                      userHelpers.currentUser.isOrganizationManager())
                      ? [
                          ...(user.deleted
                            ? [
                                <Button
                                  style={{ backgroundColor: '#A09C9C' }}
                                  color="secondary"
                                  variant="contained"
                                  // @ts-expect-error ts-migrate(2551) FIXME: Property 'restoreOrganizationUser' does not exist ... Remove this comment to see the full error message
                                  onClick={() => actions.restoreOrganizationUser(user.user_id)}
                                >
                                  <FormattedMessage id="settings.organization.users.buttons.restore" />
                                </Button>,
                              ]
                            : !userHelpers.otherUser.isOrganizationOwner(user.user_id) &&
                              !userHelpers.otherUser.isSameAsLogged(user.user_id)
                                ? [
                                    <Button
                                      style={{ backgroundColor: '#FF2825' }}
                                      color="secondary"
                                      variant="contained"
                                      onClick={() => actions.deleteOrganizationUser(user.user_id)}
                                    >
                                      <FormattedMessage id="settings.organization.users.buttons.delete" />
                                    </Button>,
                                  ]
                                : []),
                        ]
                      : []),
                  ]}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
        <VSpacer medium />
      </Grid>

      <OrganizationNewUserDialog
        isOpen={newUserDialogOpen}
        refreshProjects
        onClose={() => setNewUserDialogOpen(false)}
      />
    </>
  );
};

OrganizationUsers.defaultProps = {
  organizationUsers: undefined,
};

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    deleteOrganizationUser: (userId: any) => dispatch(Organization.deleteOrganizationUser(userId)),
    editOrganizationUser: (userId: any, role: any) =>
      dispatch(Organization.editOrganizationUser(userId, role)),
    resendOrganizationUserInvitation: (userId: any) =>
      dispatch(Organization.resendOrganizationUserInvitation(userId)),
    restoreOrganizationUser: (userId: any) =>
      dispatch(Organization.restoreOrganizationUser(userId)),
  },
});

const mapStateToProps = (state: any) => {
  return {
    organizationUsers: organizationHelpers.orderUsers(state.organization.users),
    subscriptionPlanType:
      state.organization.organization &&
      state.organization.organization.subscription &&
      state.organization.organization.subscription.plan &&
      state.organization.organization.subscription.plan.type
        ? state.organization.organization.subscription.plan.type
        : 'monkey',
  };
};

// @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ listUsers: { flexWrap: string;... Remove this comment to see the full error message
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(OrganizationUsers));
