import getHighway from '../../services/highway';
import constants from '../constants';
import { projectHelpers } from '../helpers';
import store from '../store/index';

export const getFlatVehicles = () => {
  return (dispatch: any) => {
    const highway = getHighway(store.getState().user.token);
    return highway.vehicle
      .listFlat(projectHelpers.getCurrentProjectId())
      .then((response) => {
        dispatch({
          type: constants.Vehicles.VEHICLES_GET_FLAT_VEHICLES_SUCCESS,
          payload: response,
        });
      })
      .catch((error) => {
        dispatch({
          type: constants.Vehicles.VEHICLES_GET_FLAT_VEHICLES_ERROR,
          payload: error,
        });
      });
  };
};

export const getVehicles = (offset: any, limit: any, search: any) => {
  return (dispatch: any) => {
    const highway = getHighway(store.getState().user.token);
    return highway.vehicle
      .list(projectHelpers.getCurrentProjectId(), offset, limit, search)
      .then((response) => {
        dispatch({
          type: constants.Vehicles.VEHICLES_GET_VEHICLES_SUCCESS,
          payload: response,
        });
      })
      .catch((error) => {
        dispatch({
          type: constants.Vehicles.VEHICLES_GET_VEHICLES_ERROR,
          payload: error,
        });
      });
  };
};

export const postVehicle = (vehicle: any) => {
  return (dispatch: any) => {
    const highway = getHighway(store.getState().user.token);
    highway.vehicle
      .create(vehicle, projectHelpers.getCurrentProjectId())
      .then((response) => {
        const { offset, limit } = store.getState().vehicles.vehicles;
        dispatch(getFlatVehicles()); // Es pot fer sense cridar backend
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 3 arguments, but got 2.
        dispatch(getVehicles(offset, limit));
        dispatch({
          type: constants.Vehicles.VEHICLES_CREATE_VEHICLE_SUCCESS,
          payload: response,
        });
      })
      .catch((error) => {
        dispatch({
          type: constants.Vehicles.VEHICLES_CREATE_VEHICLE_ERROR,
          payload: error,
        });
      });
  };
};
export const createVehicles = (vehicles: any) => {
  return (dispatch: any) => {
    const highway = getHighway(store.getState().user.token);
    highway.vehicle
      .createMany(vehicles, projectHelpers.getCurrentProjectId())
      .then((response) => {
        const { offset, limit } = store.getState().vehicles.vehicles;
        dispatch(getFlatVehicles()); // Es pot fer sense cridar backend
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 3 arguments, but got 2.
        dispatch(getVehicles(offset, limit));
        dispatch({
          type: constants.Vehicles.VEHICLES_CREATE_VEHICLES_SUCCESS,
          payload: response,
        });
      })
      .catch((error) => {
        dispatch({
          type: constants.Vehicles.VEHICLES_CREATE_VEHICLES_ERROR,
          payload: error,
        });
      });
  };
};

export const putVehicle = (vehicleId: any, data: any) => {
  return (dispatch: any) => {
    const highway = getHighway(store.getState().user.token);
    highway.vehicle
      .update(vehicleId, data)
      .then((response) => {
        const { offset, limit } = store.getState().vehicles.vehicles;
        dispatch(getFlatVehicles());
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 3 arguments, but got 2.
        dispatch(getVehicles(offset, limit));
        dispatch({
          type: constants.Vehicles.VEHICLES_UPDATE_VEHICLE_SUCCESS,
          payload: response,
        });
      })
      .catch((error) => {
        dispatch({
          type: constants.Vehicles.VEHICLES_UPDATE_VEHICLE_ERROR,
          payload: error,
        });
      });
  };
};

export const putVehicles = (vehicles: any, data: any) => {
  return (dispatch: any) => {
    const highway = getHighway(store.getState().user.token);
    Promise.all(vehicles.map((vehicleId: any) => highway.vehicle.update(vehicleId, data)))
      .then((response: any) => {
        const { offset, limit } = store.getState().vehicles.vehicles;
        dispatch(getFlatVehicles());
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 3 arguments, but got 2.
        dispatch(getVehicles(offset, limit));
        dispatch({
          type: constants.Vehicles.VEHICLES_UPDATE_VEHICLE_SUCCESS,
          payload: response,
        });
      })
      .catch((error: any) => {
        dispatch({
          type: constants.Vehicles.VEHICLES_UPDATE_VEHICLE_ERROR,
          payload: error,
        });
      });
  };
};

export const deleteVehicle = (vehicleId: any) => {
  return (dispatch: any) => {
    const highway = getHighway(store.getState().user.token);
    highway.vehicle
      .delete(vehicleId)
      .then((response) => {
        const { offset, limit } = store.getState().vehicles.vehicles;
        dispatch(getFlatVehicles());
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 3 arguments, but got 2.
        dispatch(getVehicles(offset, limit));
        dispatch(removeVehicleSelection());
        dispatch({
          type: constants.Vehicles.VEHICLES_DELETE_VEHICLE_SUCCESS,
          payload: response,
        });
      })
      .catch((error) => {
        dispatch({
          type: constants.Vehicles.VEHICLES_DELETE_VEHICLE_ERROR,
          payload: error,
        });
      });
  };
};

export const deleteVehicles = (vehicles: any) => {
  return (dispatch: any) => {
    const highway = getHighway(store.getState().user.token);
    Promise.all(vehicles.map((vehicleId: any) => highway.vehicle.delete(vehicleId))).then(
      (allResponses: any) => {
        const { offset, limit } = store.getState().vehicles.vehicles;
        dispatch(getFlatVehicles());
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 3 arguments, but got 2.
        dispatch(getVehicles(offset, limit));
        dispatch(removeVehicleSelection());
        dispatch({
          type: constants.Vehicles.VEHICLES_DELETE_VEHICLE_SUCCESS,
          payload: allResponses,
        });
      }
    );
  };
};

export const setSelectedVehicles = (vehicles: any) => {
  return (dispatch: any) => {
    dispatch({
      type: constants.Vehicles.VEHICLES_SET_SELECTED_VEHICLES,
      payload: vehicles,
    });
  };
};

export const removeVehicleSelection = () => {
  return (dispatch: any) => {
    dispatch({
      type: constants.Vehicles.VEHICLES_REMOVE_SELECTED,
    });
  };
};

export const selectVehicle = (id: any) => {
  return (dispatch: any) => {
    const highway = getHighway(store.getState().user.token);
    highway.vehicle
      .get(id)
      .then((response) => {
        dispatch({
          type: constants.Vehicles.VEHICLES_GET_VEHICLE_SUCCESS,
          payload: response,
        });
      })
      .catch((error) => {
        dispatch({
          type: constants.Vehicles.VEHICLES_GET_VEHICLE_ERROR,
          payload: error,
        });
      });
  };
};
