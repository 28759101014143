import { Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React, { PureComponent } from 'react';
import CapacityLoadIcon from '../assets/svg/CapacityLoadIcon';

const styles = {
  divColored: {
    width: '100%',
    position: 'absolute',
    bottom: '0px',
  },
  divInside: {
    position: 'absolute',
    width: '17px',
    height: '20px',
  },
  divOutside: {
    width: '17px',
    height: '24px',
  },
};

type OwnProps = {
  classes: {
    divColored: string;
    divOutside: string;
    divInside: string;
  };
  capacity?: number | string;
};

type Props = OwnProps;

class LoadIndicator extends PureComponent<Props> {
  static defaultProps = {
    capacity: 0,
  };

  selectColor = (capacity: any) => {
    if (capacity > 100) {
      return '#D0021B';
    }
    if (capacity > 90) {
      return '#179507';
    }
    if (capacity > 20) {
      return '#83EE76';
    }
    return '#E6EAA0';
  };

  render = () => {
    const { classes, capacity } = this.props;

    return (
      <Grid container alignItems="center">
        <Grid item md={5}>
          <div className={classes.divOutside}>
            <div className={classes.divInside}>
              <div
                className={classes.divColored}
                style={{
                  backgroundColor: this.selectColor(capacity),
                  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | number | undefined' is ... Remove this comment to see the full error message
                  height: isNaN(capacity) ? '0%' : `${capacity > 100 ? 100 : capacity}%`,
                }}
              />
            </div>
            <div style={{ position: 'absolute' }}>
              <CapacityLoadIcon />
            </div>
          </div>
        </Grid>
        <Grid item md={7}>
          {/* eslint-disable-next-line */}
          {/* @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'. */}
          <Typography style={{ color: (capacity > 100) ? '#D0021B' : '' }}>{`${isNaN(capacity) ? 0 : capacity}%`}</Typography>
        </Grid>
      </Grid>
    );
  };
}

// @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ divColored: { width: string; p... Remove this comment to see the full error message
export default withStyles(styles)(LoadIndicator);
