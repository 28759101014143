import { Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import {
  Button,
  Dialog,
  FormCheckbox,
  HSpacer,
  InputText,
  VSpacer,
} from '@sm-highway-web/react-components';
import FormattedMessage from '../components/FormattedMessageCustom';
import { Payment } from '../redux/actions';

const styles = {};

type OwnSubscriptionCancelDialogProps = {
  classes: any;
  actions: {
    cancelSubscription: (...args: any[]) => any;
  };
  open: boolean;
  handleClose: (...args: any[]) => any;
};

type SubscriptionCancelDialogState = any;

type SubscriptionCancelDialogProps = OwnSubscriptionCancelDialogProps;

class SubscriptionCancelDialog extends PureComponent<
  SubscriptionCancelDialogProps,
  SubscriptionCancelDialogState
> {
  static defaultProps = {};

  state = {
    confirmation: false,
    confirmationPassword: '',
    inputs: {
      trial_was_enought: false,
      other_solution_chosen: false,
      difficult_to_use: false,
      technical_difficulties: false,
      price_too_high: false,
      expectations_not_fulfilled: false,
      other: '',
    },
  };

  handleChangeConfirmationPass = (value: any) => {
    this.setState({
      confirmationPassword: value,
    });
  };

  handleChangeFase = () => {
    const { confirmationPassword } = this.state;
    this.setState({
      confirmation: confirmationPassword.toLowerCase() === 'cancel',
    });
  };

  handleOtherReason = (value: any) => {
    this.setState((prevState: any) => ({
      inputs: { ...prevState.inputs, other: value },
    }));
  };

  handleInputsReason = (field: any) => (value: any) => {
    this.setState((prevState: any) => ({
      inputs: { ...prevState.inputs, [field]: value },
    }));
  };

  handlecancelSubscription = () => {
    const { inputs } = this.state;
    this.props.actions.cancelSubscription(inputs);
    this.closeDialog();
  };

  closeDialog = () => {
    this.props.handleClose();
    this.setState({
      confirmation: false,
      confirmationPassword: '',
      inputs: {
        trial_was_enought: false,
        other_solution_chosen: false,
        difficult_to_use: false,
        technical_difficulties: false,
        price_too_high: false,
        expectations_not_fulfilled: false,
        other: '',
      },
    });
  };

  render() {
    const { open } = this.props;
    const { confirmation, confirmationPassword, inputs } = this.state;
    return (
      <Dialog
        modal
        onClose={this.closeDialog}
        open={open}
        title={
          confirmation ? (
            <FormattedMessage id="settings.billing.subscriptions.canceldialog.title.step2" />
          ) : (
            <FormattedMessage id="settings.billing.subscriptions.canceldialog.title.step1" />
          )
        }
      >
        {confirmation ? (
          <>
            <Grid container direction="column">
              <Typography align="center">
                <FormattedMessage id="settings.billing.subscriptions.canceldialog.subtitle.step2" />
              </Typography>
              <VSpacer />
              <Grid container>
                <Grid item sm={6}>
                  <FormCheckbox
                    checked={inputs.trial_was_enought}
                    onChange={this.handleInputsReason('trial_was_enought')}
                    labelComponent={
                      <Typography>
                        <FormattedMessage id="settings.billing.subscriptions.canceldialog.reasons.trial_was_enought" />
                      </Typography>
                    }
                  />
                </Grid>
                <Grid item sm={6}>
                  <FormCheckbox
                    checked={inputs.technical_difficulties}
                    onChange={this.handleInputsReason('technical_difficulties')}
                    labelComponent={
                      <Typography>
                        <FormattedMessage id="settings.billing.subscriptions.canceldialog.reasons.technical_difficulties" />
                      </Typography>
                    }
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item sm={6}>
                  <FormCheckbox
                    checked={inputs.other_solution_chosen}
                    onChange={this.handleInputsReason('other_solution_chosen')}
                    labelComponent={
                      <Typography>
                        <FormattedMessage id="settings.billing.subscriptions.canceldialog.reasons.other_solution_chosen" />
                      </Typography>
                    }
                  />
                </Grid>
                <Grid item sm={6}>
                  <FormCheckbox
                    checked={inputs.price_too_high}
                    onChange={this.handleInputsReason('price_too_high')}
                    labelComponent={
                      <Typography>
                        <FormattedMessage id="settings.billing.subscriptions.canceldialog.reasons.price_too_high" />
                      </Typography>
                    }
                  />
                </Grid>
              </Grid>
              <Grid container>
                <Grid item sm={6}>
                  <FormCheckbox
                    checked={inputs.difficult_to_use}
                    onChange={this.handleInputsReason('difficult_to_use')}
                    labelComponent={
                      <Typography>
                        <FormattedMessage id="settings.billing.subscriptions.canceldialog.reasons.difficult_to_use" />
                      </Typography>
                    }
                  />
                </Grid>
                <Grid item sm={6}>
                  <FormCheckbox
                    checked={inputs.expectations_not_fulfilled}
                    onChange={this.handleInputsReason('expectations_not_fulfilled')}
                    labelComponent={
                      <Typography>
                        <FormattedMessage id="settings.billing.subscriptions.canceldialog.reasons.expectations_not_fulfilled" />
                      </Typography>
                    }
                  />
                </Grid>
              </Grid>
              <Grid container>
                <InputText
                  id="other"
                  label={
                    <FormattedMessage id="settings.billing.subscriptions.canceldialog.reasons.other" />
                  }
                  value={inputs.other}
                  onChange={this.handleOtherReason}
                  margin="none"
                  fullWidth
                />
              </Grid>
              <VSpacer medium />
              <Grid container justify="flex-end">
                <Button onClick={this.closeDialog} color="secondary" variant="text">
                  <FormattedMessage id="settings.billing.subscriptions.canceldialog.buttoncancel.step2" />
                </Button>
                <HSpacer small />
                <Button onClick={this.handlecancelSubscription}>
                  <FormattedMessage id="settings.billing.subscriptions.canceldialog.buttonok.step2" />
                </Button>
              </Grid>
            </Grid>
            <VSpacer />
          </>
        ) : (
          <>
            <Grid container direction="column">
              <Typography align="center">
                <FormattedMessage id="settings.billing.subscriptions.canceldialog.subtitle.step1" />
              </Typography>
            </Grid>
            <VSpacer medium />
            <InputText
              id="confirmation"
              label={
                <FormattedMessage id="settings.billing.subscriptions.canceldialog.placeholder" />
              }
              value={confirmationPassword}
              onChange={this.handleChangeConfirmationPass}
              margin="none"
              fullWidth
            />
            <VSpacer />
            <Grid container justify="flex-end">
              <Button color="secondary" variant="text" onClick={this.closeDialog}>
                <FormattedMessage id="settings.billing.subscriptions.canceldialog.buttoncancel.step1" />
              </Button>
              <HSpacer small />
              <Button onClick={this.handleChangeFase}>
                <FormattedMessage id="settings.billing.subscriptions.canceldialog.buttonok.step1" />
              </Button>
            </Grid>
            <VSpacer />
          </>
        )}
      </Dialog>
    );
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    cancelSubscription: (reasons: any) => dispatch(Payment.cancelSubscriptionPlan(reasons)),
  },
});

export default connect(null, mapDispatchToProps)(withStyles(styles)(SubscriptionCancelDialog));
