import { Grid, Typography, withStyles } from '@material-ui/core';
import { IPlanData } from 'highway-api/dist/common/interfaces/plans';
import { IProjectBase } from 'highway-api/dist/common/interfaces/projects';
import { IRouteDataExtended } from 'highway-api/dist/common/interfaces/routes';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { styles } from '../../../../assets/css/PlanRouteMapCss';
import ServiceIcon from '../../../../assets/svg/ServiceIcon';
import { kmToSM } from '../../../../helpers/Units';
import { ActivePlan, Routes } from '../../../../redux/actions';
import FormattedMessage from '../../../FormattedMessageCustom';
import LoadIndicator from '../../../LoadIndicator';
import RouteMenu from '../../../menus/RouteMenu';

type OwnVehicleInfoSectionProps = {
  classes: {
    disableButton: string;
    smallIcon: string;
    vehicleInfoTitle: string;
    allHeight: string;
  };
  routes: {
    id: string;
    'max_distance'?: number;
    'max_weight'?: number;
    'max_volume'?: number;
    services: {}[];
    distance?: number;
  }[];
  missing: {}[];
  actions: {
    reoptimizeRoute: (...args: any[]) => any;
    updateRoute: (...args: any[]) => any;
    setRoute: (...args: any[]) => any;
  };
  openCloseVehicleDetailsModal: (...args: any[]) => any;
  selectedRoute: string | number;
  plan: IPlanData;
  activeProject: IProjectBase;
};

type VehicleInfoSectionProps = OwnVehicleInfoSectionProps;

const VehicleInfoSection = ({
  classes,
  routes,
  missing,
  openCloseVehicleDetailsModal,
  selectedRoute,
  actions,
  plan,
  activeProject,
}: VehicleInfoSectionProps) => {
  const [anchorVehicle, setAnchorVehicle] = useState<{ screenPosition: { top: number; left: number; }; doc?: IRouteDataExtended; } | undefined>();
  return (
    <>
      <div
        style={{
          height: '50px',
          backgroundColor: 'white',
          position: 'relative',
          paddingRight: '8px',
          paddingLeft: '8px',
        }}
        onDoubleClick={() => {
          // @ts-expect-error ts-migrate(7015) FIXME: Element implicitly has an 'any' type because index... Remove this comment to see the full error message
          if (selectedRoute !== 'missing') openCloseVehicleDetailsModal(routes[selectedRoute]);
        }}
        onContextMenu={(e) => {
          e.preventDefault();
          setAnchorVehicle({
            screenPosition: {
              top: e.clientY,
              left: e.clientX,
            },
          });
        }}
        onFocus={() => {}}
      >
        <Grid container className={classes.allHeight} justify="space-between">
          {selectedRoute !== 'missing' ? (
            <>
              {/* <Grid item md={3} style={{ paddingRight: '12px', paddingLeft: '12px' }}>
              <Typography align="center" className={classes.vehicleInfoTitle}>
                <FormattedMessage id="distance" />
              </Typography>

              <LoadIndicator
                capacity={
                  routes[selectedRoute].max_distance && routes[selectedRoute].max_distance !== 0
                    ? (
                        (routes[selectedRoute].services.reduce(
                          (p, n) =>
                            // eslint-disable-next-line
                            n.distance_to_next_location ? p + n.distance_to_next_location : p,
                          0
                        ) /
                          routes[selectedRoute].max_distance) *
                        100
                      ).toFixed(0)
                    : 0
                }
              />
            </Grid> */}

              <Grid item md={3} style={{ paddingRight: '12px', paddingLeft: '12px' }}>
                <Typography align="center" className={classes.vehicleInfoTitle}>
                  <FormattedMessage id="weight" />
                </Typography>

                <LoadIndicator
                  capacity={
                    // @ts-expect-error ts-migrate(7015) FIXME: Element implicitly has an 'any' type because index... Remove this comment to see the full error message
                    routes[selectedRoute].max_weight && routes[selectedRoute].max_weight !== 0
                      ? (
                          // @ts-expect-error ts-migrate(7015) FIXME: Element implicitly has an 'any' type because index... Remove this comment to see the full error message
                          (routes[selectedRoute].services.reduce(
                            (p: any, n: any) =>
                              // eslint-disable-next-line
                            n.weight && n.type === 'delivery' ? p + n.weight : p,
                            0
                          ) /
                            // @ts-expect-error ts-migrate(7015) FIXME: Element implicitly has an 'any' type because index... Remove this comment to see the full error message
                            routes[selectedRoute].max_weight) *
                          100
                        ).toFixed(0)
                      : 0
                  }
                />
              </Grid>

              <Grid item md={3} style={{ paddingRight: '12px', paddingLeft: '12px' }}>
                <Typography align="center" className={classes.vehicleInfoTitle}>
                  <FormattedMessage id="volume" />
                </Typography>

                <LoadIndicator
                  capacity={
                    // @ts-expect-error ts-migrate(7015) FIXME: Element implicitly has an 'any' type because index... Remove this comment to see the full error message
                    routes[selectedRoute].max_volume && routes[selectedRoute].max_volume !== 0
                      ? (
                          // @ts-expect-error ts-migrate(7015) FIXME: Element implicitly has an 'any' type because index... Remove this comment to see the full error message
                          (routes[selectedRoute].services.reduce(
                            (
                              p: any,
                              n: any // eslint-disable-next-line
                          ) => (n.volume && n.type === 'delivery' ? p + n.volume : p),
                            0
                          ) /
                            // @ts-expect-error ts-migrate(7015) FIXME: Element implicitly has an 'any' type because index... Remove this comment to see the full error message
                            routes[selectedRoute].max_volume) *
                          100
                        ).toFixed(0)
                      : 0
                  }
                />
              </Grid>

              <Grid item md={3}>
                <Typography align="center" className={classes.vehicleInfoTitle}>
                  <FormattedMessage id="distance" />
                </Typography>
                <Grid container alignItems="center" justify="center" style={{ height: '24px' }}>
                  <Typography align="center">
                    {`${kmToSM(
                      // @ts-expect-error ts-migrate(7015) FIXME: Element implicitly has an 'any' type because index... Remove this comment to see the full error message
                      ((routes[selectedRoute] &&
                        // @ts-expect-error ts-migrate(7015) FIXME: Element implicitly has an 'any' type because index... Remove this comment to see the full error message
                        routes[selectedRoute].services.reduce(
                          (ps: any, ns: any) => ps + (ns.distance_to_next_location || 0),
                          0
                        )) ||
                        0) / 1000,
                      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
                      activeProject.units.distance
                      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
                    ).toFixed(1)} ${activeProject.units.distance}`}
                  </Typography>
                </Grid>
              </Grid>
              <Grid item md={3}>
                <Typography align="center" className={classes.vehicleInfoTitle}>
                  <FormattedMessage id="services" />
                </Typography>

                <Grid container alignItems="center" justify="center" style={{ height: '24px' }}>
                  <Typography
                    align="center"
                    style={{
                      color:
                        // @ts-expect-error ts-migrate(7015) FIXME: Element implicitly has an 'any' type because index... Remove this comment to see the full error message
                        routes[selectedRoute] &&
                        // @ts-expect-error ts-migrate(7015) FIXME: Element implicitly has an 'any' type because index... Remove this comment to see the full error message
                        routes[selectedRoute].max_services &&
                        // @ts-expect-error ts-migrate(7015) FIXME: Element implicitly has an 'any' type because index... Remove this comment to see the full error message
                        routes[selectedRoute].services.length > routes[selectedRoute].max_services
                          ? '#D0021B'
                          : '',
                    }}
                  >
                    {/* @ts-expect-error ts-migrate(7015) FIXME: Element implicitly has an 'any' type because index... Remove this comment to see the full error message */}
                    {`${routes[selectedRoute] && routes[selectedRoute].services.length} `}
                  </Typography>
                  <div style={{ height: '100%', width: '4px' }} />
                  <ServiceIcon className={classes.smallIcon} />
                </Grid>
              </Grid>
            </>
          ) : (
            <>
              <Grid item md={9} />
              <Grid item md={3}>
                <Typography align="center" className={classes.vehicleInfoTitle}>
                  <FormattedMessage id="services" />
                </Typography>

                <Grid container alignItems="center" justify="center" style={{ height: '24px' }}>
                  <Typography align="center">{`${missing && missing.length} `}</Typography>
                  <div style={{ height: '100%', width: '4px' }} />
                  <ServiceIcon className={classes.smallIcon} />
                </Grid>
              </Grid>
            </>
          )}
        </Grid>
        {anchorVehicle && selectedRoute !== 'missing' && (
          <RouteMenu
            plan={plan}
            // @ts-expect-error ts-migrate(7015) FIXME: Element implicitly has an 'any' type because index... Remove this comment to see the full error message
            route={routes[selectedRoute]}
            contextAnchor={anchorVehicle}
            setContextAnchor={setAnchorVehicle}
            setRightPanelVisible={(visible: boolean) => {
              // @ts-expect-error ts-migrate(7015) FIXME: Element implicitly has an 'any' type because index... Remove this comment to see the full error message
              actions.setRoute(visible ? routes[selectedRoute] : undefined);
            }}
          />
        )}
      </div>
    </>
  );
};

VehicleInfoSection.defaultProps = {
  plan: undefined,
  activeProject: {
    units: {
      distance: 'km',
    },
  },
};

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    reoptimizeRoute: (routeId: any) => dispatch(Routes.optimizeRoute(routeId)),
    updateRoute: (routeId: any, services: any) => dispatch(Routes.updateRoute(routeId, services)),
    setRoute: (route: any) => dispatch(ActivePlan.setDetailView('route', route)),
  },
});

const mapStateToProps = (state: any) => {
  return {
    plan: state.activePlan.plan,
    activeProject: state.projects.activeProject,
  };
};

// @ts-expect-error ts-migrate(2345) FIXME: Argument of type '() => { divMap: { position: stri... Remove this comment to see the full error message
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(VehicleInfoSection));
