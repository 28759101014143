import { CircularProgress, Grid, Tooltip, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Check, FileCopy, OpenInNew, Send } from '@material-ui/icons';
import { Button, Dialog, HSpacer, VSpacer } from '@sm-highway-web/react-components';
import { IProjectData } from 'highway-api/dist/common/interfaces/projects';
import { IRouteDataExtended } from 'highway-api/dist/common/interfaces/routes';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import VehicleIcons from '../assets/svg/VehicleIcons';
import WarningIcon from '../assets/svg/WarningIcon';
import FormText, { validateEmail } from '../components/form-fields/FormText';
import FormattedMessage from '../components/FormattedMessageCustom';
import colors, { getRouteColor } from '../constants/Colors';
import * as routing from '../constants/Routing';
import history from '../helpers/History';
import { getRoutePublicLink } from '../helpers/Routes';
import { IUserData } from '../interfaces/users';
import { Routes } from '../redux/actions';
import { projectHelpers, userHelpers } from '../redux/helpers';
import { dispatchRouteByEmail } from '../services/requests';

const styles = {
  routesList: {
    maxHeight: '400px',
    overflowY: 'auto',
    overflowX: 'hidden',
  },
  route: {
    padding: '8px 0',
    paddingRight: '8px',
    borderTop: '1px solid rgba(224, 224, 224, 1)',
  },
  routeTitle: {
    color: '#FF8200',
    fontSize: '16px',
  },
  noWrap: {
    flexWrap: 'unset',
  },
};

type AssignDialogProps = {
  classes: {
    routesList: string;
    route: string;
    routeTitle: string;
    noWrap: string;
  };
  assignDialogOpen: boolean;
  handleOpenAssignDialog: (...args: any[]) => any;
  routes?: IRouteDataExtended[];
  publicKey?: string;
  user?: IUserData;
  project?: IProjectData;
  actions: {
    updateRoute: (...args: any[]) => any;
  };
};

const AssignDialog = ({
  routes,
  publicKey,
  handleOpenAssignDialog,
  assignDialogOpen,
  classes,
  actions,
  project,
}: AssignDialogProps) => {
  const [localRoutes, setLocalRoutes] = useState(routes || []);
  const [sendToAllEmptyEmails, setSendToAllEmptyEmails] = useState([]);
  const [copyFlags, setCopyFlags] = useState([]);
  const [sendFlags, setSendFlags] = useState([]);

  useEffect(() => {
    if (routes && routes.length > 0) {
      setLocalRoutes(routes);
    }
    return () => {
      setLocalRoutes([]);
    };
  }, [routes]);

  useEffect(() => {
    if (localRoutes && localRoutes.length > 0) {
      setSendToAllEmptyEmails(
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'any[]' is not assignable to para... Remove this comment to see the full error message
        localRoutes.filter(
          (route: any) => !route.email || route.email === '' || !validateEmail(route.email)
        )
      );
    }
  }, [localRoutes]);

  const generateLink = (route: any) => {
    return getRoutePublicLink(publicKey, route.id);
  };
  
  const handleCopyLink = (route: any) => {
    if (publicKey && route) {
      const url = generateLink(route);
      const el = document.createElement('textarea');
      el.value = url;
      const helper = document.getElementById('helpercopyassign');
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      helper.appendChild(el);
      el.select();
      document.execCommand('copy');
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      helper.removeChild(el);
    }
  };

  const handleViewLink = (route: any) => {
    window.open(generateLink(route), '_blank');
  };

  const handleSendEmail = async (routeId: any) => {
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'any[]' is not assignable to para... Remove this comment to see the full error message
    setSendFlags([...sendFlags, routeId]);

    sendRouteEmail(routeId).finally(() => {
      setSendFlags([...sendFlags.filter((flag: any) => flag !== routeId)]);
    });
  };

  const sendRouteEmail = async (routeId: any) => {
    return dispatchRouteByEmail(routeId).catch((error) => {
      /* eslint-disable-next-line no-console */
      console.error('error', error);
    });
  };

  const sendAllEmails = () => {
    const validRoutes = localRoutes.filter(
      (route: any) => route.email && route.email !== '' && validateEmail(route.email)
    );
    if (validRoutes.length > 0) {
      const loadingRoutes = [...sendFlags, ...validRoutes.map((route: any) => route.id)];

      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'any[]' is not assignable to para... Remove this comment to see the full error message
      setSendFlags(loadingRoutes);

      validRoutes.forEach((route: any) => {
        sendRouteEmail(route.id).finally(() => {
          setSendFlags(sendFlags.filter((flag: any) => flag !== route.id));
        });
      });
    }
  };

  return (
    <Dialog
      id="dispatch-dialog"
      modal
      onClose={() => {
        setLocalRoutes(routes || []);
        setSendToAllEmptyEmails([]);
        setCopyFlags([]);
        setSendFlags([]);
        handleOpenAssignDialog();
      }}
      open={assignDialogOpen}
      style={{ zIndex: 1299 }} // Needed for showing onboarding tutorial correctly.
      title={<FormattedMessage id="assigndialog.title" />}
    >
      <div id="helpercopyassign" />
      <Typography>
        <FormattedMessage id="assigndialog.subtitle" />
      </Typography>

      <VSpacer medium />

      <div className={classes.routesList}>
        <Grid container direction="column">
          {localRoutes &&
            localRoutes.map((route: any, idx: any, routesArray: any) => {
              let Icon = VehicleIcons.truck;
              if (route.icon && VehicleIcons[route.icon]) {
                Icon = VehicleIcons[route.icon];
              }
              return (
                <Grid
                  key={`assign-route-${route.id}`}
                  container
                  alignItems="center"
                  style={{
                    ...(idx === localRoutes.length - 1
                      ? { borderBottom: '1px solid rgba(224, 224, 224, 1)' }
                      : {}),
                  }}
                  className={classes.route}
                >
                  <Grid item sm={12} md={9}>
                    <Grid container className={classes.noWrap}>
                      <Grid item>
                        <Icon
                          style={{
                            color: getRouteColor(route, routes ?? []),
                            float: 'left',
                            margin: '0px 8px',
                            height: '100%',
                          }}
                        />
                      </Grid>

                      <Grid item container alignItems="center" style={{ width: '100%' }}>
                        <Typography>
                          <span>{idx + 1}:{' '}</span>
                          <span className={classes.routeTitle}>{route.label}</span>
                        </Typography>

                        {project &&
                          project.view &&
                          project.view.vehicle &&
                          project.view.vehicle.email && (
                          <FormText
                            type="email"
                            fieldValue={route.email}
                            placeholder={<FormattedMessage id="assigndialog.emailplaceholder" />}
                            handleEnter={(val: any) => {
                              const copy = [...routesArray];
                              copy[idx].email = val;
                              setLocalRoutes(copy);
                              actions.updateRoute(route.id, { email: val });
                            }}
                          />
                        )}
                      </Grid>
                    </Grid>
                  </Grid>

                  <Grid item sm={12} md={3}>
                    <Grid container justify="flex-end">
                      {project &&
                        project.view &&
                        project.view.vehicle &&
                        project.view.vehicle.email && (
                        <>
                          <Tooltip title={<FormattedMessage id="assigndialog.buttonsendemail" />}>
                            <div>
                              {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'includes' does not exist on type 'never[... Remove this comment to see the full error message */}
                              {!sendFlags.includes(route.id) && (
                                <Send
                                  style={{
                                    cursor: `${
                                      Boolean(route.email) &&
                                        validateEmail(route.email) &&
                                        sendFlags.length === 0
                                        ? 'pointer'
                                        : 'default'
                                    }`,
                                    color: `${
                                      Boolean(route.email) &&
                                        validateEmail(route.email) &&
                                        sendFlags.length === 0
                                        ? 'rgb(51, 57, 64)'
                                        : 'rgb(193, 192, 192)'
                                    }`,
                                    fontSize: '24px',
                                  }}
                                  onClick={() => {
                                    if (
                                      route.email &&
                                        validateEmail(route.email) &&
                                        sendFlags.length === 0
                                    ) {
                                      handleSendEmail(route.id);
                                    }
                                  }}
                                />
                              )}
                              {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'includes' does not exist on type 'never[... Remove this comment to see the full error message */}
                              {sendFlags.includes(route.id) && <CircularProgress size={24} />}
                            </div>
                          </Tooltip>
                          <HSpacer medium />
                        </>
                      )}

                      <Tooltip title={<FormattedMessage id="assigndialog.buttoncopylink" />}>
                        <div>
                          {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'includes' does not exist on type 'never[... Remove this comment to see the full error message */}
                          {!copyFlags.includes(route.id) && (
                            <FileCopy
                              style={{
                                cursor: 'pointer',
                                color: 'rgb(51, 57, 64)',
                                fontSize: '24px',
                              }}
                              onClick={() => {
                                // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'any[]' is not assignable to para... Remove this comment to see the full error message
                                setCopyFlags([...copyFlags, route.id]);
                                handleCopyLink(route);
                                setTimeout(() => {
                                  setCopyFlags(copyFlags);
                                }, 3000);
                              }}
                            />
                          )}
                          {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'includes' does not exist on type 'never[... Remove this comment to see the full error message */}
                          {copyFlags.includes(route.id) && (
                            <Check
                              style={{
                                color: '#5dc74b',
                                fontSize: '24px',
                              }}
                            />
                          )}
                        </div>
                      </Tooltip>

                      <HSpacer medium />
                      <Tooltip title={<FormattedMessage id="assigndialog.buttonopenlink" />}>
                        <OpenInNew
                          style={{
                            cursor: 'pointer',
                            color: 'rgb(51, 57, 64)',
                            fontSize: '24px',
                          }}
                          onClick={() => {
                            handleViewLink(route);
                          }}
                        />
                      </Tooltip>
                    </Grid>
                  </Grid>
                </Grid>
              );
            })}
        </Grid>
      </div>

      <VSpacer />

      {project && project.view && project.view.vehicle && project.view.vehicle.email && (
        <Grid container direction="column">
          <Grid container alignItems="center" spacing={2}>
            <Grid item sm={12} md={sendToAllEmptyEmails.length !== localRoutes.length ? 8 : 12}>
              <Grid container alignItems="center" direction="row" style={{ flexWrap: 'unset' }}>
                {sendToAllEmptyEmails.length > 0 && (
                  <WarningIcon style={{ color: '#D0021B', fontSize: '24px', marginRight: '8px' }} />
                )}

                <Typography>
                  {sendToAllEmptyEmails.length === 0 ? (
                    <FormattedMessage id="assigndialog.sendemailtoallinfo" />
                  ) : sendToAllEmptyEmails.length === localRoutes.length ? (
                    <FormattedMessage id="assigndialog.norouteswithemail" />
                  ) : (
                    <FormattedMessage
                      id="assigndialog.sendemailtoallerror"
                      values={{
                        // @ts-expect-error ts-migrate(2339) FIXME: Property 'label' does not exist on type 'never'.
                        routes: sendToAllEmptyEmails.map((route) => route.label).join(', '),
                      }}
                    />
                  )}
                </Typography>
              </Grid>
            </Grid>

            {sendToAllEmptyEmails.length !== localRoutes.length && (
              <Grid item sm={12} md={4}>
                <Grid container direction="row" justify="flex-end">
                  <Button
                    variant="contained"
                    onClick={() => {
                      sendAllEmails();
                    }}
                    disabled={sendFlags.length > 0}
                  >
                    {sendToAllEmptyEmails.length === 0 ? (
                      <FormattedMessage id="assigndialog.buttonsendemailtoall" />
                    ) : (
                      <FormattedMessage id="assigndialog.buttonsendemailtoallanyway" />
                    )}
                  </Button>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      )}

      {!project ||
        !project.view ||
        !project.view.vehicle ||
        (!project.view.vehicle.email && (
          <Grid container direction="column">
            <Grid container alignItems="center" spacing={2}>
              <Grid item sm={12} md={sendToAllEmptyEmails.length !== localRoutes.length ? 8 : 12}>
                <Grid container alignItems="center" direction="row" style={{ flexWrap: 'unset' }}>
                  <WarningIcon style={{ color: '#D0021B', fontSize: '24px', marginRight: '8px' }} />

                  <HSpacer small />

                  <div style={{ fontSize: '14px' }}>
                    {userHelpers.currentUser.isOrganizationAdmin() ||
                    userHelpers.currentUser.isOrganizationManager() ||
                    projectHelpers.currentUser.isManagerInProject(project) ? (
                          <>
                            <FormattedMessage tagName="span" id="assigndialog.userviewemailnotenabled.1" />

                            <span
                              style={{
                                cursor: 'pointer',
                                color: '#FF8200',
                                fontFamily: 'Work Sans',
                                border: 'none',
                              }}
                              onClick={() => {
                                history.push(
                                  `${routing.SETTINGS.ORGANIZATION.PROJECTS.ROOT}/${project.id}/${routing.ProjectEditViews.fields}`
                                );
                              }}
                            >
                              <FormattedMessage tagName="span" id="assigndialog.userviewemailnotenabled.2" />
                            </span>
                          </>
                        ) : (
                          <FormattedMessage tagName="span" id="assigndialog.userviewemailnotenabled.not_privileged" />
                        )}
                  </div>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        ))}

      <VSpacer />
    </Dialog>
  );
};

AssignDialog.defaultProps = {
  project: undefined,
  routes: undefined,
  publicKey: undefined,
  user: undefined,
};

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    updateRoute: (routeId: any, services: any) => dispatch(Routes.updateRoute(routeId, services)),
  },
});

// @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ routesList: { maxHeight: strin... Remove this comment to see the full error message
export default connect(null, mapDispatchToProps)(withStyles(styles)(AssignDialog));
