import constants from '../constants/index';

const initialState = {
  plans: {
    limit: 20,
    offset: 0,
    docs: undefined,
  },
};

function plans(state = initialState, action: any) {
  switch (action.type) {
    case constants.Plans.PLANS_GET_PLANS_SUCCESS:
      return {
        ...state,
        plans: action.payload,
      };
    default:
      return state;
  }
}
export default plans;
