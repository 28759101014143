import React from 'react';
import { Provider } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import Highway from './pages/Highway';
import store from './redux/store/index';

const styles = {};

function App() {
  return (
    <Provider store={store}>
      <Highway />
    </Provider>
  );
}

App.propTypes = {};

export default withStyles(styles)(App);
