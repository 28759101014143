import { withStyles } from '@material-ui/core/styles';
import { Snackbar } from '@sm-highway-web/react-components';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { InfoError } from '../redux/actions';
import FormattedMessage from './FormattedMessageCustom';

const styles = {
  snackbarRoot: {
    top: '-10%',
  },
};

type OwnSnackbarComponentProps = {
  classes: {
    snackbarRoot: string;
  };
  actions: {
    closeSnackBar?: (...args: any[]) => any;
  };
  type?: string;
  autoHideDuration?: number;
  message?: string;
  closeByClickAway?: boolean;
};

type SnackbarComponentState = any;

type SnackbarComponentProps = OwnSnackbarComponentProps;

class SnackbarComponent extends Component<SnackbarComponentProps, SnackbarComponentState> {
  static defaultProps: any;

  state = {
    openSnack: false,
  };

  componentDidUpdate = (prevProps: any) => {
    const { message } = this.props;
    if (!prevProps.message && message) {
      this.setState({ openSnack: true });
    }
  };

  shouldComponentUpdate = (nextProps: any) => {
    if (!nextProps.message) {
      return false;
    }
    return true;
  };

  onClose = () => {
    const { actions } = this.props;
    // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    this.setState({ openSnack: false }, () => actions.closeSnackBar());
  };

  render() {
    const { type, autoHideDuration, message, closeByClickAway, classes } = this.props;
    const { openSnack } = this.state;
    let messageParsed = message;
    if (message && (message.includes('infoerror.') || message.includes('highway.'))) {
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'Element' is not assignable to type 'string'.
      messageParsed = <FormattedMessage id={message} />;
    }
    return (
      <Snackbar
        variant={type}
        open={openSnack}
        onClose={this.onClose}
        autoHideDuration={autoHideDuration}
        closeByClickAway={closeByClickAway}
        horizontal="center"
        vertical="top"
        message={messageParsed}
        classes={{ anchorOriginTopCenter: classes.snackbarRoot }}
      />
    );
  }
}

SnackbarComponent.defaultProps = {
  type: undefined,
  autoHideDuration: undefined,
  message: undefined,
  closeByClickAway: undefined,
};

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    closeSnackBar: () => dispatch(InfoError.closeSnackBar()),
  },
});

const mapStateToProps = (state: any) => {
  return {
    type: state.infoerror.type,
    autoHideDuration: state.infoerror.autoHideDuration,
    message: state.infoerror.message,
    closeByClickAway: state.infoerror.closeByClickAway,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SnackbarComponent));
