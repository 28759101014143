import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { IRouteDataExtended } from 'highway-api/dist/common/interfaces/routes';
import { IServiceDataExtended } from 'highway-api/dist/common/interfaces/services';
import React from 'react';
import FormattedMessage from './FormattedMessageCustom';

type BrokenConstraintsPanelProps = {
  brokenConstraints: any;
  type: 'route' | 'service';
};

const BrokenConstraintsPanel = ({ type, brokenConstraints }: BrokenConstraintsPanelProps) => {
  return (
    <div style={{ margin: '10px', fontSize: '14px', lineHeight: '1.6em' }}>
      <FormattedMessage tagName="p" id={`${type}.brokenconstraints.info`} />

      {Object.keys(brokenConstraints).map((constraintKey) => {
        if (brokenConstraints[constraintKey]) {
          return (
            <p style={{ marginLeft: '8px' }} key={constraintKey}>
              <span>- </span>
              <FormattedMessage tagName="span" id={`${type}.brokenconstraints.${constraintKey}`} />
            </p>
          );
        }
        return null;
      })}
    </div>
  );
};

const CustomTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: 'rgba(5, 13, 30, 0.89)',
    maxWidth: '300px',
  },
}))(Tooltip);

type OwnServiceBrokenConstraintsTooltipProps = {
  route?: IRouteDataExtended;
  service?: IServiceDataExtended;
  children: React.ReactElement;
};

type ServiceBrokenConstraintsTooltipProps = OwnServiceBrokenConstraintsTooltipProps;

const ServiceBrokenConstraintsTooltip = ({
  service,
  route,
  children,
}: ServiceBrokenConstraintsTooltipProps) => {
  // Checks if the service weight exceeds the route maximum weight.
  const weight =
    route?.max_weight && service?.weight && service?.type === 'delivery'
      ? service?.weight > route.max_weight
      : false;

  // Checks if the service volume exceeds the route maximum volume.
  const volume =
    route?.max_volume && service?.volume && service?.type === 'delivery'
      ? service?.volume > route.max_volume
      : false;

  // Checks if the service planned_arrival_time and planned_departure_time are inside the service timewindows.
  const timewindows = validateServiceTimewindows(service);

  const brokenConstraints = { weight, volume, timewindows };

  if (!brokenConstraints.weight && !brokenConstraints.volume && !brokenConstraints.timewindows) {
    return null;
  }
  return (
    <CustomTooltip
      title={<BrokenConstraintsPanel type="service" brokenConstraints={brokenConstraints} />}
    >
      {children}
    </CustomTooltip>
  );
};

/**
 * @description Validates if the service planned_arrival_time and planned_departure_time are inside the service timewindows.
 * @param {*} service
 */
const validateServiceTimewindows = (service: any) => {
  return (
    service.timewindows &&
    service.timewindows.length > 0 &&
    service.planned_arrival_time &&
    service.planned_departure_time &&
    !service.timewindows.some((timewindow: any) =>
      isValidTimeWindow(timewindow, [service.planned_arrival_time, service.planned_departure_time])
    )
  );
};

/**
 * @description Validates that a timewindow is inside another.
 * @param {*} timewindow
 * @param {*} realTimeWindow
 */
const isValidTimeWindow = (timewindow: any, realTimeWindow: any) =>
  timewindow[0] <= realTimeWindow[0] && timewindow[1] >= realTimeWindow[1];

type OwnRouteBrokenConstraintsTooltipProps = {
  route?: IRouteDataExtended;
  children: React.ReactElement;
};

type RouteBrokenConstraintsTooltipProps = OwnRouteBrokenConstraintsTooltipProps;

const RouteBrokenConstraintsTooltip = ({ route, children }: RouteBrokenConstraintsTooltipProps) => {
  // Checks if the total of services distances exceeds the route maximum weight.
  const distance =
    route?.max_distance && route?.services.reduce(
      (p: any, n: any) => (n.distance_to_next_location ? p + n.distance_to_next_location : p),
      0
    ) > route.max_distance;

  const weight =
    route?.max_weight && route?.services.reduce(
      (p: any, n: any) => (n.weight && n.type === 'delivery' ? p + n.weight : p),
      0
    ) > route.max_weight;

  // Checks if the total of services volumes exceeds the route maximum volume.
  const volume =
    route?.max_volume && route?.services.reduce(
      (p: any, n: any) => (n.volume && n.type === 'delivery' ? p + n.volume : p),
      0
    ) > route.max_volume;

  // Checks if the route number of services exceeds its maximum volume.
  const maxServices = route?.max_services && route?.services.length > route?.max_services;

  // Checks if the route planned_arrival_time and planned_departure_time are inside the route timewindow.
  const timewindow =
    route?.planned_start_time !== undefined &&
    route?.planned_end_time !== undefined &&
    route?.timewindow && 
    !isValidTimeWindow(route?.timewindow, [route.planned_start_time, route.planned_end_time]);

  const brokenConstraints = { distance, weight, volume, max_services: maxServices, timewindow };
  if (
    !brokenConstraints.distance &&
    !brokenConstraints.weight &&
    !brokenConstraints.volume &&
    !brokenConstraints.max_services &&
    !brokenConstraints.timewindow
  ) {
    return null;
  }
  return (
    <CustomTooltip
      title={<BrokenConstraintsPanel type="route" brokenConstraints={brokenConstraints} />}
    >
      {children}
    </CustomTooltip>
  );
};

RouteBrokenConstraintsTooltip.defaultProps = {};

type OwnBrokenConstraintsTooltipProps = {
  type: 'route' | 'service';
  route?: IRouteDataExtended;
  service?: IServiceDataExtended;
  children: React.ReactElement;
};

type BrokenConstraintsTooltipProps = OwnBrokenConstraintsTooltipProps;

const BrokenConstraintsTooltip = ({
  type,
  service,
  route,
  children,
}: BrokenConstraintsTooltipProps) => {
  switch (type) {
    case 'route':
      return <RouteBrokenConstraintsTooltip route={route} children={children} />;
    case 'service':
      return (
        <ServiceBrokenConstraintsTooltip route={route} service={service} children={children} />
      );
    default:
      return null;
  }
};

export default BrokenConstraintsTooltip;
