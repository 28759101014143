import { Divider, MenuItem, Popover, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Button } from '@sm-highway-web/react-components';
import { IProjectData } from 'highway-api/dist/common/interfaces/projects';
import React, { useState } from 'react';
import More3Points from '../assets/svg/More3Points';
import { colors } from '../assets/svg/TypeIcons';
import * as routing from '../constants/Routing';
import ConfirmDeleteDialog from '../dialogs/ConfirmDeleteDialog';
// import { projectHelpers } from '../redux/helpers';
import { getPlans } from '../services/requests';
import FormattedMessage from './FormattedMessageCustom';

const styles = {
  divInside: {
    height: 'calc(100vh - 48px)',
    width: '100%',
  },
  divTable: {
    height: 'calc(100% - 300px)',
  },
  allFull: {
    height: '100%',
    width: '100%',
  },
  lastPlans: {
    height: '300px',
  },
  tableServicesDiv: {
    backgroundColor: '#5A6B86',
    padding: '4px 0px',
    width: '40px',
    borderRadius: '4px',
  },
  typowhitebold: { color: 'white', fontWeight: 'bold' },
  menuItem: { padding: '8px 16px' },
  actionTable: {
    height: '48px',
    width: '100%',
  },
  table: {
    height: 'calc(100% - 48px)',
    width: '100%',
  },
};

type OwnProps = {
  doc: {
    id: string;
    status?: string;
  };
  classes: {
    divInside: string;
    divTable: string;
    allFull: string;
    lastPlans: string;
    tableServicesDiv: string;
    typowhitebold: string;
    menuItem: string;
    table: string;
    actionTable: string;
  };
  actions: {
    createPlan: (...args: any[]) => any;
    updatePlan: (...args: any[]) => any;
    deletePlan: (...args: any[]) => any;
    getPlans: (...args: any[]) => any;
  };
  openCopyPlanDialog?: (...args: any[]) => any;
  history: {
    push: (...args: any[]) => any;
  };
  setLastPlans: (...args: any[]) => any;
  activeProject?: IProjectData;
};

type Props = OwnProps;

const Menu3Points = ({
  classes,
  doc,
  actions,
  history,
  setLastPlans,
  activeProject,
  openCopyPlanDialog,
}: Props) => {
  const [open, setOpen] = useState(null);
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  return (
    <>
      <Button
        id={`planlist-menu-${doc.id}`}
        aria-haspopup="true"
        variant="text"
        color="secondary"
        onClick={(event: any) => {
          setOpen(event.currentTarget);
          event.stopPropagation();
        }}
        style={{ padding: '0px' }}
      >
        <More3Points />
      </Button>
      <Popover
        anchorEl={open}
        keepMounted
        open={Boolean(open)}
        onClose={() => setOpen(null)}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <MenuItem
          className={classes.menuItem}
          onClick={() => {
            // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
            history.push(`${routing.PLANS.replace(':projectId', activeProject.id)}/${doc.id}`);
            setOpen(null);
          }}
        >
          <Typography>
            <FormattedMessage id="plansactivity.table.actions.open" />
          </Typography>
        </MenuItem>
        <MenuItem
          className={classes.menuItem}
          onClick={() => {
            // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
            openCopyPlanDialog();
            setOpen(null);
          }}
        >
          <Typography>
            <FormattedMessage id="plansactivity.table.actions.copyplan" />
          </Typography>
        </MenuItem>
        <Divider />
        <MenuItem
          className={classes.menuItem}
          disabled
          onClick={() => {
            setOpen(null);
          }}
        >
          <Typography>
            <FormattedMessage id="plansactivity.table.actions.changestatus" />
          </Typography>
        </MenuItem>
        {doc.status !== 'planning' && (
          <MenuItem
            className={classes.menuItem}
            onClick={async () => {
              actions.updatePlan(doc.id, { status: 'planning' }, true);
              setOpen(null);
            }}
          >
            <Typography
              style={{
                color: colors.planning,
                backgroundColor: colors.planningBG,
                padding: '2px 8px',
                borderRadius: '4px',
              }}
              align="center"
            >
              <FormattedMessage id="planning.upper" />
            </Typography>
          </MenuItem>
        )}
        {doc.status !== 'in_progress' && (
          <MenuItem
            className={classes.menuItem}
            onClick={async () => {
              actions.updatePlan(doc.id, { status: 'in_progress' }, true);
              // @ts-expect-error ts-migrate(2554) FIXME: Expected 8 arguments, but got 4.
              const resp = await getPlans(undefined, 'in_progress', 0, 3);
              setLastPlans(resp.docs);
              setOpen(null);
            }}
          >
            <Typography
              style={{
                color: colors.in_progress,
                backgroundColor: colors.in_progressBG,
                padding: '2px 8px',
                borderRadius: '4px',
              }}
              align="center"
            >
              <FormattedMessage id="in_progress.upper" />
            </Typography>
          </MenuItem>
        )}
        {doc.status !== 'finished' && (
          <MenuItem
            className={classes.menuItem}
            onClick={() => {
              actions.updatePlan(doc.id, { status: 'finished' }, true);
              setOpen(null);
            }}
          >
            <Typography
              style={{
                color: colors.finished,
                backgroundColor: colors.finishedBG,
                padding: ' 2px 8px',
                borderRadius: '4px',
              }}
              align="center"
            >
              <FormattedMessage id="finished.upper" />
            </Typography>
          </MenuItem>
        )}
        <Divider />
        {/* <MenuItem
          disabled
          className={classes.menuItem}
          onClick={() => {
            setOpen(null);
          }}
        >
          <Typography>
            <FormattedMessage id="plansactivity.table.actions.exportaspdf" />
          </Typography>
        </MenuItem>
        <Divider />
        <MenuItem
          className={classes.menuItem}
          disabled
          onClick={() => {
            setOpen(null);
          }}
        >
          <Typography>
            <FormattedMessage id="plansactivity.table.actions.exportasexcel" />
          </Typography>
        </MenuItem>
        <Divider /> */}
        <MenuItem
          className={classes.menuItem}
          onClick={() => {
            setOpenDeleteDialog(true);
            setOpen(null);
          }}
        >
          <Typography style={{ color: '#F44336' }}>
            <FormattedMessage id="plansactivity.table.actions.delete" />
          </Typography>
        </MenuItem>
      </Popover>
      <ConfirmDeleteDialog
        typeData="plans"
        open={Boolean(openDeleteDialog)}
        setDialog={setOpenDeleteDialog}
        data={['d']}
        handleDelete={() => {
          actions.deletePlan(doc.id);
          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
          setOpenDeleteDialog(null);
        }}
      />
    </>
  );
};

Menu3Points.defaultProps = {
  activeProject: undefined,
  openCopyPlanDialog: () => {},
};

// @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ divInside: { height: string; w... Remove this comment to see the full error message
export default withStyles(styles)(Menu3Points);
