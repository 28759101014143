import { services_AR, vehicles_AR } from './examples_ar';
import { services_CL, vehicles_CL } from './examples_cl';
import { services_CO, vehicles_CO } from './examples_co';
import { services_ES, vehicles_ES } from './examples_es';
import { services_MX, vehicles_MX } from './examples_mx';
import { services_PE, vehicles_PE } from './examples_pe';

const vehiclesExamples = {
  ES: vehicles_ES,
  CL: vehicles_CL,
  AR: vehicles_AR,
  CO: vehicles_CO,
  MX: vehicles_MX,
  PE: vehicles_PE,
};

export const getVehiclesExamples = (country: any) => {
  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  let vehicles = vehiclesExamples[country];
  if (!vehicles) vehicles = vehiclesExamples.ES;
  return vehicles;
};

const servicesExamples = {
  ES: services_ES,
  CL: services_CL,
  AR: services_AR,
  CO: services_CO,
  MX: services_MX,
  PE: services_PE,
};

export const getServicesExamples = (country: any) => {
  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  let services = servicesExamples[country];
  if (!services) services = servicesExamples.ES;
  return services;
};
