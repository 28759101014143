import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { Dialog } from '@sm-highway-web/react-components';
import PaymentRunway from '../components/payment-runway/PaymentRunway';

const styles = (theme: any) => ({
  orange: {
    color: theme.palette.primary.main,
  },

  gray: {
    color: '#7C7C7C',
  },

  checkmarkiconbig: {
    height: '100px',
    width: '100px',
    color: 'white',
  },

  divenvolveicon: {
    padding: '30px',
    borderRadius: '50%',
    backgroundColor: '#B7DB5D',
  },

  enclosedDiv: {
    padding: '16px',
    border: '1px solid #8194B8',
    borderRadius: '8px',
    backgroundColor: 'rgba(175,192,224,0.05)',
    boxShadow: '0 2px 4px 0 ·#EAEAEA',
  },
});

type PaymentRunwayDialogProps = {
  onClose: (...args: any[]) => any;
};

const PaymentRunwayDialog = ({ onClose }: PaymentRunwayDialogProps) => {
  return (
    <Dialog modal fullScreen open title="" disableBackdropClick onClose={onClose}>
      <Grid container justify="center">
        <Grid item lg={5}>
          <Grid container direction="column">
            <PaymentRunway onClose={onClose} />
          </Grid>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default withStyles(styles)(PaymentRunwayDialog);
