import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { Switch } from '@material-ui/core';

const styles = () => ({
  container: {
    height: '100%',
    width: '100%',
  },
  noPadding: {
    padding: '2px 4px',
  },
  children: {
    '&:hover': {
      backgroundColor: '#EEEFF2',
    },
    borderRadius: '2px',
    padding: '2px 4px',
    width: 'calc(100% - 8px)',
  },
  label: {
    fontSize: '9px',
    color: '#8B8B8B',
    textTransform: 'uppercase',
    marginBottom: '4px',
  },
});

type OwnFormBooleanProps = {
  classes: {
    container?: string;
    noPadding?: string;
    label?: string;
    children?: string;
  };
  checked?: boolean;
  onChange?: (...args: any[]) => any;
  key?: (...args: any[]) => any;
  label?: string | React.ReactElement | {};
};

type FormBooleanProps = OwnFormBooleanProps;

const FormBoolean = ({ classes, label, checked, onChange, key }: FormBooleanProps) => {
  return (
    <div className={classes.container}>
      {label && <p className={classes.label}>{label}</p>}

      {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
      <Switch key={key} checked={checked} onChange={onChange} value={checked} color="primary" />
    </div>
  );
};

FormBoolean.defaultProps = {
  label: undefined,
  onChange: () => {},
  key: undefined,
  checked: false,
};

// @ts-expect-error ts-migrate(2345) FIXME: Argument of type '() => { container: { height: str... Remove this comment to see the full error message
export default withStyles(styles)(FormBoolean);
