import createSvgIcon from '@material-ui/icons/utils/createSvgIcon';
import React from 'react';

export default createSvgIcon(
  <>
    <circle cx="12" cy="12" r="2.3" />
    <circle cx="5.6" cy="12" r="2.3" />
    <circle cx="18.5" cy="12" r="2.3" />
  </>,
  'More3PointsIcon'
);
