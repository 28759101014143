import React from 'react';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { projectHelpers } from '../../redux/helpers';
import store from '../../redux/store';
import getHighway from '../../services/highway';
import FormattedMessage from '../FormattedMessageCustom';
import { AsyncSearchCustomOption, AsyncSearchNoOptionsMessage, AsyncSearchValueContainer } from './AsyncSearchComponents ';
import { Label as StyledLabel } from './FormFields.styled';

type FormPickupProps = {
  label: string | React.ReactElement | {};
  fieldValue?: string;
  onChange: (...args: any[]) => any;
  placeholder: string | React.ReactElement | {};
};

const FormPickup = ({ label, fieldValue, onChange, placeholder }: FormPickupProps) => {
  const isSearchable = () => !fieldValue;
  return (
    <>
      {label && <StyledLabel>{label}</StyledLabel>}
      <AsyncCreatableSelect
        placeholder={placeholder}
        value={fieldValue as any}
        cacheOptions
        isClearable
        isSearchable={isSearchable()}
        components={{
          Option: AsyncSearchCustomOption,
          ValueContainer: AsyncSearchValueContainer,
          NoOptionsMessage: (_props: any) => (
            <AsyncSearchNoOptionsMessage noOptionsMessage={<FormattedMessage id="service.data.details.pickup.no_options" />} />
          ),
        }}
        onChange={onChange}
        formatCreateLabel={(inputValue: any) => {
          return (
            <FormattedMessage
              id="service.data.details.pickup.create_new"
              values={{ value: inputValue }}
            />
          );
        }}
        loadOptions={async (inputValue: any) => {
          const highway = getHighway(store.getState().user.token);
          const response = await highway.client.list(
            projectHelpers.getCurrentProjectId(),
            0,
            5,
            inputValue
          );
          return response.docs;
        }}
      />
    </>
  );
};

FormPickup.defaultProps = {
  fieldValue: undefined,
};

export default FormPickup;
