import createSvgIcon from '@material-ui/icons/utils/createSvgIcon';
import React from 'react';

export default createSvgIcon(
  <>
    <path d="m12.087 22.74c-5.935 0-10.746-4.8112-10.746-10.746s4.8112-10.746 10.746-10.746 10.746 4.8112 10.746 10.746-4.8112 10.746-10.746 10.746zm8.2221-10.746c0-4.5409-3.6811-8.2221-8.2221-8.2221s-8.2221 3.6811-8.2221 8.2221 3.6811 8.2221 8.2221 8.2221 8.2221-3.6811 8.2221-8.2221z" />
    <path d="m12.087 16.38c-2.4223 0-4.386-1.9637-4.386-4.386s1.9637-4.386 4.386-4.386 4.386 1.9637 4.386 4.386-1.9637 4.386-4.386 4.386zm2.5196-4.386c0-1.3915-1.1281-2.5196-2.5196-2.5196s-2.5196 1.1281-2.5196 2.5196 1.1281 2.5196 2.5196 2.5196 2.5196-1.128 2.5196-2.5196z" />
    <circle cx="12.087" cy="11.994" r=".8893" />
    <rect x="10.938" y="15.369" width="2.2525" height="6.3351" />
    <rect
      transform="matrix(4.5223e-11 -1 1 4.5223e-11 6.6133 30.6)"
      x="17.481"
      y="8.826"
      width="2.2525"
      height="6.3351"
    />
    <rect
      transform="matrix(4.805e-11 -1 1 4.805e-11 -5.8047 18.182)"
      x="5.0626"
      y="8.6443"
      width="2.2525"
      height="6.6985"
    />
  </>,
  'VehiclesMenuIcon'
);
