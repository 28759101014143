import { Grid, Typography } from '@material-ui/core';
import {
  AutocompleteInput,
  Button,
  Form,
  InputText,
  VSpacer,
} from '@sm-highway-web/react-components';
import { IBillingInfo } from 'highway-api/dist/common/interfaces/subscriptions';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import countries, { EU_COUNTRIES } from '../../../constants/Countries';
import { InfoError, Payment } from '../../../redux/actions';
import { postBillingAddress } from '../../../services/requests';
import FormattedMessage from '../../FormattedMessageCustom';

type OwnBillingInfoProps = {
  actions: {
    getBillingAddress: (...args: any[]) => any;
    sendStatusToSnackbar: (...args: any[]) => any;
  };
  billingInfo?: IBillingInfo;
};

type BillingInfoProps = OwnBillingInfoProps;

const BillingInfo = ({ actions, billingInfo }: BillingInfoProps) => {
  const [valueInputs, setValueInputs] = useState(billingInfo || {});
  const [dirty, setDirty] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showVAT, setShowVAT] = useState(
    billingInfo && billingInfo.country && Object.keys(EU_COUNTRIES).includes(billingInfo.country)
  );
  const intl = useIntl();

  useEffect(() => {
    actions.getBillingAddress();
  }, [actions]);

  useEffect(() => {
    setValueInputs(billingInfo || {});
  }, [billingInfo]);

  const onChange = (id: any) => {
    return (value: any) => {
      setDirty(true);
      if (id === 'country') {
        if (Object.keys(EU_COUNTRIES).includes(value)) {
          setShowVAT(true);
        } else {
          setShowVAT(false);
        }
      }
      return setValueInputs({
        ...valueInputs,
        [id]: value || undefined,
      });
    };
  };

  return (
    <>
      {billingInfo && (
        <Grid item>
          <Form
            container
            direction="column"
            onSubmit={async () => {
              setLoading(true);
              try {
                await postBillingAddress(valueInputs);
                setDirty(false);
              } catch (error) {
                if (error.response && error.response.data.messageId) {
                  actions.sendStatusToSnackbar(
                    `infoerror.${error.response.data.messageId}`,
                    'error'
                  );
                }
              }
              setLoading(false);
            }}
          >
            {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
            <Grid container direction="column" formContainer>
              <Grid item>
                <Typography variant="h2">
                  <FormattedMessage id="settings.billing.info.title" />
                </Typography>
              </Grid>
              <VSpacer />
              <Grid item>
                <Typography variant="h4">
                  <FormattedMessage id="settings.billing.info.subtitle1" />
                </Typography>
              </Grid>
              <VSpacer medium />

              {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
              <Grid container formContainer>
                {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
                <Grid item md={7} sm={12} xs={12} formContainer>
                  <InputText
                    id="line1"
                    label={<FormattedMessage id="settings.billing.info.inputline1" />}
                    value={valueInputs.line1}
                    onChange={onChange('line1')}
                    margin="none"
                    validators={[]}
                    fullWidth
                  />
                </Grid>
              </Grid>

              {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
              <Grid container formContainer>
                {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
                <Grid item md={7} sm={12} xs={12} formContainer>
                  <InputText
                    id="line2"
                    label={<FormattedMessage id="settings.billing.info.inputline2" />}
                    value={valueInputs.line2}
                    onChange={onChange('line2')}
                    margin="none"
                    validators={[]}
                    fullWidth
                  />
                </Grid>
              </Grid>
              {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
              <Grid container formContainer>
                {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
                <Grid item md={3} sm={12} xs={12} formContainer>
                  <InputText
                    id="city"
                    label={<FormattedMessage id="settings.billing.info.inputcity" />}
                    value={valueInputs.city}
                    onChange={onChange('city')}
                    margin="none"
                    validators={[]}
                    fullWidth
                  />
                </Grid>
                <Grid item md={1} sm={false} xs={false} />

                {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
                <Grid item md={3} sm={12} xs={12} formContainer>
                  <InputText
                    id="postal_code"
                    label={<FormattedMessage id="settings.billing.info.inputpostalcode" />}
                    value={valueInputs.postal_code}
                    onChange={onChange('postal_code')}
                    margin="none"
                    validators={[]}
                    fullWidth
                  />
                </Grid>
              </Grid>
              {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
              <Grid container formContainer>
                {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
                <Grid item md={3} sm={12} xs={12} formContainer>
                  <InputText
                    id="state"
                    label={<FormattedMessage id="settings.billing.info.inputstate" />}
                    value={valueInputs.state}
                    onChange={onChange('state')}
                    margin="none"
                    validators={[]}
                    fullWidth
                  />
                </Grid>
                <Grid item md={1} sm={false} xs={false} />
                {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
                <Grid item md={3} sm={12} xs={12} formContainer>
                  <AutocompleteInput
                    label={<FormattedMessage id="settings.billing.info.inputcountry" />}
                    noOptionsMessage={
                      <FormattedMessage id="paymentrunwaydialog.step2.input.nooptions" />
                    }
                    defaultValue={valueInputs.country}
                    handlerOnClickChange={(country: any) =>
                      onChange('country')(country ? country.value : undefined)
                    }
                    placeholder={intl.formatMessage({
                      id: 'paymentrunwaydialog.step2.input.country_select_ph',
                    })}
                    listSuggestions={Object.keys(countries).map((countryKey) => ({
                      label: countries[countryKey].label,
                      value: countryKey,
                    }))}
                  />
                </Grid>
              </Grid>
              {showVAT && (
                // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
                <Grid item md={3} sm={12} xs={12} formContainer>
                  <div style={{ height: '4px' }} />
                  <InputText
                    id="vat_number"
                    label={<FormattedMessage id="paymentrunwaydialog.step2.input.vatnum" />}
                    placeholder={intl.formatMessage(
                      {
                        id: 'paymentrunwaydialog.step2.input.vatnum.example',
                      },
                      {
                        // @ts-expect-error ts-migrate(2538) FIXME: Type 'undefined' cannot be used as an index type.
                        vatExample: EU_COUNTRIES[valueInputs.country].vatExample,
                      }
                    )}
                    value={valueInputs.vat_number}
                    onChange={onChange('vat_number')}
                    margin="none"
                    fullWidth
                  />
                </Grid>
              )}
            </Grid>
            <VSpacer medium />
            {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
            <Grid item formContainer>
              <Button
                disabled={loading || !dirty}
                variant={dirty || loading ? 'contained' : 'outlined'}
                type="submit"
              >
                {loading ? (
                  <FormattedMessage id="paymentrunwaydialog.buttonloading" />
                ) : (
                  <FormattedMessage id="settings.buttonupdate" />
                )}
              </Button>
            </Grid>
          </Form>
        </Grid>
      )}
    </>
  );
};

BillingInfo.defaultProps = {
  billingInfo: undefined,
};

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    sendStatusToSnackbar: (message: any, type: any) =>
      dispatch(InfoError.sendStatusToSnackbar(message, type)),
    getBillingAddress: () => dispatch(Payment.getBillingAddress()),
  },
});

const mapStateToProps = (state: any) => {
  return { billingInfo: state.payment.billingInfo };
};

export default connect(mapStateToProps, mapDispatchToProps)(BillingInfo);
