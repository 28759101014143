import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import loadinganimation from '../assets/img/preloadermonkey.svg';

const styles = {
  paper: {
    padding: '36px 64px',
    zIndex: 1,
  },
};

type OwnProps = {
  classes: {
    paper: string;
  };
  noMonkey?: boolean;
};

type Props = OwnProps;

const Loader = ({ classes, noMonkey, ...other }: Props) => {
  return (
    <div className={classes.paper} {...other}>
      {!noMonkey && <img src={loadinganimation} alt="" />}
      <div className="lds-ellipsis">
        <div />
        <div />
        <div />
      </div>
    </div>
  );
};

Loader.defaultProps = {
  noMonkey: false,
};

Loader.defaultProps = {};

export default withStyles(styles)(Loader);
