import { Chip, Grid, Tooltip, Typography } from '@material-ui/core';
import { ReportProblemOutlined } from '@material-ui/icons';
import { IPlanData } from 'highway-api/dist/common/interfaces/plans';
import { IProjectData } from 'highway-api/dist/common/interfaces/projects';
import { IRouteDataExtended } from 'highway-api/dist/common/interfaces/routes';
import { IServiceDataExtended, IServiceStatus } from 'highway-api/dist/common/interfaces/services';
import React from 'react';
import TypeIcons, { colors } from '../../../assets/svg/TypeIcons';
import VehicleIcons from '../../../assets/svg/VehicleIcons';
import WarningIcon from '../../../assets/svg/WarningIcon';
import { getRouteColor, default as colorsRoutes } from '../../../constants/Colors';
import { SERVICE_STATUS } from '../../../constants/Services';
import { calculateDuration, parseSecondsToHM } from '../../../helpers/TimeDistance';
import BrokenConstraintsTooltip from '../../BrokenConstraintsTooltip';
import CustomFieldFormBoolean from '../../custom-fields/CustomFieldFormBoolean';
import CustomFieldFormCategorical from '../../custom-fields/CustomFieldFormCategorical';
import CustomFieldFormText from '../../custom-fields/CustomFieldFormText';
// import CustomFields from '../custom-fields/CustomFields';
import FormattedMessage from '../../FormattedMessageCustom';
import OptimizationWarningsTooltip from '../../OptimizationWarningsTooltip';

type ITableColumns = {
  [key: string]: boolean;
};

type IColumnsResponse = { [key: string]: any }[];

const getColumns = (
  activeProject: IProjectData,
  tableColumns: ITableColumns,
  plan: IPlanData,
  onOpenRightPanel?: (...args: any[]) => any
): IColumnsResponse => {
  const columns = [];

  for (const key in tableColumns) {
    if (tableColumns.hasOwnProperty(key) && tableColumns[key] === true) {
      switch (key) {
        case 'icon':
          columns.push({
            width: 48,
            column: 'icon',
            align: 'center',
            name: <FormattedMessage id="planoverview.service.table.header.type" />,
            formatter: (doc: any) => {
              let Icon = TypeIcons.normal;
              if (doc && TypeIcons[doc]) {
                Icon = TypeIcons[doc];
                return (
                  <div>
                    <Icon style={{ color: colors[doc] }} />
                  </div>
                );
              }
              return (
                <div>
                  <Icon style={{ color: colors.normal }} />
                </div>
              );
            },
          });
          break;
        case 'label':
          columns.push({
            width: 220,
            column: 'label',
            name: <FormattedMessage id="planoverview.service.table.header.label" />,
            computed: (doc: IServiceDataExtended) => doc,
            formatter: (doc: IServiceDataExtended) => (
              <Tooltip title={doc.label}>
                <Typography noWrap style={{ maxWidth: '220px' }}>
                  {doc.label}
                </Typography>
              </Tooltip>
            ),
          });
          break;
        case 'alerts':
          columns.push({
            width: 68,
            column: 'alerts',
            name: <FormattedMessage id="planoverview.service.table.header.alerts" />,
            computed: (doc: IServiceDataExtended) => doc,
            formatter: (doc: IServiceDataExtended) => (
              <Grid container alignItems="center" justify="center">
                <OptimizationWarningsTooltip planRoutes={plan.routes} service={doc}>
                  <WarningIcon style={{ textAlign: 'center', color: '#D0021B', fontSize: '24px' }} />
                </OptimizationWarningsTooltip>
                <BrokenConstraintsTooltip type="service" service={doc}>
                  <ReportProblemOutlined style={{ textAlign: 'center', color: 'orange', fontSize: '24px' }} />
                </BrokenConstraintsTooltip>
              </Grid>
            ),
          });
          break;
        case 'status':
          columns.push({
            width: 135,
            column: 'status',
            name: <FormattedMessage id="planoverview.service.table.header.status" />,
            formatter: (status: IServiceStatus) => (
              status && (
                <Grid container alignItems="center">
                  <Chip
                    style={{
                      backgroundColor:
                        status === SERVICE_STATUS.pending
                          ? '#eff0f3'
                          : status === SERVICE_STATUS.completed
                            ? '#73ce617d'
                            : '#ff7e7e',
                      fontSize: '10px',
                    }}
                    label={<FormattedMessage id={`service.status.${status}`} />}
                  />
                </Grid>
              )
            ),
          });
          break;
        case 'location':
          columns.push({
            width: 244,
            column: 'location',
            name: <FormattedMessage id="planoverview.service.table.header.location" />,
            computed: (doc: IServiceDataExtended) => doc,
            formatter: (doc: IServiceDataExtended) =>
              doc.location && (doc.location.label || (doc.location.lat && doc.location.lng)) ? (
                <Tooltip
                  title={
                    doc.location.label
                      ? doc.location.label
                      : `${doc.location.lat}, ${doc.location.lng}`
                  }
                >
                  <Typography noWrap style={{ maxWidth: '220px' }}>
                    {doc.location.label
                      ? doc.location.label
                      : `${doc.location.lat}, ${doc.location.lng}`}
                  </Typography>
                </Tooltip>
              ) : (
                <Tooltip title={<FormattedMessage id="planoverview.services.warning" />}>
                  <WarningIcon style={{ color: 'red' }} />
                </Tooltip>
              ),
          });
          break;
        case 'duration':
          columns.push({
            width: 80,
            column: 'duration',
            align: 'center',
            name: <FormattedMessage id="planoverview.service.table.header.duration" />,
            formatter: (doc: any) => `${calculateDuration(doc)} min`,
          });
          break;

        case 'timewindows':
          if (activeProject?.view?.constraints?.timewindows) {
            columns.push({
              width: 220,
              align: 'center',
              column: 'timewindows',
              name: <FormattedMessage id="planoverview.service.table.header.timewindows" />,
              computed: (doc: IServiceDataExtended) => doc,
              formatter: (service: IServiceDataExtended) => {
                if (service.timewindows && service.timewindows.length > 0) {

                  return service.timewindows.length > 2 ? (
                    <Grid container alignItems="center" justify="center">
                      <Chip
                        key={service.timewindows[0][0] + service.timewindows[0][1]}
                        label={`${parseSecondsToHM(service.timewindows[0][0])}-${parseSecondsToHM(service.timewindows[0][1])}`}
                      />
                      <span
                        style={{
                          cursor: 'pointer',
                          color: '#FF8200',
                          fontFamily: 'Work Sans',
                          marginLeft: '4px',
                          border: 'none',
                        }}
                        onClick={e => {
                          if(onOpenRightPanel) {
                            onOpenRightPanel(e, service);
                          }
                        }}
                      >
                        <FormattedMessage tagName="span" id="view_more" />...
                      </span>
                    </Grid>
                  ) :
                    service.timewindows.map((timewindow: any) => (
                      <Chip
                        key={timewindow[0] + timewindow[1]}
                        label={`${parseSecondsToHM(timewindow[0])}-${parseSecondsToHM(timewindow[1])}`}
                      />
                    ));
                }
                return null;
              },
            });
          }
          break;
        case 'route_id':
          columns.push({
            width: 40,
            column: 'route_id',
            align: 'center',
            name: <FormattedMessage id="planoverview.service.table.header.route" />,
            formatter: (doc: any) => {
              let resp = doc;
              plan.routes &&
                plan.routes.forEach((route: IRouteDataExtended) => {
                  if (route.id === doc) {
                    const Icon = route.icon && VehicleIcons[route.icon] ? VehicleIcons[route.icon] : VehicleIcons.truck;
                    resp = (
                      <Tooltip title={route.label}>
                        <Icon style={{ color: getRouteColor(route, plan.routes!) }} />
                      </Tooltip>
                    );
                  }
                });
              return resp;
            },
          });
          break;
        case 'order':
          columns.push({
            width: 40,
            column: 'order',
            align: 'center',
            name: <FormattedMessage id="planoverview.service.table.header.order" />,
            formatter: (doc: any) => (doc !== undefined ? doc + 1 : doc),
          });
          break;
        case 'external_id':
        case 'location_details':
        case 'comments':
        case 'reference_person':
        case 'phone':
        case 'webpage':
        case 'optional':
          if (activeProject?.view?.client[key]) {
            columns.push({
              width: 110,
              column: key,
              align: 'center',
              name: <FormattedMessage id={`planoverview.service.table.header.${key}`} />,
              ...(key === 'webpage'
                ? {
                    computed: (doc: any) => doc.website,
                  }
                : {}),
              ...(key === 'optional'
                ? {
                    formatter: (doc: boolean) => <FormattedMessage id={doc ? 'yes' : 'no'} />,
                  }
                : {
                    formatter: (doc: string) =>
                      doc && (
                        <Tooltip title={doc}>
                          <Typography noWrap style={{ maxWidth: '110px' }}>
                            {doc}
                          </Typography>
                        </Tooltip>
                      ),
                  }),
            });
          }
          break;
        case 'custom_fields':
          if (activeProject?.custom_fields?.client) {
            activeProject?.custom_fields?.client.forEach((projectField: any) => {
        
              columns.push({
                width: 160,
                column: `custom_field-${projectField.id}`,
                align: 'center',
                name: projectField.label,
                computed: (doc: IServiceDataExtended) => doc,
                formatter: (doc: IServiceDataExtended) => {
                  if(doc && doc.custom_fields) {            
                    const customField = (doc.custom_fields as { [key: string]: any })[projectField.id];
                    switch (projectField.type) {
                      case 'text':
                      case 'numerical':
                        return (
                          <CustomFieldFormText
                            key={projectField.id}
                            projectField={projectField}
                            fieldValue={customField || ''}
                            readOnly
                            onlyValues
                          />
                        );
                      case 'boolean':
                        return (
                          <CustomFieldFormBoolean
                            key={projectField.id}
                            projectField={projectField}
                            fieldValue={customField || false}
                            readOnly
                            onlyValues
                          />
                        );
                      case 'categorical':
                        if(customField && projectField.multiple) {
                          return (
                            <span
                              style={{
                                cursor: 'pointer',
                                color: '#FF8200',
                                fontFamily: 'Work Sans',
                                border: 'none',
                              }}
                              onClick={e => {
                                if(onOpenRightPanel) {
                                  onOpenRightPanel(e, doc);
                                }
                              }}
                            >
                              <FormattedMessage id="planoverview.service.table.open_in_right_panel_to_see" />
                            </span>
                          );
                        }
                        return (
                          <CustomFieldFormCategorical
                            key={projectField.id}
                            projectField={projectField}
                            fieldValue={customField || []}
                            readOnly
                            onlyValues
                          />
                        );
                      default:
                        return null;
                    }
                  }
                  return null;
                },
              });
            });
          }
          break;
        default:
          break;
      }
    }
  }

  return columns;
};

export default getColumns;
