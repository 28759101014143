import { Button, Divider, IconButton, MenuItem, Popover, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React, { useState } from 'react';
import More3Points from '../assets/svg/More3Points';
import FormattedMessage from './FormattedMessageCustom';

const styles = {
  divInside: {
    height: 'calc(100vh - 48px)',
    width: '100%',
  },
  divTable: {
    height: 'calc(100% - 300px)',
  },
  allFull: {
    height: '100%',
    width: '100%',
  },
  lastPlans: {
    height: '300px',
  },
  tableServicesDiv: {
    backgroundColor: '#5A6B86',
    padding: '4px 0px',
    width: '40px',
    borderRadius: '4px',
  },
  typowhitebold: { color: 'white', fontWeight: 'bold' },
  menuItem: { height: '42px' },
  actionTable: {
    height: '48px',
    width: '100%',
  },
  table: {
    height: 'calc(100% - 48px)',
    width: '100%',
  },
};

type OwnProps = {
  idButton: string;
  classes: {
    divInside: string;
    divTable: string;
    allFull: string;
    lastPlans: string;
    tableServicesDiv: string;
    typowhitebold: string;
    menuItem: string;
    table: string;
    actionTable: string;
  };
  large?: boolean;
  arrayActions: (
    {
      close: boolean;
      disabled?: boolean;
      onClick: (...args: any[]) => any;
      formattedMessage: string;
      color?: string;
    } | null
  )[];
  anchor?: {
    anchorOrigin?: any;
    transformOrigin?: any;
  };
  round?: boolean;
};

type Props = OwnProps;

const PopoverMore3Points = ({ classes, round, large, idButton, anchor, arrayActions }: Props) => {
  const [open, setOpen] = useState(null);
  const Element = round ? IconButton : Button;
  return (
    <>
      {/* @ts-expect-error ts-migrate(2604) FIXME: JSX element type 'Element' does not have any const... Remove this comment to see the full error message */}
      <Element
        id={idButton}
        disableElevation
        aria-haspopup="true"
        color="secondary"
        variant={large ? 'contained' : 'text'}
        onClick={(event: any) => {
          setOpen(event.currentTarget);
          event.stopPropagation();
        }}
        style={{
          ...(large === undefined
            ? {
                padding: '0px',
              }
            : { boxShadow: 'none' }),
        }}
      >
        <More3Points />
      </Element>
      <Popover
        anchorEl={open}
        keepMounted
        open={Boolean(open)}
        onClose={() => setOpen(null)}
        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        anchorOrigin={anchor.anchorOrigin}
        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        transformOrigin={anchor.transformOrigin}
      >
        {arrayActions &&
          arrayActions.map((action: any, i: any) => {
            if (action === null) {
              const key = `divider${i}`;
              return <Divider key={key} />;
            }
            return (
              <MenuItem
                key={action.formattedMessage}
                className={classes.menuItem}
                disabled={action.disabled}
                onClick={() => {
                  action.onClick();
                  if (action.close) {
                    setOpen(null);
                  }
                }}
              >
                <Typography style={{ ...(action.color ? { color: action.color } : {}) }}>
                  <FormattedMessage id={action.formattedMessage} />
                </Typography>
              </MenuItem>
            );
          })}
      </Popover>
    </>
  );
};

PopoverMore3Points.defaultProps = {
  anchor: {
    anchorOrigin: {
      vertical: 'center',
      horizontal: 'right',
    },
    transformOrigin: {
      vertical: 'top',
      horizontal: 'left',
    },
  },
  round: false,
};

// @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ divInside: { height: string; w... Remove this comment to see the full error message
export default withStyles(styles)(PopoverMore3Points);
