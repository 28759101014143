import { Badge, Chip, Grid, Tab, Tabs, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  PaginatedDynamicDataTable,
  ScrollableDialog,
} from '@sm-highway-web/react-components';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import TypeIcons, { colors } from '../assets/svg/TypeIcons';
import DataTable from '../components/datatable/DataTable';
import ExcelUpload from '../components/ExcelUpload';
import FormattedMessage from '../components/FormattedMessageCustom';
import { getServicesFieldArray } from '../helpers/Excel';
import { parseSecondsToHM } from '../helpers/TimeDistance';
import store from '../redux/store';
import { Services } from '../redux/actions';
import eventsPanel from '../services/events';
import getHighway from '../services/highway';
import { getClients } from '../services/requests';

const styles = {
  icons: {
    margin: '1px',
    width: '32px',
    height: '32px',
  },
  divicons: {
    width: '34px',
    height: '34px',
    borderRadius: '17px',
  },
  white: {
    color: 'white',
  },
  badge: {
    width: '100%',
  },
  padding: {
    padding: '16px 0px',
  },
  width100: {
    width: '100%',
  },
  gridnumservices: {
    width: '24px',
    height: '24px',
    backgroundColor: '#FF8200',
    borderRadius: '12px',
  },
  typonumservices: {
    width: '100%',
    color: 'white',
  },
  typonumservices2: {
    color: '#FF8200',
    paddingLeft: '8px',
  },
  errorQuota: {
    backgroundColor: 'rgba(255,130,0,0.1)',
    color: '#D0021B',
    padding: '8px',
    borderRadius: '16px',
  },
};

const TabPanel = ({ value, index, children }: any) => {
  return value === index ? children : null;
};

const serviceMapping = [
  {
    column: 'icon',
    name: 'Type',
    sortable: true,
    formatter: (doc: any) => {
      let Test = TypeIcons.normal;
      if (doc && TypeIcons[doc]) {
        Test = TypeIcons[doc];
        return (
          <div>
            <Test style={{ color: colors[doc] }} />
          </div>
        );
      }
      return (
        <div>
          <Test style={{ color: colors[doc] }} />
        </div>
      );
    },
  },
  {
    column: 'label',
    name: 'Name',
    sortable: true,
  },
  {
    column: 'location',
    name: 'Location',
    computed: (doc: any) => {
      if (doc.location) {
        return doc.location.label || `${doc.location.lat}, ${doc.location.lng}`;
      }
      return '-';
    },
  },
];

type OwnAddServicesDialogProps = {
  classes: {
    icons: string;
    divicons: string;
    white: string;
    badge: string;
    padding: string;
    gridnumservices: string;
    typonumservices: string;
    typonumservices2: string;
    width100: string;
    errorQuota: string;
  };
  open: boolean;
  handleOpenCloseDialog: (...args: any[]) => any;
  actions: {
    createServicesToAPlan: (...args: any[]) => any;
  };
  planId?: string;
  projectMaxServices?: number;
  planOperationCountry?: string;
  planServices?: number;
};

type AddServicesDialogProps = OwnAddServicesDialogProps;

const AddServicesDialog = ({
  classes,
  open,
  handleOpenCloseDialog,
  actions,
  planId,
  projectMaxServices,
  planOperationCountry,
  planServices,
}: AddServicesDialogProps) => {
  const [tabIndex, setTabIndex] = useState(1);
  const [servicesToAdd1, setServicesToAdd1] = useState([]);
  const [servicesToAdd2, setServicesToAdd2] = useState([]);
  const [servicesToAdd3, setServicesToAdd3] = useState([]);

  const resultingQuota = () =>
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    planServices +
    servicesToAdd1.length +
    servicesToAdd2.length +
    servicesToAdd3.length -
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    projectMaxServices;

  useEffect(() => {
    if (open) {
      setServicesToAdd1([]);
      setServicesToAdd2([]);
      setServicesToAdd3([]);
    }
  }, [open]);

  return (
    <ScrollableDialog
      modal
      disableBackdropClick
      onClose={handleOpenCloseDialog}
      open={open}
      dialogTitle={<FormattedMessage id="plan.servicesdialog.addservices" />}
      maxWidth="lg"
      fullWidth
      dialogContent={
        <>
          <Tabs
            value={tabIndex}
            onChange={(e, v) => setTabIndex(v)}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab
              label={
                <Badge
                  className={classes.badge}
                  badgeContent={servicesToAdd2.length}
                  color="primary"
                  classes={{ colorPrimary: classes.white }}
                >
                  <Typography align="center" className={classes.width100}>
                    <FormattedMessage id="plan.servicesdialog.fromclients" />
                  </Typography>
                </Badge>
              }
              value={1}
            />
            <Tab
              label={
                <Badge
                  className={classes.badge}
                  badgeContent={servicesToAdd3.length}
                  color="primary"
                  classes={{ colorPrimary: classes.white }}
                >
                  <Typography align="center" className={classes.width100}>
                    <FormattedMessage id="plan.servicesdialog.upload" />
                  </Typography>
                </Badge>
              }
              value={2}
            />
          </Tabs>
          <div style={{ height: '70vh' }}>
            <TabPanel value={tabIndex} index={1}>
              <PaginatedDynamicDataTable
                defaultLimit={100}
                fitContainer
                search
                provider={(offset: any, limit: any, sort: any, text: any) =>
                  getClients(text, offset, limit, sort)
                }
                mapping={[
                  {
                    column: 'icon',
                    name: 'Type',
                    sortable: true,
                    formatter: (doc: any) => {
                      let Test = TypeIcons.normal;
                      if (doc && TypeIcons[doc]) {
                        Test = TypeIcons[doc];
                        return (
                          <div>
                            <Test style={{ color: colors[doc] }} />
                          </div>
                        );
                      }
                      return (
                        <div>
                          <Test style={{ color: colors[doc] }} />
                        </div>
                      );
                    },
                  },
                  {
                    column: 'label',
                    name: 'Name',
                    sortable: true,
                  },
                  {
                    column: 'location',
                    name: 'Location',
                    computed: (doc: any) => {
                      if (doc.location) {
                        return doc.location.label || `${doc.location.lat}, ${doc.location.lng}`;
                      }
                      return '-';
                    },
                  },
                  {
                    column: 'default_timewindows',
                    name: 'Time window',
                    formatter: (doc: any) =>
                      doc.map((d: any) => (
                        <Chip
                          key={d[0] + d[1]}
                          label={`${parseSecondsToHM(d[0])}-${parseSecondsToHM(d[1])}`}
                        />
                      )),
                  },
                  {
                    column: 'phone',
                    name: 'Phone',
                  },
                  {
                    column: 'comments',
                    name: 'Notes',
                    formatter: (doc: any) => (
                      <Typography style={{ maxWidth: '700px' }} noWrap>
                        {doc}
                      </Typography>
                    ),
                  },
                ]}
                multiSelectId={['id']}
                onSelectionChange={(services: any) => {
                  const servicesRaw = services.map((c: any) => getHighway(store.getState().user.token).service.fromClient(c));
                  setServicesToAdd2(servicesRaw);
                }}
              />
            </TabPanel>
            <TabPanel value={tabIndex} index={2}>
              {servicesToAdd3.length === 0 && (
                <ExcelUpload
                  sampleUrl="https://static.smartmonkey.io/examples/services_clients.xlsx"
                  intlPreffix="service.data.details"
                  fieldsArray={getServicesFieldArray()}
                  onAdd={setServicesToAdd3}
                  countryCode={planOperationCountry}
                />
              )}
              {servicesToAdd3.length > 0 && (
                <DataTable
                  maxHeight="60vh"
                  noFooter
                  docs={servicesToAdd3}
                  total={servicesToAdd3.length}
                  mapping={serviceMapping}
                />
              )}
            </TabPanel>
          </div>
        </>
      }
      dialogActions={
        <Grid container>
          <Grid item sm={3} md={3} style={{ paddingLeft: '16px' }}>
            <Grid container direction="column" justify="center" className={classes.gridnumservices}>
              <Typography className={classes.typonumservices} align="center">
                {servicesToAdd1.length + servicesToAdd2.length}
              </Typography>
              <Typography variant="h6" className={classes.typonumservices2} align="center">
                <FormattedMessage id="plansactivity.table.header.services" />
              </Typography>
            </Grid>
          </Grid>
          <Grid item sm={7} md={7}>
            {resultingQuota() > 0 && (
              <Grid container alignItems="center" className={classes.errorQuota}>
                <FormattedMessage
                  id="plan.servicesdialog.addservices.quota_exceeded"
                  values={{
                    maxServices: projectMaxServices,
                    surplus: resultingQuota(),
                  }}
                />
              </Grid>
            )}
          </Grid>
          <Grid item sm={2} md={2}>
            <Grid container justify="flex-end">
              <Button
                variant="contained"
                style={{ boxShadow: 'none', color: 'white', width: '200px' }}
                disabled={
                  resultingQuota() > 0 || servicesToAdd1.length + servicesToAdd2.length + servicesToAdd3.length <= 0
                }
                onClick={() => {
                  actions.createServicesToAPlan(
                    [...servicesToAdd1, ...servicesToAdd2, ...servicesToAdd3],
                    planId
                  );
                  const via =
                    servicesToAdd3.length > 0
                      ? 'Subir'
                      : servicesToAdd2.length > 0
                        ? 'Desde Clientes'
                        : null;
                  eventsPanel.importServices(via);
                  handleOpenCloseDialog();
                }}
              >
                <FormattedMessage id="plan.servicesdialog.create" />
              </Button>
            </Grid>
          </Grid>
        </Grid>
      }
    />
  );
};

AddServicesDialog.defaultProps = {
  projectMaxServices: undefined,
  planOperationCountry: undefined,
  planServices: 0,
};

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    createServicesToAPlan: (services: any, planId: any) =>
      dispatch(Services.createServicesToAPlan(services, planId)),
  },
});

const mapStateToProps = (state: any) => {
  return {
    projectMaxServices:
      state.projects.activeProject && state.projects.activeProject.limits.max_services_plan,
    planOperationCountry:
      state.activePlan.plan &&
      state.activePlan.plan.optimizer_config &&
      state.activePlan.plan.optimizer_config.operation_country,
    planServices: state.activePlan.plan && state.activePlan.plan.services.length,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AddServicesDialog));
