import { Grid, Paper, Typography } from '@material-ui/core';
import React from 'react';
import { Bar } from 'react-chartjs-2';
import { VSpacer } from '@sm-highway-web/react-components';

type OwnProps = {
  labels?: string[];
  data?: number[];
  title?: string;
  colors?: any; // TODO: PropTypes.oneOf([PropTypes.arrayOf(PropTypes.string), PropTypes.string])
};

type Props = OwnProps;

const Funnel = ({ labels, data, title, colors }: Props) => (
  <Paper>
    <Grid container direction="column">
      <VSpacer medium />
      <Typography variant="h4" align="center">
        {title}
      </Typography>
      <VSpacer medium />
      <Bar
        data={{
          // @ts-expect-error ts-migrate(2569) FIXME: Type 'string[] | undefined' is not an array type o... Remove this comment to see the full error message
          labels: [...labels],
          datasets: [
            {
              // @ts-expect-error ts-migrate(2569) FIXME: Type 'number[] | undefined' is not an array type o... Remove this comment to see the full error message
              data: [...data],
              backgroundColor: colors,
            },
          ],
        }}
        options={{
          scales: {
            yAxes: [
              {
                ticks: {
                  beginAtZero: true,
                },
              },
            ],
          },
          legend: {
            display: false,
          },
        }}
      />
    </Grid>
    <VSpacer medium />
  </Paper>
);

Funnel.defaultProps = {
  labels: [],
  data: [],
  title: undefined,
  colors: [],
};

export default Funnel;
