import { Grid, MenuItem, Popover } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Button, HSpacer } from '@sm-highway-web/react-components';
import { IProjectData } from 'highway-api/dist/common/interfaces/projects';
import { IVehicleData } from 'highway-api/dist/common/interfaces/vehicles';
import React, { useState } from 'react';
import More3Points from '../../assets/svg/More3Points';
import colorsRoutes from '../../constants/Colors';
import { currencyToSymbol, kmToSM, SMToKm } from '../../helpers/Units';
import CustomFields from '../custom-fields/CustomFields';
import FormColorPicker from '../form-fields/FormColorPicker';
import FormIcon from '../form-fields/FormIcon';
import FormText from '../form-fields/FormText';
import FormattedMessage from '../FormattedMessageCustom';
import Block from './Block';
import {
  checkAllR,
  checkR,
  popoverConfig,
  renderArray,
  renderFormText,
  renderFormTextArea,
  renderLocation,
  renderTimewindows,
  styles,
} from './Common';

type RouteHeaderProps = {
  classes: {
    slimbutton: string;
    menuItem: string;
  };
  data: IVehicleData;
  onUpdate?: (...args: any[]) => any;
  options?: {
    handleDeleteVehicle: (...args: any[]) => any;
  },
};

export const VehicleHeader = withStyles(styles)(({ classes, data, onUpdate, options } : RouteHeaderProps) => {
  const [anchorEl, setAnchorEl] = useState(null);
  if (data) {
    return (
      <>
        <Grid container justify="space-between">
          <Grid item container alignItems="center" style={{ width: 'auto' }}>
            <FormIcon
              fieldValue={data.icon}
              onUpdate={(icon: any) => {
                if(onUpdate)onUpdate(data.id, { icon });
              }}
              type="vehicle"
            />
            <HSpacer small />

            <FormColorPicker
              color={data.color}
              presetColors={colorsRoutes}
              onUpdate={(color: any) => {
                if(onUpdate)onUpdate(data.id, { color });
              }}
            />
          </Grid>
          <Grid container alignItems="center" style={{ width: 'auto' }}>
            {options && (
              <Grid item>
                <Button
                  aria-describedby={anchorEl !== null ? 'simple-popover' : undefined}
                  color="secondary"
                  variant="text"
                  className={classes.slimbutton}
                  onClick={(e: any) => setAnchorEl(e.currentTarget)}
                >
                  <More3Points />
                </Button>
                {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
                <Popover
                  id={anchorEl !== null ? 'simple-popover' : undefined}
                  open={Boolean(anchorEl)}
                  anchorEl={anchorEl}
                  onClose={() => setAnchorEl(null)}
                  anchorOrigin={popoverConfig.anchorOrigin}
                  transformOrigin={popoverConfig.transformOrigin}
                >
                  <MenuItem
                    onClick={() => {
                      options?.handleDeleteVehicle();
                      setAnchorEl(null);
                    }}
                    className={classes.menuItem}
                  >
                    <FormattedMessage id="rightpanel.delete" />
                  </MenuItem>
                </Popover>
              </Grid>
            )}
          </Grid>
        </Grid>
      </>
    );
  }
  return null;
});

type VehicleProps = {
  classes: {
    box: string;
  };
  data: IVehicleData;
  onUpdate?: (...args: any[]) => any;
  activeProject: IProjectData;
  countryCode?: string | null;
};

const Vehicle = ({ data, onUpdate, classes, activeProject, countryCode }: VehicleProps) => {
  const handleUpdate = (label: string, val: any) => {
    if (onUpdate) {
      onUpdate(data.id, { [label]: val || null });
    }
  };
  const preffix = 'vehicles.data.details';
  const { custom_fields: customFields, view } = activeProject;
  // @ts-expect-error
  const { constraints, vehicle } = view;
  return (
    <>
      <Grid container className={classes.box}>
        <FormText
          variant="title"
          fieldValue={data.label}
          handleEnter={(val: any) => {
            handleUpdate('label', val);
          }}
        />
        {checkR(vehicle.external_id) &&
          renderFormText(preffix, 'external_id', undefined, handleUpdate, data)}
        {checkR(vehicle.plate) && renderFormText(preffix, 'plate', undefined, handleUpdate, data)}
        {renderFormText(preffix, 'vehicle_model', undefined, handleUpdate, data)}
        {renderFormText(preffix, 'brand', undefined, handleUpdate, data)}
        {renderLocation(preffix, 'default_start_location', handleUpdate, data, countryCode)}
        {renderLocation(preffix, 'default_end_location', handleUpdate, data, countryCode)}
        {checkR(vehicle.price_per_minute) && renderFormText(
          preffix,
          'price_per_minute',
          'number',
          handleUpdate,
          data,
          <FormattedMessage
            tagName="span"
            id="price_per_minute"
            values={{
              currency: currencyToSymbol(activeProject?.units?.currency),
            }}
          />
        )}
        {checkR(vehicle.price_per_distance) && renderFormText(
          preffix,
          'price_per_distance',
          'number',
          handleUpdate,
          data,
          <FormattedMessage
            tagName="span"
            id="price_per_distance"
            values={{
              currency: currencyToSymbol(activeProject?.units?.currency),
              unit: activeProject?.units?.distance,
            }}
          />
        )}
      </Grid>
      {checkAllR(constraints) && (
        <Block title={<FormattedMessage id="clients.details.group.constraints" />}>
          {checkR(constraints.distance) &&
            renderFormText(
              preffix,
              'default_max_distance',
              'number',
              (label: any, val: any) => {
                handleUpdate(label, SMToKm(val, activeProject?.units?.distance) * 1000);
              },
              data,
              activeProject?.units?.distance,
              (value) => (value ? kmToSM(value / 1000, activeProject?.units?.distance) : null)
            )}
          {checkR(constraints.weight) &&
            renderFormText(
              preffix,
              'default_max_weight',
              'number',
              handleUpdate,
              data,
              activeProject?.units?.weight
            )}
          {checkR(constraints.volume) &&
            renderFormText(
              preffix,
              'default_max_volume',
              'number',
              handleUpdate,
              data,
              activeProject?.units?.volume
            )}
          {checkR(constraints.timewindows) &&
            renderTimewindows(preffix, 'default_timewindow', handleUpdate, data, true)}
          {checkR(constraints.provide_requires) &&
            renderArray(preffix, 'default_provides', handleUpdate, data)}
          {checkR(constraints.max_services) &&
            renderFormText(preffix, 'default_max_services', 'integer', handleUpdate, data)}
        </Block>
      )}
      {checkAllR(vehicle) && (
        <Block title={<FormattedMessage id="clients.details.group.contact" />}>
          {checkR(vehicle.phone) && renderFormText(preffix, 'phone', undefined, handleUpdate, data)}
          {checkR(vehicle.email) && renderFormText(preffix, 'email', 'email', handleUpdate, data)}
          {checkR(vehicle.comments) &&
            renderFormTextArea(preffix, 'comments', undefined, handleUpdate, data)}
        </Block>
      )}

      {customFields && customFields.vehicle.length > 0 ? (
        <Block title={<FormattedMessage id="custom_fields.title" />}>
          <CustomFields
            onUpdate={(id: any, value: any) => handleUpdate('custom_fields', { [id]: value })}
            customFields={data.custom_fields}
            projectCustomFields={customFields.vehicle}
          />
        </Block>
      ) : null}
    </>
  );
};

export default withStyles(styles)(Vehicle);
