import { Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Button, VSpacer } from '@sm-highway-web/react-components';
import { ISubscriptionBase } from 'highway-api/dist/common/interfaces/subscriptions';
import moment from 'moment';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import WarningIcon from '../../../assets/svg/WarningIcon';
import { PLAN_TYPE_TO_SHOW } from '../../../constants/Subscriptions';
import ManageSubscriptionDialog from '../../../dialogs/ManageSubscriptionDialog';
import SubscriptionCancelDialog from '../../../dialogs/SubscriptionCancelDialog';
import { planPrice } from '../../../helpers/Plans';
import { Common, Payment } from '../../../redux/actions';
import FormattedMessage from '../../FormattedMessageCustom';

const styles = {
  enclosedDiv: {
    padding: '16px',
    width: '560px',
    border: '1px solid #8194B8',
    borderRadius: '8px',
    backgroundColor: 'rgba(175,192,224,0.05)',
    boxShadow: '0 2px 4px 0 ·#EAEAEA',
  },
  noPaymentDiv: {
    border: '1px solid #FCCA46',
    borderRadius: '8px',
    backgroundColor: 'rgba(248,231,28,0.1)',
    padding: '8px',
  },
};

type OwnBillingSubscriptionsProps = {
  classes: {
    enclosedDiv: string;
    noPaymentDiv: string;
  };
  actions: {
    restoreSubscription: (...args: any[]) => any;
    openPaymentModal: (...args: any[]) => any;
  };
  subscription?: ISubscriptionBase;
};

type BillingSubscriptionsState = any;

type BillingSubscriptionsProps = OwnBillingSubscriptionsProps;

class BillingSubscriptions extends Component<BillingSubscriptionsProps, BillingSubscriptionsState> {
  static defaultProps: any;

  state = {
    subscriptionCancelModal: false,
    subscriptionManageModal: false,
  };

  handleOpenSubscriptionCancel = () => {
    this.setState((prevState: any) => ({
      subscriptionCancelModal: !prevState.subscriptionCancelModal,
    }));
  };

  handleOpenSubscriptionManage = () => {
    this.setState((prevState: any) => ({
      subscriptionManageModal: !prevState.subscriptionManageModal,
    }));
  };

  renderSubscriptionStatus = () => {
    const { subscription } = this.props;

    if (!subscription) {
      return '-';
    }
    if (subscription.status === 'trialing') {
      return 'Trial';
    }
    if (subscription.status === 'active') {
      if (subscription.plan) {
        if (subscription.plan.type === 'monkey') {
          return PLAN_TYPE_TO_SHOW.MONKEY;
        }

        if (subscription.plan.type === 'gorilla') {
          return PLAN_TYPE_TO_SHOW.GORILLA;
        }

        if (subscription.plan.type === 'kong') {
          return PLAN_TYPE_TO_SHOW.KONG;
        }
      }
    }
    return '-';
  };

  handleOpenRunway = () => {
    const { actions } = this.props;
    actions.openPaymentModal();
  };

  render() {
    const { classes, subscription, actions } = this.props;
    const { subscriptionCancelModal, subscriptionManageModal } = this.state;
    return (
      <>
        <Grid container direction="column">
          <Grid item>
            <Typography variant="h2">
              <FormattedMessage id="settings.billing.subscriptions.title" />
            </Typography>
          </Grid>
          <VSpacer />
          {subscription?.status &&
          !['trialing', 'past_due', 'canceled', 'unpaid', 'incomplete_expired'].includes(
            subscription.status
          ) ? (
                <Grid item>
                  <Grid container spacing={4}>
                    <Grid item sm={7}>
                      <Grid container direction="column">
                        <Typography variant="h4">
                          <FormattedMessage id="settings.billing.subscriptions.subtitle1" />
                        </Typography>
                        <VSpacer />
                        <Grid container direction="column" className={classes.enclosedDiv}>
                          <Typography variant="h6">{this.renderSubscriptionStatus()}</Typography>
                          <VSpacer medium />
                          <Grid container>
                            <Grid item sm={1}>
                              <Grid container direction="column">
                                <Typography>
                                  <FormattedMessage id="settings.billing.subscriptions.vehicles" />
                                </Typography>
                                <VSpacer small />
                                <Typography variant="h6" align="center">
                                  {subscription && subscription.plan.vehicles}
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid item sm={2} />
                            <Grid item sm={5}>
                              <Typography>
                                <FormattedMessage id="settings.billing.subscriptions.nextbilldate" />
                              </Typography>
                              <VSpacer small />
                              <Typography variant="h6">
                                {subscription && moment(subscription.valid).format('MMMM DD, YYYY')}
                              </Typography>
                            </Grid>
                            <Grid item sm={4}>
                              <Typography align="right">
                                <FormattedMessage id="settings.billing.subscriptions.priceestimate" />
                              </Typography>
                              <VSpacer small />
                              <Typography variant="h6" align="right">
                                {subscription && subscription.plan && (
                                  <FormattedMessage
                                    id="settings.billing.subscriptions.pricemonth"
                                    values={{
                                      value: planPrice(
                                        subscription.plan.type,
                                        subscription.plan.vehicles
                                      ),
                                    }}
                                  />
                                )}
                              </Typography>
                            </Grid>
                          </Grid>
                          <VSpacer medium />
                          {!subscription.will_cancel && (
                            <Grid container justify="flex-end">
                              <Button onClick={this.handleOpenSubscriptionManage}>
                                <FormattedMessage id="settings.billing.subscriptions.buttonmanage" />
                              </Button>
                            </Grid>
                          )}
                        </Grid>
                        <VSpacer small />
                        <Grid
                          container
                          justify="flex-end"
                          style={{ paddingRight: '16px', width: '560px' }}
                        >
                          {subscription.will_cancel ? (
                            <Grid item>
                              <Grid container direction="column" style={{ color: 'red' }}>
                                <FormattedMessage
                                  id="settings.billing.subscriptions.subscription_cancel"
                                  values={{ date: moment(subscription.valid).format('MMMM DD, YYYY') }}
                                />
                                <Grid item style={{ textAlign: 'right' }}>
                                  <Button
                                    color="secondary"
                                    variant="text"
                                    onClick={actions.restoreSubscription}
                                  >
                                    <FormattedMessage id="settings.billing.subscriptions.buttonrestoresubs" />
                                  </Button>
                                </Grid>
                              </Grid>
                            </Grid>
                          ) : (
                            <Button
                              color="secondary"
                              variant="text"
                              onClick={this.handleOpenSubscriptionCancel}
                            >
                              <FormattedMessage id="settings.billing.subscriptions.buttoncancelsubs" />
                            </Button>
                          )}
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item sm={4} />
                  </Grid>
                </Grid>
              ) : (
                <>
                  <Grid container>
                    <Grid item className={classes.noPaymentDiv} sm={4}>
                      <Grid container alignItems="center">
                        <WarningIcon style={{ float: 'left', marginRight: '16px', color: '#FCCA46' }} />
                        <Typography style={{ float: 'left' }}>
                          <FormattedMessage id="settings.billing.subscriptions.nosubs" />
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>
                  <VSpacer />
                  <Grid container>
                    <Button variant="contained" onClick={this.handleOpenRunway}>
                      <FormattedMessage id="userprofilesummary.upgradeaccount" />
                    </Button>
                  </Grid>
                </>
              )}
        </Grid>

        <SubscriptionCancelDialog
          open={subscriptionCancelModal}
          handleClose={this.handleOpenSubscriptionCancel}
        />
        {subscription && (
          <ManageSubscriptionDialog
            open={subscriptionManageModal}
            handleClose={this.handleOpenSubscriptionManage}
            currentPlan={subscription.plan}
          />
        )}
      </>
    );
  }
}

BillingSubscriptions.defaultProps = {
  subscription: undefined,
};

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    restoreSubscription: () => dispatch(Payment.postSubscriptionRestore()),
    openPaymentModal: () => dispatch(Common.openPaymentModal()),
  },
});

const mapStateToProps = (state: any) => {
  return {
    subscription: state.organization.organization && state.organization.organization.subscription,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(BillingSubscriptions));
