const parseRouteToEdit = (routes: any) => {
  return routes.reduce(
    (prev: any, next: any) => [
      ...prev,
      {
        vehicle_id: next.vehicle_id,
        steps: next.steps.reduce(
          (prevStep: any, nextStep: any) => [
            ...prevStep,
            {
              id: nextStep.id,
              type: nextStep.type,
              location: nextStep.location,
              ...(nextStep.label ? { label: nextStep.label } : {}),
            },
          ],
          []
        ),
      },
    ],
    []
  );
};

const parseMissingToEdit = (missing: any) => {
  return missing.reduce(
    (prev: any, nextPoint: any) => [
      ...prev,
      {
        id: nextPoint.id,
        location: nextPoint.location,
        ...(nextPoint.label ? { label: nextPoint.label } : {}),
      },
    ],
    []
  );
};

const parseSearchablePois = (missing: any, routes: any) => {
  return missing
    .map((m: any) => ({
      label: m.label ? m.label : m.id,
      value: m.id,
      route: 'missing',
    }))
    .concat(
      routes.reduce(
        (pr: any, nr: any, i: any) =>
          pr.concat(
            nr.steps.reduce(
              (ps: any, ns: any) =>
                ns.id
                  ? [
                      ...ps,
                      {
                        label: ns.label ? ns.label : ns.id,
                        value: ns.id,
                        route: i,
                      },
                    ]
                  : ps,
              []
            )
          ) || [],
        []
      )
    );
};

const parseSearchableRoutes = (routes: any) => {
  return routes.map((r: any) => ({
    label: r.label ? r.label : r.id,
    value: r,
  }));
};

export { parseRouteToEdit, parseMissingToEdit, parseSearchablePois, parseSearchableRoutes };
