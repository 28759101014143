import {
  Checkbox,
  Divider,
  Grid,
  Input,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  Switch,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Edit, Send } from '@material-ui/icons';
import {
  Button,
  DataTable,
  DefaultAvatar,
  Dialog,
  InputText,
  VSpacer,
} from '@sm-highway-web/react-components';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import WarningIcon from '../../../assets/svg/WarningIcon';
import { intl } from '../../../helpers/IntGlobalProvider';
import { dateFormat } from '../../../helpers/TimeDistance';
import { Organization } from '../../../redux/actions';
import { projectHelpers } from '../../../redux/helpers';
import Avatar from '../../Avatar';
import FormattedMessage from '../../FormattedMessageCustom';

const styles = {
  select: {
    padding: '8px',
    minHeight: '24px',
    display: 'flex',
    alignItems: 'center',
  },
  selectMenu: {
    '& .MuiMenu-list': {
      padding: '0',
    },
  },
  selectMenuSpan: {
    marginLeft: '8px',
    marginRight: '16px',
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
    width: '100%',
  },
  disabledDiv: {
    padding: '8px',
    border: '1px solid #FCCA46',
    borderRadius: '8px',
    backgroundColor: 'rgba(248,231,28,0.1)',
  },
};

const eventsList = [
  'plan.created',
  `plan.deleted`,
  `plan.updated`,
  `route.created`,
  `route.deleted`,
  `route.started`,
  // `route.track`, // Disabled for the moment
  `route.finished`,
  `service.created`,
  `service.deleted`,
  `service.report`,
  `service.report.completed`,
  `service.report.canceled`,
];

type IntegrationsWebhooksProps = {
  classes: {
    select: string;
    selectMenu: string;
    selectMenuSpan: string;
  };
  actions: {
    getWebhooks: (...args: any[]) => any;
    postWebhook: (...args: any[]) => any;
    deleteWebhook: (...args: any[]) => any;
    updateWebhook: (...args: any[]) => any;
    testWebhook: (...args: any[]) => any;
  };
  webhooks?: {}[];
  projects?: {}[];
  organizationLabel?: string;
  activeProjectId?: string;
};

const IntegrationsWebhooks = ({
  classes,
  activeProjectId,
  actions,
  webhooks,
  projects,
  organizationLabel,
}: IntegrationsWebhooksProps) => {
  const [webhookDialog, setWebhookDialog] = useState(false);
  const [confirmDeleteOrganization, setConfirmDeleteOrganization] = useState('');
  const [testWebhookDialog, setTestWebhookDialog] = useState(false);
  const [isCreate, setIsCreate] = useState(false);
  const [webhookUrl, setWebhookUrl] = useState('');
  const [enabled, setEnabled] = useState(true);
  const [events, setEvents] = useState<string[]>([]);
  const [webhookId, setWebhookId] = useState();
  const [projectId, setProjectId] = useState('');

  useEffect(() => {
    if (activeProjectId) setProjectId(activeProjectId);
  }, [activeProjectId, setProjectId]);

  useEffect(() => {
    if (projectId) actions.getWebhooks(projectId);
  }, [actions, projectId]);

  const closeWebhookDialog = () => {
    setConfirmDeleteOrganization('');
    setWebhookDialog(false);
    setIsCreate(false);
    setWebhookUrl('');
    setEnabled(true);
    setEvents([]);
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
    setWebhookId();
  };

  const createWebhook = () => {
    actions.postWebhook(
      {
        url: webhookUrl,
        enabled,
        enabled_events: events,
      },
      projectId
    );
    closeWebhookDialog();
  };

  const updateWebhook = () => {
    actions.updateWebhook(webhookId, {
      url: webhookUrl,
      enabled,
      enabled_events: events,
    });
    closeWebhookDialog();
  };

  const closeTestWebhookDialog = () => {
    setEvents([eventsList[0]]);
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
    setWebhookId();
    setTestWebhookDialog(false);
  };

  const testWebhook = () => {
    actions.testWebhook(webhookId, events[0]);
    closeTestWebhookDialog();
  };

  const isFormDisabled = () => !webhookUrl || events.length === 0 || !projectId;

  return (
    <Grid>
      <Grid item container style={{ padding: '8px', borderBottom: '1px solid #D7DAE2' }}>
        <Grid item container direction="row" justify="space-between" alignItems="center">
          <Grid item>
            <Typography variant="h2">
              <FormattedMessage id="settings.integrations.webhooks.title" />
            </Typography>
          </Grid>

          <Grid item>
            <Select
              value={projectId}
              variant="outlined"
              classes={{ select: classes.select }}
              style={{ width: '300px', padding: '0' }}
              MenuProps={{
                className: classes.selectMenu,
              }}
              onChange={(evt) => {
                // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
                setProjectId(evt.target.value);
              }}
            >
              {/* @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'. */}
              {projects.map((project: IProjectData) => {
                return (
                  <MenuItem key={project.id} value={project.id}>
                    <DefaultAvatar
                      labelOverlayed
                      key={`avatar${project.id}`}
                      label={`${projectHelpers.getProjectLabel(project.label, organizationLabel)}`}
                      avatar={
                        <Avatar
                          data={project}
                          labelOverlayed
                          label={`${projectHelpers.getProjectLabel(
                            project.label,
                            organizationLabel
                          )}`}
                          size={24}
                        />
                      }
                    />
                    <span className={classes.selectMenuSpan}>
                      {`${projectHelpers.getProjectLabel(
                        project.label,
                        organizationLabel
                      )}`}
                    </span>
                  </MenuItem>
                );
              })}
            </Select>
          </Grid>
        </Grid>
      </Grid>

      <VSpacer />

      {webhooks && webhooks.length === 0 && (
        <Grid>
          <FormattedMessage id="settings.integrations.webhooks.no_webhooks" />
        </Grid>
      )}

      {webhooks && webhooks.length > 0 && (
        <DataTable
          mapping={[
            {
              name: <FormattedMessage id="settings.integrations.webhooks.table.column.url" />,
              computed: (doc: any) => doc.url,
            },
            {
              column: 'enabled_events',
              name: <FormattedMessage id="settings.integrations.webhooks.table.column.events" />,
              computed: (doc: any) => (
                <ul>
                  {doc.enabled_events.map((event: any) => (
                    <li key={`event-${event}`}>{event}</li>
                  ))}
                </ul>
              ),
            },
            {
              column: 'enabled',
              name: (
                <FormattedMessage id="settings.integrations.webhooks.table.column.status" />
              ),
              computed: (doc: any) => (
                <Grid container alignItems="center">
                  <FormattedMessage id={`settings.integrations.webhooks.table.column.${doc.enabled ? 'enabled' : 'disabled' }`} />
                </Grid>
              ),
            },
            {
              column: 'created_at',
              name: (
                <FormattedMessage id="settings.integrations.webhooks.table.column.created_at" />
              ),
              computed: (doc: any) => dateFormat(doc.created_at),
            },
            {
              name: <FormattedMessage id="settings.integrations.webhooks.table.column.actions" />,
              computed: (doc: any) => (
                <Grid>
                  <Button
                    onClick={() => {
                      setWebhookUrl(doc.url);
                      setEnabled(doc.enabled);
                      setEvents(doc.enabled_events);
                      setWebhookId(doc.id);
                      setIsCreate(false);
                      setWebhookDialog(true);
                    }}
                  >
                    <Edit />
                  </Button>
                  <Tooltip
                    title={
                      <FormattedMessage id="settings.integrations.webhooks.test_webhook_body" />
                    }
                  >
                    <Button
                      style={{ marginLeft: '8px' }}
                      onClick={() => {
                        setEvents([doc.enabled_events[0]]);
                        setWebhookId(doc.id);
                        setTestWebhookDialog(true);
                      }}
                    >
                      <Send />
                    </Button>
                  </Tooltip>
                </Grid>
              ),
            },
          ]}
          docs={webhooks}
        />
      )}

      <VSpacer />

      <Button
        onClick={() => {
          setIsCreate(true);
          setWebhookDialog(true);
        }}
      >
        <FormattedMessage id="settings.integrations.webhooks.create_new_webhook_button" />
      </Button>

      {testWebhookDialog && (
        <Dialog
          open={testWebhookDialog}
          onClose={closeTestWebhookDialog}
          onCancel={closeTestWebhookDialog}
          title={<FormattedMessage id="settings.integrations.webhooks.test_webhook_title" />}
        >
          <Grid style={{ minWidth: '300px', padding: '16px' }}>
            <Grid item>
              <FormattedMessage id="settings.integrations.webhooks.test_webhook_body" />
            </Grid>

            <VSpacer large />

            <Grid item>
              <Select
                variant="outlined"
                classes={{ select: classes.select }}
                style={{ width: '300px', padding: '0' }}
                MenuProps={{
                  className: classes.selectMenu,
                }}
                // @ts-expect-error ts-migrate(2322) FIXME: Type 'unknown' is not assignable to type 'never'.
                onChange={(evt) => setEvents([evt.target.value])}
                value={events[0]}
              >
                {eventsList.map((action, idx) => (
                  <MenuItem key={`event-${idx}`} value={action}>
                    <Checkbox style={{ padding: 0 }} checked={events[0] === action} />
                    <span className={classes.selectMenuSpan}>{action}</span>
                  </MenuItem>
                ))}
              </Select>

              <VSpacer large />

              <Grid container direction="row" justify="space-around">
                <Button variant="text" color="secondary" onClick={closeTestWebhookDialog}>
                  <FormattedMessage id="cancel" />
                </Button>

                <Button variant="contained" onClick={testWebhook}>
                  <FormattedMessage id="settings.integrations.webhooks.test_webhook_button" />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Dialog>
      )}

      {webhookDialog && (
        <Dialog
          open={webhookDialog}
          onClose={closeWebhookDialog}
          title={(
            <FormattedMessage
              id={`settings.integrations.webhooks.${isCreate ? 'create' : 'update'}_webhook_title`}
            />
          )}
          onCancel={closeWebhookDialog}
        >
          <Grid style={{ minWidth: '300px', padding: '16px' }}>
            {isCreate ? (
              <Grid item>
                <FormattedMessage id="settings.integrations.webhooks.create_webhook_body" />
              </Grid>
            ) : null}

            <VSpacer small />

            <Grid item container direction="column">
              <Grid item style={{ width: '100%' }}>
                <InputText
                  label={<FormattedMessage id="settings.integrations.webhooks.table.column.url" />}
                  style={{ width: '100%' }}
                  placeholder="https://www.example.com/test"
                  customValidator={(text: any) => {
                    const expression1 = /(https:\/\/|http:\/\/)[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi;
                    const expression2 = /[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_+.~#?&//=]*)?/gi;
                    const regex1 = new RegExp(expression1);
                    const regex2 = new RegExp(expression2);
                    if (text && text.match(regex1)) {
                      return undefined;
                    }
                    if (text && text.match(regex2)) {
                      return (
                        <FormattedMessage id="settings.integrations.webhooks.url_validation_http" />
                      );
                    }
                    return <FormattedMessage id="settings.integrations.webhooks.url_validation" />;
                  }}
                  value={webhookUrl}
                  onChange={(url: any) => setWebhookUrl(url)}
                />
              </Grid>

              <VSpacer small />

              <Grid item style={{ width: '100%' }}>
                <InputLabel>
                  <FormattedMessage id="settings.integrations.webhooks.events" />
                </InputLabel>

                <Select
                  multiple
                  classes={{ select: classes.select }}
                  style={{ width: '100%', padding: '0' }}
                  MenuProps={{
                    className: classes.selectMenu,
                  }}
                  onChange={(evt) => {
                    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
                    setEvents(evt.target.value);
                  }}
                  value={events}
                  // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
                  renderValue={(selected) => selected.join(', ')}
                  input={<Input />}
                >
                  {eventsList.map((action, idx) => (
                    <MenuItem key={`event-${idx}`} value={action}>
                      <Checkbox checked={events.indexOf(action) > -1} />
                      <ListItemText>{action}</ListItemText>
                    </MenuItem>
                  ))}
                </Select>
              </Grid>

              <VSpacer large />

              <Grid item style={{ width: '100%' }}>
                <InputLabel>
                  <FormattedMessage id="settings.integrations.webhooks.enabled" />
                </InputLabel>
                <Switch
                  checked={enabled}
                  onChange={(event) => {
                    const { checked } = event.target;
                    setEnabled(checked);
                  }}
                  color="primary"
                />
                {!enabled && (
                  <>
                    <VSpacer medium />

                    <Grid container>
                      <Grid
                        item
                        container
                        direction="row"
                        alignItems="center"
                        /* @ts-expect-error ts-migrate(2339) FIXME: Property 'disabledDiv' does not exist on type '{ s... Remove this comment to see the full error message */
                        className={classes.disabledDiv}
                      >
                        <Grid item>
                          <WarningIcon
                            style={{ float: 'left', marginRight: '8px', color: '#FCCA46' }}
                          />
                        </Grid>

                        <Grid item>
                          <Typography style={{ float: 'left', fontSize: '12px' }}>
                            <FormattedMessage id="settings.integrations.webhooks.disabled_info" />
                          </Typography>
                        </Grid>
                      </Grid>
                    </Grid>

                    <VSpacer medium />
                  </>
                )}
              </Grid>

              {!isCreate && (
                <>
                  <VSpacer small />

                  <Divider />

                  <VSpacer large />

                  <Grid container direction="column">
                    <Typography style={{ fontSize: '18px' }}>
                      <FormattedMessage id="settings.integrations.webhooks.delete.title" />
                    </Typography>

                    <VSpacer small />

                    <Typography>
                      <FormattedMessage id="settings.integrations.webhooks.delete.info" />
                    </Typography>

                    <VSpacer medium />

                    <Typography style={{ fontWeight: 'bold' }}>
                      <FormattedMessage id="settings.integrations.webhooks.delete.label" />
                    </Typography>

                    <VSpacer small />

                    <InputText
                      id="organization_name"
                      placeholder={intl.formatMessage({
                        id: 'settings.integrations.webhooks.delete.label_ph',
                      })}
                      value={confirmDeleteOrganization}
                      onChange={(value: any) => setConfirmDeleteOrganization(value)}
                      margin="none"
                      validators={[]}
                    />

                    <Button
                      disabled={
                        confirmDeleteOrganization === '' ||
                        confirmDeleteOrganization.toLowerCase() !== 'webhook'
                      }
                      style={{
                        backgroundColor:
                          confirmDeleteOrganization === '' ||
                          confirmDeleteOrganization.toLowerCase() !== 'webhook'
                            ? 'rgba(0, 0, 0, 0.12)'
                            : '#FF2825',
                      }}
                      color="secondary"
                      variant="contained"
                      onClick={async () => {
                        actions.deleteWebhook(webhookId, projectId);
                        closeWebhookDialog();
                      }}
                    >
                      <FormattedMessage id="settings.integrations.webhooks.delete.title" />
                    </Button>
                  </Grid>
                </>
              )}

              <VSpacer large />

              <Grid item container direction="row" justify="space-around">
                <Button variant="text" color="secondary" type="button" onClick={closeWebhookDialog}>
                  <FormattedMessage id="cancel" />
                </Button>

                <Button
                  onClick={() => (isCreate ? createWebhook() : updateWebhook())}
                  variant="contained"
                  type="submit"
                  disabled={isFormDisabled()}
                >
                  <FormattedMessage
                    id={`settings.integrations.webhooks.${
                      isCreate ? 'create' : 'update'
                    }_webhook_button`}
                  />
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Dialog>
      )}
    </Grid>
  );
};

IntegrationsWebhooks.defaultProps = {
  webhooks: undefined,
  projects: [],
  organizationLabel: '',
  activeProjectId: undefined,
};

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    getWebhooks: (projectId: any) => dispatch(Organization.getWebhooks(projectId)),
    postWebhook: (webhook: any, projectId: any) =>
      dispatch(Organization.postWebhook(webhook, projectId)),
    deleteWebhook: (webhookId: any, projectId: any) =>
      dispatch(Organization.deleteWebhook(webhookId, projectId)),
    updateWebhook: (webhookId: any, webhookData: any) =>
      dispatch(Organization.updateWebhook(webhookId, webhookData)),
    testWebhook: (webhookId: any, eventType: any) =>
      dispatch(Organization.testWebhook(webhookId, eventType)),
  },
});

const mapStateToProps = (state: any) => {
  return {
    webhooks: state.organization && state.organization.webhooks,
    projects: projectHelpers.orderProjects(state.projects.projects),
    organizationLabel: state.organization.organization && state.organization.organization.label,
    activeProjectId: state.projects.activeProject ? state.projects.activeProject.id : undefined,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ select: { padding: string; min... Remove this comment to see the full error message
)(withStyles(styles)(IntegrationsWebhooks));
