import constants from '../constants/index';

const initialState = {
  currentTutorial: {
    id: undefined,
    step: 0,
  },
  isActive: false,
};

function tutorial(state = initialState, action: any) {
  switch (action.type) {
    case constants.Tutorial.TUTORIAL_INITIALIZE:
      return { ...state, currentTutorial: action.payload, isActive: true };
    case constants.Tutorial.TUTORIAL_SET_STEP:
      return { ...state, currentTutorial: action.payload };
    case constants.Tutorial.TUTORIAL_END:
      return { ...state, currentTutorial: initialState };
    default:
      return state;
  }
}

export default tutorial;
