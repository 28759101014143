export function hasCookie() {
  return (
    document.cookie
      .split(';')
      .map((s) => s.trim())
      .filter((s) => s.startsWith('tokenHW=') && s.length > 6).length > 0
  );
}

export function cookieToken() {
  if (hasCookie()) {
    return document.cookie
      .split(';')
      .map((s) => s.trim())
      .filter((s) => s.startsWith('tokenHW=') && s.length > 6)[0]
      .substring('tokenHW='.length);
  }
  return undefined;
}

export function removeCookie() {
  document.cookie = 'tokenHW=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
}

export function setCookie(name: any, value: any, days: any) {
  let expires = '';
  if (days) {
    const date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = `; expires='${date.toUTCString()}`;
  }
  document.cookie = `${name}=${value || ''}${expires}; path=/`;
}

export const getAsUser = () => {
  return localStorage.getItem('AS_USER');
};

export const saveAsUser = (id: string) => {
  localStorage.setItem('AS_USER', id);
};

export const removeAsUser = () => {
  localStorage.removeItem('AS_USER');
};
