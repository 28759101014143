export const TAX_VALUE = 21;

export const planPrice = (plan: any, vehicleNumber: any) => {
  const planMap = {
    monkey: 15,
    gorilla: 35,
    kong: 45,
  };
  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
  const value = (planMap[plan] || 0) * vehicleNumber;
  return value.toFixed(2);
};

export const priceTaxes = (price: any, tax: any) => {
  let value = parseFloat(price);
  if (tax) {
    value *= tax / 100;
  }
  return value.toFixed(2);
};

export const priceWithCoupon = (price: any, coupon: any) => {
  let value = parseFloat(price);
  if (coupon) {
    if (coupon.percent_off) {
      value *= 1 - coupon.percent_off / 100;
    } else if (coupon.amount_off) {
      value -= coupon.amount_off;
    }
  }
  return value.toFixed(2);
};

export const totalPrice = (plan: any, vehicleNumber: any, tax: any, coupon: any) => {
  let value = parseFloat(planPrice(plan, vehicleNumber));
  if (tax) {
    value += parseFloat(priceTaxes(value, tax));
  }
  if (coupon) {
    value = parseFloat(priceWithCoupon(value, coupon));
  }
  return value.toFixed(2);
};
