import { Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  Form,
  FormCheckbox,
  FormControl,
  InputText,
  VSpacer,
} from '@sm-highway-web/react-components';
// @ts-expect-error ts-migrate(7016) FIXME: Could not find a declaration file for module 'mate... Remove this comment to see the full error message
import InputPhoneNumber from 'material-ui-phone-number';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import logoblack from '../assets/img/logo_black.svg';
import * as routing from '../constants/Routing';
import history from '../helpers/History';
import { IUserLocation } from '../interfaces/common';
import { IUserData } from '../interfaces/users';
import { InfoError, User } from '../redux/actions';
import { acceptOrganizationUserInvitation, getOrganizationInvitation } from '../services/requests';
import FormattedMessage from './FormattedMessageCustom';

const styles = {
  buttons: {
    width: '120px',
  },
};

type AcceptInvitationProps = {
  classes: {
    buttons: string;
  };
  actions: {
    login: (...args: any[]) => any;
    sendStatusToSnackbar: (...args: any[]) => any;
  };
  token?: string;
  userLocation?: IUserLocation;
};

interface IInvitationData extends IUserData {
  // eslint-disable-next-line camelcase
  organization_id: string;
  // eslint-disable-next-line camelcase
  organization_label: string;
}

const AcceptInvitation = ({ token, userLocation, actions, classes }: AcceptInvitationProps) => {
  const [invitationData, setInvitationData] = useState<IInvitationData | undefined>();

  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  const [phoneNumber, setPhoneNumber] = useState('');
  const [password, setPassword] = useState('');
  const [terms, setTerms] = useState(false);
  const [country, setCountry] = useState(userLocation ? userLocation.country : undefined);

  const [disabledRegisterButton, setDisabledRegisterButton] = useState(false);

  useEffect(() => {
    if (userLocation) {
      setCountry(userLocation.country);
    }
  }, [userLocation]);

  useEffect(() => {
    if (token) {
      (async () => {
        const response = await getOrganizationInvitation(token);
        if (response) {
          if (response.first_name) setFirstName(response.first_name);
          if (response.last_name) setLastName(response.last_name);
          setInvitationData(response);
        } else {
          actions.sendStatusToSnackbar('infoerror.get_invitation_fail', 'error');
          history.push(`${routing.LOGIN}`);
        }
      })();
    }
  }, [token, actions]);

  const handleAcceptValidation = async () => {
    setDisabledRegisterButton(true);

    const response = await acceptOrganizationUserInvitation(token, {
      password,
      first_name: firstName,
      last_name: lastName,
      phone: phoneNumber,
    });

    if (response) {
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      actions.login(invitationData.email.toLowerCase(), password);
    } else {
      actions.sendStatusToSnackbar('infoerror.accept_invitation_fail', 'error', 4000);
    }

    setDisabledRegisterButton(false);
  };

  if (!invitationData) return null;

  return (
    <Grid container direction="column" justify="space-between">
      <Grid container justify="center">
        <img src={logoblack} alt="" />
      </Grid>

      <VSpacer small />

      <Grid item>
        <Typography variant="h2" align="center">
          <FormattedMessage id="login.register" />
        </Typography>

        <VSpacer medium />

        <Typography align="center">
          <FormattedMessage
            id="login.accept_invitation_info"
            values={{
              email: invitationData?.email,
              company: invitationData?.organization_label,
            }}
          />
        </Typography>
      </Grid>

      <Form lazyValidation onSubmit={handleAcceptValidation} direction="column">
        <InputText
          id="password"
          type="password"
          label={<FormattedMessage id="login.password" />}
          value={password}
          onChange={(value: any) => setPassword(value)}
          margin="none"
          validators={['required']}
          customValidator={(text: any) => {
            if (text.length < 8) {
              return <FormattedMessage id="password.errorhint" />;
            }
            return undefined;
          }}
        />
        <InputText
          id="first_name"
          type="text"
          label={<FormattedMessage id="register.first_name" />}
          value={firstName}
          onChange={(value: any) => setFirstName(value)}
          margin="none"
          validators={['required']}
          errorMessages={[
            <FormattedMessage key="register.errormandatory" id="register.errormandatory" />,
          ]}
        />
        <InputText
          id="last_name"
          type="text"
          label={<FormattedMessage id="register.last_name" />}
          value={lastName}
          onChange={(value: any) => setLastName(value)}
          margin="none"
          validators={['required']}
          errorMessages={[
            <FormattedMessage key="register.errormandatory2" id="register.errormandatory" />,
          ]}
        />
        <InputPhoneNumber
          name="phoneNumber"
          label={<FormattedMessage id="register.phone" />}
          defaultCountry={country ? country.toLowerCase() : undefined}
          value={phoneNumber}
          onChange={(value: any) => setPhoneNumber(value)}
        />
        <VSpacer />
        <FormCheckbox
          checked={terms}
          onChange={(value: any) => setTerms(value)}
          labelComponent={
            <Typography>
              <FormattedMessage
                id="register.termscond"
                values={{
                  a: (chunks: any) => (
                    <a
                      href="https://smartmonkey.io/terms"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {chunks}
                    </a>
                  ),
                }}
              />
            </Typography>
          }
          validators={['isTrue']}
          errorMessages={[
            <FormattedMessage key="register.errortermscond" id="register.errortermscond" />,
          ]}
        />
        <VSpacer small />

        <FormControl alignItems="center" direction="row" justify="space-between">
          <Button
            type="submit"
            className={classes.buttons}
            variant="contained"
            disabled={disabledRegisterButton}
          >
            <FormattedMessage id="login.register" />
          </Button>
        </FormControl>
      </Form>
    </Grid>
  );
};

AcceptInvitation.defaultProps = {
  token: undefined,
  userLocation: undefined,
};

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    login: (email: any, password: any) => dispatch(User.logIn(email, password)),
    sendStatusToSnackbar: (message: any, type: any) =>
      dispatch(InfoError.sendStatusToSnackbar(message, type)),
  },
});

const mapStateToProps = (state: any) => {
  return {
    userLocation: state.user.userLocation,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AcceptInvitation));
