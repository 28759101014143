import { createHighway } from 'highway-api';
import { getAsUser } from '../helpers/Cookies';
import configuration from './configuration';

const getHighway = (token: any) => {
  const asUser = getAsUser();
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'undefined' is not assignable to ... Remove this comment to see the full error message
  return createHighway(undefined, {
    bearer: token,
    apiEndpoint: configuration.HIGHWAY_BACK_END,
    ...(asUser ? { queryParams: { as_user: asUser } } : {}),
  });
};

export default getHighway;
