import { Chip, Grid, Tooltip, Typography } from '@material-ui/core';
import { Lock, ReportProblemOutlined } from '@material-ui/icons';
import { IProjectData } from 'highway-api/dist/common/interfaces/projects';
import { IRouteDataExtended, IRouteStatus } from 'highway-api/dist/common/interfaces/routes';
import React from 'react';
import VehicleIcons from '../../../assets/svg/VehicleIcons';
import { getRouteColor } from '../../../constants/Colors';
import ROUTE_STATUS from '../../../constants/Routes';
import { parseSecondsToHM, parseSecondsToHMText } from '../../../helpers/TimeDistance';
import { kmToSM } from '../../../helpers/Units';
import { getTotalPrice } from '../../../helpers/Vehicles';
import BrokenConstraintsTooltip from '../../BrokenConstraintsTooltip';
import CustomFieldFormBoolean from '../../custom-fields/CustomFieldFormBoolean';
import CustomFieldFormCategorical from '../../custom-fields/CustomFieldFormCategorical';
import CustomFieldFormText from '../../custom-fields/CustomFieldFormText';
import FormattedMessage from '../../FormattedMessageCustom';

type ITableColumns = {
  [key: string]: boolean;
};

type IColumnsResponse = { [key: string]: any }[];

const getColumns = (
  activeProject: IProjectData,
  planRoutes: IRouteDataExtended[],
  tableColumns: ITableColumns,
  onOpenRightPanel?: (...args: any[]) => any
): IColumnsResponse => {
  const columns = [];

  for (const key in tableColumns) {
    if (tableColumns.hasOwnProperty(key) && tableColumns[key] === true) {
      switch (key) {
        case 'icon':
          columns.push({
            width: 68,
            column: 'icon',
            align: 'center',
            name: <FormattedMessage id="planoverview.route.table.header.type" />,
            formatter: (icon: any, doc: any, index: any) => {
              const Icon = doc && VehicleIcons[icon] ? VehicleIcons[icon] : VehicleIcons.truck;
              return (
                <Grid container direction="row" alignItems="center" justify="center">
                  {doc.is_locked && (
                    <Tooltip
                      title={
                        <FormattedMessage id="planoverview.route.table.header.locked_tooltip" />
                      }
                    >
                      <Lock
                        style={{
                          fontSize: '18px',
                          marginLeft: '-24px',
                          marginRight: '6px',
                          color: '#56637b',
                        }}
                      />
                    </Tooltip>
                  )}
                  <Icon style={{ color: getRouteColor(doc, planRoutes) }} />
                </Grid>
              );
            },
          });
          break;
        case 'label':
          columns.push({
            width: 250,
            column: 'label',
            name: <FormattedMessage id="planoverview.route.table.header.label" />,
          });
          break;
        case 'alerts':
          columns.push({
            width: 68,
            column: 'alerts',
            name: <FormattedMessage id="planoverview.route.table.header.alerts" />,
            computed: (doc: IRouteDataExtended) => doc,
            formatter: (doc: IRouteDataExtended) =>
              <BrokenConstraintsTooltip type="route" route={doc}>
                <ReportProblemOutlined style={{ textAlign: 'center', color: 'orange', fontSize: '24px' }} />
              </BrokenConstraintsTooltip>,
          });
          break;
        case 'status':
          columns.push({
            width: 135,
            column: 'status',
            name: <FormattedMessage id="planoverview.route.table.header.status" />,
            formatter: (status: IRouteStatus) => (
              status && (
                <Grid container alignItems="center">
                  <Chip
                    style={{
                      backgroundColor:
                        status === ROUTE_STATUS.notStarted
                          ? '#eff0f3'
                          : status === ROUTE_STATUS.inTransit
                            ? '#ffc07e'
                            : '#cbfeb2',
                      fontSize: '10px',
                    }}
                    label={<FormattedMessage id={`route.status.${status}`} />}
                  />
                </Grid>
              )
            ),
          });
          break;
        case 'services':
          columns.push({
            width: 80,
            column: 'services',
            align: 'center',
            name: <FormattedMessage id="planoverview.route.table.header.services" />,
            computed: (doc: any) => doc.services.length,
          });
          break;
        case 'start_location':
          columns.push({
            width: 240,
            column: 'start_location',
            name: <FormattedMessage id="planoverview.route.table.header.start_location" />,
            computed: (doc: IRouteDataExtended) => doc,
            formatter: (doc: IRouteDataExtended) =>
              doc.start_location && (
                <Tooltip title={doc.start_location.label}>
                  <Typography noWrap style={{ maxWidth: '240px' }}>
                    {doc.start_location.label}
                  </Typography>
                </Tooltip>
              ),
          });
          break;
        case 'timewindow':
          if (activeProject?.view?.constraints?.timewindows) {
            columns.push({
              width: 135,
              column: 'timewindow',
              align: 'center',
              name: <FormattedMessage id="planoverview.route.table.header.timewindows" />,
              formatter: (doc: any) => {
                if (doc && doc.length > 0) {
                  return (
                    <Chip
                      key={doc[0] + doc[1]}
                      label={`${parseSecondsToHM(doc[0])}-${parseSecondsToHM(doc[1])}`}
                    />
                  );
                }
                return null;
              },
            });
          }
          break;
        case 'duration':
          columns.push({
            width: 100,
            column: 'duration',
            align: 'center',
            name: <FormattedMessage id="planoverview.route.table.header.route_time" />,
            computed: (doc: IRouteDataExtended) => doc,
            formatter: (doc: IRouteDataExtended) =>
              (doc.planned_end_time !== undefined && doc.planned_start_time !== undefined) ? `${parseSecondsToHMText(doc.planned_end_time - doc.planned_start_time)}` : '',
          });
          break;

        case 'total_distance':
          columns.push({
            width: 110,
            column: 'total_distance',
            align: 'center',
            name: <FormattedMessage id="planoverview.route.table.header.total_distance" />,
            computed: (doc: IRouteDataExtended) => doc,
            formatter: (doc: IRouteDataExtended) =>
              doc && activeProject?.units
                ? `${kmToSM(
                    (doc.services.reduce(
                      (ps: any, ns: any) => ps + (parseInt(ns.distance_to_next_location, 10) || 0),
                      0
                    ) || 0) / 1000,
                    activeProject?.units?.distance
                  ).toFixed(1)} ${activeProject?.units?.distance}`
                : null,
          });
          break;

        case 'max_weight':
          if (activeProject?.view?.constraints?.weight) {
            columns.push({
              width: 110,
              column: 'max_weight',
              align: 'center',
              name: <FormattedMessage id="planoverview.route.table.header.max_weight" />,
            });
          }
          break;

        case 'max_volume':
          if (activeProject?.view?.constraints?.volume) {
            columns.push({
              width: 110,
              column: 'max_volume',
              align: 'center',
              name: <FormattedMessage id="planoverview.route.table.header.max_volume" />,
            });
          }
          break;
        case 'total_price':
          columns.push({
            width: 110,
            column: 'total_price',
            align: 'center',
            name: <FormattedMessage id="planoverview.route.table.header.total_price" />,
            computed: (doc: IRouteDataExtended) => doc,
            formatter: (doc: IRouteDataExtended) => getTotalPrice(doc, activeProject),
          });
          break;
        case 'external_id':
        case 'plate':
        case 'email':
        case 'phone':
        case 'webpage':
        case 'comments':
          if (activeProject?.view?.vehicle[key]) {
            columns.push({
              width: 110,
              column: key,
              align: 'center',
              name: <FormattedMessage id={`planoverview.route.table.header.${key}`} />,
              ...(key === 'comments'
                ? {
                    formatter: (comments: string) =>
                      comments && (
                        <Tooltip title={comments}>
                          <Typography noWrap style={{ maxWidth: '110px' }}>
                            {comments}
                          </Typography>
                        </Tooltip>
                      ),
                  }
                : {}),
            });
          }
          break;
        case 'custom_fields':
          if (activeProject?.custom_fields?.vehicle) {
            activeProject?.custom_fields?.vehicle.forEach((projectField: any) => {
        
              columns.push({
                width: 160,
                column: `custom_field-${projectField.id}`,
                align: 'center',
                name: projectField.label,
                computed: (doc: IRouteDataExtended) => doc,
                formatter: (doc: IRouteDataExtended) => {
                  if(doc && doc.custom_fields) {            
                    const customField = (doc.custom_fields as { [key: string]: any })[projectField.id];
                    switch (projectField.type) {
                      case 'text':
                      case 'numerical':
                        return (
                          <CustomFieldFormText
                            key={projectField.id}
                            projectField={projectField}
                            fieldValue={customField || ''}
                            readOnly
                            onlyValues
                          />
                        );
                      case 'boolean':
                        return (
                          <CustomFieldFormBoolean
                            key={projectField.id}
                            projectField={projectField}
                            fieldValue={customField || false}
                            readOnly
                            onlyValues
                          />
                        );
                      case 'categorical':
                        if(customField && projectField.multiple) {
                          return (
                            <span
                              style={{
                                cursor: 'pointer',
                                color: '#FF8200',
                                fontFamily: 'Work Sans',
                                border: 'none',
                              }}
                              onClick={e => {
                                if(onOpenRightPanel) {
                                  onOpenRightPanel(e, doc);
                                }
                              }}
                            >
                              <FormattedMessage id="planoverview.route.table.open_in_right_panel_to_see" />
                            </span>
                          );
                        }
                        return (
                          <CustomFieldFormCategorical
                            key={projectField.id}
                            projectField={projectField}
                            fieldValue={customField || []}
                            readOnly
                            onlyValues
                          />
                        );
                      default:
                        return null;
                    }
                  }
                  return null;
                },
              });
            });
          }
          break;
        default:
          break;
      }
    }
  }

  return columns;
};

export default getColumns;
