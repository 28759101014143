import { Menu, MenuItem } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { IServiceDataExtended } from 'highway-api/dist/common/interfaces/services';
import React, { useState } from 'react';
import { connect } from 'react-redux';
// Dialogs
import ConfirmDeleteDialog from '../../dialogs/ConfirmDeleteDialog';
import InteractServicesDialog from '../../dialogs/InteractServicesDialog';
import { Routes, Services } from '../../redux/actions';
import FormattedMessage from '../FormattedMessageCustom';
import PopoverMore3Points from '../PopoverMore3Points';

const styles = {
  rootMenu: {
    width: '240px',
  },
  menuItem: {
    height: '42px',
  },
};

type OwnServiceMenuProps = {
  classes: {
    rootMenu: string;
    menuItem: string;
  };
  type?: 'contextual' | 'popover';
  plan: any;
  service: any;
  contextAnchor?:
    | {
        screenPosition: {
          top: number;
          left: number;
        };
        doc: IServiceDataExtended;
      }
    | undefined;
  actions: {
    deleteService: (...args: any[]) => any;
    saveServiceAsClient: (...args: any[]) => any;
    updateRoute: (...args: any[]) => any;
  };
  setContextAnchor?: (...args: any[]) => any;
  setRightPanelVisible?: (visible: boolean) => void;
  showSaveAsClient?: boolean;
  showAssignToRoute?: boolean;
  showViewDetails?: boolean;
  showDeleteService?: boolean;
};

type ServiceMenuProps = OwnServiceMenuProps;

const ServiceMenu = ({
  classes,
  type,
  plan,
  service,
  contextAnchor,
  setContextAnchor,
  actions,
  setRightPanelVisible,
  showSaveAsClient,
  showAssignToRoute,
  showViewDetails,
  showDeleteService,
}: ServiceMenuProps) => {
  const [dialog, setDialog] = useState<string | undefined>(undefined);

  return (
    <>
      {type === 'popover' && (
        <>
          <PopoverMore3Points
            idButton="servicepopover"
            round
            arrayActions={[
              ...(!showSaveAsClient
                ? []
                : [
                    {
                      close: true,
                      disabled: Boolean(service.client_id),
                      onClick: () => {
                        actions.saveServiceAsClient(service);
                      },
                      formattedMessage: 'services.actions.saveasclient',
                    },
                  ]),
              ...(!showAssignToRoute
                ? []
                : [
                    {
                      close: true,
                      onClick: () => {
                        setDialog('assignServices');
                      },
                      formattedMessage: 'services.actions.move_services_to_route',
                    },
                  ]),
              ...(!showViewDetails
                ? []
                : [
                    {
                      close: true,
                      onClick: () => {
                        if(setRightPanelVisible) {
                          setRightPanelVisible(true);
                        }
                      },
                      formattedMessage: 'services.actions.view_details',
                    },
                  ]),
              ...(!showDeleteService
                ? []
                : [
                    {
                      close: true,
                      onClick: () => {
                        setDialog('deleteService');
                      },
                      formattedMessage: 'services.actions.delete_service',
                    },
                  ]),
            ]}
          />
        </>
      )}

      {type === 'contextual' && (
        <>
          {contextAnchor && (
            <Menu
              onClose={() => {
                if(setContextAnchor) {
                  setContextAnchor(undefined);
                }
              }}
              onContextMenu={(e) => {
                e.preventDefault();
                
                if(setContextAnchor) {
                  setContextAnchor(undefined);
                }
              }}
              open={Boolean(contextAnchor)}
              anchorReference="anchorPosition"
              anchorPosition={contextAnchor && contextAnchor.screenPosition}
              classes={{ list: classes.rootMenu }}
            >
              {showSaveAsClient && (
                <MenuItem
                  className={classes.menuItem}
                  disabled={Boolean(service.client_id)}
                  onClick={(e) => {
                    e.stopPropagation();
                    actions.saveServiceAsClient(service);
                    
                    if(setContextAnchor) {
                      setContextAnchor(undefined);
                    }
                  }}
                >
                  <FormattedMessage id="services.actions.saveasclient" />
                </MenuItem>
              )}

              {showAssignToRoute && (
                <MenuItem
                  className={classes.menuItem}
                  onClick={(e) => {
                    e.stopPropagation();
                    setDialog('assignServices');
                  }}
                >
                  <FormattedMessage id="services.actions.move_services_to_route" />
                </MenuItem>
              )}

              {showViewDetails && (
                <MenuItem
                  className={classes.menuItem}
                  onClick={(e) => {
                    e.stopPropagation();
                    
                    if(setRightPanelVisible) {
                      setRightPanelVisible(true);
                    }
                    
                    if(setContextAnchor) {
                      setContextAnchor(undefined);
                    }
                  }}
                >
                  <FormattedMessage id="services.actions.view_details" />
                </MenuItem>
              )}

              {showDeleteService && (
                <MenuItem
                  className={classes.menuItem}
                  onClick={(e) => {
                    e.stopPropagation();
                    setDialog('deleteService');
                  }}
                >
                  <FormattedMessage id="services.actions.delete_service" />
                </MenuItem>
              )}
            </Menu>
          )}
        </>
      )}

      <ConfirmDeleteDialog
        typeData="services"
        open={dialog === 'deleteService'}
        setDialog={setDialog}
        data={service}
        handleDelete={() => {
          setDialog(undefined);
          
          if(setContextAnchor) {
            setContextAnchor(undefined);
          }

          if(setRightPanelVisible) {
            setRightPanelVisible(false);
          }
          
          actions.deleteService(service);
        }}
      />

      <InteractServicesDialog
        type="assign"
        open={dialog === 'assignServices'}
        routes={plan && plan.routes}
        setDialog={setDialog}
        handleInteractServices={(destinationRoute: any) => {
          setDialog(undefined);
          
          if(setContextAnchor) {
            setContextAnchor(undefined);
          }
          
          if (destinationRoute === 'missing') {
            const originalRoute = plan.routes.find((route: any) => route.id === service.route_id);
            const routeNewServices = originalRoute.services
              .filter((routeService: any) => routeService.id !== service.id)
              .map((s: any) => s.id);
            actions.updateRoute(originalRoute.id, { services: routeNewServices });
          } else {
            const currentRouteServices = destinationRoute.services.map(
              (routeService: any) => routeService.id
            );
            if (!currentRouteServices.includes(service.id)) {
              actions.updateRoute(destinationRoute.id, {
                services: [...currentRouteServices, ...[service.id]],
              });
            }
          }
        }}
      />
    </>
  );
};

ServiceMenu.defaultProps = {
  type: 'contextual',
  contextAnchor: {
    screenPosition: {
      top: 0,
      left: 0,
    },
  },
  setContextAnchor: () => {},
  setRightPanelVisible: () => {},
  showSaveAsClient: true,
  showAssignToRoute: true,
  showViewDetails: true,
  showDeleteService: true,
};

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    deleteService: (service: any) => dispatch(Services.deleteServices([service])),
    saveServiceAsClient: (service: any) => dispatch(Services.saveServiceAsClient(service)),
    updateRoute: (routeId: any, services: any) => dispatch(Routes.updateRoute(routeId, services)),
  },
});

// @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ ({ classes, type, plan, servic... Remove this comment to see the full error message
export default connect(null, mapDispatchToProps)(withStyles(styles)(ServiceMenu));
