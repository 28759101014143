const BASE64_MARKER = ';base64,';

// TODO: MAYBE AIXÒ HA D'ANAR A FILES!!!
export const convertDataURIToBinary = (dataURI: any) => {
  const base64Index = dataURI.indexOf(BASE64_MARKER) + BASE64_MARKER.length;
  const base64 = dataURI.substring(base64Index);
  const raw = window.atob(base64);
  const rawLength = raw.length;
  const array = new Uint8Array(new ArrayBuffer(rawLength));

  for (let i = 0; i < rawLength; i += 1) {
    array[i] = raw.charCodeAt(i);
  }
  return array;
};

export const UPLOAD_TYPES = {
  ORGANIZATION: 'organization-avatar',
  USER: 'profile-avatar',
  PROJECT: 'project-avatar',
  SERVICE_REPORT: 'service-report-image',
};

export const generateAttachmentName = (type: any) => {
  return `${type}_${new Date().getTime()}.jpg`;
};

export const MAX_AVATAR_IMAGE_SIZE = 1048576; // 1MB (1024 x 1024)

export const MAX_ATTACHMENT_IMAGE_SIZE = 1048576; // 1MB (1024 x 1024)

/**
 * Checks if the image must be resized or not. Resizes it if needed using canvas.
 * @param {*} file
 * @param {*} maxSize
 */
export async function resizeFile(file: any, maxSize: any) : Promise<Uint8Array> {
  // MUST/COULD THIS FUNCTION RETURN A REJECT??
  return new Promise((resolve: any, reject: any) => {
    if (file.size > maxSize) {
      const img = new Image();
      const compressFactor = maxSize / file.size;
      const urlCreator = window.URL || window.webkitURL;

      img.onload = () => {
        const canvas = document.createElement('canvas');
        const ctx = canvas.getContext('2d');

        if (img.height > img.width) {
          // Resizing the image width with the compression factor.
          canvas.height = img.height * compressFactor;

          // set size proportional to image
          canvas.width = canvas.height * (img.width / img.height);
        } else {
          // Resizing the image width with the compression factor.
          canvas.width = img.width * compressFactor;

          // set size proportional to image
          canvas.height = canvas.width * (img.height / img.width);
        }

        // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
        ctx.drawImage(img, 0, 0, canvas.width, canvas.height);

        // Converting to data:base64
        const data = canvas.toDataURL(file.type);

        // Converting to binary Uint8Array
        const binaryFile = convertDataURIToBinary(data);

        resolve(binaryFile);
      };
      img.src = urlCreator.createObjectURL(file);
    } else {
      resolve(file);
    }
  });
}

const createImage = (url: string) : Promise<HTMLImageElement> =>
  new Promise((resolve, reject) => {
    const image = new Image();
    image.addEventListener('load', () => resolve(image));
    image.addEventListener('error', error => reject(error));
    image.setAttribute('crossOrigin', 'anonymous'); // needed to avoid cross-origin issues on CodeSandbox
    image.src = url;
  });

function getRadianAngle(degreeValue: number) {
  return (degreeValue * Math.PI) / 180;
}

/**
 * This function was adapted from the one in the ReadMe of https://github.com/DominicTobias/react-image-crop
 * @param {File} image - Image File url
 * @param {Object} pixelCrop - pixelCrop Object provided by react-easy-crop
 * @param {number} rotation - optional rotation parameter
 */
export type IArea = {
  width: number;
  height: number;
  x: number;
  y: number;
};

export default async function getCroppedImg(imageSrc: string, pixelCrop: IArea, imageType?: string, rotation = 0) {
  const image = await createImage(imageSrc);
  const canvas = document.createElement('canvas');
  const ctx = canvas.getContext('2d') as any;

  const maxSize = Math.max(image.width, image.height);
  const safeArea = 2 * ((maxSize / 2) * Math.sqrt(2));

  // set each dimensions to double largest dimension to allow for a safe area for the
  // image to rotate in without being clipped by canvas context
  canvas.width = safeArea;
  canvas.height = safeArea;

  // translate canvas context to a central location on image to allow rotating around the center.
  ctx.translate(safeArea / 2, safeArea / 2);
  ctx.rotate(getRadianAngle(rotation));
  ctx.translate(-safeArea / 2, -safeArea / 2);

  // draw rotated image and store data.
  ctx.drawImage(
    image,
    safeArea / 2 - image.width * 0.5,
    safeArea / 2 - image.height * 0.5
  );
  const data = ctx.getImageData(0, 0, safeArea, safeArea);

  // set canvas width to final desired crop size - this will clear existing context
  canvas.width = pixelCrop.width;
  canvas.height = pixelCrop.height;

  // paste generated rotate image with correct offsets for x,y crop values.
  ctx.putImageData(
    data,
    Math.round(0 - safeArea / 2 + image.width * 0.5 - pixelCrop.x),
    Math.round(0 - safeArea / 2 + image.height * 0.5 - pixelCrop.y)
  );

  // As Base64 string
  // return canvas.toDataURL('image/jpeg');

  // As a blob
  return new Promise(resolve => {
    canvas.toBlob(file => {
      resolve(file);
    }, imageType ?? 'image/jpeg');
  });
}
