import { Grid, Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Button, HSpacer } from '@sm-highway-web/react-components';
import { IProjectData } from 'highway-api/dist/common/interfaces/projects';
import { IServiceDataExtended } from 'highway-api/dist/common/interfaces/services';
import React from 'react';
import { connect } from 'react-redux';
import DeliveryIcon from '../../assets/svg/DeliveryIcon';
import PickupIcon from '../../assets/svg/PickupIcon';
import { SERVICE_STATUS } from '../../constants/Services';
import { ActivePlan } from '../../redux/actions';
import CustomFields from '../custom-fields/CustomFields';
import FormIcon from '../form-fields/FormIcon';
import FormText from '../form-fields/FormText';
import FormattedMessage from '../FormattedMessageCustom';
import Block from './Block';
import {
  checkR,
  renderArray,
  renderCheckbox,
  renderFormText,
  renderFormTextArea,
  renderInfo,
  renderLinkedClient,
  renderLocation,
  renderMinutes,
  renderPickup,
  renderTimewindows,
  styles,
} from './Common';
import Feedback from './Feedback';

type PickupProps = {
  classes: {
    box: string;
  };
  publicKey: string;
  data: IServiceDataExtended;
  onUpdate?: (...args: any[]) => any;
  options?: {
    handleDeleteVehicle: (...args: any[]) => any;
  },
  activeProject: IProjectData;
  countryCode?: string | null;
};

export const Pickup = withStyles(styles)(({ data, onUpdate, classes, publicKey, activeProject, countryCode } : PickupProps ) => {
  const handleUpdate = (label: string, val: any) => {
    if (onUpdate) {
      onUpdate(data.id, { [label]: val });
    }
  };
  const preffix = 'service.data.details';
  // @ts-expect-error
  const { constraints, client } = activeProject.view;
  return (
    <Grid container direction="column">
      <Grid container className={classes.box}>
        <FormText
          variant="title"
          fieldValue={data.label}
          handleEnter={(val: any) => {
            handleUpdate('label', val);
          }}
        />

        <Grid item container direction="column">
          {renderLinkedClient(preffix, 'client_id', handleUpdate, data)}
        </Grid>
          
        {checkR(client.external_id) &&
          renderFormText(preffix, 'external_id', undefined, handleUpdate, data)}
        {checkR(client.external_id) &&
          renderFormText(preffix, 'client_external_id', undefined, handleUpdate, data)}
        {renderLocation(preffix, 'location', handleUpdate, data, countryCode)}
      </Grid>

      {data.status !== SERVICE_STATUS.pending && (
        <Feedback
          activeProject={activeProject}
          publicKey={publicKey}
          service={data}
          preffix={preffix}
        />
      )}

      <Block title={<FormattedMessage id="clients.details.group.planned" />}>
        {renderInfo(preffix, 'planned_arrival_time', data)}
        {renderInfo(preffix, 'planned_departure_time', data)}
      </Block>
      <Block title={<FormattedMessage id="clients.details.group.constraints" />}>
        {renderMinutes(preffix, 'duration', handleUpdate, data)}
        {checkR(constraints.timewindows) &&
          renderTimewindows(preffix, 'timewindows', handleUpdate, data)}
        {data.type !== 'pickup' && renderPickup(preffix, 'pickup', handleUpdate, data)}
      </Block>
      <Block title={<FormattedMessage id="services.details.group.contact" />}>
        {checkR(client.email) && renderFormText(preffix, 'email', 'email', handleUpdate, data)}
        {checkR(client.phone) && renderFormText(preffix, 'phone', undefined, handleUpdate, data)}
        {checkR(client.comments) && renderFormTextArea(preffix, 'comments', undefined, handleUpdate, data)}
      </Block>
    </Grid>
  );
});

const serviceHeaderMapDispatchToProps = (dispatch: any) => ({
  actions: {
    setService: (service: any) => dispatch(ActivePlan.setDetailView('service', service)),
  },
});

type ServiceHeaderProps = {
  classes: {
    slimbutton: string;
  };
  data: IServiceDataExtended;
  onUpdate?: (...args: any[]) => any;
  warning?: React.ReactElement;
  options?: React.ReactElement | {};
  actions: {
    setService: (...args: any[]) => any;
  };
  pickups: any;
};

export const ServiceHeader = connect(
  null,
  serviceHeaderMapDispatchToProps
)(
  withStyles(styles)(({ data, warning, options, onUpdate, classes, actions, pickups } : ServiceHeaderProps) => {
    if (data) {
      return (
        <>
          <Grid item>
            <FormIcon
              fieldValue={data.icon}
              onUpdate={(icon: any) => {
                if(onUpdate)onUpdate(data.id, { icon });
              }}
            />
          </Grid>
          <Grid container style={{ width: 'auto' }}>
            <Grid item style={{ marginRight: '8px' }}>
              {warning}
            </Grid>
            {data.pickup && (
              <Tooltip title={<FormattedMessage id="pickup.helper" />}>
                <Grid item style={{ marginRight: '8px' }}>
                  <Button
                    color="secondary"
                    className={classes.slimbutton}
                    style={{ padding: '0px 16px' }}
                    onClick={() => actions.setService(data.pickup)}
                  >
                    <PickupIcon style={{ height: '20px', width: '20px' }} />
                    <HSpacer small />
                    <FormattedMessage id="pickup" />
                  </Button>
                </Grid>
              </Tooltip>
            )}
            {data.type === 'pickup' && (
              <Tooltip title={<FormattedMessage id="delivery.helper" />}>
                <Grid item style={{ marginRight: '8px' }}>
                  <Button
                    color="secondary"
                    className={classes.slimbutton}
                    variant="contained"
                    style={{ padding: '0px 16px' }}
                    onClick={() => actions.setService(pickups[data.id])}
                  >
                    <DeliveryIcon style={{ height: '20px', width: '20px' }} />
                    <HSpacer small />
                    <FormattedMessage id="pickup" />
                  </Button>
                </Grid>
              </Tooltip>
            )}
            {options}
          </Grid>
        </>
      );
    }
    return null;
  })
);

type ServiceProps = {
  classes: {
    box: string;
    slimbutton: string;
    menuItem: string;
  };
  data: IServiceDataExtended;
  onUpdate?: (...args: any[]) => any;
  options?: {
    handleDeleteVehicle: (...args: any[]) => any;
  };
  publicKey: string;
  activeProject: IProjectData;
  countryCode?: string | null;
};

const Service = withStyles(styles)(
  ({ data, onUpdate, classes, publicKey, activeProject, countryCode }: ServiceProps) => {
    const handleUpdate = (label: string, val: any) => {
      if (onUpdate) {
        onUpdate(data.id, { [label]: val });
      }
    };
    const preffix = 'service.data.details';
    const { custom_fields: customFields, view } = activeProject;
    // @ts-expect-error
    const { constraints, client } = view;
    return (
      <Grid container direction="column">
        <Grid container className={classes.box}>
          <FormText
            variant="title"
            fieldValue={data.label}
            handleEnter={(val: any) => {
              handleUpdate('label', val);
            }}
          />

          <Grid item container direction="column">
            {renderLinkedClient(preffix, 'client_id', handleUpdate, data)}
          </Grid>

          {checkR(client.external_id) &&
            renderFormText(preffix, 'external_id', undefined, handleUpdate, data)}
          {checkR(client.external_id) &&
            renderFormText(preffix, 'client_external_id', undefined, handleUpdate, data)}
          {renderLocation(preffix, 'location', handleUpdate, data, countryCode)}
          {checkR(client.location_details) &&
            renderFormText(preffix, 'location_details', undefined, handleUpdate, data)}
        </Grid>

        <Grid container className={classes.box}>
          {checkR(client.optional) && renderCheckbox(preffix, 'optional', handleUpdate, data)}
        </Grid>

        {data.status !== SERVICE_STATUS.pending && (
          <Feedback
            activeProject={activeProject}
            publicKey={publicKey}
            service={data}
            preffix={preffix}
          />
        )}

        <Block title={<FormattedMessage id="clients.details.group.planned" />}>
          {renderInfo(preffix, 'planned_arrival_time', data)}
          {renderInfo(preffix, 'planned_departure_time', data)}
        </Block>
        <Block title={<FormattedMessage id="clients.details.group.constraints" />}>
          {checkR(constraints.weight) &&
            renderFormText(
              preffix,
              'weight',
              'number',
              handleUpdate,
              data,
              activeProject?.units?.weight
            )}
          {renderMinutes(preffix, 'duration', handleUpdate, data)}
          {checkR(constraints.volume) &&
            renderFormText(
              preffix,
              'volume',
              'number',
              handleUpdate,
              data,
              activeProject?.units?.volume
            )}
          {checkR(constraints.provide_requires) &&
            renderArray(preffix, 'requires', handleUpdate, data)}
          {checkR(constraints.timewindows) &&
            renderTimewindows(preffix, 'timewindows', handleUpdate, data)}
          {data.type !== 'pickup' && renderPickup(preffix, 'pickup', handleUpdate, data)}
        </Block>
        {/* {checkAllR(client) && ( */}
        <Block title={<FormattedMessage id="services.details.group.contact" />}>
          {checkR(client.reference_person) && renderFormText(preffix, 'reference_person', undefined, handleUpdate, data)}
          {checkR(client.email) && renderFormText(preffix, 'email', 'email', handleUpdate, data)}
          {checkR(client.phone) && renderFormText(preffix, 'phone', undefined, handleUpdate, data)}
          {checkR(client.webpage) && renderFormText(preffix, 'website', 'url', handleUpdate, data)}
          {checkR(client.comments) && renderFormTextArea(preffix, 'comments', undefined, handleUpdate, data)}
        </Block>
        {/* )} */}

        {customFields && customFields.client.length > 0 ? (
          <Block title={<FormattedMessage id="custom_fields.title" />}>
            <CustomFields
              onUpdate={(id: any, value: any) => handleUpdate('custom_fields', { [id]: value })}
              customFields={data.custom_fields}
              projectCustomFields={customFields.client}
            />
          </Block>
        ) : null}
      </Grid>
    );
  }
);

export default withStyles(styles)(Service);
