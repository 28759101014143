import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import * as routing from '../constants/Routing';
import { User } from '../redux/actions';

type ValidationProps = {
  match: {
    params?: {
      token?: string;
    };
  };
  actions: {
    sendValidation: (...args: any[]) => any;
  };
};

class Validation extends Component<ValidationProps> {
  componentDidMount() {
    const { match, actions } = this.props;
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    actions.sendValidation(match.params.token);
  }

  render() {
    return (
      <>
        <Redirect to={routing.LOGIN} />
      </>
    );
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    sendValidation: (token: any) => dispatch(User.sendValidation(token)),
  },
});

export default connect(null, mapDispatchToProps)(Validation);
