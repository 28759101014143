import { Grid, withStyles } from '@material-ui/core';
import { Map, Marker, Route } from '@sm-highway-web/react-components';
import { IPlanData } from 'highway-api/dist/common/interfaces/plans';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { TimeAwareEncoder } from 'time-aware-polyline';
import VehicleIcons from '../../../assets/svg/VehicleIcons';
import { getRouteColor } from '../../../constants/Colors';
import { SERVICE_STATUS } from '../../../constants/Services';
import { getAsUser } from '../../../helpers/Cookies';
import { isValidService } from '../../../helpers/Directions';
import { IUserLocation } from '../../../interfaces/common';
import { ActivePlan } from '../../../redux/actions';
import { userHelpers } from '../../../redux/helpers';

const timeAwarePolyline = new TimeAwareEncoder();

const styles = {
  divMap: {
    position: 'relative',
    height: 'calc(100vh - 48px)',
    width: '100%',
  },
  hudContainer: {
    zIndex: '1000',
    width: '518px',
    height: 'fit-content',
    maxHeight: 'calc(100vh - 92px)',
    overflow: 'auto',
  },
  routeRow: {
    height: '34px',
    marginBottom: '2px',
    color: 'white',
  },
  iconCell: {
    width: '46px',
    marginRight: '2px',
    color: 'rgb(5, 13, 30)',
  },
  labelCell: {
    backgroundColor: '#090B0FBF',
    width: '304px',
    fontSize: '24px',
    paddingLeft: '8px',
    marginRight: '2px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  servicesCell: {
    width: '100px',
    fontSize: '22px',
    backgroundColor: '#050D1E',
    paddingRight: '4px',
    marginRight: '2px',
  },
  percentCell: {
    backgroundColor: '#050D1E',
    color: '#D2190B',
    fontSize: '22px',
    width: '44px',
  },
  divInside: {
    height: 'calc(100% - 32px)',
    width: 'calc(100% - 32px)',
    position: 'absolute',
    top: 0,
    padding: '16px',
  },
};

type OwnPlanLiveProps = {
  classes: {
    divInside: string;
    divMap: string;
    hudContainer: string;
    routeRow: string;
    iconCell: string;
    labelCell: string;
    servicesCell: string;
    percentCell: string;
  };
  plan?: IPlanData;
  userLocation?: IUserLocation;
  actions: {
    getPlan: (...args: any[]) => any;
  };
};

type PlanLiveProps = OwnPlanLiveProps;

const PlanLive = ({ classes, plan, actions, userLocation }: PlanLiveProps) => {
  // const [timer, setTimer] = useState(null);
  useEffect(() => {
    if (plan) {
      const interval = setInterval(() => {
        actions.getPlan(plan.id);
      }, 5000);
      return () => clearInterval(interval);
    }
    return () => {};
  }, [plan, actions]);

  return (
    <div className={classes.divMap}>
      {plan && (
        <>
          <div className={classes.divInside}>
            <Grid container justify="space-between" style={{ height: '100%' }}>
              <Grid item className={classes.hudContainer}>
                <Grid container direction="column">
                  {plan && plan.routes.length > 0 && (
                    <Grid
                      container
                      alignItems="center"
                      justify="flex-end"
                      className={classes.routeRow}
                      style={{ width: '500px', height: '34px' }}
                    >
                      <Grid
                        container
                        alignItems="center"
                        justify="center"
                        className={classes.percentCell}
                        style={{ height: '100%' }}
                        key="griditem-percent"
                      >
                        %
                      </Grid>
                    </Grid>
                  )}
                  {plan &&
                    plan.routes.map((route: any, index: any) => {
                      const done = route.services.reduce(
                        (curr: any, next: any) =>
                          next.status !== SERVICE_STATUS.pending ? curr + 1 : curr,
                        0
                      );
                      let Icon = VehicleIcons.truck;
                      if (route.icon && VehicleIcons[route.icon]) {
                        Icon = VehicleIcons[route.icon];
                      }

                      return (
                        <Grid container key={`grid${route.id}`} className={classes.routeRow}>
                          <Grid
                            container
                            alignItems="center"
                            justify="center"
                            className={classes.iconCell}
                            style={{
                              backgroundColor: getRouteColor(route, plan.routes),
                            }}
                          >
                            <Icon />
                          </Grid>
                          <Grid container alignItems="center" className={classes.labelCell}>
                            {route.label}
                          </Grid>
                          <Grid
                            container
                            alignItems="center"
                            justify="flex-end"
                            className={classes.servicesCell}
                          >
                            <span>{`${done}/${route.services.length}`}</span>
                          </Grid>
                          <Grid
                            container
                            alignItems="center"
                            justify="center"
                            className={classes.percentCell}
                            key={`griditem-${route.id}`}
                          >
                            {done > 0
                              ? // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
                                `${parseInt((done / route.services.length) * 100, 10)}`
                              : '0'}
                          </Grid>
                        </Grid>
                      );
                    })}
                </Grid>
              </Grid>
            </Grid>
          </div>
          <Map
            center={
              plan.routes.length === 0
                ? userLocation
                    ? [userLocation.latitude, userLocation.longitude]
                    : undefined
                : undefined
            }
            zoomLevel={plan.routes.length === 0 && userLocation ? userLocation.mapZoomLevel : undefined}
            height="100%"
            keyboard={false}
            reboundsControl
          >
            {/* RENDER LAST DETECTED FOR EACH ROUTE */}
            {plan.routes.map((route: any, routeIdx: any) => {
              if (route.real_track) {
                let fullRoute;
                try {
                  fullRoute = timeAwarePolyline.decodeTimeAwarePolyline(route.real_track);
                } catch (e) {
                  /* eslint-disable-next-line no-console */
                  console.log(`Impossible to decode polyline: ${route.real_track}`);
                }
                const lastPosition = fullRoute && fullRoute[fullRoute.length - 1];

                const color = getRouteColor(route, plan.routes);
                return (
                  <>
                    {fullRoute && (
                      <Marker
                        id={`routetruck${route.id}`}
                        key={`routetruck${route.id}`}
                        kind="truck"
                        color={color}
                        lat={lastPosition[0]}
                        lng={lastPosition[1]}
                      />
                    )}
                    {(userHelpers.currentUser.isRoot() || getAsUser()) && fullRoute && (
                      <Route
                        id={`route-${route.id}`}
                        color={color}
                        timeAwarePath={route.real_track}
                      />
                    )}
                  </>
                );
              }
              return null;
            })}
            {/* RENDER ALL SERVICES WITHIN A ROUTE */}
            {plan.routes.map((route: any, routeIdx: any) =>
              route.services.filter(isValidService).map((service: any) => {
                const { lat, lng } = service.location;
                return (
                  <Marker
                    id={`service${service.id}`}
                    color={getRouteColor(route, plan.routes)}
                    lat={lat}
                    lng={lng}
                    number={service.order + 1}
                    done={service.status === SERVICE_STATUS.completed}
                    error={service.status === SERVICE_STATUS.canceled}
                  />
                );
              })
            )}

            {/* RENDER ALL SERVICES WITHOUT ROUTE */}
            {plan.services
              .filter((service: any) => !service.route_id && isValidService(service))
              .map((service: any) => {
                const { lat, lng } = service.location;
                return (
                  <Marker
                    id={`unassigned-service${service.id}`}
                    lat={lat}
                    lng={lng}
                    done={service.status === SERVICE_STATUS.completed}
                    error={service.status === SERVICE_STATUS.canceled}
                  />
                );
              })}
          </Map>
        </>
      )}
    </div>
  );
};

PlanLive.defaultProps = {
  plan: undefined,
  userLocation: undefined,
};

const mapStateToProps = (state: any) => {
  return {
    plan: state.activePlan.plan,
    userLocation: state.user.userLocation,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    getPlan: (planId: any) => dispatch(ActivePlan.getPlan(planId)),
  },
});

// @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ divMap: { position: string; he... Remove this comment to see the full error message
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PlanLive));
