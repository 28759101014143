import constants from '../constants';

export const closeSnackBar = () => {
  return { type: constants.InfoError.INFOERROR_CLOSE_SNACKBAR };
};

export const sendStatusToSnackbar = (message: any, type: any, autoHideDuration = 2000) => {
  return {
    type: constants.InfoError.INFOERROR_SEND_STATUS,
    payload: { type, message, autoHideDuration },
  };
};
