import { Button as ButtonMUI, Grid, Slide, Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Lock, ReportProblemOutlined } from '@material-ui/icons';
import { AutocompleteInput } from '@sm-highway-web/react-components';
import { IPlanData } from 'highway-api/dist/common/interfaces/plans';
import { IRouteDataExtended } from 'highway-api/dist/common/interfaces/routes';
import { IServiceDataExtended } from 'highway-api/dist/common/interfaces/services';
import React, { useReducer, useState } from 'react';
import { DragDropContext } from 'react-beautiful-dnd';
import { connect } from 'react-redux';
import ListCloseIcon from '../../../assets/svg/ListCloseIcon';
import ListOpenIcon from '../../../assets/svg/ListOpenIcon';
import WarningIcon from '../../../assets/svg/WarningIcon';
import { getRouteColor } from '../../../constants/Colors';
import AddClientDialog from '../../../dialogs/AddClientDialog';
import AddVehicleDialog from '../../../dialogs/AddVehicleDialog';
import ConfirmDeleteDialog from '../../../dialogs/ConfirmDeleteDialog';
import { parseSearchableRoutes } from '../../../helpers/Edit';
import { ActivePlan, Routes, Services } from '../../../redux/actions';
import { getLocationFromLatLng } from '../../../services/requests';
import BrokenConstraintsTooltip from '../../BrokenConstraintsTooltip';
import FormattedMessage from '../../FormattedMessageCustom';
import PlanMapMap from './PlanMapMap';
import RouteMenu from '../../menus/RouteMenu';
import ServiceMenu from '../../menus/ServiceMenu';
import OptimizationWarningsTooltip from '../../OptimizationWarningsTooltip';
import PlanMapList from './PlanMapList';
import RightPanelInfo from '../../right-panel-info/RightPanelInfo';

const styles = {
  divInside: {
    height: 'calc(100vh - 96px)',
    width: '100%',
  },
  divHeader: {
    height: '48px',
    overflow: 'hidden',
    padding: '0px 16px',
  },
  divContainer: {
    height: '100%',
    position: 'relative',
  },

  divTable: {},
  allFull: {
    height: '100%',
    width: '100%',
  },
  rightPanelInfo: {
    position: 'absolute',
    right: '0px',
    top: '0px',
    height: '100%',
    zIndex: '1200',
    width: '400px',
    backgroundColor: 'white',
    overflow: 'hidden',
  },
  divMap: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
  },
  wrapperlist: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    zIndex: 450,
    width: '40%',
    backgroundColor: 'rgba(241,241,241,0.9)',
  },
  wrapper: {
    position: 'relative',
    height: '100%',
    width: '100%',
  },
  buttonopener: {
    width: '100%',
    height: '100%',
    minWidth: '32px',
    position: 'absolute',
    backgroundColor: 'white',
    left: '0px',
    top: '0px',
    borderRadius: '0px 8px 8px 0px',
    boxShadow: 'none',
  },
  buttonopenericon: {
    width: '16px',
    height: '16px',
  },
  opener: {
    position: 'relative',
    width: '32px',
    height: '42px',
    top: '8px',
    left: '100%',
    backgroundColor: 'transparent',
  },
  divSearcher: {
    height: '38px',
    width: '300px',
    position: 'absolute',
    top: '20px',
    right: '20px',
    padding: '4px 8px',
    backgroundColor: '#ffffffAA',
    zIndex: '450',
    borderRadius: '4px',
  },
};

type OwnPlanRouteMapProps = {
  classes: {
    divInside: string;
    divHeader: string;
    divContainer: string;
    divTable: string;
    divMap: string;
    allFull: string;
    rightPanelInfo: string;
    wrapper: string;
    wrapperlist: string;
    buttonopener: string;
    buttonopenericon: string;
    opener: string;
    divSearcher: string;
  };
  plan: IPlanData;
  actions: {
    addRoutesToAPlan: (...args: any[]) => any;
    updateRoute: (...args: any[]) => any;
    reoptimizeRoute: (...args: any[]) => any;
    updateService: (...args: any[]) => any;
    setService: (...args: any[]) => any;
    setRoute: (...args: any[]) => any;
    createServicesToAPlan: (...args: any[]) => any;
    deleteRoutes: (...args: any[]) => any;
    deleteServices: (...args: any[]) => any;
    saveRouteAsVehicle: (...args: any[]) => any;
    saveServiceAsClient: (...args: any[]) => any;
  };
  detailView?: {
    type: 'service' | 'route';
    data?: IServiceDataExtended | IRouteDataExtended;
  };
  pickups: any;
};

type PlanRouteMapProps = OwnPlanRouteMapProps;

const PlanRouteMap = ({ classes, actions, plan, detailView, pickups }: PlanRouteMapProps) => {
  const [isDoublePanelLeftOpen, setIsDoublePanelLeftOpen] = useState(false);

  const [dialog, setDialog] = useState(undefined);

  const [selectedLocation, setSelectedLocation] = useState(undefined);
  const [selectedRoutes, setSelectedRoutes] = useState([0, undefined]);
  const [isMapSelection, setIsMapSelection] = useState(false);
  const [activeRoute, setActiveRoute] = useState(undefined);
  const [selectedPoi, setSelectedPoi] = useState([]);
  const [createServiceModal, setCreateServiceModal] = useReducer((prevCreateServiceModal) => {
    if (prevCreateServiceModal) {
      setSelectedLocation(undefined);
    }
    return !prevCreateServiceModal;
  }, false);

  const [createRouteModal, setCreateRouteModal] = useReducer((prevCreateRouteModal) => {
    if (prevCreateRouteModal) {
      setSelectedLocation(undefined);
    }
    return !prevCreateRouteModal;
  }, false);

  const handleDeleteRoute = () => {
    setDialog(undefined);
    actions.setRoute(undefined);
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    actions.deleteRoutes([detailView.data]);
    setSelectedRoutes([0, undefined]);
  };

  const handleDeleteServices = () => {
    setDialog(undefined);
    actions.setService(undefined);
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    actions.deleteServices([detailView.data]);
    setSelectedPoi([]);
  };

  const onReorderSimple = (route: any, previousIndex: any, nextIndex: any) => {
    const result = Array.from(plan.routes[route].services.map((s: any) => s.id));
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
    const notMoved = result.map((r: any) => (selectedPoi.includes(r) ? null : r));
    notMoved.splice(nextIndex, 0, ...selectedPoi);
    actions.updateRoute(plan.routes[route].id, {
      services: notMoved.filter((s: any) => s !== null),
    });
  };

  const onReorderToAnotherRoute = (
    sourceRoute: any,
    destinationRoute: any,
    previousIndex: any,
    nextIndex: any
  ) => {
    if (destinationRoute !== 'missing') {
      const resultDR = Array.from(plan.routes[destinationRoute].services.map((s: any) => s.id));
      resultDR.splice(nextIndex, 0, ...selectedPoi);
      actions.updateRoute(plan.routes[destinationRoute].id, { services: resultDR });
    } else {
      actions.updateRoute(plan.routes[sourceRoute].id, {
        services: plan.routes[sourceRoute].services
          .map((s: any) => s.id)
          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
          .filter((s: any) => !selectedPoi.includes(s)),
      });
    }
  };

  const onDragEnd = (result: any) => {
    const { destination, source } = result;
    if (
      !destination ||
      (destination.droppableId.includes('missing') && source.droppableId.includes('missing'))
    ) {
      return;
    }
    const [sourcePanel, , sourceRoute] = source.droppableId.split('-');
    const [destinationPanel, , destinationRoute] = destination.droppableId.split('-');
    if (sourcePanel === destinationPanel && destinationRoute !== 'missing') {
      if (destination.index === source.index) {
        return;
      }
      onReorderSimple(
        selectedRoutes[destinationPanel],
        source.index,
        source.index > destination.index ? destination.index : destination.index + 1
      );
    }
    if (sourcePanel !== destinationPanel) {
      if (sourceRoute === destinationRoute) {
        onReorderSimple(selectedRoutes[destinationPanel], source.index, destination.index);
      } else {
        onReorderToAnotherRoute(sourceRoute, destinationRoute, source.index, destination.index);
      }
    }
  };

  const onDragStart = (start: any) => {
    const splited = start.draggableId.split('-');
    //  eslint-disable-next-line
    const [panel, type, route, ...id] = splited;
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
    if (!selectedPoi.includes(id.join('-'))) {
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'any' is not assignable to type 'never'.
      setSelectedPoi([id.join('-')]);
    }
  };

  const onChangeSelectedRoute = (event: any, number: any) => {
    const { value } = event.target;
    const copySelectedRoute = Object.assign([], selectedRoutes);
    // @ts-expect-error ts-migrate(2322) FIXME: Type 'any' is not assignable to type 'never'.
    copySelectedRoute[number] = value;
    setSelectedRoutes(copySelectedRoute);
  };

  const handleSelectRoute = (e: any, routeIdx: any) => {
    if (e.originalEvent.altKey) {
      setIsDoublePanelLeftOpen(selectedRoutes[1] !== routeIdx);
      setSelectedRoutes([selectedRoutes[0], selectedRoutes[1] !== routeIdx ? routeIdx : undefined]);
      setSelectedPoi(
        activeRoute === routeIdx || activeRoute === selectedRoutes[0] ? selectedPoi : []
      );
    } else {
      setSelectedRoutes([routeIdx, selectedRoutes[1]]);
      setSelectedPoi(
        activeRoute === routeIdx || activeRoute === selectedRoutes[1] ? selectedPoi : []
      );
    }
  };

  const handleSearchServiceSelect = (service: any) => {
    if (service) {
      let route = 'missing';
      plan.routes.forEach((r: any, idx: any) => {
        if (r.id === service.value.route_id) {
          route = idx;
        }
      });
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'any[]' is not assignable to para... Remove this comment to see the full error message
      setSelectedPoi([
        ...(route === activeRoute ? selectedPoi : []),
        ...(service.value.pickup ? [service.value.pickup.id] : []),
        service.value.id,
        ...(service.value.type === 'pickup' ? [pickups[service.value.id].id] : []),
      ]);
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
      if (!selectedRoutes.includes(route)) {
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'number | ... Remove this comment to see the full error message
        setSelectedRoutes([route, selectedRoutes[1]]);
      }
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
      setActiveRoute(route);
    }
  };

  const handleSelectPoi = async (
    e: any,
    poi: any,
    route: any,
    panel: any,
    isMapSelectionParam = true
  ) => {
    let currentPois: any = [];
    setIsMapSelection(isMapSelectionParam);
    if (activeRoute === route) {
      currentPois = selectedPoi;
    }
    setSelectedLocation({ lat: poi.location.lat, lng: poi.location.lng } as any);
    if (panel === undefined) {
      // TOGGLE
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
      if (!selectedPoi.includes(poi.id)) {
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'any[]' is not assignable to para... Remove this comment to see the full error message
        setSelectedPoi([
          ...(selectedRoutes[e.originalEvent.altKey ? 1 : 0] === activeRoute &&
          (e.originalEvent.ctrlKey || e.originalEvent.metaKey)
            ? currentPois
            : []),
          ...(poi.pickup ? [poi.pickup.id] : []),
          poi.id,
          ...(poi.type === 'pickup' ? [pickups[poi.id].id] : []),
        ]);
        setSelectedRoutes(
          e.originalEvent.altKey ? [selectedRoutes[0], route] : [route, selectedRoutes[1]]
        );
        setActiveRoute(route);
        setIsDoublePanelLeftOpen(e.originalEvent.altKey ? true : isDoublePanelLeftOpen);
      } else {
        // eslint-disable-next-line
        if (poi.type === 'pickup') {
          setSelectedPoi(
            selectedPoi.filter((p) => p !== poi.id).filter((p) => pickups[poi.id].id !== p)
          );
        } else if (poi.pickup) {
          setSelectedPoi(
            selectedPoi.filter((p) => p !== poi.id).filter((p) => poi.pickup && poi.pickup.id !== p)
          );
        } else {
          setSelectedPoi(selectedPoi.filter((p) => p !== poi.id));
        }
      }
    } else {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
      //  eslint-disable-next-line
      if (!selectedPoi.includes(poi.id)) {
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'any[]' is not assignable to para... Remove this comment to see the full error message
        setSelectedPoi([
          ...(selectedRoutes[panel] === activeRoute &&
          (e.originalEvent.ctrlKey || e.originalEvent.metaKey)
            ? currentPois
            : []),
          ...(poi.pickup ? [poi.pickup.id] : []),
          poi.id,
          ...(poi.type === 'pickup' ? [pickups[poi.id].id] : []),
        ]);
        setSelectedRoutes(panel === 0 ? [route, selectedRoutes[1]] : [selectedRoutes[0], route]);
        setActiveRoute(route);
      } else {
        // eslint-disable-next-line
        if (poi.type === 'pickup') {
          setSelectedPoi(
            selectedPoi.filter((p) => p !== poi.id).filter((p) => pickups[poi.id].id !== p)
          );
        } else if (poi.pickup) {
          setSelectedPoi(
            selectedPoi.filter((p) => p !== poi.id).filter((p) => poi.pickup && poi.pickup.id !== p)
          );
        } else {
          setSelectedPoi(selectedPoi.filter((p) => p !== poi.id));
        }
      }
    }
  };

  const onClickPoi = (e: any, poi: any, idx: any, panel: any) => {
    e.persist();
    handleSelectPoi(
      { originalEvent: { ctrlKey: e.ctrlKey, metaKey: e.metaKey, altKey: e.altKey } },
      poi,
      idx,
      panel,
      false
    );
  };

  const onDoubleClickPoi = async (e: any, poi: any) => {
    e.persist();
    try {
      const result = await getLocationFromLatLng(poi.location.lat, poi.location.lng);
      setSelectedLocation(result);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={classes.divInside}>
      <Grid container className={classes.divContainer}>
        <div className={classes.wrapper}>
          <div
            className={classes.wrapperlist}
            style={{
              width: isDoublePanelLeftOpen ? '800px' : '400px',
              minWidth: isDoublePanelLeftOpen ? '700px' : '350px',
            }}
          >
            <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
              {/* First panel */}
              <PlanMapList
                routes={(plan && plan.routes) || []}
                missing={plan && plan.services.filter((s: any) => !s.route_id)}
                selectedRoute={selectedRoutes}
                selectedPoi={selectedPoi}
                openerList={isDoublePanelLeftOpen}
                number={0}
                onChangeSelectedRoute={onChangeSelectedRoute}
                onClickPoi={onClickPoi}
                onDoubleClickPoi={onDoubleClickPoi}
                openCloseVehicleDetailsModal={(r: any) => actions.setRoute(r)}
                isMapSelection={isMapSelection}
              />
              {/* Second panel */}
              {isDoublePanelLeftOpen && selectedRoutes[1] !== undefined && (
                <PlanMapList
                  routes={(plan && plan.routes) || []}
                  missing={plan && plan.services.filter((s: any) => !s.route_id)}
                  selectedRoute={selectedRoutes}
                  selectedPoi={selectedPoi}
                  openerList={isDoublePanelLeftOpen}
                  number={1}
                  onChangeSelectedRoute={onChangeSelectedRoute}
                  onClickPoi={onClickPoi}
                  onDoubleClickPoi={onDoubleClickPoi}
                  openCloseVehicleDetailsModal={(r: any) => actions.setRoute(r)}
                  isMapSelection={isMapSelection}
                />
              )}
            </DragDropContext>
            {plan && plan.routes.length > 0 && (
              <div className={classes.opener}>
                <ButtonMUI
                  variant="contained"
                  className={classes.buttonopener}
                  onClick={() => {
                    if (selectedRoutes[1] === undefined) {
                      setSelectedRoutes([
                        selectedRoutes[0],
                        typeof selectedRoutes[0] === typeof 123
                          ? // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
                            (selectedRoutes[0] + 1) % plan.routes.length
                          : 0,
                      ]);
                    }
                    setIsDoublePanelLeftOpen(!isDoublePanelLeftOpen);
                  }}
                >
                  {isDoublePanelLeftOpen ? (
                    <ListCloseIcon className={classes.buttonopenericon} />
                  ) : (
                    <ListOpenIcon className={classes.buttonopenericon} />
                  )}
                </ButtonMUI>
              </div>
            )}
          </div>
          <div className={classes.divMap}>
            {plan && (
              <>
                <div className={classes.divSearcher}>
                  <AutocompleteInput
                    noOptionsMessage={<FormattedMessage id="planmap.searcher.nooptions" />}
                    handlerOnClickChange={handleSearchServiceSelect}
                    placeholder={<FormattedMessage id="planmap.searcher.placeholder" />}
                    listSuggestions={plan && parseSearchableRoutes(plan.services)}
                  />
                </div>
                <PlanMapMap
                  setSelectedLocation={setSelectedLocation}
                  selectedLocation={selectedLocation}
                  selectedPoi={selectedPoi}
                  // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
                  selectedRoute={detailView.type === 'route' ? detailView.data : undefined}
                  // @ts-expect-error ts-migrate(2322) FIXME: Type '(number | undefined)[]' is not assignable to... Remove this comment to see the full error message
                  selectedRoutes={selectedRoutes}
                  services={plan.services}
                  routes={plan.routes}
                  handleSelectPoi={handleSelectPoi}
                  handleSelectRoute={handleSelectRoute}
                  setCreateServiceModal={setCreateServiceModal}
                  setCreateRouteModal={setCreateRouteModal}
                />
              </>
            )}
          </div>
        </div>

        {/* @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'. */}
        {detailView.type === 'service' && detailView.data && (
          // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
          <Slide in={Boolean(detailView.data)} direction="left" mountOnEnter>
            <Grid className={classes.rightPanelInfo}>
              <RightPanelInfo
                key={`${detailView?.data?.id}`}
                type="service"
                onClose={() => actions.setService(undefined)}
                onUpdate={actions.updateService}
                data={detailView?.data}
                warning={
                  <>
                    <OptimizationWarningsTooltip planRoutes={plan.routes} service={detailView?.data as IServiceDataExtended}>
                      <WarningIcon style={{ color: '#D0021B', fontSize: '24px' }} />
                    </OptimizationWarningsTooltip>                    
                    <BrokenConstraintsTooltip
                      type="service"
                      route={
                        plan &&
                        plan.routes &&
                        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
                        plan.routes.find((route: any) => route.id === detailView.data.route_id)
                      }
                      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
                      service={detailView.data}
                    >
                      <ReportProblemOutlined style={{ color: 'orange', fontSize: '24px' }} />
                    </BrokenConstraintsTooltip>
                  </>
                }
                options={
                  <ServiceMenu
                    type="popover"
                    plan={plan}
                    service={detailView?.data}
                    showViewDetails={false}
                    setRightPanelVisible={(visible: any) => {
                      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
                      actions.setService(visible ? detailView.data : undefined);
                    }}
                  />
                }
                countryCode={
                  plan && plan.optimizer_config ? plan.optimizer_config.operation_country : null
                }
              />
            </Grid>
          </Slide>
        )}
        {detailView?.type === 'route' && detailView?.data && (
          <Slide in={Boolean(detailView?.data)} direction="left" mountOnEnter>
            <Grid className={classes.rightPanelInfo}>
              <RightPanelInfo
                key={`${detailView?.data.id}`}
                type="route"
                warning={
                  <Grid container alignItems="center">
                    {(detailView?.data as IRouteDataExtended).is_locked && (
                      <Tooltip
                        title={
                          <FormattedMessage id="planoverview.route.table.header.locked_tooltip" />
                        }
                      >
                        <Lock style={{ marginRight: '8px', fontSize: '18px', color: '#56637b' }} />
                      </Tooltip>
                    )}
                    <BrokenConstraintsTooltip type="route" route={detailView?.data as IRouteDataExtended}>
                      <ReportProblemOutlined style={{ textAlign: 'center', color: 'orange', fontSize: '24px' }} />
                    </BrokenConstraintsTooltip>
                  </Grid>
                }
                options={
                  <RouteMenu
                    type="popover"
                    plan={plan}
                    route={detailView?.data as IRouteDataExtended}
                    showViewDetails={false}
                    setRightPanelVisible={(visible: any) => {
                      actions?.setRoute(visible ? detailView.data : undefined);
                    }}
                  />
                }
                onClose={() => actions.setRoute(undefined)}
                onUpdate={actions.updateRoute}
                data={{
                  ...detailView.data,
                  color: getRouteColor(detailView.data as IRouteDataExtended, plan.routes),
                } as IRouteDataExtended}
                countryCode={
                  plan && plan.optimizer_config ? plan.optimizer_config.operation_country : null
                }
              />
            </Grid>
          </Slide>
        )}
      </Grid>
      <AddClientDialog
        title="Create service"
        open={createServiceModal}
        location={selectedLocation}
        handleOpenCloseDialog={setCreateServiceModal}
        createClient={(label: any, icon: any, location: any) =>
          actions.createServicesToAPlan(plan.id, [{ label, icon, location }])
        }
      />
      <AddVehicleDialog
        title={<FormattedMessage id="routes.details.map.create_new" />}
        open={createRouteModal}
        location={selectedLocation}
        handleOpenCloseDialog={setCreateRouteModal}
        createVehicle={(label: any, icon: any, location: any) =>
          actions.addRoutesToAPlan(plan.id, [
            { label, icon, start_location: location, end_location: location },
          ])
        }
      />
      <ConfirmDeleteDialog
        typeData="routes"
        open={dialog === 'deleteRoute'}
        setDialog={setDialog}
        data={detailView?.type === 'route' && detailView?.data ? (detailView?.data as IRouteDataExtended) : {}}
        handleDelete={handleDeleteRoute}
      />
      <ConfirmDeleteDialog
        typeData="services"
        open={dialog === 'deleteService'}
        setDialog={setDialog}
        data={detailView?.type === 'service' && detailView?.data ? (detailView.data as IServiceDataExtended) : {}}
        handleDelete={handleDeleteServices}
      />
    </div>
  );
};

PlanRouteMap.defaultProps = {
  plan: undefined,
  detailView: {
    type: '',
    data: undefined,
  },
};

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    setService: (service: any) => dispatch(ActivePlan.setDetailView('service', service)),
    setRoute: (route: any) => dispatch(ActivePlan.setDetailView('route', route)),
    setMapSelectedServices: (services: any) =>
      dispatch(ActivePlan.setMapSelectedServices(services)),
    setMapSelectedRoutes: (routes: any) => dispatch(ActivePlan.setMapSelectedRoutes(routes)),
    addRoutesToAPlan: (planId: any, routes: any) =>
      dispatch(ActivePlan.createRoutes(routes, planId)),

    updateRoute: (routeId: any, services: any) => dispatch(Routes.updateRoute(routeId, services)),
    reoptimizeRoute: (routeId: any) => dispatch(Routes.optimizeRoute(routeId)),
    deleteRoutes: (routes: any) => dispatch(Routes.deleteRoutes(routes)),
    saveRouteAsVehicle: (route: any) => dispatch(Routes.saveRouteAsVehicle(route)),

    updateService: (serviceId: any, service: any) =>
      dispatch(Services.updateService(serviceId, service)),
    createServicesToAPlan: (planId: any, service: any) =>
      dispatch(Services.createServicesToAPlan(service, planId)),
    saveServiceAsClient: (service: any) => dispatch(Services.saveServiceAsClient(service)),
    deleteServices: (services: any) => dispatch(Services.deleteServices(services)),
  },
});

const mapStateToProps = (state: any) => {
  return {
    plan: state.activePlan.plan,

    detailView: state.activePlan.detailView,
    selectedServices: state.activePlan.viewMap.services,
    selectedRoutes: state.activePlan.viewMap.routes,

    pickups: state.activePlan.pickups,
  };
};

// @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ divInside: { height: string; w... Remove this comment to see the full error message
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PlanRouteMap));
