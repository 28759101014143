import { MenuItem } from '@material-ui/core';
import { HSpacer } from '@sm-highway-web/react-components';
import React from 'react';
import styled from 'styled-components';
import TypeIcons, { colors } from '../../assets/svg/TypeIcons';

type AsyncSearchNoOptionsMessageProps = {
  noOptionsMessage: string | React.ReactElement | {};
}

export const AsyncSearchNoOptionsMessage = ({ noOptionsMessage }: AsyncSearchNoOptionsMessageProps) => (
  <MenuItem style={{ height: '34px' }}>
    {noOptionsMessage}
  </MenuItem>
);

type AsyncSearchCustomOptionProps = {
  innerProps: any;
  isDisabled: boolean;
  data: {
    label?: string;
    __isNew__?: boolean;
    icon?: string;
  };
};

export const AsyncSearchCustomOption = ({ innerProps, isDisabled, data }: AsyncSearchCustomOptionProps) => {
  let Label;
  let color;
  if (data.icon) {
    Label = TypeIcons[data.icon];
    color = colors[data.icon];
  } else if (!data.__isNew__) {
    Label = TypeIcons.normal;
    color = colors.normal;
  }

  return !isDisabled ? (
    <MenuItem {...innerProps} style={{ height: '34px' }}>
      {Label ? (
        <>
          <Label style={{ color }} />
          <HSpacer small />
        </>
      ):null}
      {data.label}
    </MenuItem>
  ) : null;
};

const ValueContainerStyled = styled.div`
  height: 34px;
  display: flex;
  flex-direction: row;
  margin-left: 8px;
  align-items: center;
`;

export const AsyncSearchValueContainer = ({ ...props }) => {
  if(props.hasValue) {
    const values = props.getValue();
    if(values.length > 0) {
      const data = values[0];
      let Label;
      let color;
      if (data.icon) {
        Label = TypeIcons[data.icon];
        color = colors[data.icon];
      } else if (!data.__isNew__) {
        Label = TypeIcons.normal;
        color = colors.normal;
      }

      return (
        <ValueContainerStyled {...props}>
          {Label ? (
            <>
              <Label style={{ color }} />
              <HSpacer small />
            </>
          ):null}
          {data.label}
        </ValueContainerStyled>
      );
    }
  }
  return <div {...props} style={{ paddingLeft: '8px' }} />;
};

export default {};
