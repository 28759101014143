import { Grid, Paper, Tooltip, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { InputText, VSpacer } from '@sm-highway-web/react-components';
import moment from 'moment';
import React, { PureComponent } from 'react';
import { Line } from 'react-chartjs-2';
import {
  aggregateByWeek,
  dateFormatToPicker,
  getAllDatesBetween,
  pushZeroBetweenStartDateAndFirstDate,
} from '../helpers/TimeDistance';
import { getUsageStats } from '../services/requests';

const styles = () => ({
  containerDiv: {
    height: '100%',
    padding: '24px',
  },
  fullHeight: {
    height: '100%',
  },
});

type OwnAdministrationUsageProps = {
  classes: {
    containerDiv: string;
    fullHeight: string;
  };
};

type AdministrationUsageState = any;

type AdministrationUsageProps = OwnAdministrationUsageProps;

class AdministrationUsage extends PureComponent<
  AdministrationUsageProps,
  AdministrationUsageState
> {
  static defaultProps = {};

  state = {
    // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
    startDate: dateFormatToPicker(new Date(moment().subtract(30, 'days'))),
    endDate: dateFormatToPicker(new Date()),
    stats: undefined,
    isWeekly: false,
  };

  componentDidMount = async () => {
    const { startDate, endDate } = this.state;
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
    const response = await getUsageStats(startDate, `${endDate}T23:59:59.999Z`);
    if (response.status === 200) {
      this.setState({
        stats: {
          ...response.data,
          optimizationStats: pushZeroBetweenStartDateAndFirstDate(
            getAllDatesBetween(response.data.optimizationStats, 'optimizations'),
            startDate,
            'optimizations'
          ),
          weeklyStats: aggregateByWeek(
            pushZeroBetweenStartDateAndFirstDate(
              getAllDatesBetween(response.data.optimizationStats, 'optimizations'),
              startDate,
              'optimizations'
            ),
            'optimizations'
          ),
        },
      });
    }
  };

  handleChangeDate = (name: any) => (date: any) => {
    this.setState({ [name]: date }, async () => {
      const { startDate, endDate } = this.state;
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
      const response = await getUsageStats(startDate, `${endDate}T23:59:59.999Z`);
      if (response.status === 200) {
        this.setState({
          stats: {
            ...response.data,
            optimizationStats: pushZeroBetweenStartDateAndFirstDate(
              getAllDatesBetween(response.data.optimizationStats, 'optimizations'),
              startDate,
              'optimizations'
            ),
            weeklyStats: aggregateByWeek(
              pushZeroBetweenStartDateAndFirstDate(
                getAllDatesBetween(response.data.optimizationStats, 'optimizations'),
                startDate,
                'optimizations'
              ),
              'optimizations'
            ),
          },
        });
      }
    });
  };

  render() {
    const { classes } = this.props;
    const { endDate, startDate, stats, isWeekly } = this.state;
    return (
      <div className={classes.containerDiv}>
        <Grid container direction="column">
          <Grid item>
            <Typography variant="h2">Usage</Typography>
          </Grid>
          <VSpacer />
          <Grid item>
            <Grid container spacing={6}>
              <Grid item sm={3}>
                {stats && (
                  <Paper className={classes.fullHeight}>
                    <VSpacer medium />
                    <Typography variant="h5" align="center">
                      Average opt. by user
                    </Typography>
                    <VSpacer medium />
                    <Tooltip
                      title={`${
                        (
                          // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
                          stats.totalOptimitzations /
                          // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
                          stats.totalOptimizerUsers /
                          moment(new Date(endDate)).diff(moment(new Date(startDate)), 'days')
                        ).toFixed(2)
                      }
                      per day in
                      ${moment(new Date(endDate)).diff(moment(new Date(startDate)), 'days')} days`}
                    >
                      <Typography variant="h1" align="center">
                        {/* @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'. */}
                        {Math.ceil(stats.totalOptimitzations / stats.totalOptimizerUsers)}
                      </Typography>
                    </Tooltip>
                    <VSpacer medium />
                  </Paper>
                )}
              </Grid>
              <Grid item sm={3}>
                {stats && (
                  <Paper className={classes.fullHeight}>
                    <VSpacer medium />
                    <Typography variant="h5" align="center">
                      Total optimizations
                    </Typography>
                    <VSpacer medium />
                    <Typography variant="h1" align="center">
                      {/* @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'. */}
                      {stats.totalOptimitzations}
                    </Typography>
                    <VSpacer medium />
                  </Paper>
                )}
              </Grid>
              <Grid item sm={3}>
                {stats && (
                  <Paper className={classes.fullHeight}>
                    <VSpacer medium />
                    <Typography variant="h5" align="center">
                      Total optimizer users
                    </Typography>
                    <VSpacer medium />
                    <Typography variant="h1" align="center">
                      {/* @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'. */}
                      {stats.totalOptimizerUsers}
                    </Typography>
                    <VSpacer medium />
                  </Paper>
                )}
              </Grid>
              <Grid item sm={3}>
                <InputText
                  InputLabelProps={{
                    shrink: true,
                  }}
                  id="sdate"
                  label="Start Date"
                  type="date"
                  value={startDate}
                  onChange={this.handleChangeDate('startDate')}
                  fullWidth
                />
                <InputText
                  InputLabelProps={{
                    shrink: true,
                  }}
                  id="fdate"
                  label="End Date"
                  type="date"
                  value={endDate}
                  onChange={this.handleChangeDate('endDate')}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
          <VSpacer medium />
          <div
            style={{ cursor: 'pointer' }}
            onDoubleClick={() =>
              this.setState((prevState: any) => ({
                isWeekly: !prevState.isWeekly,
              }))
            }
          >
            {!isWeekly && (
              <>
                <Typography variant="h4">Daily stats</Typography>
                <VSpacer medium />
                <Grid item>
                  {stats && (
                    <Line
                      height={100}
                      options={{
                        elements: {
                          line: {
                            tension: 0, // disables bezier curves
                          },
                        },
                        scales: {
                          yAxes: [
                            {
                              stacked: true,
                            },
                          ],
                        },
                      }}
                      data={{
                        datasets: [
                          {
                            // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
                            data: stats.optimizationStats.map((s: any) => s.optimizations),
                            fill: false,
                            borderColor: '#3cba9f',
                            label: 'Daily optimizations',
                          },
                        ],
                        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
                        labels: stats.optimizationStats.map((s: any) => s.date),
                      }}
                    />
                  )}
                </Grid>
              </>
            )}
            {isWeekly && (
              <>
                <Typography variant="h4">Weekly stats</Typography>
                <VSpacer medium />
                <Grid item>
                  {stats && (
                    <Line
                      height={100}
                      options={{
                        elements: {
                          line: {
                            tension: 0, // disables bezier curves
                          },
                        },
                        scales: {
                          yAxes: [
                            {
                              stacked: true,
                            },
                          ],
                        },
                      }}
                      data={{
                        datasets: [
                          {
                            // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
                            data: Object.values(stats.weeklyStats),
                            fill: false,
                            borderColor: '#3cba9f',
                            label: 'Weekly optimizations',
                          },
                        ],
                        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
                        labels: Object.keys(stats.weeklyStats),
                      }}
                    />
                  )}
                </Grid>
              </>
            )}
          </div>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(AdministrationUsage);
