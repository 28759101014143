import getHighway from '../../services/highway';
import constants from '../constants';
import { projectHelpers } from '../helpers';
import store from '../store/index';

export const getPlans = (
  projectId = undefined,
  offset = 0,
  limit = 25,
  text = undefined,
  status = undefined,
  fromDate = undefined,
  toDate = undefined,
  sort = undefined
) => {
  
  // TODO: IF FAIL (project_not_found) set the first project of the projects list as the default_project. IF projects array is empty, send user to error page!

  return (dispatch: any) => {
    const highway = getHighway(store.getState().user.token);
    highway.plan
      .list(
        projectId || projectHelpers.getCurrentProjectId(),
        text,
        status,
        fromDate,
        toDate,
        sort,
        offset,
        limit
      )
      .then((response) => {
        dispatch({
          type: constants.Plans.PLANS_GET_PLANS_SUCCESS,
          payload: response,
        });
      })
      .catch((error) => {
        dispatch({
          type: constants.Plans.PLANS_GET_PLANS_ERROR,
          payload: error,
        });
      });
  };
};

export const deletePlan = (planId: any) => {
  return (dispatch: any) => {
    const hw = getHighway(store.getState().user.token);
    hw.plan
      .delete(planId)
      .then((response) => {
        dispatch({
          type: constants.Plans.PLANS_DELETE_PLAN_SUCCESS,
          payload: response,
        });
        const { limit, offset } = store.getState().plans.plans;
        dispatch(getPlans(undefined, offset, limit));
      })
      .catch((error) => {
        dispatch({
          type: constants.Plans.PLANS_DELETE_PLAN_ERROR,
          payload: error,
        });
      });
  };
};
