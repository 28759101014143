import countries from './Countries';

export const FIRST_SOLUTION_STRATEGY = [
  { value: `AUTOMATIC`, label: `AUTOMATIC` },
  { value: `PATH_CHEAPEST_ARC`, label: `PATH_CHEAPEST_ARC` },
  { value: `PATH_MOST_CONSTRAINED_ARC`, label: `PATH_MOST_CONSTRAINED_ARC` },
  { value: `EVALUATOR_STRATEGY`, label: `EVALUATOR_STRATEGY` },
  { value: `SAVINGS`, label: `SAVINGS` },
  { value: `SWEEP`, label: `SWEEP` },
  { value: `CHRISTOFIDES`, label: `CHRISTOFIDES` },
  { value: `ALL_UNPERFORMED`, label: `ALL_UNPERFORMED` },
  { value: `BEST_INSERTION`, label: `BEST_INSERTION` },
  {
    value: `PARALLEL_CHEAPEST_INSERTION`,
    label: `PARALLEL_CHEAPEST_INSERTION (default)`,
  },
  { value: `LOCAL_CHEAPEST_INSERTION`, label: `LOCAL_CHEAPEST_INSERTION` },
  { value: `GLOBAL_CHEAPEST_ARC`, label: `GLOBAL_CHEAPEST_ARC` },
  { value: `LOCAL_CHEAPEST_ARC`, label: `LOCAL_CHEAPEST_ARC` },
  { value: `FIRST_UNBOUND_MIN_VALUE`, label: `FIRST_UNBOUND_MIN_VALUE` },
];

export const LOCAL_SEARCH_STRATEGY = [
  { value: `AUTOMATIC`, label: `AUTOMATIC (default)` },
  { value: `GREEDY_DESCENT`, label: `GREEDY_DESCENT` },
  { value: `GUIDED_LOCAL_SEARCH`, label: `GUIDED_LOCAL_SEARCH` },
  { value: `SIMULATED_ANNEALING`, label: `SIMULATED_ANNEALING` },
  { value: `TABU_SEARCH`, label: `TABU_SEARCH` },
];

// Map used for optimizer config by a user with root or admin smart monkey roles.
export const PROJECT_OPTIMIZER_CONIFG_SM_ROOT_MAP = [
  {
    id: 'first_solution_strategy',
    type: 'select',
    options: [...FIRST_SOLUTION_STRATEGY],
  },
  {
    id: 'local_search_strategy',
    type: 'select',
    options: [...LOCAL_SEARCH_STRATEGY],
  },
  { id: 'time_limit_seconds', type: 'minutes' },
  { id: 'lns_time_limit', type: 'minutes' },
  { id: 'max_wait_time', type: 'minutes' },
  { id: 'matrix_multiplier', type: 'number' },
  { id: 'service_duration', type: 'minutes' },
  { id: 'balance_services', type: 'boolean' },
  {
    id: 'operation_country',
    type: 'select',
    no_text_id: 'none',
    options: parseCountries(countries),
  },
];

export const PROJECT_OPTIMIZER_CONIFG_MAP = [
  {
    id: 'matrix_multiplier',
    type: 'select',
    options: [
      {
        value: 2.5,
        label_id: 'optimizeroptions.details.matrix_multiplier.very_slow',
      },
      {
        value: 2,
        label_id: 'optimizeroptions.details.matrix_multiplier.slow',
      },
      {
        value: 1.4,
        label_id: 'optimizeroptions.details.matrix_multiplier.normal',
      },
      {
        value: 1.2,
        label_id: 'optimizeroptions.details.matrix_multiplier.fast',
      },
    ],
  },
  { id: 'balance_services', type: 'boolean' },
  {
    id: 'operation_country',
    type: 'select',
    no_text_id: 'none',
    options: parseCountries(countries),
  },
];

/**
 * Creates an array from a countries object. Also order the content by country label.
 * @param {*} countriesObject
 */
function parseCountries(countriesObject: any) {
  const countriesArray = Object.keys(countriesObject).map((countryKey) => ({
    value: countryKey,
    label: countries[countryKey].label,
  }));

  return countriesArray.sort((a, b) => {
    if (a.label < b.label) return -1;
    if (a.label > b.label) return 1;
    return 0;
  });
}
