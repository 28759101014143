import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import React from 'react';
import { VSpacer } from '@sm-highway-web/react-components';

type OwnProps = {
  defaultValue?: string;
  value?: string;
  arrayRadios?: {}[];
  label?: string | React.ReactElement | {};
  onUpdate?: (...args: any[]) => any;
};

type Props = OwnProps;

const FormRadioButtons = ({ value, arrayRadios, label, onUpdate, defaultValue }: Props) => {
  const handleChange = (event: any) => {
    if (onUpdate) {
      onUpdate(event.target.value);
    }
  };

  return (
    <FormControl component="fieldset">
      {label && <FormLabel component="legend">{label}</FormLabel>}
      {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
      <RadioGroup name={label} value={value} onChange={handleChange}>
        <VSpacer small />
        {/* @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'. */}
        {arrayRadios.map((radio: any) => (
          <FormControlLabel
            key={radio.value}
            value={radio.value}
            disabled={radio.disabled}
            control={<Radio color="primary" />}
            label={radio.label}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

FormRadioButtons.defaultProps = {
  defaultValue: undefined,
  value: undefined,
  arrayRadios: [],
  label: undefined,
  onUpdate: undefined,
};

export default FormRadioButtons;
