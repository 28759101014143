/* eslint-disable camelcase */
import en_admin from './admin/en.json';
import es_admin from './admin/es.json';
import en_all_to_organize from './all_to_organize/en.json';
import es_all_to_organize from './all_to_organize/es.json';
import en_clients from './clients/en.json';
import es_clients from './clients/es.json';
import en_infoerror from './infoerror/en.json';
import es_infoerror from './infoerror/es.json';
import en_login from './login/en.json';
import es_login from './login/es.json';
import en_organization from './organization/en.json';
import es_organization from './organization/es.json';
import en_payment from './payment/en.json';
import es_payment from './payment/es.json';
import en_projects from './projects/en.json';
import es_projects from './projects/es.json';
import en_register from './register/en.json';
import es_register from './register/es.json';
import en_resetpassword from './resetpassword/en.json';
import es_resetpassword from './resetpassword/es.json';
import en_settings from './settings/en.json';
import es_settings from './settings/es.json';
import en_subscription from './subscription/en.json';
import es_subscription from './subscription/es.json';
import en_tutorials from './tutorials/en.json';
import es_tutorials from './tutorials/es.json';
import en_userprofilesummary from './userprofilesummary/en.json';
import es_userprofilesummary from './userprofilesummary/es.json';

const translations = {
  es: {
    ...es_admin,
    ...es_login,
    ...es_register,
    ...es_resetpassword,
    ...es_settings,
    ...es_organization,
    ...es_subscription,
    ...es_userprofilesummary,
    ...es_projects,
    ...es_clients,
    ...es_infoerror,
    ...es_tutorials,
    ...es_payment,
    ...es_all_to_organize,
  },
  en: {
    ...en_admin,
    ...en_login,
    ...en_register,
    ...en_resetpassword,
    ...en_settings,
    ...en_organization,
    ...en_subscription,
    ...en_userprofilesummary,
    ...en_projects,
    ...en_clients,
    ...en_infoerror,
    ...en_tutorials,
    ...en_payment,
    ...en_all_to_organize,
  },
};

export default translations;
