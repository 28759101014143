import axios from 'axios';
import countries from '../../constants/Countries';
import { getAsUser, removeCookie, setCookie } from '../../helpers/Cookies';
import configuration from '../../services/configuration';
import eventsPanel from '../../services/events';
import Froged, { FrogedEvents } from '../../services/froged';
import { deleteQuery, get, getUserLocation, post, put } from '../../services/requests';
import constants from '../constants';
import store from '../store/index';

export const details = (token?: any) => {
  return (dispatch: any) => {
    return get(`/user/details`, undefined, {
      Authorization: `Bearer ${token || store.getState().user.token}`,
    })
      .then((response) => {
        dispatch({
          type: constants.User.USER_DETAILS_SUCCESS,
          payload: response.data,
        });

        if (
          !getAsUser() &&
          response.data.optimizer_config &&
          response.data.optimizer_config.operation_country
        ) {
          setUserLocationByCountry(response.data.optimizer_config.operation_country);
        }
      })
      .catch((error) => {
        dispatch({
          type: constants.User.USER_DETAILS_ERROR,
          payload: error,
        });
      });
  };
};

export const logIn = (email: any, password: any) => {
  return (dispatch: any) => {
    dispatch({ type: constants.User.USER_LOGIN });
    return axios
      .get(`${configuration.HIGHWAY_BACK_END}/login`, {
        params: { email, password, keep_session: true },
      })
      .then((response) => {
        dispatch(details(response.data.token));
        setCookie('tokenHW', response.data.token, 1);
        dispatch({
          type: constants.User.USER_LOGIN_SUCCESS,
          payload: response.data,
        });
        eventsPanel.login();
      })
      .catch((error) => {
        dispatch({
          type: constants.User.USER_LOGIN_ERROR,
          payload: error,
        });
      });
  };
};

export const resendValidationMail = (email: any) => {
  return (dispatch: any) => {
    return axios
      .get(`${configuration.HIGHWAY_BACK_END}/user/resend-validation`, { params: { email } })
      .then((response) => {
        dispatch({
          type: constants.User.USER_RESENDEMAIL_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: constants.User.USER_RESENDEMAIL_ERROR,
          payload: error,
        });
      });
  };
};

export const setUserLocationByCountry = (countryCode: any) => {
  const countryObject = countries[countryCode];
  if (
    countryObject &&
    countryObject.coordinates &&
    countryObject.coordinates.latitude &&
    countryObject.coordinates.longitude
  ) {
    store.dispatch({
      type: constants.User.USER_SET_USER_LOCATION,
      payload: {
        latitude: countryObject.coordinates.latitude,
        longitude: countryObject.coordinates.longitude,
        country: countryCode,
        mapZoomLevel: countryObject.mapZoomLevel,
      },
    });
  }
};

export const logout = () => {
  removeCookie();

  return { type: constants.User.USER_LOGOUT };
};

export const sendValidation = (token: any) => {
  return (dispatch: any) => {
    return axios
      .get(`${configuration.HIGHWAY_BACK_END}/user/validate`, {
        params: { token },
      })
      .then((response) => {
        // GA
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'gtag_report_conversion' does not exist o... Remove this comment to see the full error message
        window.gtag_report_conversion();

        // Capterra
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'capterra_monitorization' does not exist ... Remove this comment to see the full error message
        window.capterra_monitorization();

        Froged.track(FrogedEvents.user.on_sign_up);
        dispatch(details(response.data.token));
        setCookie('tokenHW', response.data.token, 1);
        dispatch({
          type: constants.User.USER_VALIDATE_SUCCESS,
          payload: response.data,
        });
        dispatch({
          type: constants.User.USER_LOGIN_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: constants.User.USER_VALIDATE_ERROR,
          payload: error,
        });
      });
  };
};

export const changeLanguage = (language: any) => {
  return { type: constants.User.USER_LANGUAGE, payload: language };
};

export const editUser = (
  firstName: any,
  lastName: any,
  phone: any,
  language: any,
  view: any,
  optimizationConfig: any,
  units: any,
  pod: any
) => {
  return (dispatch: any) => {
    return put(
      `/user`,
      {
        first_name: firstName,
        last_name: lastName,
        phone,
        language,
        view,
        optimizer_config: optimizationConfig,
        units,
        pod,
      },
      { Authorization: `Bearer ${store.getState().user.token}` }
    )
      .then((response) => {
        dispatch({
          type: constants.User.USER_EDIT_SUCCESS,
          payload: response.data,
        });

        if (optimizationConfig && optimizationConfig.operation_country) {
          setUserLocationByCountry(optimizationConfig.operation_country);
        }
      })
      .catch((error) => {
        dispatch({
          type: constants.User.USER_EDIT_ERROR,
          payload: error,
        });
      });
  };
};

export const beAsUser = (id: any) => {
  return { type: constants.User.USER_AS_USER, payload: id };
};

export const removeAsUser = () => {
  return { type: constants.User.USER_REMOVE_AS_USER };
};

export const getAdminValidateUser = (user: any) => {
  return (dispatch: any) => {
    return axios
      .get(`${configuration.HIGHWAY_BACK_END}/user/validate/admin`, {
        params: { user },
        headers: { Authorization: `Bearer ${store.getState().user.token}` },
      })
      .then((response) => {
        dispatch({
          type: constants.User.ADMIN_VALIDATE_USER_SUCCESS,
          payload: response.data.user,
        });
      })
      .catch((error) => {
        dispatch({
          type: constants.User.ADMIN_VALIDATE_USER_ERROR,
          payload: error,
        });
      });
  };
};

export const getDetailsByPublickey = (publickey: any) => {
  return (dispatch: any) => {
    return axios
      .get(`${configuration.HIGHWAY_BACK_END}/user/details/public`, {
        params: { public_key: publickey },
      })
      .then((response) => {
        dispatch({
          type: constants.User.USER_DETAILS_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: constants.User.USER_DETAILS_ERROR,
          payload: error,
        });
      });
  };
};

export const editGuide = (guide: any) => {
  return (dispatch: any) => {
    return put(`/user/guide`, guide, { Authorization: `Bearer ${store.getState().user.token}` })
      .then((response) => {
        dispatch({
          type: constants.User.USER_EDIT_GUIDE_SUCCESS,
          payload: response.data.guide,
        });
      })
      .catch((error) => {
        dispatch({
          type: constants.User.USER_EDIT_GUIDE_ERROR,
          payload: error,
        });
      });
  };
};

export const initUserLocation = async () => {
  const userLocation = localStorage.getItem('USER_LOCATION');
  if (userLocation) {
    const parsedUserLocation = JSON.parse(userLocation);
    if (
      parsedUserLocation.country &&
      typeof parsedUserLocation.country === 'string' &&
      parsedUserLocation.country !== ''
    ) {
      setUserLocationByCountry(parsedUserLocation.country);
      return;
    }
    localStorage.removeItem('USER_LOCATION');
  }

  const response = await getUserLocation();
  if (response) {
    setUserLocationByCountry(response.country_code);
  }
};

export const uploadAvatar = (fileName: any, mimeType: any, file: any) => {
  const formData = new FormData();
  formData.append('name', fileName);
  formData.append('mime', mimeType);
  formData.append('data', file);
  return (dispatch: any) => {
    return post(`/user/avatar`, formData, {
      'content-type': 'multipart/form-data',
      Authorization: `Bearer ${store.getState().user.token}`,
    })
      .then((response) => {
        dispatch({
          type: constants.User.USER_UPLOAD_AVATAR_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: constants.User.USER_UPLOAD_AVATAR_ERROR,
          payload: error,
        });
      });
  };
};

export const removeAvatar = () => {
  return (dispatch: any) => {
    return deleteQuery(`/user/avatar`, {
      Authorization: `Bearer ${store.getState().user.token}`,
    })
      .then((response) => {
        dispatch({
          type: constants.User.USER_REMOVE_AVATAR_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: constants.User.USER_REMOVE_AVATAR_ERROR,
          payload: error,
        });
      });
  };
};
