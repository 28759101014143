import constants from '../constants/index';

const initialState = {
  flatVehicles: undefined,
  vehicles: {
    limit: 100,
    offset: 0,
    docs: undefined,
  },
  selection: [],
  detailView: {
    type: 'vehicle',
    data: undefined,
  },
};

function vehicles(state = initialState, action: any) {
  let selection: any;
  const detailView = { ...initialState.detailView };
  switch (action.type) {
    case constants.Vehicles.VEHICLES_GET_FLAT_VEHICLES_SUCCESS:
      return { ...state, flatVehicles: action.payload };
    case constants.Vehicles.VEHICLES_CREATE_VEHICLE_SUCCESS:
      detailView.data = action.payload;
      return { ...state, detailView };
    case constants.Vehicles.VEHICLES_UPDATE_VEHICLE_SUCCESS:
      if (!state.detailView.data) {
        detailView.data = undefined;
        return {
          ...state,
          detailView,
        };
      }

      // Updating the selected routes.
      selection = [];
      if (Array.isArray(action.payload)) {
        selection = [...state.selection];
        const updatedSelection: any = [];
        action.payload.forEach((payloadItem: any) => {
          const selectedVehicle = selection.find((vehicle: any) => vehicle.id === payloadItem.id);
          if (selectedVehicle) updatedSelection.push(payloadItem);
        });

        const updatedSelectionIds = updatedSelection.map((sel: any) => sel.id);
        selection = [
          ...selection.filter((vehicle: any) => updatedSelectionIds.indexOf(vehicle.id) === -1),
          ...updatedSelection,
        ];
      } else {
        selection = [...state.selection];
        const selectedVehicle = selection.find((vehicle: any) => vehicle.id === action.payload.id);
        if (selectedVehicle) {
          selection = [
            ...selection.filter((vehicle: any) => vehicle.id !== selectedVehicle.id),
            action.payload,
          ];
        }
      }

      if (Array.isArray(action.payload) && action.payload.length > 1) {
        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        const filtered = action.payload.filter((sv: any) => sv.id === state.detailView.data.id);
        detailView.data = filtered.length > 0 ? filtered[0] : undefined;
        return {
          ...state,
          selection,
          detailView,
        };
      }

      detailView.data = action.payload;
      return { ...state, selection, detailView };
    case constants.Vehicles.VEHICLES_GET_VEHICLE_SUCCESS:
      detailView.data = action.payload;
      return { ...state, detailView };
    case constants.Vehicles.VEHICLES_GET_VEHICLES_SUCCESS:
      return { ...state, vehicles: action.payload };
    case constants.Vehicles.VEHICLES_SET_SELECTED_VEHICLES:
      return { ...state, selection: action.payload };
    case constants.Vehicles.VEHICLES_REMOVE_SELECTED:
      detailView.data = undefined;
      return { ...state, detailView };
    default:
      return state;
  }
}
export default vehicles;
