export const PAYMENT_GET_SUBSCRIPTION = 'PAYMENT_GET_SUBSCRIPTION';
export const PAYMENT_GET_SUBSCRIPTION_SUCCESS = 'PAYMENT_GET_SUBSCRIPTION_SUCCESS';
export const PAYMENT_GET_SUBSCRIPTION_ERROR = 'PAYMENT_GET_SUBSCRIPTION_ERROR';

export const PAYMENT_GET_SUBSCRIPTION_CARD = 'PAYMENT_GET_SUBSCRIPTION_CARD';
export const PAYMENT_GET_SUBSCRIPTION_CARD_SUCCESS = 'PAYMENT_GET_SUBSCRIPTION_CARD_SUCCESS';
export const PAYMENT_GET_SUBSCRIPTION_CARD_ERROR = 'PAYMENT_GET_SUBSCRIPTION_CARD_ERROR';

export const PAYMENT_POST_PAYMENT_METHOD = 'PAYMENT_POST_SUBSCRIPTION';
export const PAYMENT_POST_PAYMENT_METHOD_SUCCESS = 'PAYMENT_POST_SUBSCRIPTION_SUCCESS';
export const PAYMENT_POST_PAYMENT_METHOD_ERROR = 'PAYMENT_POST_SUBSCRIPTION_ERROR';

export const PAYMENT_POST_BILLING_ADDRESS = 'PAYMENT_POST_BILLING_ADDRESS';
export const PAYMENT_POST_BILLING_ADDRESS_SUCCESS = 'PAYMENT_POST_BILLING_ADDRESS_SUCCESS';
export const PAYMENT_POST_BILLING_ADDRESS_ERROR = 'PAYMENT_POST_BILLING_ADDRESS_ERROR';

export const PAYMENT_GET_BILLING_ADDRESS = 'PAYMENT_GET_BILLING_ADDRESS';
export const PAYMENT_GET_BILLING_ADDRESS_SUCCESS = 'PAYMENT_GET_BILLING_ADDRESS_SUCCESS';
export const PAYMENT_GET_BILLING_ADDRESS_ERROR = 'PAYMENT_GET_BILLING_ADDRESS_ERROR';

export const PAYMENT_POST_SUBSCRIPTION_PLAN = 'PAYMENT_POST_SUBSCRIPTION_PLAN';
export const PAYMENT_POST_SUBSCRIPTION_PLAN_SUCCESS = 'PAYMENT_POST_SUBSCRIPTION_PLAN_SUCCESS';
export const PAYMENT_POST_SUBSCRIPTION_PLAN_ERROR = 'PAYMENT_POST_SUBSCRIPTION_PLAN_ERROR';

export const PAYMENT_CANCEL_SUBSCRIPTON_PLAN = 'PAYMENT_CANCEL_SUBSCRIPTON_PLAN';
export const PAYMENT_CANCEL_SUBSCRIPTON_PLAN_SUCCESS = 'PAYMENT_CANCEL_SUBSCRIPTON_PLAN_SUCCESS';
export const PAYMENT_CANCEL_SUBSCRIPTON_PLAN_ERROR = 'PAYMENT_CANCEL_SUBSCRIPTON_PLAN_ERROR';

export const PAYMENT_MANAGE_SUBSCRIPTON_PLAN = 'PAYMENT_MANAGE_SUBSCRIPTON_PLAN';
export const PAYMENT_MANAGE_SUBSCRIPTON_PLAN_SUCCESS = 'PAYMENT_MANAGE_SUBSCRIPTON_PLAN_SUCCESS';
export const PAYMENT_MANAGE_SUBSCRIPTON_PLAN_ERROR = 'PAYMENT_MANAGE_SUBSCRIPTON_PLAN_ERROR';

export const DELETE_PLAN = 'DELETE_PLAN';
