const styles = () => ({
  divMap: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    width: '100%',
  },
  wrapperlist: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    zIndex: 450,
    width: '40%',
    backgroundColor: 'rgba(241,241,241,0.9)',
  },
  list: {
    width: '50%',
    height: '100%',
    float: 'left',
    borderRadius: '4px',
    backgroundColor: 'transparent',
  },
  opener: {
    position: 'relative',
    width: '32px',
    height: '42px',
    top: '8px',
    left: '100%',
    backgroundColor: 'transparent',
  },
  buttonopener: {
    width: '100%',
    height: '100%',
    minWidth: '32px',
    position: 'absolute',
    backgroundColor: 'white',
    left: '0px',
    top: '0px',
    borderRadius: '0px 8px 8px 0px',
    // boxShadow:  '5px 1px 7px -5px rgba(0,0,0,0.2),5px 2px 7px -5px rgba(0,0,0,0.14),5px 3px 6px -7px rgba(0,0,0,0.12)'
    boxShadow: 'none',
  },
  buttonopenericon: {
    width: '16px',
    height: '16px',
  },
  paper: {
    height: 'calc(100% - 99.5px)',
    overflow: 'hidden',
  },
  listItems: {
    height: 'calc(100% - 91px)',
    // overflow: 'auto'
    overflow: 'hidden',
  },
  hourTyp: {
    width: '40px',
  },
  root: {
    borderRadius: '4px',
    minHeight: '64px',
    padding: '4px 8px',
    width: '100%',
    outline: 'none!important',
    '&:hover': {
      backgroundColor: 'rgba(253,246,237,0.9) !important',
    },
    '&$selected': {
      backgroundColor: 'rgba(248,226,198,0.9) !important',
    },
    '&:focus': {
      backgroundColor: 'transparent',
    },
  },
  badge: {
    width: '100%',
    outline: 'none!important',
  },
  selected: {
    borderRadius: '4px',
    backgroundColor: 'rgba(248,226,198,0.7) !important',
  },
  wrapper: {
    position: 'relative',
    height: '100%',
  },
  lightgrey: {
    backgroundColor: '#f2f2f2',
  },
  icon: {
    width: '50%',
    height: '100px',
  },
  padding: {
    padding: '32px 16px',
  },
  divnosteps: {
    width: 'calc(100% - 32px)',
    height: '100%',
    border: '2px dashed lightgrey',
    margin: '8px 16px',
    padding: '16px',
  },
  divselector: {
    padding: '4px 0px 0px 8px',
    height: '46px',
    backgroundColor: 'white',
  },
  white: {
    color: 'white',
  },
  allHeight: {
    height: '100%',
  },
  smallIcon: {
    width: '14px',
    height: '14px',
    color: 'grey',
  },
  warningIcon: {
    margin: '0px 4px',
    backgroundColor: '#F8E71C',
    alignItems: 'center',
    display: 'flex',
    padding: '4px',
    borderRadius: '50%',
    flexWrap: 'wrap',
    float: 'right',
  },

  rootMenu: {
    width: '240px',
  },
  subtitle: {
    fontSize: '12px',
    lineHeight: '12px',
    color: '#707070',
  },
  allWidth: {
    width: '100%',
  },
  disableButton: {
    display: 'flex',
  },
  divSearcher: {
    height: '38px',
    width: '300px',
    position: 'absolute',
    top: '20px',
    right: '20px',
    padding: '4px 8px',
    backgroundColor: '#ffffffAA',
    zIndex: '450',
    borderRadius: '4px',
  },
  smallbutton: {
    minWidth: '36px',
    padding: '0px',
    height: '36px',
  },
  vehicleInfoTitle: {
    fontSize: '9px',
    color: '#8B8B8B',
    textTransform: 'uppercase',
    fontWeight: 500,
  },
});

const getItemStyle = (isDragging: any, draggableStyle: any) => ({
  // some basic styles to make the items look a bit nicer
  userSelect: 'none',
  margin: `0px 8px 8px 8px`,
  borderRadius: '4px',
  // change background colour if dragging
  background: isDragging ? 'rgba(248,226,198,0.9)' : 'white',
  // styles we need to apply on draggables
  ...draggableStyle,
});

export { styles, getItemStyle };
