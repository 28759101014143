import {
  Grid,
} from '@material-ui/core';
import { Button, VSpacer } from '@sm-highway-web/react-components';
import { IProjectData } from 'highway-api/dist/common/interfaces/projects';
import { ISubscriptionBase } from 'highway-api/dist/common/interfaces/subscriptions';
import React, { useState } from 'react';
import CommunicationPreview from '../../../../assets/img/communication_preview.png';
import * as routing from '../../../../constants/Routing';
import { PLAN_TYPE } from '../../../../constants/Subscriptions';
import history from '../../../../helpers/History';
import FormattedMessage from '../../../FormattedMessageCustom';
import { TabButton } from './ProjectCommunication.styled';
import ProjectCommunicationEmails from './ProjectCommunicationEmails';
import ProjectCommunicationWebapp from './ProjectCommunicationWebapp';

type ProjectCommunicationProps = {
  project: IProjectData;
  organizationSubscription?: ISubscriptionBase;
};

const ProjectCommunication = ({
  project,
  organizationSubscription,
}: ProjectCommunicationProps) => {
  const [seletedTab, setSeletedTab] = useState<number>(0);

  if (!project) return null;

  return (
    organizationSubscription?.plan?.type !== PLAN_TYPE.MONKEY ? (
      <>
        <Grid container style={{ backgroundColor: '#CECDCD', paddingTop: '8px' }}>
          <Grid item>
            <TabButton
              key="select-tab-0"
              variant="contained"
              width="120px"
              onClick={() => setSeletedTab(0)}
              selected={seletedTab === 0}
              style={{
                marginLeft: '8px',
              }}
            >
              <FormattedMessage id="settings.organization.projects.edit.communication.emails" />
            </TabButton>
          </Grid>

          <Grid item>
            <TabButton
              key="select-tab-1"
              variant="contained"
              width="120px"
              onClick={() => setSeletedTab(1)}
              selected={seletedTab === 1}
            >
              <FormattedMessage id="settings.organization.projects.edit.communication.webapp" />
            </TabButton>
          </Grid>
        </Grid>

        {seletedTab === 0 ? <ProjectCommunicationEmails project={project} organizationSubscription={organizationSubscription} /> : null}
        {seletedTab === 1 ? <ProjectCommunicationWebapp project={project} /> : null}
      </>

    ) : (
      <Grid container direction="column" alignItems="center">

        <VSpacer large />

        <FormattedMessage
          tagName="span"
          id="settings.organization.projects.edit.communication.subscription_not_allowed"
        />

        <VSpacer large />

        <img src={CommunicationPreview} alt="" width="400" />

        <VSpacer large />
        <VSpacer medium />

        <Button
          type="submit"
          variant="contained"
          style={{ minWidth: '220px', color: 'white' }}
          onClick={() => history.push(routing.SETTINGS.BILLING.SUBSCRIPTION)}
        >
          <FormattedMessage id="settings.organization.projects.edit.communication.subscription_not_allowed.button" />
        </Button>
      </Grid>
    )
  );
};

export default ProjectCommunication;
