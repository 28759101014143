export const SERVICES_CREATE_SERVICES = 'SERVICES_CREATE_SERVICES';
export const SERVICES_CREATE_SERVICES_SUCCESS = 'SERVICES_CREATE_SERVICES_SUCCESS';
export const SERVICES_CREATE_SERVICES_ERROR = 'SERVICES_CREATE_SERVICES_ERROR';

export const SERVICES_UPDATE_SERVICE = 'SERVICES_UPDATE_SERVICE';
export const SERVICES_UPDATE_SERVICE_SUCCESS = 'SERVICES_UPDATE_SERVICE_SUCCESS';
export const SERVICES_UPDATE_SERVICE_ERROR = 'SERVICES_UPDATE_SERVICE_ERROR';

export const SERVICES_UPDATE_SERVICES = 'SERVICES_UPDATE_SERVICES';
export const SERVICES_UPDATE_SERVICES_SUCCESS = 'SERVICES_UPDATE_SERVICES_SUCCESS';
export const SERVICES_UPDATE_SERVICES_ERROR = 'SERVICES_UPDATE_SERVICES_ERROR';

export const SERVICES_DELETE_SERVICE = 'SERVICES_UPDATE_SERVICES';
export const SERVICES_DELETE_SERVICE_SUCCESS = 'SERVICES_DELETE_SERVICE_SUCCESS';
export const SERVICES_DELETE_SERVICE_ERROR = 'SERVICES_UPDATE_SERVICES_ERROR';

export const SERVICES_SET_TABLE_COLUMNS = 'SERVICES_SET_TABLE_COLUMNS';

export const SERVICES_DEFAULT_TABLE_COLUMNS : { [key: string]: boolean } = {
  icon: true,
  label: true,
  alerts: true,
  status: true,
  location: true,
  duration: true,
  timewindows: true,
  route_id: true,
  order: true,
  external_id: false,
  location_details: false,
  comments: false,
  reference_person: false,
  phone: false,
  webpage: false,
  optional: false,
  custom_fields: false,
};
