import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { IProjectData } from 'highway-api/dist/common/interfaces/projects';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { match as matchProps, Redirect, Route, Switch } from 'react-router-dom';
import ClientsMenuIcon from '../assets/svg/ClientsMenuIcon';
import PlanificationMenuIcon from '../assets/svg/PlanificationMenuIcon';
import VehiclesMenuIcon from '../assets/svg/VehiclesMenuIcon';
import * as routing from '../constants/Routing';
import { IOrganizationBase } from '../interfaces/organizations';
import { IUserData } from '../interfaces/users';
import Administration from '../pages/Administration';
import Clients from '../pages/Clients';
import NoProjects from '../pages/NoProjects';
import PlanPage from '../pages/Plan';
import PlansPage from '../pages/Plans';
import Settings from '../pages/Settings';
import Vehicles from '../pages/Vehicles';
import { Projects } from '../redux/actions';
import { userHelpers } from '../redux/helpers';
import FormattedMessage from './FormattedMessageCustom';
import LeftBar from './LeftBar';
import ShallNotPassComponent from './ShallNotPassComponent';
import Tutorials from './tutorials/Tutorials';

type OwnRoutesWithProjectProps = {
  match: matchProps<{ projectId?: string }>;
  location: {
    pathname: string;
  };
  history: {
    push: (...args: any[]) => any;
  };
  user?: IUserData;
  activeProjectId?: string;
  projects?: IProjectData[];
  actions: {
    setActiveProject: (...args: any[]) => any;
  };
};

type RoutesWithProjectProps = OwnRoutesWithProjectProps;

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    setActiveProject: (project: any) => dispatch(Projects.setActiveProject(project)),
  },
});

const RoutesWithProject = ({
  user,
  activeProjectId,
  actions,
  projects,
  history,
  location,
  match,
}: RoutesWithProjectProps) => {
  useEffect(() => {
    if (activeProjectId) {
      if (!match.params.projectId || activeProjectId !== match.params.projectId) {
        if (location.pathname.includes('/vehicles')) {
          history.push(`${routing.VEHICLES.replace(':projectId', activeProjectId)}`);
        } else if (location.pathname.includes('/clients')) {
          history.push(`${routing.CLIENTS.replace(':projectId', activeProjectId)}`);
        } else if (location.pathname.includes('/plans')) {
          history.push(`${routing.PLANS.replace(':projectId', activeProjectId)}`);
        }
      } else if (location.pathname.includes('/no_projects')) {
        history.push(`${routing.PLANS.replace(':projectId', activeProjectId)}`);
      }
    } else if (user && projects) {
      if (projects.length > 0) {
        let activeProject = projects.find((project: any) => project.id === match.params.projectId);
        if (!activeProject)
          activeProject = projects.find((project: any) => project.id === user.default_project_id);
        if (!activeProject) activeProject = projects[0];

        actions.setActiveProject(activeProject);

        if (
          activeProject &&
          !location.pathname.includes('/vehicles') &&
          !location.pathname.includes('/clients') &&
          !location.pathname.includes('/plans') &&
          !location.pathname.includes('/settings') &&
          !location.pathname.includes('/administration')
        ) {
          history.push(`${routing.PLANS.replace(':projectId', activeProject.id)}`);
        }
      } else if (!location.pathname.includes('/no_projects')) {
        history.push(`${routing.NO_PROJECTS}`);
      }
    }
  }, [actions, user, activeProjectId, projects, match, history, location.pathname]);

  return (
    <>
      <Route path={`${match.url}/clients`} component={Clients} />
      <Route path={`${match.url}/vehicles`} component={Vehicles} />
      <Route exact path={`${match.url}/plans/:planId/:view?`} component={PlanPage} />
      <Route exact path={`${match.url}/plans`} component={PlansPage} />
    </>
  );
};

RoutesWithProject.defaultProps = {
  activeProjectId: undefined,
};

const ConnectedRoutesWithProject = connect(null, mapDispatchToProps)(RoutesWithProject);

const styles = {
  containerDiv: {
    minHeight: '100%',
    width: 'calc(100vw - 48px)',
    marginLeft: '48px',
    backgroundColor: 'white',
    position: 'relative',
    overflow: 'hidden',
  },
};

type OwnPrivateComponentProps = {
  classes: {
    containerDiv: string;
  };
  isLogged: boolean;
  match: matchProps<{ projectId?: string }>;
  location: {
    pathname: string;
  };
  history: {
    push: (...args: any[]) => any;
  };
  user?: IUserData;
  organization?: IOrganizationBase;
  activeProject?: IProjectData;
  projects?: IProjectData[];
};

type PrivateComponentProps = OwnPrivateComponentProps;

const PrivateComponent = ({
  isLogged,
  match,
  user,
  organization,
  activeProject,
  projects,
  history,
  location,
  classes,
}: PrivateComponentProps) => {
  const [tab, setTab] = useState(0);
  const [currentLocation, setCurrentLocation] = useState();

  useEffect(() => {
    if (!currentLocation || currentLocation !== location.pathname) {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
      setCurrentLocation(location.pathname);
      if (location.pathname.includes('/vehicles')) setTab(3);
      if (location.pathname.includes('/clients')) setTab(2);
      if (location.pathname.includes('/plans')) setTab(1);
    }
  }, [currentLocation, location, setTab, setCurrentLocation]);

  const handleChangeTab = (selectedTab: any) => {
    switch (selectedTab) {
      case 1:
        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        history.push(`${routing.PLANS.replace(':projectId', activeProject.id)}`);
        break;
      case 2:
        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        history.push(`${routing.CLIENTS.replace(':projectId', activeProject.id)}`);
        break;
      case 3:
        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        history.push(`${routing.VEHICLES.replace(':projectId', activeProject.id)}`);
        break;
      default:
        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
        history.push(`${routing.PLANS.replace(':projectId', activeProject.id)}`);
    }

    setTab(selectedTab);
  };

  if (!isLogged) {
    return (
      <Redirect
        to={`/login${
          location.pathname && location.pathname !== '' ? `?redirectTo=${location.pathname}` : ``
        }`}
      />
    );
  }

  return (
    <>
      <div style={{ height: '100vh', overflowY: 'hidden' }}>
        <LeftBar
          projectsCallback={(type: any) => {
            switch (type) {
              case 'manage-projects':
                history.push(routing.SETTINGS.ORGANIZATION.PROJECTS.ROOT);
                break;
              case 'add-project':
                history.push(`${routing.SETTINGS.ORGANIZATION.PROJECTS.ROOT}?create=true`);
                break;
              default:
                break;
            }
          }}
          menuItems={[
            {
              id: 'plans',
              label: <FormattedMessage id="plans.plans" />,
              icon: PlanificationMenuIcon,
              color: tab === 1 ? '#FF8200' : 'white',
              onClick: () => (activeProject ? handleChangeTab(1) : null),
            },
            {
              id: 'clients',
              label: <FormattedMessage id="clients.clients" />,
              icon: ClientsMenuIcon,
              color: tab === 2 ? '#FF8200' : 'white',
              onClick: () => (activeProject ? handleChangeTab(2) : null),
            },
            {
              id: 'vehicles',
              label: <FormattedMessage id="vehicles.vehicles" />,
              icon: VehiclesMenuIcon,
              color: tab === 3 ? '#FF8200' : 'white',
              onClick: () => (activeProject ? handleChangeTab(3) : null),
            },
          ]}
        />
        <Grid className={classes.containerDiv}>
          <Switch>
            {user &&
              organization &&
              ((organization.subscription &&
                organization.subscription.valid &&
                new Date(organization.subscription.valid) >= new Date() &&
                organization.subscription.status !== 'canceled' &&
                organization.subscription.status !== 'incomplete_expired') ||
                userHelpers.currentUser.isAdmin()) && (
              <>
                <Route path={`${match.url}/settings`} component={Settings} />
                <Route path={`${match.url}/administration`} component={Administration} />

                <Tutorials />

                <Route
                  path={`${match.url}/:projectId?`}
                  render={({ match: renderMatch }: any) => (
                    <ConnectedRoutesWithProject
                      history={history}
                      location={location}
                      match={renderMatch}
                      activeProjectId={activeProject ? activeProject.id : undefined}
                      user={user}
                      projects={projects}
                    />
                  )}
                />

                <Route exact path={`${match.url}/`}>
                  <Redirect
                    to={`${
                      activeProject
                        ? routing.PLANS.replace(':projectId', activeProject.id)
                        : routing.PLANS
                    }`}
                  />
                </Route>
                <Route exact path={`${match.url}/no_projects`} component={NoProjects} />
                <Route exact path={`${match.url}/`}>
                  <Redirect to={`${routing.NO_PROJECTS}`} />
                </Route>
              </>
            )}
            {user && organization && (
              <Route path={`${match.url}/`} component={ShallNotPassComponent} />
            )}
          </Switch>
        </Grid>
      </div>
    </>
  );
};

PrivateComponent.defaultProps = {
  user: undefined,
  organization: undefined,
  activeProject: undefined,
  projects: undefined,
};

const mapStateToProps = (state: any) => {
  return {
    isLogged: state.user.isLogged,
    user: state.user.user,
    language: state.user.language,
    organization: state.organization.organization,
    activeProject: state.projects.activeProject,
    projects: state.projects.projects && state.user.user ? state.projects.projects : undefined,
  };
};

// @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ containerDiv: { minHeight: str... Remove this comment to see the full error message
export default connect(mapStateToProps, null)(withStyles(styles)(PrivateComponent));
