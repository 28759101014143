export const vehicles_PE = [
  {
    end_location: {
      lat: -11.93561,
      lng: -77.08584,
      country: 'Perú',
      country_code: 'PER',
      state: 'Lima',
      county: 'Lima',
      postal_code: '15116',
      label: 'Asociacion de Vivienda Valle Chillon, Avenida A, 15116 Puente Piedra, Perú',
      city: 'Puente Piedra',
      district: 'Valle Chillón',
      street: 'Avenida A',
    },
    label: 'Manuel',
    max_services: 17,
    start_location: {
      lat: -11.93561,
      lng: -77.08584,
      country: 'Perú',
      country_code: 'PER',
      state: 'Lima',
      county: 'Lima',
      postal_code: '15116',
      label: 'Asociacion de Vivienda Valle Chillon, Avenida A, 15116 Puente Piedra, Perú',
      city: 'Puente Piedra',
      district: 'Valle Chillón',
      street: 'Avenida A',
    },
    timewindow: [32400, 57600],
  },
  {
    end_location: {
      lat: -12.01989,
      lng: -77.08651,
      country: 'Perú',
      country_code: 'PER',
      state: 'Lima',
      county: 'Lima',
      house_number: '163',
      postal_code: '15107',
      label: 'Avenida Los Próceres 163, 15107 San Martín de Porres, Perú',
      city: 'San Martín de Porres',
      district: 'Condevilla Señor 2da Etapa Sec. II',
      street: 'Avenida Los Próceres',
    },
    label: 'Jose',
    max_services: 17,
    start_location: {
      lat: -12.01989,
      lng: -77.08651,
      country: 'Perú',
      country_code: 'PER',
      state: 'Lima',
      county: 'Lima',
      house_number: '163',
      postal_code: '15107',
      label: 'Avenida Los Próceres 163, 15107 San Martín de Porres, Perú',
      city: 'San Martín de Porres',
      district: 'Condevilla Señor 2da Etapa Sec. II',
      street: 'Avenida Los Próceres',
    },
    timewindow: [39600, 64800],
  },
  {
    end_location: {
      lat: -12.21329,
      lng: -76.90633,
      country: 'Perú',
      country_code: 'PER',
      state: 'Lima',
      county: 'Lima',
      house_number: '1045',
      postal_code: '15822',
      label: 'Avenida Lima 1045, 15822 Villa María del Triunfo, Perú',
      city: 'Villa María del Triunfo',
      district: 'Villa Poeta José Galvez Parcela B',
      street: 'Avenida Lima',
    },
    label: 'Pedro',
    max_services: 17,
    start_location: {
      lat: -12.21329,
      lng: -76.90633,
      country: 'Perú',
      country_code: 'PER',
      state: 'Lima',
      county: 'Lima',
      house_number: '1045',
      postal_code: '15822',
      label: 'Avenida Lima 1045, 15822 Villa María del Triunfo, Perú',
      city: 'Villa María del Triunfo',
      district: 'Villa Poeta José Galvez Parcela B',
      street: 'Avenida Lima',
    },
    timewindow: [32400, 57600],
  },
  {
    end_location: {
      lat: -12.22494,
      lng: -76.9424,
      country: 'Perú',
      country_code: 'PER',
      state: 'Lima',
      county: 'Lima',
      postal_code: '15837',
      label: 'Sector 6 Grupo 8A, Lima, Perú',
      city: 'Lima',
      district: 'Sector 6 Grupo 8A',
    },
    label: 'Pablo',
    max_services: 17,
    start_location: {
      lat: -12.22494,
      lng: -76.9424,
      country: 'Perú',
      country_code: 'PER',
      state: 'Lima',
      county: 'Lima',
      postal_code: '15837',
      label: 'Sector 6 Grupo 8A, Lima, Perú',
      city: 'Lima',
      district: 'Sector 6 Grupo 8A',
    },
    timewindow: [39600, 64800],
  },
];

export const services_PE = [
  {
    label: 'RESTAURANTE MAIDO',
    location: {
      lat: -11.93561,
      lng: -77.08584,
      country: 'Perú',
      country_code: 'PER',
      state: 'Lima',
      county: 'Lima',
      postal_code: '15116',
      label: 'Asociacion de Vivienda Valle Chillon, Avenida A, 15116 Puente Piedra, Perú',
      city: 'Puente Piedra',
      district: 'Valle Chillón',
      street: 'Avenida A',
    },
    duration: 300,
    timewindows: [[28800, 36000]],
  },
  {
    label: 'RESTAURANTE CENTRAL',
    location: {
      lat: -12.01989,
      lng: -77.08651,
      country: 'Perú',
      country_code: 'PER',
      state: 'Lima',
      county: 'Lima',
      house_number: '163',
      postal_code: '15107',
      label: 'Avenida Los Próceres 163, 15107 San Martín de Porres, Perú',
      city: 'San Martín de Porres',
      district: 'Condevilla Señor 2da Etapa Sec. II',
      street: 'Avenida Los Próceres',
    },
    duration: 300,
    timewindows: [[28800, 46800]],
  },
  {
    label: 'RESTAURANTE PUJOL',
    location: {
      lat: -12.21329,
      lng: -76.90633,
      country: 'Perú',
      country_code: 'PER',
      state: 'Lima',
      county: 'Lima',
      house_number: '1045',
      postal_code: '15822',
      label: 'Avenida Lima 1045, 15822 Villa María del Triunfo, Perú',
      city: 'Villa María del Triunfo',
      district: 'Villa Poeta José Galvez Parcela B',
      street: 'Avenida Lima',
    },
    duration: 300,
    timewindows: [[28800, 46800]],
  },
  {
    label: 'RESTAURANTE DON JULIO',
    location: {
      lat: -12.22494,
      lng: -76.9424,
      country: 'Perú',
      country_code: 'PER',
      state: 'Lima',
      county: 'Lima',
      postal_code: '15837',
      label: 'Sector 6 Grupo 8A, Lima, Perú',
      city: 'Lima',
      district: 'Sector 6 Grupo 8A',
    },
    duration: 300,
    timewindows: [[28800, 46800]],
  },
  {
    label: 'RESTAURANTE BORAGO',
    location: {
      lat: -12.12041,
      lng: -77.0029,
      country: 'Perú',
      country_code: 'PER',
      state: 'Lima',
      county: 'Lima',
      house_number: '871',
      postal_code: '15038',
      label: 'Avenida Manuel Villarán 871, 15038 Surquillo, Perú',
      city: 'Surquillo',
      district: 'Los Sauces',
      street: 'Avenida Manuel Villarán',
    },
    duration: 300,
    timewindows: [[28800, 45000]],
  },
  {
    label: 'RESTAURANTE A CASA DO PORCO',
    location: {
      lat: -12.11745,
      lng: -77.00556,
      country: 'Perú',
      country_code: 'PER',
      state: 'Lima',
      county: 'Lima',
      house_number: '567',
      postal_code: '15038',
      label: 'Avenida Principal 567, 15038 Surquillo, Perú',
      city: 'Surquillo',
      district: 'La Calera de la Merced',
      street: 'Avenida Principal',
    },
    duration: 600,
    timewindows: [[28800, 45000]],
  },
  {
    label: 'RESTAURANTE EL CHATO',
    location: {
      lat: -12.12067,
      lng: -77.03362,
      country: 'Perú',
      country_code: 'PER',
      state: 'Lima',
      county: 'Lima',
      house_number: '389',
      postal_code: '15074',
      label: 'Calle Berlín 389, 15074 Miraflores, Perú',
      city: 'Miraflores',
      district: 'San Miguel de Miraflores',
      street: 'Calle Berlín',
    },
    duration: 300,
    timewindows: [[28800, 45000]],
  },
  {
    label: 'RESTAURANTE LEO',
    location: {
      lat: -12.11497,
      lng: -77.04526,
      country: 'Perú',
      country_code: 'PER',
      state: 'Lima',
      county: 'Lima',
      postal_code: '15074',
      label: 'Santa Cruz, Lima, Perú',
      city: 'Lima',
      district: 'Santa Cruz',
    },
    duration: 300,
    timewindows: [[28800, 45000]],
  },
  {
    label: 'RESTAURANTE OSSO',
    location: {
      lat: -12.0773,
      lng: -77.02803,
      country: 'Perú',
      country_code: 'PER',
      state: 'Lima',
      county: 'Lima',
      house_number: '1403',
      postal_code: '15034',
      label: 'Avenida Paseo de la República 1403, 15034 La Victoria, Perú',
      city: 'La Victoria',
      district: 'Balconcillo',
      street: 'Avenida Paseo de la República',
    },
    duration: 300,
    timewindows: [[28800, 46800]],
  },
  {
    label: 'RESTAURANTE D.O.M.',
    location: {
      lat: -12.03373,
      lng: -76.93696,
      country: 'Perú',
      country_code: 'PER',
      state: 'Lima',
      county: 'Lima',
      house_number: '186',
      postal_code: '15498',
      label: 'Calle El Sol 186, 15498 Ate, Perú',
      city: 'Ate',
      district: 'Sol de Vitarte Sector G',
      street: 'Calle El Sol',
    },
    duration: 600,
    timewindows: [[28800, 46800]],
  },
  {
    label: 'RESTAURANTE QUINTONIL',
    location: {
      lat: -12.18089,
      lng: -76.94394,
      country: 'Perú',
      country_code: 'PER',
      state: 'Lima',
      county: 'Lima',
      house_number: '2302',
      postal_code: '15816',
      label: 'Avenida Pachacútec 2302, 15816 Villa María del Triunfo, Perú',
      city: 'Villa María del Triunfo',
      district: 'José Bernardo Alcedo',
      street: 'Avenida Pachacútec',
    },
    duration: 300,
    timewindows: [[28800, 46800]],
  },
  {
    label: 'RESTAURANTE ISOLINA',
    location: {
      lat: -12.16066,
      lng: -76.94239,
      country: 'Perú',
      country_code: 'PER',
      state: 'Lima',
      county: 'Lima',
      postal_code: '15809',
      label: 'Avenida Villa María del Triunfo, 15809 Villa María del Triunfo, Perú',
      city: 'Villa María del Triunfo',
      street: 'Avenida Villa María del Triunfo',
    },
    duration: 420,
    timewindows: [[28800, 46800]],
  },
  {
    label: 'RESTAURANTE ASTRID Y GASTON',
    location: {
      lat: -12.16839,
      lng: -76.94989,
      country: 'Perú',
      country_code: 'PER',
      state: 'Lima',
      county: 'Lima',
      house_number: '142',
      postal_code: '15809',
      label: 'Avenida El Sol 142, 15809 Villa María del Triunfo, Perú',
      city: 'Villa María del Triunfo',
      district: 'Primer Hogar Policial',
      street: 'Avenida El Sol',
    },
    duration: 480,
    timewindows: [[28800, 46800]],
  },
  {
    label: 'RESTAURANTE ALCALDE',
    location: {
      lat: -12.16029,
      lng: -76.95685,
      country: 'Perú',
      country_code: 'PER',
      state: 'Lima',
      county: 'Lima',
      postal_code: '15809',
      label: 'Avenida Los Héroes, 15809 San Juan de Miraflores, Perú',
      city: 'San Juan de Miraflores',
      street: 'Avenida Los Héroes',
    },
    duration: 300,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE PANGEA',
    location: {
      lat: -12.14159,
      lng: -76.97493,
      country: 'Perú',
      country_code: 'PER',
      state: 'Lima',
      county: 'Lima',
      house_number: '150',
      postal_code: '15803',
      label: 'Avenida Salvador Allende 150, 15803 Lima, Perú',
      city: 'Lima',
      district: 'Loyola 1ra Etapa',
      street: 'Avenida Salvador Allende',
    },
    duration: 300,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE SUD 777',
    location: {
      lat: -12.14791,
      lng: -76.97393,
      country: 'Perú',
      country_code: 'PER',
      state: 'Lima',
      county: 'Lima',
      postal_code: '15803',
      label: 'Pamplona Baja, San Juan de Miraflores, Lima, Perú',
      city: 'San Juan de Miraflores',
      district: 'Pamplona Baja',
    },
    duration: 300,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE MAITO',
    location: {
      lat: -12.14978,
      lng: -76.98693,
      country: 'Perú',
      country_code: 'PER',
      state: 'Lima',
      county: 'Lima',
      house_number: '263',
      postal_code: '15056',
      label: 'Avenida Los Próceres 263, 15056 Santiago de Surco, Perú',
      city: 'Santiago de Surco',
      district: 'Los Próceres',
      street: 'Avenida Los Próceres',
    },
    duration: 300,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE MANI',
    location: {
      lat: -12.16708,
      lng: -76.99216,
      country: 'Perú',
      country_code: 'PER',
      state: 'Lima',
      county: 'Lima',
      postal_code: '15054',
      label: 'Los Parrales de Surco, Santiago de Surco, Lima, Perú',
      city: 'Santiago de Surco',
      district: 'Los Parrales de Surco',
    },
    duration: 600,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE RAFAEL',
    location: {
      lat: -12.17703,
      lng: -76.99748,
      country: 'Perú',
      country_code: 'PER',
      state: 'Lima',
      county: 'Lima',
      house_number: '1197',
      postal_code: '15056',
      label: 'Avenida El Sol 1197, 15056 Chorrillos, Perú',
      city: 'Chorrillos',
      district: 'La Campiña',
      street: 'Avenida El Sol',
    },
    duration: 300,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE MISHIGUENE',
    location: {
      lat: -12.17623,
      lng: -77.00692,
      country: 'Perú',
      country_code: 'PER',
      state: 'Lima',
      county: 'Lima',
      house_number: '401',
      postal_code: '15064',
      label: 'Avenida Prolongación Ariosto Matellini 401, 15064 Chorrillos, Perú',
      city: 'Chorrillos',
      district: 'Matellini',
      street: 'Avenida Prolongación Ariosto Matellini',
    },
    duration: 300,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE KJOLLE',
    location: {
      lat: -12.17806,
      lng: -77.009,
      country: 'Perú',
      country_code: 'PER',
      state: 'Lima',
      county: 'Lima',
      house_number: '183',
      postal_code: '15064',
      label: 'Avenida Prolongación Ariosto Matellini 183, 15064 Chorrillos, Perú',
      city: 'Chorrillos',
      district: 'Paseo de la Republica',
      street: 'Avenida Prolongación Ariosto Matellini',
    },
    duration: 300,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE HARRY SASSON',
    location: {
      lat: -12.17644,
      lng: -77.01678,
      country: 'Perú',
      country_code: 'PER',
      state: 'Lima',
      county: 'Lima',
      house_number: '1475',
      postal_code: '15064',
      label: 'Avenida Defensores del Morro 1475, 15064 Chorrillos, Perú',
      city: 'Chorrillos',
      district: 'Santa Laura',
      street: 'Avenida Defensores del Morro',
    },
    duration: 600,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE OTEQUE',
    location: {
      lat: -12.17268,
      lng: -77.01557,
      country: 'Perú',
      country_code: 'PER',
      state: 'Lima',
      county: 'Lima',
      postal_code: '15064',
      label: 'Avenida Prolongación Paseo de la República, 15064 Chorrillos, Perú',
      city: 'Chorrillos',
      street: 'Avenida Prolongación Paseo de la República',
    },
    duration: 300,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE LASAI',
    location: {
      lat: -12.1741,
      lng: -77.01558,
      country: 'Perú',
      country_code: 'PER',
      state: 'Lima',
      county: 'Lima',
      house_number: '151',
      postal_code: '15064',
      label: 'Jirón Costa Azul 151, 15064 Chorrillos, Perú',
      city: 'Chorrillos',
      district: 'Martir José Olaya',
      street: 'Jirón Costa Azul',
    },
    duration: 540,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE TEGUI',
    location: {
      lat: -12.16758,
      lng: -77.02587,
      country: 'Perú',
      country_code: 'PER',
      state: 'Lima',
      county: 'Lima',
      house_number: '314',
      postal_code: '15064',
      label: 'Avenida Defensores del Morro 314, 15064 Lima, Perú',
      city: 'Lima',
      district: 'Los Laureles',
      street: 'Avenida Defensores del Morro',
    },
    duration: 600,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE LA MAR',
    location: {
      lat: -12.16815,
      lng: -77.02542,
      country: 'Perú',
      country_code: 'PER',
      state: 'Lima',
      county: 'Lima',
      house_number: '511',
      postal_code: '15064',
      label: 'Avenida Alejandro Iglesias 511, 15064 Chorrillos, Perú',
      city: 'Chorrillos',
      district: 'Los Laureles',
      street: 'Avenida Alejandro Iglesias',
    },
    duration: 300,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE ROSSETTA',
    location: {
      lat: -12.16834,
      lng: -77.02512,
      country: 'Perú',
      country_code: 'PER',
      state: 'Lima',
      county: 'Lima',
      house_number: '444',
      postal_code: '15064',
      label: 'Avenida Defensores del Morro 444, 15064 Chorrillos, Perú',
      city: 'Chorrillos',
      district: 'Los Laureles',
      street: 'Avenida Defensores del Morro',
    },
    duration: 300,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE MAXIMO BISTROT',
    location: {
      lat: -12.17343,
      lng: -77.0203,
      country: 'Perú',
      country_code: 'PER',
      state: 'Lima',
      county: 'Lima',
      house_number: '1010',
      postal_code: '15064',
      label: 'Avenida Defensores del Morro 1010, 15064 Chorrillos, Perú',
      city: 'Chorrillos',
      district: 'Los Laureles',
      street: 'Avenida Defensores del Morro',
    },
    duration: 300,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE CHILA',
    location: {
      lat: -12.17958,
      lng: -77.01435,
      country: 'Perú',
      country_code: 'PER',
      state: 'Lima',
      county: 'Lima',
      house_number: '1890',
      postal_code: '15066',
      label: 'Avenida Defensores del Morro 1890, 15066 Chorrillos, Perú',
      city: 'Chorrillos',
      district: 'La Villa Zona Industrial',
      street: 'Avenida Defensores del Morro',
    },
    duration: 300,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE AMBROSIA',
    location: {
      lat: -12.18651,
      lng: -77.00795,
      country: 'Perú',
      country_code: 'PER',
      state: 'Lima',
      county: 'Lima',
      house_number: '110',
      postal_code: '15066',
      label: 'Avenida Villa Marina 110, 15066 Chorrillos, Perú',
      city: 'Chorrillos',
      district: 'Villa Marina',
      street: 'Avenida Villa Marina',
    },
    duration: 300,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE NICOS',
    location: {
      lat: -12.1903,
      lng: -77.00658,
      country: 'Perú',
      country_code: 'PER',
      state: 'Lima',
      county: 'Lima',
      house_number: '102',
      postal_code: '15066',
      label: 'Avenida Alameda Sur 102, 15066 Chorrillos, Perú',
      city: 'Chorrillos',
      district: 'Villa Marina',
      street: 'Avenida Alameda Sur',
    },
    duration: 600,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE LE CHIQUE',
    location: {
      lat: -12.16787,
      lng: -76.98083,
      country: 'Perú',
      country_code: 'PER',
      state: 'Lima',
      county: 'Lima',
      postal_code: '15056',
      label: 'Los Forestales, San Juan de Miraflores, Lima, Perú',
      city: 'San Juan de Miraflores',
      district: 'Los Forestales',
    },
    duration: 300,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE PARADOR LA HUELLA',
    location: {
      lat: -12.16672,
      lng: -76.97774,
      country: 'Perú',
      country_code: 'PER',
      state: 'Lima',
      county: 'Lima',
      house_number: '124',
      postal_code: '15801',
      label: 'Avenida Ramón Vargas Machuca 124, 15801 San Juan de Miraflores, Perú',
      city: 'San Juan de Miraflores',
      district: 'San Juan Unidad B',
      street: 'Avenida Ramón Vargas Machuca',
    },
    duration: 300,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE LE PATIO',
    location: {
      lat: -12.16503,
      lng: -76.97322,
      country: 'Perú',
      country_code: 'PER',
      state: 'Lima',
      county: 'Lima',
      postal_code: '15801',
      label: 'San Juan Zonas E4 y E1C, San Juan de Miraflores, Lima, Perú',
      city: 'San Juan de Miraflores',
      district: 'San Juan Zonas E4 y E1C',
    },
    duration: 300,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE OLIMPE',
    location: {
      lat: -12.16249,
      lng: -76.97057,
      country: 'Perú',
      country_code: 'PER',
      state: 'Lima',
      county: 'Lima',
      postal_code: '15801',
      label: 'San Juan de Miraflores, Lima, Perú',
      city: 'San Juan de Miraflores',
    },
    duration: 600,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE MIL',
    location: {
      lat: -12.16249,
      lng: -76.97057,
      country: 'Perú',
      country_code: 'PER',
      state: 'Lima',
      county: 'Lima',
      postal_code: '15801',
      label: 'San Juan de Miraflores, Lima, Perú',
      city: 'San Juan de Miraflores',
    },
    duration: 300,
    timewindows: [[46800, 57600]],
  },
  {
    label: 'RESTAURANTE 040',
    location: {
      lat: -12.1716,
      lng: -76.96832,
      country: 'Perú',
      country_code: 'PER',
      state: 'Lima',
      county: 'Lima',
      postal_code: '15824',
      label: 'Valle del Sharon, San Juan de Miraflores, Lima, Perú',
      city: 'San Juan de Miraflores',
      district: 'Valle del Sharon',
    },
    duration: 660,
    timewindows: [[46800, 57600]],
  },
  {
    label: 'RESTAURANTE LA DOCENA',
    location: {
      lat: -12.16261,
      lng: -76.96205,
      country: 'Perú',
      country_code: 'PER',
      state: 'Lima',
      county: 'Lima',
      house_number: '1127',
      postal_code: '15801',
      label: 'Avenida Guillermo Billinghurst 1127, 15801 San Juan de Miraflores, Perú',
      city: 'San Juan de Miraflores',
      district: 'San Juan de Miraflores Parcela D',
      street: 'Avenida Guillermo Billinghurst',
    },
    duration: 720,
    timewindows: [[46800, 57600]],
  },
  {
    label: 'RESTAURANTE MANU',
    location: {
      lat: -12.16021,
      lng: -76.96826,
      country: 'Perú',
      country_code: 'PER',
      state: 'Lima',
      county: 'Lima',
      house_number: '773',
      postal_code: '15801',
      label: 'Avenida Guillermo Billinghurst 773, 15801 Lima, Perú',
      city: 'Lima',
      district: 'San Juan de Miraflores Parcela D',
      street: 'Avenida Guillermo Billinghurst',
    },
    duration: 300,
    timewindows: [[46800, 57600]],
  },
  {
    label: 'RESTAURANTE MOCOTO',
    location: {
      lat: -12.15551,
      lng: -76.96906,
      country: 'Perú',
      country_code: 'PER',
      state: 'Lima',
      county: 'Lima',
      house_number: '628',
      postal_code: '15801',
      label: 'Avenida Los Héroes 628, 15801 San Juan de Miraflores, Perú',
      city: 'San Juan de Miraflores',
      district: 'San Juan de Miraflores Parcela D',
      street: 'Avenida Los Héroes',
    },
    duration: 300,
    timewindows: [[46800, 57600]],
  },
  {
    label: 'RESTAURANTE OSAKA',
    location: {
      lat: -12.14164,
      lng: -76.9852,
      country: 'Perú',
      country_code: 'PER',
      state: 'Lima',
      county: 'Lima',
      postal_code: '15039',
      label: 'Prolongación Benavides III Etapa, Santiago de Surco, Lima, Perú',
      city: 'Santiago de Surco',
      district: 'Prolongación Benavides III Etapa',
    },
    duration: 300,
    timewindows: [[46800, 57600]],
  },
  {
    label: 'RESTAURANTE ELENA',
    location: {
      lat: -12.14291,
      lng: -76.98529,
      country: 'Perú',
      country_code: 'PER',
      state: 'Lima',
      county: 'Lima',
      house_number: '3170',
      postal_code: '15039',
      label: 'Avenida Caminos del Inca 3170, 15039 Santiago de Surco, Perú',
      city: 'Santiago de Surco',
      district: 'Prolongación Benavides II Etapa',
      street: 'Avenida Caminos del Inca',
    },
    duration: 300,
    timewindows: [[46800, 57600]],
  },
  {
    label: 'RESTAURANTE GRAN DABBANG',
    location: {
      lat: -12.13936,
      lng: -76.98873,
      country: 'Perú',
      country_code: 'PER',
      state: 'Lima',
      county: 'Lima',
      house_number: '398',
      postal_code: '15039',
      label: 'Jirón Loma Redonda 398, 15039 Santiago de Surco, Perú',
      city: 'Santiago de Surco',
      district: 'Prolongación Benavides II Etapa',
      street: 'Jirón Loma Redonda',
    },
    duration: 300,
    timewindows: [[46800, 57600]],
  },
  {
    label: 'RESTAURANTE 99',
    location: {
      lat: -12.13486,
      lng: -76.98656,
      country: 'Perú',
      country_code: 'PER',
      state: 'Lima',
      county: 'Lima',
      house_number: '299',
      postal_code: '15039',
      label: 'Avenida Monte de los Olivos 299, 15039 Santiago de Surco, Perú',
      city: 'Santiago de Surco',
      district: 'Prolongación Benavides I Etapa',
      street: 'Avenida Monte de los Olivos',
    },
    duration: 600,
    timewindows: [[46800, 57600]],
  },
  {
    label: 'RESTAURANTE MALABAR',
    location: {
      lat: -12.13596,
      lng: -76.98214,
      country: 'Perú',
      country_code: 'PER',
      state: 'Lima',
      county: 'Lima',
      postal_code: '15039',
      label: 'Monterrico Sur, Santiago de Surco, Lima, Perú',
      city: 'Santiago de Surco',
      district: 'Monterrico Sur',
    },
    duration: 300,
    timewindows: [[46800, 57600]],
  },
  {
    label: 'RESTAURANTE MAYTA',
    location: {
      lat: -12.13102,
      lng: -76.98333,
      country: 'Perú',
      country_code: 'PER',
      state: 'Lima',
      county: 'Lima',
      postal_code: '15039',
      label: 'Santiago de Surco, Lima, Perú',
      city: 'Santiago de Surco',
    },
    duration: 300,
    timewindows: [[46800, 57600]],
  },
  {
    label: 'RESTAURANTE NARDA COMEDOR',
    location: {
      lat: -12.12998,
      lng: -76.98072,
      country: 'Perú',
      country_code: 'PER',
      state: 'Lima',
      county: 'Lima',
      house_number: '5371',
      postal_code: '15039',
      label: 'Avenida Alfredo Benavides 5371, 15039 Santiago de Surco, Perú',
      city: 'Santiago de Surco',
      district: 'Las Gardenias',
      street: 'Avenida Alfredo Benavides',
    },
    duration: 300,
    timewindows: [[46800, 57600]],
  },
  {
    label: 'HOTEL NH',
    location: {
      lat: -12.12882,
      lng: -76.98507,
      country: 'Perú',
      country_code: 'PER',
      state: 'Lima',
      county: 'Lima',
      house_number: '5004',
      postal_code: '15039',
      label: 'Avenida Alfredo Benavides 5004, 15039 Santiago de Surco, Perú',
      city: 'Santiago de Surco',
      district: 'Las Gardenias',
      street: 'Avenida Alfredo Benavides',
    },
    duration: 600,
    timewindows: [[46800, 57600]],
  },
  {
    label: 'HOTEL MARIA BONITA',
    location: {
      lat: -12.12798,
      lng: -76.99212,
      country: 'Perú',
      country_code: 'PER',
      state: 'Lima',
      county: 'Lima',
      house_number: '4215',
      postal_code: '15038',
      label: 'Avenida Alfredo Benavides 4215, 15038 Santiago de Surco, Perú',
      city: 'Santiago de Surco',
      district: 'El Dorado',
      street: 'Avenida Alfredo Benavides',
    },
    duration: 300,
    timewindows: [[46800, 57600]],
  },
  {
    label: 'HOTEL W',
    location: {
      lat: -12.13252,
      lng: -76.99543,
      country: 'Perú',
      country_code: 'PER',
      state: 'Lima',
      county: 'Lima',
      house_number: '1355',
      postal_code: '15038',
      label: 'Avenida Ayacucho 1355, 15038 Lima, Perú',
      city: 'Lima',
      district: 'Liguria',
      street: 'Avenida Ayacucho',
    },
    duration: 780,
    timewindows: [[46800, 57600]],
  },
  {
    label: 'HOTEL SHERATON',
    location: {
      lat: -12.13524,
      lng: -76.99736,
      country: 'Perú',
      country_code: 'PER',
      state: 'Lima',
      county: 'Lima',
      house_number: '1020',
      postal_code: '15049',
      label: 'Avenida Ayacucho 1020, 15049 Santiago de Surco, Perú',
      city: 'Santiago de Surco',
      district: 'Los Rosales',
      street: 'Avenida Ayacucho',
    },
    duration: 840,
    timewindows: [[46800, 57600]],
  },
  {
    label: 'BAR AMSTERDAM',
    location: {
      lat: -12.15029,
      lng: -77.00644,
      country: 'Perú',
      country_code: 'PER',
      state: 'Lima',
      county: 'Lima',
      house_number: '1519',
      postal_code: '15049',
      label: 'Avenida Jorge Chávez 1519, 15049 Santiago de Surco, Perú',
      city: 'Santiago de Surco',
      district: 'Cercado',
      street: 'Avenida Jorge Chávez',
    },
    duration: 300,
    timewindows: [[46800, 57600]],
  },
  {
    label: 'RESTAURANTE COLOMBIA PAN Y SABOR',
    location: {
      lat: -12.14511,
      lng: -77.00403,
      country: 'Perú',
      country_code: 'PER',
      state: 'Lima',
      county: 'Lima',
      house_number: '287',
      postal_code: '15049',
      label: 'Calle Sáenz Peña 287, 15049 Santiago de Surco, Perú',
      city: 'Santiago de Surco',
      district: 'Cercado',
      street: 'Calle Sáenz Peña',
    },
    duration: 300,
    timewindows: [[46800, 57600]],
  },
  {
    label: 'RESTAURANTE LA VIETNAMITA',
    location: {
      lat: -12.14501,
      lng: -77.00399,
      country: 'Perú',
      country_code: 'PER',
      state: 'Lima',
      county: 'Lima',
      house_number: '335',
      postal_code: '15049',
      label: 'Jirón Batalla de Ayacucho 335, 15049 Santiago de Surco, Perú',
      city: 'Santiago de Surco',
      district: 'Cercado',
      street: 'Jirón Batalla de Ayacucho',
    },
    duration: 300,
    timewindows: [[28800, 46800]],
  },
  {
    label: 'RESTAURANTE EL TLAXCAL',
    location: {
      lat: -12.14173,
      lng: -77.00146,
      country: 'Perú',
      country_code: 'PER',
      state: 'Lima',
      county: 'Lima',
      house_number: '198',
      postal_code: '15049',
      label: 'Avenida Ayacucho 198, 15049 Santiago de Surco, Perú',
      city: 'Santiago de Surco',
      district: 'Los Rosales',
      street: 'Avenida Ayacucho',
    },
    duration: 300,
    timewindows: [[28800, 46800]],
  },
  {
    label: 'RESTAURANTE LA CHILANGUITA',
    location: {
      lat: -12.13148,
      lng: -77.00157,
      country: 'Perú',
      country_code: 'PER',
      state: 'Lima',
      county: 'Lima',
      postal_code: '15048',
      label: 'La Castellana, Santiago de Surco, Lima, Perú',
      city: 'Santiago de Surco',
      district: 'La Castellana',
    },
    duration: 600,
    timewindows: [[28800, 46800]],
  },
  {
    label: 'RESTAURANTE LA COSA NOSTRA',
    location: {
      lat: -12.12861,
      lng: -76.99479,
      country: 'Perú',
      country_code: 'PER',
      state: 'Lima',
      county: 'Lima',
      house_number: '3882',
      postal_code: '15038',
      label: 'Avenida Alfredo Benavides 3882, 15038 Santiago de Surco, Perú',
      city: 'Santiago de Surco',
      district: 'Chama',
      street: 'Avenida Alfredo Benavides',
    },
    duration: 300,
    timewindows: [[28800, 46800]],
  },
  {
    label: 'RESTAURANTE FISHERS',
    location: {
      lat: -12.12851,
      lng: -76.99889,
      country: 'Perú',
      country_code: 'PER',
      state: 'Lima',
      county: 'Lima',
      house_number: '3537',
      postal_code: '15038',
      label: 'Avenida Alfredo Benavides 3537, 15038 Santiago de Surco, Perú',
      city: 'Santiago de Surco',
      district: 'Higuereta 4ta Etapa',
      street: 'Avenida Alfredo Benavides',
    },
    duration: 300,
    timewindows: [[28800, 46800]],
  },
  {
    label: 'RESTAURANTE DE TAPAS',
    location: {
      lat: -12.12666,
      lng: -77.00234,
      country: 'Perú',
      country_code: 'PER',
      state: 'Lima',
      county: 'Lima',
      house_number: '2851',
      postal_code: '15038',
      label: 'Avenida Tomás Marsano 2851, 15038 Lima, Perú',
      city: 'Lima',
      district: 'Higuereta 4ta Etapa',
      street: 'Avenida Tomás Marsano',
    },
    duration: 300,
    timewindows: [[28800, 46800]],
  },
  {
    label: 'RESTAURANTE LAS CAZUELAS',
    location: {
      lat: -12.12213,
      lng: -76.9987,
      country: 'Perú',
      country_code: 'PER',
      state: 'Lima',
      county: 'Lima',
      house_number: '4586',
      postal_code: '15038',
      label: 'B&B Aloja Inn, Avenida Aviación 4586, 15038 Santiago de Surco, Perú',
      city: 'Santiago de Surco',
      district: 'Higuereta 4ta Etapa',
      street: 'Avenida Aviación',
    },
    duration: 600,
    timewindows: [[28800, 46800]],
  },
  {
    label: 'RESTAURANTE PALOMARES',
    location: {
      lat: -12.12165,
      lng: -76.99332,
      country: 'Perú',
      country_code: 'PER',
      state: 'Lima',
      county: 'Lima',
      house_number: '444',
      postal_code: '15038',
      label: 'Avenida Pedro Venturo 444, 15038 Lima, Perú',
      city: 'Lima',
      district: 'Higuereta 2da Etapa',
      street: 'Avenida Pedro Venturo',
    },
    duration: 300,
    timewindows: [[28800, 46800]],
  },
  {
    label: 'RESTAURANTE SOUL',
    location: {
      lat: -12.11224,
      lng: -76.99039,
      country: 'Perú',
      country_code: 'PER',
      state: 'Lima',
      county: 'Lima',
      house_number: '218',
      postal_code: '15038',
      label: 'Calle Monte Rosa 218, 15038 Santiago de Surco, Perú',
      city: 'Santiago de Surco',
      district: 'Chacarilla del Estanque',
      street: 'Calle Monte Rosa',
    },
    duration: 900,
    timewindows: [[28800, 46800]],
  },
  {
    label: 'RESTAURANTE CLORODMIRA',
    location: {
      lat: -12.11205,
      lng: -76.99136,
      country: 'Perú',
      country_code: 'PER',
      state: 'Lima',
      county: 'Lima',
      house_number: '137',
      postal_code: '15038',
      label: 'Calle Monte Rosa 137, 15038 Lima, Perú',
      city: 'Lima',
      district: 'Chacarilla del Estanque',
      street: 'Calle Monte Rosa',
    },
    duration: 960,
    timewindows: [[28800, 46800]],
  },
];
