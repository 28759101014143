export const VEHICLES_ADD_VEHICLE = 'VEHICLES_ADD_VEHICLE';

export const VEHICLES_GET_FLAT_VEHICLES = 'VEHICLES_GET_FLAT_VEHICLES';
export const VEHICLES_GET_FLAT_VEHICLES_SUCCESS = 'VEHICLES_GET_FLAT_VEHICLES_SUCCESS';
export const VEHICLES_GET_FLAT_VEHICLES_ERROR = 'VEHICLES_GET_FLAT_VEHICLES_ERROR';

export const VEHICLES_GET_VEHICLES = 'VEHICLES_GET_VEHICLES';
export const VEHICLES_GET_VEHICLES_SUCCESS = 'VEHICLES_GET_VEHICLES_SUCCESS';
export const VEHICLES_GET_VEHICLES_ERROR = 'VEHICLES_GET_VEHICLES_ERROR';

export const VEHICLES_CREATE_VEHICLE = 'VEHICLES_CREATE_VEHICLE';
export const VEHICLES_CREATE_VEHICLE_SUCCESS = 'VEHICLES_CREATE_VEHICLE_SUCCESS';
export const VEHICLES_CREATE_VEHICLE_ERROR = 'VEHICLES_CREATE_VEHICLE_ERROR';

export const VEHICLES_CREATE_VEHICLES = 'VEHICLES_CREATE_VEHICLES';
export const VEHICLES_CREATE_VEHICLES_SUCCESS = 'VEHICLES_CREATE_VEHICLES_SUCCESS';
export const VEHICLES_CREATE_VEHICLES_ERROR = 'VEHICLES_CREATE_VEHICLES_ERROR';

export const VEHICLES_UPDATE_VEHICLE = 'VEHICLES_UPDATE_VEHICLE';
export const VEHICLES_UPDATE_VEHICLE_SUCCESS = 'VEHICLES_UPDATE_VEHICLE_SUCCESS';
export const VEHICLES_UPDATE_VEHICLE_ERROR = 'VEHICLES_UPDATE_VEHICLE_ERROR';

export const VEHICLES_DELETE_VEHICLE = 'VEHICLES_UPDATE_VEHICLE';
export const VEHICLES_DELETE_VEHICLE_SUCCESS = 'VEHICLES_DELETE_VEHICLE_SUCCESS';
export const VEHICLES_DELETE_VEHICLE_ERROR = 'VEHICLES_DELETE_VEHICLE_ERROR';

export const VEHICLES_GET_VEHICLE = 'VEHICLES_GET_VEHICLE';
export const VEHICLES_GET_VEHICLE_SUCCESS = 'VEHICLES_GET_VEHICLE_SUCCESS';
export const VEHICLES_GET_VEHICLE_ERROR = 'VEHICLES_GET_VEHICLE_ERROR';

export const VEHICLES_ROUTE_AS_VEHICLE = 'VEHICLES_ROUTE_AS_VEHICLE';
export const VEHICLES_ROUTE_AS_VEHICLE_SUCCESS = 'VEHICLES_ROUTE_AS_VEHICLE_SUCCESS';
export const VEHICLES_ROUTE_AS_VEHICLE_ERROR = 'VEHICLES_ROUTE_AS_VEHICLE_ERROR';

export const VEHICLES_SET_SELECTED_VEHICLES = 'VEHICLES_SET_SELECTED_VEHICLES';
export const VEHICLES_REMOVE_SELECTED = 'VEHICLES_REMOVE_SELECTED';
