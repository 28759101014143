import {
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Menu,
  MenuItem,
  Popover,
  Slide,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { ArrowDropDown, ArrowDropUp } from '@material-ui/icons';
import {
  ActionBar,
  Button,
  ClusterGroup,
  HSpacer,
  Map,
  Marker,
  SearchInput,
  VSpacer,
} from '@sm-highway-web/react-components';
import { IProjectData } from 'highway-api/dist/common/interfaces/projects';
import { IVehicleData, IVehicleFlat, IVehiclePagination } from 'highway-api/dist/common/interfaces/vehicles';
import React, { useEffect, useReducer, useState } from 'react';
import { connect } from 'react-redux';
import EmptyView from '../assets/img/add_new.svg';
import VehicleIcons, { colors } from '../assets/svg/VehicleIcons';
import DataTable from '../components/datatable/DataTable';
import FormArray from '../components/form-fields/FormArray';
import FormText from '../components/form-fields/FormText';
import FormTimewindows from '../components/form-fields/FormTimewindows';
import FormattedMessage from '../components/FormattedMessageCustom';
import Loader from '../components/Loader';
import RightPanelInfo from '../components/right-panel-info/RightPanelInfo';
import { getVehicleColor } from '../constants/Colors';
import AddVehicleDialog from '../dialogs/AddVehicleDialog';
import AddVehiclesDialog from '../dialogs/AddVehiclesDialog';
import { isValidService } from '../helpers/Directions';
import { parseSecondsToHM } from '../helpers/TimeDistance';
import { IUserLocation } from '../interfaces/common';
import { ActivePlan, Vehicles } from '../redux/actions';
import eventsPanel, { PageViewPages } from '../services/events';
import { getLocationFromLatLng } from '../services/requests';

const styles = {
  divHeader: {
    height: '48px',
    padding: '0px 16px',
  },
  rightPanelInfo: {
    position: 'absolute',
    right: '0px',
    top: '64px',
    height: 'calc(100vh - 64px)',
    zIndex: '1200',
    width: '400px',
    backgroundColor: 'white',
    overflow: 'hidden',
  },
  divLash: {
    position: 'absolute',
    width: '64px',
    height: '20px',
    left: 'calc(50% - 31px)',
    borderStyle: 'solid solid none solid',
    borderColor: 'lightgrey',
    borderWidth: '1px',
    backgroundColor: 'white',
    borderRadius: '5px 5px 0px 0px',
    top: '-20px',
    zIndex: 500,
  },
  rootMenu: {
    width: '240px',
  },
  menuItem: {
    height: '42px',
  },
};

type CreatePlanDialogProps = {
  dialog?: 'plan' | 'type' | 'constraints' | 'delete';
  setDialog: (...args: any[]) => any;
  selectedVehicles: IVehicleData[];
  handleClose: (...args: any[]) => any;
  actions: {
    createVehicle: (...args: any[]) => any;
    createVehicles: (...args: any[]) => any;
    updateVehicle: (...args: any[]) => any;
    updateVehicles: (...args: any[]) => any;
    selectVehicle: (...args: any[]) => any;
    removeVehicleSelection: (...args: any[]) => any;
    getFlatVehicles: (...args: any[]) => any;
    getVehicles: (...args: any[]) => any;
    deleteVehicles: (...args: any[]) => any;
    createPlan: (...args: any[]) => any;
  };
  userLocation?: IUserLocation;
};

const CreatePlanDialog = ({
  dialog,
  setDialog,
  selectedVehicles,
  handleClose,
  actions,
  userLocation,
}: CreatePlanDialogProps) => {
  return (
    <Dialog
      open={dialog === 'plan'}
      onClose={() => {
        setDialog(undefined);
      }}
    >
      <DialogTitle>
        <FormattedMessage id="vehicles.createdialog.title" />
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <FormattedMessage
            id="vehicles.createdialog.content"
            values={{ nVehicles: selectedVehicles.length }}
          />
        </DialogContentText>
        <Grid item style={{ width: '500px' }}>
          <Map
            key="map"
            center={
              !selectedVehicles || selectedVehicles.length === 0
                ? userLocation
                    ? [userLocation.latitude, userLocation.longitude]
                    : undefined
                : undefined
            }
            zoomLevel={(!selectedVehicles || selectedVehicles.length === 0) && userLocation ? userLocation.mapZoomLevel : undefined}
          >
            {selectedVehicles.filter(isValidService).map((vehicle: any) => {
              return (
                <Marker
                  id={vehicle.id}
                  key={vehicle.id}
                  lat={vehicle.default_start_location.lat}
                  lng={vehicle.default_start_location.lng}
                  color={getVehicleColor(vehicle, selectedVehicles)}
                  kind={vehicle.icon ? vehicle.icon : 'normal'}
                />
              );
            })}
          </Map>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setDialog(undefined)} color="primary">
          <FormattedMessage id="vehicles.typedialog.cancel" />
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            setDialog(undefined);
            handleClose();
            actions.createPlan(selectedVehicles);
          }}
          color="primary"
          autoFocus
        >
          <FormattedMessage id="vehicles.typedialog.confirm" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

type ConfirmDeleteDialogProps = {
  dialog?: 'plan' | 'type' | 'constraints' | 'delete';
  setDialog: (...args: any[]) => any;
  selectedVehicles: {}[];
  handleClose: (...args: any[]) => any;
  handleDelete: (...args: any[]) => any;
};

const ConfirmDeleteDialog = ({
  dialog,
  setDialog,
  selectedVehicles,
  handleClose,
  handleDelete,
}: ConfirmDeleteDialogProps) => (
  <Dialog open={dialog === 'delete'} onClose={() => setDialog(undefined)}>
    <DialogTitle>
      <FormattedMessage id="vehicles.deletedialog.title" />
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        <FormattedMessage
          id="vehicles.deletedialog.content"
          values={{ nVehicles: selectedVehicles.length }}
        />
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={() => setDialog(undefined)} color="primary">
        <FormattedMessage id="vehicles.deletedialog.cancel" />
      </Button>
      <Button
        variant="contained"
        onClick={() => {
          handleDelete();
          handleClose();
          setDialog(undefined);
        }}
        color="primary"
        autoFocus
      >
        <FormattedMessage id="vehicles.deletedialog.confirm" />
      </Button>
    </DialogActions>
  </Dialog>
);

type VehiclesConstraintsDialogProps = {
  dialog?: 'plan' | 'type' | 'constraints' | 'delete';
  setDialog: (...args: any[]) => any;
  selectedVehicles: {}[];
  handleClose: (...args: any[]) => any;
  actions: {
    createVehicle: (...args: any[]) => any;
    createVehicles: (...args: any[]) => any;
    updateVehicle: (...args: any[]) => any;
    updateVehicles: (...args: any[]) => any;
    selectVehicle: (...args: any[]) => any;
    removeVehicleSelection: (...args: any[]) => any;
    getFlatVehicles: (...args: any[]) => any;
    getVehicles: (...args: any[]) => any;
    deleteVehicles: (...args: any[]) => any;
    createPlan: (...args: any[]) => any;
  };
  projectUnits: {
    distance: string;
    weight: string;
    volume: string;
  };
};

const VehiclesConstraintsDialog = ({
  dialog,
  setDialog,
  selectedVehicles,
  handleClose,
  actions,
  projectUnits,
}: VehiclesConstraintsDialogProps) => {
  const [timewindows, setTimewindows] = useState(undefined);
  const [provides, setProvides] = useState(undefined);
  const [weight, setWeight] = useState(undefined);
  const [volume, setVolume] = useState(undefined);
  const [distance, setDistance] = useState(undefined);

  useEffect(() => {
    setTimewindows(undefined);
    setProvides(undefined);
    setWeight(undefined);
    setVolume(undefined);
    setDistance(undefined);
  }, [dialog]);

  return (
    <Dialog open={dialog === 'constraints'} onClose={() => setDialog(undefined)}>
      <DialogTitle>
        <FormattedMessage id="vehicles.constraintsdialog.title" />
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <FormattedMessage
            id="vehicles.constraintsdialog.content"
            values={{ nVehicles: selectedVehicles.length }}
          />
        </DialogContentText>
        <Grid container direction="column">
          <FormTimewindows
            label={<FormattedMessage id="vehicles.data.details.default_timewindow" />}
            timewindows={timewindows}
            onTimewindowsChange={setTimewindows}
            single
          />
          <FormArray
            label={<FormattedMessage id="vehicles.data.details.default_provides" />}
            array={provides}
            onUpdate={(val: any) => {
              setProvides(val);
            }}
          />
          <FormText
            label={<FormattedMessage id="vehicles.data.details.default_max_distance" />}
            fieldValue={distance}
            handleEnter={setDistance}
            type="number"
            suffix={projectUnits.distance}
          />
          <FormText
            label={<FormattedMessage id="vehicles.data.details.default_max_weight" />}
            fieldValue={weight}
            handleEnter={setWeight}
            type="number"
            suffix={projectUnits.weight}
          />
          <FormText
            label={<FormattedMessage id="vehicles.data.details.default_max_volume" />}
            fieldValue={volume}
            handleEnter={setVolume}
            type="number"
            suffix={projectUnits.volume}
          />
        </Grid>
      </DialogContent>

      <DialogActions>
        <Button onClick={() => setDialog(undefined)} color="primary">
          <FormattedMessage id="vehicles.constraintsdialog.cancel" />
        </Button>
        <Button
          variant="contained"
          onClick={() => {
            setDialog(undefined);
            handleClose();
            actions.updateVehicles(
              selectedVehicles.map((cli: any) => cli.id),
              {
                ...(distance ? { default_max_distance: distance } : {}),
                ...(weight ? { default_max_weight: weight } : {}),
                ...(volume ? { default_max_volume: volume } : {}),
                ...(timewindows ? { default_timewindow: timewindows } : {}),
                ...(provides ? { default_provides: provides } : {}),
              }
            );
          }}
          color="primary"
          autoFocus
        >
          <FormattedMessage id="vehicles.constraintsdialog.confirm" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

type ChangeTypeDialogProps = {
  dialog?: 'plan' | 'type' | 'constraints' | 'delete';
  setDialog: (...args: any[]) => any;
  selectedVehicles: IVehicleData[];
  handleClose: (...args: any[]) => any;
  actions: {
    createVehicle: (...args: any[]) => any;
    createVehicles: (...args: any[]) => any;
    updateVehicle: (...args: any[]) => any;
    updateVehicles: (...args: any[]) => any;
    selectVehicle: (...args: any[]) => any;
    removeVehicleSelection: (...args: any[]) => any;
    getFlatVehicles: (...args: any[]) => any;
    getVehicles: (...args: any[]) => any;
    deleteVehicles: (...args: any[]) => any;
    createPlan: (...args: any[]) => any;
  };
};

const ChangeTypeDialog = ({
  dialog,
  setDialog,
  selectedVehicles,
  handleClose,
  actions,
}: ChangeTypeDialogProps) => {
  const [selectedType, setSelectedType] = useState<string | undefined>(undefined);
  const icons = {
    width: '32px',
    height: '32px',
  };
  const iconContainer = {
    width: '36px',
    height: '36px',
    padding: '2px',
    margin: '2px',
    borderRadius: '50%',
  };
  return (
    <Dialog
      open={dialog === 'type'}
      onClose={() => {
        setDialog(undefined);
      }}
    >
      <DialogTitle>
        <FormattedMessage id="vehicles.typedialog.title" />
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <FormattedMessage
            id="vehicles.typedialog.content"
            values={{ nVehicles: selectedVehicles.length }}
          />
        </DialogContentText>
        <Grid container direction="row" justify="center">
          {Object.keys(VehicleIcons).map((icon: string) => {
            const Ficon = VehicleIcons[icon];
            return (
              <Grid
                onClick={() => setSelectedType(icon)}
                item
                key={icon}
                style={{
                  ...iconContainer,
                  backgroundColor: icon === selectedType ? '#ffb466' : 'white',
                }}
              >
                <Ficon style={{ ...icons, color: colors[icon] }} />
              </Grid>
            );
          })}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setDialog(undefined)} color="primary">
          <FormattedMessage id="vehicles.typedialog.cancel" />
        </Button>
        <Button
          variant="contained"
          disabled={selectedType === undefined}
          onClick={() => {
            actions.updateVehicles(
              selectedVehicles.map((cli: any) => cli.id),
              { icon: selectedType }
            );
            setDialog(undefined);
            handleClose();
          }}
          color="primary"
          autoFocus
        >
          <FormattedMessage id="vehicles.typedialog.confirm" />
        </Button>
      </DialogActions>
    </Dialog>
  );
};

type VehiclesPageProps = {
  classes: {
    divHeader: string;
    divLash: string;
    rootMenu: string;
    menuItem: string;
    rightPanelInfo: string;
  };
  activeProject?: IProjectData;
  vehicles?: IVehiclePagination;
  flatVehicles?: IVehicleFlat[];
  userLocation?: IUserLocation;
  detailView?: {
    type: 'vehicle';
    data?: IVehicleData;
  };
  selection?: IVehicleData[];
  actions: {
    createVehicle: (...args: any[]) => any;
    createVehicles: (...args: any[]) => any;
    updateVehicle: (...args: any[]) => any;
    updateVehicles: (...args: any[]) => any;
    selectVehicle: (...args: any[]) => any;
    setSelectedVehicles: (...args: any[]) => any;
    removeVehicleSelection: (...args: any[]) => any;
    getFlatVehicles: (...args: any[]) => any;
    getVehicles: (...args: any[]) => any;
    deleteVehicles: (...args: any[]) => any;
    createPlan: (...args: any[]) => any;
  };
};

const VehiclesPage = ({
  classes,
  actions,
  vehicles,
  flatVehicles,
  detailView,
  selection,
  userLocation,
  activeProject,
}: VehiclesPageProps) => {
  const [fullMap, setFullMap] = useReducer((prevFullMap) => !prevFullMap, false);
  const [contextAnchor, setContextAnchor] = useState(undefined);
  const [selectedLocation, setSelectedLocation] = useState(undefined);
  const [createManyVehiclesModal, setCreateManyVehiclesModal] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    eventsPanel.pageView(PageViewPages.vehicles);
  }, []);

  /**
   * Check against the quota if new vehicles can be added
   */
  const canAdd = () => {
    if (!activeProject || !flatVehicles) {
      return false;
    }

    // CONTRA LA VARIABLE DEL PROJECTE. max_vehicles.
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    return flatVehicles.length >= activeProject.limits.max_vehicles;
  };

  const [dialog, setDialog] = useState<'plan' | 'type' | 'constraints' | 'delete' | undefined>(undefined);

  const [createVehicleModal, setCreateVehicleModal] = useReducer((prevCreateVehicleModal) => {
    if (prevCreateVehicleModal) {
      setSelectedLocation(undefined);
    }
    return !prevCreateVehicleModal;
  }, false);

  const columnMapping = [
    {
      column: 'icon',
      formatter: (icon: any, doc: any) => {
        const Icon = doc && VehicleIcons[icon] ? VehicleIcons[icon] : VehicleIcons.truck;
        const color = vehicles?.docs ? getVehicleColor(doc, vehicles.docs) : 'inherit';        
        return (
          <div>
            <Icon style={{ color }} />
          </div>
        );
      },
    },
    {
      column: 'label',
      name: <FormattedMessage id="vehicles.data.details.label" />,
      sortable: true,
    },
    ...(activeProject?.view?.vehicle.plate === false
      ? []
      : [
          {
            column: 'plate',
            name: <FormattedMessage id="vehicles.data.details.plate" />,
            formatter: (doc: any) => (
              <Typography style={{ maxWidth: '700px' }} noWrap>
                {doc}
              </Typography>
            ),
          },
        ]),
    {
      column: 'default_start_location',
      name: <FormattedMessage id="vehicles.data.details.default_start_location" />,
      computed: (doc: any) => {
        if (doc.default_start_location) {
          return (
            doc.default_start_location.label ||
            `${doc.default_start_location.lat}, ${doc.default_start_location.lng}`
          );
        }
        return '-';
      },
    },
    {
      column: 'default_end_location',
      name: <FormattedMessage id="vehicles.data.details.default_end_location" />,
      computed: (doc: any) => {
        if (doc.default_end_location) {
          return (
            doc.default_end_location.label ||
            `${doc.default_end_location.lat}, ${doc.default_end_location.lng}`
          );
        }
        return '-';
      },
    },
    ...(activeProject?.view?.constraints.timewindows === false
      ? []
      : [
          {
            column: 'default_timewindow',
            name: <FormattedMessage id="vehicles.data.details.default_timewindow" />,
            formatter: (doc: any) =>
              doc && doc.length > 0 && (
                <Chip
                  key={doc[0] + doc[1]}
                  label={`${parseSecondsToHM(doc[0])}-${parseSecondsToHM(doc[1])}`}
                />
              ),
          },
        ]),
    ...(activeProject?.view?.vehicle.email === false
      ? []
      : [
          {
            column: 'email',
            name: <FormattedMessage id="vehicles.data.details.email" />,
          },
        ]),
  ];

  useEffect(() => {
    actions.getFlatVehicles();
    actions.getVehicles(0, 100);
    // setLoading(false);
  }, [actions, activeProject]);

  const handleDeleteVehicle = () => {
    actions.setSelectedVehicles(selection?.filter((v: any) => v.id !== detailView?.data?.id));
    actions.deleteVehicles([detailView?.data?.id]);
  };

  const createVehicle = (label: any, icon: any, startLocation: any) => {
    actions.createVehicle({ label, icon, default_start_location: startLocation });
  };

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDelete = () => {
    actions.deleteVehicles(selection?.map((cli: any) => cli.id));
    handleClose();
  };

  const updateVehicle = (vehicleId: any, data: any) => {
    actions.updateVehicle(vehicleId, data);
  };  

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const { docs, limit, offset, page, total } = vehicles || {};

  return (
    <Grid container direction="column">
      <ActionBar color="#050D1E">
        <Grid container direction="row-reverse" alignItems="center" className={classes.divHeader}>
          <Button variant="contained" onClick={() => setCreateManyVehiclesModal(true)}>
            <FormattedMessage id="vehicles.vehicles.new" />
          </Button>
        </Grid>
      </ActionBar>
      {/* ---- STARTING ROW PAGE ---- */}
      <Grid
        container
        direction="row"
        style={{
          height: 'calc(100vh - 48px)',
        }}
      >
        <Grid
          container
          direction="column"
          style={{ width: detailView?.data ? 'calc(100% - 400px)' : '100%' }}
        >
          {/* ---- VEHICLES TITLE BAR ---- */}
          <Grid container className={classes.divHeader} justify="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h3">
                <FormattedMessage id="vehicles.vehicles" />
              </Typography>
            </Grid>
            <Grid item>
              <Grid container>
                {/* @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'. */}
                {selection.length > 0 && (
                  <Grid item>
                    <Button
                      aria-describedby={id}
                      variant="contained"
                      color="primary"
                      onClick={handleClick}
                    >
                      <FormattedMessage id="vehicles.actions" />
                    </Button>
                    <Popover
                      id={id}
                      open={open}
                      anchorEl={anchorEl}
                      onClose={handleClose}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                      }}
                    >
                      <MenuItem onClick={() => setDialog('plan')} className={classes.menuItem}>
                        <FormattedMessage id="vehicles.actions.create_plan" />
                      </MenuItem>
                      <MenuItem onClick={() => setDialog('type')} className={classes.menuItem}>
                        <FormattedMessage id="vehicles.actions.change_type" />
                      </MenuItem>
                      <MenuItem
                        onClick={() => setDialog('constraints')}
                        className={classes.menuItem}
                      >
                        <FormattedMessage id="vehicles.actions.change_constraints" />
                      </MenuItem>
                      <MenuItem onClick={() => setDialog('delete')} className={classes.menuItem}>
                        <FormattedMessage id="vehicles.actions.delete" />
                      </MenuItem>
                    </Popover>
                  </Grid>
                )}
                <HSpacer />
                <SearchInput
                  onTextChange={(text: any) => actions.getVehicles(offset, limit, text)}
                />
              </Grid>
            </Grid>
          </Grid>
          {/* ---- MAP AND TABLE */}
          <Grid item>
            <Map
              key="map"
              center={
                !flatVehicles || flatVehicles.length === 0
                  ? userLocation
                      ? [userLocation.latitude, userLocation.longitude]
                      : undefined
                  : undefined
              }
              zoomLevel={(!flatVehicles || flatVehicles.length === 0) && userLocation ? userLocation.mapZoomLevel : undefined}
              height={fullMap ? 'calc(100vh - 96px)' : 'calc(50vh - 40px)'}
              onContextualMenu={(event: any) => {
                getLocationFromLatLng(event.latlng.lat, event.latlng.lng)
                  .then((result) => setSelectedLocation(result))
                  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'null' is not assignable to param... Remove this comment to see the full error message
                  .catch(() => setSelectedLocation(null));

                setContextAnchor({
                  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ screenPosition: { top: any; le... Remove this comment to see the full error message
                  screenPosition: {
                    top: event.originalEvent.y,
                    left: event.originalEvent.x,
                  },
                  coordinates: {
                    lat: event.latlng.lat,
                    lng: event.latlng.lng,
                  },
                });
              }}
            >
              {flatVehicles && (
                <ClusterGroup>
                  {flatVehicles.map((vehicle: any) => {
                    if(vehicle.lat && vehicle.lng) {
                      return <Marker
                        id={`start${vehicle.id}`}
                        key={`start${vehicle.id}`}
                        lat={vehicle.lat}
                        lng={vehicle.lng}
                        color={getVehicleColor(vehicle, flatVehicles)}
                        onClick={() => {
                          actions.selectVehicle(vehicle.id);
                        }}
                        kind="start"
                        selected={detailView?.data?.id === vehicle.id}
                      />;
                    }
                    return null;
                  })}
                </ClusterGroup>
              )}
            </Map>
            {/* MAP CONTEXTUAL MENU */}
            {contextAnchor && (
              <Menu
                onClose={() => {
                  setContextAnchor(undefined);
                  setSelectedLocation(undefined);
                }}
                // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
                open={contextAnchor}
                anchorReference="anchorPosition"
                // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
                anchorPosition={contextAnchor && contextAnchor?.screenPosition}
                classes={{ list: classes.rootMenu }}
              >
                {selectedLocation === undefined && <Loader noMonkey />}
                {selectedLocation === null && (
                  <p style={{ padding: '16px' }}>Nothing found in this location</p>
                )}
                {selectedLocation && (
                  <>
                    {/* @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'. */}
                    <p style={{ padding: '16px', fontSize: '11px' }}>{selectedLocation.label}</p>
                    {/* @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'. */}
                    {detailView.data && (
                      <MenuItem
                        className={classes.menuItem}
                        onClick={() => {
                          // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
                          updateVehicle(detailView.data.id, {
                            default_start_location: selectedLocation,
                          });
                          setContextAnchor(undefined);
                        }}
                      >
                        <FormattedMessage id="vehicles.details.map.add_to_current" />
                      </MenuItem>
                    )}
                    <MenuItem
                      disabled={canAdd()}
                      className={classes.menuItem}
                      onClick={() => {
                        setContextAnchor(undefined);
                        setCreateVehicleModal();
                      }}
                    >
                      <FormattedMessage id="vehicles.details.map.create_new" />
                    </MenuItem>
                  </>
                )}
              </Menu>
            )}
          </Grid>
          {/* TABLE */}
          <Grid item style={{ height: 'calc(50vh - 48px)', overflowY: 'auto' }}>
            <div style={{ position: 'relative' }}>
              <Grid item className={classes.divLash}>
                {fullMap ? (
                  <ArrowDropUp
                    style={{ height: '100%', width: '100%' }}
                    // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
                    onClick={() => setFullMap(!fullMap)}
                  />
                ) : (
                  <ArrowDropDown
                    style={{ height: '100%', width: '100%' }}
                    // @ts-expect-error ts-migrate(2554) FIXME: Expected 0 arguments, but got 1.
                    onClick={() => setFullMap(!fullMap)}
                  />
                )}
              </Grid>
            </div>
            {!(flatVehicles && flatVehicles.length === 0) ? (
              <DataTable
                limit={limit}
                page={page}
                total={total}
                docs={docs}
                docsIndex="id"
                mapping={columnMapping}
                onChangePage={actions.getVehicles}
                onRowSelected={(doc: any) => actions.selectVehicle(doc.id)}
                onMultipleSelection={(documents: any) => actions.setSelectedVehicles(documents)}
              />
            ) : (
              <Grid
                container
                direction="column"
                alignItems="center"
                justify="center"
                style={{ padding: '32px' }}
              >
                <Typography variant="h3">
                  <FormattedMessage id="vehicles.novehicles.title" />
                </Typography>
                <VSpacer medium />
                {/* eslint-disable-next-line */}
                <img
                  alt="Upload xlsx"
                  src={EmptyView}
                  width="150px"
                  onClick={() => setCreateManyVehiclesModal(true)}
                  style={{
                    cursor: 'pointer',
                  }}
                />
                <VSpacer medium />
                <FormattedMessage id="vehicles.novehicles.content" />
                <VSpacer medium />
                <Grid item>
                  <Button variant="contained" onClick={() => setCreateManyVehiclesModal(true)}>
                    <FormattedMessage id="vehicles.vehicles.new" />
                  </Button>
                </Grid>
              </Grid>
            )}
          </Grid>
          {/* VEHICLE EDIT SIDEBAR */}
          {detailView?.data && (
            <Slide in={Boolean(detailView?.data)} direction="left" mountOnEnter>
              <Grid className={classes.rightPanelInfo}>
                <RightPanelInfo
                  key={`${detailView?.data?.id}`}
                  type={detailView?.type}
                  onClose={actions.removeVehicleSelection}
                  onUpdate={updateVehicle}
                  data={detailView?.data as IVehicleData}
                  options={{
                    handleDeleteVehicle,
                  }}
                  countryCode={
                    activeProject && activeProject.optimizer_config
                      ? activeProject.optimizer_config.operation_country
                      : null
                  }
                />
              </Grid>
            </Slide>
          )}
        </Grid>
      </Grid>
      {/* ---- ENDING ROW PAGE ---- */}
      <AddVehicleDialog
        open={createVehicleModal}
        location={selectedLocation}
        handleOpenCloseDialog={setCreateVehicleModal}
        createVehicle={createVehicle}
      />
      <AddVehiclesDialog
        open={createManyVehiclesModal}
        onClose={() => {
          setCreateManyVehiclesModal(false);
        }}
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ open: boolean; onClose: () => void; create... Remove this comment to see the full error message
        createManyVehicles={actions.createVehicles}
      />
      <ConfirmDeleteDialog
        dialog={dialog}
        setDialog={setDialog}
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ id: string; }[] | undefined' is not assign... Remove this comment to see the full error message
        selectedVehicles={selection}
        handleClose={handleClose}
        handleDelete={handleDelete}
      />
      <ChangeTypeDialog
        dialog={dialog}
        setDialog={setDialog}
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ id: string; }[] | undefined' is not assign... Remove this comment to see the full error message
        selectedVehicles={selection}
        handleClose={handleClose}
        actions={actions}
      />
      <VehiclesConstraintsDialog
        dialog={dialog}
        setDialog={setDialog}
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ id: string; }[] | undefined' is not assign... Remove this comment to see the full error message
        selectedVehicles={selection}
        handleClose={handleClose}
        actions={actions}
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ distance?: string | undefined; weight?: st... Remove this comment to see the full error message
        projectUnits={activeProject.units}
      />
      <CreatePlanDialog
        dialog={dialog}
        setDialog={setDialog}
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ id: string; }[] | undefined' is not assign... Remove this comment to see the full error message
        selectedVehicles={selection}
        handleClose={handleClose}
        actions={actions}
        userLocation={userLocation}
      />
    </Grid>
  );
};

ChangeTypeDialog.defaultProps = {
  dialog: undefined,
};

CreatePlanDialog.defaultProps = {
  dialog: undefined,
};

ConfirmDeleteDialog.defaultProps = {
  dialog: undefined,
};

VehiclesConstraintsDialog.defaultProps = {
  dialog: undefined,
};

VehiclesPage.defaultProps = {
  flatVehicles: undefined,
  vehicles: undefined,
  selection: undefined,
  userLocation: undefined,
  detailView: undefined,
  activeProject: {
    units: {
      distance: `km`,
      weight: `kg`,
      volume: `m³`,
    },
    view: {
      vehicle: {
        email: false,
        plate: false,
      },
      constraints: {
        timewindows: false,
        weight: false,
        volume: false,
        max_services: false,
      },
    },
    optimizer_config: {
      balance_services: false,
      max_wait_time: 0,
      service_duration: 0,
      operation_country: '',
    },
    limits: {
      max_clients: 0,
    },
  },
};

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    getFlatVehicles: () => dispatch(Vehicles.getFlatVehicles()),
    getVehicles: (offset: any, limit: any, text: any) =>
      dispatch(Vehicles.getVehicles(offset, limit, text)),
    createVehicle: (vehicle: any) => dispatch(Vehicles.postVehicle(vehicle)),
    createVehicles: (vehicles: any) => dispatch(Vehicles.createVehicles(vehicles)),
    updateVehicle: (vehicleId: any, data: any) => dispatch(Vehicles.putVehicle(vehicleId, data)),
    updateVehicles: (vehicleId: any, data: any) => dispatch(Vehicles.putVehicles(vehicleId, data)),
    deleteVehicle: (vehicleId: any) => dispatch(Vehicles.deleteVehicle(vehicleId)),
    deleteVehicles: (vehicles: any) => dispatch(Vehicles.deleteVehicles(vehicles)),
    selectVehicle: (vehicleId: any) => dispatch(Vehicles.selectVehicle(vehicleId)),
    setSelectedVehicles: (vehicles: any) => dispatch(Vehicles.setSelectedVehicles(vehicles)),
    removeVehicleSelection: () => dispatch(Vehicles.removeVehicleSelection()),
    createPlan: (vehicles: any) => dispatch(ActivePlan.createPlan({ vehicles })),
  },
});

const mapStateToProps = (state: any) => ({
  flatVehicles: state.vehicles.flatVehicles,
  detailView: state.vehicles.detailView,
  vehicles: state.vehicles.vehicles,
  selection: state.vehicles.selection,
  userLocation: state.user.userLocation,
  activeProject: state.projects.activeProject,
});

// @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ divHeader: { height: string; p... Remove this comment to see the full error message
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(VehiclesPage));
