export const OrganizationRoles = {
  organization_administrator: 'organization_administrator',
  organization_manager: 'organization_manager',
  organization_user: 'organization_user',
};

/**
 * Order users by fullname, pending and deleted.
 * @param {*} users
 */
export const orderUsers = (users: any) => {
  try {
    const activeUsers = orderByName(users.filter((user: any) => !user.deleted && user.validated));
    const nonValidatedUsers = orderByName(
      users.filter((user: any) => !user.deleted && !user.validated)
    );
    const deletedUsers = orderByName(users.filter((user: any) => user.deleted));
    return activeUsers.concat(nonValidatedUsers).concat(deletedUsers);
  } catch (_) {
    return [];
  }
};

const orderByName = (users: any) => {
  return users.sort((a: any, b: any) => {
    const fullnameA = `${a.first_name} ${a.last_name}`;
    const fullnameB = `${b.first_name} ${b.last_name}`;
    // Compare the 2 fullnames
    if (fullnameA < fullnameB) return -1;
    if (fullnameA > fullnameB) return 1;
    return 0;
  });
};
