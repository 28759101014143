import { Grid, Typography } from '@material-ui/core';
import {
  AutocompleteInput,
  Button,
  Form,
  HSpacer,
  InputText,
  VSpacer,
} from '@sm-highway-web/react-components';
import { IBillingInfo } from 'highway-api/dist/common/interfaces/subscriptions';
import React, { useState } from 'react';
import { useIntl } from 'react-intl';
import countries, { EU_COUNTRIES } from '../../constants/Countries';
import FormattedMessage from '../FormattedMessageCustom';

type PaymentBillingAddressProps = {
  billingAddress?: IBillingInfo;
  onNextStep: (...args: any[]) => any;
  onPreviousStep: (...args: any[]) => any;
};

const PaymentBillingAddress = ({ billingAddress, onNextStep, onPreviousStep }: PaymentBillingAddressProps) => {
  const [valueInputs, setValueInputs] = useState<IBillingInfo>(billingAddress || {});
  const [isLoading, setLoading] = useState(false);
  const [showVAT, setShowVAT] = useState(
    billingAddress &&
      billingAddress.country &&
      Object.keys(EU_COUNTRIES).includes(billingAddress.country)
  );
  const intl = useIntl();

  const onChange = (id: any) => {
    return (value: any) => {
      if (id === 'country') {
        setShowVAT(Object.keys(EU_COUNTRIES).includes(value));
        return setValueInputs({
          ...valueInputs,
          [id]: value || undefined,
          vat_number: undefined,
        });
      }
      return setValueInputs({
        ...valueInputs,
        [id]: value || undefined,
      });
    };
  };

  return (
    <>
      <Typography variant="h2" align="center">
        <FormattedMessage id="paymentrunwaydialog.step2.title" />
      </Typography>
      <VSpacer />
      <Typography variant="h6">
        <FormattedMessage id="paymentrunwaydialog.step2.subsection1.title" />
      </Typography>
      <VSpacer medium />
      <Form
        container
        direction="column"
        onSubmit={async () => {
          setLoading(true);
          await onNextStep({ billingAddress: valueInputs });
          setLoading(false);
        }}
      >
        {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
        <Grid container spacing={2} formContainer>
          {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
          <Grid item md={12} formContainer>
            <InputText
              id="line1"
              label={<FormattedMessage id="paymentrunwaydialog.step2.input.streetaddress" />}
              value={valueInputs.line1}
              onChange={onChange('line1')}
              margin="none"
              fullWidth
              lazyValidation
              validators={['required']}
            />
          </Grid>
        </Grid>
        {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
        <Grid container spacing={2} formContainer>
          {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
          <Grid item md={12} formContainer>
            <InputText
              id="line2"
              label={<FormattedMessage id="paymentrunwaydialog.step2.input.streetaddress2opt" />}
              value={valueInputs.line2}
              onChange={onChange('line2')}
              margin="none"
              fullWidth
              lazyValidation
              validators={[]}
            />
          </Grid>
        </Grid>
        {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
        <Grid container spacing={2} formContainer>
          {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
          <Grid item md={6} formContainer>
            <InputText
              id="city"
              label={<FormattedMessage id="paymentrunwaydialog.step2.input.city" />}
              value={valueInputs.city}
              onChange={onChange('city')}
              margin="none"
              fullWidth
              lazyValidation
              validators={['required']}
            />
          </Grid>
          {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
          <Grid item md={6} formContainer>
            <InputText
              id="postal_code"
              label={<FormattedMessage id="paymentrunwaydialog.step2.input.postalcode" />}
              value={valueInputs.postal_code}
              onChange={onChange('postal_code')}
              margin="none"
              fullWidth
              lazyValidation
              validators={['required']}
            />
          </Grid>
        </Grid>
        {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
        <Grid container spacing={2} formContainer>
          {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
          <Grid item md={6} formContainer>
            <div style={{ height: '4px' }} />
            <InputText
              id="state"
              label={<FormattedMessage id="paymentrunwaydialog.step2.input.state" />}
              value={valueInputs.state}
              onChange={onChange('state')}
              margin="none"
              fullWidth
              validators={['required']}
            />
          </Grid>
          {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
          <Grid item md={6} formContainer>
            <AutocompleteInput
              label={<FormattedMessage id="paymentrunwaydialog.step2.input.country" />}
              noOptionsMessage={<FormattedMessage id="paymentrunwaydialog.step2.input.nooptions" />}
              defaultValue={valueInputs.country}
              handlerOnClickChange={(country: any) =>
                onChange('country')(country ? country.value : undefined)
              }
              placeholder={intl.formatMessage({
                id: 'paymentrunwaydialog.step2.input.country_select_ph',
              })}
              lazyValidation
              validators={['required']}
              listSuggestions={Object.keys(countries).map((countryKey) => ({
                label: countries[countryKey].label,
                value: countryKey,
              }))}
            />
          </Grid>
        </Grid>
        {showVAT && (
          // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
          <Grid item md={6} formContainer>
            <div style={{ height: '4px' }} />
            <Grid container direction="row" alignItems="center">
              <InputText
                id="vat_number"
                label={<FormattedMessage id="paymentrunwaydialog.step2.input.vatnum" />}
                placeholder={intl.formatMessage(
                  {
                    id: 'paymentrunwaydialog.step2.input.vatnum.example',
                  },
                  {
                    // @ts-expect-error ts-migrate(2538) FIXME: Type 'undefined' cannot be used as an index type.
                    vatExample: EU_COUNTRIES[valueInputs.country].vatExample,
                  }
                )}
                value={valueInputs.vat_number}
                onChange={onChange('vat_number')}
                margin="none"
                fullWidth
                hintSpace={false}
              />
            </Grid>
          </Grid>
        )}
        <VSpacer />
        {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
        <Grid container justify="flex-end" formContainer>
          <Button variant="text" color="secondary" onClick={onPreviousStep} disabled={isLoading}>
            <FormattedMessage id="paymentrunwaydialog.buttonback" />
          </Button>
          <HSpacer medium />
          <Button type="submit" variant="contained" disabled={isLoading}>
            {isLoading ? (
              <FormattedMessage id="paymentrunwaydialog.buttonloading" />
            ) : (
              <FormattedMessage id="paymentrunwaydialog.buttonnext" />
            )}
          </Button>
        </Grid>
      </Form>
    </>
  );
};

PaymentBillingAddress.defaultProps = {
  billingAddress: {},
};

export default PaymentBillingAddress;
