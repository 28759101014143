import styled from 'styled-components';

interface ILabelProps {
  size?: 'medium' | 'large';
}

export const Label = styled.p`
  color: #333940;
  text-transform: uppercase;
  margin-bottom: 4px;
  ${(props: ILabelProps) => {
    switch (props.size) {
      case 'large':
        return 'font-size: 16px';
      case 'medium':
      default:
        return 'font-size: 9px';
    }
  }}
`;

interface IPlaceholderProps {
  size?: 'medium' | 'large';
}

export const Placeholder = styled.div`
  padding: 0 5px;
  color: #c1c0c0;
  ${(props: IPlaceholderProps) => {
    switch (props.size) {
      case 'large':
        return 'font-size: 16px';
      case 'medium':
      default:
        return 'font-size: 12px';
    }
  }}
`;
