import * as routing from '../../constants/Routing';
import { removeCookie } from '../../helpers/Cookies';
import history from '../../helpers/History';
import constants from '../constants/index';

const errorMiddleWare = (/* store */) => (next: any) => (action: any) => {
  switch (action.type) {
    case constants.ActivePlan.ACTIVE_PLAN_GET_PLAN_ERROR:
      next(action);
      history.push(routing.PLANS);
      break;
    case constants.User.USER_DETAILS_ERROR:
      next(action);
      if (action.payload.response && action.payload.response.status === 401) {
        removeCookie();
        next({
          type: constants.User.USER_LOGOUT,
        });
      }
      if (action.payload.response && action.payload.response.status === 400) {
        history.push(routing.PLANS);
      }
      break;
    default:
      next(action);
  }
};

export default errorMiddleWare;
