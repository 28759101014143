import React, { useEffect, useState } from 'react';
import AsyncSelect from 'react-select/async';
import { projectHelpers } from '../../redux/helpers';
import store from '../../redux/store';
import getHighway from '../../services/highway';
import { AsyncSearchCustomOption, AsyncSearchNoOptionsMessage, AsyncSearchValueContainer } from './AsyncSearchComponents ';
import { Label as StyledLabel } from './FormFields.styled';

type FormLinkedElementProps = {
  type: 'client' | 'vehicle';
  label: string | React.ReactElement | {};
  fieldValue?: string;
  onChange: (...args: any[]) => any;
  placeholder: string | React.ReactElement | {};
  noOptionsMessage: string | React.ReactElement | {};
};

const FormLinkedElement = ({ type, label, fieldValue, onChange, placeholder, noOptionsMessage }: FormLinkedElementProps) => {

  const [value, setValue] = useState<any>();

  useEffect(() => {
    (async () => {
      if(fieldValue) {
        const highway = getHighway(store.getState().user.token);
        const response = await highway[type].get(fieldValue);
        setValue(response);
      }
    })();
  }, [fieldValue, type]);

  const isSearchable = () => !value;
  
  const onChangeValue = (val: any) => {
    setValue(val);
    onChange(val);
  };

  if(type !== 'client' && type !== 'vehicle') return null;

  return (
    <>
      {label && <StyledLabel>{label}</StyledLabel>}
      <AsyncSelect
        placeholder={placeholder}
        value={value}
        cacheOptions
        isClearable
        isSearchable={isSearchable()}
        components={{
          Option: AsyncSearchCustomOption,
          ValueContainer: AsyncSearchValueContainer,
          NoOptionsMessage: (_props: any) => (
            <AsyncSearchNoOptionsMessage noOptionsMessage={noOptionsMessage} />
          ),
        }}
        onChange={onChangeValue}
        loadOptions={async (inputValue: any) => {
          const highway = getHighway(store.getState().user.token);
          const response = await highway[type].list(
            projectHelpers.getCurrentProjectId(),
            0,
            5,
            inputValue
          );
          return response.docs;
        }}
      />
    </>
  );
};

FormLinkedElement.defaultProps = {
  fieldValue: undefined,
};

export default FormLinkedElement;
