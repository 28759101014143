import Froged, { FrogedEvents } from '../../services/froged';
import constants from '../constants/index';

const initialState = {
  plan: undefined,
  loading: false,
  optimizing: false,
  viewList: {
    services: [],
    routes: [],
  },
  viewMap: {
    services: [],
    routes: [],
  },
  detailView: {
    data: undefined,
    type: '',
  },
  pickups: {},
};

function activePlan(state = initialState, action: any) {
  switch (action.type) {
    case constants.ActivePlan.ACTIVE_PLAN_GET_PLAN:
      return { ...state, loading: true };
    case constants.ActivePlan.ACTIVE_PLAN_CREATE_PLAN_ROUTES_SUCCESS:
      Froged.track(FrogedEvents.plan.route.created, { number: action.payload.length });
      return state;
    case constants.ActivePlan.ACTIVE_PLAN_OPTIMIZE_PLAN:
      return { ...state, optimizing: true };
    case constants.ActivePlan.ACTIVE_PLAN_OPTIMIZE_PLAN_SUCCESS:
    case constants.ActivePlan.ACTIVE_PLAN_GET_PLAN_SUCCESS:
    case constants.ActivePlan.ACTIVE_PLAN_UPDATE_PLAN_SUCCESS:
    case constants.ActivePlan.ACTIVE_PLAN_CREATE_PLAN_SUCCESS:
      if (
        action.type === constants.ActivePlan.ACTIVE_PLAN_OPTIMIZE_PLAN_SUCCESS &&
        action.payload.finished === false
      ) {
        return {
          ...state,
          optimizing: false,
          loading: false,
        };
      }

      if (action.type === constants.ActivePlan.ACTIVE_PLAN_CREATE_PLAN_SUCCESS) {
        Froged.track(FrogedEvents.plan.created, { id: action.payload.id });
      }
      return {
        ...state,
        plan: action.payload,
        pickups: action.payload.services.reduce((ps: any, ns: any) => {
          if (ns.pickup) {
            return { ...ps, [ns.pickup.id]: ns };
          }
          return ps;
        }, {}),
        optimizing: false,
        loading: false,
      };
    case constants.ActivePlan.ACTIVE_PLAN_GET_PLAN_ERROR:
      return { ...state, loading: false };
    case constants.ActivePlan.ACTIVE_PLAN_CLEAN_PLAN:
      return { ...initialState };
    case constants.ActivePlan.ACTIVE_PLAN_CHANGE_OPTIONS_SUCCESS:
      // @ts-expect-error ts-migrate(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
      return { ...state, plan: { ...state.plan, ...action.payload } };
    case constants.ActivePlan.ACTIVE_PLAN_SET_DETAIL_VIEW:
      return { ...state, detailView: action.payload };
    case constants.ActivePlan.ACTIVE_PLAN_LIST_SET_SELECTED_ROUTES:
      return {
        ...state,
        viewList: {
          ...state.viewList,
          routes: action.payload,
        },
      };
    case constants.ActivePlan.ACTIVE_PLAN_LIST_SET_SELECTED_SERVICES:
      return {
        ...state,
        viewList: {
          ...state.viewList,
          services: action.payload,
        },
      };
    case constants.ActivePlan.ACTIVE_PLAN_MAP_SET_SELECTED_ROUTES:
      return {
        ...state,
        viewMap: {
          ...state.viewList,
          routes: action.payload,
        },
      };
    case constants.ActivePlan.ACTIVE_PLAN_MAP_SET_SELECTED_SERVICES:
      return {
        ...state,
        viewMap: {
          // @ts-expect-error ts-migrate(2783) FIXME: 'services' is specified more than once, so this us... Remove this comment to see the full error message
          services: action.payload,
          ...state.viewList,
        },
      };
    default:
      return state;
  }
}
export default activePlan;
