import { Grid, IconButton, withStyles } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import { HSpacer, SearchInput, VSpacer } from '@sm-highway-web/react-components';
import { IPlanData } from 'highway-api/dist/common/interfaces/plans';
import { IProjectData } from 'highway-api/dist/common/interfaces/projects';
import { IServiceDataExtended } from 'highway-api/dist/common/interfaces/services';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import EmptyView from '../../../assets/img/ic-new-service.svg';
import VirtualizedMaterialDataTable from '../../datatable/VirtualizedDataTable';
import FormattedMessage from '../../FormattedMessageCustom';
import ServiceMenu from '../../menus/ServiceMenu';
import PopoverMore3Points from '../../PopoverMore3Points';
import FormCreateField from './FormCreateField';
import getColumns from './ServicesListColumns';

const styles = {
  rootMenu: {
    width: '240px',
  },
  menuItem: {
    height: '42px',
  },
  grid: {
    width: '100%',
    maxHeight: 'calc(100% - 96px)',
    display: 'flex',
  },
  slimbutton: {
    padding: 0,
    height: '26px',
    boxShadow: 'none',
    color: 'white',
  },
  slimbuttonnowhite: { padding: 0, height: '24px', boxShadow: 'none' },
  centered: {
    flexDirection: 'column',
    display: 'flex',
    justifyContent: 'center',
  },
  addElementContainer: {
    minHeight: '40px',
    maxHeight: '50px',
    height: '100%',
    width: '100%',
    position: 'absolute',
    bottom: 0,
    '&:hover': { backgroundColor: '#FDF6ED' },
  },
};

type OwnServicesListProps = {
  classes: {
    rootMenu: string;
    menuItem: string;
    grid: string;
    slimbutton: string;
    slimbuttonnowhite: string;
    centered: string;
    addElementContainer: string;
  };
  plan: IPlanData;
  selectedServices: IServiceDataExtended[];
  setListSelectedServices: (...args: any[]) => any;
  openServicesModal: (...args: any[]) => any;
  setDialog: (...args: any[]) => any;
  setRightPanelVisible: (...args: any[]) => any;
  setService: (...args: any[]) => any;
  createServicesToAPlan: (...args: any[]) => any;
  saveServiceAsClient: (...args: any[]) => any;
  activeProject: IProjectData;
  tableColumns: { [key: string]: boolean };
};

type ServicesListProps = OwnServicesListProps;

const ServicesList = ({
  classes,
  plan,
  openServicesModal,
  activeProject,
  setDialog,
  setRightPanelVisible,
  setService,
  createServicesToAPlan,
  saveServiceAsClient,
  selectedServices,
  setListSelectedServices,
  tableColumns,
}: ServicesListProps) => {
  const [filterText, setFilterText] = useState('');
  const [filteredServices, setFilteredServices] = useState<IServiceDataExtended[] | undefined>(
    undefined
  );
  const [contextAnchorTableServices, setContextAnchorTableServices] = useState<
    { screenPosition: { top: number; left: number }; doc: IServiceDataExtended } | undefined
  >(undefined);

  const handleSearchServices = useCallback(() => {
    if (filterText !== '') {
      const regexx = new RegExp(`${filterText.toLowerCase()}`, `ig`);
      const servicesFiltered: IServiceDataExtended[] = plan.services.filter((s: any) => {
        const serviceRoute = plan.routes.find((r: any) => r.id === s.route_id);
        return (
          (s.label && s.label.match(regexx)) ||
          (s.location && s.location.label && s.location.label.match(regexx)) ||
          (s.external_id && s.external_id.match(regexx)) ||
          (s.icon && s.icon.match(regexx)) ||
          (serviceRoute && serviceRoute.label && serviceRoute.label.match(regexx))
        );
      });
      setFilteredServices(servicesFiltered);
    } else {
      setFilteredServices(undefined);
    }
  }, [plan, filterText]);

  useEffect(() => {
    if (plan && plan.services) {
      handleSearchServices();
    }
  }, [plan, handleSearchServices]);

  return (
    <Grid container direction="column" style={{ height: '100%', position: 'relative' }}>
      <Grid item style={{ height: '48px', width: '100%', paddingRight: '6px' }}>
        <Grid container style={{ height: '100%', width: '100%' }} justify="flex-end">
          {plan && (
            <Grid item style={{ width: '300px' }}>
              <Grid container direction="column" justify="center" style={{ height: '100%' }}>
                <SearchInput
                  onTextChange={_.debounce((text: any) => {
                    setFilterText(text);
                    handleSearchServices();
                  }, 300)}
                  searchLive
                />
              </Grid>
            </Grid>
          )}
          <HSpacer medium />
          <Grid item className={classes.centered}>
            <PopoverMore3Points
              idButton="servicespopover"
              large
              arrayActions={[
                {
                  close: true,
                  onClick: openServicesModal,
                  formattedMessage: 'services.actions.importservices',
                },
                {
                  close: true,
                  onClick: () => setDialog('configureColumnsServices'),
                  formattedMessage: 'services.actions.configure_columns',
                },
                null,
                {
                  close: true,
                  disabled: selectedServices.length === 0,
                  onClick: () => setDialog('constraintsServices'),
                  formattedMessage: 'services.actions.change_constraints',
                },
                {
                  close: true,
                  disabled: !(selectedServices.length === 1 && !selectedServices[0].client_id),
                  onClick: () => {
                    saveServiceAsClient(selectedServices[0]);
                  },
                  formattedMessage: 'services.actions.saveasclient',
                },
                {
                  close: true,
                  disabled: selectedServices.length === 0,
                  onClick: () => setDialog('assignServices'),
                  formattedMessage: 'services.actions.move_services_to_route',
                },
                {
                  close: true,
                  disabled: selectedServices.length === 0,
                  onClick: () => setDialog('deleteServices'),
                  formattedMessage: 'services.actions.delete_service',
                },
              ]}
            />
          </Grid>
        </Grid>
      </Grid>
      {plan && (
        <Grid
          item
          className={classes.grid}
          style={{
            overflowX: 'auto',
            overflowY: 'hidden',
            height: '100%',
          }}
        >
          {plan.services.length === 0 && (
            <Grid
              container
              direction="column"
              alignItems="center"
              justify="center"
              style={{ padding: '32px', overflowY: 'auto' }}
              wrap="nowrap"
            >
              {/* eslint-disable-next-line */}
              <img
                alt="Upload xlsx"
                src={EmptyView}
                width="180px"
                onClick={() => openServicesModal(true)}
                style={{
                  cursor: 'pointer',
                }}
              />
              <VSpacer medium />
              <div style={{ maxWidth: '480px', textAlign: 'center', color: '#707070' }}>
                <FormattedMessage id="services.noclients.content" />
              </div>
            </Grid>
          )}
          {plan.services.length > 0 && (
            <VirtualizedMaterialDataTable
              docs={filteredServices || plan.services}
              showCheckboxes
              onRowDoubleClick={() => {
                setRightPanelVisible(true);
              }}
              onRowSelected={(doc: any) => {
                setService(doc);
              }}
              handleOnContextMenu={(e: any, doc: any) => {
                e.preventDefault();
                setContextAnchorTableServices({
                  screenPosition: {
                    top: e.clientY,
                    left: e.clientX,
                  },
                  doc,
                });
              }}
              onMultipleSelection={(documents: any) => setListSelectedServices(documents)}
              selectedDocs={selectedServices}
              mapping={[
                ...getColumns(activeProject, tableColumns, plan, (e, doc) => {
                  e.stopPropagation();
                  setService(doc);
                  setRightPanelVisible(true);
                }),
                {
                  column: 'id',
                  align: 'right',
                  width: 100,
                  flex: 1,
                  formatter: (id: string, doc: any, i: any, hover: any) => (
                    <Grid container justify="flex-end">
                      {hover && (
                        <>
                          <IconButton
                            className={classes.slimbuttonnowhite}
                            onClick={(e) => {
                              e.stopPropagation();
                              setService(doc);
                              setRightPanelVisible(true);
                            }}
                          >
                            <Edit style={{ height: '22px' }} />
                          </IconButton>

                          <HSpacer small />

                          <ServiceMenu
                            type="popover"
                            plan={plan}
                            service={doc}
                            setRightPanelVisible={setRightPanelVisible}
                          />
                        </>
                      )}
                    </Grid>
                  ),
                },
              ]}
            />
          )}
        </Grid>
      )}
      <Grid item className={classes.addElementContainer}>
        {plan && (
          <FormCreateField
            entity="service"
            actualLength={plan.services.length}
            maxLength={activeProject?.limits ? activeProject.limits.max_services_plan : 0}
            handleCreate={(service) => createServicesToAPlan(plan.id, [service])}
            countryCode={plan.optimizer_config ? plan.optimizer_config.operation_country : null}
            searchMenuPlacement="top"
          />
        )}
      </Grid>

      {contextAnchorTableServices && (
        <ServiceMenu
          plan={plan}
          service={contextAnchorTableServices.doc}
          contextAnchor={contextAnchorTableServices}
          setContextAnchor={setContextAnchorTableServices}
          setRightPanelVisible={setRightPanelVisible}
        />
      )}
    </Grid>
  );
};

ServicesList.defaultProps = {
  activeProject: {
    limits: {
      max_services_plan: 0,
    },
    view: {
      client: {
        external_id: false,
        location_details: false,
        comments: false,
        reference_person: false,
        phone: false,
        webpage: false,
        optional: false,
      },
      constraints: {
        timewindows: false,
        distance: false,
        weight: false,
        volume: false,
        provide_requires: false,
        max_services: false,
        pickups: false,
      },
    },
  },
  tableColumns: {},
};

const mapStateToProps = (state: any) => {
  return {
    activeProject: state.projects.activeProject,
    tableColumns: state.services.tableColumns,
  };
};

// @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ rootMenu: { width: string; }; ... Remove this comment to see the full error message
export default connect(mapStateToProps, null)(withStyles(styles)(ServicesList));
