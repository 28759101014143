import { IClientData } from 'highway-api/dist/common/interfaces/clients';
import constants from '../constants/index';

const initialState = {
  flatClients: undefined,
  clients: {
    limit: 100,
    offset: 0,
    docs: undefined,
  },
  selection: [],
  detailView: {
    type: 'client',
    data: undefined,
  },
};

function clients(state = initialState, action: any) {
  let selection: any;
  const detailView = { ...initialState.detailView };
  switch (action.type) {
    case constants.Clients.CLIENTS_GET_FLAT_CLIENTS_SUCCESS:
      return { ...state, flatClients: action.payload };
    case constants.Clients.CLIENTS_CREATE_CLIENT_SUCCESS:
      detailView.data = action.payload;
      return { ...state, detailView };
    case constants.Clients.CLIENTS_UPDATE_CLIENT_SUCCESS:
      if (!state.detailView.data) {
        detailView.data = undefined;
        return {
          ...state,
          detailView,
        };
      }

      // Updating the selected routes.
      selection = [];
      if (Array.isArray(action.payload)) {
        selection = [...state.selection];
        const updatedSelection: any = [];
        action.payload.forEach((payloadItem: any) => {
          const selectedClient = selection.find((client: any) => client.id === payloadItem.id);
          if (selectedClient) updatedSelection.push(payloadItem);
        });

        const updatedSelectionIds = updatedSelection.map((sel: any) => sel.id);
        selection = [
          ...selection.filter((client: any) => updatedSelectionIds.indexOf(client.id) === -1),
          ...updatedSelection,
        ];
      } else {
        selection = [...state.selection];
        const selectedClient = selection.find((client: any) => client.id === action.payload.id);
        if (selectedClient) {
          selection = [
            ...selection.filter((client: any) => client.id !== selectedClient.id),
            action.payload,
          ];
        }
      }

      if (Array.isArray(action.payload) && action.payload.length > 1) {
        const filtered = action.payload.filter((sc: any) => sc.id === (state.detailView.data as IClientData | undefined)?.id);
        detailView.data = filtered.length > 0 ? filtered[0] : undefined;
        return {
          ...state,
          selection,
          detailView,
        };
      }

      detailView.data = action.payload;
      return { ...state, selection, detailView };
    case constants.Clients.CLIENTS_GET_CLIENT_SUCCESS:
      detailView.data = action.payload;
      return { ...state, detailView };
    case constants.Clients.CLIENTS_GET_CLIENTS_SUCCESS:
      return { ...state, clients: action.payload };
    case constants.Clients.CLIENTS_SET_SELECTED_CLIENTS:
      return { ...state, selection: action.payload };
    case constants.Clients.CLIENTS_REMOVE_SELECTED:
      detailView.data = undefined;
      return { ...state, detailView };
    default:
      return state;
  }
}
export default clients;
