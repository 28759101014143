declare const process: any;

class Configuration {
  FRESHCHAT_TOKEN: any;

  FROGED_APP_ID: any;

  HIGHWAY_BACK_END: any;

  MIXPANEL_API_KEY: any;

  STRIPE_API_KEY: any;

  HIGHWAY_CUSTOMERS_ENDPOINT: any;

  constructor() {
    this.HIGHWAY_BACK_END = process.env.REACT_APP_HIGHWAY_BACK_END;
    this.MIXPANEL_API_KEY = process.env.REACT_APP_MIXPANEL_API_KEY;
    this.FRESHCHAT_TOKEN = process.env.REACT_APP_FRESHCHAT_TOKEN;
    this.STRIPE_API_KEY = process.env.REACT_APP_STRIPE_API_KEY;
    this.FROGED_APP_ID = process.env.REACT_APP_FROGED_APP_ID;
    this.HIGHWAY_CUSTOMERS_ENDPOINT = process.env.REACT_APP_HIGHWAY_CUSTOMERS_ENDPOINT;
  }
}
const configuration = new Configuration();
export default configuration;
