const kmToSM = (km: any, distanceIn: any) => {
  if (distanceIn === 'mi') {
    return km * 0.621371;
  }
  return km;
};

const SMToKm = (sm: any, distanceIn: any) => {
  if (distanceIn === 'mi') {
    return sm / 0.621371;
  }
  return sm;
};

const settingsCurrency = [
  { code: 'EUR', symbol: '€' },
  { code: 'USD', symbol: '$' },
  { code: 'MXN', symbol: '$' },
];

const currencyToSymbol = (currency?: string ) => {
  if (currency) {
    const currencyFound = settingsCurrency.find((c: any) => c.code === currency);
    if(currencyFound) {
      return `${currencyFound.symbol}`;
    }
  }
  return '';
};

export { kmToSM, SMToKm, currencyToSymbol };
