import { TableCell, TableRow, Typography, withStyles } from '@material-ui/core';
import React, { useState } from 'react';
import { Checkbox } from '@sm-highway-web/react-components';

const styles = () => ({
  tableCell: {
    padding: '6px 12px 6px 12px',
  },
  selected: {
    '&$selected': {
      backgroundColor: '#FDF6ED',
      // color: 'red'
    },
  },
  hover: {
    '&$hover:hover': {
      backgroundColor: '#FDF6ED',
    },
  },
  row: {
    maxHeight: '36px',
    height: '36px',
  },
  checkboxColumn: {
    width: '36px',
    padding: '6px 12px 6px 12px',
  },
});

type OwnDataTableRowProps = {
  classes: {
    tableCell: string;
    checkboxColumn: string;
    row: string;
    hover: string;
    selected: string;
  };
  mapping: string[];
  handleCheckboxSelect: (...args: any[]) => any;
  handleRowSelect: (...args: any[]) => any;
  handleOnContextMenu: (...args: any[]) => any;
  onRowDoubleClick: (...args: any[]) => any;
  index: number;
  hover: boolean;
  docsIndex?: string;
  isSelected: (...args: any[]) => any;
  document: any;
};

type DataTableRowProps = OwnDataTableRowProps;

const DataTableRow = ({
  classes,
  mapping,
  docsIndex,
  isSelected,
  document,
  handleCheckboxSelect,
  handleRowSelect,
  handleOnContextMenu,
  onRowDoubleClick,
  index,
  hover,
}: DataTableRowProps) => {
  const [rowHover, setRowHover] = useState(undefined);
  const isChecked = docsIndex && isSelected(document[docsIndex]);
  return (
    <TableRow
      component="div"
      onClick={(event) => {
        event.stopPropagation();
        if (event.ctrlKey || event.metaKey) {
          handleCheckboxSelect(document);
        } else {
          handleRowSelect(document);
        }
      }}
      onContextMenu={(e) => {
        handleRowSelect(document);
        if (handleOnContextMenu) handleOnContextMenu(e, document);
      }}
      onDoubleClick={() => onRowDoubleClick(document)}
      selected={isChecked}
      classes={{ selected: classes.selected, hover: classes.hover }}
      className={classes.row}
      hover={hover}
      onMouseEnter={() => setRowHover(document)}
      onMouseLeave={() => setRowHover(undefined)}
    >
      {docsIndex && (
        <TableCell
          component="div"
          onClick={(event) => {
            event.stopPropagation();
            handleCheckboxSelect(document);
          }}
          className={classes.checkboxColumn}
        >
          <Checkbox checked={isChecked} />
        </TableCell>
      )}
      {mapping.map((entry: any, index2: any) => (
        <TableCell
          component="div"
          className={classes.tableCell}
          key={`row-item-${(docsIndex && document[docsIndex]) || index}-${entry.column || index2}`}
          align={entry.align}
          style={{
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'style' does not exist on type 'string'.
            ...(mapping[index] && mapping[index].style !== undefined ? mapping[index].style : {}),
          }}
        >
          <Typography component="span">
            {(() => {
              if (entry.formatter) {
                return entry.formatter(
                  entry.computed ? entry.computed(document) : document[entry.column],
                  document,
                  index,
                  rowHover
                );
              }
              if (entry.computed) {
                return entry.computed(document);
              }
              return document[entry.column];
            })()}
          </Typography>
        </TableCell>
      ))}
    </TableRow>
  );
};

DataTableRow.defaultProps = {
  docsIndex: undefined,
};

export default withStyles(styles)(DataTableRow);
