import { combineReducers } from 'redux';
import activePlan from './ActivePlan';
import clients from './Clients';
import common from './Common';
import infoerror from './InfoError';
import organization from './Organization';
import payment from './Payment';
import plans from './Plans';
import projects from './Projects';
import routes from './Routes';
import services from './Services';
import tutorial from './Tutorial';
import user from './User';
import vehicles from './Vehicles';

const highwayreducers = combineReducers({
  user,
  organization,
  infoerror,
  payment,
  common,
  clients,
  projects,
  plans,
  activePlan,
  services,
  routes,
  vehicles,
  tutorial,
});

export default highwayreducers;
