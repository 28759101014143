import { Chip, Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { HSpacer } from '@sm-highway-web/react-components';
import { IProjectData } from 'highway-api/dist/common/interfaces/projects';
import { IRouteDataExtended } from 'highway-api/dist/common/interfaces/routes';
import React from 'react';
import colorsRoutes from '../../constants/Colors';
import ROUTE_STATUS from '../../constants/Routes';
import { currencyToSymbol, kmToSM, SMToKm } from '../../helpers/Units';
import CustomFields from '../custom-fields/CustomFields';
import FormColorPicker from '../form-fields/FormColorPicker';
import FormIcon from '../form-fields/FormIcon';
import FormText from '../form-fields/FormText';
import FormattedMessage from '../FormattedMessageCustom';
import Block from './Block';
import {
  checkAllR,
  checkR,
  renderArray,

  renderFormText,
  renderFormTextArea,
  renderInfo,
  renderLinkedVehicle,
  renderLocation,
  renderTimewindows,
  styles,
} from './Common';

type RouteHeaderProps = {
  data: IRouteDataExtended;
  onUpdate?: (...args: any[]) => any;
  warning?: React.ReactElement;
  options?: React.ReactElement | {};
};

export const RouteHeader = ({ data, onUpdate, warning, options }: RouteHeaderProps) => {  
  const handleUpdate = (label: string, val: any) => {
    if (onUpdate) {
      onUpdate(data.id, { [label]: val });
    }
  };

  if (data) {
    return (
      <Grid container justify="space-between">
        <Grid item container alignItems="center" style={{ width: 'auto' }}>
          <FormIcon
            fieldValue={data.icon}
            onUpdate={(icon: any) => {
              handleUpdate('icon', icon);
            }}
            type="vehicle"
          />

          <HSpacer small />

          <FormColorPicker
            color={data.color}
            presetColors={colorsRoutes}
            onUpdate={(color: any) => {
              handleUpdate('color', color);
            }}
          />

          <HSpacer small />

          {data.status && (
            <Chip
              style={{
                backgroundColor:
                  data.status === ROUTE_STATUS.notStarted
                    ? '#eff0f3'
                    : data.status === ROUTE_STATUS.inTransit
                      ? '#ffc07e'
                      : '#cbfeb2',
                fontSize: '12px',
              }}
              label={<FormattedMessage id={`route.status.${data.status}`} />}
            />
          )}
        </Grid>
        <Grid container alignItems="center" style={{ width: 'auto' }}>
          <Grid item style={{ marginRight: '8px' }}>
            {warning}
          </Grid>
          <Grid item>
            {options}
          </Grid>
        </Grid>
      </Grid>
    );
  }
  return null;
};

type RouteProps = {
  classes: {
    box: string;
    slimbutton: string;
    menuItem: string;
  };
  data: IRouteDataExtended;
  onUpdate?: (...args: any[]) => any;
  options?: {
    handleDeleteVehicle: (...args: any[]) => any;
  },
  activeProject: IProjectData;
  countryCode?: string | null;
};

const Route = ({ data, onUpdate, classes, activeProject, countryCode }: RouteProps) => {
  const handleUpdate = (label: string, val: any) => {
    if (onUpdate) {
      onUpdate(data.id, { [label]: val });
    }
  };
  const preffix = 'route.data.details';
  const { custom_fields: customFields, view } = activeProject;
  // @ts-expect-error
  const { constraints, vehicle } = view;

  return (
    <Grid container direction="column">
      <Grid container className={classes.box}>
        <FormText
          variant="title"
          fieldValue={data.label}
          handleEnter={(val: any) => {
            handleUpdate('label', val);
          }}
        />

        {/* <Grid item container direction="column"> */}
        {/* {renderFormColor(preffix, 'color', handleUpdate, 0, colorsRoutes, data)} */}
        {/* </Grid> */}

        <Grid item container direction="column">
          {renderLinkedVehicle(preffix, 'vehicle_id', handleUpdate, data)}
        </Grid> 
        
        {checkR(vehicle.external_id) &&
          renderFormText(preffix, 'external_id', undefined, handleUpdate, data)}
        {renderLocation(preffix, 'start_location', handleUpdate, data, countryCode)}
        {renderLocation(preffix, 'end_location', handleUpdate, data, countryCode)}
        {checkR(vehicle.price_per_minute) && renderFormText(
          preffix,
          'price_per_minute',
          'number',
          handleUpdate,
          data,
          <FormattedMessage
            tagName="span"
            id="price_per_minute"
            values={{
              currency: currencyToSymbol(activeProject?.units?.currency),
            }}
          />
        )}
        {checkR(vehicle.price_per_distance) && renderFormText(
          preffix,
          'price_per_distance',
          'number',
          handleUpdate,
          data,
          <FormattedMessage
            tagName="span"
            id="price_per_distance"
            values={{
              currency: currencyToSymbol(activeProject?.units?.currency),
              unit: activeProject?.units?.distance,
            }}
          />
        )}
      </Grid>
      <Block title={<FormattedMessage id="clients.details.group.planned" />}>
        {renderInfo(preffix, 'services', data)}
        {renderInfo(preffix, 'planned_start_time', data)}
        {renderInfo(preffix, 'planned_end_time', data)}
      </Block>
      {checkAllR(constraints) && (
        <Block title={<FormattedMessage id="clients.details.group.constraints" />}>
          {checkR(constraints.timewindows) &&
            renderTimewindows(preffix, 'timewindow', handleUpdate, data, true)}
          {checkR(constraints.distance) &&
            renderFormText(
              preffix,
              'max_distance',
              'number',
              (label: any, val: any) => {
                handleUpdate(label, SMToKm(val, activeProject?.units?.distance) * 1000);
              },
              data,
              activeProject?.units?.distance,
              (value) => (value ? kmToSM(value / 1000, activeProject?.units?.distance) : null)
            )}
          {checkR(constraints.weight) &&
            renderFormText(
              preffix,
              'max_weight',
              'number',
              handleUpdate,
              data,
              activeProject?.units?.weight
            )}
          {checkR(constraints.provide_requires) &&
            renderArray(preffix, 'provides', handleUpdate, data)}
          {checkR(constraints.volume) &&
            renderFormText(
              preffix,
              'max_volume',
              'number',
              handleUpdate,
              data,
              activeProject?.units?.volume
            )}
          {checkR(constraints.max_services) &&
            renderFormText(preffix, 'max_services', 'integer', handleUpdate, data)}
        </Block>
      )}
      {checkAllR(vehicle) && (
        <Block title={<FormattedMessage id="clients.details.group.contact" />}>
          {checkR(vehicle.plate) && renderFormText(preffix, 'plate', undefined, handleUpdate, data)}
          {checkR(vehicle.phone) && renderFormText(preffix, 'phone', undefined, handleUpdate, data)}
          {checkR(vehicle.email) && renderFormText(preffix, 'email', 'email', handleUpdate, data)}
          {checkR(vehicle.comments) &&
            renderFormTextArea(preffix, 'comments', undefined, handleUpdate, data)}
        </Block>
      )}

      {customFields && customFields.vehicle.length > 0 ? (
        <Block title={<FormattedMessage id="custom_fields.title" />}>
          <CustomFields
            onUpdate={(id: any, value: any) => handleUpdate('custom_fields', { [id]: value })}
            customFields={data.custom_fields}
            projectCustomFields={customFields.vehicle}
          />
        </Block>
      ) : null}
    </Grid>
  );
};

export default withStyles(styles)(Route);
