import {
  cookieToken,
  getAsUser,
  hasCookie,
  removeAsUser,
  removeCookie,
  saveAsUser,
} from '../../helpers/Cookies';
import eventsPanel from '../../services/events';
import Froged from '../../services/froged';
import constants from '../constants/index';

const initialState = {
  user: undefined,
  isLogged: hasCookie(),
  token: cookieToken(),
  registerDone: false,
  loginError: false,
  language: 'en-us',
  asUser: getAsUser() || undefined,
  userLocation: undefined,
};

function user(state = initialState, action: any) {
  switch (action.type) {
    case constants.User.USER_SET_USER_LOCATION:
      localStorage.setItem('USER_LOCATION', JSON.stringify(action.payload));
      return {
        ...state,
        userLocation: action.payload,
      };
    case constants.User.USER_AS_USER:
      saveAsUser(action.payload);
      return { ...state, asUser: action.payload };
    case constants.User.USER_REMOVE_AS_USER:
      removeAsUser();
      return { ...state, asUser: undefined };
    case constants.User.USER_LANGUAGE:
      return { ...state, language: action.payload };
    case constants.User.USER_LOGIN:
      return { ...state, loginError: false, isLogged: false };
    case constants.User.USER_LOGIN_SUCCESS:
      return { ...state, token: action.payload.token, loginError: false, isLogged: true };
    case constants.User.USER_LOGIN_ERROR:
      return {
        ...state,
        loginError: true,
      };
    case constants.User.USER_DETAILS_SUCCESS:
      if (!getAsUser()) {
        Froged.boot();
        Froged.set({
          firstname: action.payload.first_name,
          lastname: action.payload.last_name,
          userId: action.payload.id,
          user_language: action.payload.language,
          ...action.payload,
        });
        eventsPanel.identify(action.payload);
      }
      return { ...state, user: action.payload };
    case constants.User.USER_REGISTER_SUCCESS:
      return { ...state, registerDone: true };
    case constants.User.USER_EDIT_SUCCESS:
      // @ts-expect-error ts-migrate(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
      return { ...state, user: { ...state.user, ...action.payload } };
    // @ts-expect-error ts-migrate(2339) FIXME: Property 'USER_EDIT_GUIDE_SUCCESS' does not exist ... Remove this comment to see the full error message
    case constants.Payment.USER_EDIT_GUIDE_SUCCESS:
      // @ts-expect-error ts-migrate(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
      return { ...state, user: { ...state.user, guide: action.payload } };
    case constants.User.USER_LOGOUT:
      Froged.logout();
      removeCookie();
      removeAsUser();
      eventsPanel.logout();
      return {
        ...state,
        user: undefined,
        isLogged: false,
        loginError: false,
        token: undefined,
        registerDone: false,
        asUser: undefined,
      };
    case constants.User.USER_UPLOAD_AVATAR_SUCCESS:
      return {
        ...state,
        user: {
          // @ts-expect-error ts-migrate(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
          ...state.user,
          avatar: action.payload.id,
          avatar_url: action.payload.avatar_url,
        },
      };
    case constants.User.USER_REMOVE_AVATAR_SUCCESS:
      return {
        ...state,
        // @ts-expect-error ts-migrate(2698) FIXME: Spread types may only be created from object types... Remove this comment to see the full error message
        user: { ...state.user, avatar: undefined, avatar_url: undefined },
      };
    default:
      return state;
  }
}

export default user;
