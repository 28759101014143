import { Grid, ListItem, Select, Tooltip, Typography } from '@material-ui/core';
import { Lock, ReportProblemOutlined } from '@material-ui/icons';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled from 'styled-components';
import VehicleIcons from '../../../../assets/svg/VehicleIcons';
import WarningIcon from '../../../../assets/svg/WarningIcon';
import { getRouteColor } from '../../../../constants/Colors';
import BrokenConstraintsTooltip from '../../../BrokenConstraintsTooltip';
import FormattedMessage from '../../../FormattedMessageCustom';

const StyledSelect = styled(Select).attrs({ classes: { root: 'root' } })`
  .root {
    padding: 6px;
  }
`;

const StyledListItem = styled(ListItem)`
  cursor: pointer;
  &:focus {
    outline: none;
  }
  :hover {
    background-color: #f1f1f1;
  }
`;

const SelectorRoutesPlan = ({ routes, paneNumber, onChangeSelectedRoute, value }: any) => {
  const [isSelectOpen, setIsSelectOpen] = useState(false);

  return (
    <StyledSelect
      value={value}
      variant="outlined"
      onChange={(e: any) => onChangeSelectedRoute(e, paneNumber)}
      fullWidth
      onOpen={() => {
        setIsSelectOpen(true);
      }}
      onClose={() => {
        setIsSelectOpen(false);
      }}
    >
      {routes &&
        routes.map((route: any, idx: any) => {
          let Icon = VehicleIcons.truck;
          if (route.icon && VehicleIcons[route.icon]) {
            Icon = VehicleIcons[route.icon];
          }
          return (
            // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
            <StyledListItem key={`vehicle-${route.id}`} value={idx}>
              <Grid container alignItems="center">
                {route.is_locked && (
                  <Tooltip
                    title={<FormattedMessage id="planoverview.route.table.header.locked_tooltip" />}
                  >
                    <Lock
                      style={{
                        marginLeft: isSelectOpen ? '-8px' : '0px',
                        fontSize: '18px',
                        color: '#56637b',
                      }}
                    />
                  </Tooltip>
                )}
                <Icon
                  style={{
                    color: getRouteColor(route, routes),
                    float: 'left',
                    marginRight: '8px',
                    marginLeft: isSelectOpen ? '0px' : '8px',
                    paddingLeft: !route.is_locked ? '8px' : '0px',
                  }}
                />
                <Typography
                  component="p"
                  style={{
                    textOverflow: 'ellipsis',
                    flex: 1,
                    /* Required for text-overflow to do anything */
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                  }}
                  key={`typography-${route.id}`}
                >
                  {route.label}
                </Typography>

                <BrokenConstraintsTooltip type="route" route={route}>
                  <ReportProblemOutlined 
                    style={{
                      float: 'left',
                      marginRight: isSelectOpen ? '0px' : '22px',
                      textAlign: 'center',
                      color: 'orange',
                      fontSize: '24px',
                    }}
                  />
                </BrokenConstraintsTooltip>
              </Grid>
            </StyledListItem>
          );
        })}
      {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
      <StyledListItem key="missing" value="missing">
        <Grid container alignItems="center">
          <WarningIcon
            color="primary"
            style={{
              float: 'left',
              margin: '0px 8px',
              height: '100%',
            }}
          />
          <Typography component="span" key="typography-missing">
            <FormattedMessage id="planmap.textnotassigned" />
          </Typography>
        </Grid>
      </StyledListItem>
    </StyledSelect>
  );
};

SelectorRoutesPlan.propTypes = {
  onChangeSelectedRoute: PropTypes.func.isRequired,
  routes: PropTypes.arrayOf(PropTypes.shape({})),
  paneNumber: PropTypes.number.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
};

SelectorRoutesPlan.defaultProps = { routes: undefined };

export default SelectorRoutesPlan;
