import Froged, { FrogedEvents } from '../../services/froged';
import constants from '../constants/index';

const initialState = {
  flatClients: undefined,
  tableColumns: getServicesTableColumns(),
};

function services(state = initialState, action: any) {
  switch (action.type) {
    case constants.Services.SERVICES_SET_TABLE_COLUMNS:
      setServicesTableColumns(action.payload);
      return {
        ...state,
        tableColumns: action.payload,
      };
    case constants.Services.SERVICES_CREATE_SERVICES_SUCCESS:
      Froged.track(FrogedEvents.plan.service.created, { number: action.payload.length });
      return { ...state, flatClients: action.payload };
    default:
      return state;
  }
}
export default services;

function getServicesTableColumns() {
  const tableColumns = localStorage.getItem('SERVICES_TABLE_COLUMNS');
  if(tableColumns) {
    const parsedTableColumns = JSON.parse(tableColumns);
    const finalTableColumns : { [key: string]: boolean } = {};
    Object.keys(constants.Services.SERVICES_DEFAULT_TABLE_COLUMNS).forEach((key: string) => {
      finalTableColumns[key] = parsedTableColumns.hasOwnProperty(key) ? parsedTableColumns[key] : constants.Services.SERVICES_DEFAULT_TABLE_COLUMNS[key];
    });
    return finalTableColumns;
  }
  return constants.Services.SERVICES_DEFAULT_TABLE_COLUMNS;
}

function setServicesTableColumns(tableColumns: any) {
  localStorage.setItem('SERVICES_TABLE_COLUMNS', JSON.stringify(tableColumns));
}
