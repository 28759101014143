import { Divider, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React, { Fragment, PureComponent } from 'react';
import { VSpacer } from '@sm-highway-web/react-components';
import FormattedMessage from '../FormattedMessageCustom';

const styles = {
  bold: {
    fontWeight: 'bold',
  },
  pointer: {
    cursor: 'pointer',
  },
};

type Props = {
  classes: {
    bold: string;
    pointer: string;
  };
  itemsMenu: {}[];
  selected: string;
};

class SettingsLeftMenu extends PureComponent<Props> {
  render() {
    const { classes, itemsMenu, selected } = this.props;
    return (
      <>
        {itemsMenu.map((item) => {
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'type' does not exist on type '{}'.
          switch (item.type) {
            case 'title':
              return (
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'id' does not exist on type '{}'.
                <Typography variant="h2" key={`titletypo${item.id}`}>
                  {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'messageId' does not exist on type '{}'. */}
                  <FormattedMessage id={item.messageId} key={`titlemess${item.id}`} />
                </Typography>
              );
            case 'subtitle':
              return (
                // @ts-expect-error ts-migrate(2339) FIXME: Property 'id' does not exist on type '{}'.
                <Typography variant="h6" className={classes.bold} key={`subtitletypo${item.id}`}>
                  {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'messageId' does not exist on type '{}'. */}
                  <FormattedMessage id={item.messageId} key={`subtitlemess${item.id}`} />
                </Typography>
              );
            case 'divider':
              // @ts-expect-error ts-migrate(2339) FIXME: Property 'id' does not exist on type '{}'.
              return <Divider key={`divider${item.id}`} />;
            case 'spacer':
              // @ts-expect-error ts-migrate(2339) FIXME: Property 'id' does not exist on type '{}'.
              return <VSpacer key={`spacer${item.id}`} />;
            case 'smallspacer':
              // @ts-expect-error ts-migrate(2339) FIXME: Property 'id' does not exist on type '{}'.
              return <VSpacer small key={`smallspacer${item.id}`} />;
            case 'mediumspacer':
              // @ts-expect-error ts-migrate(2339) FIXME: Property 'id' does not exist on type '{}'.
              return <VSpacer medium key={`medspacer${item.id}`} />;
            default:
              return (
                <Typography
                  // @ts-expect-error ts-migrate(2339) FIXME: Property 'id' does not exist on type '{}'.
                  key={`texttypo${item.id}`}
                  variant="body1"
                  // @ts-expect-error ts-migrate(2339) FIXME: Property 'id' does not exist on type '{}'.
                  color={selected === item.id ? 'primary' : 'secondary'}
                  // @ts-expect-error ts-migrate(2339) FIXME: Property 'goTo' does not exist on type '{}'.
                  onClick={item.goTo}
                  className={`noselect ${classes.pointer}`}
                >
                  {/* @ts-expect-error ts-migrate(2339) FIXME: Property 'id' does not exist on type '{}'. */}
                  <FormattedMessage key={`titlemess${item.id}`} id={item.messageId} />
                </Typography>
              );
          }
        })}
      </>
    );
  }
}

// @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ bold: { fontWeight: string; };... Remove this comment to see the full error message
export default withStyles(styles)(SettingsLeftMenu);
