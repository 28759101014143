import { Grid, TextField } from '@material-ui/core';
import { HSpacer } from '@sm-highway-web/react-components';
import React, { useState } from 'react';
import styled from 'styled-components';
import { validateCoupon } from '../../services/requests';
import FormattedMessage from '../FormattedMessageCustom';

const CouponCodeText = styled.span`
  font-size: 12px;
  color: #ff8200;
  cursor: pointer;
`;

type Props = {
  onCouponApplied: (...args: any[]) => any;
};

const CouponCode = ({ onCouponApplied }: Props) => {
  const [coupon, setCoupon] = useState();
  const [couponDetails, setCouponDetails] = useState();
  const [showInput, setShowInput] = useState(false);
  const [error, setError] = useState(false);
  return (
    <Grid container alignItems="center" justify="flex-end">
      {!showInput && (
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        <CouponCodeText align="right" onClick={() => setShowInput(true)}>
          <FormattedMessage id="payment.add_coupon" />
        </CouponCodeText>
      )}
      {showInput && !couponDetails && (
        <>
          {/* @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message */}
          <TextField error={error} value={coupon} onChange={(evt) => setCoupon(evt.target.value)} />
          <HSpacer small />
          <CouponCodeText
            // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
            align="right"
            onClick={async () => {
              if (!coupon) {
                setShowInput(false);
              } else {
                try {
                  const validatedCoupon = await validateCoupon(coupon);
                  setCouponDetails(validatedCoupon);
                  setError(false);
                  onCouponApplied(validatedCoupon);
                } catch (err) {
                  setError(true);
                }
              }
            }}
          >
            <FormattedMessage id="payment.add_coupon.validate" />
          </CouponCodeText>
        </>
      )}
      {couponDetails && (
        <CouponCodeText>
          <FormattedMessage id="payment.add_coupon.ok" />
        </CouponCodeText>
      )}
    </Grid>
  );
};

export default CouponCode;
