import { Button, Grid, Hidden, Input, Tooltip, withStyles } from '@material-ui/core';
import { Check, Close } from '@material-ui/icons';
import { HSpacer } from '@sm-highway-web/react-components';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import FormIcon from '../../form-fields/FormIcon';
import LocationSearch from '../../form-fields/LocationSearch';
import FormattedMessage from '../../FormattedMessageCustom';

const styles = {
  noPadding: {
    padding: '4px 4px',
  },
  slimbuttonnowhite: { padding: 0, height: '24px', boxShadow: 'none' },
  slimbutton: {
    padding: 0,
    height: '26px',
    boxShadow: 'none',
    color: 'white',
  },
  errorQuota: {
    backgroundColor: 'rgba(255,130,0,0.1)',
    color: '#D0021B',
    padding: '4px 16px',
    margin: '4px',
    borderRadius: '16px',
  },
};

type FormCreateFieldEditableProps = {
  classes: {
    slimbuttonnowhite: string;
    noPadding: string;
  };
  object: any;
  setObject: (...args: any[]) => any;
  entity: 'route' | 'service';
  handleCreate: (...args: any[]) => any;
  destroy: (...args: any[]) => any;
  countryCode?: string | null;
  searchMenuPlacement?: 'bottom' | 'top';
};

const FormCreateFieldEditable = withStyles(styles)(
  ({ classes, object, setObject, entity, destroy, handleCreate, countryCode, searchMenuPlacement } : FormCreateFieldEditableProps) => {
    const intl = useIntl();

    const handleKeyPressed = (evt: any) => {
      if (evt.key === 'Escape') {
        destroy();
      }
    };

    useEffect(() => {
      document.addEventListener('keydown', handleKeyPressed);
      return () => {
        document.removeEventListener('keydown', handleKeyPressed);
      };
    });

    const renderRoute = () => (
      <Grid container spacing={2}>
        <Hidden smDown>
          <Grid item style={{ width: '60px' }}>
            <FormIcon
              fieldValue={object.icon || 'normal'}
              onUpdate={(icon: any) => {
                setObject({ ...object, icon });
              }}
              type="vehicle"
            />
          </Grid>
        </Hidden>

        <Grid item style={{ flex: 1 }}>
          <Grid container>
            <Grid item style={{ maxWidth: '40%' }}>
              <Input
                value={object.label}
                placeholder={intl.formatMessage({ id: 'route.data.details.label' })}
                onChange={(evt) => setObject({ ...object, label: evt.target.value })}
                fullWidth
                classes={{ input: classes.noPadding }}
              />
            </Grid>
            <HSpacer />
            <div style={{ width: '100%', maxWidth: '40%' }}>
              <LocationSearch
                // @ts-expect-error ts-migrate(2322) FIXME: Type 'Element' is not assignable to type 'string'.
                placeholder={<FormattedMessage id="route.data.details.start_location" />}
                fieldValue={object.start_location}
                onUpdate={(startLocation: any) => {
                  setObject({
                    ...object,
                    start_location: startLocation,
                    end_location: startLocation,
                  });
                }}
                countryCode={countryCode}
                menuPlacement={searchMenuPlacement}
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
    );

    const renderService = () => (
      <Grid container spacing={2}>
        <Hidden smDown>
          <Grid item style={{ width: '60px' }}>
            <FormIcon
              fieldValue={object.icon || 'normal'}
              onUpdate={(icon: any) => {
                setObject({ ...object, icon });
              }}
              type="client"
            />
          </Grid>
        </Hidden>

        <Grid item style={{ flex: 1 }}>
          <Grid container>
            <Grid item style={{ maxWidth: '40%' }}>
              <Input
                value={object.label}
                placeholder={intl.formatMessage({ id: 'service.data.details.label' })}
                onChange={(evt) => setObject({ ...object, label: evt.target.value })}
                fullWidth
                classes={{ input: classes.noPadding }}
              />
            </Grid>
            <HSpacer />
            <div style={{ width: '100%', maxWidth: '40%' }}>
              <LocationSearch
                // @ts-expect-error ts-migrate(2322) FIXME: Type 'Element' is not assignable to type 'string'.
                placeholder={<FormattedMessage id="service.data.details.location" />}
                fieldValue={object.start_location}
                onUpdate={(startLocation: any) => {
                  setObject({
                    ...object,
                    location: startLocation,
                  });
                }}
                countryCode={countryCode}
                menuPlacement={searchMenuPlacement}
              />
            </div>
          </Grid>
        </Grid>
      </Grid>
    );
    return (
      <Grid
        container
        style={{
          height: '100%',
          backgroundColor: '#FDF6ED',
        }}
        alignItems="center"
      >
        <Grid item xs={10}>
          {entity === 'route' && renderRoute()}
          {entity === 'service' && renderService()}
        </Grid>

        <Grid item xs={2} style={{ padding: '8px' }}>
          <Grid container direction="row" justify="flex-end" spacing={2}>
            <Button
              color="secondary"
              variant="outlined"
              style={{ marginRight: '8px' }}
              onClick={() => {
                setObject({});
                destroy();
              }}
              className={classes.slimbuttonnowhite}
            >
              <Close />
            </Button>
            <Button
              color="primary"
              variant="outlined"
              style={{ marginRight: '8px' }}
              type="submit"
              onClick={() => {
                handleCreate(object);
                setObject({});
                destroy();
              }}
              className={classes.slimbuttonnowhite}
            >
              <Check />
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  }
);

type FormCreateFieldProps = {
  classes: {
    slimbutton: string;
    slimbuttonnowhite: string;
    noPadding: string;
    errorQuota: string;
  };
  handleCreate: (...args: any[]) => any;
  entity: 'route' | 'service';
  maxLength: number;
  actualLength: number;
  countryCode?: string | null;
  searchMenuPlacement?: 'bottom' | 'top';
};

const FormCreateField = ({
  handleCreate,
  entity,
  classes,
  maxLength,
  actualLength,
  countryCode,
  searchMenuPlacement,
}: FormCreateFieldProps) => {
  const [editable, setEditable] = useState(false);
  const [object, setObject] = useState({});

  return (
    <>
      {editable && (
        <FormCreateFieldEditable
          object={object}
          entity={entity}
          setObject={setObject}
          handleCreate={handleCreate}
          destroy={() => setEditable(false)}
          countryCode={countryCode}
          searchMenuPlacement={searchMenuPlacement}
        />
      )}
      {!editable ? (
        actualLength >= maxLength ? (
          <Tooltip
            placement="top-start"
            title={
              <FormattedMessage
                id={`plan.${entity}sdialog.add${entity}s.quota_exceeded`}
                values={{
                  maxServices: maxLength,
                  maxVehicles: maxLength,
                  surplus: Math.abs(actualLength - maxLength - 1),
                }}
              />
            }
          >
            <Grid
              container
              style={{ height: '100%' }}
              alignItems="center"
              onClick={() => {
                if (actualLength < maxLength) setEditable(true);
              }}
            >
              <Button
                color="secondary"
                variant="text"
                className={classes.slimbutton}
                style={{
                  color: '#57637C',
                  padding: '0px 16px',
                  ...(actualLength >= maxLength ? { color: '#CACACA' } : {}),
                }}
                disabled={actualLength >= maxLength}
              >
                <span>+{' '}<FormattedMessage tagName="span" id={`plans.actionbar.${entity === 'route' ? 'addroutes' : 'addservices'}`} /></span>
              </Button>
            </Grid>
          </Tooltip>
        ) : (
          <Grid
            container
            style={{ height: '100%' }}
            alignItems="center"
            onClick={() => {
              if (actualLength < maxLength) setEditable(true);
            }}
          >
            <Button
              color="secondary"
              variant="text"
              className={classes.slimbutton}
              style={{
                color: '#57637C',
                padding: '0px 16px',
                ...(actualLength >= maxLength ? { color: '#CACACA' } : {}),
              }}
              disabled={actualLength >= maxLength}
            >
              <span>+{' '}<FormattedMessage tagName="span" id={`plans.actionbar.${entity === 'route' ? 'addroutes' : 'addservices'}`} /></span>
            </Button>
          </Grid>
        )
      ) : null}
    </>
  );
};

export default withStyles(styles)(FormCreateField);
