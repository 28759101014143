export const vehicles_CO = [
  {
    end_location: {
      lat: 4.68089,
      lng: -74.08249,
      country: 'Colombia',
      country_code: 'COL',
      county: 'Bogotá, D.C.',
      house_number: '50',
      postal_code: '111211',
      label: 'Avenida Carrera 68 75A-50, 111211 Bogotá, D.C., Colombia',
      city: 'Bogotá, D.C.',
      district: 'Barrios Unidos',
      street: 'Avenida Carrera 68 75A',
    },
    label: 'Manuel',
    max_services: 17,
    start_location: {
      lat: 4.68089,
      lng: -74.08249,
      country: 'Colombia',
      country_code: 'COL',
      county: 'Bogotá, D.C.',
      house_number: '50',
      postal_code: '111211',
      label: 'Avenida Carrera 68 75A-50, 111211 Bogotá, D.C., Colombia',
      city: 'Bogotá, D.C.',
      district: 'Barrios Unidos',
      street: 'Avenida Carrera 68 75A',
    },
    timewindow: [32400, 57600],
  },
  {
    end_location: {
      lat: 4.56309,
      lng: -74.09852,
      country: 'Colombia',
      country_code: 'COL',
      county: 'Bogotá, D.C.',
      house_number: '13',
      postal_code: '110421',
      label: 'Calle 33B Sur 4-13, 110421 Bogotá, D.C., Colombia',
      city: 'Bogotá, D.C.',
      district: 'San Cristóbal',
      street: 'Calle 33B Sur 4',
    },
    label: 'Jose',
    max_services: 17,
    start_location: {
      lat: 4.56309,
      lng: -74.09852,
      country: 'Colombia',
      country_code: 'COL',
      county: 'Bogotá, D.C.',
      house_number: '13',
      postal_code: '110421',
      label: 'Calle 33B Sur 4-13, 110421 Bogotá, D.C., Colombia',
      city: 'Bogotá, D.C.',
      district: 'San Cristóbal',
      street: 'Calle 33B Sur 4',
    },
    timewindow: [39600, 64800],
  },
  {
    end_location: {
      lat: 4.71141,
      lng: -74.11163,
      country: 'Colombia',
      country_code: 'COL',
      county: 'Bogotá, D.C.',
      house_number: '20',
      postal_code: '111011',
      label: 'Transversal 100A Calle 80A-20, 111011 Bogotá, D.C., Colombia',
      city: 'Bogotá, D.C.',
      district: 'Engativá',
      street: 'Transversal 100A Calle 80A',
    },
    label: 'Pedro',
    max_services: 17,
    start_location: {
      lat: 4.71141,
      lng: -74.11163,
      country: 'Colombia',
      country_code: 'COL',
      county: 'Bogotá, D.C.',
      house_number: '20',
      postal_code: '111011',
      label: 'Transversal 100A Calle 80A-20, 111011 Bogotá, D.C., Colombia',
      city: 'Bogotá, D.C.',
      district: 'Engativá',
      street: 'Transversal 100A Calle 80A',
    },
    timewindow: [32400, 57600],
  },
  {
    end_location: {
      lat: 4.59318,
      lng: -74.13923,
      country: 'Colombia',
      country_code: 'COL',
      county: 'Bogotá, D.C.',
      house_number: '90',
      postal_code: '110611',
      label: 'Diagonal 47 Sur 51-90, 110611 Bogotá, D.C., Colombia',
      city: 'Bogotá, D.C.',
      district: 'Tunjuelito',
      street: 'Diagonal 47 Sur 51',
    },
    label: 'Pablo',
    max_services: 17,
    start_location: {
      lat: 4.59318,
      lng: -74.13923,
      country: 'Colombia',
      country_code: 'COL',
      county: 'Bogotá, D.C.',
      house_number: '90',
      postal_code: '110611',
      label: 'Diagonal 47 Sur 51-90, 110611 Bogotá, D.C., Colombia',
      city: 'Bogotá, D.C.',
      district: 'Tunjuelito',
      street: 'Diagonal 47 Sur 51',
    },
    timewindow: [39600, 64800],
  },
];

export const services_CO = [
  {
    label: 'RESTAURANTE MAIDO',
    location: {
      lat: 4.68089,
      lng: -74.08249,
      country: 'Colombia',
      country_code: 'COL',
      county: 'Bogotá, D.C.',
      house_number: '50',
      postal_code: '111211',
      label: 'Avenida Carrera 68 75A-50, 111211 Bogotá, D.C., Colombia',
      city: 'Bogotá, D.C.',
      district: 'Barrios Unidos',
      street: 'Avenida Carrera 68 75A',
    },
    duration: 300,
    timewindows: [[28800, 36000]],
  },
  {
    label: 'RESTAURANTE CENTRAL',
    location: {
      lat: 4.56309,
      lng: -74.09852,
      country: 'Colombia',
      country_code: 'COL',
      county: 'Bogotá, D.C.',
      house_number: '13',
      postal_code: '110421',
      label: 'Calle 33B Sur 4-13, 110421 Bogotá, D.C., Colombia',
      city: 'Bogotá, D.C.',
      district: 'San Cristóbal',
      street: 'Calle 33B Sur 4',
    },
    duration: 300,
    timewindows: [[28800, 46800]],
  },
  {
    label: 'RESTAURANTE PUJOL',
    location: {
      lat: 4.71141,
      lng: -74.11163,
      country: 'Colombia',
      country_code: 'COL',
      county: 'Bogotá, D.C.',
      house_number: '20',
      postal_code: '111011',
      label: 'Transversal 100A Calle 80A-20, 111011 Bogotá, D.C., Colombia',
      city: 'Bogotá, D.C.',
      district: 'Engativá',
      street: 'Transversal 100A Calle 80A',
    },
    duration: 300,
    timewindows: [[28800, 46800]],
  },
  {
    label: 'RESTAURANTE DON JULIO',
    location: {
      lat: 4.59318,
      lng: -74.13923,
      country: 'Colombia',
      country_code: 'COL',
      county: 'Bogotá, D.C.',
      house_number: '90',
      postal_code: '110611',
      label: 'Diagonal 47 Sur 51-90, 110611 Bogotá, D.C., Colombia',
      city: 'Bogotá, D.C.',
      district: 'Tunjuelito',
      street: 'Diagonal 47 Sur 51',
    },
    duration: 300,
    timewindows: [[28800, 46800]],
  },
  {
    label: 'RESTAURANTE BORAGO',
    location: {
      lat: 4.59623,
      lng: -74.0802,
      country: 'Colombia',
      country_code: 'COL',
      county: 'Bogotá, D.C.',
      house_number: '30',
      postal_code: '111711',
      label: 'Avenida Carrera 10 7-30, 111711 Bogotá, D.C., Colombia',
      city: 'Bogotá, D.C.',
      district: 'La Candelaria',
      street: 'Avenida Carrera 10 7',
    },
    duration: 300,
    timewindows: [[28800, 45000]],
  },
  {
    label: 'RESTAURANTE A CASA DO PORCO',
    location: {
      lat: 4.56309,
      lng: -74.09852,
      country: 'Colombia',
      country_code: 'COL',
      county: 'Bogotá, D.C.',
      house_number: '13',
      postal_code: '110421',
      label: 'Calle 33B Sur 4-13, 110421 Bogotá, D.C., Colombia',
      city: 'Bogotá, D.C.',
      district: 'San Cristóbal',
      street: 'Calle 33B Sur 4',
    },
    duration: 600,
    timewindows: [[28800, 45000]],
  },
  {
    label: 'RESTAURANTE EL CHATO',
    location: {
      lat: 4.68766,
      lng: -74.12768,
      country: 'Colombia',
      country_code: 'COL',
      county: 'Bogotá, D.C.',
      house_number: '30',
      postal_code: '110911',
      label: 'Avenida El Dorado 98-30, 110911 Bogotá, D.C., Colombia',
      city: 'Bogotá, D.C.',
      district: 'Fontibón',
      street: 'Avenida El Dorado 98',
    },
    duration: 300,
    timewindows: [[28800, 45000]],
  },
  {
    label: 'RESTAURANTE LEO',
    location: {
      lat: 4.6969,
      lng: -74.08321,
      country: 'Colombia',
      country_code: 'COL',
      county: 'Bogotá, D.C.',
      house_number: '98',
      postal_code: '111121',
      label: 'Avenida Boyacá-98, 111121 Bogotá, D.C., Colombia',
      city: 'Bogotá, D.C.',
      district: 'Suba',
      street: 'Avenida Boyacá',
    },
    duration: 300,
    timewindows: [[28800, 45000]],
  },
  {
    label: 'RESTAURANTE OSSO',
    location: {
      lat: 4.665,
      lng: -74.05607,
      country: 'Colombia',
      country_code: 'COL',
      county: 'Bogotá, D.C.',
      house_number: '13',
      postal_code: '110221',
      label: 'Carrera 13 79-13, 110221 Bogotá, D.C., Colombia',
      city: 'Bogotá, D.C.',
      district: 'Chapinero',
      street: 'Carrera 13 79',
    },
    duration: 300,
    timewindows: [[28800, 46800]],
  },
  {
    label: 'RESTAURANTE D.O.M.',
    location: {
      lat: 4.61669,
      lng: -74.12227,
      country: 'Colombia',
      country_code: 'COL',
      county: 'Bogotá, D.C.',
      house_number: '9',
      postal_code: '111621',
      label: 'Carrera 56 2-9, 111621 Bogotá, D.C., Colombia',
      city: 'Bogotá, D.C.',
      district: 'Puente Aranda',
      street: 'Carrera 56 2',
    },
    duration: 600,
    timewindows: [[28800, 46800]],
  },
  {
    label: 'RESTAURANTE QUINTONIL',
    location: {
      lat: 4.63033,
      lng: -74.11361,
      country: 'Colombia',
      country_code: 'COL',
      county: 'Bogotá, D.C.',
      house_number: '50',
      postal_code: '111611',
      label: 'Carrera 60 11-50, 111611 Bogotá, D.C., Colombia',
      city: 'Bogotá, D.C.',
      district: 'Puente Aranda',
      street: 'Carrera 60 11',
    },
    duration: 300,
    timewindows: [[28800, 46800]],
  },
  {
    label: 'RESTAURANTE ISOLINA',
    location: {
      lat: 4.72222,
      lng: -74.05192,
      country: 'Colombia',
      country_code: 'COL',
      county: 'Bogotá, D.C.',
      house_number: '21',
      postal_code: '111111',
      label: 'Calle 137 45-21, 111111 Bogotá, D.C., Colombia',
      city: 'Bogotá, D.C.',
      district: 'Suba',
      street: 'Calle 137 45',
    },
    duration: 420,
    timewindows: [[28800, 46800]],
  },
  {
    label: 'RESTAURANTE ASTRID Y GASTON',
    location: {
      lat: 4.64158,
      lng: -74.07335,
      country: 'Colombia',
      country_code: 'COL',
      county: 'Bogotá, D.C.',
      house_number: '97',
      postal_code: '111311',
      label: 'Avenida Calle 53 21-97, 111311 Bogotá, D.C., Colombia',
      city: 'Bogotá, D.C.',
      district: 'Teusaquillo',
      street: 'Avenida Calle 53 21',
    },
    duration: 480,
    timewindows: [[28800, 46800]],
  },
  {
    label: 'RESTAURANTE ALCALDE',
    location: {
      lat: 4.69206,
      lng: -74.13309,
      country: 'Colombia',
      country_code: 'COL',
      county: 'Bogotá, D.C.',
      house_number: '90',
      postal_code: '110911',
      label: 'Avenida Calle 26 103-90, 110911 Bogotá, D.C., Colombia',
      city: 'Bogotá, D.C.',
      district: 'Fontibón',
      street: 'Avenida Calle 26 103',
    },
    duration: 300,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE PANGEA',
    location: {
      lat: 4.67672,
      lng: -74.05206,
      country: 'Colombia',
      country_code: 'COL',
      county: 'Bogotá, D.C.',
      house_number: '81',
      postal_code: '110221',
      label: 'Avenida Carrera 15 92-81, 110221 Bogotá, D.C., Colombia',
      city: 'Bogotá, D.C.',
      district: 'Chapinero',
      street: 'Avenida Carrera 15 92',
    },
    duration: 300,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE SUD 777',
    location: {
      lat: 4.80931,
      lng: -74.10226,
      country: 'Colombia',
      country_code: 'COL',
      county: 'Cundinamarca',
      postal_code: '250010',
      label: 'Cota, Colombia',
      city: 'Cota',
    },
    duration: 300,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE MAITO',
    location: {
      lat: 4.71565,
      lng: -74.0964,
      country: 'Colombia',
      country_code: 'COL',
      county: 'Bogotá, D.C.',
      house_number: '32',
      postal_code: '111021',
      label: 'Calle 96 92-32, 111021 Bogotá, D.C., Colombia',
      city: 'Bogotá, D.C.',
      district: 'Engativá',
      street: 'Calle 96 92',
    },
    duration: 300,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE MANI',
    location: {
      lat: 4.65761,
      lng: -74.06146,
      country: 'Colombia',
      country_code: 'COL',
      county: 'Bogotá, D.C.',
      postal_code: '110231',
      label: 'Calle 71 13, 110231 Bogotá, D.C., Colombia',
      city: 'Bogotá, D.C.',
      district: 'Chapinero',
      street: 'Calle 71 13',
    },
    duration: 600,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE RAFAEL',
    location: {
      lat: 4.66538,
      lng: -74.07794,
      country: 'Colombia',
      country_code: 'COL',
      county: 'Bogotá, D.C.',
      house_number: '71',
      postal_code: '111221',
      label: 'Carrera 52 67A-71, 111221 Bogotá, D.C., Colombia',
      city: 'Bogotá, D.C.',
      district: 'Barrios Unidos',
      street: 'Carrera 52 67A',
    },
    duration: 300,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE MISHIGUENE',
    location: {
      lat: 4.69431,
      lng: -74.03273,
      country: 'Colombia',
      country_code: 'COL',
      county: 'Bogotá, D.C.',
      house_number: '50',
      postal_code: '110111',
      label: 'Avenida Carrera 7 116-50, 110111 Bogotá, D.C., Colombia',
      city: 'Bogotá, D.C.',
      district: 'Usaquén',
      street: 'Avenida Carrera 7 116',
    },
    duration: 300,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE KJOLLE',
    location: {
      lat: 4.66312,
      lng: -74.13101,
      country: 'Colombia',
      country_code: 'COL',
      county: 'Bogotá, D.C.',
      house_number: '52',
      postal_code: '110931',
      label: 'Calle 20 82-52, 110931 Bogotá, D.C., Colombia',
      city: 'Bogotá, D.C.',
      district: 'Fontibón',
      street: 'Calle 20 82',
    },
    duration: 300,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE HARRY SASSON',
    location: {
      lat: 4.77073,
      lng: -74.04161,
      country: 'Colombia',
      country_code: 'COL',
      county: 'Bogotá, D.C.',
      house_number: '43',
      postal_code: '110141',
      label: 'Calle 192 19-43, 110141 Bogotá, D.C., Colombia',
      city: 'Bogotá, D.C.',
      district: 'Usaquén',
      street: 'Calle 192 19',
    },
    duration: 600,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE OTEQUE',
    location: {
      lat: 4.65177,
      lng: -74.10663,
      country: 'Colombia',
      country_code: 'COL',
      county: 'Bogotá, D.C.',
      house_number: '10',
      postal_code: '110931',
      label: 'Carrera 68A 24B-10, 110931 Bogotá, D.C., Colombia',
      city: 'Bogotá, D.C.',
      district: 'Fontibón',
      street: 'Carrera 68A 24B',
    },
    duration: 300,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE LASAI',
    location: {
      lat: 4.68088,
      lng: -74.04064,
      country: 'Colombia',
      country_code: 'COL',
      county: 'Bogotá, D.C.',
      house_number: '49',
      postal_code: '110221',
      label: 'Avenida Calle 100 8A-49, 110221 Bogotá, D.C., Colombia',
      city: 'Bogotá, D.C.',
      district: 'Chapinero',
      street: 'Avenida Calle 100 8A',
    },
    duration: 540,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE TEGUI',
    location: {
      lat: 4.65684,
      lng: -74.04844,
      country: 'Colombia',
      country_code: 'COL',
      county: 'Bogotá, D.C.',
      house_number: '68',
      postal_code: '110221',
      label: 'Avenida Carrera 1 E-68, 110221 Bogotá, D.C., Colombia',
      city: 'Bogotá, D.C.',
      district: 'Chapinero',
      street: 'Avenida Carrera 1 E',
    },
    duration: 600,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE LA MAR',
    location: {
      lat: 4.76317,
      lng: -74.04526,
      country: 'Colombia',
      country_code: 'COL',
      county: 'Bogotá, D.C.',
      house_number: '3',
      postal_code: '111166',
      label: 'Calle 185 45-3, 111166 Bogotá, D.C., Colombia',
      city: 'Bogotá, D.C.',
      district: 'Suba',
      street: 'Calle 185 45',
    },
    duration: 300,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE ROSSETTA',
    location: {
      lat: 4.65684,
      lng: -74.04844,
      country: 'Colombia',
      country_code: 'COL',
      county: 'Bogotá, D.C.',
      house_number: '68',
      postal_code: '110221',
      label: 'Avenida Carrera 1 E-68, 110221 Bogotá, D.C., Colombia',
      city: 'Bogotá, D.C.',
      district: 'Chapinero',
      street: 'Avenida Carrera 1 E',
    },
    duration: 300,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE MAXIMO BISTROT',
    location: {
      lat: 4.60297,
      lng: -74.06751,
      country: 'Colombia',
      country_code: 'COL',
      county: 'Bogotá, D.C.',
      house_number: '10',
      postal_code: '111711',
      label: 'Calle 19 2A-10, 111711 Bogotá, D.C., Colombia',
      city: 'Bogotá, D.C.',
      district: 'La Candelaria',
      street: 'Calle 19 2A',
    },
    duration: 300,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE CHILA',
    location: {
      lat: 4.67981,
      lng: -74.12219,
      country: 'Colombia',
      country_code: 'COL',
      county: 'Bogotá, D.C.',
      house_number: '55',
      postal_code: '110911',
      label: 'Diagonal 25G Transversal 95-55, 110911 Bogotá, D.C., Colombia',
      city: 'Bogotá, D.C.',
      district: 'Fontibón',
      street: 'Diagonal 25G Transversal 95',
    },
    duration: 300,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE AMBROSIA',
    location: {
      lat: 4.66048,
      lng: -74.10866,
      country: 'Colombia',
      country_code: 'COL',
      county: 'Bogotá, D.C.',
      house_number: '69',
      postal_code: '110931',
      label: 'Avenida Calle 26 69C-69, 110931 Bogotá, D.C., Colombia',
      city: 'Bogotá, D.C.',
      district: 'Fontibón',
      street: 'Avenida Calle 26 69C',
    },
    duration: 300,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE NICOS',
    location: {
      lat: 4.73252,
      lng: -74.06549,
      country: 'Colombia',
      country_code: 'COL',
      county: 'Bogotá, D.C.',
      house_number: '51',
      postal_code: '111156',
      label: 'Carrera 58D 146-51, 111156 Bogotá, D.C., Colombia',
      city: 'Bogotá, D.C.',
      district: 'Suba',
      street: 'Carrera 58D 146',
    },
    duration: 600,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE LE CHIQUE',
    location: {
      lat: 4.69338,
      lng: -74.10741,
      country: 'Colombia',
      country_code: 'COL',
      county: 'Bogotá, D.C.',
      house_number: '85',
      postal_code: '111051',
      label: 'Transversal 85 Calle 70A-85, 111051 Bogotá, D.C., Colombia',
      city: 'Bogotá, D.C.',
      district: 'Engativá',
      street: 'Transversal 85 Calle 70A',
    },
    duration: 300,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE PARADOR LA HUELLA',
    location: {
      lat: 4.64786,
      lng: -74.06127,
      country: 'Colombia',
      country_code: 'COL',
      county: 'Bogotá, D.C.',
      house_number: '11',
      postal_code: '110231',
      label: 'Calle 62 9-11, 110231 Bogotá, D.C., Colombia',
      city: 'Bogotá, D.C.',
      district: 'Chapinero',
      street: 'Calle 62 9',
    },
    duration: 300,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE LE PATIO',
    location: {
      lat: 4.72175,
      lng: -73.96789,
      country: 'Colombia',
      country_code: 'COL',
      county: 'Cundinamarca',
      postal_code: '251201',
      label: 'La Calera, Colombia',
      city: 'La Calera',
    },
    duration: 300,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE OLIMPE',
    location: {
      lat: 4.8608,
      lng: -74.06035,
      country: 'Colombia',
      country_code: 'COL',
      county: 'Cundinamarca',
      house_number: '37',
      postal_code: '250001',
      label: 'Centro Comercial Santa Lucia, Calle 11 10-37, 250001 Chía, Colombia',
      city: 'Chía',
      district: 'Chía',
      street: 'Calle 11 10',
    },
    duration: 600,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE MIL',
    location: {
      lat: 4.59413,
      lng: -74.12335,
      country: 'Colombia',
      country_code: 'COL',
      county: 'Bogotá, D.C.',
      house_number: '7',
      postal_code: '111631',
      label: 'Autopista Sur 38A-7, 111631 Bogotá, D.C., Colombia',
      city: 'Bogotá, D.C.',
      district: 'Puente Aranda',
      street: 'Autopista Sur 38A',
    },
    duration: 300,
    timewindows: [[46800, 57600]],
  },
  {
    label: 'RESTAURANTE 040',
    location: {
      lat: 4.65603,
      lng: -74.05676,
      country: 'Colombia',
      country_code: 'COL',
      county: 'Bogotá, D.C.',
      house_number: '80',
      postal_code: '110221',
      label: 'Avenida Calle 72 8-80, 110221 Bogotá, D.C., Colombia',
      city: 'Bogotá, D.C.',
      district: 'Chapinero',
      street: 'Avenida Calle 72 8',
    },
    duration: 660,
    timewindows: [[46800, 57600]],
  },
  {
    label: 'RESTAURANTE LA DOCENA',
    location: {
      lat: 4.69794,
      lng: -74.06014,
      country: 'Colombia',
      country_code: 'COL',
      county: 'Bogotá, D.C.',
      house_number: '21',
      postal_code: '111111',
      label: 'Calle 113 50-21, 111111 Bogotá, D.C., Colombia',
      city: 'Bogotá, D.C.',
      district: 'Suba',
      street: 'Calle 113 50',
    },
    duration: 720,
    timewindows: [[46800, 57600]],
  },
  {
    label: 'RESTAURANTE MANU',
    location: {
      lat: 4.68421,
      lng: -74.04604,
      country: 'Colombia',
      country_code: 'COL',
      county: 'Bogotá, D.C.',
      house_number: '54',
      postal_code: '110111',
      label: 'Avenida Calle 100 13-54, 110111 Bogotá, D.C., Colombia',
      city: 'Bogotá, D.C.',
      district: 'Usaquén',
      street: 'Avenida Calle 100 13',
    },
    duration: 300,
    timewindows: [[46800, 57600]],
  },
  {
    label: 'RESTAURANTE MOCOTO',
    location: {
      lat: 4.58027,
      lng: -74.07279,
      country: 'Colombia',
      country_code: 'COL',
      county: 'Bogotá, D.C.',
      postal_code: '110321',
      label: 'El Dorado, Bogotá, D.C., Colombia',
      city: 'Bogotá, D.C.',
      district: 'El Dorado',
    },
    duration: 300,
    timewindows: [[46800, 57600]],
  },
  {
    label: 'RESTAURANTE OSAKA',
    location: {
      lat: 4.58799,
      lng: -74.2027,
      country: 'Colombia',
      country_code: 'COL',
      county: 'Cundinamarca',
      house_number: '35',
      postal_code: '250051',
      label: 'Carrera 7 32-35, 250051 Soacha, Colombia',
      city: 'Soacha',
      district: 'Despensa',
      street: 'Carrera 7 32',
    },
    duration: 300,
    timewindows: [[46800, 57600]],
  },
  {
    label: 'RESTAURANTE ELENA',
    location: {
      lat: 4.69206,
      lng: -74.13309,
      country: 'Colombia',
      country_code: 'COL',
      county: 'Bogotá, D.C.',
      house_number: '90',
      postal_code: '110911',
      label: 'Avenida Calle 26 103-90, 110911 Bogotá, D.C., Colombia',
      city: 'Bogotá, D.C.',
      district: 'Fontibón',
      street: 'Avenida Calle 26 103',
    },
    duration: 300,
    timewindows: [[46800, 57600]],
  },
  {
    label: 'RESTAURANTE GRAN DABBANG',
    location: {
      lat: 4.59995,
      lng: -74.08366,
      country: 'Colombia',
      country_code: 'COL',
      county: 'Bogotá, D.C.',
      house_number: '13',
      postal_code: '111411',
      label: 'Carrera 15 8-13, 111411 Bogotá, D.C., Colombia',
      city: 'Bogotá, D.C.',
      district: 'Los Mártires',
      street: 'Carrera 15 8',
    },
    duration: 300,
    timewindows: [[46800, 57600]],
  },
  {
    label: 'RESTAURANTE 99',
    location: {
      lat: 4.64337,
      lng: -74.07524,
      country: 'Colombia',
      country_code: 'COL',
      county: 'Bogotá, D.C.',
      postal_code: '111311',
      label: 'Calle 53B 25, 111311 Bogotá, D.C., Colombia',
      city: 'Bogotá, D.C.',
      district: 'Galerias',
      street: 'Calle 53B 25',
    },
    duration: 600,
    timewindows: [[46800, 57600]],
  },
  {
    label: 'RESTAURANTE MALABAR',
    location: {
      lat: 4.69622,
      lng: -74.08698,
      country: 'Colombia',
      country_code: 'COL',
      county: 'Bogotá, D.C.',
      postal_code: '111021',
      label: 'Avenida Carrera 72 80, 111021 Bogotá, D.C., Colombia',
      city: 'Bogotá, D.C.',
      district: 'Engativá',
      street: 'Avenida Carrera 72 80',
    },
    duration: 300,
    timewindows: [[46800, 57600]],
  },
  {
    label: 'RESTAURANTE MAYTA',
    location: {
      lat: 4.65812,
      lng: -74.05784,
      country: 'Colombia',
      country_code: 'COL',
      county: 'Bogotá, D.C.',
      house_number: '83',
      postal_code: '110221',
      label: 'Centro Comercial Avenida Chile, Calle 73 10-83, 110221 Bogotá, D.C., Colombia',
      city: 'Bogotá, D.C.',
      district: 'Chapinero',
      street: 'Calle 73 10',
    },
    duration: 300,
    timewindows: [[46800, 57600]],
  },
  {
    label: 'RESTAURANTE NARDA COMEDOR',
    location: {
      lat: 4.5914,
      lng: -74.10899,
      country: 'Colombia',
      country_code: 'COL',
      county: 'Bogotá, D.C.',
      house_number: '80',
      postal_code: '111511',
      label: 'Centro Comercial Calima, Calle 19 Sur 28-80, 111511 Bogotá, D.C., Colombia',
      city: 'Bogotá, D.C.',
      district: 'Antonio Nariño',
      street: 'Calle 19 Sur 28',
    },
    duration: 300,
    timewindows: [[46800, 57600]],
  },
  {
    label: 'HOTEL NH',
    location: {
      lat: 4.66312,
      lng: -74.13101,
      country: 'Colombia',
      country_code: 'COL',
      county: 'Bogotá, D.C.',
      house_number: '52',
      postal_code: '110931',
      label: 'Calle 20 82-52, 110931 Bogotá, D.C., Colombia',
      city: 'Bogotá, D.C.',
      district: 'Fontibón',
      street: 'Calle 20 82',
    },
    duration: 600,
    timewindows: [[46800, 57600]],
  },
  {
    label: 'HOTEL MARIA BONITA',
    location: {
      lat: 4.71337,
      lng: -74.03398,
      country: 'Colombia',
      country_code: 'COL',
      county: 'Bogotá, D.C.',
      house_number: '30',
      postal_code: '110121',
      label: 'Avenida Calle 134 9A-30, 110121 Bogotá, D.C., Colombia',
      city: 'Bogotá, D.C.',
      district: 'Usaquén',
      street: 'Avenida Calle 134 9A',
    },
    duration: 300,
    timewindows: [[46800, 57600]],
  },
  {
    label: 'HOTEL W',
    location: {
      lat: 4.65271,
      lng: -74.10918,
      country: 'Colombia',
      country_code: 'COL',
      county: 'Bogotá, D.C.',
      house_number: '39',
      postal_code: '110931',
      label: 'Carrera 68B 24-39, 110931 Bogotá, D.C., Colombia',
      city: 'Bogotá, D.C.',
      district: 'Fontibón',
      street: 'Carrera 68B 24',
    },
    duration: 780,
    timewindows: [[46800, 57600]],
  },
  {
    label: 'HOTEL SHERATON',
    location: {
      lat: 4.65789,
      lng: -74.05373,
      country: 'Colombia',
      country_code: 'COL',
      county: 'Bogotá, D.C.',
      house_number: '21',
      postal_code: '110221',
      label: 'Avenida Carrera 7 75-21, 110221 Bogotá, D.C., Colombia',
      city: 'Bogotá, D.C.',
      district: 'Chapinero',
      street: 'Avenida Carrera 7 75',
    },
    duration: 840,
    timewindows: [[46800, 57600]],
  },
  {
    label: 'BAR AMSTERDAM',
    location: {
      lat: 4.69101,
      lng: -74.0386,
      country: 'Colombia',
      country_code: 'COL',
      county: 'Bogotá, D.C.',
      house_number: '25',
      postal_code: '110111',
      label: 'Calle 110 9-25, 110111 Bogotá, D.C., Colombia',
      city: 'Bogotá, D.C.',
      district: 'Usaquén',
      street: 'Calle 110 9',
    },
    duration: 300,
    timewindows: [[46800, 57600]],
  },
  {
    label: 'RESTAURANTE COLOMBIA PAN Y SABOR',
    location: {
      lat: 4.68201,
      lng: -74.14384,
      country: 'Colombia',
      country_code: 'COL',
      county: 'Bogotá, D.C.',
      house_number: '39',
      postal_code: '110911',
      label: 'Calle 22H 106-39, 110911 Bogotá, D.C., Colombia',
      city: 'Bogotá, D.C.',
      district: 'Fontibón',
      street: 'Calle 22H 106',
    },
    duration: 300,
    timewindows: [[46800, 57600]],
  },
  {
    label: 'RESTAURANTE LA VIETNAMITA',
    location: {
      lat: 4.75929,
      lng: -74.06335,
      country: 'Colombia',
      country_code: 'COL',
      county: 'Bogotá, D.C.',
      house_number: '38',
      postal_code: '111166',
      label: 'Carrera 69 170-38, 111166 Bogotá, D.C., Colombia',
      city: 'Bogotá, D.C.',
      district: 'Suba',
      street: 'Carrera 69 170',
    },
    duration: 300,
    timewindows: [[28800, 46800]],
  },
  {
    label: 'RESTAURANTE EL TLAXCAL',
    location: {
      lat: 4.61627,
      lng: -74.07094,
      country: 'Colombia',
      country_code: 'COL',
      county: 'Bogotá, D.C.',
      house_number: '16',
      postal_code: '110311',
      label: 'Calle 28 13A-16, 110311 Bogotá, D.C., Colombia',
      city: 'Bogotá, D.C.',
      district: 'Santa Fé',
      street: 'Calle 28 13A',
    },
    duration: 300,
    timewindows: [[28800, 46800]],
  },
  {
    label: 'RESTAURANTE LA CHILANGUITA',
    location: {
      lat: 4.61427,
      lng: -74.07248,
      country: 'Colombia',
      country_code: 'COL',
      county: 'Bogotá, D.C.',
      house_number: '85',
      postal_code: '110311',
      label: 'Carrera 13B-85, 110311 Bogotá, D.C., Colombia',
      city: 'Bogotá, D.C.',
      district: 'Santa Fé',
      street: 'Carrera 13B',
    },
    duration: 600,
    timewindows: [[28800, 46800]],
  },
  {
    label: 'RESTAURANTE LA COSA NOSTRA',
    location: {
      lat: 4.69831,
      lng: -74.06838,
      country: 'Colombia',
      country_code: 'COL',
      county: 'Bogotá, D.C.',
      house_number: '78',
      postal_code: '111111',
      label: 'Transversal 58 Calle 114A-78, 111111 Bogotá, D.C., Colombia',
      city: 'Bogotá, D.C.',
      district: 'Suba',
      street: 'Transversal 58 Calle 114A',
    },
    duration: 300,
    timewindows: [[28800, 46800]],
  },
  {
    label: 'RESTAURANTE FISHERS',
    location: {
      lat: 4.60127,
      lng: -74.16119,
      country: 'Colombia',
      country_code: 'COL',
      county: 'Bogotá, D.C.',
      postal_code: '110741',
      label:
        'Centro Comercial Centro Mayor-Cinecolombia, Calle 56A Sur 70, 110741 Bogotá, D.C., Colombia',
      city: 'Bogotá, D.C.',
      district: 'Bosa',
      street: 'Calle 56A Sur 70',
    },
    duration: 300,
    timewindows: [[28800, 46800]],
  },
  {
    label: 'RESTAURANTE DE TAPAS',
    location: {
      lat: 4.74887,
      lng: -74.09494,
      country: 'Colombia',
      country_code: 'COL',
      county: 'Bogotá, D.C.',
      postal_code: '111161',
      label: 'Avenida Carrera 104 148, 111161 Bogotá, D.C., Colombia',
      city: 'Bogotá, D.C.',
      district: 'Suba',
      street: 'Avenida Carrera 104 148',
    },
    duration: 300,
    timewindows: [[28800, 46800]],
  },
  {
    label: 'RESTAURANTE LAS CAZUELAS',
    location: {
      lat: 4.59721,
      lng: -74.17602,
      country: 'Colombia',
      country_code: 'COL',
      county: 'Bogotá, D.C.',
      house_number: '82',
      postal_code: '110741',
      label: 'Calle 57Q Sur 75F-82, 110741 Bogotá, D.C., Colombia',
      city: 'Bogotá, D.C.',
      district: 'Bosa',
      street: 'Calle 57Q Sur 75F',
    },
    duration: 600,
    timewindows: [[28800, 46800]],
  },
  {
    label: 'RESTAURANTE PALOMARES',
    location: {
      lat: 4.63203,
      lng: -74.07482,
      country: 'Colombia',
      country_code: 'COL',
      county: 'Bogotá, D.C.',
      house_number: '58',
      postal_code: '111311',
      label: 'Avenida Carrera 24 41-58, 111311 Bogotá, D.C., Colombia',
      city: 'Bogotá, D.C.',
      district: 'Teusaquillo',
      street: 'Avenida Carrera 24 41',
    },
    duration: 300,
    timewindows: [[28800, 46800]],
  },
  {
    label: 'RESTAURANTE SOUL',
    location: {
      lat: 4.64309,
      lng: -74.05918,
      country: 'Colombia',
      country_code: 'COL',
      county: 'Bogotá, D.C.',
      house_number: '59',
      postal_code: '110231',
      label: 'Carrera 3A 58-59, 110231 Bogotá, D.C., Colombia',
      city: 'Bogotá, D.C.',
      district: 'Chapinero',
      street: 'Carrera 3A 58',
    },
    duration: 900,
    timewindows: [[28800, 46800]],
  },
  {
    label: 'RESTAURANTE CLORODMIRA',
    location: {
      lat: 4.67672,
      lng: -74.05206,
      country: 'Colombia',
      country_code: 'COL',
      county: 'Bogotá, D.C.',
      house_number: '81',
      postal_code: '110221',
      label: 'Avenida Carrera 15 92-81, 110221 Bogotá, D.C., Colombia',
      city: 'Bogotá, D.C.',
      district: 'Chapinero',
      street: 'Avenida Carrera 15 92',
    },
    duration: 960,
    timewindows: [
      [32400, 50400],
      [61200, 72000],
    ],
  },
];
