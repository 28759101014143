import { AvatarIcon, DefaultAvatar } from '@sm-highway-web/react-components';
import React, { useEffect, useState } from 'react';
import { getUploadAsBase64 } from '../services/requests';

type AvatarProps = {
  size?: number;
  data?: {
    // eslint-disable-next-line camelcase
    avatar_url?: string;
  };
  label?: React.ReactElement | string;
  labelOverlayed?: boolean;
};

const Avatar = ({ data, label, labelOverlayed, size }: AvatarProps) => {
  const [avatar, setAvatar] = useState<string | null | undefined>(undefined);

  useEffect(() => {
    (async () => {
      if (data?.avatar_url) {
        const userAvatar = await getUploadAsBase64(data.avatar_url);
        setAvatar(userAvatar);
      } else {
        setAvatar(undefined);
      }
    })();
  }, [data, setAvatar]);

  if (!data) return null;

  return (
    <DefaultAvatar
      label={label}
      labelOverlayed={labelOverlayed}
      icon={AvatarIcon}
      avatar={avatar}
      size={size}
    />
  );
};

Avatar.defaultProps = {
  size: 30,
  data: undefined,
  label: undefined,
  labelOverlayed: false,
};

export default Avatar;
