import { Button as MButton, ButtonGroup, Grid, Hidden, Tooltip } from '@material-ui/core';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import { FlashOn, List, Map, MobileScreenShare, PanoramaFishEye } from '@material-ui/icons';
import { ActionBar, Button, HSpacer } from '@sm-highway-web/react-components';
import { IPlanData } from 'highway-api/dist/common/interfaces/plans';
import { IProjectData } from 'highway-api/dist/common/interfaces/projects';
import React, { useCallback, useEffect, useReducer, useState } from 'react';
import { connect } from 'react-redux';
import RocketAnimation from '../assets/animations/RocketAnimation';
import FormIcon from '../components/form-fields/FormIcon';
import FormText from '../components/form-fields/FormText';
import FormattedMessage from '../components/FormattedMessageCustom';
import { anyServiceHasAnyWarnings } from '../components/OptimizationWarningsTooltip';
import PlanInspector, { PlanInspectorLive } from '../components/plans/PlanInspector';
import PlanList from '../components/plans/plan-list/PlanList';
import PlanLive from '../components/plans/plan-live/PlanLive';
import PlanMap from '../components/plans/plan-map/PlanMap';
import PopoverMore3Points from '../components/PopoverMore3Points';
import {
  ONBOARDING_STEPS,
  ONBOARDING_TUTORIAL_NAME,
} from '../components/tutorials/OnboardingTutorial';
import {
  PROJECT_OPTIMIZER_CONIFG_MAP,
  PROJECT_OPTIMIZER_CONIFG_SM_ROOT_MAP,
} from '../constants/OptimizerConfig';
import * as routing from '../constants/Routing';
import { LARGE_OPTIMIZATION_MAX_SERVICES } from '../constants/Services';
import AddRoutesDialog from '../dialogs/AddRoutesDialog';
import AddServicesDialog from '../dialogs/AddServicesDialog';
import AssignDialog from '../dialogs/AssignDialog';
import ConfirmDeleteDialog from '../dialogs/ConfirmDeleteDialog';
import ConfirmDialog from '../dialogs/ConfirmDialog';
import ConstraintsDialog from '../dialogs/ConstraintsDialog';
import { parseDataToExcel, saveData } from '../helpers/Excel';
import history from '../helpers/History';
import { IUserLocation } from '../interfaces/common';
import { IOrganizationData } from '../interfaces/organizations';
import { IUserData } from '../interfaces/users';
import { ActivePlan, Plans, Tutorial } from '../redux/actions';
import { projectHelpers, userHelpers } from '../redux/helpers';
import eventsPanel from '../services/events';

const styles = ({ palette }: Theme) => createStyles({
  divHeader: {
    height: '48px',
    overflow: 'hidden',
    padding: '0px 16px',
  },

  divContainer: {
    height: 'calc(100% - 48px)',
    position: 'relative',
  },

  divTable: {},

  allFull: {
    height: '100%',
    width: '100%',
    padding: '6px',
  },

  rightPanelInfo: {
    position: 'absolute',
    right: '0px',
    top: '0px',
    height: '100%',
    zIndex: 1200,
    width: '30%',
    backgroundColor: 'white',
    overflow: 'hidden',
  },

  slimButton: {
    color: 'white',
  },

  slimButtonDisabled: {
    color: `${palette.secondary.main} !important`,
  },

  optimizeButton: {
    borderRadius: '4px',
    width: '200px',
    backgroundColor: palette.primary.main,
    color: 'white',
    '&:hover': {
      backgroundColor: 'rgba(255, 130, 0, 0.7)',
    },
  },
});

type OwnPlanPageProps = {
  classes: {
    divHeader: string;
    divContainer: string;
    divTable: string;
    allFull: string;
    rightPanelInfo: string;
    slimButton: string;
    slimButtonDisabled: string;
    optimizeButton: string;
  };
  history: {
    push: (...args: any[]) => any;
  };
  match: {
    params: {
      planId?: string;
      view?: string;
    };
  };
  optimizingPlan: boolean;
  plan?: IPlanData;
  user: IUserData;
  activeProject: IProjectData;
  tutorial: {
    currentTutorial?: any; // TODO: { id: PropTypes.string, step: PropTypes.number }
    isActive?: boolean;
  };
  userLocation?: IUserLocation;
  actions: {
    getPlan: (...args: any[]) => any;
    updatePlan: (...args: any[]) => any;
    optimizePlan: (...args: any[]) => any;
    cleanUpPlan: (...args: any[]) => any;
    cleanUpDetailView: (...args: any[]) => any;
    deletePlan: (...args: any[]) => any;
    tutorialSetStep: (...args: any[]) => any;
    changeOptions: (...args: any[]) => any;
  };
  organization: IOrganizationData;
  asUser?: string;
};

type PlanPageProps = OwnPlanPageProps;

const PlanPage = ({
  classes,
  actions,
  match,
  optimizingPlan,
  plan,
  user,
  activeProject,
  tutorial,
  organization,
  asUser,
  userLocation,
}: PlanPageProps) => {
  const [servicesModal, setServicesModal] = useReducer(
    (prevServicesModal) => !prevServicesModal,
    false
  );
  const [routesModal, setRoutesModal] = useReducer((prevRoutesModal) => !prevRoutesModal, false);
  const [loadingOpt, setLoadingOpt] = useState(false);
  const [tabSelected, setTabSelected] = useState('');
  const [assignDialog, setAssignDialog] = useState(false);
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean | null>(null);
  const [openOptimizeDialog, setOpenOptimizeDialog] = useState(false);
  const [openWarningsDialog, setOpenWarningsDialog] = useState(false);
  const [optionsDialog, setOptionsDialog] = useState(false);
  const [optimizing, setOptimizing] = useState(false);
  const [isOnboardingTutorialActive, setIsOnboardingTutorialActive] = useState(false);

  useEffect(() => {
    if (
      tutorial &&
      tutorial.isActive === true &&
      tutorial.currentTutorial.id === ONBOARDING_TUTORIAL_NAME
    ) {
      setIsOnboardingTutorialActive(true);

      if (tutorial.currentTutorial.step !== ONBOARDING_STEPS.DISPATCH_DIALOG) {
        setAssignDialog(false);
      }
    } else {
      setIsOnboardingTutorialActive(false);
    }
  }, [tutorial]);

  const setLocationView = useCallback(
    (view) => {
      if (plan && view !== tabSelected && Object.keys(routing.PlanViews).indexOf(view) > -1) {
        setTabSelected(view);
        eventsPanel.planMenu(view);
        history.push(`${routing.PLANS.replace(':projectId', activeProject.id)}/${plan.id}/${view}`);
      }
    },
    [tabSelected, activeProject, setTabSelected, plan]
  );

  useEffect(() => {
    if (match.params.view && Object.keys(routing.PlanViews).indexOf(match.params.view) > -1) {
      setTabSelected(match.params.view);
    } else {
      setLocationView(routing.PlanViews.list);
    }
  }, [setLocationView, match.params.view]);

  useEffect(() => {
    if (match.params.planId) {
      actions.getPlan(match.params.planId);
    }
  }, [actions, match.params.planId]);

  useEffect(() => {
    if (plan) {
      setLoadingOpt(false);
    }
  }, [plan]);

  useEffect(() => {
    setOptimizing(optimizingPlan);
    if (!optimizingPlan) setLoadingOpt(false);
  }, [optimizingPlan]);

  useEffect(() => {
    return () => {
      actions.cleanUpPlan();
      actions.cleanUpDetailView();
    };
  }, [actions]);

  const handleChangeLabel = (value: any) => {
    if(plan?.id)actions.updatePlan(plan.id, { label: value });
  };

  const handleChangeOptions = (options: any) => {
    setOptionsDialog(false);
    actions.changeOptions(match.params.planId, options);
  };

  const exportExcel = () => {
    if (plan) {
      saveData(parseDataToExcel(plan), plan.label);
    }
  };

  const handleOptimizePlan = () => {
    if(plan?.services) {
      setLoadingOpt(true);
      actions.optimizePlan(
        match.params.planId,
        plan.services.length > LARGE_OPTIMIZATION_MAX_SERVICES ? true : undefined
      );
    }
  };

  const OptimizerButton = () => (
    <Tooltip
      title={
        <FormattedMessage
          id={
            plan === undefined || plan.services.length === 0 || plan.routes.length === 0
              ? 'plans.actionbar.optimize.tooltip.disabled'
              : 'plans.actionbar.optimize.tooltip'
          }
        />
      }
    >
      <MButton
        id="optimize-button"
        variant="contained"
        classes={{
          disabled: classes.slimButtonDisabled,
          root: classes.optimizeButton,
        }}
        disabled={
          plan === undefined ||
          !plan.services ||
          plan.services.length === 0 ||
          !plan.routes ||
          plan.routes.length === 0 ||
          loadingOpt ||
          plan.routes.filter((route: any) => route.is_locked).length === plan.routes.length
        }
        onClick={() => {
          if (plan && plan.services) {
            const requiresConfirmation = plan.services.some((service: any) => service.route_id);
            if (!requiresConfirmation) {
              if(anyServiceHasAnyWarnings(plan)) {
                setOpenWarningsDialog(true);
              } else {
                handleOptimizePlan();
              }              
              return;
            }
            setOpenOptimizeDialog(true);
          }
        }}
      >
        {optimizing && (
          <>
            <Grid
              container
              direction="column"
              justify="center"
              style={{ height: '100%', width: '32px' }}
            >
              <div className="lds-ellipsis-little" style={{ height: '8px' }}>
                <div style={{ top: '2px', backgroundColor: 'white' }} />
                <div style={{ top: '2px', backgroundColor: 'white' }} />
                <div style={{ top: '2px', backgroundColor: 'white' }} />
              </div>
            </Grid>
            <HSpacer small />
          </>
        )}
        {!optimizing && <FlashOn />}
        <Hidden mdDown>
          <FormattedMessage id="plans.actionbar.optimize" />
        </Hidden>
      </MButton>
    </Tooltip>
  );

  return (
    <div>
      {isOnboardingTutorialActive &&
      tutorial.currentTutorial.step === ONBOARDING_STEPS.OPTIMIZE_BUTTON &&
      (tabSelected === routing.PlanViews.list || tabSelected === routing.PlanViews.map) ? (
            <RocketAnimation targetId="optimize-button" optimizing={optimizing} />
          ) : null}

      <ActionBar color="#050D1E">
        <Grid container className={classes.allFull} direction="column" justify="center">
          <Grid container>
            <Grid item md={4}>
              <Grid container alignItems="center" style={{ height: '100%' }}>
                {plan && (
                  <Grid container alignItems="center">
                    <Grid item md={2} style={{ flexBasis: 'unset' }}>
                      <FormIcon
                        fieldValue={plan.status || 'planning'}
                        onUpdate={(status: any) => {
                          actions.updatePlan(plan.id, { status }, true);
                        }}
                        type="status"
                      />
                    </Grid>
                    <Grid item md={10}>
                      <FormText
                        variant="heading"
                        noWrap
                        fieldValue={plan.label}
                        handleEnter={handleChangeLabel}
                      />
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid item sm={8} md={4}>
              <Grid container justify="center">
                <ButtonGroup
                  variant="contained"
                  aria-label="contained primary button group"
                  id="plan-list-views-tabs"
                >
                  <Button
                    color={tabSelected === routing.PlanViews.list ? 'primary' : 'secondary'}
                    style={
                      tabSelected === routing.PlanViews.list
                        ? { backgroundColor: 'rgb(51, 57, 64)', color: '#FF8200', width: '130px' }
                        : { width: '130px' }
                    }
                    onClick={() => setLocationView(routing.PlanViews.list)}
                  >
                    <List />
                    <HSpacer small />
                    <FormattedMessage id="plans.actionbar.buttongroup1" />
                  </Button>
                  <Button
                    color={tabSelected === routing.PlanViews.map ? 'primary' : 'secondary'}
                    style={
                      tabSelected === routing.PlanViews.map
                        ? { backgroundColor: 'rgb(51, 57, 64)', color: '#FF8200', width: '130px' }
                        : { width: '130px' }
                    }
                    onClick={() => setLocationView(routing.PlanViews.map)}
                  >
                    <Map
                      // @ts-expect-error ts-migrate(2322) FIXME: Type '{ center: (number | undefined)[] | undefined... Remove this comment to see the full error message
                      center={
                        userLocation ? [userLocation.latitude, userLocation.longitude] : undefined
                      }
                      zoomLevel={userLocation ? userLocation.mapZoomLevel : undefined}
                    />
                    <HSpacer small />
                    <FormattedMessage id="plans.actionbar.buttongroup2" />
                  </Button>
                  <Button
                    color={tabSelected === routing.PlanViews.live ? 'primary' : 'secondary'}
                    style={
                      tabSelected === routing.PlanViews.live
                        ? { backgroundColor: 'rgb(51, 57, 64)', color: '#FF8200', width: '130px' }
                        : { width: '130px' }
                    }
                    onClick={() => setLocationView(routing.PlanViews.live)}
                    // disabled={plan && plan.status !== 'in_progress'}
                  >
                    <PanoramaFishEye />
                    <HSpacer small />
                    <FormattedMessage id="plans.actionbar.buttongroup3" />
                  </Button>
                </ButtonGroup>
              </Grid>
            </Grid>
            <Grid item sm={4}>
              <Grid container justify="flex-end" alignItems="center">
                <HSpacer small />
                <Button
                  id="dispatch-button"
                  variant="contained"
                  color="secondary"
                  classes={{
                    disabled: classes.slimButtonDisabled,
                    root: classes.optimizeButton,
                  }}
                  onClick={() => {
                    setAssignDialog(true);
                    eventsPanel.planDispatch();
                    setTimeout(() => {
                      // This timeout avoid trying to show tutorial in a dialog button that has not already been mounted.
                      if (
                        isOnboardingTutorialActive &&
                        tutorial.currentTutorial.step === ONBOARDING_STEPS.DISPATCH_BUTTON
                      ) {
                        actions.tutorialSetStep(
                          ONBOARDING_TUTORIAL_NAME,
                          ONBOARDING_STEPS.DISPATCH_DIALOG
                        );
                      }
                    }, 1500);
                  }}
                  disabled={!plan || plan.routes.length < 1}
                >
                  <MobileScreenShare />
                  <Hidden mdDown>
                    <FormattedMessage id="plans.actionbar.dispatch" />
                  </Hidden>
                </Button>
                <HSpacer small />
                <Grid item>
                  <PopoverMore3Points
                    idButton="popoverplan"
                    large
                    arrayActions={[
                      {
                        close: true,
                        onClick: () => exportExcel(),
                        formattedMessage: 'planoverview.exportexcel',
                      },
                      {
                        close: true,
                        onClick: () => setOptionsDialog(true),
                        formattedMessage: 'planoverview.configure_plan',
                      },
                      {
                        close: true,
                        onClick: () => {
                          history.push(
                            `${routing.SETTINGS.ORGANIZATION.PROJECTS.ROOT}/${activeProject.id}/${routing.ProjectEditViews.fields}`
                          );
                        },
                        formattedMessage: 'planoverview.configure_fields',
                      },
                      {
                        close: true,
                        onClick: () => {
                          history.push(
                            `${routing.SETTINGS.ORGANIZATION.PROJECTS.ROOT}/${activeProject.id}/${routing.ProjectEditViews.communication}`
                          );
                        },
                        formattedMessage: 'planoverview.configure_communication',
                      },
                      {
                        close: true,
                        onClick: () => {
                          setOpenDeleteDialog(true);
                        },
                        formattedMessage: 'planoverview.deleteplan',
                        color: '#F44336',
                      },
                    ]}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </ActionBar>
      {tabSelected === routing.PlanViews.list && (
        <>
          <PlanList openRoutesModal={setRoutesModal} openServicesModal={setServicesModal} />
          <Grid
            container
            // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
            style={{
              backgroundColor: '#333940',
              padding: '6px',
              height: '48px',
              position: 'absolute',
              ...(isOnboardingTutorialActive ? { zIndex: '1300' } : {}),
            }}
            alignItems="center"
          >
            <Grid item md={3} style={{ textAlign: 'left' }}>
              {plan && plan.status !== 'planning' && <PlanInspectorLive plan={plan} />}
            </Grid>
            <Grid item md={6} style={{ height: '36px' }}>
              <PlanInspector plan={plan} />
            </Grid>
            <Grid item md={3} style={{ textAlign: 'right', height: '36px' }}>
              {OptimizerButton()}
            </Grid>
          </Grid>
        </>
      )}
      {tabSelected === routing.PlanViews.map && (
        <>
          <PlanMap />
          <Grid
            container
            // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
            style={{
              backgroundColor: '#333940',
              padding: '6px',
              height: '48px',
              position: 'absolute',
              ...(isOnboardingTutorialActive ? { zIndex: '1300' } : {}),
            }}
            alignItems="center"
          >
            <Grid item md={3} style={{ textAlign: 'left' }}>
              {plan && plan.status !== 'planning' && <PlanInspectorLive plan={plan} />}
            </Grid>
            <Grid item md={6}>
              <PlanInspector plan={plan} />
            </Grid>
            <Grid item md={3} style={{ textAlign: 'right' }}>
              {OptimizerButton()}
            </Grid>
          </Grid>
        </>
      )}
      {tabSelected === routing.PlanViews.live && <PlanLive />}
      <AddServicesDialog
        open={servicesModal}
        handleOpenCloseDialog={setServicesModal}
        planId={match.params.planId}
      />
      <AddRoutesDialog
        open={routesModal}
        handleOpenCloseDialog={setRoutesModal}
        planId={match.params.planId}
      />
      <AssignDialog
        project={activeProject}
        publicKey={user.public_key}
        handleOpenAssignDialog={() => {
          setAssignDialog(!assignDialog);
          if (
            isOnboardingTutorialActive &&
            tutorial.currentTutorial.step === ONBOARDING_STEPS.DISPATCH_DIALOG
          ) {
            actions.tutorialSetStep(ONBOARDING_TUTORIAL_NAME, ONBOARDING_STEPS.PLAN_LIST_TABS);
          }
        }}
        assignDialogOpen={assignDialog}
        routes={plan && plan.routes}
      />
      <ConfirmDeleteDialog
        typeData="plans"
        open={Boolean(openDeleteDialog)}
        setDialog={setOpenDeleteDialog}
        data={['d']}
        handleDelete={() => {
          history.push(routing.PLANS);
          if(plan?.id)actions.deletePlan(plan.id);
          setOpenDeleteDialog(null);
        }}
      />
      <ConfirmDialog
        title={<FormattedMessage id="plans.confirmoptimizedialog.title" />}
        description={<FormattedMessage id="plans.confirmoptimizedialog.content" />}
        open={Boolean(openOptimizeDialog)}
        setDialog={setOpenOptimizeDialog}
        handleConfirm={() => {
          if(anyServiceHasAnyWarnings(plan)) {
            setOpenOptimizeDialog(false);
            setOpenWarningsDialog(true);
          } else {
            handleOptimizePlan();
            setOpenOptimizeDialog(false);
          }
        }}
      />
      <ConfirmDialog
        title={<FormattedMessage id="plans.optimizationwarningsdialog.title" />}
        description={<FormattedMessage id="plans.optimizationwarningsdialog.content" />}
        open={Boolean(openWarningsDialog)}
        setDialog={setOpenWarningsDialog}
        handleConfirm={() => {
          handleOptimizePlan();
          setOpenWarningsDialog(false);
        }}
      />
      <ConstraintsDialog
        typeData="optimizeroptions"
        projectData={{
          id: activeProject.id,
          label: `${projectHelpers.getProjectLabel(activeProject.label, organization.label)}`,
        }}
        open={Boolean(optionsDialog)}
        data={plan && plan.optimizer_config ? plan.optimizer_config : {}}
        constraintsMap={[
          ...(userHelpers.currentUser.isAdmin() || asUser
            ? PROJECT_OPTIMIZER_CONIFG_SM_ROOT_MAP
            : PROJECT_OPTIMIZER_CONIFG_MAP),
        ]}
        setDialog={setOptionsDialog}
        handleSetConstraints={handleChangeOptions}
      />
    </div>
  );
};

PlanPage.defaultProps = {
  asUser: undefined,
  tutorial: undefined,
  userLocation: undefined,
};

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    getPlan: (planId: any) => dispatch(ActivePlan.getPlan(planId)),
    updatePlan: (planId: any, plan: any) => dispatch(ActivePlan.updatePlan(planId, plan)),
    optimizePlan: (planId: any, large: any) => dispatch(ActivePlan.optimizePlan(planId, large)),
    cleanUpPlan: () => dispatch(ActivePlan.cleanPlan()),
    cleanUpDetailView: () => dispatch(ActivePlan.setDetailView('service', undefined)),
    deletePlan: (planId: any) => dispatch(Plans.deletePlan(planId)),
    tutorialSetStep: (tutorialId: any, step: any) => dispatch(Tutorial.setStep(tutorialId, step)),
    changeOptions: (planId: any, options: any) =>
      dispatch(ActivePlan.changeOptions(planId, options)),
  },
});

const mapStateToProps = (state: any) => {
  return {
    optimizingPlan: state.activePlan.optimizing,
    plan: state.activePlan.plan,
    user: state.user.user,
    activeProject: state.projects.activeProject ? state.projects.activeProject : {},
    tutorial: state.tutorial,
    asUser: state.user.asUser,
    organization: state.organization.organization,
    userLocation: state.user.userLocation,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PlanPage));
