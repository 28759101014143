import { Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Button, InputText, VSpacer } from '@sm-highway-web/react-components';
import lodash from 'lodash';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import CheckmarkIcon from '../../../assets/svg/CheckmarkIcon';
import { IOrganizationData } from '../../../interfaces/organizations';
import { Organization } from '../../../redux/actions';
import { userHelpers } from '../../../redux/helpers';
import { getUploadAsBase64 } from '../../../services/requests';
import AvatarManager from '../../AvatarManager';
import FormattedMessage from '../../FormattedMessageCustom';
import {
  ContainerInputs,
} from '../SettingsStyled';

const styles = {};

type OrganizationProfileProps = {
  classes: any;
  actions: {
    organizationEdit: (...args: any[]) => any;
    uploadAvatar: (...args: any[]) => any;
    removeAvatar: (...args: any[]) => any;
  };
  organization?: IOrganizationData;
  isDirtyState: (...args: any[]) => any;
};

const OrganizationProfile = ({
  classes,
  organization,
  isDirtyState,
  actions,
}: OrganizationProfileProps) => {

  const [isLoadingImage, setIsLoadingImage] = useState(false);
  const [isUploadingImage, setIsUploadingImage] = useState(false);

  const [label, setLabel] = useState('');
  const [avatarImage, setAvatarImage] = useState<string | null>('');
  const [success, setSuccess] = useState(false);
  const [dirtyState, setDirtyState] = useState(false);

  useEffect(() => {
    if (organization) {
      setLabel(organization.label);

      (async () => {
        if (organization.avatar_url) {
          setIsLoadingImage(true);
          const avatar = await getUploadAsBase64(organization.avatar_url);
          setAvatarImage(avatar);
          setIsLoadingImage(false);
        }
      })();
    }
  }, [organization]);

  useEffect(() => {
    if (organization) {
      if (!lodash.isEqual(label, organization.label)) {
        setDirtyState(true);
      } else {
        setDirtyState(false);
      }
    }
  }, [organization, label]);

  useEffect(() => {
    isDirtyState(dirtyState);
  }, [dirtyState, isDirtyState]);

  const handleEditOrganization = () => {
    actions.organizationEdit({ label });
    setSuccess(true);
  };

  if (!organization) return null;
  
  return (
    <Grid container direction="column">
      <Grid item>
        <Typography variant="h2">
          <FormattedMessage id="settings.organization.profile.title" />
        </Typography>
      </Grid>
      <VSpacer />

      <Grid item>
        <Typography variant="h4">
          <FormattedMessage id="settings.organization.profile.profile_picture" />
        </Typography>
      </Grid>

      <VSpacer medium />

      <Grid item>
        <AvatarManager
          avatarUrl={organization.avatar_url}
          onRemoveAvatar={() => actions.removeAvatar()}
          onAvatarUpload={(fileName: string, imageType: string, image: Blob) => actions.uploadAvatar(fileName, imageType, image)}
        />
      </Grid>

      <VSpacer medium />
      <VSpacer medium />

      <Grid item>
        <ContainerInputs>
          {userHelpers.currentUser.isOrganizationAdmin() ? (
            <InputText
              id="label"
              label={<FormattedMessage id="settings.organization.profile.name" />}
              value={label}
              onChange={(value: any) => setLabel(value)}
              margin="none"
              validators={[]}
            />
          ) : (
            <Grid item>
              <span style={{ fontSize: '12px' }}>
                <FormattedMessage id="settings.organization.profile.name" />
              </span>
              <VSpacer medium />
              <Typography
                style={{
                  fontSize: '16px',
                  borderBottom: '1px solid rgba(0, 0, 0, 0.87)',
                  paddingBottom: '8px',
                }}
              >
                {label}
              </Typography>
            </Grid>
          )}
        </ContainerInputs>
      </Grid>
      <VSpacer medium />

      {userHelpers.currentUser.isOrganizationAdmin() && (
        <Grid item>
          <Button
            variant={dirtyState || success ? 'contained' : 'outlined'}
            onClick={handleEditOrganization}
          >
            {success ? (
              <>
                <CheckmarkIcon />
                <FormattedMessage id="settings.buttonupdated" />
              </>
            ) : (
              <FormattedMessage id="settings.buttonupdate" />
            )}
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

OrganizationProfile.defaultProps = {
  organization: undefined,
};

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    organizationEdit: (projectData: any) => dispatch(Organization.editOrganization(projectData)),
    uploadAvatar: (fileName: any, mimeType: any, file: any) =>
      dispatch(Organization.uploadAvatar(fileName, mimeType, file)),
    removeAvatar: () => dispatch(Organization.removeAvatar()),
  },
});

const mapStateToProps = (state: any) => {
  return { organization: state.organization.organization };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(OrganizationProfile));
