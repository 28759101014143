import { Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Button, HSpacer, Pill, VSpacer } from '@sm-highway-web/react-components';
import { IProjectData } from 'highway-api/dist/common/interfaces/projects';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import * as routing from '../../../constants/Routing';
import history from '../../../helpers/History';
import { IOrganizationUsers } from '../../../interfaces/organizations';
import { Projects } from '../../../redux/actions';
import { organizationHelpers, projectHelpers, userHelpers } from '../../../redux/helpers';
import Avatar from '../../Avatar';
import CustomSelect from '../../CustomSelect';
import FormattedMessage from '../../FormattedMessageCustom';
import OrganizationNewUserDialog from '../organization/OrganizationNewUserDialog';
import OrganizationProjectAddUserDialog from './OrganizationProjectAddUserDialog';

const styles = {
  listUsers: {
    flexWrap: 'unset',
  },
  listUsersItem: {
    marginBottom: '20px',
    marginRight: '8px',
  },
};

type OrganizationProjectsEditUsersProps = {
  classes: {
    listUsers: string;
    listUsersItem: string;
  };
  actions: {
    deleteProjectUser: (...args: any[]) => any;
    editProjectUser: (...args: any[]) => any;
  };
  organizationUsers: IOrganizationUsers[];
  project: IProjectData;
  subscriptionPlanType: string;
};

const OrganizationProjectsEditMembers = ({
  classes,
  organizationUsers,
  actions,
  project,
  subscriptionPlanType,
}: OrganizationProjectsEditUsersProps) => {
  const [newUserDialogOpen, setNewUserDialogOpen] = useState(false);
  const [addUserDialogOpen, setAddUserDialogOpen] = useState(false);

  if (!project || !project.users) {
    return null;
  }

  return (
    <>
      <Grid container direction="column">
        <Grid item container style={{ padding: '8px', borderBottom: '1px solid #D7DAE2' }}>
          <Grid
            item
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            wrap="nowrap"
          >
            <Grid item container wrap="nowrap">
              <Typography variant="h2">
                <FormattedMessage
                  tagName="span"
                  id="settings.organization.projects.edit.members.title"
                />
                {` (${project.users.length})`}
              </Typography>
            </Grid>

            {subscriptionPlanType !== 'monkey' &&
              (userHelpers.currentUser.isOrganizationAdmin() ||
                userHelpers.currentUser.isOrganizationManager() ||
                projectHelpers.currentUser.isManagerInProject(project)) && (
              <Grid item container wrap="nowrap" justify="flex-end">
                <Grid item>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => setNewUserDialogOpen(true)}
                  >
                    <FormattedMessage id="settings.organization.projects.edit.members.buttons.create" />
                  </Button>
                </Grid>

                <HSpacer small />

                <Grid item>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => setAddUserDialogOpen(true)}
                  >
                    <FormattedMessage id="settings.organization.projects.edit.members.buttons.add" />
                  </Button>
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>

        {subscriptionPlanType !== 'monkey' &&
          (userHelpers.currentUser.isOrganizationAdmin() ||
            userHelpers.currentUser.isOrganizationManager() ||
            projectHelpers.currentUser.isManagerInProject(project)) ? (
              <>
                <VSpacer large />

                <Grid item>
                  <FormattedMessage
                    tagName="span"
                    id="settings.organization.projects.edit.members.info"
                    values={{
                      a: (chunks: any) => (
                        <span
                          style={{
                            cursor: 'pointer',
                            color: '#FF8200',
                          }}
                          onClick={() => history.push(`${routing.SETTINGS.ORGANIZATION.USERS}`)}
                        >
                          {chunks}
                        </span>
                      ),
                    }}
                  />
                </Grid>
              </>
            ) : null}

        <VSpacer large />

        <Grid item>
          <Grid container direction="column" className={classes.listUsers}>
            {projectHelpers.orderProjectUsers(project.users).map((user: any) => (
              <Grid item key={user.user_id} className={classes.listUsersItem}>
                <Pill
                  avatar={<Avatar data={user} labelOverlayed={false} size={50} />}
                  info={
                    <Grid container direction="column">
                      <Grid item>
                        <Typography variant="h3" style={{ fontSize: '22px' }}>
                          {`${user.first_name} ${user.last_name}`}
                        </Typography>
                      </Grid>

                      <Grid item>
                        <Typography variant="h5" style={{ fontSize: '16px' }}>
                          {user.email}
                        </Typography>
                      </Grid>
                    </Grid>
                  }
                  actions={[
                    <CustomSelect
                      width={250}
                      value={user.role}
                      disabled={
                        subscriptionPlanType === 'monkey' ||
                        (!userHelpers.currentUser.isOrganizationAdmin() &&
                          !userHelpers.currentUser.isOrganizationManager() &&
                          !projectHelpers.currentUser.isManagerInProject(project))
                      }
                      selectItems={[
                        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ value: string; title: JSX.Element; info: J... Remove this comment to see the full error message
                        {
                          value: 'project_manager',
                          title: (
                            <FormattedMessage id="settings.organization.projects.edit.roles.manager" />
                          ),
                          info: (
                            <FormattedMessage id="settings.organization.projects.edit.roles.manager.description" />
                          ),
                        },
                        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ value: string; title: JSX.Element; info: J... Remove this comment to see the full error message
                        {
                          value: 'project_user',
                          title: (
                            <FormattedMessage id="settings.organization.projects.edit.roles.user" />
                          ),
                          info: (
                            <FormattedMessage id="settings.organization.projects.edit.roles.user.description" />
                          ),
                        },
                      ]}
                      onChange={(value: any) => {
                        actions.editProjectUser(project.id, user.user_id, value);
                      }}
                    />,

                    ...(subscriptionPlanType !== 'monkey' &&
                    (userHelpers.currentUser.isOrganizationAdmin() ||
                      userHelpers.currentUser.isOrganizationManager() ||
                      projectHelpers.currentUser.isManagerInProject(project))
                      ? [
                          <Button
                            style={{
                              height: '40px',
                              boxShadow: 'unset',
                              backgroundColor: '#FF2825',
                            }}
                            color="secondary"
                            variant="contained"
                            onClick={() => actions.deleteProjectUser(project.id, user.user_id)}
                          >
                            <FormattedMessage id="settings.organization.projects.edit.members.buttons.delete" />
                          </Button>,
                        ]
                      : []),
                  ]}
                />
              </Grid>
            ))}
          </Grid>
        </Grid>
        <VSpacer medium />
      </Grid>

      <OrganizationNewUserDialog
        isOpen={newUserDialogOpen}
        selectedProjectId={project.id}
        onClose={() => setNewUserDialogOpen(false)}
      />

      <OrganizationProjectAddUserDialog
        selectedProjectId={project.id}
        availableUsers={organizationUsers.filter(
          (user: any) =>
            project.users && !project.users.find((_user: any) => _user.user_id === user.user_id)
        )}
        isOpen={addUserDialogOpen}
        onClose={() => setAddUserDialogOpen(false)}
      />
    </>
  );
};

OrganizationProjectsEditMembers.defaultProps = {};

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    deleteProjectUser: (projectId: any, userId: any) =>
      dispatch(Projects.deleteProjectUser(projectId, userId)),
    editProjectUser: (projectId: any, userId: any, role: any) =>
      dispatch(Projects.editProjectUser(projectId, userId, { role })),
  },
});

const mapStateToProps = (state: any) => {
  return {
    organizationUsers: organizationHelpers.orderUsers(state.organization.users),
    subscriptionPlanType:
      state.organization.organization &&
      state.organization.organization.subscription &&
      state.organization.organization.subscription.plan &&
      state.organization.organization.subscription.plan.type
        ? state.organization.organization.subscription.plan.type
        : 'monkey',
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ listUsers: { flexWrap: string;... Remove this comment to see the full error message
)(withStyles(styles)(OrganizationProjectsEditMembers));
