import {
  ClickAwayListener,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  Input,
  MenuItem,
  Popover,
  Switch,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { PopoverOrigin } from '@material-ui/core/Popover';
import { Button, Checkbox, DefaultAvatar, HSpacer, InputText, VSpacer } from '@sm-highway-web/react-components';
import { IProjectCustomField, IProjectData, IServiceTrackingEmail, IServiceTrackingEmailItem, IServiceTrackingEmailTypes } from 'highway-api/dist/common/interfaces/projects';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as routing from '../../../../constants/Routing';
import history from '../../../../helpers/History';
import { intl } from '../../../../helpers/IntGlobalProvider';
import { ObjectOfAny } from '../../../../interfaces/common';
import { Projects } from '../../../../redux/actions';
import configuration from '../../../../services/configuration';
import Avatar from '../../../Avatar';
import FormattedMessage from '../../../FormattedMessageCustom';
import {
  CommunicationEmailBody,
  CommunicationEmailBodyFooter,
  CommunicationEmailContainer,
  CommunicationEmailContainerBody,
  CommunicationEmailContainerFooter,
  CommunicationEmailContainerHeader,
  EmailAvatarStyled,
  SubstitutionPill,
  SubstitutionsContainer,
} from './ProjectCommunication.styled';

export const podPopoverConfig = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
};

type EditPodComponentProps = {
  onUpdate: (...args: any[]) => any;
  emailTemplate: IServiceTrakingEmailState;
  availablePod: IPodField[]
};

const EditPodComponent = ({
  onUpdate,
  emailTemplate,
  availablePod,
}: EditPodComponentProps) => {

  const [podAnchorEl, setPodAnchorEl] = useState<any>();

  return (
    <Grid item>
      <ClickAwayListener onClickAway={() => setPodAnchorEl(undefined)}>
        <Button
          disabled={availablePod.length === 0}
          aria-describedby={podAnchorEl !== null ? 'simple-popover' : undefined}
          color="secondary"
          variant="contained"
          style={{
            width: '160px',
            marginBottom: '8px',
          }}
          onClick={(e: any) => setPodAnchorEl(e.currentTarget)}
        >
          <FormattedMessage tagName="span" id="settings.organization.projects.edit.communication.emails.edit_report" />
        </Button>
      </ClickAwayListener>              

      <Popover
        id={podAnchorEl !== null ? 'simple-popover' : undefined}
        open={Boolean(podAnchorEl)}
        anchorEl={podAnchorEl}
        onClose={() => setPodAnchorEl(undefined)}
        anchorOrigin={podPopoverConfig.anchorOrigin as PopoverOrigin}
        transformOrigin={podPopoverConfig.transformOrigin as PopoverOrigin}
      >
        {availablePod.map((field: IPodField, index: number) => (
          <MenuItem
            key={`available-pod-option-${index}`}
            value={field.id}
            onClick={(e: any) => {
              e.preventDefault();
              let podFields = emailTemplate.pod_fields ?? [];
              if (podFields.indexOf(field.id) > -1) {
                podFields = podFields.filter(pod => pod !== field.id);
              } else {
                podFields.push(field.id);
              }
              onUpdate({
                ...emailTemplate,
                pod_fields: podFields,
              } as IServiceTrakingEmailState);
            }}
          >
            <Checkbox
              checked={(emailTemplate.pod_fields ?? []).indexOf(field.id) > -1}
              style={{
                marginRight: '4px',
              }}
            />
            <span>{field.label}</span>
          </MenuItem>
        ))}
      </Popover>
    </Grid>
  );
};

const CUSTOM_FIELD_PREFFIX = `custom_fields.`;

const SUBSTITUTION_CHARACTER_START = '{{';
const SUBSTITUTION_CHARACTER_END = '}}';

const customerEmailSubstitutions = [
  'label',
  'external_id',
  'location_details',
  'comments',
  'email',
  'reference_person',
  'phone',
  'webpage',
];

const htmlToString = (text?: string | null) => {
  return text ? text.replace(/<br\/>/g, '\n') : undefined;
};

const stringToHtml = (text?: string | null) => {
  return text ? text.replace(/\n/g, '<br/>') : undefined;
};

interface IServiceTrakingEmailState extends IServiceTrackingEmailItem {
  textAreaCursorPosition: number;
  inputRef: React.RefObject<HTMLInputElement>;
}

interface TestItemObject extends IServiceTrackingEmailItem {
  email?: string;
}

type IPodField = {
  id: string;
  label: string;
};

type ProjectCommunicationEmailsProps = {
  actions: {
    editCommunication: (...args: any[]) => any;
  };
  emailType: IServiceTrackingEmailTypes;
  userEmail?: string;
  project: IProjectData;
};

const ProjectCommunicationEmailsTemplate = ({
  actions,
  emailType,
  userEmail,
  project,
}: ProjectCommunicationEmailsProps) => {
  const [isSendTestDialogOpen, setIsSendTestDialogOpen] = useState<boolean>(false);
  const [isEnableEmailDialogOpen, setIsEnableEmailDialogOpen] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<TestItemObject | undefined>(undefined);
  const [emailTemplate, setEmailTemplate] = useState<IServiceTrakingEmailState | undefined>();
  const [availablePod, setAvailablePod] = useState<IPodField[] | undefined>();
  const [podAnchorEl, setPodAnchorEl] = useState<any>();

  useEffect(() => {
    
    // Only showing the pickup emails if pickups are available in priject view constraints.
    if (project.service_tracking_email) {
      const emailData = (project.service_tracking_email as IServiceTrackingEmail)[emailType];
      if(emailData) {
        const body = htmlToString(emailData.body);
        setEmailTemplate(
          {
            ...emailData,
            ...(body ? { body } : {}),
            inputRef: React.createRef(),
            textAreaCursorPosition: emailData.body ? emailData.body.length : 0,
          } as IServiceTrakingEmailState
        );

        let completedPodFelds : IPodField[];

        switch(emailType) {
          case 'service_completed':
          case 'pickup_completed':
            completedPodFelds = [
              ...(project.view?.service_report_completed.pictures ? [{
                id: 'pictures',
                label: intl.formatMessage({ id: 'settings.organization.projects.edit.fields.service_report_completed.pictures' }),
              }]:[]),
              ...(project.view?.service_report_completed.comments ? [{
                id: 'comments',
                label: intl.formatMessage({ id: 'settings.organization.projects.edit.fields.service_report_completed.comments' }),
              }]:[]),
              ...(project.view?.service_report_completed.signature ? [{
                id: 'signature',
                label: intl.formatMessage({ id: 'settings.organization.projects.edit.fields.service_report_completed.signature' }),
              }]:[]),
            ];
            setAvailablePod([
              ...completedPodFelds,
              ...(project.custom_fields?.service_report_completed ?? [].map((field: IProjectCustomField) => ({ id: field.id, label: field.label }) ))]);
            break;
          case 'service_canceled':
          case 'pickup_canceled':
            completedPodFelds = [
              ...(project.view?.service_report_canceled.pictures ? [{
                id: 'pictures',
                label: intl.formatMessage({ id: 'settings.organization.projects.edit.fields.service_report_canceled.pictures' }),
              }]:[]),
              ...(project.view?.service_report_canceled.comments ? [{
                id: 'comments',
                label: intl.formatMessage({ id: 'settings.organization.projects.edit.fields.service_report_canceled.comments' }),
              }]:[]),
              ...(project.view?.service_report_canceled.canceled_categorical ? [{
                id: 'canceled_categorical',
                label: intl.formatMessage({ id: 'settings.organization.projects.edit.fields.service_report_canceled.canceled_categorical' }),
              }]:[]),
            ];
            setAvailablePod([
              ...completedPodFelds,
              ...(project.custom_fields?.service_report_canceled ?? [].map((field: IProjectCustomField) => ({ id: field.id, label: field.label }) ))]);
            break;
          default:
            setAvailablePod(undefined);
            break;
        }
      }
    }
  }, [emailType, project]);

  const updateEmailItem = async (itemToUpdate: IServiceTrakingEmailState) => {
    const body = stringToHtml(itemToUpdate.body);
    await actions.editCommunication(project.id, {
      service_tracking_email: {
        [emailType]: {
          ...(body ? { body } : {}),
          from: itemToUpdate.from,
          subject: itemToUpdate.subject,
          enabled: itemToUpdate.enabled,
          pod_fields: itemToUpdate.pod_fields ?? [],
        },
      },
    });
  };

  const sendTestItem = async () => {
    if (selectedItem) {
      const body = stringToHtml(selectedItem.body);
      await Projects.sendTestCommunication(project.id, {
        type: emailType,
        email: selectedItem.email,
        from: selectedItem.from,
        subject: selectedItem.subject,
        ...(body ? { body } : {}),
      }, 'email');
    }
    setSelectedItem(undefined);
    setIsSendTestDialogOpen(false);
  };

  const isSaveButtonDisabled = (serviceTrakingEmailType: any) => {
    const email = (project.service_tracking_email as ObjectOfAny)[emailType];

    const emailBody = htmlToString(email.body);
    const serviceTrakingEmailTypeBody = htmlToString(serviceTrakingEmailType.body);

    return (
      emailBody === serviceTrakingEmailTypeBody &&
      email.from === serviceTrakingEmailType.from &&
      email.subject === serviceTrakingEmailType.subject
    );
  };
  
  if (!emailTemplate) return null;

  return (
    <>
      <CommunicationEmailContainer>
        <CommunicationEmailContainerHeader>
          <Grid container>
            <Button
              color="primary"
              variant="contained"
              disabled={isSaveButtonDisabled(emailTemplate)}
              onClick={() => {
                updateEmailItem(emailTemplate);
              }}
            >
              <FormattedMessage id="save" />
            </Button>

            <HSpacer small />

            <Button
              color="secondary"
              variant="contained"
              onClick={() => {
                const object = {
                  ...emailTemplate,
                  email: userEmail,
                };
                setSelectedItem(object);
                setIsSendTestDialogOpen(true);
              }}
            >
              <FormattedMessage id="settings.organization.projects.edit.communication.emails.send_test.button" />
            </Button>
          </Grid>

          <Switch
            checked={emailTemplate.enabled}
            onChange={(event) => {
              const { checked } = event.target;
              if (checked) {
                const object = {
                  ...emailTemplate,
                  enabled: checked,
                };
                setSelectedItem(object);
                setIsEnableEmailDialogOpen(true);
              } else {
                updateEmailItem({
                  ...emailTemplate,
                  enabled: checked,
                });
              }
            }}
            color="primary"
          />
        </CommunicationEmailContainerHeader>

        <CommunicationEmailContainerBody>
          <Grid container direction="column" style={{ backgroundColor: 'white' }}>
            <Grid item container alignItems="center" style={{ padding: '8px' }}>
              <Grid 
                item
                container
                alignItems="center"
                justify="center"
                style={{
                  width: '80px',
                  height: '32px',
                  color: '#FF8200',
                  padding: '0 8px',
                  backgroundColor: '#ffe6cc',
                }}
              >
                <FormattedMessage tagName="span" id="settings.organization.projects.edit.communication.emails.from" />
              </Grid>

              <HSpacer small />

              <Grid item style={{ flex: 1 }}>
                <Input
                  value={emailTemplate.from ?? ''}
                  placeholder={intl.formatMessage({ id: 'settings.organization.projects.edit.communication.emails.from' })}
                  onChange={(evt: any) => {
                    setEmailTemplate({
                      ...emailTemplate,
                      from: evt.target.value !== '' ? evt.target.value : null,
                    });
                  }}
                  fullWidth
                  disableUnderline
                />
              </Grid>

              <Grid item>
                <span
                  style={{
                    color: '#CECDCD',
                  }}
                >
                  {`<no-reply@smartmonkey.io>`}
                </span>
              </Grid>
            </Grid>

            <Divider />

            <Grid item container alignItems="center" style={{ padding: '8px' }}>
              <Grid 
                item
                container
                alignItems="center"
                justify="center"
                style={{
                  width: '80px',
                  height: '32px',
                  color: '#FF8200',
                  padding: '0 8px',
                  backgroundColor: '#ffe6cc',
                }}
              >
                <FormattedMessage tagName="span" id="settings.organization.projects.edit.communication.emails.subject" />
              </Grid>

              <HSpacer small />

              <Grid item style={{ flex: 1 }}>
                <Input
                  value={emailTemplate.subject ?? ''}
                  placeholder={intl.formatMessage({ id: 'settings.organization.projects.edit.communication.emails.subject' })}
                  onChange={(evt: any) => {
                    setEmailTemplate({
                      ...emailTemplate,
                      subject: evt.target.value !== '' ? evt.target.value : null,
                    });
                  }}
                  fullWidth
                  disableUnderline
                />
              </Grid>
            </Grid>
          </Grid>

          {!emailTemplate.enabled ? (
            <Grid container style={{ backgroundColor: '#FFB2B2', height: '45px' }} justify="center" alignItems="center">
              <span style={{ fontSize: '14px', color: '#e03636' }}><FormattedMessage id="settings.organization.projects.edit.communication.emails.disabled_warning" /></span>
            </Grid>
          ): null}

          <Grid container justify="center">

            <CommunicationEmailBody>
              <Grid item container direction="row" alignItems="center" justify="space-between" wrap="nowrap">
                <Grid item container direction="column" alignItems="center">

                  <VSpacer medium />

                  <Tooltip
                    placement="top"
                    title={
                      <FormattedMessage id="settings.organization.projects.edit.communication.emails.change_image_tooltip" />
                    }
                  >
                    <Grid
                      item
                      style={{
                        cursor: 'pointer',
                        marginBottom: '8px',
                      }}
                      onClick={() => {
                        history.push(
                          `${routing.SETTINGS.ORGANIZATION.PROJECTS.ROOT}/${project.id}/${routing.ProjectEditViews.general}`
                        );
                      }}
                    >
                      <EmailAvatarStyled>
                        {project.avatar_url ?
                            <Avatar
                              data={project}
                              labelOverlayed
                              size={84}
                            />
                          :
                            <DefaultAvatar
                              labelOverlayed={false}
                              // icon={AvatarIcon}
                              size={84}
                            />
                        }
                      </EmailAvatarStyled>
                    </Grid>
                  </Tooltip>
                </Grid>
              </Grid>

              <Grid container direction="column" style={{ position: 'relative', padding: '0 20px' }}>
                <VSpacer medium />

                <Grid item container justify="center">
                  <span
                    style={{
                      color: '#050D1E',
                      fontSize: '34px',
                      fontWeight: 'bold',
                      textAlign: 'center',
                    }}
                  >
                    {emailTemplate.subject ??
                      <FormattedMessage
                        tagName="span"
                        id={`settings.organization.projects.edit.communication.emails.${emailType}`}
                      />
                    }
                  </span>
                </Grid>
                
                <VSpacer large />

                <Grid
                  item
                  style={{
                    position: 'relative',
                  }}
                >
                  <TextField
                    multiline
                    value={emailTemplate.body ?? ''}
                    variant="outlined"
                    style={{
                      fontFamily: 'Work Sans',
                      fontSize: '16px',
                      outline: 'unset',
                      width: '100%',
                      zIndex: 2,
                    }}
                    inputRef={emailTemplate.inputRef}
                    onSelect={() => {
                      setEmailTemplate({
                        ...emailTemplate,
                        textAreaCursorPosition: emailTemplate.inputRef.current?.selectionStart,
                      } as IServiceTrakingEmailState);
                    }}
                    rows={4}
                    rowsMax={5}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    aria-label="Communication email body"
                    onChange={(evt) => {
                      setEmailTemplate({
                        ...emailTemplate,
                        body: evt.target.value !== '' ? evt.target.value : undefined,
                      });
                    }}
                  />
                  {(!emailTemplate.body || emailTemplate.body === '') &&
                    <div style={{
                      zIndex: 1,
                      position: 'absolute',
                      top: 0,
                      lineHeight: '19px',
                      color: '#a6a6a6',
                      paddingBottom: '18.5px',
                      paddingLeft: '14px',
                      paddingRight: '14px',
                      paddingTop: '18.5px',
                    }}
                    >
                      <FormattedMessage
                        tagName="span"
                        id={`settings.organization.projects.edit.communication.emails.${emailType}.placeholder`}
                      />
                    </div>
                  }
                </Grid>

                {(emailType === 'route_started' || emailType === 'service_approaching' || emailType === 'pickup_approaching') ? (
                  <>
                    <VSpacer large />
                    <Grid item container justify="center">
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() => {
                          const publicServiceLink = `${configuration.HIGHWAY_CUSTOMERS_ENDPOINT}/public/service/example`;
                          window.open(`${publicServiceLink}`, '_blank');
                        }}
                      >
                        <FormattedMessage tagName="span" id="settings.organization.projects.edit.communication.emails.follow_route_button" />
                      </Button>
                    </Grid>
                  </>
                )
                  :
                  null
                }
              </Grid>
              <CommunicationEmailBodyFooter />
            </CommunicationEmailBody>
          </Grid>
        </CommunicationEmailContainerBody>

        <CommunicationEmailContainerFooter>
          <SubstitutionsContainer>
            {customerEmailSubstitutions.map((substitution: string, index: number) => (
              <SubstitutionPill
                key={`substitution-pill-${index}`}
                text={
                  <FormattedMessage
                    tagName="span"
                    id={`settings.organization.projects.edit.communication.emails.substitutions.${substitution}`}
                  />
                }
                onSelect={() => {
                  const selectionStart = emailTemplate.textAreaCursorPosition;
                  const currentBody = `${emailTemplate.body ?? ''}`;
                  const body = `${currentBody.slice(0, selectionStart)}${SUBSTITUTION_CHARACTER_START}${substitution}${SUBSTITUTION_CHARACTER_END}${currentBody.slice(selectionStart)}`;
                  setEmailTemplate({
                    ...emailTemplate,
                    body,
                  });
                }}
              />
            ))}
            {project?.custom_fields?.client ? (
              project.custom_fields.client.map((customField: IProjectCustomField, index: number) => (
                <SubstitutionPill
                  key={`substitution-pill-custom-fields-${index}`}
                  text={
                    customField.label
                  }
                  onSelect={() => {
                    const selectionStart = emailTemplate.textAreaCursorPosition;
                    const currentBody = `${emailTemplate.body ?? ''}`;
                    const body = `${currentBody.slice(0, selectionStart)}${SUBSTITUTION_CHARACTER_START}${CUSTOM_FIELD_PREFFIX}${customField.id}${SUBSTITUTION_CHARACTER_END}${currentBody.slice(selectionStart)}`;
                    setEmailTemplate({
                      ...emailTemplate,
                      body,
                    });
                  }}
                />
              ))
            )
              : null}
          </SubstitutionsContainer>

          {availablePod && availablePod.length > 0 ? (
            <EditPodComponent availablePod={availablePod} emailTemplate={emailTemplate} onUpdate={updateEmailItem} />
          ) : null}

          {availablePod && availablePod.length === 0 ? (
            <Tooltip
              placement="top"
              title={
                <FormattedMessage tagName="span" id="settings.organization.projects.edit.communication.emails.edit_report.disabled" />
              }
            >
              <EditPodComponent availablePod={availablePod} emailTemplate={emailTemplate} onUpdate={updateEmailItem} />
            </Tooltip>
          ) : null}
        </CommunicationEmailContainerFooter>
      </CommunicationEmailContainer>

      <VSpacer large />

      <Dialog
        fullWidth
        maxWidth="sm"
        open={!!isEnableEmailDialogOpen}
        onClose={() => {
          setIsEnableEmailDialogOpen(false);
          setSelectedItem(undefined);
        }}
      >
        <DialogTitle>
          <FormattedMessage id="settings.organization.projects.edit.communication.emails.enable.title" />
        </DialogTitle>
        <DialogContent>
          <Grid container direction="column">
            <Typography>
              <FormattedMessage id="settings.organization.projects.edit.communication.emails.enable.description" />
            </Typography>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            onClick={() => {
              setIsEnableEmailDialogOpen(false);
              setSelectedItem(undefined);
            }}
          >
            <FormattedMessage tagName="span" id="cancel" />
          </Button>
          <Button
            color="primary"
            onClick={async () => {
              await updateEmailItem(selectedItem as IServiceTrakingEmailState);
              setSelectedItem(undefined);
              setIsEnableEmailDialogOpen(false);
            }}
          >
            <FormattedMessage tagName="span" id="confirm" />
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth
        maxWidth="sm"
        open={!!isSendTestDialogOpen}
        onClose={() => {
          setIsSendTestDialogOpen(false);
          setSelectedItem(undefined);
        }}
      >
        <DialogTitle>
          <FormattedMessage id="settings.organization.projects.edit.communication.emails.send_test.title" />
        </DialogTitle>
        <DialogContent>
          <Grid container direction="column">

            <Typography>
              <FormattedMessage id="settings.organization.projects.edit.communication.emails.send_test.description" />
            </Typography>

            <VSpacer large />

            <InputText
              id="email"
              type="email"
              label={<FormattedMessage id="login.email" />}
              value={selectedItem?.email}
              onChange={(value: any) => {
                const object = {
                  ...selectedItem,
                  email: value,
                };
                setSelectedItem(object as TestItemObject);
              }}
              validators={['required', 'email']}
              errorMessages={[
                <FormattedMessage
                  key="settings.error.invalid_email"
                  id="settings.error.invalid_email"
                />,
              ]}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            onClick={() => {
              setIsSendTestDialogOpen(false);
              setSelectedItem(undefined);
            }}
          >
            <FormattedMessage tagName="span" id="cancel" />
          </Button>
          <Button
            disabled={!selectedItem?.email || selectedItem.email === ''}
            onClick={async () => sendTestItem()}
            color="primary"
          >
            <FormattedMessage tagName="span" id="send" />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

ProjectCommunicationEmailsTemplate.defaultProps = {};

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    editCommunication: (projectId: string, communicationData: any) =>
      dispatch(Projects.editCommunication(projectId, communicationData)),
  },
});

export default connect(
  null,
  mapDispatchToProps
)(ProjectCommunicationEmailsTemplate);
