import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import React from 'react';
import { Button } from '@sm-highway-web/react-components';
import FormattedMessage from '../components/FormattedMessageCustom';

type OwnProps = {
  open: boolean;
  setDialog: (...args: any[]) => any;
  data?: {} | {}[] | string[];
  handleDelete: (...args: any[]) => any;
  typeData: string;
};

type Props = OwnProps;

const ConfirmDeleteDialog = ({ open, setDialog, data, handleDelete, typeData }: Props) => (
  <Dialog open={open} onClose={() => setDialog(undefined)}>
    <DialogTitle>
      <FormattedMessage id={`${typeData}.deletedialog.title`} />
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">
        <FormattedMessage
          id={`${typeData}.deletedialog.content`}
          values={{ data: Array.isArray(data) ? data.length : '1' }}
        />
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={() => setDialog(undefined)} color="primary">
        <FormattedMessage id={`${typeData}.deletedialog.cancel`} />
      </Button>
      <Button
        variant="contained"
        onClick={() => {
          handleDelete();
        }}
        color="primary"
        autoFocus
      >
        <FormattedMessage id={`${typeData}.deletedialog.confirm`} />
      </Button>
    </DialogActions>
  </Dialog>
);

ConfirmDeleteDialog.defaultProps = {
  data: undefined,
};

export default ConfirmDeleteDialog;
