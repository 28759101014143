import React from 'react';
import { FormattedMessage } from 'react-intl';

type OwnProps = {
  id?: string;
  tagName?: any;
  description?: string;
  values?: Record<string, any>;
};

type Props = OwnProps;

const FormattedMessageCustom = ({ id, tagName, description, values }: Props) => {
  const defaultValues = {
    br: <br />,
    span: (chunks: any) => <span>{chunks}</span>,
    ul: (chunks: any) => <ul>{chunks}</ul>,
    li: (chunks: any) => <li>{chunks}</li>,
    b: (chunks: any) => <strong>{chunks}</strong>,
  };

  return (
    <FormattedMessage
      id={id}
      tagName={tagName}
      description={description}
      values={{
        ...defaultValues,
        ...values,
      }}
    />
  );
};

FormattedMessageCustom.defaultProps = {
  id: '',
  tagName: 'div',
  description: '',
  values: {},
};

export default FormattedMessageCustom;
