import { Grid, Paper, Typography } from '@material-ui/core';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import { VSpacer } from '@sm-highway-web/react-components';
import React, { PureComponent } from 'react';
import { Pie } from 'react-chartjs-2';

const styles = ({ palette }: Theme) => createStyles({
  link: {
    textDecoration: 'none',
    color: palette.primary.main,
    fontWeight: 'bold',
  },
});

type LinkProps = {
  classes: {
    link: string;
  };
  labels: string[];
  data: number[];
  title: string;
};

class Link extends PureComponent<LinkProps> {
  render() {
    const { labels, title, data } = this.props;
    return (
      <Paper>
        <Grid container direction="column">
          <VSpacer medium />
          <Typography variant="h4" align="center">
            {title}
          </Typography>
          <VSpacer medium />
          <Pie
            data={{
              labels,
              datasets: [
                {
                  data,
                  backgroundColor: ['#8991a3', '#ffa74c'],
                  hoverBackgroundColor: ['#57637C', '#FF8200'],
                },
              ],
            }}
          />
        </Grid>
        <VSpacer medium />
      </Paper>
    );
  }
}

export default withStyles(styles)(Link);
