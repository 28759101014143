import createSvgIcon from '@material-ui/icons/utils/createSvgIcon';
import React from 'react';

export const Stepper01 = createSvgIcon(
  <>
    <path d="m23 12c0-6.1-4.9-11-11-11s-11 4.9-11 11 5 11 11 11 11-5 11-11zm-8.7 5.3h-3.1v-7.5c-0.7 0.6-1.5 1.1-2.4 1.3l-0.3-2.6c1.2-0.1 2.7-1 3.2-1.7h2.6v10.5z" />
  </>,
  'Stepper01'
);

export const Stepper02 = createSvgIcon(
  <>
    <path d="m12 1c-6 0-11 4.9-11 11s5 11 11 11 11-5 11-11-4.9-11-11-11zm4.2 16.3h-8.2v-2c3.4-2 4.9-3.7 4.9-5 0-0.7-0.4-1.2-1.1-1.2-0.9 0-1.3 0.7-1.3 2l-2.8-0.8c0-2.5 1.9-3.8 4.3-3.8 3 0 4.1 1.9 4.1 3.4 0 2.1-1.7 3.6-4.3 4.9 0.6 0 1.5-0.1 2.3-0.1h2v2.6z" />
  </>,
  'Stepper02'
);

export const Stepper03 = createSvgIcon(
  <>
    <path d="m23 12c0-6.1-4.9-11-11-11s-11 4.9-11 11 5 11 11 11 11-5 11-11zm-11.2 5.4c-2.4 0-3.8-0.7-4.3-2.7l2.9-1.2c0 1.1 0.6 1.4 1.3 1.4s1.2-0.3 1.2-1c0-0.6-0.4-0.9-1.3-0.9h-0.6v-1.9h0.5c0.8 0 1.2-0.3 1.2-1s-0.4-1-1-1-1.2 0.3-1.2 1.4l-2.8-1c0.3-2.2 2-2.9 4.3-2.9 2.5 0 3.9 1.1 3.9 3s-1.5 2.4-2 2.4c0.5 0 2.2 0.3 2.2 2.4 0 1.9-1.9 3-4.3 3z" />
  </>,
  'Stepper03'
);
