import { Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Button, Dialog, HSpacer, InputText, VSpacer } from '@sm-highway-web/react-components';
import FormattedMessage from '../components/FormattedMessageCustom';
import PaymentPlanSelector from '../components/payment-runway/PaymentPlanSelector';
import { InfoError, Payment } from '../redux/actions';

const styles = {};

type OwnManageSubscriptionDialogProps = {
  classes: any;
  actions: {
    sendStatusToSnackbar: (...args: any[]) => any;
    manageSubscription: (...args: any[]) => any;
  };
  open: boolean;
  handleClose: (...args: any[]) => any;
  currentPlan: {
    type: string;
    vehicles: number;
  };
};

type ManageSubscriptionDialogProps = OwnManageSubscriptionDialogProps;

const ManageSubscriptionDialog = ({
  open,
  handleClose,
  currentPlan,
  actions,
}: ManageSubscriptionDialogProps) => {
  const [confirmation, setConfirmation] = useState(false);
  const [confirmationPassword, setConfirmationPassword] = useState('');
  const [originalPlan, setOriginalPlan] = useState({});

  useEffect(() => {
    if (currentPlan) {
      setOriginalPlan({
        plan: currentPlan.type,
        vehicles: currentPlan.vehicles,
      });
    }
  }, [currentPlan]);

  useEffect(() => {
    return () => {
      cleanVariables();
    };
  }, []);

  const handleChangeConfirmationPass = (value: any) => {
    setConfirmationPassword(value);
  };

  const handleChangeFase = () => {
    setConfirmation(confirmationPassword.toLowerCase() === 'manage');
  };

  const cleanVariables = () => {
    setConfirmation(false);
    setConfirmationPassword('');
  };

  return (
    <Dialog
      modal
      fullScreen={confirmation}
      onClose={() => {
        cleanVariables();
        handleClose();
      }}
      open={open}
      title={
        confirmation ? (
          <FormattedMessage id="settings.billing.subscriptions.managedialog.title.step2" />
        ) : (
          <FormattedMessage id="settings.billing.subscriptions.managedialog.title.step1" />
        )
      }
    >
      {confirmation ? (
        <Grid container justify="center">
          <Grid item lg={5}>
            <Grid container direction="column">
              <PaymentPlanSelector
                data={originalPlan}
                nextButtonIntl="settings.billing.subscriptions.managedialog.buttonok.step2"
                onNextStep={(data: any) => {
                  // @ts-expect-error ts-migrate(2339) FIXME: Property 'plan' does not exist on type '{}'.
                  if (originalPlan.plan !== data.plan || originalPlan.vehicles !== data.vehicles) {
                    actions.manageSubscription(data.plan, data.vehicles);
                    cleanVariables();
                    handleClose();
                  } else {
                    actions.sendStatusToSnackbar(
                      'infoerror.highway.subscription.error.already_have_a_subscription_with_n_vehicles',
                      'error'
                    );
                  }
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      ) : (
        <>
          <Grid container direction="column">
            <Typography align="center">
              <FormattedMessage id="settings.billing.subscriptions.managedialog.subtitle.step1" />
            </Typography>
          </Grid>
          <VSpacer medium />
          <InputText
            id="confirmation"
            label={
              <FormattedMessage id="settings.billing.subscriptions.managedialog.placeholder" />
            }
            value={confirmationPassword}
            onChange={handleChangeConfirmationPass}
            margin="none"
            fullWidth
          />
          <VSpacer />
          <Grid container justify="flex-end">
            <Button color="secondary" variant="text" onClick={handleClose}>
              <FormattedMessage id="settings.billing.subscriptions.managedialog.buttoncancel.step1" />
            </Button>
            <HSpacer small />
            <Button onClick={handleChangeFase}>
              <FormattedMessage id="settings.billing.subscriptions.managedialog.buttonok.step1" />
            </Button>
          </Grid>
          <VSpacer />
        </>
      )}
    </Dialog>
  );
};

ManageSubscriptionDialog.defaultProps = {};

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    sendStatusToSnackbar: (message: any, type: any) =>
      dispatch(InfoError.sendStatusToSnackbar(message, type)),
    manageSubscription: (plan: any, vehicles: any) =>
      dispatch(Payment.manageSubscriptionPlan(plan, vehicles)),
  },
});

export default connect(null, mapDispatchToProps)(withStyles(styles)(ManageSubscriptionDialog));
