import moment from 'moment';

const parseHMtoSeconds = (value: any) => {
  return value
    .split(':')
    .reduce((prev: any, next: any, idx: any) => prev + (idx === 0 ? next * 60 * 60 : next * 60), 0);
};

const dateTodayFormat = () => {
  return moment(new Date()).format('DD/MM/YYYY HH:mm');
};

const dateFormat = (value: any) => {
  return moment(new Date(value)).format('DD/MM/YYYY HH:mm');
};

const dateFormatShortDate = (value: any) => {
  return moment(new Date(value)).format('DD/MM/YY');
};

const dateFormatShortTime = (value: any) => {
  return moment(new Date(value)).format('HH:mm');
};

const dateFormatLongDate = (value: any) => {
  return moment(new Date(value)).format('DD/MM/YYYY');
};

const dateFormatToPicker = (value: any) => {
  return moment(value).format('YYYY-MM-DD');
};

const dateTodayFormatExcel = () => {
  return moment(new Date()).format('DD-MM-YYYY_HH\'mm');
};

const dateTodayFormatPlan = () => {
  return moment(new Date()).format('YYYY/MM/DD');
};

const parseSecondsToHM = (value: any) => {
  if (value === undefined) {
    return '--:--';
  }
  return `${`0${Math.floor(value / 3600)}`.slice(-2)}:${`0${Math.floor(
    (value - Math.floor(value / 3600) * 3600) / 60
  )}`.slice(-2)}`;
};

const parseSecondsToHMText = (value: any) => {
  if (!value && parseInt(value, 10) !== 0) {
    return '';
  }
  return `${Math.floor(value / 3600) > 0 ? `${Math.floor(value / 3600)}h ` : ''}${Math.floor(
    (value - Math.floor(value / 3600) * 3600) / 60
  )}m`;
};

const twoDigits = (value: any) => {
  if (value < 10) {
    return `0${value}`;
  }
  return value;
};

const timestampToHoursMinutes = (value: any) => {
  return `${twoDigits(new Date(value).getHours())}:${twoDigits(new Date(value).getMinutes())}`;
};

const arrayTimeWindowsToString = (array: any) => {
  if (array && array.length > 0) {
    return array.map(
      (timewindow: any) => `${parseSecondsToHM(timewindow[0])}-${parseSecondsToHM(timewindow[1])} `
    );
  }
  return '';
};

const calculateTimeLeft = (date: any) => {
  // @ts-expect-error ts-migrate(2362) FIXME: The left-hand side of an arithmetic operation must... Remove this comment to see the full error message
  return Math.ceil((new Date(date) - new Date()) / (1000 * 60 * 60 * 24));
};

const getAllDatesBetween = (array: any, field: any) => {
  const dates: any = [];
  if (array.length === 0) {
    return dates;
  }
  const currDate = moment(array[0].date);
  const lastDate = moment(array[array.length - 1].date);
  let i = 0;
  do {
    if (array[i].date === dateFormatToPicker(currDate.clone().toDate())) {
      dates.push(array[i]);
      i += 1;
    } else {
      dates.push({ date: dateFormatToPicker(currDate.clone().toDate()), [field]: 0 });
    }
  } while (currDate.add(1, 'days').diff(lastDate) <= 0);
  return dates;
};

const pushZeroBetweenStartDateAndFirstDate = (array: any, startDate: any, field: any) => {
  if (array.length > 0) {
    const currDate = moment(array[0].date);
    const firstDate = moment(startDate);
    if (firstDate < currDate) {
      while (currDate.subtract(1, 'days').diff(firstDate) >= 0) {
        array.unshift({ date: dateFormatToPicker(currDate.clone().toDate()), [field]: 0 });
      }
    }
  }
  return array;
};

const aggregateByWeek = (array: any, field: any) => {
  return array.reduce(
    (p: any, n: any) => ({
      ...p,
      [moment(n.date).week()]: p[moment(n.date).week()]
        ? p[moment(n.date).week()] + n[field]
        : 0 + n[field],
    }),
    {}
  );
};

const calculateDuration = (duration: any) => (duration ? Math.ceil(duration / 60) : 0);

export {
  parseHMtoSeconds,
  dateTodayFormat,
  parseSecondsToHM,
  dateTodayFormatExcel,
  timestampToHoursMinutes,
  dateFormat,
  twoDigits,
  arrayTimeWindowsToString,
  parseSecondsToHMText,
  calculateTimeLeft,
  dateFormatShortDate,
  dateFormatLongDate,
  dateFormatToPicker,
  getAllDatesBetween,
  pushZeroBetweenStartDateAndFirstDate,
  calculateDuration,
  aggregateByWeek,
  dateTodayFormatPlan,
  dateFormatShortTime,
};
