import constants from '../constants/index';

const initialState = {
  card: undefined,
  billingInfo: undefined,
  plan: undefined,
};

function payment(state = initialState, action: any) {
  switch (action.type) {
    case constants.User.USER_REMOVE_AS_USER:
      return {
        ...initialState,
      };
    case constants.Payment.PAYMENT_GET_SUBSCRIPTION_CARD_SUCCESS:
      return {
        ...state,
        card: action.payload,
      };
    case constants.Payment.PAYMENT_GET_BILLING_ADDRESS_SUCCESS:
      return {
        ...state,
        billingInfo: action.payload,
      };
    case constants.Payment.PAYMENT_POST_SUBSCRIPTION_PLAN_SUCCESS:
      return {
        ...state,
        plan: action.payload,
      };
    case constants.Payment.DELETE_PLAN:
      return {
        ...state,
        plan: undefined,
      };
    case constants.User.USER_LOGOUT:
      return { ...initialState };
    default:
      return state;
  }
}
export default payment;
