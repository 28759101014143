import createSvgIcon from '@material-ui/icons/utils/createSvgIcon';
import React from 'react';

export default createSvgIcon(
  <>
    <path
      d="M8.1,0.9c0-0.1,0-0.2-0.1-0.3C7.5,0.3,6.9,0.1,6.4,0.1c-0.5,0-1,0.2-1.6,0.5C4.4,0.8,4,0.9,3.6,0.9
	c-0.4,0-0.7-0.1-1.1-0.2V0.3C2.5,0.1,2.4,0,2.2,0C2,0,1.9,0.1,1.9,0.3v10.6c0,0.2,0.1,0.3,0.3,0.3c0.2,0,0.3-0.1,0.3-0.3V4.7
	c0.4,0.2,0.7,0.2,1.1,0.2c0.5,0,1-0.2,1.6-0.5C5.6,4.2,6,4.1,6.4,4.1c0.4,0,0.8,0.1,1.3,0.3c0,0,0.1,0,0.2,0c0.2,0,0.3-0.1,0.3-0.3
	L8.1,0.9L8.1,0.9z M8.1,0.9"
    />
  </>,
  'GoalIcon'
);
