import { Grid } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { VSpacer } from '@sm-highway-web/react-components';
import { styles } from './Common';

type Props = {
  classes?: {
    box: string;
  };
  title: string | React.ReactElement;
  children: React.ReactElement | React.ReactNode | React.ReactElement[];
};

const Block = ({ title, children, classes }: Props) => (
  <Grid
    container
    style={{ borderTop: '1px solid #E7E7E7' }}
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    className={classes.box}
    direction="column"
  >
    <VSpacer small />
    <p
      style={{
        color: '#8194B8',
        textTransform: 'uppercase',
        // @ts-expect-error ts-migrate(2322) FIXME: Type '{ color: string; textTransform: "uppercase";... Remove this comment to see the full error message
        size: '12px',
        margin: 0,
      }}
    >
      {title}
    </p>
    {children}
    <VSpacer small />
  </Grid>
);

export default withStyles(styles)(Block);
