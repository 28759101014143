import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Button, HSpacer, InputText, VSpacer } from '@sm-highway-web/react-components';
import { IOptimizerConfig } from 'highway-api/dist/common/interfaces/common';
import { IProjectData, IProjectUnits } from 'highway-api/dist/common/interfaces/projects';
import lodash from 'lodash';
import React, { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { connect } from 'react-redux';
import {
  PROJECT_OPTIMIZER_CONIFG_MAP,
  PROJECT_OPTIMIZER_CONIFG_SM_ROOT_MAP,
} from '../../../constants/OptimizerConfig';
import * as routing from '../../../constants/Routing';
import history from '../../../helpers/History';
import { IOrganizationData } from '../../../interfaces/organizations';
import { Projects } from '../../../redux/actions';
import { projectHelpers, userHelpers } from '../../../redux/helpers';
import FormBoolean from '../../form-fields/FormBoolean';
import FormRadioButton from '../../form-fields/FormRadioButton';
import FormText from '../../form-fields/FormText';
import FormTimewindows from '../../form-fields/FormTimewindows';
import FormattedMessage from '../../FormattedMessageCustom';

const styles = {
  select: {
    backgroundColor: '#EBF0F8',
    marginTop: '6px',
    borderRadius: '4px',
    paddingLeft: '6px',
    '&:focus': {
      borderRadius: '4px',
      backgroundColor: '#FDF6ED',
    },
  },
  label: {
    fontSize: '9px',
    color: '#8B8B8B',
    textTransform: 'uppercase',
    marginBottom: '4px',
  },
};

const settingsCurrency = [
  { value: 'EUR', label: 'EUR (€)' },
  { value: 'USD', label: 'USD ($)' },
  { value: 'MXN', label: 'MXN ($)' },
];
const settingsDistance = [
  { value: 'km', label: 'Km' },
  { value: 'mi', label: 'Mi' },
];
const settingsWeight = [
  { value: 'kg', label: 'kg' },
  { value: 'lb', label: 'lb' },
];
const settingsVolume = [
  { value: 'm³', label: 'm³' },
  { value: 'u', label: 'u' },
  { value: 'l', label: 'l' },
];

type OwnOrganizationProjectsEditSettingsProps = {
  classes: {
    select?: string;
    label?: string;
  };
  actions: {
    editProject: (...args: any[]) => any;
  };
  project: IProjectData;
  isDirtyState: (...args: any[]) => any;
  asUser?: string;
  organization: IOrganizationData;
};

type OrganizationProjectsEditSettingsProps = OwnOrganizationProjectsEditSettingsProps;

const OrganizationProjectsEditSettings = ({
  classes,
  project,
  isDirtyState,
  organization,
  actions,
  asUser,
}: OrganizationProjectsEditSettingsProps) => {
  const [dirtyState, setDirtyState] = useState(false);
  const [confirmDeleteOrganization, setConfirmDeleteOrganization] = useState<string>('');
  const [optimizerSettings, setOptimizerSettings] = useState<IOptimizerConfig | undefined>(undefined);
  const [deleteDialogVisible, setDeleteDialogVisible] = useState<boolean>(false);
  const [units, setUnits] = useState<IProjectUnits | undefined>(undefined);
  const intl = useIntl();

  useEffect(() => {
    if (project) {
      setOptimizerSettings(project?.optimizer_config);
      setUnits(project?.units);
    }
  }, [project]);

  useEffect(() => {
    if (project) {
      if (
        !lodash.isEqual(optimizerSettings, project.optimizer_config) ||
        !lodash.isEqual(units, project.units)
      ) {
        setDirtyState(true);
      } else {
        setDirtyState(false);
      }
    }
  }, [project, optimizerSettings, units]);

  useEffect(() => {
    isDirtyState(dirtyState);
  }, [dirtyState, isDirtyState]);

  const handleEditProjectSettings = () => {
    actions.editProject(project.id, {
      optimizer_config: optimizerSettings,
      units,
    });
  };

  const getOptimizerMap = () =>
    userHelpers.currentUser.isAdmin() || asUser
      ? PROJECT_OPTIMIZER_CONIFG_SM_ROOT_MAP
      : PROJECT_OPTIMIZER_CONIFG_MAP;

  if (!project) return null;

  return (
    <Grid container direction="column">
      <Grid item container style={{ padding: '8px', borderBottom: '1px solid #D7DAE2' }}>
        <Grid
          item
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          wrap="nowrap"
        >
          <Grid item container wrap="nowrap">
            <Typography variant="h2">
              <FormattedMessage id="settings.organization.projects.edit.settings.title" />
            </Typography>
          </Grid>
        </Grid>
      </Grid>

      <VSpacer large />

      <Grid item>
        <Typography variant="h5">
          <FormattedMessage id="settings.organization.projects.edit.settings.units" />
        </Typography>
      </Grid>

      <VSpacer medium />

      <Divider />

      <VSpacer medium />

      <Grid container>
        {units?.distance && (
          <FormRadioButton
            value={units.distance}
            arrayRadios={settingsDistance}
            label={intl.formatMessage({ id: 'distance' })}
            onUpdate={(distance: any) => setUnits({ ...units, distance })}
            defaultValue="km"
          />
        )}

        <HSpacer />
        
        {units?.weight && (
          <FormRadioButton
            value={units.weight}
            arrayRadios={settingsWeight}
            label={intl.formatMessage({ id: 'weight' })}
            onUpdate={(weight: any) => setUnits({ ...units, weight })}
            defaultValue="kg"
          />
        )}

        <HSpacer />
        
        {units?.volume && (
          <FormRadioButton
            value={units.volume}
            arrayRadios={settingsVolume}
            label={intl.formatMessage({ id: 'volume' })}
            onUpdate={(volume: any) => setUnits({ ...units, volume })}
            defaultValue="m³"
          />
        )}
        
        <HSpacer />
        
        {units?.currency && (
          <FormRadioButton
            value={units.currency}
            arrayRadios={settingsCurrency}
            label={intl.formatMessage({ id: 'currency' })}
            onUpdate={(currency: any) => setUnits({ ...units, currency })}
            defaultValue="EUR"
          />
        )}
      </Grid>

      <VSpacer large />

      <Grid item>
        <Typography variant="h5">
          <FormattedMessage id="settings.organization.projects.edit.settings.optimizer_config.title" />
        </Typography>
      </Grid>

      <VSpacer medium />

      <Divider />

      <VSpacer medium />

      <Grid item>
        <VSpacer small />
        <FormattedMessage id="settings.organization.projects.edit.settings.optimizer_config.description" />
      </Grid>
      <VSpacer />

      <VSpacer medium />

      <Grid container direction="column" style={{ maxWidth: '300px' }}>
        {/* @ts-expect-error ts-migrate(2349) FIXME: This expression is not callable. */}
        {optimizerSettings && getOptimizerMap().map((c: any) => {
          switch (c.type) {
            case 'number':
              return (
                <Grid item key={`optimizeroptions-${c.id}`}>
                  <FormText
                    key={`${c.type}${c.id}`}
                    label={<FormattedMessage id={`optimizeroptions.details.${c.id}`} />}
                    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                    fieldValue={optimizerSettings[c.id]}
                    handleEnter={(value: any) =>
                      setOptimizerSettings((prevOptimizerSettings) => ({
                        ...prevOptimizerSettings,
                        [c.id]: value,
                      }))
                    }
                    type="number"
                  />
                </Grid>
              );
            case 'select':
              return (
                <Grid item key={`optimizeroptions-${c.id}`}>
                  <p className={classes.label} key={`plabel${c.id}`}>
                    <FormattedMessage id={`optimizeroptions.details.${c.id}`} />
                  </p>
                  <Select
                    key={`select${c.id}`}
                    disableUnderline
                    style={{ width: '100%' }}
                    classes={{ root: classes.select }}
                    onChange={async (evt) => {
                      const { value } = evt.target;
                      if (value === undefined || value === 'noselect') {
                        setOptimizerSettings((prevOptimizerSettings) => ({
                          ...prevOptimizerSettings,
                          [c.id]: null,
                        }));
                      } else {
                        setOptimizerSettings((prevOptimizerSettings) => ({
                          ...prevOptimizerSettings,
                          [c.id]: value,
                        }));
                      }
                    }}
                    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                    value={optimizerSettings[c.id] || 'noselect'}
                  >
                    <MenuItem value="noselect" key={`${c.id}menuItemnoselect`}>
                      {c.no_text_id ? (
                        <FormattedMessage id={`${c.no_text_id}`} />
                      ) : c.no_text ? (
                        `${c.no_text}`
                      ) : (
                        '-'
                      )}
                    </MenuItem>
                    {c.options.map((options: any) => (
                      <MenuItem value={`${options.value}`} key={`${c.id}menuItem${options.value}`}>
                        {options.label_id ? (
                          <FormattedMessage id={`${options.label_id}`} />
                        ) : (
                          options.label
                        )}
                      </MenuItem>
                    ))}
                  </Select>
                </Grid>
              );
            case 'minutes':
              return (
                <Grid item key={`optimizeroptions-${c.id}`}>
                  <FormText
                    key={`${c.type}${c.id}`}
                    label={<FormattedMessage id={`optimizeroptions.details.${c.id}`} />}
                    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                    fieldValue={optimizerSettings[c.id] ? optimizerSettings[c.id] / 60 : undefined}
                    handleEnter={(value: any) => {
                      setOptimizerSettings((prevOptimizerSettings) => ({
                        ...prevOptimizerSettings,
                        [c.id]: value * 60,
                      }));
                    }}
                    suffix="min"
                    type="number"
                  />
                </Grid>
              );
            case 'boolean':
              return (
                <Grid item key={`optimizeroptions-${c.id}`}>
                  <FormBoolean
                    // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type '(string &... Remove this comment to see the full error message
                    key={`${c.type}${c.id}`}
                    label={<FormattedMessage id={`optimizeroptions.details.${c.id}`} />}
                    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                    checked={optimizerSettings[c.id]}
                    onChange={(event: any) => {
                      const { checked } = event.target;
                      setOptimizerSettings((prevOptimizerSettings) => ({
                        ...prevOptimizerSettings,
                        [c.id]: checked,
                      }));
                    }}
                    color="primary"
                  />
                </Grid>
              );
            case 'timewindows':
              return (
                <Grid item key={`optimizeroptions-${c.id}`}>
                  <FormTimewindows
                    key={`${c.type}${c.id}`}
                    label={<FormattedMessage id={`optimizeroptions.details.${c.id}`} />}
                    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                    timewindows={optimizerSettings[c.id]}
                    onTimewindowsChange={(value: any) =>
                      setOptimizerSettings((prevOptimizerSettings) => ({
                        ...prevOptimizerSettings,
                        [c.id]: value,
                      }))
                    }
                  />
                </Grid>
              );
            case 'timewindow':
              return (
                <Grid item key={`optimizeroptions-${c.id}`}>
                  <FormTimewindows
                    key={`${c.type}${c.id}`}
                    label={<FormattedMessage id={`optimizeroptions.details.${c.id}`} />}
                    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                    timewindows={optimizerSettings[c.id]}
                    onTimewindowsChange={(value: any) => {
                      setOptimizerSettings((prevOptimizerSettings) => ({
                        ...prevOptimizerSettings,
                        [c.id]: value,
                      }));
                    }}
                    single
                  />
                </Grid>
              );
            default:
              return (
                <Grid item key={`optimizeroptions-${c.id}`}>
                  <FormText
                    key={`${c.type}${c.id}`}
                    label={<FormattedMessage id={`optimizeroptions.details.${c.id}`} />}
                    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                    fieldValue={optimizerSettings[c.id]}
                    handleEnter={(value: any) =>
                      setOptimizerSettings((prevOptimizerSettings) => ({
                        ...prevOptimizerSettings,
                        [c.id]: value,
                      }))
                    }
                  />
                </Grid>
              );
          }
        })}
      </Grid>

      <Grid item style={{ position: 'fixed', bottom: '40px', right: '40px' }}>
        <Button onClick={handleEditProjectSettings} disabled={!dirtyState}>
          <FormattedMessage id="settings.buttonupdate" />
        </Button>
      </Grid>

      {(userHelpers.currentUser.isOrganizationAdmin() ||
        userHelpers.currentUser.isOrganizationManager() ||
        projectHelpers.currentUser.isManagerInProject(project)) && (
        <>
          <VSpacer />

          <Grid item container style={{ padding: '8px', borderBottom: '1px solid #D7DAE2' }}>
            <Grid
              item
              container
              direction="row"
              justify="space-between"
              alignItems="center"
              wrap="nowrap"
            >
              <Grid item container wrap="nowrap">
                <Typography variant="h2">
                  <FormattedMessage id="settings.organization.projects.edit.settings.danger_zone.title" />
                </Typography>
              </Grid>
            </Grid>
          </Grid>

          <VSpacer medium />

          <Grid item>
            <FormattedMessage id="settings.organization.projects.edit.settings.danger_zone.description" />
          </Grid>
          <VSpacer />

          <Grid item container alignItems="center" spacing={1}>
            <Grid item>
              <Button
                onClick={() => setDeleteDialogVisible(true)}
                style={{
                  boxShadow: 'unset',
                  backgroundColor:
                    project.id === organization.default_project_id
                      ? 'rgba(0, 0, 0, 0.12)'
                      : '#FF2825',
                }}
                color="secondary"
                variant="contained"
                disabled={project.id === organization.default_project_id}
              >
                <FormattedMessage id="settings.organization.projects.edit.settings.danger_zone.delete.title" />
              </Button>
            </Grid>
            <Grid item>
              {project.id === organization.default_project_id && (
                <Typography style={{ color: '#FF2825', fontSize: '12px' }}>
                  <FormattedMessage id="settings.organization.projects.edit.settings.danger_zone.delete.default_project_disabled" />
                </Typography>
              )}
            </Grid>
          </Grid>
        </>
      )}

      <VSpacer large />

      <Dialog open={!!deleteDialogVisible} onClose={() => setDeleteDialogVisible(false)}>
        <DialogTitle>
          <FormattedMessage id="settings.organization.projects.edit.settings.danger_zone.delete.title" />
        </DialogTitle>

        <DialogContent>
          <Grid container direction="column">
            <Typography>
              <FormattedMessage
                id="settings.organization.projects.edit.settings.danger_zone.delete.info"
                values={{
                  label: `${projectHelpers.getProjectLabel(project.label, organization.label)}`,
                }}
              />
            </Typography>

            <VSpacer small />

            <VSpacer large />

            <Typography style={{ fontWeight: 'bold' }}>
              <FormattedMessage id="settings.organization.projects.edit.settings.danger_zone.delete.label" />
            </Typography>

            <VSpacer small />

            <InputText
              id="organization_name"
              placeholder={intl.formatMessage({
                id: 'settings.organization.projects.edit.settings.danger_zone.delete.label_ph',
              })}
              value={confirmDeleteOrganization}
              onChange={(value: any) => setConfirmDeleteOrganization(value)}
              margin="none"
              validators={[]}
            />
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteDialogVisible(false)} color="secondary">
            <FormattedMessage id="settings.buttoncancel" />
          </Button>
          <Button
            disabled={
              confirmDeleteOrganization === '' ||
              projectHelpers.getProjectLabel(project.label, organization.label).toLowerCase() !==
                confirmDeleteOrganization.toLowerCase()
            }
            style={{
              backgroundColor:
                confirmDeleteOrganization === '' ||
                projectHelpers.getProjectLabel(project.label, organization.label).toLowerCase() !==
                  confirmDeleteOrganization.toLowerCase()
                  ? 'rgba(0, 0, 0, 0.12)'
                  : '#FF2825',
            }}
            color="secondary"
            variant="contained"
            onClick={async () => {
              // @ts-expect-error ts-migrate(2339) FIXME: Property 'deleteProject' does not exist on type '{... Remove this comment to see the full error message
              actions.deleteProject(project.id);
              history.push(`${routing.SETTINGS.ORGANIZATION.PROJECTS.ROOT}`);
              setDeleteDialogVisible(false);
            }}
          >
            <FormattedMessage id="settings.organization.projects.edit.settings.danger_zone.delete.title" />
          </Button>
        </DialogActions>
      </Dialog>
    </Grid>
  );
};

OrganizationProjectsEditSettings.defaultProps = {
  asUser: undefined,
};

const mapStateToProps = (state: any) => {
  return {
    asUser: state.user.asUser,
    organization: state.organization.organization && state.organization.organization,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    editProject: (projectId: any, projectData: any) =>
      dispatch(Projects.editProject(projectId, projectData)),
    deleteProject: (projectId: any) => dispatch(Projects.deleteProject(projectId)),
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ select: { backgroundColor: str... Remove this comment to see the full error message
)(withStyles(styles)(OrganizationProjectsEditSettings));
