export const USER_SET_USER_LOCATION = 'USER_SET_USER_LOCATION';

export const USER_LOGIN = 'USER_LOGIN';
export const USER_LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS';
export const USER_LOGIN_ERROR = 'USER_LOGIN_ERROR';
export const USER_LOGOUT = 'USER_LOGOUT';

export const USER_DETAILS = 'USER_DETAILS';
export const USER_DETAILS_SUCCESS = 'USER_DETAILS_SUCCESS';
export const USER_DETAILS_ERROR = 'USER_DETAILS_ERROR';

export const USER_REGISTER = 'USER_REGISTER';
export const USER_REGISTER_SUCCESS = 'USER_REGISTER_SUCCESS';
export const USER_REGISTER_ERROR = 'USER_REGISTER_ERROR';

export const USER_RESENDEMAIL = 'USER_RESENDEMAIL';
export const USER_RESENDEMAIL_SUCCESS = 'USER_RESENDEMAIL_SUCCESS';
export const USER_RESENDEMAIL_ERROR = 'USER_RESENDEMAIL_ERROR';

export const USER_VALIDATE = 'USER_VALIDATE';
export const USER_VALIDATE_SUCCESS = 'USER_VALIDATE_SUCCESS';
export const USER_VALIDATE_ERROR = 'USER_VALIDATE_ERROR';

export const USER_EDIT = 'USER_EDIT';
export const USER_EDIT_SUCCESS = 'USER_EDIT_SUCCESS';
export const USER_EDIT_ERROR = 'USER_EDIT_ERROR';

export const USER_LANGUAGE = 'USER_LANGUAGE';

export const USER_AS_USER = 'USER_AS_USER';
export const USER_REMOVE_AS_USER = 'USER_REMOVE_AS_USER';

export const ADMIN_VALIDATE_USER_SUCCESS = 'ADMIN_VALIDATE_USER_SUCCESS';
export const ADMIN_VALIDATE_USER_ERROR = 'ADMIN_VALIDATE_USER_ERROR';

export const USER_EDIT_GUIDE_SUCCESS = 'USER_EDIT_GUIDE_SUCCESS';
export const USER_EDIT_GUIDE_ERROR = 'USER_EDIT_GUIDE_ERROR';

export const USER_UPLOAD_AVATAR_SUCCESS = 'USER_UPLOAD_AVATAR_SUCCESS';
export const USER_UPLOAD_AVATAR_ERROR = 'USER_UPLOAD_AVATAR_ERROR';

export const USER_REMOVE_AVATAR_SUCCESS = 'USER_REMOVE_AVATAR_SUCCESS';
export const USER_REMOVE_AVATAR_ERROR = 'USER_REMOVE_AVATAR_ERROR';
