import constants from '../constants/index';

const initialState = {
  tableColumns: getRouteTableColumns(),
};

function routes(state = initialState, action: any) {
  switch (action.type) {
    case constants.Routes.ROUTES_SET_TABLE_COLUMNS:
      setRoutesTableColumns(action.payload);
      return {
        ...state,
        tableColumns: action.payload,
      };
    default:
      return state;
  }
}
export default routes;

function getRouteTableColumns() {
  const tableColumns = localStorage.getItem('ROUTES_TABLE_COLUMNS');
  if(tableColumns) {
    const parsedTableColumns = JSON.parse(tableColumns);
    const finalTableColumns : { [key: string]: boolean } = {};
    Object.keys(constants.Routes.ROUTES_DEFAULT_TABLE_COLUMNS).forEach((key: string) => {
      finalTableColumns[key] = parsedTableColumns.hasOwnProperty(key) ? parsedTableColumns[key] : constants.Routes.ROUTES_DEFAULT_TABLE_COLUMNS[key];
    });
    return finalTableColumns;
  }
  return constants.Routes.ROUTES_DEFAULT_TABLE_COLUMNS;
}

function setRoutesTableColumns(tableColumns: any) {
  localStorage.setItem('ROUTES_TABLE_COLUMNS', JSON.stringify(tableColumns));
}
