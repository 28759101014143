import { Grid, InputLabel, ListItem, Select, Tooltip, Typography } from '@material-ui/core';
import { Lock } from '@material-ui/icons';
import { Button, Dialog, VSpacer } from '@sm-highway-web/react-components';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
// ASSETS
import VehicleIcons from '../assets/svg/VehicleIcons';
import WarningIcon from '../assets/svg/WarningIcon';
import FormattedMessage from '../components/FormattedMessageCustom';
import colors from '../constants/Colors';

const StyledListItem = styled(ListItem)`
  cursor: pointer;
  &:focus {
    outline: none;
  }
  :hover {
    background-color: #f1f1f1;
  }
`;

type InteractServicesDialogProps = {
  open: boolean;
  setDialog: (...args: any[]) => any;
  type: 'assign' | 'exchange';
  handleInteractServices: (...args: any[]) => any;
  routes?: any[]; // TODO: PropTypes.shape
};

const InteractServicesDialog = ({
  open,
  setDialog,
  type,
  routes,
  handleInteractServices,
}: InteractServicesDialogProps) => {
  const [selectedRoute, setSelectedRoute] = useState(undefined);

  // Select the first route to avoid empty select
  useEffect(() => {
    if (routes && routes.length > 0) {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '0' is not assignable to paramete... Remove this comment to see the full error message
      setSelectedRoute(0);
    }
  }, [routes]);

  const onInteractServices = (e: any) => {
    e.stopPropagation();
    if (selectedRoute !== undefined) {
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      handleInteractServices(selectedRoute === 'missing' ? selectedRoute : routes[selectedRoute]);
    }
  };

  const routesList =
    routes &&
    routes.map((route: any, idx: any) => {
      let Icon = VehicleIcons.truck;
      if (route.icon && VehicleIcons[route.icon]) {
        Icon = VehicleIcons[route.icon];
      }
      return (
        <StyledListItem
          key={`vehicle-${route.vehicle_id || idx}`}
          // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
          value={idx}
          disabled={route.is_locked}
        >
          <Grid container alignItems="center">
            {route.is_locked && (
              <Tooltip
                title={<FormattedMessage id="planoverview.route.table.header.locked_tooltip" />}
              >
                <Lock style={{ fontSize: '18px', color: '#56637b' }} />
              </Tooltip>
            )}
            <Icon
              style={{
                color: colors[idx],
                float: 'left',
                margin: '0px 8px 0px 8px',
              }}
            />
            <Typography
              component="p"
              style={{
                textOverflow: 'ellipsis',

                // width: (route.is_locked) ? '172px' : '210px',
                /* Required for text-overflow to do anything */
                whiteSpace: 'nowrap',
                overflow: 'hidden',
              }}
              key={`typography-${route.id}`}
            >
              {route.label}
            </Typography>
          </Grid>
        </StyledListItem>
      );
    });

  return (
    <Dialog
      modal
      onClose={() => setDialog(undefined)}
      open={open}
      fullWidth
      maxWidth="sm"
      title={<FormattedMessage id={`${type}servicesdialog.title`} />}
    >
      <Typography>
        <FormattedMessage id={`${type}servicesdialog.subtitle`} />
      </Typography>
      <VSpacer medium />
      <Grid container spacing={2}>
        <Grid item lg={7} md={7} sm={12}>
          <InputLabel shrink htmlFor="select-multiple-native3">
            <FormattedMessage id={`${type}servicesdialog.select_label`} />
          </InputLabel>
          <Select
            value={selectedRoute}
            defaultValue={0}
            onClick={(e) => {
              e.stopPropagation();
            }}
            onChange={(evt) => {
              // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
              setSelectedRoute(evt.target.value);
            }}
            fullWidth
            inputProps={{
              id: 'select-multiple-native3',
            }}
          >
            {routesList}

            {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
            <StyledListItem key="missing" value="missing">
              <Grid container alignItems="center">
                <WarningIcon
                  color="primary"
                  style={{
                    float: 'left',
                    margin: '0px 8px',
                    height: '100%',
                  }}
                />
                <Typography component="span" key="typography-missing">
                  <FormattedMessage id="planmap.textnotassigned" />
                </Typography>
              </Grid>
            </StyledListItem>
          </Select>
        </Grid>
        <Grid item lg={5} md={5} sm={12}>
          <VSpacer small />
          <Grid container direction="column" justify="center">
            <Button
              disabled={
                selectedRoute !== 'missing' &&
                (selectedRoute === undefined ||
                  !routes ||
                  // @ts-expect-error ts-migrate(2538) FIXME: Type 'undefined' cannot be used as an index type.
                  !routes[selectedRoute] ||
                  // @ts-expect-error ts-migrate(2538) FIXME: Type 'undefined' cannot be used as an index type.
                  routes[selectedRoute].is_locked)
              }
              variant="contained"
              onClick={onInteractServices}
            >
              <FormattedMessage id={`${type}servicesdialog.button`} />
            </Button>
          </Grid>
        </Grid>
      </Grid>
      <VSpacer />
    </Dialog>
  );
};

InteractServicesDialog.defaultProps = {
  routes: undefined,
};

export default InteractServicesDialog;
