import { Typography } from '@material-ui/core';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import React, { PureComponent } from 'react';
import { Link as MUILink } from 'react-router-dom';
import eventsPanel from '../services/events';

const styles = ({ palette }: Theme) => createStyles({
  link: {
    textDecoration: 'none',
    color: palette.primary.main,
    fontWeight: 'bold',
  },
});

type LinkProps = {
  classes: {
    link: string;
  };
  eventsPanelEvent?: string;
  to: string;
};

class Link extends PureComponent<LinkProps> {
  static defaultProps: any;

  render() {
    const { to, children, classes, eventsPanelEvent, ...other } = this.props;
    return (
      <Typography component="span">
        <MUILink
          to={to}
          {...other}
          className={classes.link}
          onClick={() => {
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'track' does not exist on type 'EventsPan... Remove this comment to see the full error message
            if (eventsPanelEvent) eventsPanel.track(eventsPanelEvent);
          }}
        >
          {children}
        </MUILink>
      </Typography>
    );
  }
}

Link.defaultProps = {
  children: undefined,
  eventsPanelEvent: undefined,
};

export default withStyles(styles)(Link);
