import {
  CircularProgress,
  Grid,
  InputLabel,
  OutlinedInput,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Button, Checkbox, Dialog, VSpacer } from '@sm-highway-web/react-components';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import FormattedMessage from '../components/FormattedMessageCustom';

const styles = () => ({
  inputPadding: {
    padding: '8px',
  },
});

type CopyPlanDialogProps = {
  classes: {
    inputPadding?: string;
  };
  planInfo: {
    id?: string;
    label?: string;
  };
  handleCopyPlan: (...args: any[]) => any;
  copyDialogOpen: boolean;
  handleOpenAssignDialog: (...args: any[]) => any;
};

const CopyPlanDialog = ({
  classes,
  planInfo,
  handleCopyPlan,
  handleOpenAssignDialog,
  copyDialogOpen,
}: CopyPlanDialogProps) => {
  const [loading, setLoading] = useState(false);
  const [label, setLabel] = useState(`${planInfo.label} (2)`);
  const [filter, setFilter] = useState({
    routes: true,
    services: {
      unassigned: true,
      pending: true,
      canceled: true,
      completed: true,
      cleanpod: true,
    },
  });

  const handleSelectAllServices = () => {
    const filterKeys = Object.keys(filter.services);
    // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    const filteredKeys = filterKeys.filter((serviceKey) => filter.services[serviceKey]);
    setFilter({
      ...filter,
      services: {
        unassigned: filteredKeys.length === 0,
        pending: filteredKeys.length === 0,
        canceled: filteredKeys.length === 0,
        completed: filteredKeys.length === 0,
        cleanpod: filteredKeys.length === 0,
      },
    });
  };

  const changeServiceFilterValue = (key: any, value: any) => {
    if (!isCheckboxDisabled(key)) {
      const newFilter = { ...filter };
      // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
      newFilter.services[key] = value;

      // Uncheck the clean POD checkbox when all other service filters are disabled.
      if (
        key !== 'cleanpod' &&
        [
          newFilter.services.unassigned,
          newFilter.services.pending,
          newFilter.services.canceled,
          newFilter.services.completed,
        ].filter((filterValue) => filterValue === false).length === 4
      ) {
        newFilter.services.cleanpod = false;
      }

      setFilter(newFilter);
    }
  };

  const history = useHistory();

  /**
   * If any filter of services is active, disable the cleanpod checkbox.
   * @param {*} key
   */
  const isCheckboxDisabled = (key: any) =>
    key === 'cleanpod' &&
    [
      filter.services.unassigned,
      filter.services.pending,
      filter.services.canceled,
      filter.services.completed,
    ].filter((filterValue) => filterValue === true).length === 0;

  return (
    <Dialog
      modal
      onClose={handleOpenAssignDialog}
      open={copyDialogOpen}
      title={<FormattedMessage id="copyplandialog.title" />}
    >
      <Typography>
        <FormattedMessage id="copyplandialog.subtitle" />
      </Typography>

      <VSpacer large />

      <Grid container direction="column" spacing={2}>
        <Grid item sm={7}>
          <InputLabel>
            <FormattedMessage id="copyplandialog.planname" />
          </InputLabel>
          <VSpacer medium />
          {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
          <OutlinedInput
            classes={{ input: classes.inputPadding }}
            value={label}
            autoFocus
            fullWidth
            type="text"
            onChange={(e) => {
              setLabel(e.target.value);
            }}
          />
        </Grid>

        <VSpacer medium />

        <Grid item sm={12}>
          <Checkbox
            checked={filter.routes}
            onChange={(e: any, value: any) => {
              const newFilter = { ...filter };
              newFilter.routes = value;
              setFilter(newFilter);
            }}
          />
          <span
            style={{ marginLeft: '8px', cursor: 'pointer' }}
            onClick={() => {
              const newFilter = { ...filter };
              newFilter.routes = !newFilter.routes;
              setFilter(newFilter);
            }}
          >
            <FormattedMessage tagName="span" id="copyplandialog.selectallroutes" />
          </span>
        </Grid>

        <Grid item sm={12}>
          <Checkbox
            checked={
              // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
              Object.keys(filter.services).filter((key) => filter.services[key] === true).length ===
              Object.keys(filter.services).length
            }
            onChange={handleSelectAllServices}
            indeterminate={
              // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
              Object.keys(filter.services).filter((key) => filter.services[key] === true).length >
                0 &&
              // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
              Object.keys(filter.services).filter((key) => filter.services[key] === true).length <
                Object.keys(filter.services).length
            }
          />
          <span
            style={{ marginLeft: '8px', cursor: 'pointer' }}
            onClick={() => {
              handleSelectAllServices();
            }}
          >
            <FormattedMessage tagName="span" id="copyplandialog.selectallservices" />
          </span>

          <VSpacer medium />

          {Object.keys(filter.services).map((key) => {
            return (
              <Grid item key={key} style={{ marginLeft: '18px' }}>
                <Checkbox
                  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                  checked={filter.services[key]}
                  onChange={(e: any, value: any) => changeServiceFilterValue(key, value)}
                  disabled={isCheckboxDisabled(key)}
                />

                <span
                  style={{
                    marginLeft: '8px',
                    cursor: `${isCheckboxDisabled(key) ? 'default' : 'pointer'}`,
                    color: `${isCheckboxDisabled(key) ? '#cacaca' : 'initial'}`,
                  }}
                  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                  onClick={() => changeServiceFilterValue(key, !filter.services[key])}
                >
                  <FormattedMessage tagName="span" id={`copyplandialog.select${key}services`} />
                </span>

                <VSpacer small />
              </Grid>
            );
          })}
        </Grid>

        <VSpacer medium />

        <Grid container justify="flex-end">
          <Button
            disabled={loading}
            variant="contained"
            style={{
              color: 'white',
              minWidth: '125px',
            }}
            onClick={() => {
              setLoading(true);
              handleCopyPlan(planInfo.id, label, filter);
            }}
          >
            {loading ? 
                <CircularProgress size={32} />
              :
                <FormattedMessage id="copyplandialog.buttoncreate" />
            }
          </Button>
        </Grid>
      </Grid>
      <VSpacer />
    </Dialog>
  );
};

export default withStyles(styles)(CopyPlanDialog);
