import { Grid } from '@material-ui/core';
import * as qs from 'query-string';
import React from 'react';
import { connect } from 'react-redux';
import { Redirect, Route, Switch } from 'react-router-dom';
import styled from 'styled-components';
import * as routing from '../constants/Routing';
import Login from '../pages/Login';
import LeftBar from './LeftBar';
import Validation from './Validation';

const GridContainer = styled(Grid)`
  min-height: 100vh;
  width: calc(100vw - 48px);
  margin-left: 48px;
`;

const GridChild = styled(Grid)`
  min-height: 100vh;
`;

type PublicComponentProps = {
  isLogged: boolean;
  location: {
    search?: string;
  };
};

const PublicComponent = ({ isLogged, location }: PublicComponentProps) => {
  const queryParams = qs.parse(location && location.search ? location.search : '');

  if (isLogged && queryParams && queryParams.redirectTo && queryParams.redirectTo !== '') {
    // TODO: Control if URL is from highway??

    window.location.href = queryParams.redirectTo as string;
    return null;
  }

  if (isLogged) return <Redirect to={routing.PRIVATEHIGHWAY} />;

  return (
    <>
      <GridContainer container justify="space-between" direction="column" wrap="nowrap">
        <GridChild item>
          <LeftBar />
          <Switch>
            <Route path={routing.LOGIN} exact component={() => <Login route="login" />} />
            <Route path={routing.USERVALIDATION} exact component={Validation} />
            <Route
              path={routing.ORGANIZATION_ACCEPT_USER_INVITATION}
              exact
              component={({ match }: any) => (
                <Login route="invitation" token={match.params.token} />
              )}
            />
            <Route path={routing.REGISTER} exact component={() => <Login route="register" />} />
            <Route
              path={routing.RESETPASSWORD}
              exact
              component={() => <Login route="reset-password" />}
            />
            <Route
              path={routing.NEWPASSWORD}
              exact
              component={({ match }: any) => (
                <Login route="reset-password" token={match.params.token} />
              )}
            />
            <Redirect from={routing.NOMATCHES} to={routing.LOGIN} />
          </Switch>
        </GridChild>
      </GridContainer>
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    isLogged: state.user.isLogged,
    language: state.user.language,
  };
};

export default connect(mapStateToProps, null)(PublicComponent);
