export const ACTIVE_PLAN_CREATE_PLAN_SUCCESS = 'ACTIVE_PLAN_CREATE_PLAN_SUCCESS';
export const ACTIVE_PLAN_CREATE_PLAN_ERROR = 'ACTIVE_PLAN_CREATE_PLAN_ERROR';

export const ACTIVE_PLAN_GET_PLAN = 'ACTIVE_PLAN_GET_PLAN';
export const ACTIVE_PLAN_GET_PLAN_SUCCESS = 'ACTIVE_PLAN_GET_PLAN_SUCCESS';
export const ACTIVE_PLAN_GET_PLAN_ERROR = 'ACTIVE_PLAN_GET_PLAN_ERROR';

export const ACTIVE_PLAN_OPTIMIZE_PLAN = 'ACTIVE_PLAN_OPTIMIZE_PLAN';
export const ACTIVE_PLAN_OPTIMIZE_PLAN_SUCCESS = 'ACTIVE_PLAN_OPTIMIZE_PLAN_SUCCESS';
export const ACTIVE_PLAN_OPTIMIZE_PLAN_ERROR = 'ACTIVE_PLAN_OPTIMIZE_PLAN_ERROR';

export const ACTIVE_PLAN_CREATE_PLAN_ROUTES_SUCCESS = 'ACTIVE_PLAN_CREATE_PLAN_ROUTES_SUCCESS';
export const ACTIVE_PLAN_CREATE_PLAN_ROUTES_ERROR = 'ACTIVE_PLAN_CREATE_PLAN_ROUTES_ERROR';

export const ACTIVE_PLAN_UPDATE_PLAN_SUCCESS = 'ACTIVE_PLAN_UPDATE_PLAN_SUCCESS';
export const ACTIVE_PLAN_UPDATE_PLAN_ERROR = 'ACTIVE_PLAN_UPDATE_PLAN_ERROR';

export const ACTIVE_PLAN_CLEAN_PLAN = 'ACTIVE_PLAN_CLEAN_PLAN';

export const ACTIVE_PLAN_CHANGE_OPTIONS_SUCCESS = 'ACTIVE_PLAN_CHANGE_OPTIONS_SUCCESS';
export const ACTIVE_PLAN_CHANGE_OPTIONS_ERROR = 'ACTIVE_PLAN_CHANGE_OPTIONS_ERROR';

export const ACTIVE_PLAN_SET_DETAIL_VIEW = 'ACTIVE_PLAN_SET_DETAIL_VIEW';

export const ACTIVE_PLAN_LIST_SET_SELECTED_ROUTES = 'ACTIVE_PLAN_LIST_SET_SELECTED_ROUTES';
export const ACTIVE_PLAN_LIST_SET_SELECTED_SERVICES = 'ACTIVE_PLAN_LIST_SET_SELECTED_SERVICES';

export const ACTIVE_PLAN_MAP_SET_SELECTED_ROUTES = 'ACTIVE_PLAN_MAP_SET_SELECTED_ROUTES';
export const ACTIVE_PLAN_MAP_SET_SELECTED_SERVICES = 'ACTIVE_PLAN_MAP_SET_SELECTED_SERVICES';
