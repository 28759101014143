import XLSX from 'xlsx';
import store from '../redux/store';
import { getLocationFromLatLng, postGeocode } from '../services/requests';
import { intl } from './IntGlobalProvider';
import { getRoutePublicLink } from './Routes';
import { dateFormat, parseSecondsToHM } from './TimeDistance';
import { kmToSM } from './Units';
import { getTotalPrice } from './Vehicles';

const s2ab = (s: any) => {
  const buf = new ArrayBuffer(s.length); // convert s to arrayBuffer
  const view = new Uint8Array(buf); // create uint8array as viewer
  // eslint-disable-next-line
  for (let i = 0; i < s.length; i += 1) view[i] = s.charCodeAt(i) & 0xff; // convert to octet
  return buf;
};

const saveData = (() => {
  const a = document.createElement('a');
  document.body.appendChild(a);
  return (wbout: any, name: any) => {
    if (wbout !== null) {
      const blob = new Blob([s2ab(wbout)], { type: 'application/octet-stream' });
      const url = window.URL.createObjectURL(blob);
      a.href = url;
      a.download = `ExportRoutes_${name}.xlsx`;
      a.click();
      window.URL.revokeObjectURL(url);
    }
  };
})();

const parseTimewindowToExcel = (value: any) => {
  return value.reduce(
    (p: any, n: any, i: any) =>
      i === value.length - 1 ? p + parseSecondsToHM(n) : `${p + parseSecondsToHM(n)}-`,
    ''
  );
};

const parseTimewindowsToExcel = (value: any) => {
  return value.reduce(
    (p: any, n: any, i: any) =>
      i === value.length - 1 ? p + parseTimewindowToExcel(n) : `${p + parseTimewindowToExcel(n)};`,

    ''
  );
};

const parseTW = (value: any) => {
  if (Array.isArray(value)) {
    if (Array.isArray(value[0])) {
      return parseTimewindowsToExcel(value);
    }

    return parseTimewindowToExcel(value);
  }

  return null;
};

const parseDataToExcel = (plan: any) => {
  const wb = XLSX.utils.book_new();
  const userInfo = store.getState().user.user;
  const { activeProject } = store.getState().projects;
  const distanceIn = activeProject.units.distance;
  wb.Props = {
    Title: 'Routes',
    Subject: 'Routes exported',
    Author: 'SmartMonkey',
    CreatedDate: new Date(),
  };

  const servicesLabel = intl.formatMessage({ id: 'routes.excel.sheets.services' });
  wb.SheetNames.push(servicesLabel);

  const vehiclesLabel = intl.formatMessage({ id: 'routes.excel.sheets.vehicles' });
  wb.SheetNames.push(vehiclesLabel);

  const vehiclewsData = [
    [
      intl.formatMessage({ id: 'routes.excel.columns.name' }),
      intl.formatMessage({ id: 'routes.excel.columns.external_id' }),
      intl.formatMessage({ id: 'routes.excel.columns.location_start' }),
      intl.formatMessage({ id: 'routes.excel.columns.address_start' }),
      intl.formatMessage({ id: 'routes.excel.columns.location_end' }),
      intl.formatMessage({ id: 'routes.excel.columns.address_end' }),
      intl.formatMessage({ id: 'routes.excel.columns.time_window' }),
      intl.formatMessage({ id: 'routes.excel.columns.max_distance' }),
      intl.formatMessage({ id: 'routes.excel.columns.max_weight' }),
      intl.formatMessage({ id: 'routes.excel.columns.max_volume' }),
      intl.formatMessage({ id: 'routes.excel.columns.max_services' }),
      intl.formatMessage({ id: 'routes.excel.columns.provides' }),
      intl.formatMessage({ id: 'routes.excel.columns.route_link' }),
      intl.formatMessage({ id: 'routes.excel.columns.price_per_minute' }),
      intl.formatMessage({ id: 'routes.excel.columns.price_per_distance' }),
      intl.formatMessage({ id: 'routes.excel.columns.total_price' }),
      ...(activeProject.custom_fields && activeProject.custom_fields.vehicle
        ? activeProject.custom_fields.vehicle.map((field: any) => field.label)
        : []),
    ],
  ];

  const servicesHeaders = [
    intl.formatMessage({ id: 'routes.excel.vehicles.columns.vehicle_id' }),
    intl.formatMessage({ id: 'routes.excel.vehicles.columns.order' }),
    intl.formatMessage({ id: 'routes.excel.vehicles.columns.external_id' }),
    intl.formatMessage({ id: 'routes.excel.vehicles.columns.client_external_id' }),
    intl.formatMessage({ id: 'routes.excel.vehicles.columns.name' }),
    intl.formatMessage({ id: 'routes.excel.vehicles.columns.location' }),
    intl.formatMessage({ id: 'routes.excel.vehicles.columns.address' }),
    intl.formatMessage({ id: 'routes.excel.vehicles.columns.address_2' }),
    intl.formatMessage({ id: 'routes.excel.vehicles.columns.time_windows' }),
    intl.formatMessage({ id: 'routes.excel.vehicles.columns.weight' }),
    intl.formatMessage({ id: 'routes.excel.vehicles.columns.volume' }),
    intl.formatMessage({ id: 'routes.excel.vehicles.columns.requires' }),
    intl.formatMessage({ id: 'routes.excel.vehicles.columns.eta' }),
    intl.formatMessage({ id: 'routes.excel.vehicles.columns.etd' }),
    intl.formatMessage({ id: 'routes.excel.vehicles.columns.duration' }),
    intl.formatMessage(
      { id: 'routes.excel.vehicles.columns.distance' },
      { value: `${distanceIn}` }
    ),
    intl.formatMessage({ id: 'routes.excel.vehicles.columns.comments' }),
    intl.formatMessage({ id: 'routes.excel.vehicles.columns.reference_person' }),
    intl.formatMessage({ id: 'routes.excel.vehicles.columns.phone' }),
    intl.formatMessage({ id: 'routes.excel.vehicles.columns.email' }),
    intl.formatMessage({ id: 'routes.excel.vehicles.columns.website' }),
    intl.formatMessage({ id: 'routes.excel.vehicles.columns.type' }),

    ...(activeProject.custom_fields && activeProject.custom_fields.client
      ? activeProject.custom_fields.client.map((field: any) => field.label)
      : []),

    intl.formatMessage({ id: 'routes.excel.vehicles.columns.done_at' }),
    intl.formatMessage({ id: 'routes.excel.vehicles.columns.status' }),
    intl.formatMessage({ id: 'routes.excel.vehicles.columns.feedback.comments' }),
    intl.formatMessage({ id: 'routes.excel.vehicles.columns.feedback.signature' }),
    intl.formatMessage({ id: 'routes.excel.vehicles.columns.feedback.images' }),
    intl.formatMessage({ id: 'routes.excel.vehicles.columns.feedback.reasons' }),

    ...(activeProject.custom_fields && activeProject.custom_fields.service_report_completed
      ? activeProject.custom_fields.service_report_completed.map((field: any) => field.label)
      : []),

    ...(activeProject.custom_fields && activeProject.custom_fields.service_report_canceled
      ? activeProject.custom_fields.service_report_canceled.map((field: any) => field.label)
      : []),
  ];

  const servicesWData = [servicesHeaders];

  plan.routes.forEach((route: any, idx: any) => {
    let sheetName = route.label ? `${idx}-${route.label}` : `${idx}`;

    // Accomodate name to Excel sheets naming constraints (http://www.excelcodex.com/2012/06/worksheets-naming-conventions/)
    sheetName = sheetName.substring(0, 31);
    sheetName = sheetName.replace(/[\\/*[\]:?]/g, '-');

    wb.SheetNames.push(sheetName);

    vehiclewsData.push([
      route.label,
      route.external_id,
      route.start_location &&
        `${route.start_location.lat ? route.start_location.lat : ''}, ${
          route.start_location.lng ? route.start_location.lng : ''
        }`,
      route.start_location && route.start_location.label,
      route.end_location &&
        `${route.end_location.lat ? route.end_location.lat : ''}, ${
          route.end_location.lng ? route.end_location.lng : ''
        }`,
      route.end_location && route.end_location.label,
      route.timewindow && parseTimewindowToExcel(route.timewindow),
      route.max_distance,
      route.max_weight,
      route.max_volume,
      route.max_services,
      route.provides &&
        route.provides.reduce(
          (p: any, n: any, i: any) => (i !== route.provides.length - 1 ? `${p + n} ,` : p + n),
          ''
        ),
      `${getRoutePublicLink(userInfo.public_key, route.id)}`,
      route.price_per_minute,
      route.price_per_distance,
      getTotalPrice(route, activeProject),
      ...(activeProject.custom_fields && activeProject.custom_fields.vehicle
        ? parseCustomFieldValues(route.custom_fields, activeProject.custom_fields.vehicle)
        : []),
    ]);

    const wsData = [servicesHeaders];

    const yes = intl.formatMessage({ id: 'yes' });
    const no = intl.formatMessage({ id: 'no' });
    route.services.forEach((service: any) => {
      const serviceData = [
        route.label,
        service.order + 1,
        service.external_id,
        service.client_external_id,
        service.label,
        service.location &&
          `${service.location.lat ? service.location.lat : ''}, ${
            service.location.lng ? service.location.lng : ''
          }`,
        service.location && service.location.label,
        service.location_details,
        service.timewindows && parseTW(service.timewindows),
        service.weight,
        service.volume,
        service.requires &&
          service.requires.reduce(
            (p: any, n: any, i: any) => (i !== service.requires.length - 1 ? `${p + n} ,` : p + n),
            ''
          ),
        service.planned_arrival_time ? parseSecondsToHM(service.planned_arrival_time) : null,
        service.planned_departure_time ? parseSecondsToHM(service.planned_departure_time) : null,
        service.duration && service.duration / 60,
        kmToSM(service.distance_to_next_location / 1000, distanceIn).toFixed(1),
        service.comments,
        service.reference_person,
        service.phone,
        service.email,
        service.website,
        service.type,
        ...(activeProject.custom_fields && activeProject.custom_fields.client
          ? parseCustomFieldValues(service.custom_fields, activeProject.custom_fields.client)
          : []),
        service.done_at ? dateFormat(service.done_at) : null,
        service.status
          ? intl.formatMessage({
              id: `routes.excel.vehicles.columns.status.${service.status}`,
            })
          : null,
        (service.feedback && service.feedback.comments) || null,
        (service.feedback && ((service.feedback.signature && yes) || no)) || null,
        (service.feedback &&
          ((service.feedback.images && service.feedback.images.length > 0 && yes) || no)) ||
          null,
        (service.feedback &&
          service.feedback.reason &&
          intl.formatMessage({
            id: `service.data.details.feedback.reason.${service.feedback.reason}`,
          })) ||
          null,

        ...(activeProject.custom_fields && activeProject.custom_fields.service_report_completed
          ? parseCustomFieldValues(
              service.feedback && service.feedback.completed_custom_fields
                ? service.feedback.completed_custom_fields
                : [],
              activeProject.custom_fields.service_report_completed
            )
          : []),

        ...(activeProject.custom_fields && activeProject.custom_fields.service_report_canceled
          ? parseCustomFieldValues(
              service.feedback && service.feedback.canceled_custom_fields
                ? service.feedback.canceled_custom_fields
                : [],
              activeProject.custom_fields.service_report_canceled
            )
          : []),
      ];

      wsData.push(serviceData);
      servicesWData.push(serviceData);
    });
    wb.Sheets[`${idx}-${route.label}`] = XLSX.utils.aoa_to_sheet(wsData);
  });

  wb.Sheets[servicesLabel] = XLSX.utils.aoa_to_sheet(servicesWData);

  wb.Sheets[vehiclesLabel] = XLSX.utils.aoa_to_sheet(vehiclewsData);

  // Iteration used for setting cell links for each dispatch.
  plan.routes.forEach((route: any, idx: any) => {
    wb.Sheets[vehiclesLabel][`M${idx + 2}`].l = {
      Target: `${getRoutePublicLink(userInfo.public_key, route.id)}`,
    };
  });

  const missingLabel = intl.formatMessage({ id: 'routes.excel.sheets.missing' });
  wb.SheetNames.push(missingLabel);
  const wsDataM = [
    [
      'Name',
      'ExternalID',
      'Location',
      'Address',
      'Timewindows',
      'Weight',
      'Volume',
      'Duration',
      'Requires',
      'Comments',
      'Reference Person',
      'Phone',
      'Email',
      'Website',
      'Type',
      ...(activeProject.custom_fields && activeProject.custom_fields.client
        ? activeProject.custom_fields.client.map((field: any) => field.label)
        : []),
    ],
  ];

  plan.services
    .filter((s: any) => !s.route_id)
    .forEach((service: any) => {
      wsDataM.push([
        service.label,
        service.extenal_id,
        service.location &&
          `${service.location.lat ? service.location.lat : ''}, ${
            service.location.lng ? service.location.lng : ''
          }`,
        service.location && service.location.label,
        service.timewindows && parseTW(service.timewindows),
        service.weight,
        service.volume,
        service.duration && service.duration / 60,
        service.requires &&
          service.requires.reduce(
            (p: any, n: any, i: any) => (i !== service.requires.length - 1 ? `${p + n} ,` : p + n),
            ''
          ),
        service.comments,
        service.reference_person,
        service.phone,
        service.email,
        service.website,
        service.type,
        ...(activeProject.custom_fields && activeProject.custom_fields.client
          ? parseCustomFieldValues(service.custom_fields, activeProject.custom_fields.client)
          : []),
      ]);
    });
  wb.Sheets[missingLabel] = XLSX.utils.aoa_to_sheet(wsDataM);
  if (wb.SheetNames.length > 0) {
    return XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
  }

  return null;
};

const isNumeric = (num: any) => {
  // eslint-disable-next-line
  return !isNaN(num);
};

const parseAddressToApi = (value: any) => {
  const splitByComas = value.toString().split(',');
  if (splitByComas.length === 2 && isNumeric(splitByComas[0]) && isNumeric(splitByComas[1])) {
    return { lat: Number(splitByComas[0]), lng: Number(splitByComas[1]) };
  }
  return value;
};

function parseCustomFieldValues(customFields: any, projectCustomFields: any) {
  const values: any = [];

  projectCustomFields.forEach((field: any) => {
    let value = null;

    if (
      Object.keys(customFields)
        .map((key) => key)
        .includes(field.id)
    ) {
      if (customFields[field.id] !== undefined && customFields[field.id] !== null) {
        let selectedOptions;
        switch (field.type) {
          case 'text':
          case 'numerical':
            value = customFields[field.id].toString();
            break;
          case 'boolean':
            value = customFields[field.id] ? 'True' : 'False';
            break;
          case 'categorical':
            selectedOptions = field.options.filter((_option: any, index: any) =>
              customFields[field.id].includes(index.toString())
            );
            value = selectedOptions.join(', ');
            break;
          default:
            break;
        }
      }
    }
    values.push(value);
  });
  return values;
}

const parseString = async (string: any) => {
  if (string === null) {
    return undefined;
  }
  return new Promise((resolve: any) => {
    try {
      resolve(`${string}`);
    } catch (e) {
      resolve('');
    }
  });
};

const parseBoolean = async (value: any) => {
  if (value === null) {
    return undefined;
  }
  return new Promise((resolve: any) => {
    try {
      // eslint-disable-next-line
      if (isNaN(value)) {
        resolve(null);
      }
      resolve(Boolean(value));
    } catch (e) {
      resolve(null);
    }
  });
};

const parseListSemicolon = async (list: any) => {
  if (list === null) {
    return undefined;
  }
  return new Promise((resolve: any) => {
    try {
      resolve(list.split(';').map((v: any) => v.trim()));
    } catch (e) {
      resolve(null);
    }
  });
};

const parseNumber = async (number: any) => {
  if (number === null) {
    return undefined;
  }
  return new Promise((resolve: any) => {
    try {
      // eslint-disable-next-line
      if (isNaN(number)) {
        resolve(null);
      }
      resolve(parseFloat(number));
    } catch (e) {
      resolve(null);
    }
  });
};

const parseInt = async (number: any) => {
  if (number === null) {
    return undefined;
  }
  return new Promise((resolve: any) => {
    try {
      // eslint-disable-next-line
      if (isNaN(number)) {
        resolve(null);
      }
      resolve(Math.ceil(parseFloat(number)));
    } catch (e) {
      resolve(null);
    }
  });
};

const parseMinutes = async (number: any) => {
  if (number === null) {
    return undefined;
  }
  return new Promise((resolve: any) => {
    try {
      // eslint-disable-next-line
      if (isNaN(number)) {
        resolve(null);
      }
      resolve(Math.ceil(parseFloat(number) * 60));
    } catch (e) {
      resolve(null);
    }
  });
};

const toCoords = (string: any) => {
  try {
    const coords = string.split(',');

    // eslint-disable-next-line
    if (coords.length === 2 && !isNaN(coords[0]) && !isNaN(coords[1])) {
      const lat = parseFloat(coords[0]);
      const lng = parseFloat(coords[1]);
      return [lat, lng];
    }
  } catch (error) {
    return undefined;
  }
  return undefined;
};

const parseAddress = async (address: any, { countryCode } = { countryCode: '' }) => {
  if (address === null) {
    return undefined;
  }
  try {
    // Check if address are coordinates
    const coordinates = toCoords(address);
    let response;
    if (coordinates) {
      response = await getLocationFromLatLng(coordinates[0], coordinates[1]);
      return response;
    }
    response = await postGeocode([address], countryCode);
    return response.data[0];
  } catch (e) {
    return null;
  }
};

const expRegex = '^([0-9]|0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$';

const toSeconds = (hour: any) => {
  const regex1 = new RegExp(expRegex);
  if (hour.match(regex1) === null) {
    return null;
  }
  const hhmm = hour.split(':');
  return Number.parseInt(hhmm[0], 10) * 60 * 60 + Number.parseInt(hhmm[1], 10) * 60;
};

const parseTimewindows = (timewindows: any) => {
  if (timewindows === null) {
    return undefined;
  }
  try {
    const splitted = timewindows.split(';');
    const arrayTW = splitted.map((val: any) => {
      const timeStart = toSeconds(val.split('-')[0]);
      let timeEnd = toSeconds(val.split('-')[1]);
      if (timeStart === null || timeEnd === null) {
        return undefined;
      }
      // Adding 24 hours if timeStart is higher than timeEnd. Each time is from different days (maximum 1 day).
      timeEnd = timeStart > timeEnd ? timeEnd + 3600 * 24 : timeEnd;
      return [timeStart, timeEnd];
    });
    if (arrayTW.includes(null)) {
      return undefined;
    }
    return arrayTW.filter((tw: any) => tw);
  } catch (e) {
    return [];
  }
};

const parseList = (list: any) => {
  if (list === null) {
    return undefined;
  }
  try {
    const string = (isNumeric(list) ? `${list}` : list).trim();
    return string.split(',').map((v: any) => v.trim());
  } catch (e) {
    return null;
  }
};

const parseTimewindow = (timewindow: any) => {
  if (timewindow === null) {
    return undefined;
  }
  try {
    const timeStart = toSeconds(timewindow.split('-')[0]);
    let timeEnd = toSeconds(timewindow.split('-')[1]);
    if (timeStart === null || timeEnd === null) {
      return [];
    }
    // Adding 24 hours if timeStart is higher than timeEnd. Each time is from different days (maximum 1 day).
    timeEnd = timeStart > timeEnd ? timeEnd + 3600 * 24 : timeEnd;
    return [timeStart, timeEnd];
  } catch (e) {
    return [];
  }
};

/**
 * This functions adds modifications to a given text. Modifications are:
 * - Trim
 * - Normalize accents/diacritics
 * - UpperCase
 * @param {*} text
 */
export const normalizeString = (text: any) => {
  const trimmedText = text.trim();
  const normalizedText = trimmedText.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  const upperedText = normalizedText.toUpperCase();
  return upperedText;
};

const customFieldsArray = (customFields: any) => [
  'custom_fields',
  [
    ...customFields.map((field: any) => {
      let parseFunction;
      switch (field.type) {
        case 'text':
          parseFunction = parseString;
          break;
        case 'numerical':
          parseFunction = parseNumber;
          break;
        case 'boolean':
          parseFunction = parseBoolean;
          break;
        case 'categorical':
          parseFunction = async (rawValue: any) => {
            if (rawValue === null) return undefined;

            const value = await parseString(rawValue);

            // Extract the values that are splitted using semicolons.
            let fieldDataValues = await parseListSemicolon(value);

            // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
            fieldDataValues = fieldDataValues.map((fieldValue: any) => normalizeString(fieldValue));

            // Value must be an array of strings.
            if (
              !Array.isArray(fieldDataValues) ||
              !!fieldDataValues.find((val: any) => typeof val !== `string`)
            )
              return null;

            // Value have multiple options selected but field does not allow multiple selection.
            if (fieldDataValues.length > 1 && !field.multiple) return null;

            const fieldOptions = field.options.map((option: any) => normalizeString(option));

            if (fieldDataValues.find((val: any) => !fieldOptions.includes(normalizeString(val))))
              return null;

            const selectedOptions: any = [];
            fieldOptions.forEach((option: any, index: any) => {
              // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
              if (fieldDataValues.includes(option)) {
                selectedOptions.push(index.toString());
              }
            });

            return selectedOptions;
          };
          break;
        default:
          return [];
      }
      return [`custom_fields.${field.id}`, parseFunction, [field.id, field.label], field.label];
    }),
  ],
];

const servicesExcelColumns = {
  label: [
    'NAME',
    'NOMBRE',
    'CLIENT NAME',
    'NOMBRE DE CLIENTE',
    'DRIVER',
    'DRIVER // PLATE',
    'CONDUCTOR',
    'CONDUCTOR // MATRICULA',
  ],
  external_id: [
    'EXTERNAL ID/CLIENT NUMBER',
    'EXTERNAL ID',
    'CLIENT NUMBER',
    'ID EXTERNO/NUMERO DE CLIENTE',
    'ID EXTERNO',
    'NUMERO DE CLIENTE',
  ],
  location: ['LOCATION 1', 'LOCATION', 'DIRECTION 1', 'DIRECTION', 'DIRECCION', 'DIRECCION 1'],
  location_details: ['LOCATION 2', 'DIRECTION 2', 'DIRECCION 2'],
  comments: ['COMMENTS', 'COMENTARIOS'],
  phone: ['PHONE', 'TELEFONO'],
  email: ['EMAIL', 'E-MAIL'],
  website: ['WEBSITE', 'WEB SITE', 'PAGINA WEB'],
  reference_person: ['REFERENCE PERSON', 'CONTACT PERSON', 'PERSONA DE CONTACTO'],
  duration: ['DURATION', 'DURACION'],
  requires: ['REQUIRES', 'REQUISITOS'],
  timewindows: [
    'TIME WINDOWS',
    'TIMEWINDOWS',
    'VENTANAS DE TIEMPO',
    'HORAS DE RECEPCION',
    'HORARIO',
    'HORARIOS',
  ],
  weight: ['WEIGHT', 'PESO'],
  volume: ['VOLUME', 'VOLUMEN'],
  pickup: {
    label: ['NOMBRE DE RECOGIDA', 'RECOGIDA', 'NOMBRE RECOGIDA', 'PICKUP', 'PICKUP NAME'],
    location: ['DIRECCION DE RECOGIDA', 'DIRECCION RECOGIDA', 'PICKUP LOCATION', 'PICKUP ADDRESS'],
    external_id: ['ID DEL PICKUP', 'ID PICKUP', 'PICKUP ID'],
    duration: ['PICKUP DURATION', 'DURACION PICKUP', 'DURACION DEL PICKUP'],
    phone: ['PICKUP PHONE', 'TELEFONO RECOGIDA'],
    comments: ['PICKUP COMMENTS', 'COMENTARIOS PICKUP', 'COMENTARIOS DEL PICKUP'],
    timewindows: [
      'PICKUP TIME WINDOWS',
      'PICKUP TIMEWINDOWS',
      'VENTANAS DE TIEMPO PICKUP',
      'HORARIO PICKUP',
      'HORAS DE RECOGIDA',
    ],
  },
};

const getServicesFieldArray = () => {
  const { activeProject } = store.getState().projects;
  if (activeProject) {
    return [
      [
        'general',
        [
          ['label', parseString, servicesExcelColumns.label],
          ['external_id', parseString, servicesExcelColumns.external_id],
          ['location', parseAddress, servicesExcelColumns.location],
          ['location_details', parseString, servicesExcelColumns.location_details],
        ],
      ],
      [
        'contact',
        [
          ['comments', parseString, servicesExcelColumns.comments],
          ['phone', parseString, servicesExcelColumns.phone],
          ['email', parseString, servicesExcelColumns.email],
          ['website', parseString, servicesExcelColumns.website],
          ['reference_person', parseString, servicesExcelColumns.reference_person],
        ],
      ],
      [
        'constraints',
        [
          ['duration', parseMinutes, servicesExcelColumns.duration],
          // ['reward', parseInt],
          ['requires', parseList, servicesExcelColumns.requires],
          // ['cluster', parseString],
          // ['assign_to', parseString],
          ['timewindows', parseTimewindows, servicesExcelColumns.timewindows],
          ['weight', parseNumber, servicesExcelColumns.weight],
          ['volume', parseNumber, servicesExcelColumns.volume],
        ],
      ],
      [
        'pickups',
        [
          ['pickup.label', parseString, servicesExcelColumns.pickup.label],
          ['pickup.location', parseAddress, servicesExcelColumns.pickup.location],
          ['pickup.external_id', parseString, servicesExcelColumns.pickup.external_id],
          ['pickup.duration', parseMinutes, servicesExcelColumns.pickup.duration],
          ['pickup.phone', parseString, servicesExcelColumns.pickup.phone],
          ['pickup.comments', parseString, servicesExcelColumns.pickup.comments],
          ['pickup.timewindows', parseTimewindows, servicesExcelColumns.pickup.timewindows],
        ],
      ],
      ...(activeProject.custom_fields
        ? [customFieldsArray(activeProject.custom_fields.client)]
        : []),
    ];
  }
  return [];
};

const clientsExcelColumns = {
  label: [
    'NAME',
    'NOMBRE',
    'CLIENT NAME',
    'NOMBRE DE CLIENTE',
    'NAME OF CLIENT',
    'NOMBRE DEL CLIENTE',
    'NAME CLIENT',
    'NOMBRE CLIENTE',
  ],
  external_id: [
    'EXTERNAL ID/CLIENT NUMBER',
    'EXTERNAL ID',
    'CLIENT NUMBER',
    'ID EXTERNO/NUMERO DE CLIENTE',
    'ID EXTERNO',
    'NUMERO DE CLIENTE',
  ],
  location: ['LOCATION 1', 'LOCATION', 'DIRECTION 1', 'DIRECTION', 'DIRECCION', 'DIRECCION 1'],
  location_details: ['LOCATION 2', 'DIRECTION 2', 'DIRECCION 2'],
  comments: ['COMMENTS', 'COMENTARIOS'],
  phone: ['PHONE', 'TELEFONO'],
  email: ['EMAIL', 'E-MAIL'],
  website: ['WEBSITE', 'WEB SITE', 'PAGINA WEB'],
  reference_person: ['REFERENCE PERSON', 'CONTACT PERSON', 'PERSONA DE CONTACTO'],
  default_duration: ['DURATION', 'DURACION'],
  default_requires: ['REQUIRES', 'REQUISITOS'],
  default_timewindows: [
    'TIME WINDOWS',
    'TIMEWINDOWS',
    'VENTANAS DE TIEMPO',
    'HORAS DE RECEPCION',
    'HORARIO',
    'HORARIOS',
  ],
  default_weight: ['WEIGHT', 'PESO'],
  default_volume: ['VOLUME', 'VOLUMEN'],
};

const getClientFieldsArray = () => {
  const { activeProject } = store.getState().projects;
  if (activeProject) {
    return [
      [
        'general',
        [
          ['label', parseString, clientsExcelColumns.label],
          ['external_id', parseString, clientsExcelColumns.external_id],
          ['location', parseAddress, clientsExcelColumns.location],
          ['location_details', parseString, clientsExcelColumns.location_details],
        ],
      ],
      [
        'contact',
        [
          ['comments', parseString, clientsExcelColumns.comments],
          ['phone', parseString, clientsExcelColumns.phone],
          ['email', parseString, clientsExcelColumns.email],
          ['website', parseString, clientsExcelColumns.website],
          ['reference_person', parseString, clientsExcelColumns.reference_person],
        ],
      ],
      [
        'constraints',
        [
          ['default_duration', parseMinutes, clientsExcelColumns.default_duration],
          // ['default_reward', parseInt],
          ['default_requires', parseList, clientsExcelColumns.default_requires],
          // ['default_cluster', parseString],
          // ['default_assign_to', parseString],
          ['default_timewindows', parseTimewindows, clientsExcelColumns.default_timewindows],
          ['default_weight', parseNumber, clientsExcelColumns.default_weight],
          ['default_volume', parseNumber, clientsExcelColumns.default_volume],
        ],
      ],
      ...(activeProject.custom_fields
        ? [customFieldsArray(activeProject.custom_fields.client)]
        : []),
    ];
  }
  return [];
};

const routesExcelColumns = {
  label: [
    'NAME',
    'NOMBRE',
    'CLIENT NAME',
    'NOMBRE DE CLIENTE',
    'DRIVER',
    'DRIVER // PLATE',
    'CONDUCTOR',
    'CONDUCTOR // MATRICULA',
  ],
  start_location: ['START LOCATION', 'PUNTO DONDE COMIENZA'],
  end_location: ['END LOCATION', 'PUNTO FINAL'],
  plate: ['PLATE', 'MATRICULA'],
  phone: ['PHONE', 'TELEFONO'],
  email: ['EMAIL', 'E-MAIL', 'CORREO'],
  provides: ['PROVIDES', 'PROVEE'],
  timewindows: ['WORKING HOURS', 'HORAS DE TRABAJO'],
  max_distance: ['MAX DISTANCE', 'DISTANCIA MAXIMA'],
  max_weight: ['MAX WEIGHT', 'PESO MAX'],
  max_volume: ['MAX VOL', 'MAXIMUM VOLUME', 'VOL MAX', 'VOLUMEN MAXIMO'],
  max_services: ['MAX SERVICES', 'MAXIMUM SERVICES', 'SERVICIOS MAX', 'SERVICIOS MAXIMOS'],
  price_per_minute: ['PRICE PER HOUR', 'PRECIO POR HORA'],
  price_per_distance: ['PRICE PER DISTANCE', 'PRECIO POR DISTANCIA'],
};

const getRoutesFieldArray = () => {
  const { activeProject } = store.getState().projects;
  if (activeProject) {
    return [
      [
        'general',
        [
          ['label', parseString, routesExcelColumns.label],
          ['external_id', parseString],
          ['start_location', parseAddress, routesExcelColumns.start_location],
          ['end_location', parseAddress, routesExcelColumns.end_location],
          ['price_per_minute', parseNumber, vehiclesExcelColumns.price_per_minute],
          ['price_per_distance', parseNumber, vehiclesExcelColumns.price_per_distance],
        ],
      ],
      [
        'contact',
        [
          ['plate', parseString, routesExcelColumns.plate],
          ['phone', parseString, routesExcelColumns.phone],
          ['email', parseString, routesExcelColumns.email],
        ],
      ],
      [
        'constraints',
        [
          ['provides', parseList, routesExcelColumns.provides],
          ['timewindow', parseTimewindow, routesExcelColumns.timewindows],
          ['max_distance', parseNumber, routesExcelColumns.max_distance],
          ['max_weight', parseNumber, routesExcelColumns.max_weight],
          ['max_volume', parseNumber, routesExcelColumns.max_volume],
          ['max_services', parseInt, routesExcelColumns.max_services],
        ],
      ],
      ...(activeProject.custom_fields
        ? [customFieldsArray(activeProject.custom_fields.vehicle)]
        : []),
    ];
  }
  return [];
};

const vehiclesExcelColumns = {
  label: [
    'NAME',
    'NOMBRE',
    'CLIENT NAME',
    'NOMBRE DE CLIENTE',
    'DRIVER',
    'DRIVER // PLATE',
    'CONDUCTOR',
    'CONDUCTOR // MATRICULA',
  ],
  default_start_location: ['START LOCATION', 'PUNTO DONDE COMIENZA'],
  default_end_location: ['END LOCATION', 'PUNTO FINAL'],
  plate: ['PLATE', 'MATRICULA'],
  phone: ['PHONE', 'TELEFONO'],
  email: ['EMAIL', 'E-MAIL', 'CORREO'],
  default_provides: ['PROVIDES', 'PROVEE'],
  default_timewindow: ['WORKING HOURS', 'HORAS DE TRABAJO'],
  default_max_distance: ['MAX DISTANCE', 'DISTANCIA MAX'],
  default_max_weight: ['MAX WEIGHT', 'PESO MAX'],
  default_max_volume: ['MAX VOL', 'MAXIMUM VOLUME', 'VOL MAX', 'VOLUMEN MAXIMO'],
  default_max_services: ['MAX SERVICES', 'MAXIMUM SERVICES', 'SERVICIOS MAX', 'SERVICIOS MAXIMOS'],
  price_per_minute: ['PRICE PER HOUR', 'PRECIO POR HORA'],
  price_per_distance: ['PRICE PER DISTANCE', 'PRECIO POR DISTANCIA'],
};

const getVehicleFieldsArray = () => {
  const { activeProject } = store.getState().projects;
  if (activeProject) {
    return [
      [
        'general',
        [
          ['label', parseString, vehiclesExcelColumns.label],
          ['default_start_location', parseAddress, vehiclesExcelColumns.default_start_location],
          ['default_end_location', parseAddress, vehiclesExcelColumns.default_end_location],
          ['price_per_minute', parseNumber, vehiclesExcelColumns.price_per_minute],
          ['price_per_distance', parseNumber, vehiclesExcelColumns.price_per_distance],
        ],
      ],
      [
        'contact',
        [
          ['plate', parseString, vehiclesExcelColumns.plate],
          ['phone', parseString, vehiclesExcelColumns.phone],
          ['email', parseString, vehiclesExcelColumns.email],
        ],
      ],
      [
        'constraints',
        [
          ['default_provides', parseList, vehiclesExcelColumns.default_provides],
          ['default_timewindow', parseTimewindow, vehiclesExcelColumns.default_timewindow],
          ['default_max_distance', parseNumber, vehiclesExcelColumns.default_max_distance],
          ['default_max_weight', parseNumber, vehiclesExcelColumns.default_max_weight],
          ['default_max_volume', parseNumber, vehiclesExcelColumns.default_max_volume],
          ['default_max_services', parseInt, vehiclesExcelColumns.default_max_services],
        ],
      ],
      ...(activeProject.custom_fields
        ? [customFieldsArray(activeProject.custom_fields.vehicle)]
        : []),
    ];
  }
  return [];
};

export {
  s2ab,
  saveData,
  parseAddressToApi,
  isNumeric,
  parseInt,
  parseAddress,
  parseList,
  parseTimewindows,
  parseNumber,
  parseString,
  getServicesFieldArray,
  getRoutesFieldArray,
  getClientFieldsArray,
  getVehicleFieldsArray,
  parseDataToExcel,
};
