import { Chip, Grid, Link, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Button, VSpacer } from '@sm-highway-web/react-components';
import { ISubscriptionBase } from 'highway-api/dist/common/interfaces/subscriptions';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import TruckIcon from '../assets/svg/TruckIcon';
import * as routing from '../constants/Routing';
import { PLAN_TYPE_ICON } from '../constants/Subscriptions';
import history from '../helpers/History';
import { calculateTimeLeft } from '../helpers/TimeDistance';
import { IOrganizationData } from '../interfaces/organizations';
import { IUserData } from '../interfaces/users';
import { Common } from '../redux/actions';
import FormattedMessage from './FormattedMessageCustom';

const PLANS = {
  monkey: {
    label: 'Monkey',
    bgColor: 'rgb(5, 13, 30)',
    color: 'white',
    avatar: (
      <span
        role="img"
        aria-label="monkey"
        style={{ fontSize: '18px', height: '18px', width: '18px' }}
      >
        {PLAN_TYPE_ICON.MONKEY}
      </span>
    ),
  },
  gorilla: {
    label: 'Gorilla',
    bgColor: 'white',
    color: 'initial',
    avatar: (
      <span
        role="img"
        aria-label="gorilla"
        style={{ fontSize: '18px', height: '18px', width: '18px' }}
      >
        {PLAN_TYPE_ICON.GORILLA}
      </span>
    ),
  },
  kong: {
    label: 'Kong',
    bgColor: 'rgb(255, 130, 0)',
    color: 'white',
    avatar: (
      <span
        role="img"
        aria-label="kong"
        style={{ fontSize: '18px', height: '18px', width: '18px' }}
      >
        {PLAN_TYPE_ICON.KONG}
      </span>
    ),
  },
};

type PlanTypeBadgeProps = {
  subscription: ISubscriptionBase;
};

const PlanTypeBadge = ({ subscription }: PlanTypeBadgeProps) => {
  if (
    subscription.status === 'active' &&
    new Date() < new Date(subscription.valid) &&
    subscription.plan &&
    subscription.plan.type
  ) {
    const plan = PLANS[subscription.plan.type];
    if (plan) {
      return (
        <Chip
          avatar={plan.avatar}
          label={plan.label}
          variant="outlined"
          style={{ color: plan.color, backgroundColor: plan.bgColor }}
        />
      );
    }
  }
  return null;
};

PlanTypeBadge.defaultProps = {
  subscription: undefined,
};

const styles = () => ({
  allWidth: {
    width: '100%',
  },
  truckicon: {
    width: '22px',
    height: '22px',
    color: 'grey',
  },
});

type OwnUserProfileSummaryProps = {
  classes: {
    allWidth: string;
    truckicon: string;
  };
  user: IUserData;
  organization: IOrganizationData;
  actions: {
    openPaymentModal: (...args: any[]) => any;
  };
};

type UserProfileSummaryProps = OwnUserProfileSummaryProps;

class UserProfileSummary extends Component<UserProfileSummaryProps> {
  static defaultProps = {};

  handleOpenPaymenRunwayModal = () => {
    const { actions } = this.props;
    actions.openPaymentModal();
  };

  render() {
    const { classes, user, organization } = this.props;
    return (
      <div className={classes.allWidth}>
        <Typography align="center" variant="h4">
          <>
            {user.first_name}
            <br />
            {user.last_name}
          </>
        </Typography>
        <VSpacer medium />
        {organization.subscription && organization.subscription.status === 'active' && (
          <>
            <Grid container spacing={1} alignItems="center">
              <Grid item xs={4}>
                <Typography variant="h6" align="right">
                  {`${organization.subscription.plan.vehicles} `}
                </Typography>
              </Grid>
              <Grid item xs={2}>
                <TruckIcon className={classes.truckicon} />
              </Grid>
              <Grid item xs={6}>
                <PlanTypeBadge subscription={organization.subscription} />
              </Grid>
            </Grid>
          </>
        )}
        {organization.subscription && organization.subscription.status === 'trialing' && (
          <>
            <Typography align="center" style={{ fontSize: '16px' }}>
              <FormattedMessage id="userprofilesummary.trial" /> 
              {' '}
              {/* eslint-disable-next-line */}
              <Link
                component="button"
                onClick={(e: any) => {
                  e.stopPropagation();
                  // @ts-expect-error ts-migrate(2339) FIXME: Property 'SUBSCRIPTION' does not exist on type '{ ... Remove this comment to see the full error message
                  history.push(routing.SETTINGS.ACCOUNT.SUBSCRIPTION);
                }}
                underline="none"
                style={{ fontSize: '12px' }}
              >
                <FormattedMessage
                  id="userprofilesummary.trialvalue"
                  values={{
                    value:
                      calculateTimeLeft(organization.subscription.valid) > 0
                        ? calculateTimeLeft(organization.subscription.valid)
                        : 0,
                  }}
                />
              </Link>
            </Typography>
            <VSpacer medium />
            <Grid container spacing={1}>
              <Grid item lg={6}>
                <Typography variant="h6" align="right">
                  {`${organization.subscription.plan.vehicles} `}
                </Typography>
              </Grid>
              <Grid item lg={6}>
                <TruckIcon className={classes.truckicon} />
              </Grid>
            </Grid>
          </>
        )}
        {organization.subscription && organization.subscription.status === 'trialing' && (
          <>
            <VSpacer medium />
            <Grid container justify="center">
              <Button
                variant="contained"
                onClick={(event: any) => {
                  event.preventDefault();
                  event.stopPropagation();
                  this.handleOpenPaymenRunwayModal();
                }}
              >
                <FormattedMessage id="userprofilesummary.upgradeaccount" />
              </Button>
            </Grid>
          </>
        )}
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    openPaymentModal: () => dispatch(Common.openPaymentModal()),
  },
});

export default connect(null, mapDispatchToProps)(withStyles(styles)(UserProfileSummary));
