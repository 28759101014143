import { CircularProgress } from '@material-ui/core';
import { AddAPhoto, Clear } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import { getUploadAsBase64 } from '../services/requests';
import ImageUpload, { UPLOADER_ID } from './ImageUpload';
import {
  AddImageContainer,
  ClearButton,
  ContainerInputs,
  ImageContainer,
  ImageContainerLoader,
} from './settings/SettingsStyled';

type AvatarManagerProps = {
  avatarUrl?: string | null;
  onRemoveAvatar: (...args: any[]) => any;
  onAvatarUpload: (...args: any[]) => any;
};

let uploadTimeout: any;
let removeTimeout: any;

const AvatarManager = ({ avatarUrl, onRemoveAvatar, onAvatarUpload }: AvatarManagerProps) => {

  const [avatarImage, setAvatarImage] = useState<string | null>('');
  const [isLoadingImage, setIsLoadingImage] = useState(false);

  useEffect(() => {
    return () => {
      if(uploadTimeout) {
        clearTimeout(uploadTimeout);
        uploadTimeout = undefined;
      }
      if(removeTimeout) {
        clearTimeout(removeTimeout);
        removeTimeout = undefined;
      }
    };
  }, []);

  useEffect(() => {
    (async () => {
      if(avatarUrl) {
        setIsLoadingImage(true);
        const retrievedAvatar = await getUploadAsBase64(avatarUrl);
        setAvatarImage(retrievedAvatar);
      }
      setIsLoadingImage(false);
    })();
  }, [avatarUrl]);

  const handleRemovePhoto = async () => {
    setIsLoadingImage(true);
    onRemoveAvatar();
    // This is a hack for removing setting the isLoading to false only if remove query has failed.
    removeTimeout = setTimeout(() => {
      if(isLoadingImage)setIsLoadingImage(false);
      removeTimeout = undefined;
    }, 10000);
  };

  const uploadAvatarImage = async(fileName: string, imageType: string, image: Blob) => {
    setIsLoadingImage(true);
    onAvatarUpload(fileName, imageType, image);
    // This is a hack for removing setting the isLoading to false only if upload query has failed.
    uploadTimeout = setTimeout(() => {
      if(isLoadingImage)setIsLoadingImage(false);
      uploadTimeout = undefined;
    }, 10000);
  };

  return (
    <ContainerInputs>
      {isLoadingImage ? (
        <ImageContainerLoader>
          <CircularProgress size={32} />
        </ImageContainerLoader>
      ) : (
        <>
          {!avatarUrl ? (
            <>
              <AddImageContainer onClick={() => {document.getElementById(UPLOADER_ID)?.click();}}>
                <AddAPhoto />
              </AddImageContainer>
              <ImageUpload crop onImageUploaded={uploadAvatarImage} />
            </>
          ) : (
            <div style={{ position: 'relative', marginRight: '6px' }}>
              <ImageContainer imgUri={avatarImage} />
              <div style={{ position: 'absolute', left: '-4px', top: '-4px' }}>
                <ClearButton>
                  <Clear
                    style={{ fontSize: '18px' }}
                    onClick={handleRemovePhoto}
                  />
                </ClearButton>
              </div>
            </div>
          )}
        </>
      )}
    </ContainerInputs>
  );
};

export default AvatarManager;
