import {
  Box,
  Grid,
  Tooltip,
  Typography,
} from '@material-ui/core';
import LinearProgress, { LinearProgressProps } from '@material-ui/core/LinearProgress';
import { VSpacer } from '@sm-highway-web/react-components';
import { IProjectData, IServiceTrackingEmail, IServiceTrackingEmailTypes } from 'highway-api/dist/common/interfaces/projects';
import { ISubscriptionBase } from 'highway-api/dist/common/interfaces/subscriptions';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { intl } from '../../../../helpers/IntGlobalProvider';
import { getSubscriptionEmailsUsage } from '../../../../helpers/Subscription';
import { dateFormatLongDate } from '../../../../helpers/TimeDistance';
import { IUserData } from '../../../../interfaces/users';
import Froged from '../../../../services/froged';
import FormattedMessage from '../../../FormattedMessageCustom';
import { CustomLink } from './ProjectCommunication.styled';
import ProjectCommunicationEmailsTemplate from './ProjectCommunicationEmailsTemplate';

interface LinearProgressWithLabelProps extends LinearProgressProps {
  used: number;
  total: number;
}

const LinearProgressWithLabel = ({ used, total }: LinearProgressWithLabelProps) => {
  let value = getSubscriptionEmailsUsage(used, total);
  value = value <= 100 ? value : 100;
  return (
    <Tooltip
      placement="top"
      title={
        <FormattedMessage
          id="settings.organization.projects.edit.communication.emails.usage"
          values={{
            used,
            total,
          }}
        />
      }
    >
      <Box display="flex" alignItems="center">
        <Box width="100%" mr={1}>
          <LinearProgress variant="determinate" value={value} style={{ height: '16px', borderRadius: '50px' }} />
        </Box>
        {value ?
            <Box minWidth={35}>
              <Typography variant="body2" color="textSecondary">{`${Math.round(value)}%`}</Typography>
            </Box>
          : null}
      </Box>
    </Tooltip>
  );
};

type ProjectCommunicationEmailsProps = {
  user?: IUserData;
  project: IProjectData;
  organizationSubscription?: ISubscriptionBase;
};

const ProjectCommunicationEmails = ({
  user,
  project,
  organizationSubscription,
}: ProjectCommunicationEmailsProps) => {
  const [serviceTrackingEmails, setServiceTrackingEmails] = useState<IServiceTrackingEmailTypes[]>([]);
  const [selectedEmailType, setSelectedEmailType] = useState<IServiceTrackingEmailTypes | undefined>();

  useEffect(() => {
    if (project?.service_tracking_email) {
      const trackingEmails: IServiceTrackingEmailTypes[] = [];
      Object.keys(project.service_tracking_email).forEach((emailType: string) => {

        // Only showing the pickup emails if pickups are available in priject view constraints.
        if (!(
          emailType === `pickup_approaching` ||
          emailType === `pickup_completed` ||
          emailType === `pickup_canceled`
        ) || project.view?.constraints.pickups) {
          if (project.service_tracking_email) {
            trackingEmails.push(emailType as IServiceTrackingEmailTypes);
          }
        }
      });
      if(!selectedEmailType && trackingEmails.length > 0) {
        setSelectedEmailType(trackingEmails[0]);
      }
      setServiceTrackingEmails(trackingEmails);
    }
  }, [project, selectedEmailType]);

  const isTemplateEnabled = (emailType: IServiceTrackingEmailTypes) => {
    return (project.service_tracking_email as IServiceTrackingEmail)[emailType]?.enabled;
  };

  if (!project) return null;

  return (
    <Grid container direction="row" wrap="nowrap">
      <Grid item container direction="column" style={{ padding: '12px 8px', width: '350px' }} wrap="nowrap">
        <Grid item container direction="column">
          <Grid item container style={{ backgroundColor: '#F1F1F1', borderRadius: '12px', padding: '16px' }}>
            <Grid item>
              <Typography variant="h6" style={{ color: '#707070', fontSize: '16px' }}>
                <FormattedMessage id="settings.organization.projects.edit.communication.emails.usage_title" />
              </Typography>
            </Grid>

            {organizationSubscription?.plan ?
                <Grid item style={{ width: '100%' }}>
                  <LinearProgressWithLabel
                    variant="determinate"
                    used={organizationSubscription.plan.customer_emails_count}
                    total={organizationSubscription.plan.max_customer_emails}
                  />

                  <VSpacer small />

                  <span style={{ fontSize: '12px', color: '#707070' }}>
                    <FormattedMessage
                      tagName="span"
                      id="settings.organization.projects.edit.communication.emails.current_period_ends"
                      values={{ date: dateFormatLongDate(organizationSubscription.valid) }}
                    />        
                  </span>

                  <VSpacer small />

                  <CustomLink style={{ fontSize: '14px' }} onClick={() => Froged.message(intl.formatMessage({ id: 'settings.organization.projects.edit.communication.emails.more_emails.text' }))}>
                    <FormattedMessage
                      tagName="span"
                      id="settings.organization.projects.edit.communication.emails.more_emails"
                    />
                  </CustomLink>
                </Grid>
              : null}
          </Grid>
          <VSpacer small />
        </Grid>

        <Grid 
          container 
          direction="column"
        >
          {serviceTrackingEmails.map((emailType: any, index: number) => (
            <Grid
              key={`email-${index}`}
              container 
              direction="row" 
              style={{
                cursor: 'pointer',
                border: '1px solid #F1F1F1',
                borderBottom: '1px solid #F1F1F1',
                backgroundColor: (selectedEmailType === emailType ? '#FFE6CC' : 'white'),
                ...(index === 0 ? { borderTop: '1px solid #F1F1F1' } : { borderTop: 'unset' }),
              }}
              onClick={() => setSelectedEmailType(emailType)}
            >
              <Grid item container xs={2} justify="center" alignItems="center">
                <span
                  style={{
                    width: '20px',
                    height: '20px',
                    borderRadius: '50%',
                    backgroundColor: isTemplateEnabled(emailType) ? '#CBFFB2' : '#FFB2B2',
                  }}
                />
              </Grid>

              <Grid item container direction="column" wrap="nowrap" justify="center" xs={10} alignItems="flex-start" style={{ height: '80px' }}>
                <Grid item>
                  <span style={{ fontSize: '16px', color: '#404040' }}>
                    <FormattedMessage tagName="span" id={`settings.organization.projects.edit.communication.emails.${emailType}`} />
                  </span>
                </Grid>

                <Grid item>
                  <span style={{ fontSize: '12px', color: '#707070' }}>
                    <FormattedMessage
                      tagName="span"
                      id={`settings.organization.projects.edit.communication.emails.${emailType}.description`}
                    />
                  </span>
                </Grid>
              </Grid>
            </Grid>
          ))}
        </Grid>

        <VSpacer medium />

        <Grid item container justify="center">
          <Grid item style={{ textAlign: 'center' }}>
            <span style={{ color: '#707070', fontSize: '11px' }}>
              <FormattedMessage tagName="span" id="settings.organization.projects.edit.communication.emails.help_message" />
            </span>
          </Grid>

          <Grid item>
            <CustomLink 
              style={{ fontSize: '11px' }}
              onClick={() => window.open(`https://support.smartmonkey.io/docs/${user?.language === 'es-es' ? 'es': 'en'}/98932623-comunicacion-con-clientes`, '_blank')}
            >
              <FormattedMessage tagName="span" id="settings.organization.projects.edit.communication.visit_help_page" />
            </CustomLink>
          </Grid>
        </Grid>
      </Grid>

      <Grid item container direction="column" style={{ flex: '1', padding: '12px 8px', backgroundColor: '#F1F1F1' }}>
        {selectedEmailType ? <ProjectCommunicationEmailsTemplate emailType={selectedEmailType} userEmail={user?.email} project={project} /> : null}
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state: any) => {
  return { user: state.user.user };
};

export default connect(
  mapStateToProps,
  null
)(ProjectCommunicationEmails);
