import createSvgIcon from '@material-ui/icons/utils/createSvgIcon';
import React from 'react';

export default createSvgIcon(
  <>
    <path d="m20.8 5.8c-2-2.8-5.2-4.6-8.9-4.6-5.5 0-10.1 4.1-10.7 9.5h2.8c0.6-3.8 4-6.8 8-6.8 2.9 0 5.5 1.6 6.9 3.9l-2.9 2.8h6.8v-6.7l-2 1.9z" />
    <path d="M20,13.4c-0.6,3.8-4,6.8-8,6.8c-2.9,0-5.5-1.6-6.9-3.9L8,13.4H1.2v6.8l1.9-1.9c2,2.8,5.2,4.6,8.9,4.6  c5.5,0,10.1-4.1,10.7-9.5H20z" />
  </>,
  'ReloadIcon'
);
