import { Fab, Grid, Hidden, SwipeableDrawer } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { KeyboardArrowRight } from '@material-ui/icons';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Route, Switch } from 'react-router-dom';
import FormattedMessage from '../components/FormattedMessageCustom';
import AccountPaymentMethod from '../components/settings/account/AccountPaymentMethod';
import AccountProfile from '../components/settings/account/AccountProfile';
import BillingInfo from '../components/settings/billing/BillingInfo';
import BillingInvoices from '../components/settings/billing/BillingInvoices';
import BillingSubscriptions from '../components/settings/billing/BillingSubscriptions';
import IntegrationsKeys from '../components/settings/integration/IntegrationsKeys';
import IntegrationsWebhooks from '../components/settings/integration/IntegrationsWebhooks';
import OrganizationProfile from '../components/settings/organization/OrganizationProfile';
import OrganizationUsers from '../components/settings/organization/OrganizationUsers';
import OrganizationProjects from '../components/settings/projects/OrganizationProjects';
import OrganizationProjectsEdit from '../components/settings/projects/OrganizationProjectsEdit';
import SettingsLeftMenu from '../components/settings/SettingsLeftMenu';
import * as routing from '../constants/Routing';
import settingsItems from '../constants/SettingsItems';
import NormalDialog from '../dialogs/NormalDialog';
import { userHelpers } from '../redux/helpers';
import configuration from '../services/configuration';
import eventsPanel, { PageViewPages } from '../services/events';

const styles = {
  fab: {
    position: 'absolute',
    top: '90px',
    left: '-25px',
    borderRadius: '50%',
    paddingLeft: '10px',
  },
  allHeight: {
    minHeight: 'calc(100vh)',
    width: 'calc(100vw - 48px)',
  },
  leftMenu: {
    padding: '24px',
    backgroundColor: 'rgba(234,234,234,0.2)',
    boxShadow: 'inset -1px 0 1px 1px #EAEAEA',
    height: 'calc(100vh)',
    width: '300px',
    overflowY: 'auto',
  },
  leftMenuSwipeable: {
    padding: '24px',
    backgroundColor: 'rgba(234,234,234,0.2)',
    height: '100vh',
  },
  rightContainer: {
    padding: '24px',
    overflowY: 'auto',
    height: 'calc(100vh)',
    width: 'calc(100vw - 300px - 48px)',
  },
  rightContainerMobile: {
    padding: '24px',
    overflowY: 'auto',
    height: 'calc(100vh)',
  },
};

type SettingsProps = {
  classes: {
    allHeight: string;
    rightContainer: string;
    rightContainerMobile: string;
    leftMenu: string;
    leftMenuSwipeable: string;
    fab: string;
  };
  location: {
    pathname: string;
  };
  history: {
    push: (...args: any[]) => any;
  };
  planType?: string;
};

const Settings = ({ classes, location, history, planType }: SettingsProps) => {
  const [open, setOpen] = useState(false);
  const [dirtyState, setDirtyState] = useState(false);
  const [currentPathname, setCurrentPathname] = useState('');
  const [routeSelected, setRouteSelected] = useState(undefined);
  const [modalChengesNotSaved, setModalChengesNotSaved] = useState(false);
  const stripePromise = loadStripe(configuration.STRIPE_API_KEY);

  useEffect(() => {
    if (!!location && location.pathname !== currentPathname) {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
      eventsPanel.pageView(PageViewPages.settings, location.pathname);
      setCurrentPathname(location.pathname);
    }
  }, [location, currentPathname]);

  const isDirtyState = () => dirtyState;

  const handleDirtyState = (dirtyStateChange: any) => setDirtyState(dirtyStateChange);

  const handleOpenCloseDialog = (newRouteSelected: any) => {
    setModalChengesNotSaved(!modalChengesNotSaved);
    setRouteSelected(newRouteSelected);
  };

  const goTo = () => {
    if (routeSelected) {
      history.push(routeSelected);
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
      handleOpenCloseDialog();
      handleDirtyState(false);
    }
  };

  const renderContent = (childrens: any) => (
    <>
      <Hidden smDown>
        <Grid item className={classes.rightContainer}>
          {childrens}
        </Grid>
      </Hidden>
      <Hidden smUp>
        <Grid className={classes.rightContainerMobile}>{childrens}</Grid>
      </Hidden>
    </>
  );

  const renderDrawer = (childrens: any) => (
    <>
      <Hidden smUp>
        <SwipeableDrawer
          open={open}
          onClose={() => toggleDrawer(false)}
          onOpen={() => toggleDrawer(true)}
        >
          <Grid item className={classes.leftMenuSwipeable}>
            {childrens}
          </Grid>
        </SwipeableDrawer>
      </Hidden>
      <Hidden smDown>
        <Grid item className={classes.leftMenu}>
          {childrens}
        </Grid>
      </Hidden>
    </>
  );

  const toggleDrawer = (openChange: any) => setOpen(openChange);

  return (
    <Grid container className={classes.allHeight}>
      {renderDrawer(
        <SettingsLeftMenu
          itemsMenu={settingsItems(
            isDirtyState,
            handleOpenCloseDialog,
            planType,
            userHelpers.currentUser.isOrganizationAdmin()
          )}
          selected={
            location.pathname.includes('settings/organization/projects')
              ? 'settings.organization.projects'
              : location.pathname.replace(/\//g, '.').replace('.h.', '')
          }
        />
      )}
      {renderContent(
        <>
          <Hidden smUp>
            <Fab className={classes.fab} onClick={() => toggleDrawer(true)}>
              <KeyboardArrowRight />
            </Fab>
          </Hidden>
          <Switch>
            <Route
              path={`${routing.SETTINGS.ACCOUNT.PROFILE}`}
              render={() => <AccountProfile isDirtyState={handleDirtyState} />}
            />

            <Route
              path={`${routing.SETTINGS.ORGANIZATION.PROFILE}`}
              render={() => <OrganizationProfile isDirtyState={handleDirtyState} />}
            />

            <Route
              exact
              path={`${routing.SETTINGS.ORGANIZATION.PROJECTS.EDIT}`}
              // @ts-expect-error ts-migrate(2322) FIXME: Type '{ isDirtyState: (dirtyState: any) => void; }... Remove this comment to see the full error message
              render={() => <OrganizationProjectsEdit isDirtyState={handleDirtyState} />}
            />

            <Route
              path={`${routing.SETTINGS.ORGANIZATION.PROJECTS.ROOT}`}
              component={OrganizationProjects}
            />

            <Route
              path={`${routing.SETTINGS.ORGANIZATION.USERS}`}
              component={OrganizationUsers}
            />

            <Route path={`${routing.SETTINGS.BILLING.INFO}`} component={BillingInfo} />

            <Route
              path={`${routing.SETTINGS.BILLING.PAYMENT}`}
              render={() => (
                <Elements stripe={stripePromise}>
                  <AccountPaymentMethod />
                </Elements>
              )}
            />

            <Route
              path={`${routing.SETTINGS.BILLING.SUBSCRIPTION}`}
              component={BillingSubscriptions}
            />

            <Route path={`${routing.SETTINGS.BILLING.INVOICES}`} component={BillingInvoices} />

            <Route
              path={`${routing.SETTINGS.INTEGRATIONS.INPUT_HOOKS}`}
              component={IntegrationsKeys}
            />

            <Route
              path={`${routing.SETTINGS.INTEGRATIONS.OUTPUT_HOOKS}`}
              component={IntegrationsWebhooks}
            />
          </Switch>
        </>
      )}
      <NormalDialog
        open={dirtyState && modalChengesNotSaved}
        handleOpenCloseDialog={handleOpenCloseDialog}
        title={<FormattedMessage id="settings.notsaved.title" />}
        subtitle={<FormattedMessage id="settings.notsaved.subtitle" />}
        cancelText={<FormattedMessage id="settings.notsaved.stayonpage" />}
        actions={[
          {
            label: <FormattedMessage id="settings.notsaved.leavethepage" />,
            variant: 'contained',
            onClick: goTo,
          },
        ]}
      />
    </Grid>
  );
};

Settings.defaultProps = {
  planType: '',
};

const mapStateToProps = (state: any) => {
  return {
    planType:
      state.organization.organization &&
      state.organization.organization.subscription &&
      state.organization.organization.subscription.plan &&
      state.organization.organization.subscription.plan.type,
  };
};

// @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ fab: { position: string; top: ... Remove this comment to see the full error message
export default connect(mapStateToProps, null)(withStyles(styles)(Settings));
