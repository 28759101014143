import history from '../helpers/History';
import * as routing from './Routing';

const administrationItems = () => [
  {
    type: 'title',
    messageId: 'admin.title',
    id: 'title',
  },
  { type: 'spacer', id: 'spacer1' },
  {
    type: 'text',
    messageId: 'admin.subtitle.users',
    id: 'users',
    goTo: () => {
      history.push(routing.ADMINISTRATION.USERS);
    },
  },
  { type: 'mediumspacer', id: 'mspacer4' },
  {
    type: 'text',
    messageId: 'admin.subtitle.acquisition',
    id: 'acquisition',
    goTo: () => {
      history.push(routing.ADMINISTRATION.ACQUISITION);
    },
  },
  { type: 'mediumspacer', id: 'mspacer5' },
  {
    type: 'text',
    messageId: 'admin.subtitle.usage',
    id: 'usage',
    goTo: () => {
      history.push(routing.ADMINISTRATION.USAGE);
    },
  },
  { type: 'mediumspacer', id: 'mspacer6' },
  {
    type: 'text',
    messageId: 'admin.subtitle.subscriptions',
    id: 'subscriptions',
    goTo: () => {
      history.push(routing.ADMINISTRATION.SUBSCRIPTIONS);
    },
  },
  { type: 'mediumspacer', id: 'mspacer10' },
];

export default administrationItems;
