import React, { useEffect, useState } from 'react';
import styled from 'styled-components';

const Container = styled.div`
  z-index: 1300;
  position: absolute;
  width: 120px;
  height: 310px;

  transform: translate(
    ${(props: any) => props.originalX}px,
    ${(props: any) => props.originalY - 78}px
  );
  animation: appear 3s;
  animation-timing-function: ease-in-out;
  @keyframes appear {
    0% {
      transform: translate(
        ${(props: any) => props.originalX}px,
        ${(props: any) => props.originalY}px
      );
    }
    100% {
      transform: translate(
        ${(props: any) => props.originalX}px,
        ${(props: any) => props.originalY - 78}px
      );
    }
  }

  &.optimized {
    transform: translate(${(props: any) => props.originalX}px, -310px);
    animation: disappear 3s;
    animation-timing-function: ease-in-out;
    @keyframes disappear {
      0% {
        transform: translate(
          ${(props: any) => props.originalX}px,
          ${(props: any) => props.originalY - 78}px
        );
      }
      50% {
        transform: translate(
          ${(props: any) => props.originalX}px,
          ${(props: any) => props.originalY - 278}px
        );
      }
      100% {
        transform: translate(${(props: any) => props.originalX}px, -310px);
      }
    }
  }
`;

const Astronaut = styled.g`
  transform: translate(${(props: any) => props.initialX}px, ${(props: any) => props.initialY}px);

  animation-name: shake-little;
  animation-duration: 100ms;
  animation-timing-function: ease-in-out;
  animation-iteration-count: infinite;

  .monkey-astronaut {
    visibility: ${(props: any) => (props.initialized ? 'visible' : 'hidden')};
  }

  @keyframes shake-little {
    2% {
      transform: translate(
          ${(props: any) => props.initialX}px,
          ${(props: any) => props.initialY + 0}px
        )
        rotate(0);
    }
    4% {
      transform: translate(
          ${(props: any) => props.initialX}px,
          ${(props: any) => props.initialY + 1}px
        )
        rotate(0);
    }
    6% {
      transform: translate(
          ${(props: any) => props.initialX}px,
          ${(props: any) => props.initialY + 0}px
        )
        rotate(0);
    }
    8% {
      transform: translate(
          ${(props: any) => props.initialX}px,
          ${(props: any) => props.initialY + 1}px
        )
        rotate(0);
    }
    10% {
      transform: translate(
          ${(props: any) => props.initialX}px,
          ${(props: any) => props.initialY + 1}px
        )
        rotate(0);
    }
    12% {
      transform: translate(
          ${(props: any) => props.initialX}px,
          ${(props: any) => props.initialY + 0}px
        )
        rotate(0);
    }
    14% {
      transform: translate(
          ${(props: any) => props.initialX}px,
          ${(props: any) => props.initialY + 1}px
        )
        rotate(0);
    }
    16% {
      transform: translate(
          ${(props: any) => props.initialX}px,
          ${(props: any) => props.initialY + 1}px
        )
        rotate(0);
    }
    18% {
      transform: translate(
          ${(props: any) => props.initialX}px,
          ${(props: any) => props.initialY + 0}px
        )
        rotate(0);
    }
    20% {
      transform: translate(
          ${(props: any) => props.initialX}px,
          ${(props: any) => props.initialY + 0}px
        )
        rotate(0);
    }
    22% {
      transform: translate(
          ${(props: any) => props.initialX}px,
          ${(props: any) => props.initialY + 1}px
        )
        rotate(0);
    }
    24% {
      transform: translate(
          ${(props: any) => props.initialX}px,
          ${(props: any) => props.initialY + 0}px
        )
        rotate(0);
    }
    26% {
      transform: translate(
          ${(props: any) => props.initialX}px,
          ${(props: any) => props.initialY + 1}px
        )
        rotate(0);
    }
    28% {
      transform: translate(
          ${(props: any) => props.initialX}px,
          ${(props: any) => props.initialY + 1}px
        )
        rotate(0);
    }
    30% {
      transform: translate(
          ${(props: any) => props.initialX}px,
          ${(props: any) => props.initialY + 1}px
        )
        rotate(0);
    }
    32% {
      transform: translate(
          ${(props: any) => props.initialX}px,
          ${(props: any) => props.initialY + 1}px
        )
        rotate(0);
    }
    34% {
      transform: translate(
          ${(props: any) => props.initialX}px,
          ${(props: any) => props.initialY + 0}px
        )
        rotate(0);
    }
    36% {
      transform: translate(
          ${(props: any) => props.initialX}px,
          ${(props: any) => props.initialY + 0}px
        )
        rotate(0);
    }
    38% {
      transform: translate(
          ${(props: any) => props.initialX}px,
          ${(props: any) => props.initialY + 1}px
        )
        rotate(0);
    }
    40% {
      transform: translate(
          ${(props: any) => props.initialX}px,
          ${(props: any) => props.initialY + 1}px
        )
        rotate(0);
    }
    42% {
      transform: translate(
          ${(props: any) => props.initialX}px,
          ${(props: any) => props.initialY + 1}px
        )
        rotate(0);
    }
    44% {
      transform: translate(
          ${(props: any) => props.initialX}px,
          ${(props: any) => props.initialY + 1}px
        )
        rotate(0);
    }
    46% {
      transform: translate(
          ${(props: any) => props.initialX}px,
          ${(props: any) => props.initialY + 0}px
        )
        rotate(0);
    }
    48% {
      transform: translate(
          ${(props: any) => props.initialX}px,
          ${(props: any) => props.initialY + 0}px
        )
        rotate(0);
    }
    50% {
      transform: translate(
          ${(props: any) => props.initialX}px,
          ${(props: any) => props.initialY + 1}px
        )
        rotate(0);
    }
    52% {
      transform: translate(
          ${(props: any) => props.initialX}px,
          ${(props: any) => props.initialY + 0}px
        )
        rotate(0);
    }
    54% {
      transform: translate(
          ${(props: any) => props.initialX}px,
          ${(props: any) => props.initialY + 1}px
        )
        rotate(0);
    }
    56% {
      transform: translate(
          ${(props: any) => props.initialX}px,
          ${(props: any) => props.initialY + 0}px
        )
        rotate(0);
    }
    58% {
      transform: translate(
          ${(props: any) => props.initialX}px,
          ${(props: any) => props.initialY + 0}px
        )
        rotate(0);
    }
    60% {
      transform: translate(
          ${(props: any) => props.initialX}px,
          ${(props: any) => props.initialY + 1}px
        )
        rotate(0);
    }
    62% {
      transform: translate(
          ${(props: any) => props.initialX}px,
          ${(props: any) => props.initialY + 0}px
        )
        rotate(0);
    }
    64% {
      transform: translate(
          ${(props: any) => props.initialX}px,
          ${(props: any) => props.initialY + 1}px
        )
        rotate(0);
    }
    66% {
      transform: translate(
          ${(props: any) => props.initialX}px,
          ${(props: any) => props.initialY + 1}px
        )
        rotate(0);
    }
    68% {
      transform: translate(
          ${(props: any) => props.initialX}px,
          ${(props: any) => props.initialY + 1}px
        )
        rotate(0);
    }
    70% {
      transform: translate(
          ${(props: any) => props.initialX}px,
          ${(props: any) => props.initialY + 1}px
        )
        rotate(0);
    }
    72% {
      transform: translate(
          ${(props: any) => props.initialX}px,
          ${(props: any) => props.initialY + 0}px
        )
        rotate(0);
    }
    74% {
      transform: translate(
          ${(props: any) => props.initialX}px,
          ${(props: any) => props.initialY + 0}px
        )
        rotate(0);
    }
    76% {
      transform: translate(
          ${(props: any) => props.initialX}px,
          ${(props: any) => props.initialY + 0}px
        )
        rotate(0);
    }
    78% {
      transform: translate(
          ${(props: any) => props.initialX}px,
          ${(props: any) => props.initialY + 0}px
        )
        rotate(0);
    }
    80% {
      transform: translate(
          ${(props: any) => props.initialX}px,
          ${(props: any) => props.initialY + 1}px
        )
        rotate(0);
    }
    82% {
      transform: translate(
          ${(props: any) => props.initialX}px,
          ${(props: any) => props.initialY + 0}px
        )
        rotate(0);
    }
    84% {
      transform: translate(
          ${(props: any) => props.initialX}px,
          ${(props: any) => props.initialY + 1}px
        )
        rotate(0);
    }
    86% {
      transform: translate(
          ${(props: any) => props.initialX}px,
          ${(props: any) => props.initialY + 0}px
        )
        rotate(0);
    }
    88% {
      transform: translate(
          ${(props: any) => props.initialX}px,
          ${(props: any) => props.initialY + 0}px
        )
        rotate(0);
    }
    90% {
      transform: translate(
          ${(props: any) => props.initialX}px,
          ${(props: any) => props.initialY + 1}px
        )
        rotate(0);
    }
    92% {
      transform: translate(
          ${(props: any) => props.initialX}px,
          ${(props: any) => props.initialY + 0}px
        )
        rotate(0);
    }
    94% {
      transform: translate(
          ${(props: any) => props.initialX}px,
          ${(props: any) => props.initialY + 1}px
        )
        rotate(0);
    }
    96% {
      transform: translate(
          ${(props: any) => props.initialX}px,
          ${(props: any) => props.initialY + 1}px
        )
        rotate(0);
    }
    98% {
      transform: translate(
          ${(props: any) => props.initialX}px,
          ${(props: any) => props.initialY + 0}px
        )
        rotate(0);
    }
    0%,
    100% {
      transform: translate(${(props: any) => props.initialX}px, ${(props: any) => props.initialY}px)
        rotate(0);
    }
  }
`;

type OwnProps = {
  targetId: string;
  optimizing: boolean;
};

type Props = OwnProps;

const RocketAnimation = ({ targetId, optimizing }: Props) => {
  const [initialized, setInitialized] = useState(false);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const [optimized, setOptimized] = useState(false);

  useEffect(() => {
    if (!optimizing && !initialized) {
      setInitialized(true);
    } else if (optimizing && initialized) {
      setOptimized(true);
    }
  }, [initialized, optimizing, setInitialized, setOptimized]);

  useEffect(() => {
    if (targetId && targetId !== '') {
      const target = document.getElementById(targetId);
      // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
      const { x, y } = target.getBoundingClientRect();
      setPosition({ x, y });
    }
  }, [targetId, setPosition]);

  // Bubbles
  useEffect(() => {
    const bubblesIntervals: any = [];

    const jetBubbles = document.getElementsByClassName('jetBubble');

    // Jet bubbles
    for (let bubblesCounter = 0; bubblesCounter < jetBubbles.length; bubblesCounter += 1) {
      const jb = jetBubbles[bubblesCounter];

      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | null' is not assignable... Remove this comment to see the full error message
      const originalR = parseInt(jb.getAttribute('r'), 10);

      // Getting the first state of the bubble. (0 -> 3)
      let TYPE = Math.floor(Math.random() * 4);

      bubblesIntervals[bubblesCounter] = setInterval(() => {
        let newR = 0;
        switch (TYPE) {
          case 0:
            newR = originalR - 15 / 3;
            break;
          case 2:
            newR = originalR + 15 / 3;
            break;
          case 1:
          case 3:
          default:
            newR = originalR;
            break;
        }

        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number' is not assignable to par... Remove this comment to see the full error message
        jb.setAttribute('r', newR < 0 ? 0 : newR);

        TYPE = TYPE === 3 ? 0 : TYPE + 1;
      }, 500 / TYPE);
    }

    // Cleaning intervals.
    return () => {
      // @ts-expect-error ts-migrate(7006) FIXME: Parameter 'interval' implicitly has an 'any' type.
      bubblesIntervals.forEach((interval) => {
        clearInterval(interval);
      });
    };
  }, []);

  return (
    <Container
      // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
      originalX={position.x}
      originalY={position.y}
      className={`${optimized ? `optimized` : ``}`}
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        width="119.4"
        height="305.9"
        viewBox="0 0 119.4 305.9"
      >
        <defs>
          <clipPath id="clip-path">
            <path
              d="M300,465.7h0a25.073,25.073,0,0,1-25-25V249.4a25.138,25.138,0,0,1,25-25h0a25.073,25.073,0,0,1,25,25V440.7A25.009,25.009,0,0,1,300,465.7Z"
              transform="translate(-275 -263.3)"
            />
          </clipPath>
          <clipPath id="clip-path-2">
            <path
              d="M300,465.7h0a25.073,25.073,0,0,1-25-25V249.4a25.138,25.138,0,0,1,25-25h0a25.073,25.073,0,0,1,25,25V440.7A25.009,25.009,0,0,1,300,465.7Z"
              transform="translate(-285 -263.3)"
            />
          </clipPath>
          <clipPath id="clip-path-3">
            <path
              d="M300,465.7h0a25.073,25.073,0,0,1-25-25V249.4a25.138,25.138,0,0,1,25-25h0a25.073,25.073,0,0,1,25,25V440.7A25.009,25.009,0,0,1,300,465.7Z"
              transform="translate(-295 -263.3)"
            />
          </clipPath>
          <clipPath id="clip-path-4">
            <path
              d="M300,465.7h0a25.073,25.073,0,0,1-25-25V249.4a25.138,25.138,0,0,1,25-25h0a25.073,25.073,0,0,1,25,25V440.7A25.009,25.009,0,0,1,300,465.7Z"
              transform="translate(-305 -263.3)"
            />
          </clipPath>
          <clipPath id="clip-path-5">
            <path
              d="M300,465.7h0a25.073,25.073,0,0,1-25-25V249.4a25.138,25.138,0,0,1,25-25h0a25.073,25.073,0,0,1,25,25V440.7A25.009,25.009,0,0,1,300,465.7Z"
              transform="translate(-315 -263.3)"
            />
          </clipPath>
          <linearGradient
            id="linear-gradient"
            y1="0.5"
            x2="1"
            y2="0.5"
            gradientUnits="objectBoundingBox"
          >
            <stop offset="0.02" stopColor="#f05b62" />
            <stop offset="1" stopColor="#ff8200" />
          </linearGradient>
          <clipPath id="clip-path-6">
            <path
              id="Trazado_66"
              d="M54.7,49.1s3.8-2.3,4.5-5.4a8.3,8.3,0,0,0-.5-5.2A5.415,5.415,0,0,0,56,36.1s-.7,4.7-1.3,6.9c-.6,2.1-2.4,6.6-2.4,6.6A3.5,3.5,0,0,0,54.7,49.1Zm-43.4,0a4.726,4.726,0,0,0,2.4.6s-1.8-4.5-2.4-6.6S10,36.2,10,36.2a5.415,5.415,0,0,0-2.7,2.4,8.3,8.3,0,0,0-.5,5.2C7.5,46.8,11.3,49.1,11.3,49.1ZM54.467,22.579A32.626,32.626,0,0,1,56.6,32c3.8,1.8,5.8,6.7,4.7,11.7-1.2,5.1-5.2,8.6-9.4,8.4-4.4,6.9-11.245,9.348-19.045,9.348S18.2,58.9,13.9,52c-4.5.6-9.1-3-10.3-8.4s1.3-10.7,5.6-12a33.392,33.392,0,0,1,1.961-8.653c0-.008.006-.016.091-.225C11.252,22.722,44.085,22.317,54.467,22.579Z"
            />
          </clipPath>
          <clipPath id="clip-path-7">
            <rect id="Rectángulo_148" width="58.3" height="57.284" transform="translate(8.4 8.3)" />
          </clipPath>
          <clipPath id="clip-path-8">
            <path
              id="Trazado_18"
              d="M42.9,48.3c3.1,0,5.5-1.6,7.5-3.7,2-2,3.7-4.5,3.7-7.6,0-3.6-.1-7.4-2.7-9.5-1.9-1.5-5.9-1.8-8.5-1.8a11.3,11.3,0,0,0,0,22.6Z"
            />
          </clipPath>
          <clipPath id="clip-path-9">
            <rect
              id="Rectángulo_105"
              width="58.3"
              height="63.3"
              transform="translate(-20.1 -17.3)"
            />
          </clipPath>
          <clipPath id="clip-path-10">
            <ellipse
              id="Elipse_17"
              cx="11.2"
              cy="11.3"
              rx="11.2"
              ry="11.3"
              transform="translate(22 37.7)"
            />
          </clipPath>
          <clipPath id="clip-path-11">
            <rect
              id="Rectángulo_107"
              width="58.3"
              height="63.3"
              transform="translate(-10.3 -29.4)"
            />
          </clipPath>
          <clipPath id="clip-path-12">
            <path
              id="Trazado_19"
              d="M27.4,53.5a17.731,17.731,0,0,0,5.6.9,14.315,14.315,0,0,0,7.1-1.6c-.7,1.4-3.4,2.3-6.6,2.3C30.8,55.1,28.5,54.5,27.4,53.5Z"
            />
          </clipPath>
          <clipPath id="clip-path-13">
            <rect
              id="Rectángulo_109"
              width="58.3"
              height="63.3"
              transform="translate(-15.7 -44.5)"
            />
          </clipPath>
          <clipPath id="clip-path-14">
            <path
              id="Trazado_20"
              d="M23.1,48.3c-3.1,0-5.5-1.6-7.5-3.7-2-2-3.7-4.5-3.7-7.6,0-3.6.1-7.4,2.7-9.5,1.9-1.5,5.9-1.8,8.5-1.8a11.3,11.3,0,0,1,0,22.6Z"
            />
          </clipPath>
          <clipPath id="clip-path-15">
            <rect
              id="Rectángulo_111"
              width="58.3"
              height="63.3"
              transform="translate(-0.2 -17.3)"
            />
          </clipPath>
          <clipPath id="clip-path-16">
            <path
              id="Trazado_21"
              d="M43.9,34.9a.764.764,0,0,0-.2.5.773.773,0,0,0,.7.8.764.764,0,0,0,.5-.2,2.959,2.959,0,0,1,.2,1,2.308,2.308,0,0,1-2.2,2.3A2.242,2.242,0,0,1,40.7,37a2.308,2.308,0,0,1,2.2-2.3A2.958,2.958,0,0,1,43.9,34.9Z"
            />
          </clipPath>
          <clipPath id="clip-path-17">
            <rect id="Rectángulo_113" width="58.3" height="63.3" transform="translate(-29 -26.4)" />
          </clipPath>
          <clipPath id="clip-path-18">
            <path id="Trazado_22" d="M31.7,36.2V34.7h3v1.5Z" />
          </clipPath>
          <clipPath id="clip-path-19">
            <rect
              id="Rectángulo_115"
              width="58.3"
              height="63.3"
              transform="translate(-20.1 -26.4)"
            />
          </clipPath>
          <clipPath id="clip-path-20">
            <path
              id="Trazado_23"
              d="M24.4,34.9a.764.764,0,0,0-.2.5.773.773,0,0,0,.7.8.764.764,0,0,0,.5-.2,2.958,2.958,0,0,1,.2,1,2.308,2.308,0,0,1-2.2,2.3A2.242,2.242,0,0,1,21.2,37a2.308,2.308,0,0,1,2.2-2.3A2.958,2.958,0,0,1,24.4,34.9Z"
            />
          </clipPath>
          <clipPath id="clip-path-21">
            <rect
              id="Rectángulo_117"
              width="58.3"
              height="63.3"
              transform="translate(-9.6 -26.4)"
            />
          </clipPath>
          <clipPath id="clip-path-22">
            <path
              id="Trazado_24"
              d="M31.7,47.4c0,.3-.7.6-1.5.6s-1.5-.3-1.5-.6.7-.6,1.5-.6,1.5.2,1.5.6"
            />
          </clipPath>
          <clipPath id="clip-path-23">
            <rect
              id="Rectángulo_119"
              width="58.3"
              height="63.3"
              transform="translate(-17.1 -38.4)"
            />
          </clipPath>
          <clipPath id="clip-path-24">
            <path
              id="Trazado_25"
              d="M37.7,47.4c0,.3-.7.6-1.5.6s-1.5-.3-1.5-.6.7-.6,1.5-.6,1.5.2,1.5.6"
            />
          </clipPath>
          <clipPath id="clip-path-25">
            <rect id="Rectángulo_121" width="58.3" height="63.3" transform="translate(-23 -38.4)" />
          </clipPath>
          <clipPath id="clip-path-26">
            <path
              id="Trazado_26"
              d="M27.2,46.7a1.033,1.033,0,0,1,.4-.8,3.7,3.7,0,0,1,1.1-.5,1.7,1.7,0,0,1,.7-.1h1.5c.2,0,.5.1.7.1a3.7,3.7,0,0,1,1.1.5,1.033,1.033,0,0,1,.4.8v.2a.31.31,0,0,0-.1-.2,2.181,2.181,0,0,0-.5-.4,2.935,2.935,0,0,0-1-.3c-.2,0-.4-.1-.6-.1H29.5a1.268,1.268,0,0,0-.6.1,6.214,6.214,0,0,0-1,.3,1.082,1.082,0,0,0-.5.4.31.31,0,0,0-.1.2C27.2,47,27.2,46.9,27.2,46.7Z"
            />
          </clipPath>
          <clipPath id="clip-path-27">
            <rect
              id="Rectángulo_123"
              width="58.3"
              height="63.3"
              transform="translate(-15.6 -36.9)"
            />
          </clipPath>
          <clipPath id="clip-path-28">
            <path
              id="Trazado_27"
              d="M33.2,46.7a1.033,1.033,0,0,1,.4-.8,3.7,3.7,0,0,1,1.1-.5,1.7,1.7,0,0,1,.7-.1h1.5c.2,0,.5.1.7.1a3.7,3.7,0,0,1,1.1.5,1.033,1.033,0,0,1,.4.8v.2a.31.31,0,0,0-.1-.2,2.181,2.181,0,0,0-.5-.4,2.935,2.935,0,0,0-1-.3c-.2,0-.4-.1-.6-.1H35.5a1.268,1.268,0,0,0-.6.1,6.214,6.214,0,0,0-1,.3,1.082,1.082,0,0,0-.5.4.31.31,0,0,0-.1.2C33.2,47,33.2,46.9,33.2,46.7Z"
            />
          </clipPath>
          <clipPath id="clip-path-29">
            <rect
              id="Rectángulo_125"
              width="58.3"
              height="63.3"
              transform="translate(-21.6 -36.9)"
            />
          </clipPath>
          <clipPath id="clip-path-30">
            <path
              id="Trazado_28"
              d="M39.2,51.4a1.141,1.141,0,0,1,.6-.3,1.7,1.7,0,0,1,.7.1c.1,0,.2.1.3.2s.2.1.3.2.2.2.2.3c.1.1.1.2.2.3a1.7,1.7,0,0,1,.1.7c0,.2-.3.5-.3.6-.1.1-.2.1-.2.2a.349.349,0,0,0,.1-.2.749.749,0,0,0,.1-.5,1.421,1.421,0,0,0-.2-.6c0-.1-.1-.2-.2-.3s-.1-.2-.2-.3-.2-.2-.3-.2c-.1-.1-.2-.1-.3-.2-.2-.1-.4-.1-.6-.2,0,.1-.1.2-.3.2l-.2.2C39.1,51.5,39.1,51.4,39.2,51.4Z"
            />
          </clipPath>
          <clipPath id="clip-path-31">
            <rect
              id="Rectángulo_127"
              width="58.3"
              height="63.3"
              transform="translate(-28.3 -42.2)"
            />
          </clipPath>
          <clipPath id="clip-path-32">
            <path
              id="Trazado_30"
              d="M38,30.2H49.3a3.329,3.329,0,0,1,3.3,3.3v6.9a3.329,3.329,0,0,1-3.3,3.3H38a3.329,3.329,0,0,1-3.3-3.3V33.5A3.329,3.329,0,0,1,38,30.2Z"
            />
          </clipPath>
          <clipPath id="clip-path-33">
            <rect id="Rectángulo_128" width="58.3" height="63.3" transform="translate(3.3)" />
          </clipPath>
          <clipPath id="clip-path-34">
            <path
              id="Trazado_32"
              d="M17.1,30.2H28.4a3.329,3.329,0,0,1,3.3,3.3v6.9a3.329,3.329,0,0,1-3.3,3.3H17.1a3.329,3.329,0,0,1-3.3-3.3V33.5A3.2,3.2,0,0,1,17.1,30.2Z"
            />
          </clipPath>
        </defs>

        {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
        <Astronaut initialized={initialized} initialX={-1540} initialY={-195.1}>
          <g>
            <g transform="translate(1575 252.3)" clipPath="url(#clip-path)">
              <path d="M0,0H10V212.7H0Z" fill="#ff8216" />
            </g>
            <g transform="translate(1585 252.3)" clipPath="url(#clip-path-2)">
              <path d="M0,0H10V212.7H0Z" fill="#597e8d" />
            </g>
            <g transform="translate(1595 252.3)" clipPath="url(#clip-path-3)">
              <path d="M0,0H10V212.7H0Z" fill="#fac792" />
            </g>
            <g transform="translate(1605 252.3)" clipPath="url(#clip-path-4)">
              <path d="M0,0H10V212.7H0Z" fill="#8194b8" />
            </g>
            <g transform="translate(1615 252.3)" clipPath="url(#clip-path-5)">
              <path d="M0,0H10V212.7H0Z" fill="#333940" />
            </g>
          </g>
          <g className="jetBubbles">
            <g id="greyJets" transform="translate(1300 -11)">
              <circle
                className="jetBubble"
                cx="23"
                cy="23"
                r="23"
                transform="translate(266 466)"
                fill="#f8e6d2"
              />
              <circle
                className="jetBubble"
                cx="20"
                cy="20"
                r="20"
                transform="translate(250 450)"
                fill="#f8e6d2"
              />
              <circle
                className="jetBubble"
                cx="21"
                cy="21"
                r="21"
                transform="translate(298 462)"
                fill="#f8e6d2"
              />
            </g>
            <g id="colorJets" transform="translate(1300 -11)">
              <circle
                className="jetBubble"
                cx="10"
                cy="10"
                r="10"
                transform="translate(310 470)"
                fill="#333940"
              />
              <circle
                className="jetBubble"
                cx="11"
                cy="11"
                r="11"
                transform="translate(318 442)"
                fill="#597e8d"
              />
              <circle
                className="jetBubble"
                cx="24"
                cy="24"
                r="24"
                transform="translate(265 445)"
                fill="#597e8d"
              />
              <circle
                className="jetBubble"
                cx="20"
                cy="20"
                r="20"
                transform="translate(240 430)"
                fill="#333940"
              />
              <circle
                className="jetBubble"
                cx="10"
                cy="10"
                r="10"
                transform="translate(309 453)"
                fill="#8194b8"
              />
              <circle
                className="jetBubble"
                cx="18"
                cy="18"
                r="18"
                transform="translate(272 445)"
                fill="#ff8216"
              />
              <circle
                className="jetBubble"
                cx="21"
                cy="21"
                r="21"
                transform="translate(291 422)"
                fill="#fac792"
              />
              <circle
                className="jetBubble"
                cx="8"
                cy="8"
                r="8"
                transform="translate(273 441.4)"
                fill="#8194b8"
              />
            </g>
          </g>
          <g className="monkey-astronaut">
            <g id="Grupo_193" transform="translate(1 -2)">
              <g id="Grupo_175" transform="translate(1300 -11)">
                <g id="Grupo_174">
                  <path
                    id="Trazado_42"
                    d="M265,320.7h.5V304.6H244.1A21.486,21.486,0,0,0,265,320.7m69.9,0h.1a20.767,20.767,0,0,0,15.2-6.3,20.3,20.3,0,0,0,5.6-9.8H334.4v16.1h.5m-63.1-96.3a22.885,22.885,0,0,0-18.1,22.4v13.6a20.71,20.71,0,0,1,11.3-3.2h0a22.6,22.6,0,0,1,2.6.1,35.363,35.363,0,0,1-2.3-12.7,33.392,33.392,0,0,1,6.5-20.2m74.5,36V246.8a22.047,22.047,0,0,0-6.7-16.2,21.6,21.6,0,0,0-10.4-6l-.2.8a32.737,32.737,0,0,1,5.8,19.2,35.218,35.218,0,0,1-2,11.8l.1.8a15.482,15.482,0,0,1,2.2-.1h0A21.869,21.869,0,0,1,346.3,260.4Z"
                    fill="#ccc"
                  />
                  <path
                    id="Trazado_43"
                    d="M299.9,210.1h-.1a33.15,33.15,0,0,0-24.3,10.1,36.224,36.224,0,0,0-3.6,4.2,33.475,33.475,0,0,0-6.5,20.3,34.968,34.968,0,0,0,2.3,12.7,14.168,14.168,0,0,0,.8,1.8,33.528,33.528,0,0,0,7.1,9.9,34.334,34.334,0,0,0,10.7,7.4.349.349,0,0,1,.2.1,18.409,18.409,0,0,0,2.5.9,35.7,35.7,0,0,0,11,1.7,33.374,33.374,0,0,0,24.4-10.1,34.5,34.5,0,0,0,7.9-11.8l.3-.9a34.6,34.6,0,0,0,1.9-11.7,33.07,33.07,0,0,0-5.7-19.1l-.2-.2a.1.1,0,0,0-.1-.1h0l-.1-.1-.1-.1h0l-.1-.1c0-.1-.1-.1-.1-.2a.1.1,0,0,0-.1-.1,36.7,36.7,0,0,0-3.7-4.3,32.955,32.955,0,0,0-24.4-10.3m50.2,53.4a18.986,18.986,0,0,0-3.9-3.1,20.71,20.71,0,0,0-11.3-3.2h0a16.2,16.2,0,0,0-2.2.1h-.4l-.3-.1a35.156,35.156,0,0,1-32.3,21.9,35.7,35.7,0,0,1-11-1.7c-.8-.3-1.7-.6-2.5-.9a.349.349,0,0,1-.2-.1,34.87,34.87,0,0,1-10.7-7.4,35.439,35.439,0,0,1-7.1-9.9c-.3-.6-.5-1.2-.8-1.8a21.748,21.748,0,0,0-2.6-.1h0a20.71,20.71,0,0,0-11.3,3.2,23.093,23.093,0,0,0-3.9,3.1,20.607,20.607,0,0,0-6.3,15.2v20.6a23.516,23.516,0,0,0,.6,5.4h21.4V338a16.266,16.266,0,0,0,4.7,8.7,16.01,16.01,0,0,0,12,5,18.307,18.307,0,0,0,12.7-5.3,15.036,15.036,0,0,0,5-8.7V321.8h.3v15.9a15.036,15.036,0,0,0,5,8.7,18.307,18.307,0,0,0,12.7,5.3,16.387,16.387,0,0,0,12-5,16.962,16.962,0,0,0,4.7-8.7V304.6h21.4a23.517,23.517,0,0,0,.6-5.4V278.6a20.706,20.706,0,0,0-6.3-15.1M300,311.1v0Z"
                    fill="#fff"
                  />
                </g>
              </g>
              <path
                id="Trazado_45"
                d="M355.8,304.6a23.517,23.517,0,0,0,.6-5.4V278.6a20.767,20.767,0,0,0-6.3-15.2,18.986,18.986,0,0,0-3.9-3.1,20.71,20.71,0,0,0-11.3-3.2h0a16.2,16.2,0,0,0-2.2.1h-.4m-.2.1a35.156,35.156,0,0,1-32.3,21.9,35.7,35.7,0,0,1-11-1.7c-.8-.3-1.7-.6-2.5-.9a.349.349,0,0,1-.2-.1,34.87,34.87,0,0,1-10.7-7.4,35.439,35.439,0,0,1-7.1-9.9c-.3-.6-.5-1.2-.8-1.8a21.748,21.748,0,0,0-2.6-.1h0a20.71,20.71,0,0,0-11.3,3.2,23.093,23.093,0,0,0-3.9,3.1,20.607,20.607,0,0,0-6.3,15.2v20.6a23.516,23.516,0,0,0,.6,5.4h21.4V282.2m80.9-21.8V246.8a22.047,22.047,0,0,0-6.7-16.2,21.6,21.6,0,0,0-10.4-6m-.3.8a32.737,32.737,0,0,1,5.8,19.2,35.218,35.218,0,0,1-2,11.8m-33-46.2h.1a33.374,33.374,0,0,1,24.4,10.1,36.7,36.7,0,0,1,3.7,4.3.1.1,0,0,0,.1.1c0,.1.1.1.1.2l.1.1h0l.1.1h0l.1.1h0l.1.1.2.2a33.3,33.3,0,0,1,5.7,19.1,34.192,34.192,0,0,1-1.9,11.7l-.3.9m-3-32.6h0c-.3-.1-.6-.1-.8-.2h-.2a.1.1,0,0,0,.1.1v.1c.1.1.2.3.3.4h0c.1.1.2.3.3.4h0m-57.1-1q1.65-2.1,3.6-4.2a33.15,33.15,0,0,1,24.3-10.1m-46,50.3V246.8a22.047,22.047,0,0,1,6.7-16.2,21.487,21.487,0,0,1,11.4-6.2,33.475,33.475,0,0,0-6.5,20.3,34.968,34.968,0,0,0,2.3,12.7m65.1-.9h0v.2l-.2.5c0,.1-.1.1-.1.2m2.3,63.3h.3a20.767,20.767,0,0,0,15.2-6.3,20.3,20.3,0,0,0,5.6-9.8H334.4v16.1h.2m0,0h0m-47.3,1.1H313m-13,16a15.036,15.036,0,0,0,5,8.7,18.307,18.307,0,0,0,12.7,5.3,16.387,16.387,0,0,0,12-5,16.962,16.962,0,0,0,4.7-8.7V320.8m-68.8-.1V338a16.266,16.266,0,0,0,4.7,8.7,16.01,16.01,0,0,0,12,5,18.307,18.307,0,0,0,12.7-5.3,15.036,15.036,0,0,0,5-8.7V321.8m-34.4-1.1H265a20.767,20.767,0,0,1-15.2-6.3,20.3,20.3,0,0,1-5.6-9.8m21.4,0v16.1m68.8-38.6v22.6M300,311.1V281"
                transform="translate(1300 -11)"
                fill="none"
                stroke="#2d2d2d"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="4"
              />
              <g id="Grupo_186" transform="translate(83 -56)">
                <circle
                  id="Elipse_39"
                  cx="8.7"
                  cy="8.7"
                  r="8.7"
                  transform="translate(1527.9 325.2)"
                  fill="#333940"
                  stroke="#597e8d"
                  strokeMiterlimit="10"
                  strokeWidth="1.439"
                />
                <path
                  id="logo.42a4f008_1_"
                  d="M14.193,4.5,2.229,7.444c-.125.063-.188.125,0,.188l2.881,1.19a.864.864,0,0,0,.564,0L6.99,8.2l.5.94L6.3,9.95a1.364,1.364,0,0,0-.313.5L5.361,14.4c0,.188.063.188.188.063l8.769-9.772c.125-.125.063-.188-.125-.188Zm-6.2,4.2-.438-.814,1.5-.689.313.626L7.992,8.7Zm1.817-1.19-.251-.564,1.19-.564.188.313Zm2.944-2-1.5,1-.188-.313L12.753,5.5C12.878,5.377,12.878,5.44,12.753,5.5Z"
                  transform="translate(1527.89 325.946)"
                  fillRule="evenodd"
                  fill="url(#linear-gradient)"
                />
              </g>
              <g id="Grupo_215" transform="translate(-39)">
                <g id="Grupo_200" transform="translate(1606.701 194)" clipPath="url(#clip-path-6)">
                  <g
                    id="Grupo_199-2"
                    transform="translate(-5.1 -2.284)"
                    clipPath="url(#clip-path-7)"
                  >
                    <rect
                      id="Rectángulo_147"
                      width="75"
                      height="72.397"
                      transform="translate(0 0.789)"
                      fill="#ff8200"
                    />
                  </g>
                </g>
                <g id="Grupo_201" transform="translate(1606.701 194)">
                  <g id="Grupo_127" clipPath="url(#clip-path-8)">
                    <g id="Grupo_126" transform="translate(23.4 17.3)" clipPath="url(#clip-path-9)">
                      <rect id="Rectángulo_104" width="39.1" height="39.3" fill="#fff" />
                    </g>
                  </g>
                </g>
                <g id="Grupo_202" transform="translate(1606.701 194)">
                  <g id="Grupo_130" clipPath="url(#clip-path-10)">
                    <g
                      id="Grupo_129"
                      transform="translate(13.6 29.4)"
                      clipPath="url(#clip-path-11)"
                    >
                      <rect id="Rectángulo_106" width="39.1" height="39.3" fill="#fff" />
                    </g>
                  </g>
                </g>
                <g id="Grupo_203" transform="translate(1606.701 194)">
                  <g id="Grupo_133" clipPath="url(#clip-path-12)">
                    <g id="Grupo_132" transform="translate(19 44.5)" clipPath="url(#clip-path-13)">
                      <rect id="Rectángulo_108" width="29.3" height="19" fill="#010101" />
                    </g>
                  </g>
                </g>
                <g id="Grupo_204" transform="translate(1606.701 194)">
                  <g id="Grupo_136" clipPath="url(#clip-path-14)">
                    <g id="Grupo_135" transform="translate(3.5 17.3)" clipPath="url(#clip-path-15)">
                      <rect id="Rectángulo_110" width="39.1" height="39.3" fill="#fff" />
                    </g>
                  </g>
                </g>
                <g id="Grupo_205" transform="translate(1606.701 194)">
                  <g id="Grupo_139" clipPath="url(#clip-path-16)">
                    <g
                      id="Grupo_138"
                      transform="translate(32.3 26.4)"
                      clipPath="url(#clip-path-17)"
                    >
                      <rect id="Rectángulo_112" width="21.2" height="21.2" fill="#010101" />
                    </g>
                  </g>
                </g>
                <g id="Grupo_206" transform="translate(1606.701 194)">
                  <g id="Grupo_142" clipPath="url(#clip-path-18)">
                    <g
                      id="Grupo_141"
                      transform="translate(23.4 26.4)"
                      clipPath="url(#clip-path-19)"
                    >
                      <rect id="Rectángulo_114" width="19.7" height="18.2" fill="#010101" />
                    </g>
                  </g>
                </g>
                <g id="Grupo_207" transform="translate(1606.701 194)">
                  <g id="Grupo_145" clipPath="url(#clip-path-20)">
                    <g
                      id="Grupo_144"
                      transform="translate(12.9 26.4)"
                      clipPath="url(#clip-path-21)"
                    >
                      <rect id="Rectángulo_116" width="21.2" height="21.2" fill="#010101" />
                    </g>
                  </g>
                </g>
                <g id="Grupo_208" transform="translate(1606.701 194)">
                  <g id="Grupo_148" clipPath="url(#clip-path-22)">
                    <g
                      id="Grupo_147"
                      transform="translate(20.4 38.4)"
                      clipPath="url(#clip-path-23)"
                    >
                      <rect id="Rectángulo_118" width="19.7" height="17.9" fill="#010101" />
                    </g>
                  </g>
                </g>
                <g id="Grupo_209" transform="translate(1606.701 194)">
                  <g id="Grupo_151" clipPath="url(#clip-path-24)">
                    <g
                      id="Grupo_150"
                      transform="translate(26.3 38.4)"
                      clipPath="url(#clip-path-25)"
                    >
                      <rect id="Rectángulo_120" width="19.7" height="17.9" fill="#010101" />
                    </g>
                  </g>
                </g>
                <g id="Grupo_210" transform="translate(1606.701 194)">
                  <g id="Grupo_154" clipPath="url(#clip-path-26)">
                    <g
                      id="Grupo_153"
                      transform="translate(18.9 36.9)"
                      clipPath="url(#clip-path-27)"
                    >
                      <rect id="Rectángulo_122" width="22.7" height="18.4" fill="#010101" />
                    </g>
                  </g>
                </g>
                <g id="Grupo_211" transform="translate(1606.701 194)">
                  <g id="Grupo_157" clipPath="url(#clip-path-28)">
                    <g
                      id="Grupo_156"
                      transform="translate(24.9 36.9)"
                      clipPath="url(#clip-path-29)"
                    >
                      <rect id="Rectángulo_124" width="22.7" height="18.4" fill="#010101" />
                    </g>
                  </g>
                </g>
                <g id="Grupo_212" transform="translate(1606.701 194)">
                  <g id="Grupo_160" clipPath="url(#clip-path-30)">
                    <g
                      id="Grupo_159"
                      transform="translate(27.302 51.492) rotate(-45)"
                      clipPath="url(#clip-path-31)"
                    >
                      <rect id="Rectángulo_126" width="18.2" height="19.7" fill="#010101" />
                    </g>
                  </g>
                </g>
                <g id="Grupo_213" transform="translate(1606.701 194)">
                  <g id="Grupo_163" clipPath="url(#clip-path-32)">
                    <g id="Grupo_162" clipPath="url(#clip-path-33)">
                      <path
                        id="Trazado_29"
                        d="M38,30.2H49.3a3.329,3.329,0,0,1,3.3,3.3v6.9a3.329,3.329,0,0,1-3.3,3.3H38a3.329,3.329,0,0,1-3.3-3.3V33.5A3.329,3.329,0,0,1,38,30.2Z"
                        fill="none"
                        stroke="#010101"
                        strokeMiterlimit="10"
                        strokeWidth="2"
                      />
                    </g>
                  </g>
                </g>
                <g id="Grupo_214" transform="translate(1606.701 194)">
                  <g id="Grupo_166" clipPath="url(#clip-path-34)">
                    <g id="Grupo_165" clipPath="url(#clip-path-33)">
                      <path
                        id="Trazado_31"
                        d="M17.1,30.2H28.4a3.329,3.329,0,0,1,3.3,3.3v6.9a3.329,3.329,0,0,1-3.3,3.3H17.1a3.329,3.329,0,0,1-3.3-3.3V33.5A3.2,3.2,0,0,1,17.1,30.2Z"
                        fill="none"
                        stroke="#010101"
                        strokeMiterlimit="10"
                        strokeWidth="2"
                      />
                    </g>
                  </g>
                </g>
              </g>
              <path
                d="M333.112,247.5c0,6.738-3.323,12.372-9.968,17.122s-14.756,7.07-24.138,7.07c-9.479,0-17.493-2.32-24.138-7.07S264.9,254.131,264.9,247.5c0-5.634,2.345-10.6,7.134-14.8h54.237C330.766,236.9,333.112,241.869,333.112,247.5Z"
                transform="translate(1300.858 -17.861)"
                fill="rgba(255,130,0,0.3)"
                stroke="#2d2d2d"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="4"
              />
            </g>
          </g>
        </Astronaut>
      </svg>
    </Container>
  );
};

RocketAnimation.defaultProps = {};

export default RocketAnimation;
