import eventsPanel from '../../services/events';
import getHighway from '../../services/highway';
import constants from '../constants';
import { projectHelpers } from '../helpers';
import store from '../store/index';
import { getPlan } from './ActivePlan';

export const setTableColumns = (columns: any) => {
  return (dispatch: any) => {
    dispatch({
      type: constants.Routes.ROUTES_SET_TABLE_COLUMNS,
      payload: columns,
    });
  };
};

export const updateRoute = (routeId: any, services: any) => {
  return (dispatch: any) => {
    const highway = getHighway(store.getState().user.token);
    highway.route
      .update(routeId, services)
      .then((response) => {
        dispatch(getPlan(response.plan_id));
        const { activePlan } = store.getState();
        if (
          activePlan.detailView &&
          activePlan.detailView.type === 'route' &&
          activePlan.detailView.data &&
          activePlan.detailView.data.id === routeId
        ) {
          dispatch({
            type: constants.ActivePlan.ACTIVE_PLAN_SET_DETAIL_VIEW,
            payload: {
              type: 'route',
              data: response,
            },
          });
        }
        // dispatch({
        //   type: constants.Routes.ROUTES_UPDATE_ROUTE_SUCCESS,
        //   payload: response
        // });
      })
      .catch((error) => {
        dispatch({
          type: constants.Routes.ROUTES_UPDATE_ROUTE_ERROR,
          payload: error,
        });
      });
  };
};

export const optimizeRoute = (routeId: any) => {
  return (dispatch: any) => {
    const highway = getHighway(store.getState().user.token);
    highway.route
      .optimize(routeId)
      .then((response) => {
        dispatch(getPlan(response.route.plan_id));
        dispatch({
          type: constants.Routes.ROUTES_OPTIMIZE_ROUTE_SUCCESS,
          payload: response,
        });
        eventsPanel.routeOptimized(
          response.route.services.length,
          response.route.services.reduce(
            (p: any, n: any) => (n.distance_to_next_location ? p + n.distance_to_next_location : p),
            0
          ),
          response.route.services.reduce((p: any, n: any) => p + n.duration, 0)
        );
      })
      .catch((error) => {
        dispatch({
          type: constants.Routes.ROUTES_OPTIMIZE_ROUTE_ERROR,
          payload: error,
        });
      });
  };
};

export const deleteRoutes = (routes: any) => {
  return (dispatch: any) => {
    const highway = getHighway(store.getState().user.token);
    Promise.all(routes.map((r: any) => highway.route.delete(r.id))).then((allResponses: any) => {
      if (allResponses.length > 0) {
        dispatch(getPlan(allResponses[0].plan_id));
      }
      dispatch({
        type: constants.Routes.ROUTES_DELETE_ROUTE_SUCCESS,
        payload: allResponses,
      });
    });
  };
};

export const updateRoutes = (routes: any, changes: any) => {
  return (dispatch: any) => {
    const highway = getHighway(store.getState().user.token);
    Promise.all(routes.map((r: any) => highway.route.update(r.id, changes))).then(
      (allResponses: any) => {
        if (allResponses.length > 0) {
          dispatch(getPlan(allResponses[0].plan_id));
        }
        dispatch({
          type: constants.Routes.ROUTES_UPDATE_ROUTES_SUCCESS,
          payload: allResponses,
        });
      }
    );
  };
};

export const updateMultipleRoutes = (routes: any) => {
  return (dispatch: any) => {
    const highway = getHighway(store.getState().user.token);
    Promise.all(
      routes.map((r: any) => {
        const { id, ...cleanRoute } = r;
        return highway.route.update(id, cleanRoute);
      })
    ).then((allResponses: any) => {
      if (allResponses.length > 0) {
        dispatch(getPlan(allResponses[0].plan_id));
      }
      dispatch({
        type: constants.Routes.ROUTES_UPDATE_ROUTES_SUCCESS,
        payload: allResponses,
      });
    });
  };
};

export const saveRouteAsVehicle = (route: any) => {
  return (dispatch: any) => {
    const highway = getHighway(store.getState().user.token);
    const vehicle = highway.vehicle.fromRoute(route);
    highway.vehicle
      .create(vehicle, projectHelpers.getCurrentProjectId())
      .then((response) => {
        dispatch({
          type: constants.Vehicles.VEHICLES_ROUTE_AS_VEHICLE_SUCCESS,
          payload: response,
        });
        dispatch(updateRoute(route.id, { vehicle_id: response.id }));
      })
      .catch((error) => {
        dispatch({
          type: constants.Vehicles.VEHICLES_ROUTE_AS_VEHICLE_ERROR,
          payload: error,
        });
      });
  };
};
