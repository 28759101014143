import {
  ONBOARDING_STEPS,
  ONBOARDING_TUTORIAL_NAME,
} from '../../components/tutorials/OnboardingTutorial';
import * as routing from '../../constants/Routing';
import history from '../../helpers/History';
import { dateTodayFormatPlan } from '../../helpers/TimeDistance';
import eventsPanel from '../../services/events';
import getHighway from '../../services/highway';
import { post } from '../../services/requests';
import constants from '../constants';
import { projectHelpers } from '../helpers';
import store from '../store/index';
import { Plans, Tutorial } from './index';

export const getPlan = (planId: any) => {
  return (dispatch: any) => {
    dispatch({ type: constants.ActivePlan.ACTIVE_PLAN_GET_PLAN });
    const highway = getHighway(store.getState().user.token);
    highway.plan
      .get(planId)
      .then((response) => {
        dispatch({
          type: constants.ActivePlan.ACTIVE_PLAN_GET_PLAN_SUCCESS,
          payload: response,
        });
      })
      .catch((error) => {
        dispatch({
          type: constants.ActivePlan.ACTIVE_PLAN_GET_PLAN_ERROR,
          payload: error,
        });
      });
  };
};

export const createPlan = (plan: any) => {
  return (dispatch: any) => {
    const highway = getHighway(store.getState().user.token);

    const { clients, vehicles, ...cleanPlan } = plan;
    if (clients) {
      cleanPlan.services = clients.map((service: any) => highway.service.fromClient(service));
    }
    if (vehicles) {
      cleanPlan.routes = vehicles.map((route: any) => highway.route.fromVehicle(route));
    }

    const projectId = projectHelpers.getCurrentProjectId();

    highway.plan
      .create({ label: `${dateTodayFormatPlan()}_Plan`, ...cleanPlan }, projectId)
      .then((response) => {
        dispatch({
          type: constants.ActivePlan.ACTIVE_PLAN_CREATE_PLAN_SUCCESS,
          payload: response,
        });
        eventsPanel.planCreated();
        history.push(`${routing.PLANS.replace(':projectId', projectId)}/${response.id}`);
        dispatch(Tutorial.setStep(ONBOARDING_TUTORIAL_NAME, ONBOARDING_STEPS.OPTIMIZE_BUTTON));
      })
      .catch((error) => {
        dispatch({
          type: constants.ActivePlan.ACTIVE_PLAN_CREATE_PLAN_ERROR,
          payload: error,
        });
      });
  };
};

export const updatePlan = (planId: any, plan: any, refresh = false) => {
  return (dispatch: any) => {
    const hw = getHighway(store.getState().user.token);
    hw.plan
      .update(planId, plan)
      .then((response) => {
        dispatch({
          type: constants.ActivePlan.ACTIVE_PLAN_UPDATE_PLAN_SUCCESS,
          payload: response,
        });
        if (refresh) {
          const { limit, offset } = store.getState().plans.plans;
          dispatch(Plans.getPlans(undefined, offset, limit));
        }

        if (plan.status) {
          eventsPanel.planUpdated(plan.status);
        }
      })
      .catch((error) => {
        dispatch({
          type: constants.ActivePlan.ACTIVE_PLAN_UPDATE_PLAN_ERROR,
          payload: error,
        });
      });
  };
};

export const optimizePlan = (planId: any, large: any) => {
  return (dispatch: any) => {
    dispatch({
      type: constants.ActivePlan.ACTIVE_PLAN_OPTIMIZE_PLAN,
    });

    const hw = getHighway(store.getState().user.token);
    const optFunction = hw.plan.optimize; // large? hw.plan.optimizeAsync : hw.plan.optimize;
    optFunction(planId)
      .then((response) => {
        dispatch({
          type: constants.ActivePlan.ACTIVE_PLAN_OPTIMIZE_PLAN_SUCCESS,
          payload: response,
        });

        eventsPanel.planOptimized(
          response.services.length,
          response.routes.length,
          response.services.reduce(
            (p, n) => (n.distance_to_next_location ? p + n.distance_to_next_location : p),
            0
          ),
          // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
          response.services.reduce((p, n) => p + n.duration, 0)
        );
        dispatch(Tutorial.setStep(ONBOARDING_TUTORIAL_NAME, ONBOARDING_STEPS.DISPATCH_BUTTON));
      })
      .catch((error) => {
        dispatch({
          type: constants.ActivePlan.ACTIVE_PLAN_OPTIMIZE_PLAN_ERROR,
          payload: error,
        });
      });
  };
};

export const createRoutes = (routes: any, planId: any) => {
  return (dispatch: any) => {
    const highway = getHighway(store.getState().user.token);
    return highway.route
      .createMany(planId, routes)
      .then((response) => {
        dispatch(getPlan(planId));
        dispatch({
          type: constants.ActivePlan.ACTIVE_PLAN_CREATE_PLAN_ROUTES_SUCCESS,
          payload: response,
        });
      })
      .catch((error) => {
        dispatch({
          type: constants.ActivePlan.ACTIVE_PLAN_CREATE_PLAN_ROUTES_ERROR,
          payload: error,
        });
      });
  };
};

export const cleanPlan = () => {
  return { type: constants.ActivePlan.ACTIVE_PLAN_CLEAN_PLAN };
};

export const changeOptions = (planId: any, options: any) => {
  return (dispatch: any) => {
    return post(`/api/v1/plan/${planId}/config`, options, {
      Authorization: `Bearer ${store.getState().user.token}`,
    })
      .then((response) => {
        dispatch({
          type: constants.ActivePlan.ACTIVE_PLAN_CHANGE_OPTIONS_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: constants.ActivePlan.ACTIVE_PLAN_CHANGE_OPTIONS_ERROR,
          payload: error,
        });
      });
  };
};

export const setListSelectedRoutes = (routes: any) => {
  return (dispatch: any) => {
    dispatch({
      type: constants.ActivePlan.ACTIVE_PLAN_LIST_SET_SELECTED_ROUTES,
      payload: routes,
    });
  };
};

export const setMapSelectedRoutes = (routes: any) => {
  return (dispatch: any) => {
    dispatch({
      type: constants.ActivePlan.ACTIVE_PLAN_MAP_SET_SELECTED_ROUTES,
      payload: routes,
    });
  };
};

export const setListSelectedServices = (services: any) => {
  return (dispatch: any) => {
    dispatch({
      type: constants.ActivePlan.ACTIVE_PLAN_LIST_SET_SELECTED_SERVICES,
      payload: services,
    });
  };
};

export const setMapSelectedServices = (services: any) => {
  return (dispatch: any) => {
    dispatch({
      type: constants.ActivePlan.ACTIVE_PLAN_MAP_SET_SELECTED_SERVICES,
      payload: services,
    });
  };
};

export const setDetailView = (type: any, data: any) => {
  return (dispatch: any) => {
    dispatch({
      type: constants.ActivePlan.ACTIVE_PLAN_SET_DETAIL_VIEW,
      payload: {
        type,
        data,
      },
    });
  };
};

export const cleanDetailView = () => {
  return (dispatch: any) => {
    dispatch({
      type: constants.ActivePlan.ACTIVE_PLAN_SET_DETAIL_VIEW,
      payload: {
        type: '',
        data: undefined,
      },
    });
  };
};
