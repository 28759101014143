export const vehicles_MX = [
  {
    end_location: {
      lat: 19.41767,
      lng: -99.16664,
      country: 'México',
      country_code: 'MEX',
      state: 'Ciudad de México',
      house_number: '296',
      postal_code: '06700',
      label: 'Calle Tabasco 296, Roma Norte, 06700 Cuauhtémoc, CMX, México',
      city: 'Cuauhtémoc',
      district: 'Roma Norte',
      street: 'Calle Tabasco',
    },
    label: 'Manuel',
    max_services: 17,
    start_location: {
      lat: 19.41767,
      lng: -99.16664,
      country: 'México',
      country_code: 'MEX',
      state: 'Ciudad de México',
      house_number: '296',
      postal_code: '06700',
      label: 'Calle Tabasco 296, Roma Norte, 06700 Cuauhtémoc, CMX, México',
      city: 'Cuauhtémoc',
      district: 'Roma Norte',
      street: 'Calle Tabasco',
    },
    timewindow: [32400, 57600],
  },
  {
    end_location: {
      lat: 19.36036,
      lng: -99.15202,
      country: 'México',
      country_code: 'MEX',
      state: 'Ciudad de México',
      house_number: '157',
      postal_code: '03300',
      label: 'Avenida Nevado 157, Portales Sur, 03300 Benito Juárez, CMX, México',
      city: 'Benito Juárez',
      district: 'Portales Sur',
      street: 'Avenida Nevado',
    },
    label: 'Jose',
    max_services: 17,
    start_location: {
      lat: 19.36036,
      lng: -99.15202,
      country: 'México',
      country_code: 'MEX',
      state: 'Ciudad de México',
      house_number: '157',
      postal_code: '03300',
      label: 'Avenida Nevado 157, Portales Sur, 03300 Benito Juárez, CMX, México',
      city: 'Benito Juárez',
      district: 'Portales Sur',
      street: 'Avenida Nevado',
    },
    timewindow: [39600, 64800],
  },
  {
    end_location: {
      lat: 19.34854,
      lng: -99.15929,
      country: 'México',
      country_code: 'MEX',
      state: 'Ciudad de México',
      house_number: '44',
      postal_code: '04020',
      label: 'Calle Ignacio Vallarta 44, La Concepción, 04020 Coyoacán, CMX, México',
      city: 'Coyoacán',
      district: 'La Concepción',
      street: 'Calle Ignacio Vallarta',
    },
    label: 'Pedro',
    max_services: 17,
    start_location: {
      lat: 19.34854,
      lng: -99.15929,
      country: 'México',
      country_code: 'MEX',
      state: 'Ciudad de México',
      house_number: '44',
      postal_code: '04020',
      label: 'Calle Ignacio Vallarta 44, La Concepción, 04020 Coyoacán, CMX, México',
      city: 'Coyoacán',
      district: 'La Concepción',
      street: 'Calle Ignacio Vallarta',
    },
    timewindow: [32400, 57600],
  },
  {
    end_location: {
      lat: 19.34638,
      lng: -99.17012,
      country: 'México',
      country_code: 'MEX',
      state: 'Ciudad de México',
      house_number: '75',
      postal_code: '04010',
      label: 'Calle de Pino 75, Santa Catarina, 04010 Coyoacán, CMX, México',
      city: 'Coyoacán',
      district: 'Santa Catarina',
      street: 'Calle de Pino',
    },
    label: 'Pablo',
    max_services: 17,
    start_location: {
      lat: 19.34638,
      lng: -99.17012,
      country: 'México',
      country_code: 'MEX',
      state: 'Ciudad de México',
      house_number: '75',
      postal_code: '04010',
      label: 'Calle de Pino 75, Santa Catarina, 04010 Coyoacán, CMX, México',
      city: 'Coyoacán',
      district: 'Santa Catarina',
      street: 'Calle de Pino',
    },
    timewindow: [39600, 64800],
  },
];

export const services_MX = [
  {
    label: 'RESTAURANTE MAIDO',
    location: {
      lat: 19.41767,
      lng: -99.16664,
      country: 'México',
      country_code: 'MEX',
      state: 'Ciudad de México',
      house_number: '296',
      postal_code: '06700',
      label: 'Calle Tabasco 296, Roma Norte, 06700 Cuauhtémoc, CMX, México',
      city: 'Cuauhtémoc',
      district: 'Roma Norte',
      street: 'Calle Tabasco',
    },
    duration: 300,
    timewindows: [[28800, 36000]],
  },
  {
    label: 'RESTAURANTE CENTRAL',
    location: {
      lat: 19.36036,
      lng: -99.15202,
      country: 'México',
      country_code: 'MEX',
      state: 'Ciudad de México',
      house_number: '157',
      postal_code: '03300',
      label: 'Avenida Nevado 157, Portales Sur, 03300 Benito Juárez, CMX, México',
      city: 'Benito Juárez',
      district: 'Portales Sur',
      street: 'Avenida Nevado',
    },
    duration: 300,
    timewindows: [[28800, 46800]],
  },
  {
    label: 'RESTAURANTE PUJOL',
    location: {
      lat: 19.34854,
      lng: -99.15929,
      country: 'México',
      country_code: 'MEX',
      state: 'Ciudad de México',
      house_number: '44',
      postal_code: '04020',
      label: 'Calle Ignacio Vallarta 44, La Concepción, 04020 Coyoacán, CMX, México',
      city: 'Coyoacán',
      district: 'La Concepción',
      street: 'Calle Ignacio Vallarta',
    },
    duration: 300,
    timewindows: [[28800, 46800]],
  },
  {
    label: 'RESTAURANTE DON JULIO',
    location: {
      lat: 19.34638,
      lng: -99.17012,
      country: 'México',
      country_code: 'MEX',
      state: 'Ciudad de México',
      house_number: '75',
      postal_code: '04010',
      label: 'Calle de Pino 75, Santa Catarina, 04010 Coyoacán, CMX, México',
      city: 'Coyoacán',
      district: 'Santa Catarina',
      street: 'Calle de Pino',
    },
    duration: 300,
    timewindows: [[28800, 46800]],
  },
  {
    label: 'RESTAURANTE BORAGO',
    location: {
      lat: 19.33439,
      lng: -99.20597,
      country: 'México',
      country_code: 'MEX',
      state: 'Ciudad de México',
      postal_code: '01080',
      label: 'Calle José María Morelos, Tizapán, 01080 Álvaro Obregón, CMX, México',
      city: 'Álvaro Obregón',
      district: 'Tizapán',
      street: 'Calle José María Morelos',
    },
    duration: 300,
    timewindows: [[28800, 45000]],
  },
  {
    label: 'RESTAURANTE A CASA DO PORCO',
    location: {
      lat: 19.36063,
      lng: -99.2015,
      country: 'México',
      country_code: 'MEX',
      state: 'Ciudad de México',
      house_number: '15',
      postal_code: '01710',
      label: 'Calle Andrés Henestrosa 15, Las Águilas, 01710 Álvaro Obregón, CMX, México',
      city: 'Álvaro Obregón',
      district: 'Las Águilas',
      street: 'Calle Andrés Henestrosa',
    },
    duration: 600,
    timewindows: [[28800, 45000]],
  },
  {
    label: 'RESTAURANTE EL CHATO',
    location: {
      lat: 19.32986,
      lng: -99.2153,
      country: 'México',
      country_code: 'MEX',
      state: 'Ciudad de México',
      house_number: '3',
      postal_code: '10200',
      label: 'Cerrada Eucaliptos 3, San Jerónimo Lídice, 10200 La Magdalena Contreras, CMX, México',
      city: 'La Magdalena Contreras',
      district: 'San Jerónimo Lídice',
      street: 'Cerrada Eucaliptos',
    },
    duration: 300,
    timewindows: [[28800, 45000]],
  },
  {
    label: 'RESTAURANTE LEO',
    location: {
      lat: 19.30924,
      lng: -99.19372,
      country: 'México',
      country_code: 'MEX',
      state: 'Ciudad de México',
      house_number: '215',
      postal_code: '04500',
      label: 'Avenida Zacatepetl 215, Ote. Jardines del Pedregal, 04500 Coyoacán, CMX, México',
      city: 'Coyoacán',
      district: 'Ote. Jardines del Pedregal',
      street: 'Avenida Zacatepetl',
    },
    duration: 300,
    timewindows: [[28800, 45000]],
  },
  {
    label: 'RESTAURANTE OSSO',
    location: {
      lat: 19.40675,
      lng: -99.12078,
      country: 'México',
      country_code: 'MEX',
      state: 'Ciudad de México',
      house_number: '149',
      postal_code: '15860',
      label:
        'Calzada Guillermo Prieto 149, La Magdalena Mixiuhca, 15860 Venustiano Carranza, CMX, México',
      city: 'Venustiano Carranza',
      district: 'La Magdalena Mixiuhca',
      street: 'Calzada Guillermo Prieto',
    },
    duration: 300,
    timewindows: [[28800, 46800]],
  },
  {
    label: 'RESTAURANTE D.O.M.',
    location: {
      lat: 19.39123,
      lng: -99.17822,
      country: 'México',
      country_code: 'MEX',
      state: 'Ciudad de México',
      house_number: '133',
      postal_code: '03810',
      label: 'Louisiana 133, Nápoles, 03810 Benito Juárez, CMX, México',
      city: 'Benito Juárez',
      district: 'Nápoles',
      street: 'Louisiana',
    },
    duration: 600,
    timewindows: [[28800, 46800]],
  },
  {
    label: 'RESTAURANTE QUINTONIL',
    location: {
      lat: 19.34274,
      lng: -99.11516,
      country: 'México',
      country_code: 'MEX',
      state: 'Ciudad de México',
      postal_code: '09800',
      label: 'Canal Nacional, Barrio San Antonio Culhuacán, 09800 Iztapalapa, CMX, México',
      city: 'Iztapalapa',
      district: 'Barrio San Antonio Culhuacán',
      street: 'Canal Nacional',
    },
    duration: 300,
    timewindows: [[28800, 46800]],
  },
  {
    label: 'RESTAURANTE ISOLINA',
    location: {
      lat: 19.34274,
      lng: -99.11516,
      country: 'México',
      country_code: 'MEX',
      state: 'Ciudad de México',
      postal_code: '09800',
      label: 'Canal Nacional, Barrio San Antonio Culhuacán, 09800 Iztapalapa, CMX, México',
      city: 'Iztapalapa',
      district: 'Barrio San Antonio Culhuacán',
      street: 'Canal Nacional',
    },
    duration: 420,
    timewindows: [[28800, 46800]],
  },
  {
    label: 'RESTAURANTE ASTRID Y GASTON',
    location: {
      lat: 19.35499,
      lng: -99.16613,
      country: 'México',
      country_code: 'MEX',
      state: 'Ciudad de México',
      house_number: '302',
      postal_code: '04100',
      label: 'Calle Berlín 302, Del Carmen, 04100 Coyoacán, CMX, México',
      city: 'Coyoacán',
      district: 'Del Carmen',
      street: 'Calle Berlín',
    },
    duration: 480,
    timewindows: [[28800, 46800]],
  },
  {
    label: 'RESTAURANTE ALCALDE',
    location: {
      lat: 19.36429,
      lng: -99.18386,
      country: 'México',
      country_code: 'MEX',
      state: 'Ciudad de México',
      house_number: '119',
      postal_code: '03900',
      label: 'Calle Damas 119, San José Insurgentes, 03900 Benito Juárez, CMX, México',
      city: 'Benito Juárez',
      district: 'San José Insurgentes',
      street: 'Calle Damas',
    },
    duration: 300,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE PANGEA',
    location: {
      lat: 19.4225,
      lng: -99.15291,
      country: 'México',
      country_code: 'MEX',
      state: 'Ciudad de México',
      house_number: '62',
      postal_code: '06720',
      label: 'Dr. Manuel Carmona y Valle 62, Doctores, 06720 Cuauhtémoc, CMX, México',
      city: 'Cuauhtémoc',
      district: 'Doctores',
      street: 'Dr. Manuel Carmona y Valle',
    },
    duration: 300,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE SUD 777',
    location: {
      lat: 19.41507,
      lng: -99.17785,
      country: 'México',
      country_code: 'MEX',
      state: 'Ciudad de México',
      house_number: '101',
      postal_code: '06140',
      label: 'Pachuca 101, Condesa, 06140 Cuauhtémoc, CMX, México',
      city: 'Cuauhtémoc',
      district: 'Condesa',
      street: 'Pachuca',
    },
    duration: 300,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE MAITO',
    location: {
      lat: 19.3736,
      lng: -99.19409,
      country: 'México',
      country_code: 'MEX',
      state: 'Ciudad de México',
      house_number: '5',
      postal_code: '01460',
      label: 'Alejandro Allori 5, Alfonso XIII, 01460 Álvaro Obregón, CMX, México',
      city: 'Álvaro Obregón',
      district: 'Alfonso XIII',
      street: 'Alejandro Allori',
    },
    duration: 300,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE MANI',
    location: {
      lat: 19.3588,
      lng: -99.16769,
      country: 'México',
      country_code: 'MEX',
      state: 'Ciudad de México',
      postal_code: '03330',
      label: 'Avenida Río Churubusco, Xoco, 03330 Benito Juárez, CMX, México',
      city: 'Benito Juárez',
      district: 'Xoco',
      street: 'Avenida Río Churubusco',
    },
    duration: 600,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE RAFAEL',
    location: {
      lat: 19.30582,
      lng: -99.25353,
      country: 'México',
      country_code: 'MEX',
      state: 'Ciudad de México',
      house_number: '28',
      postal_code: '10640',
      label: 'Prolongación Nogal 28, Pueblo Nuevo Alto, 10640 La Magdalena Contreras, CMX, México',
      city: 'La Magdalena Contreras',
      district: 'Pueblo Nuevo Alto',
      street: 'Prolongación Nogal',
    },
    duration: 300,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE MISHIGUENE',
    location: {
      lat: 19.34829,
      lng: -99.18981,
      country: 'México',
      country_code: 'MEX',
      state: 'Ciudad de México',
      house_number: '89',
      postal_code: '01000',
      label: 'Calle Cracovia 89, San Ángel, 01000 Álvaro Obregón, CMX, México',
      city: 'Álvaro Obregón',
      district: 'San Ángel',
      street: 'Calle Cracovia',
    },
    duration: 300,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE KJOLLE',
    location: {
      lat: 19.31559,
      lng: -99.13575,
      country: 'México',
      country_code: 'MEX',
      state: 'Ciudad de México',
      house_number: '153',
      postal_code: '04870',
      label: 'Calle 7 153, Espartaco, 04870 Coyoacán, CMX, México',
      city: 'Coyoacán',
      district: 'Espartaco',
      street: 'Calle 7',
    },
    duration: 300,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE HARRY SASSON',
    location: {
      lat: 19.35044,
      lng: -99.12077,
      country: 'México',
      country_code: 'MEX',
      state: 'Ciudad de México',
      house_number: '19',
      postal_code: '04250',
      label: 'Avenida Río Churubusco 19, Paseos de Taxqueña, 04250 Coyoacán, CMX, México',
      city: 'Coyoacán',
      district: 'Paseos de Taxqueña',
      street: 'Avenida Río Churubusco',
    },
    duration: 600,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE OTEQUE',
    location: {
      lat: 19.41767,
      lng: -99.16664,
      country: 'México',
      country_code: 'MEX',
      state: 'Ciudad de México',
      house_number: '296',
      postal_code: '06700',
      label: 'Calle Tabasco 296, Roma Norte, 06700 Cuauhtémoc, CMX, México',
      city: 'Cuauhtémoc',
      district: 'Roma Norte',
      street: 'Calle Tabasco',
    },
    duration: 300,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE LASAI',
    location: {
      lat: 19.38995,
      lng: -99.15123,
      country: 'México',
      country_code: 'MEX',
      state: 'Ciudad de México',
      house_number: '1703',
      postal_code: '03023',
      label: 'Luz Saviñon 1703, Narvarte Oriente, 03023 Benito Juárez, CMX, México',
      city: 'Benito Juárez',
      district: 'Narvarte Oriente',
      street: 'Luz Saviñon',
    },
    duration: 540,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE TEGUI',
    location: {
      lat: 19.30533,
      lng: -99.14846,
      country: 'México',
      country_code: 'MEX',
      state: 'Ciudad de México',
      house_number: '9',
      postal_code: '04650',
      label: 'La Flores 9, Buenavista, 04650 Coyoacán, CMX, México',
      city: 'Coyoacán',
      district: 'Buenavista',
      street: 'La Flores',
    },
    duration: 600,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE LA MAR',
    location: {
      lat: 19.30099,
      lng: -99.15532,
      country: 'México',
      country_code: 'MEX',
      state: 'Ciudad de México',
      house_number: '130',
      postal_code: '04730',
      label: 'Pablo de la Llave 130, Cantil del Pedregal, 04730 Coyoacán, CMX, México',
      city: 'Coyoacán',
      district: 'Cantil del Pedregal',
      street: 'Pablo de la Llave',
    },
    duration: 300,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE ROSSETTA',
    location: {
      lat: 19.30553,
      lng: -99.13313,
      country: 'México',
      country_code: 'MEX',
      state: 'Ciudad de México',
      house_number: '32',
      postal_code: '14310',
      label: 'Roque Estrada 32, Belisario Domínguez, 14310 Tlalpan, CMX, México',
      city: 'Tlalpan',
      district: 'Belisario Domínguez',
      street: 'Roque Estrada',
    },
    duration: 300,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE MAXIMO BISTROT',
    location: {
      lat: 19.30553,
      lng: -99.13313,
      country: 'México',
      country_code: 'MEX',
      state: 'Ciudad de México',
      house_number: '32',
      postal_code: '14310',
      label: 'Roque Estrada 32, Belisario Domínguez, 14310 Tlalpan, CMX, México',
      city: 'Tlalpan',
      district: 'Belisario Domínguez',
      street: 'Roque Estrada',
    },
    duration: 300,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE CHILA',
    location: {
      lat: 19.3868,
      lng: -99.11696,
      country: 'México',
      country_code: 'MEX',
      state: 'Ciudad de México',
      house_number: '6',
      postal_code: '08920',
      label: 'Calle Estudios Churubusco 6, Jardines Tecma, 08920 Iztacalco, CMX, México',
      city: 'Iztacalco',
      district: 'Jardines Tecma',
      street: 'Calle Estudios Churubusco',
    },
    duration: 300,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE AMBROSIA',
    location: {
      lat: 19.3868,
      lng: -99.11696,
      country: 'México',
      country_code: 'MEX',
      state: 'Ciudad de México',
      house_number: '6',
      postal_code: '08920',
      label: 'Calle Estudios Churubusco 6, Jardines Tecma, 08920 Iztacalco, CMX, México',
      city: 'Iztacalco',
      district: 'Jardines Tecma',
      street: 'Calle Estudios Churubusco',
    },
    duration: 300,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE NICOS',
    location: {
      lat: 19.36342,
      lng: -99.12559,
      country: 'México',
      country_code: 'MEX',
      state: 'Ciudad de México',
      house_number: '39',
      postal_code: '09089',
      label: 'Avenida Unidad Modelo 39, Unidad Modelo, 09089 Iztapalapa, CMX, México',
      city: 'Iztapalapa',
      district: 'Unidad Modelo',
      street: 'Avenida Unidad Modelo',
    },
    duration: 600,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE LE CHIQUE',
    location: {
      lat: 19.37236,
      lng: -99.12217,
      country: 'México',
      country_code: 'MEX',
      state: 'Ciudad de México',
      house_number: '1433',
      postal_code: '09400',
      label: 'Calzada de la Viga 1433, La Viga, 09400 Iztapalapa, CMX, México',
      city: 'Iztapalapa',
      district: 'La Viga',
      street: 'Calzada de la Viga',
    },
    duration: 300,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE PARADOR LA HUELLA',
    location: {
      lat: 19.41346,
      lng: -99.18644,
      country: 'México',
      country_code: 'MEX',
      state: 'Ciudad de México',
      house_number: '38',
      postal_code: '11850',
      label:
        'Calle General Zuazua 38, San Miguel Chapultepec I Sección, 11850 Miguel Hidalgo, CMX, México',
      city: 'Miguel Hidalgo',
      district: 'San Miguel Chapultepec I Sección',
      street: 'Calle General Zuazua',
    },
    duration: 300,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE LE PATIO',
    location: {
      lat: 19.36791,
      lng: -99.21796,
      country: 'México',
      country_code: 'MEX',
      state: 'Ciudad de México',
      house_number: '182',
      postal_code: '01430',
      label: 'Calle de la Romería 182, Colina del Sur, 01430 Álvaro Obregón, CMX, México',
      city: 'Álvaro Obregón',
      district: 'Colina del Sur',
      street: 'Calle de la Romería',
    },
    duration: 300,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE OLIMPE',
    location: {
      lat: 19.35622,
      lng: -99.18842,
      country: 'México',
      country_code: 'MEX',
      state: 'Ciudad de México',
      house_number: '107',
      postal_code: '01020',
      label: 'Calle Ricardo Palmerín 107, Guadalupe Inn, 01020 Álvaro Obregón, CMX, México',
      city: 'Álvaro Obregón',
      district: 'Guadalupe Inn',
      street: 'Calle Ricardo Palmerín',
    },
    duration: 600,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE MIL',
    location: {
      lat: 19.35122,
      lng: -99.15312,
      country: 'México',
      country_code: 'MEX',
      state: 'Ciudad de México',
      house_number: '61',
      postal_code: '04100',
      label: 'Avenida Miguel Hidalgo y Costilla 61, Del Carmen, 04100 Coyoacán, CMX, México',
      city: 'Coyoacán',
      district: 'Del Carmen',
      street: 'Avenida Miguel Hidalgo y Costilla',
    },
    duration: 300,
    timewindows: [[46800, 57600]],
  },
  {
    label: 'RESTAURANTE 040',
    location: {
      lat: 19.40968,
      lng: -99.08221,
      country: 'México',
      country_code: 'MEX',
      state: 'Ciudad de México',
      house_number: '39',
      postal_code: '15020',
      label: 'Calle 71 39, Puebla, 15020 Venustiano Carranza, CMX, México',
      city: 'Venustiano Carranza',
      district: 'Puebla',
      street: 'Calle 71',
    },
    duration: 660,
    timewindows: [[46800, 57600]],
  },
  {
    label: 'RESTAURANTE LA DOCENA',
    location: {
      lat: 19.42872,
      lng: -99.10913,
      country: 'México',
      country_code: 'MEX',
      state: 'Ciudad de México',
      house_number: '29',
      postal_code: '15500',
      label:
        'Waldo Martín del Campo 29, Moctezuma 1A Sección, 15500 Venustiano Carranza, CMX, México',
      city: 'Venustiano Carranza',
      district: 'Moctezuma 1A Sección',
      street: 'Waldo Martín del Campo',
    },
    duration: 720,
    timewindows: [[46800, 57600]],
  },
  {
    label: 'RESTAURANTE MANU',
    location: {
      lat: 19.31463,
      lng: -99.1034,
      country: 'México',
      country_code: 'MEX',
      state: 'Ciudad de México',
      house_number: '5B',
      postal_code: '04480',
      label: 'Calle Rosario Castellanos 5B, CTM IX Culhuacán, 04480 Coyoacán, CMX, México',
      city: 'Coyoacán',
      district: 'CTM IX Culhuacán',
      street: 'Calle Rosario Castellanos',
    },
    duration: 300,
    timewindows: [[46800, 57600]],
  },
  {
    label: 'RESTAURANTE MOCOTO',
    location: {
      lat: 19.40775,
      lng: -99.1744,
      country: 'México',
      country_code: 'MEX',
      state: 'Ciudad de México',
      house_number: '73D',
      postal_code: '06100',
      label: 'Calle Saltillo 73D, Hipódromo, 06100 Cuauhtémoc, CMX, México',
      city: 'Cuauhtémoc',
      district: 'Hipódromo',
      street: 'Calle Saltillo',
    },
    duration: 300,
    timewindows: [[46800, 57600]],
  },
  {
    label: 'RESTAURANTE OSAKA',
    location: {
      lat: 19.4213,
      lng: -99.24692,
      country: 'México',
      country_code: 'MEX',
      state: 'México',
      house_number: '52',
      postal_code: '52784',
      label: 'Bosque del Secreto 52, La Herradura, 52784 Huixquilucan, Edomex, México',
      city: 'Huixquilucan',
      district: 'La Herradura',
      street: 'Bosque del Secreto',
    },
    duration: 300,
    timewindows: [[46800, 57600]],
  },
  {
    label: 'RESTAURANTE ELENA',
    location: {
      lat: 19.39436,
      lng: -99.28862,
      country: 'México',
      country_code: 'MEX',
      state: 'México',
      postal_code: '52763',
      label: 'Hacienda de las Palmas, Huixquilucan, Edomex, México',
      city: 'Huixquilucan',
      district: 'Hacienda de las Palmas',
    },
    duration: 300,
    timewindows: [[46800, 57600]],
  },
  {
    label: 'RESTAURANTE GRAN DABBANG',
    location: {
      lat: 19.41677,
      lng: -99.17601,
      country: 'México',
      country_code: 'MEX',
      state: 'Ciudad de México',
      house_number: '29',
      postal_code: '06140',
      label: 'Avenida Mazatlán 29, Condesa, 06140 Cuauhtémoc, CMX, México',
      city: 'Cuauhtémoc',
      district: 'Condesa',
      street: 'Avenida Mazatlán',
    },
    duration: 300,
    timewindows: [[46800, 57600]],
  },
  {
    label: 'RESTAURANTE 99',
    location: {
      lat: 19.39928,
      lng: -99.14776,
      country: 'México',
      country_code: 'MEX',
      state: 'Ciudad de México',
      house_number: '60',
      postal_code: '03023',
      label: 'Zempoala 60, Narvarte Oriente, 03023 Benito Juárez, CMX, México',
      city: 'Benito Juárez',
      district: 'Narvarte Oriente',
      street: 'Zempoala',
    },
    duration: 600,
    timewindows: [[46800, 57600]],
  },
  {
    label: 'RESTAURANTE MALABAR',
    location: {
      lat: 19.33756,
      lng: -99.10775,
      country: 'México',
      country_code: 'MEX',
      state: 'Ciudad de México',
      house_number: '2',
      postal_code: '09800',
      label: 'Calle Marcos I Brunnel 2, Pueblo Culhuacán, 09800 Iztapalapa, CMX, México',
      city: 'Iztapalapa',
      district: 'Pueblo Culhuacán',
      street: 'Calle Marcos I Brunnel',
    },
    duration: 300,
    timewindows: [[46800, 57600]],
  },
  {
    label: 'RESTAURANTE MAYTA',
    location: {
      lat: 19.2805,
      lng: -99.11426,
      country: 'México',
      country_code: 'MEX',
      state: 'Ciudad de México',
      house_number: '27',
      postal_code: '16034',
      label: 'Canal Turístico 27, 18, 16034 Xochimilco, CMX, México',
      city: 'Xochimilco',
      district: '18',
      street: 'Canal Turístico',
    },
    duration: 300,
    timewindows: [[46800, 57600]],
  },
  {
    label: 'RESTAURANTE NARDA COMEDOR',
    location: {
      lat: 19.31463,
      lng: -99.1034,
      country: 'México',
      country_code: 'MEX',
      state: 'Ciudad de México',
      house_number: '5B',
      postal_code: '04480',
      label: 'Calle Rosario Castellanos 5B, CTM IX Culhuacán, 04480 Coyoacán, CMX, México',
      city: 'Coyoacán',
      district: 'CTM IX Culhuacán',
      street: 'Calle Rosario Castellanos',
    },
    duration: 300,
    timewindows: [[46800, 57600]],
  },
  {
    label: 'HOTEL NH',
    location: {
      lat: 19.31463,
      lng: -99.1034,
      country: 'México',
      country_code: 'MEX',
      state: 'Ciudad de México',
      house_number: '5B',
      postal_code: '04480',
      label: 'Calle Rosario Castellanos 5B, CTM IX Culhuacán, 04480 Coyoacán, CMX, México',
      city: 'Coyoacán',
      district: 'CTM IX Culhuacán',
      street: 'Calle Rosario Castellanos',
    },
    duration: 600,
    timewindows: [[46800, 57600]],
  },
  {
    label: 'HOTEL MARIA BONITA',
    location: {
      lat: 19.37343,
      lng: -99.1425,
      country: 'México',
      country_code: 'MEX',
      state: 'Ciudad de México',
      house_number: '27',
      postal_code: '03660',
      label: 'Calzada San Simón 27, San Simón Ticumac, 03660 Benito Juárez, CMX, México',
      city: 'Benito Juárez',
      district: 'San Simón Ticumac',
      street: 'Calzada San Simón',
    },
    duration: 300,
    timewindows: [[46800, 57600]],
  },
  {
    label: 'HOTEL W',
    location: {
      lat: 19.32294,
      lng: -99.20213,
      country: 'México',
      country_code: 'MEX',
      state: 'Ciudad de México',
      house_number: '453',
      postal_code: '01900',
      label: 'Iglesia 453, Jardines del Pedregal, 01900 Álvaro Obregón, CMX, México',
      city: 'Álvaro Obregón',
      district: 'Jardines del Pedregal',
      street: 'Iglesia',
    },
    duration: 780,
    timewindows: [[46800, 57600]],
  },
  {
    label: 'HOTEL SHERATON',
    location: {
      lat: 19.31463,
      lng: -99.1034,
      country: 'México',
      country_code: 'MEX',
      state: 'Ciudad de México',
      house_number: '5B',
      postal_code: '04480',
      label: 'Calle Rosario Castellanos 5B, CTM IX Culhuacán, 04480 Coyoacán, CMX, México',
      city: 'Coyoacán',
      district: 'CTM IX Culhuacán',
      street: 'Calle Rosario Castellanos',
    },
    duration: 840,
    timewindows: [[46800, 57600]],
  },
  {
    label: 'BAR AMSTERDAM',
    location: {
      lat: 19.31463,
      lng: -99.1034,
      country: 'México',
      country_code: 'MEX',
      state: 'Ciudad de México',
      house_number: '5B',
      postal_code: '04480',
      label: 'Calle Rosario Castellanos 5B, CTM IX Culhuacán, 04480 Coyoacán, CMX, México',
      city: 'Coyoacán',
      district: 'CTM IX Culhuacán',
      street: 'Calle Rosario Castellanos',
    },
    duration: 300,
    timewindows: [[46800, 57600]],
  },
  {
    label: 'RESTAURANTE COLOMBIA PAN Y SABOR',
    location: {
      lat: 19.38472,
      lng: -99.25375,
      country: 'México',
      country_code: 'MEX',
      state: 'Ciudad de México',
      house_number: '9',
      postal_code: '05120',
      label:
        'Privada de Tamarindos 9, Bosques de las Lomas, 05120 Cuajimalpa de Morelos, CMX, México',
      city: 'Cuajimalpa de Morelos',
      district: 'Bosques de las Lomas',
      street: 'Privada de Tamarindos',
    },
    duration: 300,
    timewindows: [[46800, 57600]],
  },
  {
    label: 'RESTAURANTE LA VIETNAMITA',
    location: {
      lat: 19.38333,
      lng: -99.25844,
      country: 'México',
      country_code: 'MEX',
      state: 'Ciudad de México',
      house_number: '270',
      postal_code: '05120',
      label:
        'Bosque de Tabachines 270, Bosques de las Lomas, 05120 Cuajimalpa de Morelos, CMX, México',
      city: 'Cuajimalpa de Morelos',
      district: 'Bosques de las Lomas',
      street: 'Bosque de Tabachines',
    },
    duration: 300,
    timewindows: [[28800, 46800]],
  },
  {
    label: 'RESTAURANTE EL TLAXCAL',
    location: {
      lat: 19.35185,
      lng: -99.18123,
      country: 'México',
      country_code: 'MEX',
      state: 'Ciudad de México',
      house_number: '150',
      postal_code: '01030',
      label: 'Avenida Vito Alessio Robles 150, Florida, 01030 Álvaro Obregón, CMX, México',
      city: 'Álvaro Obregón',
      district: 'Florida',
      street: 'Avenida Vito Alessio Robles',
    },
    duration: 300,
    timewindows: [[28800, 46800]],
  },
  {
    label: 'RESTAURANTE LA CHILANGUITA',
    location: {
      lat: 19.37,
      lng: -99.1756,
      country: 'México',
      country_code: 'MEX',
      state: 'Ciudad de México',
      house_number: '1717',
      postal_code: '03104',
      label: 'Patricio Sanz 1717, Del Valle Sur, 03104 Benito Juárez, CMX, México',
      city: 'Benito Juárez',
      district: 'Del Valle Sur',
      street: 'Patricio Sanz',
    },
    duration: 600,
    timewindows: [[28800, 46800]],
  },
  {
    label: 'RESTAURANTE LA COSA NOSTRA',
    location: {
      lat: 19.50611,
      lng: -99.20074,
      country: 'México',
      country_code: 'MEX',
      state: 'Ciudad de México',
      postal_code: '02100',
      label: 'Tierra Caliente, El Rosario, 02100 Azcapotzalco, CMX, México',
      city: 'Azcapotzalco',
      district: 'El Rosario',
      street: 'Tierra Caliente',
    },
    duration: 300,
    timewindows: [[28800, 46800]],
  },
  {
    label: 'RESTAURANTE FISHERS',
    location: {
      lat: 19.52576,
      lng: -99.19417,
      country: 'México',
      country_code: 'MEX',
      state: 'México',
      house_number: '112',
      postal_code: '54075',
      label:
        'Avenida Ixtacala 112, Los Reyes Ixtacala 2da. Sección, 54075 Tlalnepantla de Baz, Edomex, México',
      city: 'Tlalnepantla de Baz',
      district: 'Los Reyes Ixtacala 2da. Sección',
      street: 'Avenida Ixtacala',
    },
    duration: 300,
    timewindows: [[28800, 46800]],
  },
  {
    label: 'RESTAURANTE DE TAPAS',
    location: {
      lat: 19.37718,
      lng: -99.09364,
      country: 'México',
      country_code: 'MEX',
      state: 'Ciudad de México',
      postal_code: '09040',
      label: 'Central de Abastos, Iztapalapa, CMX, México',
      city: 'Iztapalapa',
      district: 'Central de Abastos',
    },
    duration: 300,
    timewindows: [[28800, 46800]],
  },
  {
    label: 'RESTAURANTE LAS CAZUELAS',
    location: {
      lat: 19.38725,
      lng: -99.08391,
      country: 'México',
      country_code: 'MEX',
      state: 'Ciudad de México',
      house_number: '48',
      postal_code: '09030',
      label: 'Avenida Río Tecolutla 48, Los Paseos de Churubusco, 09030 Iztapalapa, CMX, México',
      city: 'Iztapalapa',
      district: 'Los Paseos de Churubusco',
      street: 'Avenida Río Tecolutla',
    },
    duration: 600,
    timewindows: [[28800, 46800]],
  },
  {
    label: 'RESTAURANTE PALOMARES',
    location: {
      lat: 19.48562,
      lng: -99.08162,
      country: 'México',
      country_code: 'MEX',
      state: 'Ciudad de México',
      house_number: '111',
      postal_code: '07530',
      label:
        'Camino de la Prosperidad A 111, Campestre Aragón, 07530 Gustavo A Madero, CMX, México',
      city: 'Gustavo A Madero',
      district: 'Campestre Aragón',
      street: 'Camino de la Prosperidad A',
    },
    duration: 300,
    timewindows: [[28800, 46800]],
  },
  {
    label: 'RESTAURANTE SOUL',
    location: {
      lat: 19.46647,
      lng: -99.17923,
      country: 'México',
      country_code: 'MEX',
      state: 'Ciudad de México',
      house_number: '208',
      postal_code: '02080',
      label: 'Calle Ignacio Allende 208, Clavería, 02080 Azcapotzalco, CMX, México',
      city: 'Azcapotzalco',
      district: 'Clavería',
      street: 'Calle Ignacio Allende',
    },
    duration: 900,
    timewindows: [[28800, 46800]],
  },
  {
    label: 'RESTAURANTE CLORODMIRA',
    location: {
      lat: 19.45592,
      lng: -99.12955,
      country: 'México',
      country_code: 'MEX',
      state: 'Ciudad de México',
      house_number: '45',
      postal_code: '06250',
      label:
        'Calzada de los Misterios 45, Ex-Hipódromo de Peralvillo, 06250 Cuauhtémoc, CMX, México',
      city: 'Cuauhtémoc',
      district: 'Ex-Hipódromo de Peralvillo',
      street: 'Calzada de los Misterios',
    },
    duration: 960,
    timewindows: [
      [32400, 50400],
      [61200, 72000],
    ],
  },
];
