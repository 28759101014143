import { Grid } from '@material-ui/core';
import { IProjectData } from 'highway-api/dist/common/interfaces/projects';
import { IServiceDataExtended } from 'highway-api/dist/common/interfaces/services';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { SERVICE_STATUS } from '../../constants/Services';
import configuration from '../../services/configuration';
import CustomFields from '../custom-fields/CustomFields';
import { Label } from '../form-fields/FormFields.styled';
import FormattedMessage from '../FormattedMessageCustom';
import Block from './Block';
import { checkR, renderInfo } from './Common';

const Signature = styled.img`
  width: calc(100% - 14px);
  height: 200px;
  border: 2px dashed #ababab;
  border-radius: 16px;
`;

const ImageContainer = styled.div`
  width: 64px;
  height: 64px;
  margin-right: 6px;
  border-radius: 16px;
  border: 1px solid #ababab;
  ${(props: any) => `background: url(${props.imgUri}) no-repeat center center;`}
  background-size: cover;
  cursor: pointer;
`;

ImageContainer.propTypes = {
  // @ts-expect-error ts-migrate(2322) FIXME: Type '{ imgUri: PropTypes.Requireable<string>; }' ... Remove this comment to see the full error message
  imgUri: PropTypes.string,
};

ImageContainer.defaultProps = {
  // @ts-expect-error ts-migrate(2322) FIXME: Type '{ imgUri: string; }' is not assignable to ty... Remove this comment to see the full error message
  imgUri: '',
};

type FeedbackProps = {
  service: IServiceDataExtended;
  activeProject: IProjectData;
  preffix: string;
  publicKey: string;
};

const Feedback = ({ activeProject, service, preffix, publicKey }: FeedbackProps) => {
  
  const statusString = `${preffix}.status.${service.status}`;
  const translatedStatus = <FormattedMessage id={statusString} />;

  const cancelReasons = [
    {
      translationId: 'service.data.details.feedback.reason.nobody',
      tag: 'nobody',
    },
    {
      translationId: 'service.data.details.feedback.reason.wrong_address',
      tag: 'wrong_address',
    },
    {
      translationId: 'service.data.details.feedback.reason.other',
      tag: 'other',
    },
  ];

  const { custom_fields: customFields, view } = activeProject;
  const { service_report_completed: serviceReportCompleted, service_report_canceled: serviceReportCanceled } = view!;
  const { images, comments, reason, signature, completed_custom_fields: completedCustomFields, canceled_custom_fields: canceledCustomFields } =
    (service && service.feedback) || {};

  return (
    <Block title={<FormattedMessage id="clients.details.group.operation" />}>
      {renderInfo(preffix, 'status', { status: translatedStatus })}
      {renderInfo(preffix, 'done_at', service)}

      {((service.status === SERVICE_STATUS.completed &&
        checkR(serviceReportCompleted.comments)) ||
        (service.status === SERVICE_STATUS.canceled &&
          checkR(serviceReportCanceled.comments))) && (
        <>
          <Label>
            <FormattedMessage id={`${preffix}.feedback.comments`} />
          </Label>
          <p style={{ marginTop: '0px' }}>
            {comments ? (
              <span>{comments}</span>
            ) : (
              <span style={{ fontSize: '12px' }}>
                <FormattedMessage id="none" />
              </span>
            )}
          </p>
        </>
      )}

      {service.status === SERVICE_STATUS.completed && checkR(serviceReportCompleted.signature) && (
        <>
          <Label>
            <FormattedMessage id={`${preffix}.feedback.signature`} />
          </Label>
          {signature ? (
            <Signature src={signature} alt="Feedback signature" />
          ) : (
            <p style={{ marginTop: '0px' }}>
              <span style={{ fontSize: '12px' }}>
                <FormattedMessage id="none" />
              </span>
            </p>
          )}
        </>
      )}
      {service.status === SERVICE_STATUS.canceled && checkR(serviceReportCanceled.canceled_categorical) && (
        <>
          <Label>
            <FormattedMessage id={`${preffix}.feedback.reason`} />
          </Label>
          <p style={{ marginTop: '0px' }}>
            {reason ? (
              (() => {
                const foundReason = cancelReasons.find(
                  (cancelReason: any) => cancelReason.tag === reason
                );
                return foundReason ? (
                  <FormattedMessage id={`${foundReason.translationId}`} />
                ) : null;
              })()
            ) : (
              <p style={{ marginTop: '0px' }}>
                <span style={{ fontSize: '12px' }}>
                  <FormattedMessage id="none" />
                </span>
              </p>
            )}
          </p>
        </>
      )}

      {((service.status === SERVICE_STATUS.completed &&
        checkR(serviceReportCompleted.pictures)) ||
        (service.status === SERVICE_STATUS.canceled &&
          checkR(serviceReportCanceled.pictures))) && (
        <>
          <Label>
            <FormattedMessage id={`${preffix}.feedback.images`} />
          </Label>
          <Grid container>
            {images && images.length > 0 ? (
              images.map((imageId: any) => {
                const imageUri = `${configuration.HIGHWAY_BACK_END}/api/v1/service/${service.id}/report/image/${imageId}/public?public_key=${publicKey}`;
                return (
                  <ImageContainer
                    key={imageId}
                    // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
                    imgUri={imageUri}
                    onClick={() => window.open(imageUri, '_blank')}
                  />
                );
              })
            ) : (
              <p style={{ marginTop: '0px' }}>
                <span style={{ fontSize: '12px' }}>
                  <FormattedMessage id="none" />
                </span>
              </p>
            )}
          </Grid>
        </>
      )}

      {customFields && customFields.service_report_completed.length > 0 && service.status === SERVICE_STATUS.completed && completedCustomFields ? (
        <CustomFields
          readOnly
          customFields={completedCustomFields}
          projectCustomFields={customFields.service_report_completed.filter(
            (field: any) =>
              Object.keys(completedCustomFields)
                .map((key) => key)
                .indexOf(field.id) > -1
          )}
        />
      ) : null}

      {customFields && customFields.service_report_canceled.length > 0 && service.status === SERVICE_STATUS.canceled && canceledCustomFields ? (
        <CustomFields
          readOnly
          customFields={canceledCustomFields}
          projectCustomFields={customFields.service_report_canceled.filter(
            (field: any) =>
              Object.keys(canceledCustomFields)
                .map((key) => key)
                .indexOf(field.id) > -1
          )}
        />
      ) : null}
    </Block>
  );
};

export default Feedback;
