import getHighway from '../../services/highway';
import { post } from '../../services/requests';
import constants from '../constants';
import { projectHelpers } from '../helpers';
import store from '../store/index';
import { getPlan } from './ActivePlan';

export const setTableColumns = (columns: any) => {
  return (dispatch: any) => {
    dispatch({
      type: constants.Services.SERVICES_SET_TABLE_COLUMNS,
      payload: columns,
    });
  };
};

export const createServicesToAPlan = (services: any, planId: any) => {
  return (dispatch: any) => {
    return post(`/api/v1/services?plan_id=${planId}`, services, {
      Authorization: `Bearer ${store.getState().user.token}`,
    })
      .then((response) => {
        dispatch(getPlan(planId));
        dispatch({
          type: constants.Services.SERVICES_CREATE_SERVICES_SUCCESS,
          payload: response.data,
        });
      })
      .catch((error) => {
        dispatch({
          type: constants.Services.SERVICES_CREATE_SERVICES_ERROR,
          payload: error,
        });
      });
  };
};

export const updateService = (serviceId: any, service: any) => {
  return (dispatch: any) => {
    const highway = getHighway(store.getState().user.token);
    highway.service
      .update(serviceId, service)
      .then((response) => {
        dispatch(getPlan(response.plan_id));

        const { activePlan } = store.getState();
        if (
          activePlan.detailView &&
          activePlan.detailView.type === 'service' &&
          activePlan.detailView.data &&
          activePlan.detailView.data.id === serviceId
        ) {
          dispatch({
            type: constants.ActivePlan.ACTIVE_PLAN_SET_DETAIL_VIEW,
            payload: {
              type: 'service',
              data: response,
            },
          });
        }
      })
      .catch((error) => {
        dispatch({
          type: constants.Services.SERVICES_UPDATE_SERVICE_ERROR,
          payload: error,
        });
      });
  };
};

export const deleteServices = (services: any) => {
  return (dispatch: any) => {
    const highway = getHighway(store.getState().user.token);
    Promise.all(services.map((s: any) => highway.service.delete(s.id))).finally(
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '(allResponses: any) => void' is ... Remove this comment to see the full error message
      (allResponses: any) => {
        dispatch(getPlan(services[0].plan_id));
        dispatch({
          type: constants.Services.SERVICES_DELETE_SERVICE_SUCCESS,
          payload: allResponses,
        });
      }
    );
  };
};

export const updateServices = (services: any, changes: any) => {
  return (dispatch: any) => {
    const highway = getHighway(store.getState().user.token);
    Promise.all(services.map((s: any) => highway.service.update(s.id, changes))).then(
      (allResponses: any) => {
        dispatch(getPlan(allResponses[0].plan_id));
        dispatch({
          type: constants.Services.SERVICES_UPDATE_SERVICES_SUCCESS,
          payload: allResponses,
        });
      }
    );
  };
};

export const saveServiceAsClient = (service: any) => {
  return (dispatch: any) => {
    const highway = getHighway(store.getState().user.token);
    const client = highway.client.fromService(service);
    highway.client
      .create(client, projectHelpers.getCurrentProjectId())
      .then((response) => {
        dispatch({
          type: constants.Clients.CLIENTS_SERVICE_AS_CLIENT_SUCCESS,
          payload: response,
        });
        dispatch(updateService(service.id, { client_id: response.id }));
      })
      .catch((error) => {
        dispatch({
          type: constants.Clients.CLIENTS_SERVICE_AS_CLIENT_ERROR,
          payload: error,
        });
      });
  };
};
