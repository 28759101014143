import { Grid, Select } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import { Button } from '@sm-highway-web/react-components';
import Logo from '../../../../assets/img/logo_email_footer.svg';

export const CustomLink = styled.span`
  cursor: pointer;
  color: #FF8200;
`;

interface ITabButton {
  selected: boolean;
}

export const TabButton = styled(Button)`
  box-shadow: none;
  border-radius: 8px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  color: #333940;
  background-color: ${(props: ITabButton) => props.selected ? 'white' : 'transparent' };
  :hover {
    background-color: ${(props: ITabButton) => props.selected ? 'white' : '#d5d5d5' };
    box-shadow: none;
  }
`;

export const CommunicationEmailContainer = styled.div`
  margin-bottom: 20px;
  min-width: 300px;
  color: #333940;
  border-radius: 8px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  background-color: white;
`;

export const CommunicationEmailContainerHeader = styled.div`
  padding: 4px 6px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #444444;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
`;

export const CommunicationEmailContainerBody = styled.div`
  display: flex;
  flex-direction: column;
  padding-bottom: 20px;
  background-color: #f9f9f9;
`;

export const CommunicationEmailBody = styled.div`
  width: 100%;
  max-width: 550px;
  background-color: white;
  border-bottom-left-radius: 16px;
  border-bottom-right-radius: 16px;
  min-height: 500px;
  position: relative;
`;

export const EmailAvatarStyled = styled.div`
  margin: 2px;
  padding: 4px;
  border-radius: 20px;
  :hover {
    margin: 0;
    border: 2px solid #FF8200;
  }
`;

const CommunicationEmailBodyFooterStyled = styled(Grid)`
  height: 40px;
  color: white;
  padding: 0 25px;
  width: 100%;
  margin-top: 20px;
  background-color: #050d1e;
  border-bottom-left-radius: 14px;
  border-bottom-right-radius: 14px;
  position: absolute;
  bottom: 0;
`;

export const CommunicationEmailBodyFooter = () => {
  return (
    <CommunicationEmailBodyFooterStyled container alignItems="center">
      <img src={Logo} alt="" height="22" />
      <span style={{ marginLeft: '8px', fontSize: '12px', color: '#cecdcd' }}>Powered by Smartmonkey®</span>
    </CommunicationEmailBodyFooterStyled>
  );
};

export const CommunicationEmailContainerFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: start;
  padding: 6px 22px;
  border-top: 1px solid #F1F1F1;
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
`;

export const SubstitutionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  padding-top: 8px;
`;

export const SubstitutionPillStyled = styled.div`
  cursor: pointer;
  padding: 4px 8px;
  line-height: 1;
  margin-right: 8px;
  border-radius: 15px;
  padding-bottom: 5px;
  margin-bottom: 8px;
  background-color: #9f9f9f;
  :hover {
    background-color: #d0d0d0;
  }
`;

type SubstitutionPillProps = {
  text: string | React.ReactElement;
  onSelect: (...args: any[]) => any;
};

export const SubstitutionPill = ({ text, onSelect }: SubstitutionPillProps) => {
  return (
    <SubstitutionPillStyled
      onClick={onSelect}
    >
      <span style={{ fontSize: '12px', color: 'white' }}>{text}</span>
    </SubstitutionPillStyled>
  );
};

export const PodSelect = styled(Select)`
  width: 200px;
  .MuiSelect-select {
    padding: 8px;
  }
`;

export default {};
