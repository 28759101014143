import { Chip, Grid, OutlinedInput } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React, { useEffect, useRef, useState } from 'react';
import FormattedMessage from '../FormattedMessageCustom';
import { Label, Placeholder } from './FormFields.styled';

const styles = () => ({
  editingChip: {
    marginRight: '4px',
    marginTop: '4px',
  },
  chip: {
    marginRight: '4px',
    marginTop: '4px',
    height: '34px',
  },
  children: {
    minHeight: '38px',
    '&:hover': {
      backgroundColor: '#EEEFF2',
    },
    borderRadius: '2px',
    width: 'calc(100% - 8px)',
  },
  noPadding: {
    padding: '2px 4px',
    height: '34px',
  },
});

type OwnFormArrayProps = {
  classes: {
    editingChip: string;
    noPadding: string;
    chip: string;
    children: string;
  };
  onUpdate: (...args: any[]) => any;
  label?: string | React.ReactElement | {};
  array?: string[];
};

type FormArrayProps = OwnFormArrayProps;

const FormArray = ({ classes, array, onUpdate, label }: FormArrayProps) => {
  const [currentVal, setCurrentVal] = useState(undefined);
  const [focused, setFocused] = useState(false);
  const formRef = useRef();

  const handleRemove = (idx: any) => {
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    array.splice(idx, 1);
    onUpdate(array);
  };

  const isRepeated = (current: any) => {
    let flag = false;
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    array.some((val: any) => {
      flag = flag || current === val;
      return flag;
    });
    return flag;
  };

  const handleFocusedKeyPress = async (event: any) => {
    switch (event.key) {
      case 'Enter':
        if (currentVal && currentVal !== '') {
          if (!isRepeated(currentVal)) {
            // @ts-expect-error ts-migrate(2569) FIXME: Type 'string[] | undefined' is not an array type o... Remove this comment to see the full error message
            onUpdate([...array, currentVal]);
            setCurrentVal(undefined);
          }
        }
        break;
      default:
        break;
    }
  };

  const handleClick = (evt: any) => {
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    if (formRef.current.contains(evt.target)) {
      setFocused(true);
    } else {
      setFocused(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  });

  return (
    <Grid container direction="column">
      {label && <Label>{label}</Label>}
      {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
      <Grid
        container
        ref={formRef}
        alignItems="center"
        className={!focused ? classes.children : ''}
        onBlur={(e: any) => {
          if (e.target.tagName === 'INPUT') {
            return;
          }
          setFocused(false);
        }}
      >
        <div>
          {/* @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'. */}
          {array.length > 0 ? (
            // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
            array.map((arrayElem: any, idx: any) => (
              <Chip
                key={arrayElem}
                className={classes.chip}
                onDelete={
                  focused
                    ? () => {
                        handleRemove(idx);
                      }
                    : undefined
                }
                label={arrayElem}
              />
            ))
          ) : (
            <>
              {!focused && (
                <Placeholder>
                  <FormattedMessage id="none" />
                </Placeholder>
              )}
            </>
          )}
        </div>
        {focused && (
          <Grid
            item
            style={{ width: '100%' }}
            // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
            key={`render-${array.length}`}
            onKeyDown={handleFocusedKeyPress}
          >
            <Grid container alignItems="center" className={classes.editingChip}>
              {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
              <OutlinedInput
                value={currentVal || ''}
                classes={{ input: classes.noPadding }}
                autoFocus
                fullWidth
                onChange={(evt) => {
                  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
                  setCurrentVal(evt.target.value);
                }}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

FormArray.defaultProps = {
  label: undefined,
};

FormArray.defaultProps = {
  array: [],
};

export default withStyles(styles)(FormArray);
