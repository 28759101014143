import React from 'react';
import styled from 'styled-components';

export const stepLighBgColor = '#FFFFFF';
export const stepDarkBgColor = '#333940';

const TooltipTitle = styled.div`
  font-size: 20px;
  color: #ff8200;
  text-align: center;
`;

const TooltipBody = styled.div`
  padding: 24px;
  background-color: ${(props: any) => (props.theme === `dark` ? stepDarkBgColor : stepLighBgColor)};
  border-radius: 12px;
  max-width: 350px;
`;

const TooltipContent = styled.div`
  color: ${(props: any) => (props.theme === `dark` ? `#E9E9E9` : `#333940`)};
  font-size: 16px;
  text-align: center;
  margin: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const TooltipFooter = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  .progress {
    color: ${(props: any) => (props.theme === `dark` ? `#AAAAAA` : `#333940`)};
    font-size: 12px;
  }
`;

const SkipButton = styled.button`
  border: none;
  color: ${(props: any) => (props.theme === `dark` ? `#AAAAAA` : `#333940`)};
  text-decoration: underline;
  outline: none;
  cursor: pointer;
  font-size: 12px;
  background: none;
`;

type Props = {
  index: number;
  step: any;
  skipProps: any;
  tooltipProps: any;
};

const CustomTooltip = ({ index, step, skipProps, tooltipProps }: Props) => {
  return (
    <TooltipBody {...tooltipProps} theme={step.theme}>
      {step.title && <TooltipTitle {...step.titleProps}>{step.title}</TooltipTitle>}

      <TooltipContent {...step.contentProps} theme={step.theme}>
        {step.content}
      </TooltipContent>

      <TooltipFooter theme={step.theme}>
        {step.skip && (
          <SkipButton {...skipProps} theme={step.theme}>
            {step.skipTitle}
          </SkipButton>
        )}

        {step.totalIndex ? (
          <span className="progress">
            <span>{index + 1}</span>
            <span>/</span>
            <span>{step.totalIndex}</span>
          </span>
        ) : null}
      </TooltipFooter>
    </TooltipBody>
  );
};

export default CustomTooltip;
