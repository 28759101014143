import constants from '../constants/index';

const initialState = {
  paymentModal: false,
};

function common(state = initialState, action: any) {
  switch (action.type) {
    case constants.Common.COMMON_CLOSE_PAYMENT_MODAL:
      return {
        ...state,
        paymentModal: false,
      };
    case constants.Common.COMMON_OPEN_PAYMENT_MODAL:
      return {
        ...state,
        paymentModal: true,
      };
    case constants.User.USER_REMOVE_AS_USER:
    case constants.User.USER_LOGOUT:
      return { ...initialState };
    default:
      return state;
  }
}
export default common;
