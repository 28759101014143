import { Menu, MenuItem, withStyles } from '@material-ui/core';
import { Map, Marker, Route } from '@sm-highway-web/react-components';
import { IRouteDataExtended } from 'highway-api/dist/common/interfaces/routes';
import { IServiceDataExtended } from 'highway-api/dist/common/interfaces/services';
import { debounce } from 'lodash';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { getRouteColor } from '../../../constants/Colors';
import { SERVICE_STATUS } from '../../../constants/Services';
import { isValidLocation, isValidService } from '../../../helpers/Directions';
import { IUserLocation } from '../../../interfaces/common';
import { ActivePlan, Routes, Services } from '../../../redux/actions';
import { getLocationFromLatLng } from '../../../services/requests';
import FormattedMessage from '../../FormattedMessageCustom';
import Loader from '../../Loader';

const styles = () => ({
  rootMenu: {
    width: '240px',
  },
  menuItem: {
    height: '42px',
  },
});

type PlanMapMapProps = {
  classes: {
    rootMenu: string;
    menuItem: string;
  };
  projectMaxServices?: number;
  userLocation?: IUserLocation;
  setSelectedLocation: (...args: any[]) => any;
  selectedRoute?: {
    id?: string;
  };
  selectedLocation?: {
    label?: string;
    lat?: number;
    lng?: number;
  };
  selectedPoi: string[];
  selectedRoutes: (string | number)[];
  services: IServiceDataExtended[];
  routes: IRouteDataExtended[];
  handleSelectPoi: (...args: any[]) => any;
  handleSelectRoute: (...args: any[]) => any;
  setCreateServiceModal: (...args: any[]) => any;
  setCreateRouteModal: (...args: any[]) => any;
  actions: {
    setService: (...args: any[]) => any;
    updateRoute: (...args: any[]) => any;
    updateService: (...args: any[]) => any;
  };
};

const PlanMapMap = ({
  classes,
  projectMaxServices,
  setSelectedLocation,
  selectedLocation,
  selectedPoi,
  selectedRoute,
  selectedRoutes,
  services,
  routes,
  handleSelectPoi,
  handleSelectRoute,
  setCreateServiceModal,
  setCreateRouteModal,
  actions,
  userLocation,
}: PlanMapMapProps) => {
  const [contextAnchor, setContextAnchor] = useState(undefined);
  return (
    <Map
      center={
        services.length === 0 && routes.length === 0
          ? userLocation
              ? [userLocation.latitude, userLocation.longitude]
              : undefined
          : selectedLocation
            ? [selectedLocation.lat, selectedLocation.lng]
            : undefined
      }
      zoomLevel={services.length === 0 && routes.length === 0 && userLocation ? userLocation.mapZoomLevel : undefined}
      height="100%"
      keyboard={false}
      reboundsControl
      onContextualMenu={async (event: any) => {
        try {
          const result = await getLocationFromLatLng(event.latlng.lat, event.latlng.lng);
          setSelectedLocation(result);
        } catch (error) {
          setSelectedLocation(null);
        }
        setContextAnchor({
          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ screenPosition: { top: any; le... Remove this comment to see the full error message
          screenPosition: {
            top: event.originalEvent.y,
            left: event.originalEvent.x,
          },
          coordinates: {
            lat: event.latlng.lat,
            lng: event.latlng.lng,
          },
        });
      }}
    >
      {contextAnchor && (
        <Menu
          onClose={() => {
            setContextAnchor(undefined);
            setSelectedLocation(undefined);
          }}
          open={Boolean(contextAnchor)}
          anchorReference="anchorPosition"
          // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
          anchorPosition={contextAnchor && contextAnchor.screenPosition}
          classes={{ list: classes.rootMenu }}
        >
          {selectedLocation === undefined && <Loader noMonkey />}
          {selectedLocation === null && (
            <p style={{ padding: '16px' }}>Nothing found in this location</p>
          )}
          {selectedLocation && (
            <>
              <p style={{ padding: '16px', fontSize: '11px' }}>{selectedLocation.label}</p>
              {selectedPoi && selectedPoi.length === 1 && (
                <MenuItem
                  className={classes.menuItem}
                  onClick={() => {
                    actions.updateService(selectedPoi[0], {
                      location: selectedLocation,
                    });
                    setContextAnchor(undefined);
                  }}
                >
                  <FormattedMessage id="services.details.map.add_to_current" />
                </MenuItem>
              )}
              {selectedRoute && (
                <MenuItem
                  className={classes.menuItem}
                  onClick={() => {
                    actions.updateRoute(selectedRoute.id, {
                      start_location: selectedLocation,
                    });
                    setContextAnchor(undefined);
                  }}
                >
                  <FormattedMessage id="routes.details.map.add_to_current_start" />
                </MenuItem>
              )}
              {selectedRoute && (
                <MenuItem
                  className={classes.menuItem}
                  onClick={() => {
                    actions.updateRoute(selectedRoute.id, {
                      end_location: selectedLocation,
                    });
                    setContextAnchor(undefined);
                  }}
                >
                  <FormattedMessage id="routes.details.map.add_to_current_end" />
                </MenuItem>
              )}
              <MenuItem
                className={classes.menuItem}
                // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
                disable={services.length >= projectMaxServices}
                onClick={() => {
                  setContextAnchor(undefined);
                  setCreateServiceModal();
                }}
              >
                <FormattedMessage id="services.details.map.create_new" />
              </MenuItem>
              <MenuItem
                className={classes.menuItem}
                // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
                disable={services.length >= projectMaxServices}
                onClick={() => {
                  setContextAnchor(undefined);
                  setCreateRouteModal();
                }}
              >
                <FormattedMessage id="routes.details.map.create_new" />
              </MenuItem>
            </>
          )}
        </Menu>
      )}
      {services
        .filter((service: any) => !service.route_id && isValidService(service))
        .map((service: any) => {
          const { lat, lng } = service.location;
          return (
            <Marker
              onClick={(e: any) => handleSelectPoi(e, service, 'missing')}
              selected={selectedPoi.includes(service.id)}
              key={`marker${service.id}`}
              id={`marker${service.id}`}
              lat={lat}
              lng={lng}
              done={
                service.status === SERVICE_STATUS.completed && !selectedPoi.includes(service.id)
              }
              error={
                service.status === SERVICE_STATUS.canceled && !selectedPoi.includes(service.id)
              }
            />
          );
        })}
      {routes.map((route: any, idx: any) => {
        return route.planned_track ? (
          <Route
            key={`route-${route.id}`}
            id={`route-${route.id}`}
            path={route.planned_track}
            color={getRouteColor(route as IRouteDataExtended, routes)}
            selected={selectedRoutes.includes(idx)}
            onClick={(e: any) => handleSelectRoute(e, idx)}
          >
            {isValidLocation(route.start_location) ? (
              <Marker id={`marker-start-${route.id}`} lat={route.start_location.lat} lng={route.start_location.lng} kind="start" />
            ) : null}
            {isValidLocation(route.end_location) ? (
              <Marker id={`marker-end-${route.id}`} lat={route.end_location.lat} lng={route.end_location.lng} kind="end" />
            ) : null}
  
            {route.services.filter(isValidService).map((service: any) => {
              const { lat, lng } = service.location;
              return (
                <Marker
                  onClick={debounce((event: any) => handleSelectPoi(event, service, idx), 300)}
                  onDoubleClick={() => {
                    actions.setService(service);
                  }}
                  selected={selectedPoi.includes(service.id)}
                  key={`marker${service.id}`}
                  id={`marker${service.id}`}
                  lat={lat}
                  lng={lng}
                  number={service.order + 1}
                  done={
                    service.status === SERVICE_STATUS.completed && !selectedPoi.includes(service.id)
                  }
                  error={
                    service.status === SERVICE_STATUS.canceled && !selectedPoi.includes(service.id)
                  }
                />
              );
            })}
          </Route>
        ) : null;
        // ) : (
        //   route.services.map(service => {
        //     if (service.location && service.location.lat && service.location.lng) {
        //       const { lat, lng } = service.location;
        //       return (
        //         <Marker
        //           color={idx}
        //           onClick={debounce(e => handleSelectPoi(e, service, idx), 300)}
        //           onDoubleClick={() => actions.setService(service)}
        //           selected={selectedPoi.includes(service.id)}
        //           key={`marker${service.id}`}
        //           id={`marker${service.id}`}
        //           number={service.order + 1}
        //           lat={lat}
        //           lng={lng}
        //           done={service.status === SERVICE_STATUS.completed && !selectedPoi.includes(service.id)}
        //           error={service.status === SERVICE_STATUS.canceled && !selectedPoi.includes(service.id)}
        //         />
        //       )
        //     }
        //     return null;
        //   })
        // )
      })}
    </Map>
  );
};

PlanMapMap.defaultProps = {};

PlanMapMap.defaultProps = {
  selectedRoute: undefined,
  selectedLocation: undefined,
  projectMaxServices: undefined,
  userLocation: undefined,
};

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    updateRoute: (routeId: any, services: any) => dispatch(Routes.updateRoute(routeId, services)),
    updateService: (serviceId: any, service: any) =>
      dispatch(Services.updateService(serviceId, service)),
    setService: (service: any) => dispatch(ActivePlan.setDetailView('service', service)),
    setRoute: (route: any) => dispatch(ActivePlan.setDetailView('route', route)),
  },
});

const mapStateToProps = (state: any) => {
  return {
    projectMaxServices:
      state.projects.activeProject && state.projects.activeProject.limits.max_services_plan,
    userLocation: state.user.userLocation,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PlanMapMap));
