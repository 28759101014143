import * as ActivePlan from './ActivePlan';
import * as Clients from './Clients';
import * as Common from './Common';
import * as InfoError from './InfoError';
import * as Organization from './Organization';
import * as Payment from './Payment';
import * as Plans from './Plans';
import * as Projects from './Projects';
import * as Routes from './Routes';
import * as Services from './Services';
import * as Tutorial from './Tutorial';
import * as User from './User';
import * as Vehicles from './Vehicles';

export default {
  User,
  Organization,
  InfoError,
  Payment,
  Common,
  Clients,
  Projects,
  Plans,
  ActivePlan,
  Services,
  Routes,
  Vehicles,
  Tutorial,
};
