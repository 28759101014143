import { DialogContent } from '@material-ui/core';
import React from 'react';
import { Dialog } from '@sm-highway-web/react-components';
import PaymentMethod from '../components/payment-runway/PaymentMethod';

type Props = {
  open: boolean;
  handleOpenCloseDialog: (...args: any[]) => any;
  handleSubmitSubscription: (...args: any[]) => any;
};

const PaymentDialog = ({ open, handleOpenCloseDialog, handleSubmitSubscription }: Props) => {
  const handleNewToken = (data: any) => handleSubmitSubscription(data.token);

  return (
    <Dialog modal onClose={handleOpenCloseDialog} open={open} fullWidth maxWidth="sm">
      <DialogContent>
        <PaymentMethod onNextStep={handleNewToken} onPreviousStep={handleOpenCloseDialog} />
      </DialogContent>
    </Dialog>
  );
};

export default PaymentDialog;
