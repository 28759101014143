import { Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { IPlanData } from 'highway-api/dist/common/interfaces/plans';
import React from 'react';
import FormattedMessage from '../FormattedMessageCustom';
import StatusBarComponent from '../StatusBarComponent';

const styles = () => ({
  chip: {
    background:
      'transparent linear-gradient(180deg, #F05B62 0%, #FF8200 100%) 0% 0% no-repeat padding-box;',
    height: '150px',
    width: '300px',
    borderRadius: '12px',
    padding: '6px',
    cursor: 'pointer',
  },
  heading: {
    maxHeight: '56px',
    lineHeight: '1.2em',
    color: 'white',
    overflow: 'hidden',
    fontSize: '24px',
  },
  labelContainer: {
    width: '288px',
    margin: 0,
  },
  numberText: {
    fontSize: '32px',
    fontWeight: '500',
    color: 'white',
    top: '50%',
  },
  itemContainer: {
    width: '100px',
  },
});

// @ts-expect-error ts-migrate(2345) FIXME: Argument of type '() => { chip: { background: stri... Remove this comment to see the full error message
export const EmptyPlanChip = withStyles(styles)(({ classes }) => (
  <Grid
    container
    direction="column"
    alignItems="center"
    justify="center"
    className={classes.chip}
    style={{
      background:
        'linear-gradient(180deg, rgba(89,95,102,1) 0%, rgba(106,111,117,1) 43%, rgba(213,214,215,1) 93%',
    }}
  >
    <Typography align="center" className={classes.heading} style={{ fontSize: '20px' }}>
      <FormattedMessage id="plans.no_plans_in_progress" />
    </Typography>
  </Grid>
));

type OwnPlanChipProps = {
  classes: {
    chip: string;
    heading: string;
    labelContainer: string;
    numberText: string;
    itemContainer: string;
  };
  plan?: IPlanData;
  handleClick: (...args: any[]) => any;
};

type PlanChipProps = OwnPlanChipProps;

const PlanChip = ({ classes, plan, handleClick }: PlanChipProps) => {
  if (!plan) return null;
  return (
    <Grid
      container
      direction="column"
      className={classes.chip}
      onClick={handleClick}
      justify="space-between"
    >
      <Grid container alignItems="center" justify="center" style={{ height: '56px' }}>
        <Grid item>
          <div className={classes.labelContainer}>
            <Typography align="center" className={classes.heading}>
              {plan.label}
            </Typography>
          </div>
        </Grid>
      </Grid>
      <Grid item>
        <Grid container justify="center">
          <Grid container direction="column" className={classes.itemContainer}>
            <Grid container style={{ height: '36px' }} alignItems="center" justify="center">
              <Typography className={classes.numberText} align="center">
                {plan.total_routes}
              </Typography>
            </Grid>
            <Typography
              align="center"
              style={{
                color: '#333940',
                fontSize: '9px',
                textTransform: 'uppercase',
                // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
                fontWeight: '600',
              }}
            >
              <FormattedMessage id="plans.create.table1.title" />
            </Typography>
          </Grid>
          <Grid container direction="column" className={classes.itemContainer}>
            <Grid container style={{ height: '36px' }} alignItems="center" justify="center">
              <Typography className={classes.numberText} align="center">
                {plan.total_services}
              </Typography>
            </Grid>
            <Typography
              align="center"
              style={{
                color: '#333940',
                fontSize: '9px',
                textTransform: 'uppercase',
                // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
                fontWeight: '600',
              }}
            >
              <FormattedMessage id="plans.create.table2.title" />
            </Typography>
          </Grid>
        </Grid>
      </Grid>
      <Grid container alignItems="center" justify="center" style={{ height: '20px' }}>
        <Grid item>
          <StatusBarComponent
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'pending_services' does not exist on type... Remove this comment to see the full error message
            pendingServices={plan.pending_services}
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'completed_services' does not exist on ty... Remove this comment to see the full error message
            completedServices={plan.completed_services}
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'canceled_services' does not exist on typ... Remove this comment to see the full error message
            canceledServices={plan.canceled_services}
            width="200px"
          />
        </Grid>
      </Grid>
    </Grid>
  );
};

// @ts-expect-error ts-migrate(2345) FIXME: Argument of type '() => { chip: { background: stri... Remove this comment to see the full error message
export default withStyles(styles)(PlanChip);
