import { Grid, Hidden, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React, { Fragment, useEffect } from 'react';
import { VSpacer } from '@sm-highway-web/react-components';
import loginimage from '../assets/img/login_illustration_svg.svg';
import AcceptInvitation from '../components/AcceptInvitation';
import FormattedMessage from '../components/FormattedMessageCustom';
import LoginComponent from '../components/LoginComponent';
import RegisterComponent from '../components/RegisterComponent';
import ResetPasswordComponent from '../components/ResetPasswordComponent';

const styles = {
  contentColumn: {
    width: '100%',
    height: '600px',
  },
  allHeight: {
    height: '100%',
  },
  imgback: {
    backgroundImage: `url(${loginimage})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundColor: '#FFFEF3',
    height: '100%',
    overflow: 'hidden',
  },
  trapezoid: {
    borderTop: '1300px solid white',
    borderLeft: '0px solid transparent',
    borderRight: '300px solid transparent',
    height: '0',
    width: '0px',
  },
  trapezoidNew: {
    borderTop: '1300px solid white',
    borderLeft: '0px solid transparent',
    borderRight: '150px solid transparent',
    height: '0',
    width: '0px',
  },
  typo: {
    width: 'calc(100% - 400px)',
    color: '#FF978F',
    fontWeight: 'bold',
    padding: '24px 50px',
    fontFamily: 'Playfair Display',
  },
  typo2: {
    width: 'calc(100% - 250px)',
    color: '#FF978F',
    fontWeight: 'bold',
    padding: '24px 50px',
    fontFamily: 'Playfair Display',
  },
};

type OwnProps = {
  classes: {
    allHeight: string;
    contentColumn: string;
    trapezoid: string;
    imgback: string;
    trapezoidNew: string;
    typo: string;
    typo2: string;
  };
  route: 'login' | 'invitation' | 'register' | 'reset-password' | 'reset-password';
  token?: string;
};

type Props = OwnProps;

const Login = ({ classes, route, token }: Props) => {
  useEffect(() => {
    if (route === 'register') {
      window.location.href = 'https://planner.smartmonkey.io/register';
    }
  });
  return (
    <>
      { route !== 'register' && <Grid container style={{ height: '100vh' }}>
        <Grid
          item
          xl={4}
          lg={4}
          md={6}
          sm={12}
          xs={12}
          className={classes.allHeight}
          style={{ backgroundColor: 'white' }}
        >
          <Grid container className={classes.allHeight} justify="center">
            <Grid item xl={4} lg={4} md={2} sm={false} xs={false} />
            <Grid item xl={8} lg={8} md={10} sm={10} xs={11} className={classes.allHeight}>
              <Grid container direction="column" justify="center" className={classes.allHeight}>
                <div className={classes.contentColumn}>
                  {route === 'login' && <LoginComponent />}
                  {route === 'reset-password' && <ResetPasswordComponent token={token} />}
                  {route === 'invitation' && <AcceptInvitation token={token} />}
                </div>
                <VSpacer large />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <Hidden smDown>
          <Grid item xl={8} lg={8} md={6} sm={false} xs={false} className={classes.imgback}>
            <Grid container>
              <Hidden mdDown>
                <div className={classes.trapezoid} />
              </Hidden>
              <Hidden lgUp>
                <div className={classes.trapezoidNew} />
              </Hidden>
              <Hidden mdDown>
                <Typography variant="h1" align="center" className={classes.typo}>
                  <FormattedMessage id="login.welcome" />
                </Typography>
              </Hidden>
              <Hidden lgUp>
                <Typography variant="h3" align="center" className={classes.typo2}>
                  <FormattedMessage id="login.welcome" />
                </Typography>
              </Hidden>
            </Grid>
          </Grid>
        </Hidden>
      </Grid>}
    </>
  );
};

Login.defaultProps = {
  token: undefined,
};

// @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ contentColumn: { width: string... Remove this comment to see the full error message
export default withStyles(styles)(Login);
