import createSvgIcon from '@material-ui/icons/utils/createSvgIcon';
import React from 'react';

export default createSvgIcon(
  <>
    <path d="m21.4 5.8l-5.9-2.3c-0.1-0.1-0.3-0.1-0.4 0l-5.6 2.2-5.7-2.2c-0.2-0.2-0.4-0.1-0.6 0-0.1 0.1-0.2 0.3-0.2 0.5v14.1c0 0.2 0.1 0.5 0.4 0.5l5.9 2.3c0.1 0.1 0.3 0.1 0.4 0l5.6-2.3 5.6 2.3c0.1 0 0.1 0.1 0.2 0.1s0.2-0.1 0.4-0.1c0.2-0.1 0.2-0.3 0.2-0.5v-14.1c0.1-0.2 0-0.4-0.3-0.5zm-0.8 13.7l-5-2.1c-0.1-0.1-0.3-0.1-0.4 0l-5.2 2.1v-2.1h-1.1v2.1l-4.7-1.9v-12.7l5 2.1c0.1 0 0.3 0 0.4-0.1l5.1-2.1v2.1h1.2v-2l4.7 1.9v12.7z" />
    <path d="m16.6 7.2c-0.9 0-1.7 0.6-1.9 1.5h-4.2c-1.2 0-2.1 0.9-2.1 2.1s0.9 2.1 2.1 2.1h3.6c0.5 0 0.9 0.4 0.9 0.9s-0.4 0.9-0.9 0.9h-4.2c-0.2-0.8-1.1-1.4-1.9-1.4-1.1 0-2 0.9-2 2s0.9 2 2 2c0.9 0 1.6-0.6 1.9-1.4h4.2c1.2 0 2.1-0.9 2.1-2.1s-0.9-2.1-2.1-2.1h-3.6c-0.5 0-0.9-0.4-0.9-0.9s0.4-0.9 0.9-0.9h4.3c0.3 0.8 1 1.3 1.9 1.3 1.1 0 2-0.9 2-2s-1-2-2.1-2zm-8.6 8.8c-0.5 0-0.8-0.4-0.8-0.8s0.4-0.8 0.8-0.8 0.8 0.4 0.8 0.8-0.3 0.8-0.8 0.8zm8.6-6c-0.5 0-0.8-0.4-0.8-0.8s0.4-0.8 0.8-0.8 0.8 0.4 0.8 0.8-0.3 0.8-0.8 0.8z" />
  </>,
  'ViewMapIcon'
);
