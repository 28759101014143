import { Grid, Tab, Tabs, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import {
  Business as BusinessIcon, Comment as CommentIcon,
  Group as GroupIcon,
  Settings as SettingsIcon,
  Visibility as VisibilityIcon,
} from '@material-ui/icons';
import { DefaultAvatar, HSpacer, VSpacer } from '@sm-highway-web/react-components';
import { IProjectData } from 'highway-api/dist/common/interfaces/projects';
import { ISubscriptionBase } from 'highway-api/dist/common/interfaces/subscriptions';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as routing from '../../../constants/Routing';
import history from '../../../helpers/History';
import { projectHelpers, userHelpers } from '../../../redux/helpers';
import Avatar from '../../Avatar';
import FormattedMessage from '../../FormattedMessageCustom';
import OrganizationManageResourcesDialog from '../organization/OrganizationManageResourcesDialog';
import ProjectCommunication from './communication/ProjectCommunication';
import OrganizationProjectsEditFields from './OrganizationProjectsEditFields';
import OrganizationProjectsEditGeneral from './OrganizationProjectsEditGeneral';
import OrganizationProjectsEditMembers from './OrganizationProjectsEditMembers';
import OrganizationProjectsEditSettings from './OrganizationProjectsEditSettings';

const styles = {
  header: {
    // padding: '12px',
  },
  tabContainer: {
    borderBottom: '1px solid #D7DAE2',
  },
  tabsroot: {
    minHeight: 'unset',
    color: 'white',
  },
  tabroot: {
    padding: '0px 12px',
    height: '100%',
    width: '200px',
    fontWidth: 500,
  },
};

const TabPanel = ({ value, index, children }: any) => {
  return value === index ? children : null;
};

type OrganizationProjectsEditProps = {
  classes: {
    header: string;
    tabContainer: string;
    tabroot: string;
    tabsroot: string;
  };
  isDirtyState: (...args: any[]) => any;
  match: {
    params: {
      projectId?: string;
      view?: string;
    };
  };
  organizationLabel?: string;
  organizationProjects?: IProjectData[];
  organizationVehicles?: number;
  organizationSubscription?: ISubscriptionBase;
};

const OrganizationProjectsEdit = ({
  classes,
  isDirtyState,
  match,
  organizationLabel,
  organizationVehicles,
  organizationProjects,
  organizationSubscription,
}: OrganizationProjectsEditProps) => {
  const [project, setProject] = useState<IProjectData | undefined>(undefined);
  const [tabSelected, setTabSelected] = useState('');
  const [manageResourcesDialogOpen, setManageResourcesDialogOpen] = useState(false);

  const setLocationView = useCallback(
    (view) => {
      if (view !== tabSelected && Object.keys(routing.ProjectEditViews).indexOf(view) > -1) {
        setTabSelected(view);
        history.push(
          `${routing.SETTINGS.ORGANIZATION.PROJECTS.ROOT}/${match.params.projectId}/${view}`
        );
      }
    },
    [tabSelected, setTabSelected, match.params]
  );

  useEffect(() => {
    if (match.params.projectId && match.params.projectId !== '' && organizationProjects) {
      const foundProject = organizationProjects.find(
        (orgProject: IProjectData) => orgProject.id === match.params.projectId
      );
      if (!foundProject) {
        history.push(`${routing.SETTINGS.ORGANIZATION.PROJECTS.ROOT}`);
      } else {
        setProject(foundProject);
      }
    }
  }, [match.params.projectId, organizationProjects, setProject]);

  useEffect(() => {
    if (
      match.params.view &&
      Object.keys(routing.ProjectEditViews).indexOf(match.params.view) > -1
    ) {
      if (
        !userHelpers.currentUser.isOrganizationAdmin() &&
        !userHelpers.currentUser.isOrganizationManager() &&
        !projectHelpers.currentUser.isManagerInProject(project) &&
        (match.params.view === routing.ProjectEditViews.settings ||
          match.params.view === routing.ProjectEditViews.fields)
      ) {
        setLocationView(routing.ProjectEditViews.general);
      } else {
        setTabSelected(match.params.view);
      }

      setTabSelected(match.params.view);
    } else {
      setLocationView(routing.ProjectEditViews.general);
    }
  }, [project, setLocationView, match.params.view]);

  if (!project) {
    return null;
  }

  return (
    <>
      <Grid container direction="column" wrap="nowrap" style={{ height: '100%' }}>
        <Grid item container direction="column" className={classes.header} wrap="nowrap">
          <Grid item container direction="row" wrap="nowrap">
            <Grid item>
              <DefaultAvatar
                labelOverlayed
                label={`${projectHelpers.getProjectLabel(project.label, organizationLabel)}`}
                avatar={
                  <Avatar
                    data={project as any}
                    label={`${projectHelpers.getProjectLabel(project.label, organizationLabel)}`}
                    labelOverlayed
                    size={50}
                  />
                }
                size={50}
              />
            </Grid>

            <HSpacer large />

            <Grid item container direction="column">
              <Grid item>
                <Typography variant="h5">
                  {`${projectHelpers.getProjectLabel(project.label, organizationLabel)}`}
                </Typography>
              </Grid>

              <VSpacer small />

              <Grid item container>
                <span
                  style={{
                    fontSize: '14px',
                    color: '#707070',
                  }}
                >
                  <FormattedMessage
                    id="settings.organization.projects.edit.info"
                    values={{
                      vehicles: project?.limits?.max_vehicles,
                      clients: project?.limits?.max_clients,
                    }}
                  />
                </span>

                {(userHelpers.currentUser.isOrganizationAdmin() ||
                  userHelpers.currentUser.isOrganizationManager() ||
                  projectHelpers.currentUser.isManagerInProject(project)) && (
                  <>
                    <HSpacer small />

                    <Grid item>
                      <div
                        style={{
                          cursor: 'pointer',
                          fontSize: '14px',
                          color: '#FF8200',
                          fontFamily: 'Work Sans',
                          border: 'none',
                        }}
                        onClick={() => setManageResourcesDialogOpen(true)}
                      >
                        <FormattedMessage id="settings.organization.projects.edit.manage_resources" />
                      </div>
                    </Grid>
                  </>
                )}
              </Grid>
            </Grid>
          </Grid>

          <VSpacer large />

          <Grid item container alignItems="center" className={classes.tabContainer}>
            <Tabs
              id="project-list-tabs"
              value={tabSelected}
              onChange={(e, v) => {
                setLocationView(v);
              }}
              indicatorColor="primary"
              textColor="primary"
              classes={{ root: classes.tabsroot }}
            >
              <Tab
                label={(
                  <Grid container justify="center" alignItems="center">
                    <BusinessIcon />
                    <HSpacer small />
                    <FormattedMessage id="settings.organization.projects.edit.general.title" />
                  </Grid>
                )}
                value={routing.ProjectEditViews.general}
                classes={{ root: classes.tabroot }}
              />
              <Tab
                label={(
                  <Grid container justify="center" alignItems="center">
                    <GroupIcon />
                    <HSpacer small />
                    <FormattedMessage id="settings.organization.projects.edit.members.title" />
                  </Grid>
                )}
                value={routing.ProjectEditViews.members}
                classes={{ root: classes.tabroot }}
              />
              {(userHelpers.currentUser.isOrganizationAdmin() ||
                userHelpers.currentUser.isOrganizationManager() ||
                projectHelpers.currentUser.isManagerInProject(project)) && (
                <Tab
                  label={(
                    <Grid container justify="center" alignItems="center">
                      <VisibilityIcon />
                      <HSpacer small />
                      <FormattedMessage id="settings.organization.projects.edit.fields.title" />
                    </Grid>
                  )}
                  value={routing.ProjectEditViews.fields}
                  classes={{ root: classes.tabroot }}
                />
              )}
              {(userHelpers.currentUser.isOrganizationAdmin() ||
                userHelpers.currentUser.isOrganizationManager() ||
                projectHelpers.currentUser.isManagerInProject(project)) && (
                <Tab
                  label={(
                    <Grid container justify="center" alignItems="center">
                      <CommentIcon />
                      <HSpacer small />
                      <FormattedMessage id="settings.organization.projects.edit.communication.title" />
                    </Grid>
                  )}
                  value={routing.ProjectEditViews.communication}
                  classes={{ root: classes.tabroot }}
                />
              )}
              {(userHelpers.currentUser.isOrganizationAdmin() ||
                userHelpers.currentUser.isOrganizationManager() ||
                projectHelpers.currentUser.isManagerInProject(project)) && (
                <Tab
                  label={(
                    <Grid container justify="center" alignItems="center">
                      <SettingsIcon />
                      <HSpacer small />
                      <FormattedMessage id="settings.organization.projects.edit.settings.title" />
                    </Grid>
                  )}
                  value={routing.ProjectEditViews.settings}
                  classes={{ root: classes.tabroot }}
                />
              )}
            </Tabs>
          </Grid>
        </Grid>

        <TabPanel value={tabSelected} index={routing.ProjectEditViews.general}>
          <Grid container style={{ height: 'calc(100% - 48px)' }} direction="column">
            <VSpacer medium />
            <OrganizationProjectsEditGeneral project={project} isDirtyState={isDirtyState} />
          </Grid>
        </TabPanel>

        <TabPanel value={tabSelected} index={routing.ProjectEditViews.members}>
          <Grid container style={{ height: 'calc(100% - 48px)' }} direction="column">
            <VSpacer medium />
            <OrganizationProjectsEditMembers project={project} />
          </Grid>
        </TabPanel>

        {(userHelpers.currentUser.isOrganizationAdmin() ||
          userHelpers.currentUser.isOrganizationManager() ||
          projectHelpers.currentUser.isManagerInProject(project)) && (
          <TabPanel value={tabSelected} index={routing.ProjectEditViews.fields}>
            <Grid container style={{ height: 'calc(100% - 48px)' }} direction="column">
              <VSpacer large />
              <OrganizationProjectsEditFields project={project} />
            </Grid>
          </TabPanel>
        )}

        {(userHelpers.currentUser.isOrganizationAdmin() ||
          userHelpers.currentUser.isOrganizationManager() ||
          projectHelpers.currentUser.isManagerInProject(project)) && (
          <Grid container style={{ height: 'calc(100% - 48px)' }} direction="column" wrap="nowrap">
            <TabPanel value={tabSelected} index={routing.ProjectEditViews.communication}>
              <ProjectCommunication project={project} organizationSubscription={organizationSubscription} />
            </TabPanel>
          </Grid>
        )}

        {(userHelpers.currentUser.isOrganizationAdmin() ||
          userHelpers.currentUser.isOrganizationManager() ||
          projectHelpers.currentUser.isManagerInProject(project)) && (
          <TabPanel value={tabSelected} index={routing.ProjectEditViews.settings}>
            <Grid container style={{ height: 'calc(100% - 48px)' }} direction="column">
              <VSpacer large />
              <OrganizationProjectsEditSettings project={project} isDirtyState={isDirtyState} />
            </Grid>
          </TabPanel>
        )}
      </Grid>

      {manageResourcesDialogOpen && organizationVehicles && <OrganizationManageResourcesDialog
        organizationTotalVehicles={organizationVehicles}
        isOpen={manageResourcesDialogOpen}
        onClose={() => setManageResourcesDialogOpen(false)}
      />}
    </>
  );
};

OrganizationProjectsEdit.defaultProps = {
  organizationVehicles: 0,
  organizationLabel: '',
  organizationProjects: undefined,
};

const mapStateToProps = (state: any) => {
  return {
    organizationSubscription: state.organization.organization ? state.organization.organization.subscription : undefined,
    organizationLabel: state.organization.organization && state.organization.organization.label,
    organizationProjects: state.projects.projects
      ? projectHelpers.orderProjects(state.projects.projects)
      : undefined,
    organizationVehicles:
      state.organization.organization &&
      state.organization.organization.subscription &&
      state.organization.organization.subscription.plan &&
      state.organization.organization.subscription.plan.vehicles,
  };
};

export default connect(
  mapStateToProps,
  null
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ ({ classes, isDirtyState, matc... Remove this comment to see the full error message
)(withStyles(styles)(withRouter(OrganizationProjectsEdit)));
