import SvgIcon from '@material-ui/core/SvgIcon';
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon';
import React from 'react';

const moto = createSvgIcon(
  <>
    <path d="m22.9 14.2v-0.3c-0.6-0.9-1.6-1.4-2.6-1.4h-0.1v-0.4c0-0.2-0.1-0.4-0.3-0.4l-0.7-0.1-0.7-2.8c0-0.1-0.1-0.2-0.2-0.3l-0.3-0.2s0.1 0 0.1-0.1c0.1-0.1 0.2-0.2 0.2-0.3v-1.4-0.1-0.2c0-0.1-0.1-0.2-0.2-0.3-0.3-0.2-0.7-0.3-1.1-0.3-0.4 0.1-0.7 0.3-0.9 0.6l-2 0.2c-0.3 0-0.6 0.3-0.6 0.7 0 0.3 0.3 0.6 0.6 0.6h0.1l1.7-0.2c0.1 0.2 0.2 0.4 0.3 0.5 0.5 1.1 1.6 4.3 0.7 5.6-0.2 0.4-0.6 0.5-1.2 0.5h-2.3c-0.7 0-1.2-0.3-1.5-0.8s-0.2-1.2 0.2-2c0.1-0.1 0.2-0.2 0.2-0.4v-1.9c0-0.2-0.2-0.4-0.4-0.4h-7.1c-0.2 0-0.4 0.2-0.4 0.4v1.8c-0.9 0.3-2.4 1.2-3.5 3.7v0.2 0.8c0 0.2 0.2 0.4 0.4 0.4h0.6c0.1 1.5 1.3 2.7 2.8 2.7s2.7-1.2 2.8-2.7h9.7c0.1 1.5 1.3 2.7 2.8 2.7s2.8-1.3 2.8-2.8c0-0.5-0.1-0.9-0.3-1.3h0.2c0.1-0.1 0.2-0.2 0.2-0.3zm-17.1-2.3h1.8c0.1 0 0.2 0.1 0.2 0.2s-0.1 0.2-0.2 0.2h-1.8c-0.1 0-0.2-0.1-0.2-0.2s0-0.2 0.2-0.2zm-1.1 4.9c-0.6 0-1-0.4-1.1-1h2.2c0 0.6-0.5 1-1.1 1zm2.8-2.7h-1.7c-0.1 0-0.2-0.1-0.2-0.2s0.1-0.2 0.2-0.2h1.8c0.1 0 0.2 0.1 0.2 0.2s-0.1 0.2-0.3 0.2zm0.8-0.9h-3.3c-0.1 0-0.2-0.1-0.2-0.2s0.1-0.2 0.2-0.2h3.4c0.1 0 0.2 0.1 0.2 0.2s-0.1 0.2-0.3 0.2zm11.7 3.6c-0.6 0-1.1-0.5-1.1-1.1v-0.3l1.8-0.5c0.2 0.2 0.3 0.5 0.3 0.8 0.1 0.6-0.4 1.1-1 1.1z" />
  </>,
  'VehicleIconMoto'
);

const walk = createSvgIcon(
  <>
    <path d="M14.1,5.2c1.1,0,2-0.9,2-2c0-1.1-0.9-2-2-2s-2,0.9-2,2S13,5.2,14.1,5.2z" />
    <path
      d="M19.7,12.8v-2c-1.9,0-3.5-1-4.4-2.5l-1-1.6c-0.4-0.6-1-1-1.7-1c-0.3,0-0.5,0.1-0.8,0.2L6.5,8v4.8h2V9.4l1.8-0.8L7.5,22.9
				h2.1l1.8-8.1l2.2,2v6.1h2v-7.6l-2.1-2.1l0.6-3C15.5,11.8,17.4,12.8,19.7,12.8z"
    />
  </>,
  'VehicleIconWalk'
);

const truck = createSvgIcon(
  <>
    <path d="m14.1 5.2h-12.4c-0.2 0-0.4 0.2-0.4 0.4v10.9c0 0.2 0.2 0.4 0.4 0.4h1.1c0.2 0 0.4-0.2 0.4-0.4 0.2-1.3 1.2-2.1 2.5-2.1s2.3 0.8 2.5 2.1c0 0.2 0.2 0.4 0.4 0.4h5.5c0.2 0 0.4-0.2 0.4-0.4v-10.9c0-0.2-0.2-0.4-0.4-0.4zm-8.4 10c-1 0-1.8 0.8-1.8 1.8s0.8 1.8 1.8 1.8 1.8-0.8 1.8-1.8c0-0.9-0.8-1.8-1.8-1.8zm13.5 0c-1 0-1.8 0.8-1.8 1.8s0.8 1.8 1.8 1.8 1.8-0.8 1.8-1.8c0-0.9-0.8-1.8-1.8-1.8zm3.7-3.2-3.5-4.2c-0.1-0.1-0.2-0.2-0.3-0.2h-3.2c-0.2 0-0.4 0.2-0.4 0.4v8.5c0 0.2 0.2 0.4 0.4 0.4h0.5c0.2 0 0.4-0.2 0.4-0.4 0.2-1.3 1.2-2.1 2.5-2.1s2.3 0.8 2.5 2.1c0 0.2 0.2 0.4 0.4 0.4h0.5c0.2 0 0.4-0.2 0.4-0.4v-4.2c-0.1-0.2-0.2-0.3-0.2-0.3zm-2.5-0.5h-3.7c-0.2 0-0.4-0.2-0.4-0.4v-2.2c0-0.2 0.2-0.4 0.4-0.4h1.9c0.1 0 0.3 0.1 0.3 0.2l1.8 2.2c0.3 0.1 0.1 0.6-0.3 0.6z" />
  </>,
  'VehicleIconTruck'
);

const furgo = createSvgIcon(
  <>
    <path
      d="M5.7,14c-1,0-1.8,0.8-1.8,1.8c0,1,0.8,1.8,1.8,1.8c1,0,1.8-0.8,1.8-1.8C7.6,14.8,6.8,14,5.7,14z M21.7,10.9
c-0.7-0.4-3.1-0.8-3.1-0.8c0.2,0.1-1.4-1.7-2.7-2.7c-1.3-1-3-1.1-3.1-1.1l-11.1,0c-0.2,0-0.4,0.2-0.4,0.4l0,8.5
c0,0.2,0.2,0.4,0.4,0.4h1.1c0.2,0,0.4-0.2,0.4-0.4c0.2-1.3,1.2-2.1,2.5-2.1C7,13.2,8,14,8.2,15.3c0,0.2,0.2,0.4,0.4,0.4h5.5
c0,0,0,0,0,0h2.2c0.2,0,0.4-0.2,0.4-0.4c0.2-1.3,1.2-2.1,2.5-2.1s2.3,0.8,2.5,2.1c0,0.2,0.2,0.4,0.4,0.4h0.5c0.2,0,0.4-0.2,0.4-0.4
V12C23,11.9,22.3,11.2,21.7,10.9z M16.9,10.4h-3.7c-0.2,0-0.4-0.2-0.4-0.4V7.7c0-0.2,0.2-0.4,0.4-0.4c0,0,0.9,0,1.7,0.5
c1.6,0.9,2.3,2,2.3,2C17.5,10,17.3,10.4,16.9,10.4z M19.2,14c-1,0-1.8,0.8-1.8,1.8c0,1,0.8,1.8,1.8,1.8c1,0,1.8-0.8,1.8-1.8
C21,14.8,20.2,14,19.2,14z"
    />
  </>,
  'VehicleIconFurgo'
);

export const colors : { [key: string]: string }= {
  normal: '#8194B8',
  fav: '#fde753',
  champ: '#8B572A',
  poi: ' #48B730',
  bookmark: '#9013FE',
  user: '#4BAEE8',
  hangar: '#5CB9B4',
  warning: '#BF2728',
  barrow: '#4A4A4A',
};

export default { moto, walk, truck, furgo } as { [key: string]: typeof SvgIcon };
