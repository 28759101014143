import { Grid, Tooltip, Typography } from '@material-ui/core';
import React from 'react';
import FormattedMessage from './FormattedMessageCustom';

type Props = {
  pendingServices: number;
  completedServices: number;
  canceledServices: number;
  width: string;
};

const StatusBarComponent = ({
  pendingServices,
  completedServices,
  canceledServices,
  width,
}: Props) => {
  const totalServices = pendingServices + completedServices + canceledServices;

  if (totalServices === 0) return null;

  const bullet = {
    borderRadius: '12px',
    height: '10px',
    width,
    display: 'inline-flex',
    backgroundColor: '#F1F1F1',
  };

  const statusList = [
    ...(completedServices > 0
      ? [
          {
            id: 1,
            width: `${(completedServices * 100) / totalServices}%`,
            tooltipId: `plans.plans_in_progress.completed_services`,
            value: completedServices,
            backgroundColor: `#30bf2d`,
          },
        ]
      : []),
    ...(canceledServices > 0
      ? [
          {
            id: 2,
            width: `${(canceledServices * 100) / totalServices}%`,
            tooltipId: `plans.plans_in_progress.canceled_services`,
            value: canceledServices,
            backgroundColor: `#FF2034`,
          },
        ]
      : []),
    ...(pendingServices > 0
      ? [
          {
            id: 3,
            width: `${(pendingServices * 100) / totalServices}%`,
            tooltipId: `plans.plans_in_progress.pending_services`,
            value: pendingServices,
            backgroundColor: `#b7b7b7`,
          },
        ]
      : []),
  ];

  return (
    <div style={bullet}>
      {statusList.map((status, idx) => {
        return (
          <Tooltip
            key={`bullet-${status.id}`}
            title={
              <Grid container justify="center" direction="column">
                <Typography align="center" style={{ color: 'white', fontSize: '12px' }}>
                  <FormattedMessage id={status.tooltipId} values={{ number: status.value }} />
                </Typography>
              </Grid>
            }
          >
            <div
              style={{
                height: '100%',
                display: 'flex',
                minWidth: '8px',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: status.width,
                backgroundColor: status.backgroundColor,
                borderTopLeftRadius: `${idx === 0 ? '12px' : '0px'}`,
                borderBottomLeftRadius: `${idx === 0 ? '12px' : '0px'}`,
                borderTopRightRadius: `${idx === statusList.length - 1 ? '12px' : '0px'}`,
                borderBottomRightRadius: `${idx === statusList.length - 1 ? '12px' : '0px'}`,
              }}
            />
          </Tooltip>
        );
      })}
    </div>
  );
};

export default StatusBarComponent;
