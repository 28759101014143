import { Grid, Tooltip } from '@material-ui/core';
import { CloudDownloadOutlined } from '@material-ui/icons';
import { Button, HSpacer, VSpacer } from '@sm-highway-web/react-components';
import React from 'react';
import Dropzone from 'react-dropzone';
import { connect } from 'react-redux';
import UploadExcel from '../assets/img/upload_excel.svg';
import FormattedMessage from './FormattedMessageCustom';
// ASSETS

const baseStyle = {
  flex: 1,
  display: 'flex',
  height: '100%',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: '4px',
  borderColor: '#eeeeee',
  borderRadius: '8px',
  borderStyle: 'dashed',
  backgroundColor: 'rgba(255,255,255,0)',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out',
  justifyContent: 'center',
};

const activeStyle = {
  borderColor: '#2196f3',
};

const acceptStyle = {
  borderColor: '#00e676',
};

const rejectStyle = {
  borderColor: '#ff1744',
};

type OwnCustomDropzoneProps = {
  acceptedFiles?: string | string[];
  multiple?: boolean;
  onDrop: (...args: any[]) => any;
  error?: {
    id?: string;
    values?: any;
  };
  loading?: boolean;
  language?: string;
  text: string | any;
  messageLoading?: string;
};

type CustomDropzoneProps = OwnCustomDropzoneProps;

const CustomDropzone = ({
  acceptedFiles,
  onDrop,
  multiple,
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'sampleUrl' does not exist on type 'OwnCu... Remove this comment to see the full error message
  sampleUrl,
  text,
}: CustomDropzoneProps) => (
  <Dropzone onDrop={onDrop} accept={acceptedFiles} multiple={multiple} noClick>
    {({ getRootProps, getInputProps, isDragActive, isDragAccept, isDragReject, open }) => {
      const style = {
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {}),
      };
      // let messageButton = 'optimizerupload.buttonload';
      // if (isDragAccept && !isDragReject) {
      //   messageButton = 'optimizerupload.buttonstatevalid';
      // } else if (!isDragAccept && isDragReject) {
      //   messageButton = 'optimizerupload.buttonstateinvalid';
      // }
      return (
        <Grid
          container
          style={{ width: '100%' }}
          {...getRootProps({
            className: 'dropzone',
            onDrop: (event) => {
              event.preventDefault();
            },
            // @ts-expect-error ts-migrate(2322) FIXME: Type '{ borderColor: string; flex: number; display... Remove this comment to see the full error message
            style,
          })}
        >
          <input {...getInputProps()} />
          <img alt="Upload xlsx" src={UploadExcel} width="150px" />
          <VSpacer large />
          {text}
          <VSpacer small />
          <Grid container justify="center">
            <Button variant="contained" onClick={open}>
              <FormattedMessage id="dropzone.actions.upload" />
            </Button>
            <HSpacer small />
            {sampleUrl && (
              <Tooltip title={<FormattedMessage id="tooltips.download_example" />}>
                <a href={sampleUrl}>
                  <Button variant="contained" color="secondary">
                    <CloudDownloadOutlined />
                  </Button>
                </a>
              </Tooltip>
            )}
          </Grid>
          <VSpacer large />
        </Grid>
      );
    }}
  </Dropzone>
);

CustomDropzone.defaultProps = {
  messageLoading: undefined,
  loading: undefined,
};

CustomDropzone.defaultProps = {
  acceptedFiles: undefined,
  multiple: false,
  error: undefined,
};

CustomDropzone.defaultProps = {
  language: 'en-us',
};

const mapStateToProps = (state: any) => {
  return { language: state.user.user && state.user.user.language };
};

export default connect(mapStateToProps, null)(CustomDropzone);
