import { Grid, Typography } from '@material-ui/core';
import { Button, Dialog, VSpacer } from '@sm-highway-web/react-components';
import { CustomFieldValueTypes } from 'highway-api/dist/common/interfaces/projects';
import React, { useState } from 'react';
import ProjectCustomField from '../components/custom-fields/ProjectCustomField';
import FormattedMessage from '../components/FormattedMessageCustom';

type Props = {
  type: CustomFieldValueTypes;
  open: boolean;
  handleCreateCustomField: (...args: any[]) => any;
  onCloseDialog: (...args: any[]) => any;
};

const AddProjectCustomFieldDialog = ({
  type,
  open,
  handleCreateCustomField,
  onCloseDialog,
}: Props) => {
  const [field, setField] = useState({
    id: '',
    label: '',
    description: '',
    type,
    enabled: true,
    options: [],
    multiple: false,
  });
  return (
    <Dialog
      modal
      disableBackdropClick
      disableEscapeKeyDown
      onClose={onCloseDialog}
      open={open}
      title={
        <FormattedMessage
          id={`settings.organization.projects.edit.fields.custom_fields.dialog.${type}.title`}
        />
      }
    >
      <Typography>
        <FormattedMessage
          id={`settings.organization.projects.edit.fields.custom_fields.dialog.${type}.subtitle`}
        />
      </Typography>

      <VSpacer large />

      <Grid container direction="column" style={{ minWidth: '500px' }}>
        <Grid item container direction="column" spacing={2} alignItems="center">
          <Grid item style={{ padding: '0 20px', width: '100%' }}>
            <ProjectCustomField
              // @ts-expect-error ts-migrate(2322) FIXME: Type '{ style: { width: string; }; create: true; f... Remove this comment to see the full error message
              style={{ width: 'unset' }}
              create
              field={field}
              editable
              onUpdate={(fieldUpdate: any) => setField({ ...field, ...fieldUpdate })}
            />
          </Grid>
        </Grid>

        <VSpacer medium />

        <Grid container justify="flex-end">
          <Button
            disabled={
              !field.id ||
              !field.label ||
              (field.type === 'categorical' && field.options.length === 0)
            }
            variant="contained"
            onClick={() => {
              handleCreateCustomField(field);
            }}
          >
            <FormattedMessage id="settings.organization.projects.edit.fields.custom_fields.dialog.buttoncreate" />
          </Button>
        </Grid>
      </Grid>
      <VSpacer />
    </Dialog>
  );
};

export default AddProjectCustomFieldDialog;
