import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  DefaultAvatar,
  Form,
  FormControl,
  VSpacer,
} from '@sm-highway-web/react-components';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { Projects } from '../../../redux/actions';
import { projectHelpers } from '../../../redux/helpers';
import Avatar from '../../Avatar';
import CustomSelect from '../../CustomSelect';
import FormattedMessage from '../../FormattedMessageCustom';

const styles = {
  dialogRoot: {
    maxWidth: '700px',
  },
  select: {
    padding: '8px',
    minHeight: '24px',
  },
  selectMenu: {
    '& .MuiMenu-list': {
      padding: '0',
    },
  },
};

type OwnOrganizationProjectAddUserDialogProps = {
  classes: {
    dialogRoot: string;
    select: string;
    selectMenu: string;
  };
  selectedProjectId: string;
  availableUsers?: {}[];
  isOpen?: boolean;
  onClose: (...args: any[]) => any;
  actions: {
    addProjectUser: (...args: any[]) => any;
  };
};

type OrganizationProjectAddUserDialogProps = OwnOrganizationProjectAddUserDialogProps;

const OrganizationProjectAddUserDialog = ({
  classes,
  selectedProjectId,
  availableUsers,
  isOpen,
  onClose,
  actions,
}: OrganizationProjectAddUserDialogProps) => {
  const [user, setUser] = useState();
  const [role, setRole] = useState(projectHelpers.ProjectRoles.project_manager);
  const [disabledSubmitButton, setDisabledSubmitButton] = useState(false);

  const closeDialog = () => {
    setDisabledSubmitButton(false);
    onClose();
  };

  const handleAddUser = async () => {
    setDisabledSubmitButton(true);
    await actions.addProjectUser(selectedProjectId, {
      user_id: user,
      role,
    });
    setTimeout(() => closeDialog(), 500);
  };

  return (
    <Dialog classes={{ paper: classes.dialogRoot }} open={!!isOpen} onClose={closeDialog}>
      <DialogTitle>
        <FormattedMessage id="settings.organization.projects.edit.members.add.title" />
      </DialogTitle>
      <DialogContent>
        <Grid container direction="column">
          <Form lazyValidation onSubmit={handleAddUser} direction="column">
            <Grid container spacing={1}>
              <Grid xs={12} md={6} item container direction="column">
                <Typography style={{ marginBottom: '12px' }}>
                  <FormattedMessage id="settings.organization.projects.edit.members.add.select_user" />
                </Typography>

                <Select
                  variant="outlined"
                  style={{ width: '300px', padding: '0' }}
                  value={user || ''}
                  classes={{ select: classes.select }}
                  MenuProps={{
                    className: classes.selectMenu,
                  }}
                  onChange={(evt) => {
                    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
                    setUser(evt.target.value);
                  }}
                  renderValue={(value) => {
                    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
                    const selectedUser = availableUsers.find(
                      (_user: any) => _user.user_id === value
                    );
                    if (!selectedUser) return null;

                    return (
                      <Grid container alignItems="center" style={{ flexWrap: 'unset' }}>
                        <Grid item>
                          <DefaultAvatar
                            size={24}
                            labelOverlayed
                            // @ts-expect-error ts-migrate(2339) FIXME: Property 'id' does not exist on type '{}'.
                            key={`avatar-${selectedUser.id}`}
                            // @ts-expect-error ts-migrate(2339) FIXME: Property 'first_name' does not exist on type '{}'.
                            label={`${selectedUser.first_name} ${selectedUser.last_name}`}
                            avatar={<Avatar data={selectedUser} labelOverlayed={false} size={24} />}
                          />
                        </Grid>

                        <Grid item>
                          <span
                            style={{ marginLeft: '8px' }}
                            // @ts-expect-error ts-migrate(2339) FIXME: Property 'first_name' does not exist on type '{}'.
                          >{`${selectedUser.first_name} ${selectedUser.last_name}`}</span>
                        </Grid>
                      </Grid>
                    );
                  }}
                >
                  {/* @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'. */}
                  {availableUsers.map((u: any) => {
                    return (
                      <MenuItem key={u.user_id} value={u.user_id}>
                        <DefaultAvatar
                          labelOverlayed
                          key={`avatar-${u.user_id}`}
                          label={`${u.first_name} ${u.last_name}`}
                          // avatar={u.avatar}
                          avatar={<Avatar data={u} labelOverlayed={false} size={24} />}
                        />
                        <span style={{ marginLeft: '8px' }}>
                          {`${u.first_name} ${u.last_name}`}
                        </span>
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>

              <Grid xs={12} md={6} item container direction="column">
                <Typography style={{ marginBottom: '12px' }}>
                  <FormattedMessage id="settings.organization.projects.edit.members.add.project_role" />
                </Typography>

                <CustomSelect
                  value={role}
                  width={300}
                  disabled={disabledSubmitButton}
                  selectItems={[
                    // @ts-expect-error ts-migrate(2322) FIXME: Type '{ value: string; title: JSX.Element; info: J... Remove this comment to see the full error message
                    {
                      value: projectHelpers.ProjectRoles.project_manager,
                      title: (
                        <FormattedMessage id="settings.organization.projects.edit.roles.manager" />
                      ),
                      info: (
                        <FormattedMessage id="settings.organization.projects.edit.roles.manager.description" />
                      ),
                    },
                    // @ts-expect-error ts-migrate(2322) FIXME: Type '{ value: string; title: JSX.Element; info: J... Remove this comment to see the full error message
                    {
                      value: projectHelpers.ProjectRoles.project_user,
                      title: (
                        <FormattedMessage id="settings.organization.projects.edit.roles.user" />
                      ),
                      info: (
                        <FormattedMessage id="settings.organization.projects.edit.roles.user.description" />
                      ),
                    },
                  ]}
                  onChange={(value: any) => {
                    setRole(value);
                  }}
                />
              </Grid>
            </Grid>

            <VSpacer large />

            <FormControl alignItems="center" direction="row" justify="space-between">
              <Button onClick={closeDialog} color="secondary">
                <FormattedMessage id="settings.buttoncancel" />
              </Button>
              <Button type="submit" color="primary" disabled={disabledSubmitButton || !user}>
                <FormattedMessage id="settings.buttonadd" />
              </Button>
            </FormControl>

            <VSpacer small />
          </Form>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

OrganizationProjectAddUserDialog.defaultProps = {
  isOpen: false,
};

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    addProjectUser: (projectId: any, projectUserData: any) =>
      dispatch(Projects.addProjectUser(projectId, projectUserData)),
  },
});

export default connect(
  null,
  mapDispatchToProps
)(withStyles(styles)(OrganizationProjectAddUserDialog));
