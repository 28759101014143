import SvgIcon from '@material-ui/core/SvgIcon';
import createSvgIcon from '@material-ui/icons/utils/createSvgIcon';
import React from 'react';

const normal = createSvgIcon(
  <>
    <path d="m12 6c-3.3 0-6 2.7-6 6s2.7 6 6 6 6-2.7 6-6-2.7-6-6-6zm0 10c-2.2 0-4-1.8-4-4s1.8-4 4-4 4 1.8 4 4-1.8 4-4 4z" />
    <path d="m12 0c-6.6 0-12 5.4-12 12s5.4 12 12 12 12-5.4 12-12-5.4-12-12-12zm0 20c-4.4 0-8-3.6-8-8s3.6-8 8-8 8 3.6 8 8-3.6 8-8 8z" />
  </>,
  'TypeIconNormal'
);

const fav = createSvgIcon(
  <>
    <path d="m12 0c-6.6 0-12 5.4-12 12s5.4 12 12 12 12-5.4 12-12-5.4-12-12-12zm6.7 10.4-2.5 2.3c-0.1 0.1-0.2 0.2-0.1 0.4l0.6 3.3c0.1 0.3 0 0.6-0.2 0.8s-0.5 0.3-0.8 0.3c-0.2 0-0.3 0-0.5-0.1l-3-1.5c-0.1-0.1-0.3-0.1-0.4 0l-3 1.5c-0.4 0.2-0.9 0.1-1.2-0.2-0.2-0.2-0.3-0.5-0.2-0.8l0.6-3.3c0-0.1 0-0.3-0.1-0.4l-2.5-2.3c-0.4-0.3-0.5-0.7-0.4-1 0.2-0.4 0.5-0.6 0.9-0.7l3.4-0.5c0.1 0 0.3-0.1 0.3-0.2l1.5-3c0.2-0.3 0.5-0.5 0.9-0.5s0.7 0.2 0.9 0.5l1.5 3c0.1 0.1 0.2 0.2 0.3 0.2l3.4 0.5c0.4 0.1 0.7 0.3 0.9 0.7 0.1 0.3 0 0.7-0.3 1z" />
  </>,
  'TypeIconFav'
);

const champ = createSvgIcon(
  <>
    <path d="m7.6 6.8h-1.4c0.1 1.4 0.5 2.8 1.1 3.8 0.5 0.7 1.1 1.2 1.7 1.5l-0.3-0.6c-0.6-1.3-1-2.9-1.1-4.7z" />
    <path d="m15.3 11.5-0.3 0.6c0.6-0.2 1.2-0.7 1.7-1.5 0.7-1 1.1-2.3 1.1-3.8h-1.3c-0.2 1.8-0.6 3.4-1.2 4.7z" />
    <path d="m12 0c-6.6 0-12 5.4-12 12s5.4 12 12 12 12-5.4 12-12-5.4-12-12-12zm5.3 11c-0.8 1.2-1.9 1.9-3.1 2-0.3 0.3-0.5 0.5-0.8 0.7v1.7h0.6c0.8 0 1.4 0.6 1.4 1.4v0.6c0.2 0 0.4 0.2 0.4 0.4s-0.2 0.4-0.4 0.4h-7c-0.2 0-0.4-0.2-0.4-0.4s0.2-0.4 0.4-0.4v-0.6c0-0.8 0.6-1.4 1.4-1.4h0.6v-1.7c-0.3-0.2-0.6-0.4-0.8-0.7-1.2-0.1-2.2-0.8-3.1-2-0.6-1.2-1-2.8-1-4.5 0-0.2 0.2-0.4 0.4-0.4h1.7v-0.6c0-0.2 0.2-0.4 0.4-0.4h8.3c0.2 0 0.4 0.2 0.4 0.4v0.6h1.7c0.2 0 0.4 0.2 0.4 0.4-0.2 1.7-0.7 3.3-1.5 4.5z" />
  </>,
  'TypeIconChamp'
);

const poi = createSvgIcon(
  <>
    <circle cx="11.9" cy="9.1" r="1.8" />
    <path d="m12 0c-6.6 0-12 5.4-12 12s5.4 12 12 12 12-5.4 12-12-5.4-12-12-12zm4.7 11.9-4.3 7c-0.1 0.2-0.3 0.3-0.5 0.3s-0.4-0.1-0.5-0.3l-4.2-7c-0.4-0.9-0.7-1.9-0.7-2.8 0-3 2.5-5.5 5.6-5.5 3 0 5.4 2.4 5.4 5.4 0 1-0.4 2-0.8 2.9z" />
  </>,
  'TypeIconPoi'
);
const bookmark = createSvgIcon(
  <>
    <path d="m12 0c-6.6 0-12 5.4-12 12s5.4 12 12 12 12-5.4 12-12-5.4-12-12-12zm5.1 15.1c0 0.2-0.1 0.3-0.2 0.5-0.1 0.1-0.2 0.3-0.4 0.3-0.1 0-0.2 0.1-0.3 0.1-0.3 0-0.5-0.1-0.7-0.3l-3.5-3.3-3.5 3.3c-0.1 0.2-0.3 0.3-0.6 0.3-0.1 0-0.2 0-0.3-0.1-0.2-0.1-0.3-0.2-0.4-0.3s-0.2-0.3-0.2-0.5v-10.2c0-0.2 0.1-0.3 0.2-0.5s0.2-0.3 0.4-0.3c0.1 0 0.2-0.1 0.3-0.1h8.3c0.1 0 0.2 0 0.3 0.1 0.2 0.1 0.3 0.2 0.4 0.3s0.2 0.3 0.2 0.5v10.2z" />
  </>,
  'TypeIconBookmark'
);
const user = createSvgIcon(
  <>
    <path d="m12 0c-6.6 0-12 5.4-12 12s5.4 12 12 12 12-5.4 12-12-5.4-12-12-12zm0 18.5h-6.4c0-4.7 4.1-4.7 5-5.9l0.1-0.6c-1.3-0.6-2.2-2.2-2.2-4 0-2.4 1.6-4.4 3.5-4.4s3.5 2 3.5 4.4c0 1.8-0.9 3.4-2.2 4l0.1 0.6c1 1.2 5 1.2 5 5.8h-6.4z" />
  </>,
  'TypeIconUser'
);
const hangar = createSvgIcon(
  <>
    <path d="m12 0c-6.6 0-12 5.4-12 12s5.4 12 12 12 12-5.4 12-12-5.4-12-12-12zm3.5 18.8h-7v-1h7.1v1zm0-2.2h-7v-1.2h7.1v1.2zm0-2.3h-7v-1.2h7.1v1.2zm0-2.3h-7v-1.1h7.1v1.1zm3.1-2.2v9h-1.9v-9h-9.4v9h-1.8v-9h-2.1c1.4-3 4.7-5.4 8.6-5.4s7.2 2.4 8.6 5.4h-2z" />
  </>,
  'TypeIconHangar'
);
const warning = createSvgIcon(
  <>
    <polygon points="7.7 15.5 16.3 15.5 12 7.6" />
    <path d="m12 0c-6.6 0-12 5.4-12 12s5.4 12 12 12 12-5.4 12-12-5.4-12-12-12zm7.8 17.2c-0.1 0.2-0.3 0.2-0.5 0.2h-14.6c-0.2 0-0.4-0.1-0.5-0.2-0.1-0.2-0.1-0.3 0-0.5l7.3-12.6c0.1-0.2 0.3-0.3 0.4-0.3s0.3 0.1 0.4 0.3l7.3 12.6c0.2 0.2 0.3 0.4 0.2 0.5z" />
  </>,
  'TypeIconWarning'
);
const barrow = createSvgIcon(
  <>
    <path d="m14.3 13.9c-0.8 0-1.5 0.7-1.5 1.5s0.7 1.5 1.5 1.5 1.5-0.7 1.5-1.5c0.1-0.8-0.6-1.5-1.5-1.5zm0 2.1c-0.3 0-0.6-0.3-0.6-0.6s0.3-0.6 0.6-0.6 0.6 0.3 0.6 0.6c0.1 0.3-0.2 0.6-0.6 0.6z" />
    <path d="m12 0c-6.6 0-12 5.4-12 12s5.4 12 12 12 12-5.4 12-12-5.4-12-12-12zm-2.5 8.1h9.1c0.4 0 0.7 0.4 0.6 0.8l-1.2 4.2c-0.1 0.2-0.3 0.4-0.5 0.4-0.6-1.1-1.8-1.8-3.2-1.8-1.3 0-2.5 0.7-3.2 1.8-0.2 0-0.4-0.2-0.4-0.4l-1.8-4.2c-0.2-0.3 0.1-0.8 0.6-0.8zm9.7 7.3h-1.8c0 1.7-1.4 3-3 3s-3-1.4-3-3h-1l-2.3 2.3-0.9-0.9 2.1-2.1-3.3-7.2h-1.4v-1.2h1.8c0.2 0 0.5 0.1 0.6 0.4l3.5 7.6h1.1c0.5-1.1 1.5-1.8 2.8-1.8 1.2 0 2.3 0.8 2.8 1.8h2.1v1.1z" />
  </>,
  'TypeIconBarrow'
);

export const colors: {[key: string]: string} = {
  normal: '#8194B8',
  fav: '#fde753',
  champ: '#8B572A',
  poi: ' #48B730',
  bookmark: '#9013FE',
  user: '#4BAEE8',
  hangar: '#5CB9B4',
  warning: '#BF2728',
  barrow: '#4A4A4A',
  planning: '#FF8200',
  in_progress: '#FB4A59',
  finished: '#006841',
  planningBG: '#FDE7CD',
  in_progressBG: '#FFE4E7',
  finishedBG: '#DDFDEE',
};

export default { normal, fav, champ, barrow, warning, hangar, user, bookmark, poi } as {[key: string]: typeof SvgIcon};
