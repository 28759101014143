import { Grid, Typography } from '@material-ui/core';
import { Button, Checkbox, Dialog, SearchInput, VSpacer } from '@sm-highway-web/react-components';
import { IProjectView } from 'highway-api/dist/common/interfaces/projects';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { connect } from 'react-redux';
import FormattedMessage from '../components/FormattedMessageCustom';
import { intl } from '../helpers/IntGlobalProvider';
import { Routes, Services } from '../redux/actions';
import constants from '../redux/constants/index';

export type ITypeData = `services` | `routes`;

type ConfigureTableColumnsDialogProps = {
  open: boolean;
  actions: {
    services: {
      setTableColumns: (...args: any[]) => any;
    };
    routes: {
      setTableColumns: (...args: any[]) => any;
    };
  };
  typeData: ITypeData;
  setDialog: (...args: any[]) => any;
  routesTableColumns: typeof constants.Routes.ROUTES_DEFAULT_TABLE_COLUMNS;
  servicesTableColumns: typeof constants.Services.SERVICES_DEFAULT_TABLE_COLUMNS;
  projectView?: IProjectView;
};

const ConfigureTableColumnsDialog = ({
  open,
  actions,
  typeData,
  setDialog,
  projectView,
  routesTableColumns,
  servicesTableColumns,
}: ConfigureTableColumnsDialogProps) => {

  const [filterText, setFilterText] = useState<string>('');
  const [columns, setColumns] = useState<{ [key: string]: boolean } | undefined>();
  const [filteredColumns, setFilteredColumns] = useState<{ [key: string]: boolean } | undefined>();

  // @ts-ignore
  const handleSearchRoutes = useCallback(() => {
    if (filterText !== '' && columns) {
      const regexx = new RegExp(`${filterText.toLowerCase()}`, `ig`);
      const matchingKeys = Object.keys(columns).filter((key: string) => {
        const translation = intl.formatMessage({ id: `${typeData}.configuretablecolumns.${key}` });
        return key.match(regexx) || translation.match(regexx);
      });

      const matchingColumns: { [key: string]: boolean } = {};
      matchingKeys.forEach((key: string) => {
        matchingColumns[key] = columns[key];
      });
      setFilteredColumns(matchingColumns);
    } else {
      setFilteredColumns(undefined);
    }
  }, [typeData, columns, filterText]);

  useEffect(() => {
    if (typeData === 'routes' && routesTableColumns) {
      setColumns(routesTableColumns);
    } else if (typeData === 'services' && servicesTableColumns) {
      setColumns(servicesTableColumns);
    }
  }, [typeData, setColumns, routesTableColumns, servicesTableColumns]);

  /**
   * Disables the las active checkbox. There should be at least one active checkbox.
   * @param checkboxValue
   */
  const checkboxesDisabled = (checkboxValue: boolean) => {
    const columnsArray = Object.keys(columns || {}).map((key: string) => (columns || {})[key]);
    const enabledValues = columnsArray.filter((value: boolean) => value === true);

    return checkboxValue === true && enabledValues.length === 1;
  };

  const showFieldDependingOnProjectViewPreferences = (key: string) => {
    let field: string = key;
    /**
     * This hack is needed because project.view uses weigth and volume but in frontend we use max_weight and mac_volume.
     */
    switch (key) {
      case 'max_weight':
        field = 'weight';
        break;
      case 'max_volume':
        field = 'volume';
        break;
      default:
        break;
    }

    if (
      projectView &&
      ((projectView.constraints && (projectView.constraints as any)[field] === false) ||
        (typeData === 'routes' &&
          projectView.vehicle &&
          (projectView.vehicle as any)[field] === false) ||
        (typeData === 'services' &&
          projectView.client &&
          (projectView.client as any)[field] === false))
    ) {
      return false;
    }
    return true;
  };

  if (!columns) return null;
  
  return (
    <Dialog
      modal
      onClose={() => setDialog(undefined)}
      open={open}
      title={<FormattedMessage id={`${typeData}.configuretablecolumns.title`} />}
    >
      <Typography>
        <FormattedMessage id={`${typeData}.configuretablecolumns.subtitle`} />
      </Typography>

      <VSpacer large />

      <Grid container direction="column">
        <Grid item>
          <Grid container direction="column" justify="center" style={{ height: '100%' }}>
            <SearchInput
              width="100%"
              onTextChange={_.debounce((text: any) => {
                setFilterText(text);
                handleSearchRoutes();
              }, 300)}
              searchLive
            />
          </Grid>
        </Grid>
      </Grid>

      <VSpacer medium />

      {Object.keys(filteredColumns || columns).length === 0 && (
        <Grid
          container
          direction="column"
          spacing={2}
          wrap="nowrap"
          style={{ maxHeight: '250px', minHeight: '200px', overflowY: 'auto' }}
          justify="center"
          alignContent="center"
        >
          <Typography align="center">
            <FormattedMessage id={`${typeData}.configuretablecolumns.no_results`} />
          </Typography>
        </Grid>
      )}

      {Object.keys(filteredColumns || columns).length > 0 && (
        <Grid
          container
          direction="column"
          spacing={2}
          wrap="nowrap"
          style={{ maxHeight: '250px', minHeight: '200px', overflowY: 'auto' }}
        >
          {Object.keys(filteredColumns || columns).map((key: string) => {
            const column = (columns as any)[key];
            if (!showFieldDependingOnProjectViewPreferences(key)) {
              return null;
            }
            return (
              <Grid item container direction="row" sm={12}>
                <Checkbox
                  checked={column}
                  disabled={(() => checkboxesDisabled(column))()}
                  onChange={(e: any, value: any) => {
                    const newColumns: { [key: string]: boolean } = { ...columns };
                    newColumns[key] = value;
                    setColumns(newColumns);
                    actions[typeData].setTableColumns(newColumns);
                  }}
                />
                <span
                  style={{ marginLeft: '8px', cursor: 'pointer' }}
                  onClick={() => {
                    if (!checkboxesDisabled(column)) {
                      const newColumns: { [key: string]: boolean } = { ...columns };
                      newColumns[key] = !newColumns[key];
                      setColumns(newColumns);
                      actions[typeData].setTableColumns(newColumns);
                    }
                  }}
                >
                  <FormattedMessage id={`${typeData}.configuretablecolumns.${key}`} />
                </span>
              </Grid>
            );
          })}
        </Grid>
      )}
      
      <VSpacer />

      <Grid container justify="flex-end">
        <Button
          variant="contained"
          onClick={() => setDialog(undefined)}
          color="primary"
          autoFocus
        >
          <FormattedMessage id="confirm" />
        </Button>
      </Grid>

      <VSpacer />
    </Dialog>
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    services: {
      setTableColumns: (columns: any) => dispatch(Services.setTableColumns(columns)),
    },
    routes: {
      setTableColumns: (columns: any) => dispatch(Routes.setTableColumns(columns)),
    },
  },
});

const mapStateToProps = (state: any) => {
  return {
    routesTableColumns: state.routes.tableColumns,
    servicesTableColumns: state.services.tableColumns,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ConfigureTableColumnsDialog);
