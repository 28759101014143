import { ListItemText, MenuItem, Popover, Select, Tooltip, Typography } from '@material-ui/core';
import { PopoverOrigin } from '@material-ui/core/Popover';
import { Add, Close } from '@material-ui/icons';
import { IProjectCustomField } from 'highway-api/dist/common/interfaces/projects';
import React, { useState } from 'react';
import { Label } from '../form-fields/FormFields.styled';
import FormattedMessage from '../FormattedMessageCustom';

const popoverConfig = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
};

type CustomFieldFormCategoricalProps = {
  onUpdate?: (...args: any[]) => any;
  fieldValue: string | string[];
  projectField: IProjectCustomField;
  readOnly?: boolean;
  onlyValues?: boolean;
  size?: 'medium' | 'large';
};

const CustomFieldFormCategorical = ({
  fieldValue,
  onUpdate,
  projectField,
  readOnly,
  onlyValues = false,
  size,
}: CustomFieldFormCategoricalProps) => {
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);

  if(projectField.type !== 'categorical') return null;

  return (
    <>
      {!onlyValues && <Label size={size}>{projectField.label}</Label>}
      {!projectField.multiple ? (
        readOnly ? (
          <span>{fieldValue ? (projectField.options!)[parseInt((fieldValue as string), 10)] : ''}</span>
        ) : (
          <Select
            displayEmpty
            multiple={projectField.multiple}
            style={{ width: '100%', marginBottom: '5px' }}
            onChange={(evt) => {
              if(onUpdate) {
                onUpdate([evt.target.value]);
              }
            }}
            value={fieldValue || ''}
          >
            <MenuItem key="not-selected" value="">
              <span
                style={{
                  color: '#929599',
                  fontSize: `${size === 'large' ? '16px' : '12px'}`,
                }}
              >
                <FormattedMessage id="not-selected" />
              </span>
            </MenuItem>

            {projectField.options!.map((option: any, index: any) => (
              <MenuItem key={`option-${index}`} value={index.toString()}>
                <ListItemText
                  disableTypography
                  primary={(
                    <Typography style={{ fontSize: `${size === 'large' ? '16px' : '12px'}` }}>
                      {option}
                    </Typography>
                  )}
                />
              </MenuItem>
            ))}
          </Select>
        )
      ) : (
        <div
          style={{
            display: 'flex',
            marginTop: '8px',
            flexWrap: 'wrap',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          {!readOnly && (!fieldValue || projectField.options!.length > fieldValue.length) ? (
            <div
              // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'EventTarget & HTMLDivElement' is... Remove this comment to see the full error message
              onClick={(e) => setPopoverAnchorEl(e.currentTarget)}
              style={{
                display: 'flex',
                cursor: 'pointer',
                flexDirection: 'row',
                alignItems: 'center',
                marginRight: '5px',
                marginBottom: '5px',
                padding: '8px',
                borderRadius: '15px',
                backgroundColor: '#f9f9f9',
              }}
            >
              <Add style={{ marginRight: '2px', fontSize: '18px', color: '#333940' }} />
              <span
                style={{
                  color: '#929599',
                  fontSize: `${size === 'large' ? '16px' : '12px'}`,
                }}
              >
                <FormattedMessage id="add" />
              </span>
            </div>
          ) : null}

          {fieldValue
            ? (fieldValue as string[]).map((value: any, index: any, array: any) => (
                <div
                  key={index}
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    padding: '8px',
                    borderRadius: '15px',
                    backgroundColor: '#e0e0e0',
                    marginRight: '5px',
                    marginBottom: '5px',
                  }}
                >
                  <Tooltip title={projectField.options![parseInt(value, 10)]}>
                    <span
                      style={{
                        fontSize: '14px',
                        color: '#333940',
                      }}
                    >
                      {projectField.options![parseInt(value, 10)]}
                    </span>
                  </Tooltip>
                  {!readOnly ? (
                    <Close
                      style={{ cursor: 'pointer', fontSize: '18px', color: '#a6a6a6' }}
                      onClick={() => {
                        const optionsArray = [...array];
                        optionsArray.splice(index, 1);
                        if(onUpdate) {
                          onUpdate(optionsArray);
                        }
                      }}
                    />
                  ) : null}
                </div>
              ))
            : null}

          <Popover
            id={popoverAnchorEl !== null ? `${projectField.id}-popover` : undefined}
            open={Boolean(popoverAnchorEl)}
            anchorEl={popoverAnchorEl}
            onClose={() => setPopoverAnchorEl(null)}
            anchorOrigin={popoverConfig.anchorOrigin as PopoverOrigin}
            transformOrigin={popoverConfig.transformOrigin as PopoverOrigin}
          >
            <div style={{ padding: '8px 0' }}>
              {projectField.options!.map((value: any, index: any) => (
                <MenuItem
                  style={{
                    maxWidth: '300px',
                    whiteSpace: 'normal',
                    overflow: 'auto',
                    fontSize: `${size === 'large' ? '16px' : '12px'}`,
                  }}
                  key={index}
                  disabled={!!fieldValue && (fieldValue as string[]).indexOf(index.toString()) > -1}
                  onClick={() => {
                    const optionsArray = [...((fieldValue as string[]) || [])];
                    optionsArray.push(index.toString());
                    if(onUpdate) {
                      onUpdate(optionsArray);
                    }
                    setPopoverAnchorEl(null);
                  }}
                >
                  <span>{value}</span>
                </MenuItem>
              ))}
            </div>
          </Popover>
        </div>
      )}
    </>
  );
};

CustomFieldFormCategorical.defaultProps = {
  readOnly: false,
  size: 'medium',
};

export default CustomFieldFormCategorical;
