import PropTypes from 'prop-types';
import styled from 'styled-components';

export const Placeholder = styled.div`
  font-size: 12px;
  color: #c1c0c0;
  padding: 0 5px;
`;

export const ProjectCustomField = styled.div`
  margin-bottom: 20px;
  min-width: 300px;
  ${(props: any) => (props.maxWidth ? `max-width: ${props.maxWidth}px;` : 'unset')}
  color: #333940;
  padding: 12px;
  border-radius: 8px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14),
    0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  ${(props: any) => (props.enabled ? `background-color: white;` : `background-color: #e9e9e9;`)}
`;

ProjectCustomField.propTypes = {
  // @ts-expect-error ts-migrate(2322) FIXME: Type '{ enabled: PropTypes.Validator<boolean>; }' ... Remove this comment to see the full error message
  enabled: PropTypes.bool.isRequired,
  maxWidth: PropTypes.number,
};

export const ProjectCustomFieldHeader = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const ProjectCustomFieldHeaderTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #050d1e;
  .span {
    font-size: 20px;
  }
`;

export const ProjectCustomFieldBody = styled.div`
  margin: 10px 0;
  display: flex;
  align-items: start;
  flex-direction: column;
`;

export const IdChip = styled.span`
  color: white;
  font-size: 11px;
  padding: 2px 4px;
  border-radius: 4px;
  background-color: #aaaaaa;
`;

export const CategoricalContent = styled.div`
  width: 100%;
  margin-top: 20px;
`;

export const CategoricalOptions = styled.div`
  display: flex;
  flex-direction: column;
  max-height: 150px;
  overflow-y: auto;
  margin-bottom: 10px;
  .option {
    font-size: 12px;
    padding: 10px;
    border-radius: 6px;
    background-color: #e9e9e9;
    margin-bottom: 5px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }
`;

export const CategoricalNewOption = styled.div`
  cursor: pointer;
  font-size: 14px;
  color: grey;
  margin-bottom: 20px;
`;

export const CategoricalMultiple = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;
`;

export const ProjectCustomFieldFooter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const RemoveField = styled.button`
  margin-left: 10px;
  color: #d2190b;
  font-size: 14px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  outline: none;
`;

// padding: 0;
// border: none;
// text-transform: none;
// margin-left: 10px;
// color: #d2190b;
// font-weight: normal;
// font-size: 14px;
