import { Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Button, HSpacer, VSpacer } from '@sm-highway-web/react-components';
import { IPlanData, IPlanPagination } from 'highway-api/dist/common/interfaces/plans';
import { IProjectData } from 'highway-api/dist/common/interfaces/projects';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import EmptyViewExample from '../assets/img/plans_example.svg';
import { colors } from '../assets/svg/TypeIcons';
import { getServicesExamples, getVehiclesExamples } from '../assets/templates/examples';
import DataTable from '../components/datatable/DataTable';
import FormattedMessage from '../components/FormattedMessageCustom';
import Link from '../components/Link';
import Loader from '../components/Loader';
import Menu3Points from '../components/Menu3Points';
import PlanChip, { EmptyPlanChip } from '../components/plans/PlanChip';
import StatusBarComponent from '../components/StatusBarComponent';
import * as routing from '../constants/Routing';
import CopyPlanDialog from '../dialogs/CopyPlanDialog';
import { dateFormat } from '../helpers/TimeDistance';
import { IUserLocation } from '../interfaces/common';
import { ActivePlan, InfoError, Plans } from '../redux/actions';
import eventsPanel, { PageViewPages } from '../services/events';
import { copyPlan, getPlans } from '../services/requests';

const styles = {
  divInside: {
    height: 'calc(100vh - 30px)',
    width: '100%',
    zIndex: 1,
  },
  divTable: {
    height: 'calc(100% - 150px)',
    backgroundColor: 'white',
  },
  allFull: {
    height: '100%',
    width: '100%',
  },
  lastPlans: {
    height: '150px',
    overflow: 'hidden',
  },
  tableServicesDiv: {
    backgroundColor: '#5A6B86',
    padding: '4px 0px',
    width: '40px',
    borderRadius: '4px',
  },
  typowhitebold: { color: 'white', fontWeight: 'bold' },
  menuItem: { padding: '8px 16px' },
  actionTable: {
    height: '48px',
    width: '100%',
  },
  table: {
    height: 'calc(100% - 48px)',
    width: '100%',
  },
};

type PlansPageProps = {
  classes: {
    divInside: string;
    divTable: string;
    allFull: string;
    lastPlans: string;
    tableServicesDiv: string;
    typowhitebold: string;
    menuItem: string;
    table: string;
    actionTable: string;
  };
  history: {
    push: (...args: any[]) => any;
  };
  actions: {
    createPlan: (...args: any[]) => any;
    updatePlan: (...args: any[]) => any;
    deletePlan: (...args: any[]) => any;
    getPlans: (...args: any[]) => any;
    getPlan: (...args: any[]) => any;
    createExamplePlan: (...args: any[]) => any;
    sendStatusToSnackbar: (...args: any[]) => any;
  };
  plans: IPlanPagination;
  userLocation?: IUserLocation;
  activeProject?: IProjectData;
};

const PlansPage = ({
  classes,
  actions,
  history,
  plans,
  userLocation,
  activeProject,
}: PlansPageProps) => {
  const [lastPlans, setLastPlans] = useState<IPlanData[] | undefined>(undefined);
  const [isCreating, setCreating] = useState(false);
  const [copyPlanInfo, setCopyPlanInfo] = useState(null);
  const [isCopyPlanDialogOpen, setIsCopyPlanDialogOpen] = useState(false);

  useEffect(() => {
    eventsPanel.pageView(PageViewPages.plans);
  }, []);

  useEffect(() => {
    if (activeProject) {
      actions.getPlans(activeProject.id);
    }
  }, [actions, activeProject]);

  useEffect(() => {
    if (plans && activeProject) {
      (async () => {
        const resp = await getPlans(undefined, 'in_progress', 0, 3, undefined, undefined, undefined, undefined);
        setLastPlans(resp.docs);
      })();
    }
  }, [plans, activeProject]);

  if (!plans.docs) {
    return (
      <Grid container justify="center" style={{ height: '800px' }} alignItems="center">
        <Grid container justify="center" style={{ width: '200px', height: '200px' }}>
          <Loader />
        </Grid>
      </Grid>
    );
  }

  const columnMapping = [
    {
      column: 'status',
      name: <FormattedMessage id="plansactivity.table.header.status" />,

      formatter: (doc: any) =>
        doc ? (
          <Typography
            style={{
              color: colors[doc],
              backgroundColor: colors[`${doc}BG`],
              padding: ' 2px 4px',
              borderRadius: '4px',
              maxWidth: '140px',
            }}
            align="center"
          >
            <FormattedMessage id={`${doc}.upper`} />
          </Typography>
        ) : null,
    },
    {
      column: 'label',
      name: <FormattedMessage id="plansactivity.table.header.label" />,
      computed: (doc: any) => doc,
      formatter: (doc: any) => (
        <Link
          to={`${routing.PLANS.replace(
            ':projectId',
            String(activeProject?.id)
          )}/${doc.id}/${routing.PlanViews.list}`}
        >
          {doc.label}
        </Link>
      ),
    },
    {
      column: 'created_at',
      name: <FormattedMessage id="plansactivity.table.header.createdate" />,
      computed: (doc: any) => dateFormat(doc.created_at),
    },
    {
      column: 'total_routes',
      name: <FormattedMessage id="plansactivity.table.header.routes" />,
      formatter: (doc: any) => (
        <div className={classes.tableServicesDiv}>
          <Typography align="center" className={classes.typowhitebold}>
            {doc}
          </Typography>
        </div>
      ),
    },
    {
      column: 'total_services',
      name: <FormattedMessage id="plansactivity.table.header.services" />,
      formatter: (doc: any) => (
        <div className={classes.tableServicesDiv}>
          <Typography align="center" className={classes.typowhitebold}>
            {doc}
          </Typography>
        </div>
      ),
    },
    {
      column: 'progress',
      name: <FormattedMessage id="plansactivity.table.header.progress" />,
      computed: (doc: any) => doc,
      formatter: (doc: any) => (
        <StatusBarComponent
          pendingServices={doc.pending_services}
          completedServices={doc.completed_services}
          canceledServices={doc.canceled_services}
          width="100px"
        />
      ),
    },
    {
      columns: '_actions',
      name: <FormattedMessage id="plansactivity.table.header.actions" />,
      formatter: (f: any, doc: any) => (
        <Menu3Points
          doc={doc}
          history={history}
          actions={actions}
          setLastPlans={setLastPlans}
          activeProject={activeProject}
          openCopyPlanDialog={() => {
            const { id, label } = doc;
            // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ id: any; label: any; }' is not... Remove this comment to see the full error message
            setCopyPlanInfo({ id, label });
            setIsCopyPlanDialogOpen(true);
          }}
        />
      ),
    },
  ];

  const onCopyPlan = async (sourcePlanId: any, label: any, filters: any) => {
    const createdPlan = await copyPlan(sourcePlanId, label, filters);

    if (createdPlan && createdPlan.id && activeProject) {
      setIsCopyPlanDialogOpen(false);
      setCopyPlanInfo(null);
      history.push(`${routing.PLANS.replace(':projectId', activeProject.id)}/${createdPlan.id}/${routing.PlanViews.list}`);
    } else {
      actions.sendStatusToSnackbar('infoerror.copyplanerror', 'error', 8000);
    }
  };

  return (
    <>
      {isCopyPlanDialogOpen && (
        <CopyPlanDialog
          // @ts-expect-error ts-migrate(2322) FIXME: Type 'null' is not assignable to type '{ id?: stri... Remove this comment to see the full error message
          planInfo={copyPlanInfo}
          handleCopyPlan={onCopyPlan}
          copyDialogOpen={isCopyPlanDialogOpen}
          handleOpenAssignDialog={() => {
            setIsCopyPlanDialogOpen(!isCopyPlanDialogOpen);
          }}
        />
      )}

      <div style={{ backgroundColor: '#CECDCD', height: '100vh' }}>
        <div
          style={{
            width: '100%',
            height: '178px',
            position: 'absolute',
            backgroundColor: '#333940',
            zIndex: 0,
          }}
        />
        <div>
          <Grid item container md={12} justify="center">
            <Grid item container direction="column" md={10} lg={10} xl={8}>
              <VSpacer large />
              <Grid container className={classes.divInside} direction="column">
                <VSpacer small />
                <Typography
                  style={{
                    textTransform: 'uppercase',
                    fontSize: '18px',
                    fontWeight: 500,
                    color: 'white',
                  }}
                >
                  <FormattedMessage id="plans.plans_in_progress" />
                </Typography>
                <VSpacer medium />
                <Grid container className={classes.lastPlans}>
                  {lastPlans && lastPlans.length === 0 && <EmptyPlanChip />}
                  {lastPlans && (
                    <>
                      <PlanChip
                        plan={lastPlans[0]}
                        handleClick={() => {
                          if (lastPlans[0])
                            history.push(
                              `${routing.PLANS.replace(
                                ':projectId',
                                // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
                                activeProject ? activeProject.id : undefined
                              )}/${lastPlans[0].id}/${routing.PlanViews.list}`
                            );
                        }}
                      />
                      <HSpacer medium />

                      <PlanChip
                        plan={lastPlans[1]}
                        handleClick={() => {
                          if (lastPlans[1])
                            history.push(
                              `${routing.PLANS.replace(
                                ':projectId',
                                // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
                                activeProject ? activeProject.id : undefined
                              )}/${lastPlans[1].id}/${routing.PlanViews.list}`
                            );
                        }}
                      />
                      <HSpacer medium />
                      <PlanChip
                        plan={lastPlans[2]}
                        handleClick={() => {
                          if (lastPlans[2])
                            history.push(
                              `${routing.PLANS.replace(
                                ':projectId',
                                // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
                                activeProject ? activeProject.id : undefined
                              )}/${lastPlans[2].id}/${routing.PlanViews.list}`
                            );
                        }}
                      />
                    </>
                  )}
                </Grid>
                <VSpacer medium />
                <Grid
                  container
                  className={classes.divTable}
                  direction="column"
                  style={{
                    height: 'calc(100% - 160px - 46px)',
                  }}
                >
                  <Grid
                    container
                    style={{
                      height: '44px',
                      backgroundColor: 'rgb(51, 57, 64)',
                      padding: '4px 8px 4px 8px',
                    }}
                    alignItems="center"
                    justify="space-between"
                  >
                    <Typography variant="h6" style={{ color: 'white', textTransform: 'uppercase' }}>
                      <FormattedMessage id="plans.plans" />
                    </Typography>

                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={() => actions.createPlan()}
                    >
                      <FormattedMessage id="plansactivity.header.button" />
                    </Button>
                  </Grid>

                  <Grid className={classes.table}>
                    {plans.docs && plans.docs.length > 0 ? (
                      <DataTable
                        docs={plans.docs}
                        limit={plans.limit}
                        page={plans.page}
                        total={plans.total}
                        onChangePage={(offset: any, limit: any) =>
                          // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
                          actions.getPlans(activeProject.id, offset, limit)
                        }
                        hover
                        mapping={columnMapping}
                      />
                    ) : (
                      <Grid
                        container
                        direction="column"
                        alignItems="center"
                        justify="center"
                        style={{ padding: '32px' }}
                      >
                        <Typography
                          variant="h3"
                          style={{ textTransform: 'uppercase', color: 'rgb(51, 57, 64)' }}
                        >
                          <FormattedMessage id="plansactivity.noplans.title" />
                        </Typography>
                        <VSpacer medium />
                        <Grid container>
                          <Grid item sm={12}>
                            <Grid container direction="column" justify="center">
                              <Grid container justify="center">
                                {/* eslint-disable-next-line */}
                                <img
                                  alt="Upload xlsx"
                                  src={EmptyViewExample}
                                  width="150px"
                                  onClick={() =>
                                    actions.createExamplePlan(
                                      userLocation ? userLocation.country : undefined
                                    )
                                  }
                                  style={{
                                    cursor: 'pointer',
                                  }}
                                />
                              </Grid>
                              <VSpacer medium />
                              <Grid
                                container
                                justify="center"
                                style={{ textAlign: 'center', color: 'rgb(51, 57, 64)' }}
                              >
                                <FormattedMessage id="plansactivity.noplans.example" />
                              </Grid>
                              <VSpacer medium />
                              <Grid container justify="center">
                                <Button
                                  id="create-example-plan-button"
                                  disabled={isCreating}
                                  variant="contained"
                                  onClick={() => {
                                    setCreating(true);
                                    actions.createExamplePlan(
                                      userLocation ? userLocation.country : undefined
                                    );
                                  }}
                                >
                                  {isCreating && (
                                    <>
                                      <Grid
                                        container
                                        direction="column"
                                        justify="center"
                                        style={{ height: '100%', width: '32px' }}
                                      >
                                        <div
                                          className="lds-ellipsis-little"
                                          style={{ height: '8px' }}
                                        >
                                          <div style={{ top: '2px', backgroundColor: 'white' }} />
                                          <div style={{ top: '2px', backgroundColor: 'white' }} />
                                          <div style={{ top: '2px', backgroundColor: 'white' }} />
                                        </div>
                                      </Grid>
                                      <HSpacer small />
                                    </>
                                  )}
                                  <FormattedMessage id="plansactivity.header.button2" />
                                </Button>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </div>
    </>
  );
};

PlansPage.defaultProps = {
  userLocation: undefined,
  activeProject: undefined,
};

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    createPlan: () => dispatch(ActivePlan.createPlan({})),
    updatePlan: (planId: any, plan: any, refresh: any) =>
      dispatch(ActivePlan.updatePlan(planId, plan, refresh)),
    deletePlan: (planId: any) => dispatch(Plans.deletePlan(planId)),
    getPlan: (planId: any) => dispatch(ActivePlan.getPlan(planId)),
    getPlans: (projectId: any, offset: any, limit: any, text: any) =>
      dispatch(Plans.getPlans(projectId, offset, limit, text)),
    createExamplePlan: (country: any) => {
      dispatch(
        ActivePlan.createPlan({
          routes: getVehiclesExamples(country),
          services: getServicesExamples(country),
        })
      );
    },
    sendStatusToSnackbar: (message: any, type: any) =>
      dispatch(InfoError.sendStatusToSnackbar(message, type)),
  },
});

const mapStateToProps = (state: any) => {
  return {
    plans: state.plans.plans,
    userLocation: state.user.userLocation,
    activeProject: state.projects.activeProject,
  };
};

// @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ divInside: { height: string; w... Remove this comment to see the full error message
export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PlansPage));
