import { Grid, Typography } from '@material-ui/core';
import { Button, HSpacer, InputText, VSpacer } from '@sm-highway-web/react-components';
import { CardElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { IBillingInfo } from 'highway-api/dist/common/interfaces/subscriptions';
import React, { useEffect, useState } from 'react';
import CardInfo from '../CardInfo';
import FormattedMessage from '../FormattedMessageCustom';
import CardPaymentForm from './CardPaymentForm';

type OwnProps = {
  data?: {
    plan?: string;
    vehicles?: number;
    billingAddress?: IBillingInfo;
    card?: any;
  };
  onNextStep: (...args: any[]) => any;
  onPreviousStep: (...args: any[]) => any;
};

type Props = OwnProps;

const PaymentMethod = ({ data, onNextStep, onPreviousStep }: Props) => {
  const [showCard, setShowCard] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [name, setName] = useState();
  const elements = useElements();
  const stripe = useStripe();
  const [card, setCard] = useState((data && data.card) || undefined);
  useEffect(() => {
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    if (data.card && Object.keys(data.card).length > 0) {
      setShowCard(true);
    }
  }, [setShowCard, data]);

  const handleSubmit = async (evt: any) => {
    evt.preventDefault();
    setLoading(true);
    if (showCard) {
      return onNextStep({});
    }
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    const cardElement = elements.getElement(CardElement);
    setCard(cardElement);
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    const { token } = await stripe.createToken(cardElement, { name });
    if (token) {
      await onNextStep({ card: token.card, token: token.id });
    } else {
      setLoading(false);
    }
    return {};
  };

  return (
    <>
      <Typography variant="h2" align="center">
        <FormattedMessage id="paymentrunwaydialog.step3.title" />
      </Typography>
      <VSpacer />

      {showCard && <CardInfo card={card} />}
      {!showCard && (
        <>
          <Typography variant="h6">
            <FormattedMessage id="paymentrunwaydialog.step3.subsection1.title" />
          </Typography>
          <InputText
            id="name"
            label={<FormattedMessage id="settings.billing.payment.dialog.inputname" />}
            value={name}
            onChange={setName}
            margin="none"
            fullWidth
            validators={[]}
            autoFocus
          />
          <CardPaymentForm />
        </>
      )}
      {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
      <Grid container justify="flex-end" formContainer>
        <Button variant="text" color="secondary" onClick={onPreviousStep} disabled={isLoading}>
          <FormattedMessage id="paymentrunwaydialog.buttonback" />
        </Button>
        <HSpacer medium />
        <Button onClick={handleSubmit} variant="contained" disabled={isLoading}>
          {isLoading ? (
            <FormattedMessage id="paymentrunwaydialog.buttonloading" />
          ) : (
            <FormattedMessage id="paymentrunwaydialog.buttonnext" />
          )}
        </Button>
      </Grid>
    </>
  );
};

PaymentMethod.defaultProps = {
  data: {},
};

export default PaymentMethod;
