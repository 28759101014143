import { Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment';
import React, { Fragment, PureComponent } from 'react';
import { Line } from 'react-chartjs-2';
import { InputText, VSpacer } from '@sm-highway-web/react-components';
import {
  aggregateByWeek,
  dateFormatToPicker,
  getAllDatesBetween,
  pushZeroBetweenStartDateAndFirstDate,
} from '../helpers/TimeDistance';
import { getAcquisitionStats } from '../services/requests';
import FormattedMessage from './FormattedMessageCustom';
import RoundStatistics from './RoundStatistics';
import Funnel from './stats/Funnel';

const styles = () => ({
  containerDiv: {
    height: '100%',
    padding: '24px',
  },
});

type AdministrationAcquisitionProps = {
  classes: {
    containerDiv: string;
  };
  history: {
    push: (...args: any[]) => any;
  };
};

type AdministrationAcquisitionState = any;

class AdministrationAcquisition extends PureComponent<
  AdministrationAcquisitionProps,
  AdministrationAcquisitionState
> {
  state = {
    // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
    startDate: dateFormatToPicker(new Date(moment().subtract(30, 'days'))),
    endDate: dateFormatToPicker(new Date()),
    stats: undefined,
    isWeekly: false,
  };

  componentDidMount = async () => {
    const { startDate, endDate } = this.state;
    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
    const response = await getAcquisitionStats(startDate, `${endDate}T23:59:59.999Z`);
    if (response.status === 200) {
      this.setState({
        stats: {
          ...response.data,
          registerStats: pushZeroBetweenStartDateAndFirstDate(
            getAllDatesBetween(response.data.register_stats, 'value'),
            startDate,
            'value'
          ),
          weeklyStats: aggregateByWeek(
            pushZeroBetweenStartDateAndFirstDate(
              getAllDatesBetween(response.data.register_stats, 'value'),
              startDate,
              'value'
            ),
            'value'
          ),
        },
      });
    }
  };

  handleChangeDate = (name: any) => (date: any) => {
    this.setState({ [name]: date }, async () => {
      const { startDate, endDate } = this.state;
      if (startDate !== '' && endDate !== '') {
        // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string' is not assignable to par... Remove this comment to see the full error message
        const response = await getAcquisitionStats(startDate, `${endDate}T23:59:59.999Z`);
        if (response.status === 200) {
          this.setState({
            stats: {
              ...response.data,
              registerStats: pushZeroBetweenStartDateAndFirstDate(
                getAllDatesBetween(response.data.register_stats, 'value'),
                startDate,
                'value'
              ),
              weeklyStats: aggregateByWeek(
                pushZeroBetweenStartDateAndFirstDate(
                  getAllDatesBetween(response.data.register_stats, 'value'),
                  startDate,
                  'value'
                ),
                'value'
              ),
            },
          });
        }
      }
    });
  };

  render() {
    const { classes } = this.props;
    const { endDate, startDate, stats, isWeekly } = this.state;
    return (
      <div className={classes.containerDiv}>
        <Grid container direction="column">
          <Grid item>
            <Typography variant="h2">
              <FormattedMessage id="admin.acquisition.title" />
            </Typography>
          </Grid>
          <VSpacer />
          <Grid item>
            <Grid container spacing={6}>
              <Grid item sm={4}>
                {stats && (
                  <Funnel
                    title="Activation Funnel"
                    data={[
                      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
                      stats.total_users,
                      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
                      stats.validated,
                      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
                      stats.with_plans,
                      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
                      stats.with_optimization,
                    ]}
                    colors="#89cff0"
                    labels={['Registered', 'Validated', 'With Plans', 'With Opt.']}
                  />
                )}
              </Grid>
              <Grid item sm={4}>
                {stats && (
                  <RoundStatistics
                    title="Optimization"
                    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
                    data={[stats.total_users - stats.with_optimization, stats.with_optimization]}
                    labels={['Without Opt.', 'With Opt.']}
                  />
                )}
              </Grid>
              <Grid item sm={4}>
                <InputText
                  InputLabelProps={{
                    shrink: true,
                  }}
                  id="sdate"
                  label="Start Date"
                  type="date"
                  value={startDate}
                  onChange={this.handleChangeDate('startDate')}
                  fullWidth
                />
                <InputText
                  InputLabelProps={{
                    shrink: true,
                  }}
                  id="fdate"
                  label="End Date"
                  type="date"
                  value={endDate}
                  onChange={this.handleChangeDate('endDate')}
                  fullWidth
                />
              </Grid>
            </Grid>
          </Grid>
          <VSpacer medium />
          <div
            style={{ cursor: 'pointer' }}
            onDoubleClick={() =>
              this.setState((prevState: any) => ({
                isWeekly: !prevState.isWeekly,
              }))
            }
          >
            {!isWeekly && (
              <>
                <Typography variant="h4">Daily stats</Typography>
                <VSpacer medium />
                <Grid item>
                  {stats && (
                    <Line
                      height={80}
                      options={{
                        elements: {
                          line: {
                            tension: 0, // disables bezier curves
                          },
                        },
                        scales: {
                          yAxes: [
                            {
                              stacked: true,
                            },
                          ],
                        },
                      }}
                      data={{
                        datasets: [
                          {
                            // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
                            data: stats.register_stats.map((s: any) => s.value),
                            fill: false,
                            borderColor: '#3cba9f',
                            label: 'Daily registrations',
                          },
                        ],
                        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
                        labels: stats.register_stats.map((s: any) => s.date),
                      }}
                    />
                  )}
                </Grid>
              </>
            )}
            {isWeekly && (
              <>
                <Typography variant="h4">Weekly stats</Typography>
                <VSpacer medium />
                <Grid item>
                  {stats && (
                    <Line
                      height={80}
                      options={{
                        elements: {
                          line: {
                            tension: 0, // disables bezier curves
                          },
                        },
                        scales: {
                          yAxes: [
                            {
                              stacked: true,
                            },
                          ],
                        },
                      }}
                      data={{
                        datasets: [
                          {
                            // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
                            data: Object.values(stats.weeklyStats),
                            fill: false,
                            borderColor: '#3cba9f',
                            label: 'Weekly registrations',
                          },
                        ],
                        // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
                        labels: Object.keys(stats.weeklyStats),
                      }}
                    />
                  )}
                </Grid>
              </>
            )}
          </div>
        </Grid>
      </div>
    );
  }
}

export default withStyles(styles)(AdministrationAcquisition);
