import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Switch,
  Typography,
} from '@material-ui/core';
import { Clear as ClearIcon, Edit as EditIcon, ExpandLess, ExpandMore } from '@material-ui/icons';
import { Button, Checkbox, InputText, VSpacer } from '@sm-highway-web/react-components';
import { CustomFieldValueTypes } from 'highway-api/dist/common/interfaces/projects';
import React, { useEffect, useState } from 'react';
import BooleanIcon from '../../assets/img/custom_field-boolean.svg';
import CategoricalIcon from '../../assets/img/custom_field-categorical.svg';
import NumericalIcon from '../../assets/img/custom_field-numerical.svg';
import TextIcon from '../../assets/img/custom_field-text.svg';
import { intl } from '../../helpers/IntGlobalProvider';
import { dateFormat } from '../../helpers/TimeDistance';
import FormattedMessage from '../FormattedMessageCustom';
import {
  CategoricalContent,
  CategoricalMultiple,
  CategoricalNewOption,
  CategoricalOptions,
  IdChip,
  Placeholder,
  ProjectCustomField as ProjectCustomFieldContainer,
  ProjectCustomFieldBody,
  ProjectCustomFieldFooter,
  ProjectCustomFieldHeader,
  ProjectCustomFieldHeaderTitle,
  RemoveField,
} from './ProjectCustomField.styled';

type ProjectCustomFieldIconProps = {
  type?: CustomFieldValueTypes;
  iconSrc?: string;
};

export const ProjectCustomFieldIcon = ({ type, iconSrc }: ProjectCustomFieldIconProps) => {
  if (iconSrc) return <img alt="" src={iconSrc} width="20px" />;
  if (type) {
    let src;
    switch (type) {
      case 'text':
        src = TextIcon;
        break;
      case 'numerical':
        src = NumericalIcon;
        break;
      case 'boolean':
        src = BooleanIcon;
        break;
      case 'categorical':
        src = CategoricalIcon;
        break;
      default:
        return null;
    }

    return <img alt="" src={src} width="20px" />;
  }
  return null;
};

type EditTextFieldProps = {
  originalValue: React.ReactElement | string;
  value: React.ReactElement | string;
  setValue: (...args: any[]) => any;
  onUpdate: (...args: any[]) => any;
  isEditable: boolean;
  customStyles?: any;
  inputCustomStyles?: any;
  labelCustomStyles?: any;
  emptyPlaceholder: React.ReactElement | string;
  customValidator?: (...args: any[]) => any;
};

const EditTextField = ({
  originalValue,
  value,
  setValue,
  isEditable,
  onUpdate,
  customStyles,
  inputCustomStyles,
  labelCustomStyles,
  emptyPlaceholder,
  customValidator,
}: EditTextFieldProps) => {
  const [editing, setEditing] = useState(false);
  const [hoverLabel, setHoverLabel] = useState(false);

  const handleKeyPress = (event: any) => {
    switch (event.key) {
      case 'Enter':
      case 'Escape':
        endEditing();
        break;
      default:
        break;
    }
  };

  const endEditing = () => {
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    if (!customValidator(value)) {
      onUpdate(value);
    } else {
      setValue(originalValue);
    }
    setEditing(false);
    setHoverLabel(false);
  };

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        cursor: isEditable ? 'pointer' : 'default',
        ...(customStyles || {}),
      }}
      onMouseEnter={() => {
        if (isEditable) setHoverLabel(true);
      }}
      onMouseLeave={() => {
        if (isEditable) setHoverLabel(false);
      }}
    >
      {editing ? (
        <InputText
          style={{
            top: '3px',
            marginLeft: '4px',
            ...(inputCustomStyles || {}),
          }}
          type="text"
          value={value}
          onChange={(text: any) => {
            setValue(text);
          }}
          autoFocus
          margin="none"
          onBlur={() => endEditing()}
          onKeyDown={handleKeyPress}
          customValidator={customValidator}
        />
      ) : value ? (
        <span
          onClick={() => {
            if (hoverLabel) setEditing(true);
          }}
          style={{
            marginLeft: '8px',
            cursor: 'pointer',
            ...(labelCustomStyles || {}),
          }}
        >
          {value}
        </span>
      ) : (
        <Placeholder>
          <span
            onClick={() => {
              if (hoverLabel) setEditing(true);
            }}
          >
            {emptyPlaceholder}
          </span>
        </Placeholder>
      )}
      {hoverLabel || editing ? (
        <EditIcon
          style={{ fontSize: '18px', color: '#333940', cursor: 'pointer' }}
          onClick={() => setEditing(true)}
        />
      ) : null}
    </div>
  );
};

EditTextField.defaultProps = {
  customStyles: undefined,
  inputCustomStyles: undefined,
  labelCustomStyles: undefined,
  customValidator: () => {},
};

type ProjectCustomFieldProps = {
  field: {
    id?: string;
    label?: React.ReactElement | string;
    description?: React.ReactElement | string;
    enabled?: boolean;
    type: CustomFieldValueTypes;
    multiple?: boolean;
    options?: string[];
    'created_at'?: string;
  };
  editable?: boolean;
  iconSrc?: string;
  onUpdate?: (...args: any[]) => any;
  onRemove?: (...args: any[]) => any;
  create?: boolean;
  maxWidth?: number;
};

const ProjectCustomField = ({
  field,
  editable,
  iconSrc,
  onUpdate,
  onRemove,
  create,
  maxWidth,
}: ProjectCustomFieldProps) => {
  const [expanded, setExpanded] = useState(create || false);
  const [fieldId, setFieldId] = useState('');
  const [label, setLabel] = useState('');
  const [description, setDescription] = useState('');
  const [enabled, setEnabled] = useState(false);
  const [categoricalOptions, setCategoricalOptions] = useState([]);
  const [multiple, setMultiple] = useState(false);

  const [removeDialogOpen, setRemoveDialogOpen] = useState(false);
  const [confirmDeleteCustomFieldId, setConfirmDeleteCustomFieldId] = useState('');
  const [fieldToRemove, setFieldToRemove] = useState();

  useEffect(() => {
    if (field) {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | ReactElement<any, strin... Remove this comment to see the full error message
      setLabel(field.label || '');
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | ReactElement<any, strin... Remove this comment to see the full error message
      setDescription(field.description || '');
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'boolean | undefined' is not assi... Remove this comment to see the full error message
      setEnabled(field.enabled);
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string[]' is not assignable to p... Remove this comment to see the full error message
      setCategoricalOptions(field.options || []);
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'boolean | undefined' is not assi... Remove this comment to see the full error message
      setMultiple(field.multiple);
    }
  }, [field]);

  return (
    <>
      {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
      <ProjectCustomFieldContainer enabled={enabled} maxWidth={maxWidth}>
        <ProjectCustomFieldHeader
          style={{ cursor: !create ? 'pointer' : 'default' }}
          onClick={() => {
            if (!create) setExpanded(!expanded);
          }}
        >
          <ProjectCustomFieldHeaderTitle>
            <ProjectCustomFieldIcon type={field ? field.type : undefined} iconSrc={iconSrc} />
            <EditTextField
              // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | ReactElement<any, string | ((props:... Remove this comment to see the full error message
              originalValue={editable ? field.label : ''}
              value={label}
              setValue={(value: any) => {
                setLabel(value);

                // In custom field creation. If ID is not set, create it with the same value of label.
                const newId = value.toLowerCase().trim().replace(/ /g, '_');
                
                // Only alphanumeric characters
                setFieldId(newId.replace(/\W/g, ''));
              }}
              // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
              onUpdate={(value: any) => onUpdate({ label: value, id: fieldId })}
              // @ts-expect-error ts-migrate(2322) FIXME: Type 'boolean | undefined' is not assignable to ty... Remove this comment to see the full error message
              isEditable={expanded && editable}
              customValidator={(text: any) => {
                const valueText = text.trim();
                if (valueText.length < 1) {
                  return (
                    <FormattedMessage id="settings.organization.projects.edit.fields.custom_fields.label_required" />
                  );
                }
                return undefined;
              }}
              emptyPlaceholder={
                <FormattedMessage id="settings.organization.projects.edit.fields.custom_fields.label_ph" />
              }
            />
          </ProjectCustomFieldHeaderTitle>

          {!create ? (
            expanded ? (
              <ExpandLess style={{ cursor: 'pointer' }} />
            ) : (
              <ExpandMore style={{ cursor: 'pointer' }} />
            )
          ) : null}
        </ProjectCustomFieldHeader>

        {expanded ? (
          <>
            <ProjectCustomFieldBody>
              {!create ? (
                field.id ? (
                  <IdChip>{field.id}</IdChip>
                ) : null
              ) : (
                <EditTextField
                  // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | undefined' is not assignable to typ... Remove this comment to see the full error message
                  originalValue={field.id}
                  value={fieldId}
                  setValue={(value: any) => setFieldId(value.toLowerCase())}
                  // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
                  onUpdate={(value: any) => onUpdate({ id: value.trim() })}
                  isEditable
                  customValidator={(text: any) => {
                    const valueText = text.trim();
                    if (valueText.length < 1) {
                      return (
                        <FormattedMessage id="settings.organization.projects.edit.fields.custom_fields.id_required" />
                      );
                    }
                    if (!/^[a-z0-9_-]*$/.test(valueText)) {
                      return (
                        <FormattedMessage id="settings.organization.projects.edit.fields.custom_fields.id_pattern_failed" />
                      );
                    }

                    return undefined;
                  }}
                  emptyPlaceholder={
                    <FormattedMessage id="settings.organization.projects.edit.fields.custom_fields.id_ph" />
                  }
                />
              )}

              {create || field.description ? (
                <EditTextField
                  // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | ReactElement<any, string | ((props:... Remove this comment to see the full error message
                  originalValue={field.description}
                  value={description}
                  setValue={setDescription}
                  // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
                  onUpdate={(value: any) => onUpdate({ description: value.trim() })}
                  // @ts-expect-error ts-migrate(2322) FIXME: Type 'boolean | undefined' is not assignable to ty... Remove this comment to see the full error message
                  isEditable={expanded && editable}
                  customStyles={{
                    margin: 0,
                    width: '100%',
                    fontSize: '15px',
                    marginTop: '20px',
                  }}
                  inputCustomStyles={{
                    width: '100%',
                    marginLeft: 0,
                  }}
                  labelCustomStyles={{
                    width: '100%',
                    marginLeft: 0,
                    wordBreak: 'break-word',
                  }}
                  emptyPlaceholder={
                    <FormattedMessage id="settings.organization.projects.edit.fields.custom_fields.description_ph" />
                  }
                />
              ) : null}

              {field.type === 'categorical' ? (
                <CategoricalContent>
                  <CategoricalOptions>
                    {categoricalOptions.map((option, index) => (
                      <div className="option" key={`option-key-${index}`}>
                        {create ? (
                          <>
                            <EditTextField
                              // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
                              originalValue={field.options[index] || ''}
                              value={option}
                              setValue={(text: any) => {
                                const categoricalOptionsCopy = [...categoricalOptions];
                                // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'any' is not assignable to parame... Remove this comment to see the full error message
                                categoricalOptionsCopy.splice(index, 1, text);
                                setCategoricalOptions(categoricalOptionsCopy);
                              }}
                              // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
                              onUpdate={() => onUpdate({ options: categoricalOptions })}
                              // @ts-expect-error ts-migrate(2322) FIXME: Type 'boolean | undefined' is not assignable to ty... Remove this comment to see the full error message
                              isEditable={expanded && editable}
                              customValidator={(text: any) => {
                                if (text.length < 1) {
                                  return (
                                    <FormattedMessage id="settings.organization.projects.edit.fields.custom_fields.categorical_option_label_required" />
                                  );
                                }
                                return undefined;
                              }}
                              customStyles={{
                                width: '100%',
                                fontSize: '15px',
                              }}
                              inputCustomStyles={{
                                width: '100%',
                                marginLeft: 0,
                                fonSize: '15px',
                              }}
                              labelCustomStyles={{
                                width: '100%',
                                marginLeft: 0,
                              }}
                              emptyPlaceholder={
                                <FormattedMessage id="settings.organization.projects.edit.fields.custom_fields.categorical_option_label_ph" />
                              }
                            />
                            <ClearIcon
                              id="clear-icon"
                              style={{ color: '#57637c' }}
                              onClick={(e) => {
                                const categoricalOptionsCopy = [...categoricalOptions];
                                categoricalOptionsCopy.splice(index, 1);
                                setCategoricalOptions(categoricalOptionsCopy);
                                // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
                                onUpdate({ options: categoricalOptionsCopy });
                              }}
                            />
                          </>
                        ) : (
                          <span>{option}</span>
                        )}
                      </div>
                    ))}
                  </CategoricalOptions>
                  {create ? (
                    <CategoricalNewOption
                      /* @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string[]' is not assignable to p... Remove this comment to see the full error message */
                      onClick={() => setCategoricalOptions([...categoricalOptions, ``])}
                    >
                      <FormattedMessage id="settings.organization.projects.edit.fields.custom_fields.categorical_multiple.new_option" />
                    </CategoricalNewOption>
                  ) : null}
                  <CategoricalMultiple>
                    <Checkbox
                      disabled={!create}
                      checked={multiple}
                      onChange={(e: any, value: any) => {
                        setMultiple(value);
                        // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
                        onUpdate({ multiple: value });
                      }}
                    />
                    <FormattedMessage id="settings.organization.projects.edit.fields.custom_fields.categorical_multiple" />
                  </CategoricalMultiple>
                </CategoricalContent>
              ) : null}
            </ProjectCustomFieldBody>

            <ProjectCustomFieldFooter>
              <div>
                {field.created_at ? (
                  <>
                    <span style={{ fontSize: '9px' }}>
                      <FormattedMessage
                        id="settings.organization.projects.edit.fields.custom_fields.created_at"
                        values={{ time: dateFormat(field.created_at) }}
                      />
                    </span>

                    {editable ? (
                      <RemoveField
                        style={{
                          marginLeft: 0,
                          paddingLeft: 0,
                        }}
                        type="button"
                        onClick={() => {
                          setRemoveDialogOpen(true);
                          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ id?: string | undefined; label... Remove this comment to see the full error message
                          setFieldToRemove(field);
                        }}
                      >
                        <FormattedMessage id="settings.organization.projects.edit.fields.custom_fields.remove" />
                      </RemoveField>
                    ) : null}
                  </>
                ) : null}
              </div>

              <Switch
                checked={enabled}
                onChange={(event) => {
                  const { checked } = event.target;
                  setEnabled(checked);
                  // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
                  onUpdate({ enabled: checked });
                }}
                color="primary"
              />
            </ProjectCustomFieldFooter>
          </>
        ) : null}
      </ProjectCustomFieldContainer>

      {fieldToRemove ? (
        <Dialog
          disableEscapeKeyDown
          open={!!removeDialogOpen}
          onClose={() => {
            setRemoveDialogOpen(false);
            // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
            setFieldToRemove();
          }}
        >
          <DialogTitle>
            <FormattedMessage id="settings.organization.projects.edit.fields.custom_fields.delete_dialog.title" />
          </DialogTitle>

          <DialogContent>
            <Grid container direction="column">
              <Typography>
                <FormattedMessage
                  id="settings.organization.projects.edit.fields.custom_fields.delete_dialog.info"
                  // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
                  values={{ id: fieldToRemove.id }}
                />
              </Typography>

              <VSpacer small />

              <VSpacer large />

              <Typography style={{ fontWeight: 'bold' }}>
                <FormattedMessage id="settings.organization.projects.edit.fields.custom_fields.delete_dialog.label" />
              </Typography>

              <VSpacer small />

              <InputText
                id="organization_name"
                placeholder={intl.formatMessage({
                  id:
                    'settings.organization.projects.edit.fields.custom_fields.delete_dialog.label_ph',
                })}
                value={confirmDeleteCustomFieldId}
                onChange={(value: any) => setConfirmDeleteCustomFieldId(value)}
                margin="none"
                validators={[]}
              />
            </Grid>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setRemoveDialogOpen(false);
                // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
                setFieldToRemove();
              }}
              color="secondary"
            >
              <FormattedMessage id="settings.buttoncancel" />
            </Button>
            <Button
              // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
              disabled={confirmDeleteCustomFieldId !== fieldToRemove.id}
              style={{
                backgroundColor:
                  // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
                  confirmDeleteCustomFieldId !== fieldToRemove.id
                    ? 'rgba(0, 0, 0, 0.12)'
                    : '#FF2825',
              }}
              color="secondary"
              variant="contained"
              onClick={() => {
                setRemoveDialogOpen(false);
                // @ts-expect-error ts-migrate(2554) FIXME: Expected 1 arguments, but got 0.
                setFieldToRemove();
                // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
                onRemove();
              }}
            >
              <FormattedMessage id="settings.organization.projects.edit.fields.custom_fields.delete_dialog.title" />
            </Button>
          </DialogActions>
        </Dialog>
      ) : null}
    </>
  );
};

ProjectCustomField.defaultProps = {
  field: {
    id: undefined,
    label: undefined,
    description: undefined,
    enabled: true,
    multiple: false,
    options: [],
    created_at: undefined,
  },
  editable: false,
  iconSrc: undefined,
  onUpdate: () => {},
  onRemove: () => {},
  create: false,
};

export default ProjectCustomField;
