import { Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { VSpacer } from '@sm-highway-web/react-components';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import { IUserData } from '../../../interfaces/users';
import { Common, InfoError, User } from '../../../redux/actions';
import FormattedMessage from '../../FormattedMessageCustom';

const styles = {
  code: {
    maxWidth: 'calc(100vw - 100px)',
  },
};

type OwnIntegrationsKeysProps = {
  classes: {
    code: string;
  };
  actions: {
    userEdit: (...args: any[]) => any;
    sendStatusToSnackbar: (...args: any[]) => any;
    openPaymentModal: (...args: any[]) => any;
  };
  user: IUserData;
};

type IntegrationsKeysProps = OwnIntegrationsKeysProps;

class IntegrationsKeys extends Component<IntegrationsKeysProps> {
  static defaultProps = {};

  state = {};

  onKeyClick = (evt: any) => {
    evt.target.select();
  };

  render() {
    const { user, classes } = this.props;
    return (
      <>
        <Grid container direction="column" style={{ width: '100%' }}>
          <Grid item>
            <Typography variant="h2">
              <FormattedMessage id="settings.integrations.keys.title" />
            </Typography>
          </Grid>
          <VSpacer />
          <Typography>
            <FormattedMessage id="settings.integrations.keys.description" />
          </Typography>
          <VSpacer />
          <Grid item>
            <Typography variant="h6">
              <FormattedMessage id="settings.integrations.keys.publicKey" />
            </Typography>
            <VSpacer small />
            {user && (
              <div className={classes.code}>
                <SyntaxHighlighter language="bash" style={docco}>
                  {user.public_key}
                </SyntaxHighlighter>
              </div>
            )}
            <VSpacer />
            <Typography variant="h6">
              <FormattedMessage id="settings.integrations.keys.privateKey" />
            </Typography>
            <VSpacer small />
            {user && (
              <div className={classes.code}>
                <SyntaxHighlighter language="bash" style={docco}>
                  {user.private_key}
                </SyntaxHighlighter>
              </div>
            )}
            <VSpacer />
            <Typography variant="h6">
              <FormattedMessage id="settings.integrations.keys.example" />
            </Typography>
            <VSpacer small />
            <Typography>
              <FormattedMessage
                id="settings.integrations.keys.example_description"
                values={{
                  a: (chunks: any) => (
                    <a
                      href="https://support.smartmonkey.io/docs/en/developers"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {chunks}
                    </a>
                  ),
                }}
              />
            </Typography>
          </Grid>
        </Grid>
      </>
    );
  }
}

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    userEdit: (firstName: any, lastName: any, company: any, phone: any, language: any) =>
      // @ts-expect-error ts-migrate(2554) FIXME: Expected 8 arguments, but got 5.
      dispatch(User.editUser(firstName, lastName, company, phone, language)),
    sendStatusToSnackbar: (message: any, type: any) =>
      dispatch(InfoError.sendStatusToSnackbar(message, type)),
    openPaymentModal: () => dispatch(Common.openPaymentModal()),
  },
});

const mapStateToProps = (state: any) => {
  return { user: state.user.user };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(IntegrationsKeys));
