import { Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { IPlanData } from 'highway-api/dist/common/interfaces/plans';
import { IProjectData } from 'highway-api/dist/common/interfaces/projects';
import React from 'react';
import { connect } from 'react-redux';
import { SERVICE_STATUS } from '../../constants/Services';
import { parseSecondsToHMText } from '../../helpers/TimeDistance';
import { kmToSM } from '../../helpers/Units';
import FormattedMessage from '../FormattedMessageCustom';

const styles = {
  allFull: {
    height: '100%',
    width: '100%',
    padding: '4px 8px',
    backgroundColor: 'white',
  },
  rounded: {
    height: '20px',
    width: '70px',
    borderRadius: '4px',
  },
  white: {
    paddingTop: '4px',
    color: 'white',
    fontSize: '16px',
    fontWeight: 500,
  },
  inspectorItemContainer: {
    width: '100px',
  },
};

type PlanInspectorLiveItemProps = {
  classes: {
    white: string;
    inspectorItemContainer: string;
  },
  labelId: string;
  value: number;
  total: number;
  color?: string;
};

const PlanInspectorLiveItem = withStyles(styles)(({ classes, labelId, value, total, color } : PlanInspectorLiveItemProps) => {
  const percent = (value && total && Math.round((value / total) * 100)) || 0;
  return (
    <Grid container direction="column" className={classes.inspectorItemContainer}>
      <Typography className={classes.white} align="center" style={{ color }}>
        {`${value} | ${percent}%`}
      </Typography>
      <Typography align="center" style={{ color: '#8B8B8B', fontSize: '8px' }}>
        <FormattedMessage id={labelId} />
      </Typography>
    </Grid>
  );
});

type PlanInspectorLiveProps = {
  plan: IPlanData;
};

export const PlanInspectorLive = withStyles(styles)(({ plan }: PlanInspectorLiveProps) => {
  let completedServices = 0;
  let canceledServices = 0;
  let pendingServices = 0;
  const total = (plan && plan.services.length) || 0;

  if (plan) {
    plan.services.forEach((service: any) => {
      switch (service.status) {
        case SERVICE_STATUS.completed:
          completedServices += 1;
          break;
        case SERVICE_STATUS.canceled:
          canceledServices += 1;
          break;
        default:
          pendingServices += 1;
          break;
      }
    });
  }

  return (
    <Grid container alignItems="center" justify="flex-start" style={{ width: 'auto' }}>
      <PlanInspectorLiveItem
        labelId="plan.inspector.completed"
        value={completedServices}
        total={total}
        color="#CDDC39"
      />
      <PlanInspectorLiveItem
        labelId="plan.inspector.canceled"
        value={canceledServices}
        total={total}
        color="#F44336"
      />
      <PlanInspectorLiveItem
        labelId="plan.inspector.pending"
        value={pendingServices}
        total={total}
      />
    </Grid>
  );
});

PlanInspectorLive.defaultProps = {
  plan: undefined,
};

type PlanInspectorItemProps = {
  classes: {
    white: string;
    inspectorItemContainer: string;
  },
  labelId: string;
  value: string | number;
};

const PlanInspectorItem = withStyles(styles)(({ classes, labelId, value } : PlanInspectorItemProps) => {
  return (
    <Grid container direction="column" className={classes.inspectorItemContainer}>
      <Typography className={classes.white} align="center">
        {value}
      </Typography>
      <Typography align="center" style={{ color: '#8B8B8B', fontSize: '8px' }}>
        <FormattedMessage id={labelId} />
      </Typography>
    </Grid>
  );
});

type PlanInspectorProps = {
  classes: {
    allFull: string;
    rounded: string;
    white: string;
  };
  plan?: IPlanData;
  activeProject?: IProjectData;
};

const PlanInspector = ({ plan, activeProject }: PlanInspectorProps) => {
  /**
   * Calculates the total amount of the plan routes.
   * Value returned has XXh YYm time format
   * @return {string} - Amount of time.
   */
  const getDurationTime = () : string => {
    if (plan && plan.routes.length > 0) {
      return parseSecondsToHMText(plan.routes
        .map((route: any) => {
          if (route.planned_end_time !== undefined && route.planned_start_time !== undefined) {
            const difference = route.planned_end_time - route.planned_start_time;

            // To avoid results with seconds that show in inspector a different value than in routes list.
            return difference - (difference % 60);
          }
          return 0;
        })
        .reduce((previous: any, next: any) => previous + next, 0));
    }
    return '0 m';
  };

  const distance =
    plan &&
    plan.routes.length > 0 &&
    plan.routes.reduce(
      (pr: any, nr: any) =>
        nr.services.length > 0
          ? pr +
            nr.services.reduce(
              (ps: any, ns: any) =>
                ns.distance_to_next_location !== undefined ? ps + ns.distance_to_next_location : ps,
              0
            )
          : pr,
      0
    );

  return (
    <Grid container alignItems="center" justify="center" style={{ width: 'auto' }}>
      <PlanInspectorItem
        labelId="plan.inspector.services"
        value={(plan && plan.services.length) || '0'}
      />
      <PlanInspectorItem
        labelId="plan.inspector.unscheduled"
        value={
          (plan && plan.services.reduce((p: any, n: any) => (n.route_id ? p : p + 1), 0)) || '0'
        }
      />
      <PlanInspectorItem labelId="plan.inspector.duration" value={getDurationTime()} />
      <PlanInspectorItem
        labelId="plan.inspector.distance"
        value={
          (plan &&
            activeProject &&
            `${parseInt(
              kmToSM(
                distance / 1000,
                activeProject?.units?.distance
              ),
              10
            )} ${
              activeProject?.units?.distance
            }`) ||
          '-'
        }
      />
    </Grid>
  );
};

PlanInspector.defaultProps = {
  plan: undefined,
};

const mapStateToProps = (state: any) => {
  return {
    plans: state.plans.plans,
    activeProject: state.projects.activeProject,
  };
};

export default connect(mapStateToProps, null)(withStyles(styles)(PlanInspector));
