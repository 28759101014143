import { MenuItem, TextField, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React, { useEffect, useState } from 'react';

const styles = {
  root: {
    margin: 0,
    '& .MuiOutlinedInput-input': {
      padding: '8px',
      minHeight: '24px',
    },
  },
  menu: {
    '& .MuiMenu-list': {
      padding: '0',
    },
  },
  menuItem: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    whiteSpace: 'unset',
    paddingTop: '8px',
    paddingBottom: '8px',
    position: 'relative',
    '&::after': {
      width: 'calc(100% - 32px)',
      height: '1px',
      backgroundColor: 'lightgray',
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: 0,
      margin: '0 16px',
    },

    '&:last-of-type': {
      '&:after': {
        display: 'none',
      },
    },
  },
  menuItemTitle: {
    fontSize: '16px',
  },
  menuItemInfo: {
    color: 'grey',
    fontSize: '13px',
  },
};

type OwnProps = {
  classes: {
    root: string;
    menu: string;
    menuItem: string;
    menuItemTitle: string;
    menuItemInfo: string;
  };
  width?: number;
  value: string;
  onChange: (...args: any[]) => any;
  selectItems?: string[];
  disabled?: boolean;
};

type Props = OwnProps;

const CustomSelect = ({ classes, width, disabled, value, onChange, selectItems }: Props) => {
  const [selectValue, setSelectValue] = useState();

  useEffect(() => {
    if (selectItems && value && value !== selectValue) {
      const valueFound = selectItems.find((item: any) => item.value === value);
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
      setSelectValue(valueFound);
    }
  }, [selectValue, value, selectItems, setSelectValue]);

  return (
    <TextField
      id="custom-select"
      select
      className={classes.root}
      style={{ width: `${width}px` }}
      value={selectValue || ''}
      onChange={(event) => {
        const selected = event.target.value;
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'value' does not exist on type 'string'.
        onChange(selected.value);
      }}
      SelectProps={{
        MenuProps: {
          className: classes.menu,
          style: { width: `${width}px` },
        },
        renderValue: (option) => (
          <>
            {/* @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'. */}
            {option.title && (
              <Typography className={classes.menuItemTitle} variant="h5">
                {/* @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'. */}
                {option.title}
              </Typography>
            )}
          </>
        ),
      }}
      disabled={disabled}
      variant="outlined"
      margin="normal"
    >
      {/* @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'. */}
      {selectItems.map((option: any, idx: any) => (
        <MenuItem className={classes.menuItem} key={`roleselect-${idx}`} value={option}>
          {option.title && (
            <Typography className={classes.menuItemTitle} variant="h5">
              {option.title}
            </Typography>
          )}
          {option.info && <Typography className={classes.menuItemInfo}>{option.info}</Typography>}
        </MenuItem>
      ))}
    </TextField>
  );
};

CustomSelect.defaultProps = {
  width: 300,
  selectItems: [],
  disabled: false,
};

// @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ root: { margin: number; '& .Mu... Remove this comment to see the full error message
export default withStyles(styles)(CustomSelect);
