export const vehicles_AR = [
  {
    end_location: {
      lat: -34.63202,
      lng: -58.40049,
      country: 'Argentina',
      country_code: 'ARG',
      state: 'Ciudad Autónoma de Buenos Aires',
      county: 'Ciudad de Buenos Aires',
      house_number: '1837',
      postal_code: '1247',
      label: 'Avenida Jujuy 1837, 1247 Ciudad de Buenos Aires, Argentina',
      city: 'Ciudad de Buenos Aires',
      district: 'Parque Patricios',
      street: 'Avenida Jujuy',
    },
    label: 'Manuel',
    max_services: 17,
    start_location: {
      lat: -34.63202,
      lng: -58.40049,
      country: 'Argentina',
      country_code: 'ARG',
      state: 'Ciudad Autónoma de Buenos Aires',
      county: 'Ciudad de Buenos Aires',
      house_number: '1837',
      postal_code: '1247',
      label: 'Avenida Jujuy 1837, 1247 Ciudad de Buenos Aires, Argentina',
      city: 'Ciudad de Buenos Aires',
      district: 'Parque Patricios',
      street: 'Avenida Jujuy',
    },
    timewindow: [32400, 57600],
  },
  {
    end_location: {
      lat: -34.61002,
      lng: -58.38972,
      country: 'Argentina',
      country_code: 'ARG',
      state: 'Ciudad Autónoma de Buenos Aires',
      county: 'Ciudad de Buenos Aires',
      house_number: '1647',
      postal_code: '1089',
      label: 'Hipólito Yrigoyen 1647, 1089 Ciudad de Buenos Aires, Argentina',
      city: 'Ciudad de Buenos Aires',
      district: 'Montserrat',
      street: 'Hipólito Yrigoyen',
    },
    label: 'Jose',
    max_services: 17,
    start_location: {
      lat: -34.61002,
      lng: -58.38972,
      country: 'Argentina',
      country_code: 'ARG',
      state: 'Ciudad Autónoma de Buenos Aires',
      county: 'Ciudad de Buenos Aires',
      house_number: '1647',
      postal_code: '1089',
      label: 'Hipólito Yrigoyen 1647, 1089 Ciudad de Buenos Aires, Argentina',
      city: 'Ciudad de Buenos Aires',
      district: 'Montserrat',
      street: 'Hipólito Yrigoyen',
    },
    timewindow: [39600, 64800],
  },
  {
    end_location: {
      lat: -34.59813,
      lng: -58.39593,
      country: 'Argentina',
      country_code: 'ARG',
      state: 'Ciudad Autónoma de Buenos Aires',
      county: 'Ciudad de Buenos Aires',
      house_number: '951',
      postal_code: '1111',
      label: 'Ayacucho 951, 1111 Ciudad de Buenos Aires, Argentina',
      city: 'Ciudad de Buenos Aires',
      district: 'Recoleta',
      street: 'Ayacucho',
    },
    label: 'Pedro',
    max_services: 17,
    start_location: {
      lat: -34.59813,
      lng: -58.39593,
      country: 'Argentina',
      country_code: 'ARG',
      state: 'Ciudad Autónoma de Buenos Aires',
      county: 'Ciudad de Buenos Aires',
      house_number: '951',
      postal_code: '1111',
      label: 'Ayacucho 951, 1111 Ciudad de Buenos Aires, Argentina',
      city: 'Ciudad de Buenos Aires',
      district: 'Recoleta',
      street: 'Ayacucho',
    },
    timewindow: [32400, 57600],
  },
  {
    end_location: {
      lat: -34.58796,
      lng: -58.40014,
      country: 'Argentina',
      country_code: 'ARG',
      state: 'Ciudad Autónoma de Buenos Aires',
      county: 'Ciudad de Buenos Aires',
      house_number: '1969',
      postal_code: '1425',
      label: 'Laprida 1969, 1425 Ciudad de Buenos Aires, Argentina',
      city: 'Ciudad de Buenos Aires',
      district: 'Recoleta',
      street: 'Laprida',
    },
    label: 'Pablo',
    max_services: 17,
    start_location: {
      lat: -34.58796,
      lng: -58.40014,
      country: 'Argentina',
      country_code: 'ARG',
      state: 'Ciudad Autónoma de Buenos Aires',
      county: 'Ciudad de Buenos Aires',
      house_number: '1969',
      postal_code: '1425',
      label: 'Laprida 1969, 1425 Ciudad de Buenos Aires, Argentina',
      city: 'Ciudad de Buenos Aires',
      district: 'Recoleta',
      street: 'Laprida',
    },
    timewindow: [39600, 64800],
  },
];

export const services_AR = [
  {
    label: 'RESTAURANTE MAIDO',
    location: {
      lat: -34.63202,
      lng: -58.40049,
      country: 'Argentina',
      country_code: 'ARG',
      state: 'Ciudad Autónoma de Buenos Aires',
      county: 'Ciudad de Buenos Aires',
      house_number: '1837',
      postal_code: '1247',
      label: 'Avenida Jujuy 1837, 1247 Ciudad de Buenos Aires, Argentina',
      city: 'Ciudad de Buenos Aires',
      district: 'Parque Patricios',
      street: 'Avenida Jujuy',
    },
    duration: 300,
    timewindows: [[28800, 36000]],
  },
  {
    label: 'RESTAURANTE CENTRAL',
    location: {
      lat: -34.61002,
      lng: -58.38972,
      country: 'Argentina',
      country_code: 'ARG',
      state: 'Ciudad Autónoma de Buenos Aires',
      county: 'Ciudad de Buenos Aires',
      house_number: '1647',
      postal_code: '1089',
      label: 'Hipólito Yrigoyen 1647, 1089 Ciudad de Buenos Aires, Argentina',
      city: 'Ciudad de Buenos Aires',
      district: 'Montserrat',
      street: 'Hipólito Yrigoyen',
    },
    duration: 300,
    timewindows: [[28800, 46800]],
  },
  {
    label: 'RESTAURANTE PUJOL',
    location: {
      lat: -34.59813,
      lng: -58.39593,
      country: 'Argentina',
      country_code: 'ARG',
      state: 'Ciudad Autónoma de Buenos Aires',
      county: 'Ciudad de Buenos Aires',
      house_number: '951',
      postal_code: '1111',
      label: 'Ayacucho 951, 1111 Ciudad de Buenos Aires, Argentina',
      city: 'Ciudad de Buenos Aires',
      district: 'Recoleta',
      street: 'Ayacucho',
    },
    duration: 300,
    timewindows: [[28800, 46800]],
  },
  {
    label: 'RESTAURANTE DON JULIO',
    location: {
      lat: -34.58796,
      lng: -58.40014,
      country: 'Argentina',
      country_code: 'ARG',
      state: 'Ciudad Autónoma de Buenos Aires',
      county: 'Ciudad de Buenos Aires',
      house_number: '1969',
      postal_code: '1425',
      label: 'Laprida 1969, 1425 Ciudad de Buenos Aires, Argentina',
      city: 'Ciudad de Buenos Aires',
      district: 'Recoleta',
      street: 'Laprida',
    },
    duration: 300,
    timewindows: [[28800, 46800]],
  },
  {
    label: 'RESTAURANTE BORAGO',
    location: {
      lat: -34.58553,
      lng: -58.39815,
      country: 'Argentina',
      country_code: 'ARG',
      state: 'Ciudad Autónoma de Buenos Aires',
      county: 'Ciudad de Buenos Aires',
      house_number: '2436',
      postal_code: '1425',
      label: 'Galileo 2436, 1425 Ciudad de Buenos Aires, Argentina',
      city: 'Ciudad de Buenos Aires',
      district: 'Recoleta',
      street: 'Galileo',
    },
    duration: 300,
    timewindows: [[28800, 45000]],
  },
  {
    label: 'RESTAURANTE A CASA DO PORCO',
    location: {
      lat: -34.58103,
      lng: -58.40845,
      country: 'Argentina',
      country_code: 'ARG',
      state: 'Ciudad Autónoma de Buenos Aires',
      county: 'Ciudad de Buenos Aires',
      house_number: '2841',
      postal_code: '1425',
      label: 'Paunero 2841, 1425 Ciudad de Buenos Aires, Argentina',
      city: 'Ciudad de Buenos Aires',
      district: 'Palermo',
      street: 'Paunero',
    },
    duration: 600,
    timewindows: [[28800, 45000]],
  },
  {
    label: 'RESTAURANTE EL CHATO',
    location: {
      lat: -34.59401,
      lng: -58.3829,
      country: 'Argentina',
      country_code: 'ARG',
      state: 'Ciudad Autónoma de Buenos Aires',
      county: 'Ciudad de Buenos Aires',
      house_number: '1222',
      postal_code: '1010',
      label: 'Cerrito 1222, 1010 Ciudad de Buenos Aires, Argentina',
      city: 'Ciudad de Buenos Aires',
      district: 'Retiro',
      street: 'Cerrito',
    },
    duration: 300,
    timewindows: [[28800, 45000]],
  },
  {
    label: 'RESTAURANTE LEO',
    location: {
      lat: -34.61207,
      lng: -58.36108,
      country: 'Argentina',
      country_code: 'ARG',
      state: 'Ciudad Autónoma de Buenos Aires',
      county: 'Ciudad de Buenos Aires',
      house_number: '489',
      postal_code: '1107',
      label: 'Azucena Villaflor 489, 1107 Ciudad de Buenos Aires, Argentina',
      city: 'Ciudad de Buenos Aires',
      district: 'Puerto Madero',
      street: 'Azucena Villaflor',
    },
    duration: 300,
    timewindows: [[28800, 45000]],
  },
  {
    label: 'RESTAURANTE OSSO',
    location: {
      lat: -34.69304,
      lng: -58.31865,
      country: 'Argentina',
      country_code: 'ARG',
      state: 'Buenos Aires',
      county: 'Avellaneda',
      house_number: '5469',
      postal_code: '1874',
      label: 'Bolívar 5469, 1874 Villa Domínico, Argentina',
      city: 'Villa Domínico',
      street: 'Bolívar',
    },
    duration: 300,
    timewindows: [[28800, 46800]],
  },
  {
    label: 'RESTAURANTE D.O.M.',
    location: {
      lat: -34.75422,
      lng: -58.27372,
      country: 'Argentina',
      country_code: 'ARG',
      state: 'Buenos Aires',
      county: 'Quilmes',
      house_number: '3624',
      postal_code: '1879',
      label: 'Lisandro de la Torre 3624, 1879 Quilmes Oeste, Argentina',
      city: 'Quilmes Oeste',
      street: 'Lisandro de la Torre',
    },
    duration: 600,
    timewindows: [[28800, 46800]],
  },
  {
    label: 'RESTAURANTE QUINTONIL',
    location: {
      lat: -34.66174,
      lng: -58.36391,
      country: 'Argentina',
      country_code: 'ARG',
      state: 'Buenos Aires',
      county: 'Avellaneda',
      house_number: '748',
      postal_code: '1870',
      label: 'General Marconi 748, 1870 Avellaneda, Argentina',
      city: 'Avellaneda',
      street: 'General Marconi',
    },
    duration: 300,
    timewindows: [[28800, 46800]],
  },
  {
    label: 'RESTAURANTE ISOLINA',
    location: {
      lat: -34.66778,
      lng: -58.40519,
      country: 'Argentina',
      country_code: 'ARG',
      state: 'Buenos Aires',
      county: 'Lanús',
      house_number: '260',
      postal_code: '1822',
      label: 'Paso de Burgos 260, 1822 Valentín Alsina, Argentina',
      city: 'Valentín Alsina',
      street: 'Paso de Burgos',
    },
    duration: 420,
    timewindows: [[28800, 46800]],
  },
  {
    label: 'RESTAURANTE ASTRID Y GASTON',
    location: {
      lat: -34.71381,
      lng: -58.39724,
      country: 'Argentina',
      country_code: 'ARG',
      state: 'Buenos Aires',
      county: 'Lanús',
      house_number: '3600',
      postal_code: '1824',
      label: 'Camilo Benzo Conde de Cavour 3600, 1824 Lanús Oeste, Argentina',
      city: 'Lanús Oeste',
      street: 'Camilo Benzo Conde de Cavour',
    },
    duration: 480,
    timewindows: [[28800, 46800]],
  },
  {
    label: 'RESTAURANTE ALCALDE',
    location: {
      lat: -34.74865,
      lng: -58.38126,
      country: 'Argentina',
      country_code: 'ARG',
      state: 'Buenos Aires',
      county: 'Lomas de Zamora',
      house_number: '1150',
      postal_code: '1828',
      label: 'Gallo 1150, 1828 Banfield Este, Argentina',
      city: 'Banfield Este',
      street: 'Gallo',
    },
    duration: 300,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE PANGEA',
    location: {
      lat: -34.76134,
      lng: -58.38225,
      country: 'Argentina',
      country_code: 'ARG',
      state: 'Buenos Aires',
      county: 'Lomas de Zamora',
      house_number: '1456',
      postal_code: '1834',
      label: 'Presidente L. Sáenz Peña 1456, 1834 Temperley, Argentina',
      city: 'Temperley',
      street: 'Presidente L. Sáenz Peña',
    },
    duration: 300,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE SUD 777',
    location: {
      lat: -34.79532,
      lng: -58.3633,
      country: 'Argentina',
      country_code: 'ARG',
      state: 'Buenos Aires',
      county: 'Almirante Brown',
      house_number: '3016',
      postal_code: '1847',
      label: 'Avenida San Martín 3016, 1847 Rafael Calzada, Argentina',
      city: 'Rafael Calzada',
      street: 'Avenida San Martín',
    },
    duration: 300,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE MAITO',
    location: {
      lat: -34.75693,
      lng: -58.39735,
      country: 'Argentina',
      country_code: 'ARG',
      state: 'Buenos Aires',
      county: 'Lomas de Zamora',
      house_number: '343',
      postal_code: '1832',
      label: 'Pellegrini 343, 1832 Lomas de Zamora, Argentina',
      city: 'Lomas de Zamora',
      street: 'Pellegrini',
    },
    duration: 300,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE MANI',
    location: {
      lat: -34.75523,
      lng: -58.4221,
      country: 'Argentina',
      country_code: 'ARG',
      state: 'Buenos Aires',
      county: 'Lomas de Zamora',
      house_number: '198',
      postal_code: '1832',
      label: 'Belelli 198, 1832 Lomas de Zamora, Argentina',
      city: 'Lomas de Zamora',
      street: 'Belelli',
    },
    duration: 600,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE RAFAEL',
    location: {
      lat: -34.6865,
      lng: -58.475,
      country: 'Argentina',
      country_code: 'ARG',
      state: 'Ciudad Autónoma de Buenos Aires',
      county: 'Ciudad de Buenos Aires',
      house_number: '4858',
      postal_code: '1439',
      label: 'Cosquín 4858, 1439 Ciudad de Buenos Aires, Argentina',
      city: 'Ciudad de Buenos Aires',
      district: 'Villa Riachuelo',
      street: 'Cosquín',
    },
    duration: 300,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE MISHIGUENE',
    location: {
      lat: -34.64512,
      lng: -58.51837,
      country: 'Argentina',
      country_code: 'ARG',
      state: 'Ciudad Autónoma de Buenos Aires',
      county: 'Ciudad de Buenos Aires',
      house_number: '610',
      postal_code: '1408',
      label: 'Martiniano Leguizamón 610, 1408 Ciudad de Buenos Aires, Argentina',
      city: 'Ciudad de Buenos Aires',
      district: 'Liniers',
      street: 'Martiniano Leguizamón',
    },
    duration: 300,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE KJOLLE',
    location: {
      lat: -34.64157,
      lng: -58.51308,
      country: 'Argentina',
      country_code: 'ARG',
      state: 'Ciudad Autónoma de Buenos Aires',
      county: 'Ciudad de Buenos Aires',
      house_number: '276',
      postal_code: '1408',
      label: 'Fonrouge 276, 1408 Ciudad de Buenos Aires, Argentina',
      city: 'Ciudad de Buenos Aires',
      district: 'Liniers',
      street: 'Fonrouge',
    },
    duration: 300,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE HARRY SASSON',
    location: {
      lat: -34.64081,
      lng: -58.56153,
      country: 'Argentina',
      country_code: 'ARG',
      state: 'Buenos Aires',
      county: 'La Matanza',
      house_number: '53',
      postal_code: '1704',
      label: 'Pueyrredón 53, 1704 Ramos Mejía, Argentina',
      city: 'Ramos Mejía',
      street: 'Pueyrredón',
    },
    duration: 600,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE OTEQUE',
    location: {
      lat: -34.60147,
      lng: -58.56,
      country: 'Argentina',
      country_code: 'ARG',
      state: 'Buenos Aires',
      county: '3 de Febrero',
      house_number: '2104',
      postal_code: '1678',
      label: 'Rauch 2104, 1678 Caseros, Argentina',
      city: 'Caseros',
      street: 'Rauch',
    },
    duration: 300,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE LASAI',
    location: {
      lat: -34.58177,
      lng: -58.69464,
      country: 'Argentina',
      country_code: 'ARG',
      state: 'Buenos Aires',
      county: 'San Miguel',
      house_number: '1396',
      postal_code: '1661',
      label: 'Gustavo Flaubert 1396, 1661 Bella Vista, Argentina',
      city: 'Bella Vista',
      street: 'Gustavo Flaubert',
    },
    duration: 540,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE TEGUI',
    location: {
      lat: -34.61731,
      lng: -58.75773,
      country: 'Argentina',
      country_code: 'ARG',
      state: 'Buenos Aires',
      county: 'Moreno',
      house_number: '2755',
      postal_code: '1664',
      label: 'Luis Beláustegui 2755, 1664 Trujui, Argentina',
      city: 'Trujui',
      street: 'Luis Beláustegui',
    },
    duration: 600,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE LA MAR',
    location: {
      lat: -34.6337,
      lng: -58.788,
      country: 'Argentina',
      country_code: 'ARG',
      state: 'Buenos Aires',
      county: 'Moreno',
      house_number: '215',
      postal_code: '1744',
      label: 'Francia 215, 1744 Moreno, Argentina',
      city: 'Moreno',
      street: 'Francia',
    },
    duration: 300,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE ROSSETTA',
    location: {
      lat: -34.65616,
      lng: -58.78051,
      country: 'Argentina',
      country_code: 'ARG',
      state: 'Buenos Aires',
      county: 'Moreno',
      house_number: '2001',
      postal_code: '1744',
      label: 'Diagonal Coleta Palacio 2001, 1744 Moreno, Argentina',
      city: 'Moreno',
      street: 'Diagonal Coleta Palacio',
    },
    duration: 300,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE MAXIMO BISTROT',
    location: {
      lat: -34.62643,
      lng: -58.43985,
      country: 'Argentina',
      country_code: 'ARG',
      state: 'Ciudad Autónoma de Buenos Aires',
      county: 'Ciudad de Buenos Aires',
      house_number: '840',
      postal_code: '1424',
      label: 'Avenida Pedro Goyena 840, 1424 Ciudad de Buenos Aires, Argentina',
      city: 'Ciudad de Buenos Aires',
      district: 'Caballito',
      street: 'Avenida Pedro Goyena',
    },
    duration: 300,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE CHILA',
    location: {
      lat: -34.62912,
      lng: -58.45008,
      country: 'Argentina',
      country_code: 'ARG',
      state: 'Ciudad Autónoma de Buenos Aires',
      county: 'Ciudad de Buenos Aires',
      house_number: '1603',
      postal_code: '1406',
      label: 'José Bonifacio 1603, 1406 Ciudad de Buenos Aires, Argentina',
      city: 'Ciudad de Buenos Aires',
      district: 'Caballito',
      street: 'José Bonifacio',
    },
    duration: 300,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE AMBROSIA',
    location: {
      lat: -34.62916,
      lng: -58.45035,
      country: 'Argentina',
      country_code: 'ARG',
      state: 'Ciudad Autónoma de Buenos Aires',
      county: 'Ciudad de Buenos Aires',
      house_number: '1619',
      postal_code: '1406',
      label: 'José Bonifacio 1619, 1406 Ciudad de Buenos Aires, Argentina',
      city: 'Ciudad de Buenos Aires',
      district: 'Caballito',
      street: 'José Bonifacio',
    },
    duration: 300,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE NICOS',
    location: {
      lat: -34.61287,
      lng: -58.44032,
      country: 'Argentina',
      country_code: 'ARG',
      state: 'Ciudad Autónoma de Buenos Aires',
      county: 'Ciudad de Buenos Aires',
      house_number: '561',
      postal_code: '1405',
      label: 'Hidalgo 561, 1405 Ciudad de Buenos Aires, Argentina',
      city: 'Ciudad de Buenos Aires',
      district: 'Caballito',
      street: 'Hidalgo',
    },
    duration: 600,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE LE CHIQUE',
    location: {
      lat: -34.60572,
      lng: -58.44533,
      country: 'Argentina',
      country_code: 'ARG',
      state: 'Ciudad Autónoma de Buenos Aires',
      county: 'Ciudad de Buenos Aires',
      house_number: '1275',
      postal_code: '1414',
      label: 'Valentín Virasoro 1275, 1414 Ciudad de Buenos Aires, Argentina',
      city: 'Ciudad de Buenos Aires',
      district: 'Villa Crespo',
      street: 'Valentín Virasoro',
    },
    duration: 300,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE PARADOR LA HUELLA',
    location: {
      lat: -34.59807,
      lng: -58.44888,
      country: 'Argentina',
      country_code: 'ARG',
      state: 'Ciudad Autónoma de Buenos Aires',
      county: 'Ciudad de Buenos Aires',
      house_number: '3012',
      postal_code: '1414',
      label: 'Avenida Juan B. Justo 3012, 1414 Ciudad de Buenos Aires, Argentina',
      city: 'Ciudad de Buenos Aires',
      district: 'Villa Crespo',
      street: 'Avenida Juan B. Justo',
    },
    duration: 300,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE LE PATIO',
    location: {
      lat: -34.61174,
      lng: -58.49176,
      country: 'Argentina',
      country_code: 'ARG',
      state: 'Ciudad Autónoma de Buenos Aires',
      county: 'Ciudad de Buenos Aires',
      house_number: '2113',
      postal_code: '1417',
      label: 'Martín Pescador 2113, 1417 Ciudad de Buenos Aires, Argentina',
      city: 'Ciudad de Buenos Aires',
      district: 'Villa del Parque',
      street: 'Martín Pescador',
    },
    duration: 300,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE OLIMPE',
    location: {
      lat: -34.5956,
      lng: -58.50203,
      country: 'Argentina',
      country_code: 'ARG',
      state: 'Ciudad Autónoma de Buenos Aires',
      county: 'Ciudad de Buenos Aires',
      house_number: '3880',
      postal_code: '1419',
      label: 'Campana 3880, 1419 Ciudad de Buenos Aires, Argentina',
      city: 'Ciudad de Buenos Aires',
      district: 'Villa Devoto',
      street: 'Campana',
    },
    duration: 600,
    timewindows: [[32400, 46800]],
  },
  {
    label: 'RESTAURANTE MIL',
    location: {
      lat: -34.58704,
      lng: -58.50857,
      country: 'Argentina',
      country_code: 'ARG',
      state: 'Ciudad Autónoma de Buenos Aires',
      county: 'Ciudad de Buenos Aires',
      house_number: '4933',
      postal_code: '1419',
      label: 'Estanislao S. Zeballos 4933, 1419 Ciudad de Buenos Aires, Argentina',
      city: 'Ciudad de Buenos Aires',
      district: 'Villa Pueyrredón',
      street: 'Estanislao S. Zeballos',
    },
    duration: 300,
    timewindows: [[46800, 57600]],
  },
  {
    label: 'RESTAURANTE LA DOCENA',
    location: {
      lat: -34.5843,
      lng: -58.46788,
      country: 'Argentina',
      country_code: 'ARG',
      state: 'Ciudad Autónoma de Buenos Aires',
      county: 'Ciudad de Buenos Aires',
      house_number: '834',
      postal_code: '1427',
      label: 'Plaza Oeste 834, 1427 Ciudad de Buenos Aires, Argentina',
      city: 'Ciudad de Buenos Aires',
      district: 'Villa Ortúzar',
      street: 'Plaza Oeste',
    },
    duration: 720,
    timewindows: [[46800, 57600]],
  },
  {
    label: 'RESTAURANTE MANU',
    location: {
      lat: -34.57747,
      lng: -58.43635,
      country: 'Argentina',
      country_code: 'ARG',
      state: 'Ciudad Autónoma de Buenos Aires',
      county: 'Ciudad de Buenos Aires',
      house_number: '2141',
      postal_code: '1425',
      label: 'Arévalo 2141, 1425 Ciudad de Buenos Aires, Argentina',
      city: 'Ciudad de Buenos Aires',
      district: 'Palermo',
      street: 'Arévalo',
    },
    duration: 300,
    timewindows: [[46800, 57600]],
  },
  {
    label: 'RESTAURANTE MOCOTO',
    location: {
      lat: -34.56499,
      lng: -58.44297,
      country: 'Argentina',
      country_code: 'ARG',
      state: 'Ciudad Autónoma de Buenos Aires',
      county: 'Ciudad de Buenos Aires',
      house_number: '1361',
      postal_code: '1426',
      label: 'Villanueva 1361, 1426 Ciudad de Buenos Aires, Argentina',
      city: 'Ciudad de Buenos Aires',
      district: 'Palermo',
      street: 'Villanueva',
    },
    duration: 300,
    timewindows: [[46800, 57600]],
  },
  {
    label: 'RESTAURANTE OSAKA',
    location: {
      lat: -34.56653,
      lng: -58.45558,
      country: 'Argentina',
      country_code: 'ARG',
      state: 'Ciudad Autónoma de Buenos Aires',
      county: 'Ciudad de Buenos Aires',
      house_number: '1725',
      postal_code: '1426',
      label: 'Amenábar 1725, 1426 Ciudad de Buenos Aires, Argentina',
      city: 'Ciudad de Buenos Aires',
      district: 'Belgrano',
      street: 'Amenábar',
    },
    duration: 300,
    timewindows: [[46800, 57600]],
  },
  {
    label: 'RESTAURANTE ELENA',
    location: {
      lat: -34.55921,
      lng: -58.45766,
      country: 'Argentina',
      country_code: 'ARG',
      state: 'Ciudad Autónoma de Buenos Aires',
      county: 'Ciudad de Buenos Aires',
      house_number: '2332',
      postal_code: '1428',
      label: 'Vuelta de Obligado 2332, 1428 Ciudad de Buenos Aires, Argentina',
      city: 'Ciudad de Buenos Aires',
      district: 'Belgrano',
      street: 'Vuelta de Obligado',
    },
    duration: 300,
    timewindows: [[46800, 57600]],
  },
  {
    label: 'RESTAURANTE GRAN DABBANG',
    location: {
      lat: -34.55572,
      lng: -58.44337,
      country: 'Argentina',
      country_code: 'ARG',
      state: 'Ciudad Autónoma de Buenos Aires',
      county: 'Ciudad de Buenos Aires',
      house_number: '1200',
      postal_code: '1428',
      label: 'Echeverría 1200, 1428 Ciudad de Buenos Aires, Argentina',
      city: 'Ciudad de Buenos Aires',
      district: 'Belgrano',
      street: 'Echeverría',
    },
    duration: 300,
    timewindows: [[46800, 57600]],
  },
  {
    label: 'RESTAURANTE 99',
    location: {
      lat: -34.54343,
      lng: -58.46127,
      country: 'Argentina',
      country_code: 'ARG',
      state: 'Ciudad Autónoma de Buenos Aires',
      county: 'Ciudad de Buenos Aires',
      house_number: '7698',
      postal_code: '1429',
      label: 'Avenida del Libertador 7698, 1429 Ciudad de Buenos Aires, Argentina',
      city: 'Ciudad de Buenos Aires',
      district: 'Núñez',
      street: 'Avenida del Libertador',
    },
    duration: 600,
    timewindows: [[46800, 57600]],
  },
  {
    label: 'RESTAURANTE MALABAR',
    location: {
      lat: -34.54706,
      lng: -58.46881,
      country: 'Argentina',
      country_code: 'ARG',
      state: 'Ciudad Autónoma de Buenos Aires',
      county: 'Ciudad de Buenos Aires',
      house_number: '2355',
      postal_code: '1429',
      label: 'Manzanares 2355, 1429 Ciudad de Buenos Aires, Argentina',
      city: 'Ciudad de Buenos Aires',
      district: 'Núñez',
      street: 'Manzanares',
    },
    duration: 300,
    timewindows: [[46800, 57600]],
  },
  {
    label: 'RESTAURANTE MAYTA',
    location: {
      lat: -34.54361,
      lng: -58.47177,
      country: 'Argentina',
      country_code: 'ARG',
      state: 'Ciudad Autónoma de Buenos Aires',
      county: 'Ciudad de Buenos Aires',
      house_number: '2375',
      postal_code: '1429',
      label: 'Correa 2375, 1429 Ciudad de Buenos Aires, Argentina',
      city: 'Ciudad de Buenos Aires',
      district: 'Núñez',
      street: 'Correa',
    },
    duration: 300,
    timewindows: [[46800, 57600]],
  },
  {
    label: 'RESTAURANTE NARDA COMEDOR',
    location: {
      lat: -34.53903,
      lng: -58.46684,
      country: 'Argentina',
      country_code: 'ARG',
      state: 'Ciudad Autónoma de Buenos Aires',
      county: 'Ciudad de Buenos Aires',
      house_number: '1735',
      postal_code: '1429',
      label: 'Arias 1735, 1429 Ciudad de Buenos Aires, Argentina',
      city: 'Ciudad de Buenos Aires',
      district: 'Núñez',
      street: 'Arias',
    },
    duration: 300,
    timewindows: [[46800, 57600]],
  },
  {
    label: 'HOTEL NH',
    location: {
      lat: -34.52761,
      lng: -58.47316,
      country: 'Argentina',
      country_code: 'ARG',
      state: 'Buenos Aires',
      county: 'Vicente López',
      house_number: '788',
      postal_code: '1638',
      label: 'S. Segurola 788, 1638 Vicente López, Argentina',
      city: 'Vicente López',
      street: 'S. Segurola',
    },
    duration: 600,
    timewindows: [[46800, 57600]],
  },
  {
    label: 'HOTEL MARIA BONITA',
    location: {
      lat: -34.5098,
      lng: -58.48992,
      country: 'Argentina',
      country_code: 'ARG',
      state: 'Buenos Aires',
      county: 'Vicente López',
      house_number: '2747',
      postal_code: '1636',
      label: 'Córdoba 2747, 1636 Olivos, Argentina',
      city: 'Olivos',
      street: 'Córdoba',
    },
    duration: 300,
    timewindows: [[46800, 57600]],
  },
  {
    label: 'HOTEL W',
    location: {
      lat: -34.52895,
      lng: -58.53405,
      country: 'Argentina',
      country_code: 'ARG',
      state: 'Buenos Aires',
      county: 'Vicente López',
      house_number: '5543',
      postal_code: '1606',
      label: 'Carlos Guido y Spano 5543, 1606 Carapachay, Argentina',
      city: 'Carapachay',
      street: 'Carlos Guido y Spano',
    },
    duration: 780,
    timewindows: [[46800, 57600]],
  },
  {
    label: 'HOTEL SHERATON',
    location: {
      lat: -34.49114,
      lng: -58.57037,
      country: 'Argentina',
      country_code: 'ARG',
      state: 'Buenos Aires',
      county: 'San Isidro',
      house_number: '875',
      postal_code: '1609',
      label: 'Capitán Juan de San Martín 875, 1609 Boulogne Sur Mer, Argentina',
      city: 'Boulogne Sur Mer',
      street: 'Capitán Juan de San Martín',
    },
    duration: 840,
    timewindows: [[46800, 57600]],
  },
  {
    label: 'BAR AMSTERDAM',
    location: {
      lat: -34.49328,
      lng: -58.55472,
      country: 'Argentina',
      country_code: 'ARG',
      state: 'Buenos Aires',
      county: 'San Isidro',
      house_number: '230',
      postal_code: '1609',
      label: 'Marcos Paz 230, 1609 Boulogne Sur Mer, Argentina',
      city: 'Boulogne Sur Mer',
      street: 'Marcos Paz',
    },
    duration: 300,
    timewindows: [[46800, 57600]],
  },
  {
    label: 'RESTAURANTE COLOMBIA PAN Y SABOR',
    location: {
      lat: -34.44294,
      lng: -58.53826,
      country: 'Argentina',
      country_code: 'ARG',
      state: 'Buenos Aires',
      county: 'San Fernando',
      postal_code: '1644',
      label: 'Marina del Sol, 1644 Victoria, Argentina',
      city: 'Victoria',
      street: 'Marina del Sol',
    },
    duration: 300,
    timewindows: [[46800, 57600]],
  },
  {
    label: 'RESTAURANTE LA VIETNAMITA',
    location: {
      lat: -34.43701,
      lng: -58.58417,
      country: 'Argentina',
      country_code: 'ARG',
      state: 'Buenos Aires',
      county: 'Tigre',
      house_number: '1730',
      postal_code: '1648',
      label: 'Luis García 1730, 1648 Tigre, Argentina',
      city: 'Tigre',
      street: 'Luis García',
    },
    duration: 300,
    timewindows: [[28800, 46800]],
  },
  {
    label: 'RESTAURANTE EL TLAXCAL',
    location: {
      lat: -34.46486,
      lng: -58.61244,
      country: 'Argentina',
      country_code: 'ARG',
      state: 'Buenos Aires',
      county: 'Tigre',
      postal_code: '1618',
      label: 'Coronel M. Escalada, 1618 Los Troncos del Talar, Argentina',
      city: 'Los Troncos del Talar',
      street: 'Coronel M. Escalada',
    },
    duration: 300,
    timewindows: [[28800, 46800]],
  },
  {
    label: 'RESTAURANTE LA CHILANGUITA',
    location: {
      lat: -34.48938,
      lng: -58.6323,
      country: 'Argentina',
      country_code: 'ARG',
      state: 'Buenos Aires',
      county: 'Tigre',
      house_number: '1750',
      postal_code: '1611',
      label: 'Ombú 1750, 1611 Don Torcuato Oeste, Argentina',
      city: 'Don Torcuato Oeste',
      street: 'Ombú',
    },
    duration: 600,
    timewindows: [[28800, 46800]],
  },
  {
    label: 'RESTAURANTE LA COSA NOSTRA',
    location: {
      lat: -34.45081,
      lng: -58.64345,
      country: 'Argentina',
      country_code: 'ARG',
      state: 'Buenos Aires',
      county: 'Tigre',
      postal_code: '1617',
      label: 'Juan Pedro Echeverría, 1617 General Pacheco, Argentina',
      city: 'General Pacheco',
      street: 'Juan Pedro Echeverría',
    },
    duration: 300,
    timewindows: [[28800, 46800]],
  },
  {
    label: 'RESTAURANTE FISHERS',
    location: {
      lat: -34.4185,
      lng: -58.71086,
      country: 'Argentina',
      country_code: 'ARG',
      state: 'Buenos Aires',
      county: 'Tigre',
      house_number: '701',
      postal_code: '1621',
      label: 'Avenida Benavídez 701, 1621 Benavídez, Argentina',
      city: 'Benavídez',
      street: 'Avenida Benavídez',
    },
    duration: 300,
    timewindows: [[28800, 46800]],
  },
  {
    label: 'RESTAURANTE DE TAPAS',
    location: {
      lat: -34.38828,
      lng: -58.7257,
      country: 'Argentina',
      country_code: 'ARG',
      state: 'Buenos Aires',
      county: 'Escobar',
      house_number: '498',
      postal_code: '1623',
      label: 'Moreno 498, 1623 Ingeniero Maschwitz, Argentina',
      city: 'Ingeniero Maschwitz',
      street: 'Moreno',
    },
    duration: 300,
    timewindows: [[28800, 46800]],
  },
  {
    label: 'RESTAURANTE LAS CAZUELAS',
    location: {
      lat: -34.52041,
      lng: -58.75904,
      country: 'Argentina',
      country_code: 'ARG',
      state: 'Buenos Aires',
      county: 'José C. Paz',
      house_number: '2278',
      postal_code: '1665',
      label: 'Arenales 2278, 1665 José C. Paz, Argentina',
      city: 'José C. Paz',
      street: 'Arenales',
    },
    duration: 600,
    timewindows: [[28800, 46800]],
  },
  {
    label: 'RESTAURANTE PALOMARES',
    location: {
      lat: -34.43881,
      lng: -58.84735,
      country: 'Argentina',
      country_code: 'ARG',
      state: 'Buenos Aires',
      county: 'Pilar',
      house_number: '850',
      postal_code: '1669',
      label: 'Saravi 850, 1669 La Lonja, Argentina',
      city: 'La Lonja',
      street: 'Saravi',
    },
    duration: 300,
    timewindows: [[28800, 46800]],
  },
  {
    label: 'RESTAURANTE SOUL',
    location: {
      lat: -34.43881,
      lng: -58.84735,
      country: 'Argentina',
      country_code: 'ARG',
      state: 'Buenos Aires',
      county: 'Pilar',
      house_number: '850',
      postal_code: '1669',
      label: 'Saravi 850, 1669 La Lonja, Argentina',
      city: 'La Lonja',
      street: 'Saravi',
    },
    duration: 900,
    timewindows: [[28800, 46800]],
  },
  {
    label: 'RESTAURANTE CLORODMIRA',
    location: {
      lat: -34.47147,
      lng: -58.88679,
      country: 'Argentina',
      country_code: 'ARG',
      state: 'Buenos Aires',
      county: 'Pilar',
      house_number: '2151',
      postal_code: '1629',
      label: 'Almirante Guillermo Brown 2151, 1629 Pilar, Argentina',
      city: 'Pilar',
      street: 'Almirante Guillermo Brown',
    },
    duration: 960,
    timewindows: [
      [32400, 50400],
      [61200, 72000],
    ],
  },
];
