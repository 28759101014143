import { MuiThemeProvider } from '@material-ui/core';
import { CookiesBanner, theme } from '@sm-highway-web/react-components';
import React, { useEffect, useState } from 'react';
import { FormattedMessage, RawIntlProvider } from 'react-intl';
import { connect } from 'react-redux';
import { Redirect, Route, Router, Switch } from 'react-router-dom';
import PrivateComponent from '../components/PrivateComponent';
import PublicComponent from '../components/PublicComponent';
import SnackbarComponent from '../components/SnackbarComponent';
import * as routes from '../constants/Routing';
import history from '../helpers/History';
import { intl, setSelectedIntl } from '../helpers/IntGlobalProvider';
import { languageShort } from '../helpers/Translations';
import { User } from '../redux/actions';
import store from '../redux/store/index';

let { language } = navigator; // language without region code

switch (languageShort(language)) {
  case 'eu':
  case 'es':
  case 'gl':
  case 'ca':
    language = 'es-es';
    store.dispatch(User.changeLanguage(language));
    break;
  default:
    language = 'en-us';
}

type OwnHighwayProps = {
  isLogged: boolean;
  userLanguage?: string;
};

type HighwayProps = OwnHighwayProps;

const Highway = ({ isLogged, userLanguage }: HighwayProps) => {
  const tempCookiesAccepted = localStorage.getItem('COOKIES_ACCEPTED');
  const [cookiesAccepted, setCookiesAccepted] = useState(tempCookiesAccepted || false);
  const [defaultIntl, setDefaultIntl] = useState(intl);

  setSelectedIntl(languageShort(userLanguage || language));

  useEffect(() => {
    if (isLogged) {
      localStorage.setItem('COOKIES_ACCEPTED', JSON.stringify(true));
      setCookiesAccepted(true);
    } else {
      User.initUserLocation();
    }

    // Setting the user language.
    const locale = languageShort(userLanguage || language);
    setSelectedIntl(locale);
    setDefaultIntl(intl);
  }, [isLogged, userLanguage]);

  const handleCookiesAccept = () => {
    localStorage.setItem('COOKIES_ACCEPTED', JSON.stringify(true));
    setCookiesAccepted(true);
  };

  return (
    <MuiThemeProvider theme={theme}>
      <RawIntlProvider value={defaultIntl}>
        <>
          <SnackbarComponent />
          <Router history={history}>
            <Switch>
              <Redirect from={routes.LOGOUT} to={routes.LOGIN} />
              <Route path={routes.PRIVATEHIGHWAY} component={PrivateComponent} />
              <Route path={routes.ROOT} component={PublicComponent} />
            </Switch>
          </Router>
          {!isLogged && (!cookiesAccepted || cookiesAccepted === 'false') && (
            <CookiesBanner
              message={
                <FormattedMessage
                  id="cookies.message"
                  values={{
                    a: (chunks: any) => (
                      <a href={`${routes.COOKIESPOLICY}`} target="_blank" rel="noopener noreferrer">
                        {chunks}
                      </a>
                    ),
                  }}
                />
              }
              acceptAction={handleCookiesAccept}
              moreInfoAction={() => window.open(routes.COOKIESPOLICY, '_blank')}
            />
          )}
        </>
      </RawIntlProvider>
    </MuiThemeProvider>
  );
};

Highway.defaultProps = {
  isLogged: false,
  userLanguage: languageShort(language),
};

const mapStateToProps = (state: any) => {
  return {
    isLogged: state.user.isLogged,
    userLanguage: state.user.user && state.user.user.language,
  };
};

export default connect(mapStateToProps, null)(Highway);
