import { AsyncSearchableSelect } from '@sm-highway-web/react-components';
import React from 'react';
import {
  getAutocompleteGeocode,
  getLocationFromId,
  getLocationFromLatLng,
} from '../../services/requests';
import FormattedMessage from '../FormattedMessageCustom';

type LocationSearchProps = {
  onUpdate: (...args: any[]) => any;
  placeholder?: string;
  fieldValue?: string;
  onKeyDown?: (...args: any[]) => any;
  onBlur?: (...args: any[]) => any;
  countryCode?: string | null;
  menuPlacement?: 'bottom' | 'top';
};

const LocationSearch = ({
  onUpdate,
  placeholder,
  fieldValue,
  onKeyDown,
  onBlur,
  countryCode,
  menuPlacement,
}: LocationSearchProps) => {
  const mapOptionsToValues = (results: any) => {
    return results.map((r: any) => ({
      value: r,
      label: r.label,
    }));
  };

  let writingTimeout: any = null;

  return (
    <AsyncSearchableSelect
      defaultValue={fieldValue}
      isClearable
      autoFocus
      blurInputOnSelect
      placeholder={placeholder}
      onKeyDown={onKeyDown}
      onBlur={onBlur}
      menuPlacement={menuPlacement}
      noOptionsMessage={<FormattedMessage id="formlocation.notfound" />}
      actionOnSelectedOption={async (location: any) => {
        if (location && location.lat && location.lng) {
          await onUpdate(location);
        } else if (location === null) {
          await onUpdate(undefined);
        } else {
          const resp = await getLocationFromId(location.location_id);
          await onUpdate(resp);
        }
      }}
      getOptions={async (inputValue: any, callback: any) => {
        // cuando escribes la funcion va llamando al cleartimeout lo que hace que se rompa
        // la promesa y por lo tanto  ser rompa el stack de funciones
        clearTimeout(writingTimeout);
        await new Promise((res: any) => {
          writingTimeout = setTimeout(res, 1000);
        });
        const inputValueSplited = inputValue.split(',');
        if (
          inputValueSplited.length === 2 && // eslint-disable-next-line
          !isNaN(inputValueSplited[0]) && // eslint-disable-next-line
          !isNaN(inputValueSplited[1])
        ) {
          const [lat, lng] = inputValueSplited;
          const resp = await getLocationFromLatLng(lat, lng);
          if (resp) {
            return callback(mapOptionsToValues([resp]));
          }
        }

        const response = await getAutocompleteGeocode(inputValue, countryCode);
        return callback(mapOptionsToValues(response));
      }}
    />
  );
};

LocationSearch.defaultProps = {
  placeholder: undefined,
  fieldValue: undefined,
  onKeyDown: () => {},
  onBlur: () => {},
};

export default LocationSearch;
