import { createIntl, createIntlCache } from 'react-intl';
import translations from './Translations';

const intls: any = {};

// This is optional but highly recommended
// since it prevents memory leak
const cache = createIntlCache();

Object.keys(translations).forEach((locale: string) => {
  intls[locale] = createIntl(
    {
      locale,
      // @ts-ignore FIXME!!
      messages: translations[locale],
    },
    cache
  );
});

export let intl = intls.es;

export const setSelectedIntl = (locale: string) => {
  // @ts-ignore FIXME!!
  intl = intls[locale];
};
