import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { MoreHoriz, TransferWithinAStation } from '@material-ui/icons';
// import FormattedMessage from './FormattedMessageCustom';
import { Button, SearchInput } from '@sm-highway-web/react-components';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import Icons from '../assets/svg';
import * as routing from '../constants/Routing';
import { PLAN_TYPE_TO_SHOW } from '../constants/Subscriptions';
import { dateFormatShortDate } from '../helpers/TimeDistance';
import { IAdministrationUserExtended, IAdministrationUsersPagination } from '../interfaces/users';
import { InfoError, User } from '../redux/actions';
import { getAdminUsers, putSubscriptionLimits } from '../services/requests';
import DataTable from './datatable/DataTable';

const styles = (theme: any) => ({
  icons: {
    color: theme.palette.secondary.main,
    height: '20px',
  },

  crossicon: {
    color: ' #ff5c33',
  },

  checkIcon: { color: '#00cc00' },
  trialing: { color: '#C1C233' },
  pastDue: { color: '#e64400' },
  reduced: { height: '16px' },

  containerDiv: {
    height: 'calc(100% - 48px)',
    overflow: 'hidden',
  },

  copycursor: {
    cursor: 'copy',
  },

  pointer: {
    cursor: 'pointer',
  },

  input: {
    padding: '8px',
  },
});

type EditLimitsDialogProps = {
  user?: IAdministrationUserExtended;
  onClose: (...args: any[]) => any;
};

const EditLimitsDialog = ({ user, onClose }: EditLimitsDialogProps) => {
  const [maxClients, setMaxClients] = useState<number | undefined>();
  const [maxServicesPlan, setMaxServicesPlan] = useState<number | undefined>();
  const [maxCustomerEmails, setMaxCustomerEmails] = useState<number | undefined>();

  useEffect(() => {
    setMaxCustomerEmails(user?.subscription?.plan?.max_customer_emails);
  }, [user]);

  return (
    <Dialog open={!!user} onClose={onClose}>
      <DialogTitle>Edit User Limits</DialogTitle>
      <DialogContent>
        <Grid container direction="column">
          <Typography>Max Emails</Typography>
          <TextField
            type="number"
            onChange={(evt) => setMaxCustomerEmails(parseInt(evt.target.value, 10))}
            defaultValue={maxCustomerEmails}
            InputProps={{
              inputProps: {
                min: 0,
                max: user?.subscription?.plan?.customer_emails_count,
              },
            }}
          />
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button
          onClick={async () => {
            if(user) {
              await putSubscriptionLimits(user.id, maxCustomerEmails);
            }
            onClose();
          }}
          color="primary"
        >
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

EditLimitsDialog.defaultProps = {
  user: undefined,
};

type AdministrationUsersProps = {
  classes: {
    icons: string;
    crossicon: string;
    containerDiv: string;
    checkIcon: string;
    reduced: string;
    copycursor: string;
    trialing: string;
    pastDue: string;
    pointer: string;
  };
  user?: {
    id: string;
  };
  actions: {
    beAsUser: (...args: any[]) => any;
    validateUserAdmin: (...args: any[]) => any;
    sendStatusToSnackbar: (...args: any[]) => any;
  };
  history: {
    push: (...args: any[]) => any;
  };
  asUser?: string;
};

const AdministrationUsers = ({
  classes,
  user,
  actions,
  asUser,
  history,
}: AdministrationUsersProps) => {
  const [paginatedUsers, setPaginatedUsers] = useState<IAdministrationUsersPagination | undefined>(undefined);
  const [searchText, setSearchText] = useState(undefined);
  const [offset, setOffset] = useState(0);
  const [limit, setLimit] = useState(100);
  const [subscriptionStatus, setSubscriptionStatus] = useState([]);
  const [editUser, setEditUser] = useState();
  const [loading, setLoading] = useState(false);

  const handleAsUser = (id: any) => {
    actions.beAsUser(id);
    window.open(routing.PLANS);
  };

  const asyncSetUsers = (text: any, offsetX: any, limitX: any, subscriptionStatusX: any) => {
    (async () => {
      const ss = subscriptionStatusX || subscriptionStatus;
      const response = await getAdminUsers(
        text,
        offsetX || offset,
        limitX || limit,
        'created:desc',
        ss.length > 0 ? ss.join(':') : undefined
      );
      setPaginatedUsers(response);
    })();
  };

  useEffect(() => {
    if (!asUser) {
      (async () => {
        setLoading(true);
        const response = await getAdminUsers(searchText, 0, 100, 'created:desc');
        setPaginatedUsers(response);
        setLoading(false);
      })();
    } else {
      actions.sendStatusToSnackbar('infoerror.using_as_user_forbidden_action', 'error');
    }
  }, [searchText, asUser, actions]);

  const onPageChange = (offsetX: any, limitX: any) => {
    setOffset(offsetX);
    setLimit(limitX);
    // @ts-expect-error ts-migrate(2554) FIXME: Expected 4 arguments, but got 3.
    asyncSetUsers(undefined, offsetX, limitX);
  };

  const copyEmail = (event: any) => {
    const el = document.createElement('textarea');
    el.value = event.target.innerText;
    const helper = document.getElementById('copy-email-helper');
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    helper.appendChild(el);
    el.select();
    document.execCommand('copy');
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    helper.removeChild(el);
  };

  const validateUserAdmin = async (userId: any) => {
    if (!asUser) {
      await actions.validateUserAdmin(userId);
      paginatedUsers?.docs.forEach((doc: IAdministrationUserExtended) => {
        if (doc.id === userId) {
          doc.validated = true;
        }
      });
    } else {
      actions.sendStatusToSnackbar('infoerror.using_as_user_forbidden_action', 'error');
    }
  };

  return (
    <>
      <Grid container direction="row" style={{ padding: '8px' }} justify="space-between">
        <Select
          multiple
          variant="outlined"
          style={{ width: '300px', padding: '0' }}
          value={subscriptionStatus}
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'input' does not exist on type '{ icons: ... Remove this comment to see the full error message
          classes={{ select: classes.input }}
          onChange={(evt) => {
            if (!asUser) {
              // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
              setSubscriptionStatus(evt.target.value);
              asyncSetUsers(undefined, undefined, undefined, evt.target.value);
            } else {
              actions.sendStatusToSnackbar('infoerror.using_as_user_forbidden_action', 'error');
            }
          }}
        >
          <MenuItem value="active">Active</MenuItem>
          <MenuItem value="trialing">Trialing</MenuItem>
          <MenuItem value="canceled">Canceled</MenuItem>
          <MenuItem value="incomplete">Incomplete</MenuItem>
        </Select>
        <SearchInput
          disabled={loading}
          onTextChange={(text: any) => {
            setSearchText(text);
          }}
        />
      </Grid>
      <div className={classes.containerDiv}>
        <DataTable
          limit={limit}
          page={paginatedUsers && paginatedUsers.page}
          total={paginatedUsers && paginatedUsers.total}
          docs={paginatedUsers && paginatedUsers.docs}
          onChangePage={onPageChange}
          mapping={[
            {
              column: 'first_name',
              name: 'Name',
              // sortable: true,
              computed: (doc: any) => ({
                name: `${doc.first_name ? doc.first_name : ''} ${
                  doc.last_name ? doc.last_name : ''
                }`,

                deleted: doc.deleted,
              }),
              formatter: (doc: any) => (
                <div style={doc.deleted ? { textDecoration: 'line-through' } : {}}>{doc.name}</div>
              ),
            },
            {
              column: 'email',
              name: 'Email',
              // sortable: true,
              formatter: (doc: any) => (
                <div
                  className={`noselect ${classes.copycursor}`}
                  onDoubleClick={(event) => copyEmail(event)}
                >
                  {doc}
                </div>
              ),
            },
            {
              column: 'company',
              name: 'Company',
              // sortable: true
            },
            {
              column: 'phone',
              name: 'Phone',
              // sortable: false
            },
            {
              column: 'stats',
              name: 'Usage (VCPO)',
              align: 'center',
              computed: (doc: any) => {
                return <Grid container direction="column" style={{ textAlign: 'justify', width: '100%', minWidth: '150px' }} wrap="nowrap">
                  <Grid item><span>Vehicles: {doc.total_vehicles ?? 0 }</span></Grid>
                  <Grid item><span>Clients: {doc.total_clients ?? 0 }</span></Grid>
                  <Grid item><span>Plans: {doc.total_plans ?? 0 }</span></Grid>
                  <Grid item><span>Optimizations: {doc.total_executions ?? 0 }</span></Grid>
                </Grid>;
              },
            },
            {
              column: 'license',
              name: 'License',
              align: 'center',
              computed: (doc: any) => {
                return <Grid container direction="column" style={{ textAlign: 'justify', width: '100%', minWidth: '200px' }} wrap="nowrap">
                  {doc.subscription.plan.type ? 
                      <Grid item>
                        <strong
                          role="img"
                          aria-label={doc.subscription.plan.type}
                          style={{ fontSize: '14px', height: '14px', width: '14px' }}
                        > 
                          {PLAN_TYPE_TO_SHOW[doc.subscription.plan.type.toUpperCase()]}
                        </strong>
                      </Grid>
                    :null}
                  <Grid item><span>Licenses: {doc.subscription.plan.vehicles ?? 0 }</span></Grid>
                  <Grid item><span>Max emails/month: {doc.subscription.plan.max_customer_emails ?? 0 }</span></Grid>
                  <Grid item><span>Emails sent this month: {doc.subscription.plan.customer_emails_count ?? 0 }</span></Grid>
                </Grid>;
              },
            },
            {
              column: 'created',
              name: 'Registration',
              align: 'center',
              // sortable: true,
              formatter: dateFormatShortDate,
            },
            {
              column: 'payment',
              name: 'Payment Status',
              computed: (doc: any) => (doc.subscription ? doc.subscription.status : ''),
              formatter: (status: any) => {
                let cname;
                switch (status) {
                  case 'active':
                    cname = classes.checkIcon;
                    break;
                  case 'trialing':
                    cname = classes.trialing;
                    break;
                  case 'canceled':
                  case 'incomplete':
                    cname = classes.crossicon;
                    break;
                  case 'past_due':
                    cname = classes.pastDue;
                    break;
                  default:
                    break;
                }
                return <div className={cname}>{status}</div>;
              },
            },
            {
              column: 'date_payment',
              name: 'Date',
              computed: (doc: any) => (doc.subscription ? doc.subscription.valid : ''),
              formatter: (doc: any) =>
                doc === '' ? (
                  'No subscription'
                ) : doc ? (
                  <div
                    className={new Date(doc) > new Date() ? classes.checkIcon : classes.crossicon}
                  >
                    {dateFormatShortDate(doc)}
                  </div>
                ) : null,
            },
            {
              column: 'validated',
              name: 'Val.',
              computed: (doc: any) => doc,
              formatter: (doc: any) =>
                doc.validated ? (
                  <Icons.CheckmarkIcon className={`${classes.icons} ${classes.checkIcon}`} />
                ) : (
                  <Icons.CrossIcon
                    className={`${classes.icons} ${classes.reduced} ${classes.crossicon} ${classes.pointer}`}
                    onDoubleClick={() => {
                      validateUserAdmin(doc.id);
                    }}
                  />
                ),
            },
            {
              column: 'actions',
              name: 'Action',
              align: 'center',
              computed: (doc: any) => doc,
              formatter: (doc: any) =>
                // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
                doc.id !== user.id && (
                  <>
                    <Button color="secondary" variant="text" onClick={() => handleAsUser(doc.id)}>
                      <TransferWithinAStation />
                    </Button>
                    <Button
                      color="secondary"
                      variant="text"
                      onClick={() => {
                        if (!asUser) {
                          setEditUser(doc);
                        } else {
                          actions.sendStatusToSnackbar(
                            'infoerror.using_as_user_forbidden_action',
                            'error'
                          );
                        }
                      }}
                    >
                      <MoreHoriz />
                    </Button>
                  </>
                ),
            },
          ]}
        />
        <div id="copy-email-helper" />
        <EditLimitsDialog user={editUser} onClose={() => setEditUser(undefined)} />
      </div>
    </>
  );
};

AdministrationUsers.defaultProps = {
  user: undefined,
  asUser: undefined,
};

const mapStateToProps = (state: any) => {
  return {
    user: state.user.user,
    asUser: state.user.asUser,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    beAsUser: (id: any) => dispatch(User.beAsUser(id)),
    validateUserAdmin: (user: any) => dispatch(User.getAdminValidateUser(user)),
    sendStatusToSnackbar: (message: any, type: any) =>
      dispatch(InfoError.sendStatusToSnackbar(message, type)),
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(AdministrationUsers));
