import { intl } from '../../helpers/IntGlobalProvider';
import store from '../store/index';

// const intl = useIntl();

export const ProjectRoles = {
  project_manager: 'project_manager',
  project_user: 'project_user',
};

export const getCurrentProjectId = () => {
  const { activeProject } = store.getState().projects;
  if (activeProject && activeProject.id) {
    return activeProject.id;
  }
  const { organization } = store.getState().organization;
  if (organization && organization.default_project_id) return organization.default_project_id;

  return '';
};

export const getProjectLabel = (projectLabel: any, organizationLabel?: any) => {
  try {

    let label;

    if (organizationLabel && organizationLabel !== '') {
      const { organization } = store.getState().organization;
      label = organization.label;
    } else {
      label = organizationLabel;
    }

    const projectDefault = intl.formatMessage({ id: 'projects.project' });
    return `${projectLabel || `${label} ${projectDefault}`}`;
  } catch (_) {
    return '';
  }
};

export const currentUser = {
  /**
   * Checks if current user belongs to project.
   * @param {*} projects
   * @param {*} userId
   */
  belongsToProject: (project: any) => {
    try {
      const { user } = store.getState().user;
      return !!project.users.find((_user: any) => _user.user_id === user.id);
    } catch (_) {
      return false;
    }
  },

  /**
   * Checks if current user belongs to project and is project manager.
   * @param {*} projects
   */
  isManagerInProject: (project: any) => {
    try {
      const { user } = store.getState().user;
      return !!project.users.find(
        (_user: any) => _user.user_id === user.id && _user.role === ProjectRoles.project_manager
      );
    } catch (_) {
      return false;
    }
  },
};

/**
 * Used in LeftBar component.
 * @param {*} projects
 * @param {*} userId
 */
export const filterAccessibleProjects = (projects: any, userId: any) => {
  try {
    const ordered = orderProjects(projects);
    return ordered.filter(
      (project: any) => !!project.users.find((_user: any) => _user.user_id === userId)
    );
  } catch (_) {
    return [];
  }
};

export const orderProjects = (projects: any) => {
  try {
    return projects.sort((a: any, b: any) => {
      const nameA = `${getProjectLabel(a.label ? a.label : a.id)}`;
      const nameB = `${getProjectLabel(b.label ? b.label : b.id)}`;
      // Compare the 2 names
      if (nameA < nameB) return -1;
      if (nameA > nameB) return 1;
      return 0;
    });
  } catch (_) {
    return [];
  }
};

export const orderProjectUsers = (projectUsers: any) => {
  try {
    return projectUsers.sort((a: any, b: any) => {
      const fullnameA = `${a.first_name} ${a.last_name}`;
      const fullnameB = `${b.first_name} ${b.last_name}`;
      // Compare the 2 fullnames
      if (fullnameA < fullnameB) return -1;
      if (fullnameA > fullnameB) return 1;
      return 0;
    });
  } catch (_) {
    return [];
  }
};
