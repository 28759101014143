import ActivityIcon from './ActivityIcon';
import ChatIcon from './ChatIcon';
import CheckmarkIcon from './CheckmarkIcon';
import CrossIcon from './CrossIcon';
import ExcelIcon from './ExcelIcon';
import FlagIcon from './FlagIcon';
import GoalIcon from './GoalIcon';
import GoToMapIcon from './GoToMapIcon';
import HouseIcon from './HouseIcon';
import InspectRouteIcon from './InspectRouteIcon';
import POIIcon from './POIIcon';
import ReloadIcon from './ReloadIcon';
import RouteIcon from './RouteIcon';
import ServiceIcon from './ServiceIcon';
import ShareIcon from './ShareIcon';
import * as StatusIcons from './StatusIcon';
import * as StepperIcons from './StepperIcon';
import SuccessStarIcon from './SuccessStarIcon';
import TimeWindowIcon from './TimeWindowIcon';
import TruckIcon from './TruckIcon';
import ViewMapIcon from './ViewMapIcon';
import WarningIcon from './WarningIcon';

export default {
  ActivityIcon,
  ChatIcon,
  CheckmarkIcon,
  CrossIcon,
  ExcelIcon,
  FlagIcon,
  GoToMapIcon,
  GoalIcon,
  HouseIcon,
  InspectRouteIcon,
  POIIcon,
  ReloadIcon,
  RouteIcon,
  ServiceIcon,
  ShareIcon,
  SuccessStarIcon,
  StatusIcons,
  StepperIcons,
  TruckIcon,
  TimeWindowIcon,
  ViewMapIcon,
  WarningIcon,
};
