import createSvgIcon from '@material-ui/icons/utils/createSvgIcon';
import React from 'react';

export const StatusSuccesIcon = createSvgIcon(
  <>
    <path d="m12.1 1.1c-6 0-10.9 4.9-10.9 10.9s4.9 10.9 10.9 10.9 10.9-4.9 10.9-10.9-4.9-10.9-10.9-10.9zm7.2 7.5l-8 8h-0.1c-0.4 0.4-1 0.4-1.3 0l-3.7-3.7c-0.4-0.4-0.3-0.9 0-1.3 0.4-0.4 1-0.4 1.3 0l3 3 7.4-7.4c0.4-0.4 0.9-0.3 1.3 0s0.5 1 0.1 1.4z" />
  </>,
  'StatusSuccesIcon'
);

export const StatusFailIcon = createSvgIcon(
  <>
    <path d="m12.1 1.1c-6 0-10.9 4.9-10.9 10.9s4.9 10.9 10.9 10.9 10.9-4.9 10.9-10.9-4.9-10.9-10.9-10.9zm5.2 14.9c0.4 0.4 0.4 1 0 1.4s-1 0.4-1.4 0l-4-4-4 4c-0.4 0.4-1 0.4-1.4 0s-0.4-1 0-1.4l4-4-4-4c-0.4-0.4-0.4-1 0-1.4s1-0.4 1.4 0l4 4 4-4c0.4-0.4 1-0.4 1.4 0s0.4 1 0 1.4l-4 4 4 4z" />
  </>,
  'StatusFailIcon'
);
