import { IProjectCommunicationPayload } from 'highway-api/dist/src/Project';
import getHighway from '../../services/highway';
import { deleteQuery, post, put } from '../../services/requests';
import constants from '../constants';
import store from '../store/index';

export const setActiveProject = (project: any) => {
  return (dispatch: any) => {
    dispatch({
      type: constants.Projects.SET_ACTIVE_PROJECT_SUCCESS,
      payload: project,
    });
  };
};

export const getProjects = () => {
  return (dispatch: any) => {
    const { user } = store.getState();
    const highway = getHighway(user.token);
    highway.project
      .getAll()
      .then((response) => {
        const projects = response;

        dispatch({
          type: constants.Projects.PROJECTS_GET_PROJECTS_SUCCESS,
          payload: {
            projects,
            activeProject: undefined,
            // activeProject
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: constants.Projects.PROJECTS_GET_PROJECTS_ERROR,
          payload: error,
        });
      });
  };
};

export const getProject = (projectId: any) => {
  return (dispatch: any) => {
    const { user } = store.getState();
    const highway = getHighway(user.token);
    highway.project
      .get(projectId)
      .then((response) => {
        // TODO: Check if response exists in the array of projects

        dispatch({
          type: constants.Projects.PROJECTS_GET_PROJECT_SUCCESS,
          payload: response,
        });
      })
      .catch((error) => {
        dispatch({
          type: constants.Projects.PROJECTS_GET_PROJECT_ERROR,
          payload: error,
        });
      });
  };
};

export const createProject = (label: any, description: any, location: any, limits: any) => {
  return (dispatch: any) => {
    const { user } = store.getState();
    const highway = getHighway(user.token);
    highway.project
      .create({ label, description, location, limits })
      .then((response) => {
        dispatch({
          type: constants.Projects.PROJECTS_CREATE_PROJECT_SUCCESS,
          payload: response,
        });
      })
      .catch((error) => {
        dispatch({
          type: constants.Projects.PROJECTS_CREATE_PROJECT_ERROR,
          payload: error,
        });
      });
  };
};

export const editProject = (projectId: any, projectData: any) => {
  return (dispatch: any) => {
    const { user } = store.getState();
    const highway = getHighway(user.token);
    highway.project
      .update(projectId, projectData)
      .then((response) => {
        dispatch({
          type: constants.Projects.PROJECTS_EDIT_PROJECT_SUCCESS,
          payload: response,
        });
      })
      .catch((error) => {
        dispatch({
          type: constants.Projects.PROJECTS_EDIT_PROJECT_ERROR,
          payload: error,
        });
      });
  };
};

export const editProjectsResources = (projectsResources: any) => {
  return (dispatch: any) => {
    const { user } = store.getState();
    const highway = getHighway(user.token);
    highway.project
      .updateProjectsResources(projectsResources)
      .then((response) => {
        dispatch({
          type: constants.Projects.PROJECTS_EDIT_PROJECTS_RESOURCES_SUCCESS,
          payload: response,
        });
      })
      .catch((error) => {
        dispatch({
          type: constants.Projects.PROJECTS_EDIT_PROJECTS_RESOURCES_ERROR,
          payload: error,
        });
      });
  };
};

export const deleteProject = (projectId: any) => {
  return (dispatch: any) => {
    const { user } = store.getState();
    const highway = getHighway(user.token);
    highway.project
      .delete(projectId)
      .then((response) => {
        dispatch({
          type: constants.Projects.PROJECTS_DELETE_PROJECT_SUCCESS,
          payload: response,
        });
      })
      .catch((error) => {
        dispatch({
          type: constants.Projects.PROJECTS_DELETE_PROJECT_ERROR,
          payload: error,
        });
      });
  };
};

export const addProjectUser = (projectId: any, projectUserData: any) => {
  return (dispatch: any) => {
    const { user } = store.getState();
    const highway = getHighway(user.token);
    highway.project
      .createUser(projectId, projectUserData)
      .then((response) => {
        dispatch({
          type: constants.Projects.PROJECTS_CREATE_PROJECT_USER_SUCCESS,
          payload: {
            projectId,
            projectUsers: response,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: constants.Projects.PROJECTS_CREATE_PROJECT_USER_ERROR,
          payload: error,
        });
      });
  };
};

export const editProjectUser = (projectId: any, userId: any, projectUserDataUpdate: any) => {
  return (dispatch: any) => {
    const { user } = store.getState();
    const highway = getHighway(user.token);
    highway.project
      .updateUser(projectId, userId, projectUserDataUpdate)
      .then((response) => {
        dispatch({
          type: constants.Projects.PROJECTS_EDIT_PROJECT_USER_SUCCESS,
          payload: {
            projectId,
            projectUsers: response,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: constants.Projects.PROJECTS_EDIT_PROJECT_USER_ERROR,
          payload: error,
        });
      });
  };
};

export const deleteProjectUser = (projectId: any, userId: any) => {
  return (dispatch: any) => {
    const { user } = store.getState();
    const highway = getHighway(user.token);
    highway.project
      .deleteUser(projectId, userId)
      .then((response) => {
        dispatch({
          type: constants.Projects.PROJECTS_DELETE_PROJECT_USER_SUCCESS,
          payload: {
            projectId,
            projectUsers: response,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: constants.Projects.PROJECTS_DELETE_PROJECT_USER_ERROR,
          payload: error,
        });
      });
  };
};

export const uploadAvatar = (projectId: any, fileName: any, mimeType: any, file: any) => {
  const formData = new FormData();
  formData.append('name', fileName);
  formData.append('mime', mimeType);
  formData.append('data', file);
  return (dispatch: any) => {
    return post(`/api/v1/project/${projectId}/avatar`, formData, {
      'content-type': 'multipart/form-data',
      Authorization: `Bearer ${store.getState().user.token}`,
    })
      .then((response) => {
        dispatch({
          type: constants.Projects.PROJECTS_UPLOAD_PROJECT_AVATAR_SUCCESS,
          payload: {
            ...response.data,
            projectId,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: constants.Projects.PROJECTS_UPLOAD_PROJECT_AVATAR_ERROR,
          payload: error,
        });
      });
  };
};

export const removeAvatar = (projectId: any) => {
  return (dispatch: any) => {
    return deleteQuery(`/api/v1/project/${projectId}/avatar`, {
      Authorization: `Bearer ${store.getState().user.token}`,
    })
      .then((response) => {
        dispatch({
          type: constants.Projects.PROJECTS_REMOVE_PROJECT_AVATAR_SUCCESS,
          payload: {
            ...response.data,
            projectId,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: constants.Projects.PROJECTS_REMOVE_PROJECT_AVATAR_ERROR,
          payload: error,
        });
      });
  };
};

export const createCustomField = (projectId: any, type: any, fieldData: any) => {
  return (dispatch: any) => {
    return post(`/api/v1/project/${projectId}/custom_fields?type=${type}`, fieldData, {
      Authorization: `Bearer ${store.getState().user.token}`,
    })
      .then((response) => {
        dispatch({
          type: constants.Projects.PROJECTS_CREATE_CUSTOM_FIELD_SUCCESS,
          payload: {
            ...response.data,
            projectId,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: constants.Projects.PROJECTS_CREATE_CUSTOM_FIELD_ERROR,
          // This should be payload: error when queries are added in the frontend API.
          payload: error.response.data,
        });
      });
  };
};

export const updateCustomField = (
  projectId: any,
  customFieldId: any,
  type: any,
  fieldData: any
) => {
  return (dispatch: any) => {
    return put(
      `/api/v1/project/${projectId}/custom_fields/${customFieldId}?type=${type}`,
      fieldData,
      {
        Authorization: `Bearer ${store.getState().user.token}`,
      }
    )
      .then((response) => {
        dispatch({
          type: constants.Projects.PROJECTS_EDIT_CUSTOM_FIELD_SUCCESS,
          payload: {
            ...response.data,
            projectId,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: constants.Projects.PROJECTS_EDIT_CUSTOM_FIELD_ERROR,
          // This should be payload: error when queries are added in the frontend API.
          payload: error.response.data,
        });
      });
  };
};

export const removeCustomField = (projectId: any, customFieldId: any, type: any) => {
  return (dispatch: any) => {
    return deleteQuery(`/api/v1/project/${projectId}/custom_fields/${customFieldId}?type=${type}`, {
      Authorization: `Bearer ${store.getState().user.token}`,
    })
      .then((response) => {
        dispatch({
          type: constants.Projects.PROJECTS_REMOVE_CUSTOM_FIELD_SUCCESS,
          payload: {
            ...response.data,
            projectId,
          },
        });
      })
      .catch((error) => {
        dispatch({
          type: constants.Projects.PROJECTS_REMOVE_CUSTOM_FIELD_ERROR,
          // This should be payload: error when queries are added in the frontend API.
          payload: error.response.data,
        });
      });
  };
};

export const editCommunication = (projectId: any, payloadData: IProjectCommunicationPayload) => {
  return (dispatch: any) => {
    const { user } = store.getState();
    const highway = getHighway(user.token);
    highway.project
      .editCommunication(projectId, payloadData)
      .then((response) => {
        dispatch({
          type: constants.Projects.PROJECTS_EDIT_PROJECT_SUCCESS,
          payload: response,
        });
      })
      .catch((error) => {
        dispatch({
          type: constants.Projects.PROJECTS_EDIT_PROJECT_ERROR,
          payload: error,
        });
      });
  };
};

export const sendTestCommunication = async (projectId: any, payload: any, channel = 'email') => {
  return post(`/api/v1/project/${projectId}/communication/test?channel=${channel}`,
    payload,
    {
      Authorization: `Bearer ${store.getState().user.token}`,
    }
  );
};
