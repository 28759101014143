import { Grid, ListItem, Select, Tooltip } from '@material-ui/core';
import React, { useState } from 'react';
import styled from 'styled-components';
import StatusesIcons from '../../assets/svg/StatusesIcons';
import TypeIcons, { colors } from '../../assets/svg/TypeIcons';
import VehicleIcons from '../../assets/svg/VehicleIcons';
import FormattedMessage from '../FormattedMessageCustom';

const StyledSelect = styled(Select).attrs({ classes: { root: 'root' } })`
  width: 65px;
  .root {
    padding-top: 4px;
    padding-bottom: 4px;
    padding-left: 4px;
  }
`;

const StyledSelectGrid = styled(Grid)`
  width: 65px;
  cursor: pointer;
  border-radius: 4px;
  &:hover: {
    background-color: #eeeff2;
  }
`;

const StyledListItem = styled(ListItem)`
  cursor: pointer;
  &:focus {
    outline: none;
  }
  :hover {
    background-color: #f1f1f1;
  }
`;

type FormIconProps = {
  fieldValue?: string;
  onUpdate: (...args: any[]) => any;
  type?: 'vehicle' | 'client' | 'status';
};

const FormIcon = ({ fieldValue, onUpdate, type }: FormIconProps) => {
  const [editing, setEditing] = useState(false);

  let array = TypeIcons;
  if (type === 'vehicle') array = VehicleIcons;
  if (type === 'status') array = StatusesIcons;
  const routesList = Object.keys(array).map((icon) => {
    let Element = TypeIcons[icon];
    if (type === 'vehicle') Element = VehicleIcons[icon];
    if (type === 'status') Element = StatusesIcons[icon];

    const color = colors[icon];
    return (
      // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
      <StyledListItem key={`icon-select-${icon}`} value={icon}>
        <Grid
          container
          alignItems="center"
          justify="center"
          style={{ backgroundColor: type === 'status' ? colors[`${icon}BG`] : 'transparent' }}
        >
          <Element style={{ color }} />
        </Grid>
      </StyledListItem>
    );
  });

  let Element;
  if (type === 'vehicle') {
    Element =
      fieldValue && fieldValue in VehicleIcons ? VehicleIcons[fieldValue] : VehicleIcons.truck;
  } else if (type === 'status') {
    Element =
      fieldValue && StatusesIcons && fieldValue in StatusesIcons
        ? 
          StatusesIcons[fieldValue]
        : StatusesIcons.planning;
  } else {
    Element = fieldValue && fieldValue in TypeIcons ? TypeIcons[fieldValue] : TypeIcons.normal;
  }
  const color = fieldValue ? colors[fieldValue] : colors.normal;

  const handleClick = async (evt: any) => {
    await onUpdate(evt.target.value);
    setEditing(false);
  };

  return editing ? (
    <StyledSelect
      variant="outlined"
      open={editing}
      value={fieldValue}
      onClose={() => setEditing(false)}
      onChange={handleClick}
      fullWidth
    >
      {routesList}
    </StyledSelect>
  ) : (
    <StyledSelectGrid container className="select-grid" direction="column" justify="center">
      <Tooltip title={<FormattedMessage id={`tooltip.select.${type}`} />}>
        <Grid container justify="center">
          <Element style={{ color }} onClick={() => setEditing(true)} />
        </Grid>
      </Tooltip>
    </StyledSelectGrid>
  );
};

FormIcon.defaultProps = {
  fieldValue: undefined,
};

FormIcon.defaultProps = {
  type: 'client',
  fieldValue: undefined,
};

export default FormIcon;
