import { Grid, Typography, withStyles } from '@material-ui/core';
import { Edit, KeyboardArrowLeft, KeyboardArrowRight } from '@material-ui/icons';
import { Button, VSpacer } from '@sm-highway-web/react-components';
import lodash from 'lodash';
import React, { Component } from 'react';
import { Droppable } from 'react-beautiful-dnd';
import { connect } from 'react-redux';
import { styles } from '../../../assets/css/PlanRouteMapCss';
import NoDriverIcon from '../../../assets/svg/NoDriverIcon';
import FormattedMessage from '../../FormattedMessageCustom';
import SelectorRoutesPlan from './plan-map-view/SelectorRoutesPlan';
import VehicleInfoSection from './plan-map-view/VehicleInfoSection';
import VirtualizedDraggableList from './plan-map-view/VirtualizedDraggableList';

type PlanMapListProps = {
  classes: {
    list: string;
    listItems: string;
    root: string;
    icon: string;
    padding: string;
    opener: string;
    divnosteps: string;
    divselector: string;
    smallbutton: string;
  };
  routes?: {
    steps?: {}[];
  }[];
  missing?: {}[];
  number: number;
  onChangeSelectedRoute: (...args: any[]) => any;
  selectedRoute: (string | number)[];
  selectedPoi: string[];
  openerList: boolean;
  onClickPoi: (...args: any[]) => any;
  onDoubleClickPoi: (...args: any[]) => any;
  openCloseVehicleDetailsModal: (...args: any[]) => any;
  isMapSelection: boolean;
};

class PlanMapList extends Component<PlanMapListProps> {
  static defaultProps: any;

  selectedDiv = React.createRef();

  componentDidUpdate = (prevProps: any) => {
    const { selectedPoi, isMapSelection } = this.props;
    if (!lodash.isEqual(prevProps.selectedPoi, selectedPoi) && !!isMapSelection) {
      this.scrollToRef();
    }
  };

  scrollToRef = () => {
    if (this.selectedDiv.current !== null) {
      // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
      this.selectedDiv.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    }
  };

  prevRoute = () => {
    const { selectedRoute, number, onChangeSelectedRoute, routes } = this.props;
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    let goTo = routes.length - 1;
    if (selectedRoute[number] !== 'missing') {
      if (selectedRoute[number] > 0) {
        // @ts-expect-error ts-migrate(2362) FIXME: The left-hand side of an arithmetic operation must... Remove this comment to see the full error message
        goTo = selectedRoute[number] - 1;
      } else {
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'number'.
        goTo = 'missing';
      }
    }
    onChangeSelectedRoute({ target: { value: goTo } }, number);
  };

  nextRoute = () => {
    const { selectedRoute, number, onChangeSelectedRoute, routes } = this.props;
    let goTo = 0;
    if (selectedRoute[number] !== 'missing') {
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      if (selectedRoute[number] === routes.length - 1) {
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'string' is not assignable to type 'number'.
        goTo = 'missing';
      } else {
        // @ts-expect-error ts-migrate(2365) FIXME: Operator '+' cannot be applied to types 'string | ... Remove this comment to see the full error message
        goTo = selectedRoute[number] + 1;
      }
    }
    onChangeSelectedRoute({ target: { value: goTo } }, number);
  };

  render() {
    const {
      classes,
      routes,
      missing,
      selectedRoute,
      openerList,
      onChangeSelectedRoute,
      number,
      openCloseVehicleDetailsModal,
      selectedPoi,
      onClickPoi,
      onDoubleClickPoi,
    } = this.props;
    return (
      <div
        className={classes.list}
        style={{
          width: openerList || number === 1 ? '50%' : '100%',
        }}
        id={`listservices${number}`}
      >
        {routes && routes.length > 0 ? (
          <>
            <Grid id={`selectorRoute${number}`} className={classes.divselector} container>
              <Grid item style={{ width: '70%' }}>
                <SelectorRoutesPlan
                  routes={routes}
                  paneNumber={number}
                  value={selectedRoute[number]}
                  onChangeSelectedRoute={onChangeSelectedRoute}
                />
              </Grid>
              {routes.length > 0 && (
                <>
                  <Button
                    variant="text"
                    className={classes.smallbutton}
                    color="secondary"
                    onClick={this.prevRoute}
                  >
                    <KeyboardArrowLeft />
                  </Button>
                  <Button
                    variant="text"
                    onClick={this.nextRoute}
                    className={classes.smallbutton}
                    color="secondary"
                  >
                    <KeyboardArrowRight />
                  </Button>
                </>
              )}
              {selectedRoute[number] !== 'missing' && (
                <Button
                  variant="text"
                  color="secondary"
                  className={classes.smallbutton}
                  onClick={() => {
                    if (selectedRoute[number] !== 'missing')
                      // @ts-expect-error ts-migrate(7015) FIXME: Element implicitly has an 'any' type because index... Remove this comment to see the full error message
                      openCloseVehicleDetailsModal(routes[selectedRoute[number]]);
                  }}
                >
                  <Edit style={{ width: '24px', height: '24px' }} />
                </Button>
              )}
            </Grid>
            <VehicleInfoSection
              // @ts-expect-error ts-migrate(2322) FIXME: Type '{ steps?: {}[] | undefined; }[]' is not assi... Remove this comment to see the full error message
              routes={routes}
              selectedRoute={selectedRoute[number]}
              // @ts-expect-error ts-migrate(2322) FIXME: Type '{}[] | undefined' is not assignable to type ... Remove this comment to see the full error message
              missing={missing}
              openCloseVehicleDetailsModal={openCloseVehicleDetailsModal}
            />
            {/* RENDER LISTS [ MISSING | NO ROUTES | COMMON ROUTE ] */}
            {selectedRoute[number] === 'missing' ? (
              // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
              missing.length > 0 ? (
                <div className={classes.listItems}>
                  <VirtualizedDraggableList
                    id={`${number}-droppable-missing`}
                    panel={number}
                    // @ts-expect-error ts-migrate(2322) FIXME: Type '{}[] | undefined' is not assignable to type ... Remove this comment to see the full error message
                    listItems={missing}
                    type="missing"
                    selectedPoi={selectedPoi}
                    onClickPoi={onClickPoi}
                    onDoubleClickPoi={onDoubleClickPoi}
                    selectedDiv={this.selectedDiv}
                  />
                </div>
              ) : (
                <div className={classes.listItems}>
                  <Droppable droppableId={`${number}-droppable-${selectedRoute[number]}`}>
                    {(provided: any, snapshot: any) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={{
                          borderRadius: '4px',
                          minHeight: 'calc(100% - 13px)',
                          padding: '8px 0px 1px 0px',
                          backgroundColor: snapshot.isDraggingOver
                            ? 'rgba(255,183,0,0.4)'
                            : 'transparent',
                        }}
                      >
                        <Grid
                          container
                          direction="column"
                          justify="center"
                          className={classes.divnosteps}
                          style={{
                            borderRadius: '4px',
                          }}
                        >
                          <Grid item>
                            <Typography align="center">
                              <FormattedMessage id="planmap.draganddropunassigned" />
                            </Typography>
                          </Grid>
                          <div style={{ display: 'none' }}>{provided.placeholder}</div>
                        </Grid>
                      </div>
                    )}
                  </Droppable>
                </div>
              )
            ) : (
              <>
                {/* @ts-expect-error ts-migrate(7015) FIXME: Element implicitly has an 'any' type because index... Remove this comment to see the full error message */}
                {routes[selectedRoute[number]].services.length > 0 ? (
                  <div className={classes.listItems}>
                    <VirtualizedDraggableList
                      id={`${number}-droppable-${selectedRoute[number]}`}
                      panel={number}
                      listItems={
                        routes &&
                        // @ts-expect-error ts-migrate(7015) FIXME: Element implicitly has an 'any' type because index... Remove this comment to see the full error message
                        routes[selectedRoute[number]] &&
                        // @ts-expect-error ts-migrate(7015) FIXME: Element implicitly has an 'any' type because index... Remove this comment to see the full error message
                        routes[selectedRoute[number]].services
                      }
                      type="normal"
                      selectedPoi={selectedPoi}
                      onClickPoi={onClickPoi}
                      onDoubleClickPoi={onDoubleClickPoi}
                      selectedDiv={this.selectedDiv}
                      isDragDisabled={
                        routes &&
                        // @ts-expect-error ts-migrate(7015) FIXME: Element implicitly has an 'any' type because index... Remove this comment to see the full error message
                        routes[selectedRoute[number]] &&
                        // @ts-expect-error ts-migrate(7015) FIXME: Element implicitly has an 'any' type because index... Remove this comment to see the full error message
                        routes[selectedRoute[number]].is_locked
                      }
                      route={
                        // @ts-expect-error ts-migrate(7015) FIXME: Element implicitly has an 'any' type because index... Remove this comment to see the full error message
                        routes && routes[selectedRoute[number]] && routes[selectedRoute[number]]
                      }
                      // @ts-expect-error ts-migrate(2322) FIXME: Type 'string | number' is not assignable to type '... Remove this comment to see the full error message
                      routeSelected={selectedRoute[number]}
                    />
                  </div>
                ) : (
                  <div className={classes.listItems}>
                    <Droppable droppableId={`${number}-droppable-${selectedRoute[number]}`}>
                      {(provided: any, snapshot: any) => (
                        <div
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                          style={{
                            borderRadius: '4px',
                            minHeight: 'calc(100% - 13px)',
                            padding: '8px 0px 1px 0px',
                            backgroundColor: snapshot.isDraggingOver
                              ? 'rgba(255,183,0,0.4)'
                              : 'transparent',
                          }}
                        >
                          <Grid
                            container
                            direction="column"
                            justify="center"
                            className={classes.divnosteps}
                            style={{
                              borderRadius: '4px',
                            }}
                          >
                            <Grid item>
                              <Typography align="center">
                                <FormattedMessage id="planmap.draganddrop" />
                              </Typography>
                            </Grid>
                            <div style={{ display: 'none' }}>{provided.placeholder}</div>
                          </Grid>
                        </div>
                      )}
                    </Droppable>
                  </div>
                )}
              </>
            )}
          </>
        ) : (
          // NO ROUTES AND SERVICES
          <div className={classes.padding}>
            <Grid container justify="center">
              <NoDriverIcon color="primary" className={classes.icon} />
            </Grid>
            <VSpacer />
            <Typography variant="h5" align="center">
              <FormattedMessage id="planmap.norouteandservices" />
            </Typography>
            <VSpacer />
            <Typography variant="h6" align="center">
              <FormattedMessage id="planmap.pleaseaddroutes" />
            </Typography>
          </div>
        )}
      </div>
    );
  }
}

PlanMapList.defaultProps = {
  routes: undefined,
  missing: undefined,
};

// @ts-expect-error ts-migrate(2345) FIXME: Argument of type '() => { divMap: { position: stri... Remove this comment to see the full error message
export default connect(null, null)(withStyles(styles)(PlanMapList));
