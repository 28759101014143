export const CLIENTS_ADD_CLIENT = 'CLIENTS_ADD_CLIENT';

export const CLIENTS_GET_FLAT_CLIENTS = 'CLIENTS_GET_FLAT_CLIENTS';
export const CLIENTS_GET_FLAT_CLIENTS_SUCCESS = 'CLIENTS_GET_FLAT_CLIENTS_SUCCESS';
export const CLIENTS_GET_FLAT_CLIENTS_ERROR = 'CLIENTS_GET_FLAT_CLIENTS_ERROR';

export const CLIENTS_GET_CLIENTS = 'CLIENTS_GET_CLIENTS';
export const CLIENTS_GET_CLIENTS_SUCCESS = 'CLIENTS_GET_CLIENTS_SUCCESS';
export const CLIENTS_GET_CLIENTS_ERROR = 'CLIENTS_GET_CLIENTS_ERROR';

export const CLIENTS_CREATE_CLIENT = 'CLIENTS_CREATE_CLIENT';
export const CLIENTS_CREATE_CLIENT_SUCCESS = 'CLIENTS_CREATE_CLIENT_SUCCESS';
export const CLIENTS_CREATE_CLIENT_ERROR = 'CLIENTS_CREATE_CLIENT_ERROR';

export const CLIENTS_CREATE_CLIENTS = 'CLIENTS_CREATE_CLIENTS';
export const CLIENTS_CREATE_CLIENTS_SUCCESS = 'CLIENTS_CREATE_CLIENTS_SUCCESS';
export const CLIENTS_CREATE_CLIENTS_ERROR = 'CLIENTS_CREATE_CLIENTS_ERROR';

export const CLIENTS_UPDATE_CLIENT = 'CLIENTS_UPDATE_CLIENT';
export const CLIENTS_UPDATE_CLIENT_SUCCESS = 'CLIENTS_UPDATE_CLIENT_SUCCESS';
export const CLIENTS_UPDATE_CLIENT_ERROR = 'CLIENTS_UPDATE_CLIENT_ERROR';

export const CLIENTS_DELETE_CLIENT = 'CLIENTS_UPDATE_CLIENT';
export const CLIENTS_DELETE_CLIENT_SUCCESS = 'CLIENTS_DELETE_CLIENT_SUCCESS';
export const CLIENTS_DELETE_CLIENT_ERROR = 'CLIENTS_DELETE_CLIENT_ERROR';

export const CLIENTS_GET_CLIENT = 'CLIENTS_GET_CLIENT';
export const CLIENTS_GET_CLIENT_SUCCESS = 'CLIENTS_GET_CLIENT_SUCCESS';
export const CLIENTS_GET_CLIENT_ERROR = 'CLIENTS_GET_CLIENT_ERROR';

export const CLIENTS_SERVICE_AS_CLIENT = 'CLIENTS_SERVICE_AS_CLIENT';
export const CLIENTS_SERVICE_AS_CLIENT_SUCCESS = 'CLIENTS_SERVICE_AS_CLIENT_SUCCESS';
export const CLIENTS_SERVICE_AS_CLIENT_ERROR = 'CLIENTS_SERVICE_AS_CLIENT_ERROR';

export const CLIENTS_SET_SELECTED_CLIENTS = 'CLIENTS_SET_SELECTED_CLIENTS';
export const CLIENTS_REMOVE_SELECTED = 'CLIENTS_REMOVE_SELECTED';
