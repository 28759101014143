import React, { useEffect, useState } from 'react';
import Joyride, { ACTIONS } from 'react-joyride';
import { connect } from 'react-redux';
import { Button } from '@sm-highway-web/react-components';
import OnboardingTutorialDialog from '../../dialogs/OnboardingTutorialDialog';
import { Tutorial } from '../../redux/actions';
import FormattedMessage from '../FormattedMessageCustom';
import CustomTooltip, { stepDarkBgColor, stepLighBgColor } from './CustomTooltip';

export const ONBOARDING_STEPS = {
  DIALOG1: 0,
  CREATE_PLAN_BUTTON: 1,
  OPTIMIZE_BUTTON: 2,
  DISPATCH_BUTTON: 3,
  DISPATCH_DIALOG: 4,
  PLAN_LIST_TABS: 5,
  PLAN_LIST_VIEWS_BUTTON_GROUP: 6,
  DIALOG2: 7,
};

export const ONBOARDING_TUTORIAL_NAME = 'onboarding';

type OwnOnboardingTutorialProps = {
  actions: {
    endTutorial: (...args: any[]) => any;
    setStep: (...args: any[]) => any;
  };
  plans: {
    docs?: any[]; // TODO: PropTypes.shape
  };
  currentTutorial: {
    id?: any; // TODO: PropTypes.string | PropTypes.undefined
    step?: number;
  };
};

type OnboardingTutorialProps = OwnOnboardingTutorialProps;

const OnboardingTutorial = ({ actions, plans, currentTutorial }: OnboardingTutorialProps) => {
  const [runTutorial, setRunTutorial] = useState(false);
  const [tutorialStep, setTutorialStep] = useState(0);

  useEffect(() => {
    // Onboarding tutorial needs to be initialized in the plans list without any plan created.
    if (plans && plans.docs) {
      if (currentTutorial && currentTutorial.id === ONBOARDING_TUTORIAL_NAME) {
        if (currentTutorial.step !== ONBOARDING_STEPS.DIALOG1 || plans.docs.length === 0) {
          if (!runTutorial) {
            setRunTutorial(true);
          }
          if (tutorialStep !== currentTutorial.step) {
            // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'number | undefined' is not assig... Remove this comment to see the full error message
            setTutorialStep(currentTutorial.step);
          }
        } else {
          actions.endTutorial();
          setRunTutorial(false);
        }
      }
    }
  }, [actions, plans, currentTutorial, runTutorial, tutorialStep]);

  const tutorialSteps = [
    {
      target: '#create-example-plan-button',
      placement: 'bottom',

      // Custom props
      title: <FormattedMessage id="tutorials.onboarding.step-1.title" />,
      content: <FormattedMessage id="tutorials.onboarding.step-1.info" />,
    },
    {
      target: '#optimize-button',
      placement: 'top',

      // Custom props
      title: <FormattedMessage id="tutorials.onboarding.step-2.title" />,
      content: <FormattedMessage id="tutorials.onboarding.step-2.info" />,
    },
    {
      target: '#dispatch-button',
      placement: 'bottom',

      // Custom props
      title: <FormattedMessage id="tutorials.onboarding.step-3.title" />,
      content: <FormattedMessage id="tutorials.onboarding.step-3.info" />,
    },
    {
      target: '#dispatch-dialog',
      placement: 'bottom',

      // Custom props
      title: <FormattedMessage id="tutorials.onboarding.step-4.title" />,
      content: (
        <>
          <FormattedMessage id="tutorials.onboarding.step-4.info" />

          <Button
            style={{ marginTop: '20px', color: 'white' }}
            variant="contained"
            onClick={() => {
              actions.setStep(ONBOARDING_STEPS.PLAN_LIST_TABS);
            }}
            color="primary"
          >
            <FormattedMessage id="tutorials.next" />
          </Button>
        </>
      ),
    },
    {
      target: '#plan-list-tabs',
      placement: 'right',

      // Custom props
      title: <FormattedMessage id="tutorials.onboarding.step-5.title" />,
      content: (
        <>
          <FormattedMessage id="tutorials.onboarding.step-5.info" />

          <Button
            style={{ marginTop: '20px', color: 'white' }}
            variant="contained"
            onClick={() => {
              actions.setStep(ONBOARDING_STEPS.PLAN_LIST_VIEWS_BUTTON_GROUP);
            }}
            color="primary"
          >
            <FormattedMessage id="tutorials.next" />
          </Button>
        </>
      ),
      theme: 'light',
      styles: {
        options: {
          arrowColor: stepLighBgColor,
        },
      },
    },
    {
      target: '#plan-list-views-tabs',
      placement: 'right',

      // Custom props
      title: <FormattedMessage id="tutorials.onboarding.step-6.title" />,
      content: (
        <>
          <FormattedMessage id="tutorials.onboarding.step-6.info" />

          <Button
            style={{ marginTop: '20px', color: 'white' }}
            variant="contained"
            onClick={() => {
              actions.setStep(ONBOARDING_STEPS.DIALOG2);
            }}
            color="primary"
          >
            <FormattedMessage id="tutorials.onboarding.step-6.end-button" />
          </Button>
        </>
      ),
      theme: 'light',
      styles: {
        options: {
          arrowColor: stepLighBgColor,
        },
      },
    },
  ];

  const TOTAL_STEPS = tutorialSteps.length;

  return (
    <>
      <OnboardingTutorialDialog
        open={
          runTutorial &&
          (tutorialStep === ONBOARDING_STEPS.DIALOG1 || tutorialStep === ONBOARDING_STEPS.DIALOG2)
        }
        onSkip={() => {
          actions.endTutorial();
          setRunTutorial(false);
        }}
        step={tutorialStep}
        onNextStep={() => {
          switch (tutorialStep) {
            case ONBOARDING_STEPS.DIALOG1:
              actions.setStep(ONBOARDING_STEPS.CREATE_PLAN_BUTTON);
              break;
            case ONBOARDING_STEPS.DIALOG2:
              actions.endTutorial();
              setRunTutorial(false);
              break;
            default:
              break;
          }
        }}
        onClose={() => {
          switch (tutorialStep) {
            case ONBOARDING_STEPS.DIALOG1:
              actions.setStep(ONBOARDING_STEPS.CREATE_PLAN_BUTTON);
              break;
            case ONBOARDING_STEPS.DIALOG2:
              actions.endTutorial();
              setRunTutorial(false);
              break;
            default:
              break;
          }
        }}
      />

      <Joyride
        tooltipComponent={CustomTooltip}
        locale={{
          back: <FormattedMessage id="tutorials.back" />,
          close: <FormattedMessage id="tutorials.close" />,
          last: <FormattedMessage id="tutorials.last" />,
          next: <FormattedMessage id="tutorials.next" />,
          skip: <FormattedMessage id="tutorials.skip" />,
        }}
        run={runTutorial && tutorialStep > 0}
        callback={(data) => {
          const { action, index } = data;

          // Last one before final dialog.
          if (index === 5 && action === ACTIONS.SKIP) {
            actions.setStep(ONBOARDING_STEPS.DIALOG2);
          } else if (action === ACTIONS.CLOSE || action === ACTIONS.SKIP) {
            actions.endTutorial();
          }
        }}
        styles={{
          options: {
            arrowColor: stepDarkBgColor,
            backgroundColor: stepDarkBgColor,
            zIndex: 1299, // Material design dialogs has z-index: 1300
          },
        }}
        stepIndex={tutorialStep - 1}
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        steps={tutorialSteps.map((step) => ({
          ...step,
          skip: true,
          totalIndex: TOTAL_STEPS,
          hideCloseButton: true,
          disableOverlay: true,
          disableOverlayClose: true,
          disableBeacon: true,
          theme: step.theme ? step.theme : 'dark',
          skipTitle: <FormattedMessage id="tutorials.skip" />,
        }))}
      />
    </>
  );
};

OnboardingTutorial.defaultProps = {};

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    endTutorial: () => dispatch(Tutorial.end(ONBOARDING_TUTORIAL_NAME)),
    setStep: (step: any) => dispatch(Tutorial.setStep(ONBOARDING_TUTORIAL_NAME, step)),
  },
});

const mapStateToProps = (state: any) => {
  return {
    plans: state.plans.plans,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OnboardingTutorial);
