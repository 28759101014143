import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { Button } from '@sm-highway-web/react-components';
import React from 'react';
import FormattedMessage from '../components/FormattedMessageCustom';

type ConfirmDialogProps = {
  title: string | React.ReactElement;
  description: string | React.ReactElement;
  open: boolean;
  setDialog: (...args: any[]) => any;
  handleConfirm: (...args: any[]) => any;
};

const ConfirmDialog = ({ open, title, description, setDialog, handleConfirm }: ConfirmDialogProps) => (
  <Dialog open={open} onClose={() => setDialog(undefined)}>
    <DialogTitle>{title}</DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description">{description}</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={() => setDialog(undefined)} color="primary">
        <FormattedMessage id="cancel" />
      </Button>
      <Button
        variant="contained"
        onClick={() => {
          handleConfirm();
        }}
        color="primary"
        autoFocus
      >
        <FormattedMessage id="confirm" />
      </Button>
    </DialogActions>
  </Dialog>
);

export default ConfirmDialog;
