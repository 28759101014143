import { Divider, Grid, MenuItem, Popover, Select, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Button, VSpacer } from '@sm-highway-web/react-components';
import { CustomFieldValueTypes, IProjectData } from 'highway-api/dist/common/interfaces/projects';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import BooleanIcon from '../../../assets/img/custom_field-boolean.svg';
import CategoricalIcon from '../../../assets/img/custom_field-categorical.svg';
import LocationIcon from '../../../assets/img/custom_field-location.svg';
import NumericalIcon from '../../../assets/img/custom_field-numerical.svg';
import PictureIcon from '../../../assets/img/custom_field-picture.svg';
import SignatureIcon from '../../../assets/img/custom_field-signature.svg';
import TextIcon from '../../../assets/img/custom_field-text.svg';
import TimewindowsIcon from '../../../assets/img/custom_field-timewindows.svg';
import AddProjectCustomFieldDialog from '../../../dialogs/AddProjectCustomFieldDialog';
import { ObjectOfAny } from '../../../interfaces/common';
import { Projects } from '../../../redux/actions';
import ProjectCustomField from '../../custom-fields/ProjectCustomField';
import FormattedMessage from '../../FormattedMessageCustom';

const styles = {
  input: {
    padding: '8px',
  },
};

const createFieldTypes: { [key in CustomFieldValueTypes]: any } = {
  text: {
    iconSrc: TextIcon,
  } as ObjectOfAny,
  boolean: {
    iconSrc: BooleanIcon,
  } as ObjectOfAny,
  numerical: {
    iconSrc: NumericalIcon,
  } as ObjectOfAny,
  categorical: {
    iconSrc: CategoricalIcon,
  } as ObjectOfAny,
};

const settingsConstraints: ObjectOfAny = {
  // NOT YET.
  // distance: {
  //   iconSrc: NumericalIcon
  // } as ObjectOfAny,
  weight: {
    iconSrc: NumericalIcon,
  } as ObjectOfAny,
  volume: {
    iconSrc: NumericalIcon,
  } as ObjectOfAny,
  timewindows: {
    iconSrc: TimewindowsIcon,
  } as ObjectOfAny,
  provide_requires: {
    iconSrc: BooleanIcon,
  } as ObjectOfAny,
  max_services: {
    iconSrc: NumericalIcon,
  } as ObjectOfAny,
  pickups: {
    iconSrc: BooleanIcon,
  } as ObjectOfAny,
};

const sectionsDescriptionFields: ObjectOfAny = {
  vehicle: {
    external_id: {
      iconSrc: TextIcon,
    } as ObjectOfAny,
    plate: {
      iconSrc: TextIcon,
    } as ObjectOfAny,
    comments: {
      iconSrc: TextIcon,
    } as ObjectOfAny,
    email: {
      iconSrc: TextIcon,
    } as ObjectOfAny,
    phone: {
      iconSrc: TextIcon,
    } as ObjectOfAny,
    webpage: {
      iconSrc: TextIcon,
    } as ObjectOfAny,
    price_per_minute: {
      iconSrc: NumericalIcon,
    } as ObjectOfAny,
    price_per_distance: {
      iconSrc: NumericalIcon,
    } as ObjectOfAny,
  },
  client: {
    external_id: {
      iconSrc: TextIcon,
    } as ObjectOfAny,
    location_details: {
      iconSrc: LocationIcon,
    } as ObjectOfAny,
    comments: {
      iconSrc: TextIcon,
    } as ObjectOfAny,
    email: {
      iconSrc: TextIcon,
    } as ObjectOfAny,
    reference_person: {
      iconSrc: TextIcon,
    } as ObjectOfAny,
    phone: {
      iconSrc: TextIcon,
    } as ObjectOfAny,
    webpage: {
      iconSrc: TextIcon,
    } as ObjectOfAny,
    optional: {
      iconSrc: BooleanIcon,
    } as ObjectOfAny,
  } as ObjectOfAny,
  webapp: {
    external_id: {
      iconSrc: TextIcon,
    } as ObjectOfAny,
    comments: {
      iconSrc: TextIcon,
    } as ObjectOfAny,
    reference_person: {
      iconSrc: TextIcon,
    } as ObjectOfAny,
    phone: {
      iconSrc: TextIcon,
    } as ObjectOfAny,
    webpage: {
      iconSrc: TextIcon,
    } as ObjectOfAny,
  } as ObjectOfAny,
  service_report_completed: {
    comments: {
      iconSrc: TextIcon,
    } as ObjectOfAny,
    pictures: {
      iconSrc: PictureIcon,
    } as ObjectOfAny,
    signature: {
      iconSrc: SignatureIcon,
    } as ObjectOfAny,
  } as ObjectOfAny,
  service_report_canceled: {
    comments: {
      iconSrc: TextIcon,
    } as ObjectOfAny,
    pictures: {
      iconSrc: PictureIcon,
    } as ObjectOfAny,
    canceled_categorical: {
      iconSrc: CategoricalIcon,
    } as ObjectOfAny,
  } as ObjectOfAny,
};

type OrganizationProjectsEditFieldsProps = {
  classes: {
    input: string;
  };
  actions: {
    editProject: (...args: any[]) => any;
    createCustomField: (...args: any[]) => any;
    updateCustomField: (...args: any[]) => any;
    removeCustomField: (...args: any[]) => any;
  };
  project: IProjectData;
};

const OrganizationProjectsEditFields = ({
  project,
  classes,
  actions,
}: OrganizationProjectsEditFieldsProps) => {
  const [section, setSection] = useState<string>(Object.keys(sectionsDescriptionFields)[0]);
  const [constraints, setConstraints] = useState({});
  const [openPopover, setOpenPopover] = useState(null);
  const [createFieldDialogType, setCreateFieldDialogType] = useState<CustomFieldValueTypes | null>(null);
  const [descriptionFields, setDescriptionFields] = useState<ObjectOfAny>({
    vehicle: {},
    client: {},
    webapp: {},
    service_report_completed: {},
    service_report_canceled: {},
  });

  useEffect(() => {
    if (project) {
      if (project.view) {
        setConstraints(project.view.constraints || {});
        setDescriptionFields({
          vehicle: project.view.vehicle || {},
          client: project.view.client || {},
          webapp: project.view.webapp || {},
          service_report_completed: project.view.service_report_completed || {},
          service_report_canceled: project.view.service_report_canceled || {},
        });
      }
    }
  }, [project]);

  if (!project) return null;

  return (
    <Grid container direction="column" alignItems="center">
      <Grid item container direction="column" style={{ maxWidth: '600px' }}>
        <Grid item container style={{ padding: '8px', borderBottom: '1px solid #D7DAE2' }}>
          <Grid
            item
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            wrap="nowrap"
          >
            <Grid item container wrap="nowrap" justify="space-between" alignItems="center">
              <Typography variant="h2">
                <FormattedMessage id="settings.organization.projects.edit.fields.title" />
              </Typography>
            </Grid>
          </Grid>
        </Grid>

        <VSpacer large />

        <Grid item>
          <FormattedMessage id="settings.organization.projects.edit.fields.description" />
        </Grid>

        <VSpacer />

        <Grid item container direction="column" alignContent="flex-end">
          <Select
            variant="outlined"
            style={{ width: '300px', padding: '0' }}
            value={section}
            classes={{ select: classes.input }}
            onChange={(evt) => {
              // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
              setSection(evt.target.value);
            }}
          >
            <MenuItem value="vehicle">
              <FormattedMessage id="settings.organization.projects.edit.fields.sections.vehicles" />
            </MenuItem>
            <MenuItem value="client">
              <FormattedMessage id="settings.organization.projects.edit.fields.sections.clients" />
            </MenuItem>
            <MenuItem value="webapp">
              <FormattedMessage id="settings.organization.projects.edit.fields.sections.webapp" />
            </MenuItem>
            <MenuItem value="service_report_completed">
              <FormattedMessage id="settings.organization.projects.edit.fields.sections.service_report_completed" />
            </MenuItem>
            <MenuItem value="service_report_canceled">
              <FormattedMessage id="settings.organization.projects.edit.fields.sections.service_report_canceled" />
            </MenuItem>
          </Select>
        </Grid>

        <VSpacer />

        {section === 'vehicle' || section === 'client' ? (
          <>
            <Grid item>
              <Typography variant="h5">
                <FormattedMessage id="settings.organization.projects.edit.fields.constraints" />
              </Typography>
            </Grid>

            <VSpacer small />

            <Divider />

            <VSpacer medium />

            {Object.keys(settingsConstraints).map((setting) => (
              <ProjectCustomField
                key={`gridconitemconstraintes${setting}`}
                field={{
                  label: (
                    <FormattedMessage
                      id={`settings.organization.projects.edit.fields.constraints.${setting}`}
                    />
                  ),
                  description: (
                    <FormattedMessage
                      id={`settings.organization.projects.edit.fields.constraints.${setting}.description`}
                    />
                  ),
                  type: 'boolean',
                  // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                  enabled: constraints[setting] || false,
                }}
                iconSrc={settingsConstraints[setting].iconSrc}
                onUpdate={(value: any) => {
                  actions.editProject(project.id, {
                    view: {
                      ...project.view,
                      constraints: {
                        ...constraints,
                        ...{ [`${setting}`]: value.enabled },
                      },
                    },
                  });
                }}
              />
            ))}

            <VSpacer />
          </>
        ) : null}

        <Grid item>
          <Typography variant="h5">
            <FormattedMessage id="settings.organization.projects.edit.fields.description_fields" />
          </Typography>
        </Grid>

        <VSpacer small />

        <Divider />

        <VSpacer medium />

        {Object.keys(sectionsDescriptionFields[section]).map((setting) => (
          <ProjectCustomField
            key={`gridconitem${section}${setting}`}
            field={{
              label: (
                <FormattedMessage
                  id={`settings.organization.projects.edit.fields.${section}.${setting}`}
                />
              ),
              description: (
                <FormattedMessage
                  id={`settings.organization.projects.edit.fields.${section}.${setting}.description`}
                  values={setting === 'price_per_distance' ? {
                    unit: project?.units?.distance,
                  } : {}}
                />
              ),
              type: 'boolean',
              enabled: descriptionFields[section][setting] || false,
            }}
            iconSrc={sectionsDescriptionFields[section][setting].iconSrc}
            onUpdate={(value: any) => {
              actions.editProject(project.id, {
                view: {
                  ...project.view,
                  [section]: {
                    ...descriptionFields[section],
                    ...{ [`${setting}`]: value.enabled },
                  },
                },
              });
            }}
          />
        ))}

        <VSpacer />

        {section !== 'webapp' && (
          <>
            <Grid item container wrap="nowrap" justify="space-between" alignItems="center">
              <Typography variant="h5">
                <FormattedMessage id="settings.organization.projects.edit.fields.custom_fields" />
              </Typography>

              <Button
                variant="contained"
                onClick={(event: any) => {
                  setOpenPopover(event.currentTarget);
                  event.stopPropagation();
                }}
              >
                <FormattedMessage id="settings.organization.projects.edit.fields.custom_fields.dialog.buttoncreate" />
              </Button>

              <Popover
                anchorEl={openPopover}
                keepMounted
                open={Boolean(openPopover)}
                onClose={() => setOpenPopover(null)}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
                transformOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
              >
                <div style={{ width: '200px', padding: '8px 0' }}>
                  {Object.keys(createFieldTypes).map((fieldType: string) => (
                    <MenuItem
                      key={fieldType}
                      onClick={() => {
                        setOpenPopover(null);
                        setCreateFieldDialogType(fieldType as CustomFieldValueTypes);
                      }}
                    >
                      <img
                        alt=""
                        src={createFieldTypes[fieldType as CustomFieldValueTypes].iconSrc}
                        width="20px"
                        style={{ marginRight: '8px' }}
                      />
                      <Typography>
                        <FormattedMessage
                          id={`settings.organization.projects.edit.fields.custom_fields.types.${fieldType}`}
                        />
                      </Typography>
                    </MenuItem>
                  ))}
                </div>
              </Popover>
            </Grid>

            <VSpacer small />

            <Divider />

            <VSpacer medium />

            {/* @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'. */}
            {project.custom_fields[section].length > 0 ? (
              // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
              project.custom_fields[section].map((field: any) => (
                <ProjectCustomField
                  key={field.id}
                  field={field}
                  editable
                  onUpdate={(updatedField: any) => {
                    // Avoiding to update the custom field ID.
                    const { id, ...other } = updatedField;
                    actions.updateCustomField(project.id, field.id, section, other);
                  }}
                  onRemove={() => {
                    actions.removeCustomField(project.id, field.id, section);
                  }}
                />
              ))
            ) : (
              <FormattedMessage id="settings.organization.projects.edit.fields.custom_fields.no_fields" />
            )}

            <VSpacer />

            <VSpacer />

            {createFieldDialogType !== null ? (
              <AddProjectCustomFieldDialog
                type={createFieldDialogType as CustomFieldValueTypes}
                open={createFieldDialogType !== null}
                onCloseDialog={() => {
                  setCreateFieldDialogType(null);
                }}
                handleCreateCustomField={(field) => {
                  setCreateFieldDialogType(null);
                  actions.createCustomField(project.id, section, field);
                }}
              />
            ) : null}
          </>
        )}
      </Grid>
    </Grid>
  );
};

OrganizationProjectsEditFields.defaultProps = {};

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    editProject: (projectId: any, projectData: any) =>
      dispatch(Projects.editProject(projectId, projectData)),
    createCustomField: (projectId: any, type: any, fieldData: any) =>
      dispatch(Projects.createCustomField(projectId, type, fieldData)),
    updateCustomField: (projectId: any, customFieldId: any, type: any, fieldData: any) =>
      dispatch(Projects.updateCustomField(projectId, customFieldId, type, fieldData)),
    removeCustomField: (projectId: any, customFieldId: any, type: any) =>
      dispatch(Projects.removeCustomField(projectId, customFieldId, type)),
  },
});

const mapStateToProps = (state: any) => {
  return { user: state.user.user };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(OrganizationProjectsEditFields));
