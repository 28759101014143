import { TableCell, TableSortLabel, withStyles } from '@material-ui/core';
import React from 'react';

const styles = () => ({
  tableHead: {
    backgroundColor: 'white',
    fontSize: '14px',
    textTransform: 'uppercase',
    color: '#8194B8',
    fontWeight: 500,
  },
  tableCell: {
    padding: '6px 12px 6px 12px',
  },
});

type OwnTableCellHeaderProps = {
  classes: {
    tableCell: string;
    tableHead: string;
  };
  entry: {
    column?: string;
    style?: any;
    align?: string;
    sortable?: boolean;
    name?: string | React.ReactElement;
  };
  order?: 'asc' | 'desc';
  onColumnSort: (...args: any[]) => any;
};

type TableCellHeaderProps = OwnTableCellHeaderProps;

const TableCellHeader = ({ classes, entry, order, onColumnSort }: TableCellHeaderProps) => {
  const toggleOrder = () => {
    if (!order || order === 'asc') {
      return 'desc';
    }
    return 'asc';
  };
  return (
    <TableCell
      component="div"
      className={classes.tableCell}
      classes={{ head: classes.tableHead }}
      // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
      align={entry.align}
      style={{
        ...(entry.style !== undefined ? entry.style : {}),
      }}
    >
      {entry.sortable === true ? (
        <TableSortLabel direction={order} onClick={() => onColumnSort(entry.column, toggleOrder())}>
          {entry.name}
        </TableSortLabel>
      ) : (
        <>{entry.name}</>
      )}
    </TableCell>
  );
};

TableCellHeader.defaultProps = {
  order: undefined,
};

// @ts-expect-error ts-migrate(2345) FIXME: Argument of type '() => { tableHead: { backgroundC... Remove this comment to see the full error message
export default withStyles(styles)(TableCellHeader);
