import React from 'react';
import styled from 'styled-components';

// const MOBILE_WIDTH = 360;
// const MOBILE_HEIGHT = 640;

const ASPECT_RATIO = 0.5025;
const MOBILE_HEIGHT = 470;
const MOBILE_WIDTH = Math.round(ASPECT_RATIO * MOBILE_HEIGHT);

export const SmartphoneContainer = styled.div`
  position: relative;
  width: ${MOBILE_WIDTH}px;
  height: ${MOBILE_HEIGHT}px;
  margin: auto;
  border: 10px #444 solid;
  border-top-width: 10px;
  border-bottom-width: 10px;
  border-radius: 32px;
  background-color: white;

  /* The circle on the bottom of the device */
  :before {
    content: '';
    display: block;
    width: 120px;
    height: 20px;
    position: absolute;
    left: 50%;  
    top: 0px;
    transform: translate(-50%, -50%);
    background: #444;
    border-radius: 12px;
  }
  /* The horizontal line on the top of the device */
  :after {
    content: '';
    display: block;
    width: 60px;
    height: 5px;
    position: absolute;
    top: 0px;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #333;
    border-radius: 10px;
  }
`;

/* The screen (or content) of the device */
export const SmartphoneContent = styled.div`
  .smartphone .content {
    width: ${MOBILE_WIDTH}px;
    height: ${MOBILE_HEIGHT}px;
    background: white;
  }
`;

type MobileMockupProps = {
  content?: React.ReactElement;
};

const MobileMockup = ({ content }: MobileMockupProps) => {

  return (
    <SmartphoneContainer>
      <SmartphoneContent>
        {content}
      </SmartphoneContent>
    </SmartphoneContainer>
  );
};

MobileMockup.propType = {
  content: undefined,
};

export default MobileMockup;
