import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  DefaultAvatar,
  Form,
  FormControl,
  InputText,
  VSpacer,
} from '@sm-highway-web/react-components';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { InfoError, Organization } from '../../../redux/actions';
import { organizationHelpers, projectHelpers } from '../../../redux/helpers';
import Avatar from '../../Avatar';
import CustomSelect from '../../CustomSelect';
import FormattedMessage from '../../FormattedMessageCustom';

const styles = {
  dialogRoot: {
    maxWidth: '700px',
  },
  select: {
    padding: '8px',
    minHeight: '24px',
  },
  selectMenu: {
    '& .MuiMenu-list': {
      padding: '0',
    },
  },
};

type OwnOrganizationNewUserDialogProps = {
  classes: {
    dialogRoot: string;
    select: string;
    selectMenu: string;
  };
  actions: {
    createOrganizationUser: (...args: any[]) => any;
    sendStatusToSnackbar?: (...args: any[]) => any;
  };
  onClose: (...args: any[]) => any;
  isOpen?: boolean;
  projects?: {}[];
  refreshProjects?: boolean;
  selectedProjectId?: string;
  organizationLabel?: string;
};

type OrganizationNewUserDialogProps = OwnOrganizationNewUserDialogProps;

const OrganizationNewUserDialog = ({
  classes,
  projects,
  isOpen,
  onClose,
  actions,
  selectedProjectId,
  organizationLabel,
  refreshProjects,
}: OrganizationNewUserDialogProps) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [role, setRole] = useState(
    organizationHelpers.OrganizationRoles.organization_administrator
  );
  const [email, setEmail] = useState('');
  const [disabledSubmitButton, setDisabledSubmitButton] = useState(false);
  const [assignedProjects, setAssignedProjects] = useState([]);

  useEffect(() => {
    if (projects && selectedProjectId) {
      setAssignedProjects([
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'any' is not assignable to type 'never'.
        projects
          .map((project: any) => project.id)
          .find((project: any) => project === selectedProjectId),
      ]);
    }
  }, [selectedProjectId, projects, setAssignedProjects]);

  const closeDialog = () => {
    setFirstName('');
    setLastName('');
    setRole(organizationHelpers.OrganizationRoles.organization_administrator);
    setEmail('');
    setAssignedProjects([]);
    setDisabledSubmitButton(false);
    onClose();
  };

  const handleCreateUser = async () => {
    if (assignedProjects && assignedProjects.length > 0) {
      setDisabledSubmitButton(true);

      await actions.createOrganizationUser(
        email,
        firstName,
        lastName,
        role,
        assignedProjects,
        refreshProjects
      );

      setTimeout(() => closeDialog(), 500);
    } else {
      // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
      actions.sendStatusToSnackbar(
        'infoerror.new_organization_user.assigned_projects_needed',
        'error'
      );
    }
  };

  return (
    <Dialog classes={{ paper: classes.dialogRoot }} open={!!isOpen} onClose={closeDialog}>
      <DialogTitle>
        <FormattedMessage id="settings.organization.users.new.title" />
      </DialogTitle>
      <DialogContent>
        <Grid container direction="column">
          <Form lazyValidation onSubmit={handleCreateUser} direction="column">
            <InputText
              id="first_name"
              type="text"
              label={<FormattedMessage id="settings.organization.users.new.first_name" />}
              value={firstName}
              onChange={(value: any) => setFirstName(value)}
              margin="none"
              validators={['required']}
              errorMessages={[
                <FormattedMessage
                  key="settings.error.field_mandatory"
                  id="settings.error.field_mandatory"
                />,
              ]}
            />

            <InputText
              id="lastName"
              type="text"
              label={<FormattedMessage id="settings.organization.users.new.last_name" />}
              value={lastName}
              onChange={(value: any) => setLastName(value)}
              margin="none"
              validators={['required']}
              errorMessages={[
                <FormattedMessage
                  key="settings.error.field_mandatory_2"
                  id="settings.error.field_mandatory"
                />,
              ]}
            />

            <InputText
              id="email"
              type="email"
              label={<FormattedMessage id="login.email" />}
              value={email}
              onChange={(value: any) => setEmail(value)}
              margin="none"
              validators={['required', 'email']}
              errorMessages={[
                <FormattedMessage
                  key="settings.error.invalid_email"
                  id="settings.error.invalid_email"
                />,
              ]}
            />

            <VSpacer medium />

            <Grid container spacing={1}>
              <Grid xs={12} md={6} item container direction="column">
                <Typography style={{ marginBottom: '12px' }}>
                  <FormattedMessage id="settings.organization.users.organization_role" />
                </Typography>

                <CustomSelect
                  value={role}
                  width={300}
                  disabled={disabledSubmitButton}
                  selectItems={[
                    // @ts-expect-error ts-migrate(2322) FIXME: Type '{ value: string; title: JSX.Element; info: J... Remove this comment to see the full error message
                    {
                      value: organizationHelpers.OrganizationRoles.organization_administrator,
                      title: (
                        <FormattedMessage id="settings.organization.users.roles.administrator" />
                      ),
                      info: (
                        <FormattedMessage id="settings.organization.users.roles.administrator.description" />
                      ),
                    },
                    // @ts-expect-error ts-migrate(2322) FIXME: Type '{ value: string; title: JSX.Element; info: J... Remove this comment to see the full error message
                    {
                      value: organizationHelpers.OrganizationRoles.organization_manager,
                      title: <FormattedMessage id="settings.organization.users.roles.manager" />,
                      info: (
                        <FormattedMessage id="settings.organization.users.roles.manager.description" />
                      ),
                    },
                    // @ts-expect-error ts-migrate(2322) FIXME: Type '{ value: string; title: JSX.Element; info: J... Remove this comment to see the full error message
                    {
                      value: organizationHelpers.OrganizationRoles.organization_user,
                      title: <FormattedMessage id="settings.organization.users.roles.user" />,
                      info: (
                        <FormattedMessage id="settings.organization.users.roles.user.description" />
                      ),
                    },
                  ]}
                  onChange={(value: any) => {
                    setRole(value);
                  }}
                />
              </Grid>

              <Grid xs={12} md={6} item container direction="column">
                <Typography style={{ marginBottom: '12px' }}>
                  <FormattedMessage id="settings.organization.users.assigned_projects" />
                </Typography>

                <Select
                  multiple
                  variant="outlined"
                  style={{ width: '300px', padding: '0' }}
                  value={assignedProjects}
                  classes={{ select: classes.select }}
                  MenuProps={{
                    className: classes.selectMenu,
                  }}
                  onChange={(evt) => {
                    // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'unknown' is not assignable to pa... Remove this comment to see the full error message
                    setAssignedProjects(evt.target.value);
                  }}
                  renderValue={(selection) => (
                    <FormattedMessage
                      id="settings.organization.users.assigned_projects_selected"
                      values={{
                        // @ts-expect-error ts-migrate(2571) FIXME: Object is of type 'unknown'.
                        projects: selection.length,
                      }}
                    />
                  )}
                >
                  {/* @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'. */}
                  {projects?.map((project: IProjectData) => {
                    return (
                      <MenuItem key={project.id} value={project.id}>
                        <DefaultAvatar
                          labelOverlayed
                          key={`avatar${project.id}`}
                          label={`${projectHelpers.getProjectLabel(
                            project.label,
                            organizationLabel
                          )}`}
                          avatar={
                            <Avatar
                              data={project}
                              labelOverlayed
                              label={`${projectHelpers.getProjectLabel(
                                project.label,
                                organizationLabel
                              )}`}
                              size={24}
                            />
                          }
                        />
                        <span style={{ marginLeft: '8px' }}>{`${projectHelpers.getProjectLabel(
                          project.label,
                          organizationLabel
                        )}`}</span>
                      </MenuItem>
                    );
                  })}
                </Select>
              </Grid>
            </Grid>

            <VSpacer large />

            <FormControl alignItems="center" direction="row" justify="space-between">
              <Button onClick={closeDialog} color="secondary">
                <FormattedMessage id="settings.buttoncancel" />
              </Button>
              <Button type="submit" color="primary" disabled={disabledSubmitButton}>
                <FormattedMessage id="settings.buttonupcreate" />
              </Button>
            </FormControl>

            <VSpacer small />
          </Form>
        </Grid>
      </DialogContent>
    </Dialog>
  );
};

OrganizationNewUserDialog.defaultProps = {
  isOpen: false,
  projects: [],
  refreshProjects: true,
  selectedProjectId: undefined,
  organizationLabel: '',
};

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    createOrganizationUser: (
      email: any,
      firstName: any,
      lastName: any,
      role: any,
      projects: any,
      refreshProjects: any
    ) =>
      dispatch(
        Organization.createOrganizationUser(
          email,
          firstName,
          lastName,
          role,
          projects,
          refreshProjects
        )
      ),
    sendStatusToSnackbar: (msg: any, type: any) =>
      dispatch(InfoError.sendStatusToSnackbar(msg, type)),
  },
});

const mapStateToProps = (state: any) => {
  return {
    organizationLabel: state.organization.organization && state.organization.organization.label,
    projects: projectHelpers.orderProjects(state.projects.projects),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withStyles(styles)(OrganizationNewUserDialog));
