import { Grid, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { VSpacer } from '@sm-highway-web/react-components';
import FormattedMessage from '../components/FormattedMessageCustom';

const styles = {
  container: {
    height: '100vh',
    width: '100%',
  },
};

type OwnProps = {
  classes: {
    container: string;
  };
};

type Props = OwnProps;

const NoProjects = ({ classes }: Props) => {
  return (
    <Grid
      className={classes.container}
      direction="column"
      container
      justify="center"
      alignItems="center"
    >
      <Grid item>
        <Typography variant="h2">
          <FormattedMessage id="projects.not_assigned_projects.title" />
        </Typography>
      </Grid>

      <VSpacer />

      <Grid item>
        <Typography variant="h6">
          <FormattedMessage id="projects.not_assigned_projects.info" />
        </Typography>
      </Grid>

      {/* <Grid container justify="center">
        <img src={logoblack} alt="" className={classes.logo}></img>
      </Grid> */}
    </Grid>
  );
};

NoProjects.defaultProps = {};

export default withStyles(styles)(NoProjects);
