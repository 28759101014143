import { Grid, Typography } from '@material-ui/core';
import moment from 'moment';
import React, { useState } from 'react';
import { Button, Form, FormCheckbox, HSpacer, VSpacer } from '@sm-highway-web/react-components';
import { EU_COUNTRIES, getTaxAmountByUE } from '../../constants/Countries';
import { planPrice, priceTaxes, TAX_VALUE, totalPrice } from '../../helpers/Plans';
import CardInfo from '../CardInfo';
import FormattedMessage from '../FormattedMessageCustom';
import CouponCode from './CouponCode';

const PaymentConfirmation = ({ data, onPreviousStep, onNextStep }: any) => {
  const [terms, setTerms] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [coupon, setCoupon] = useState();

  const getTaxAmount = () => getTaxAmountByUE(data.billingAddress.country, TAX_VALUE);

  return (
    <Form
      onSubmit={async () => {
        setLoading(true);
        await onNextStep({ coupon });
        setLoading(false);
      }}
    >
      {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
      <Grid container direction="column" formContainer>
        <Typography variant="h2" align="center">
          <FormattedMessage id="paymentrunwaydialog.step4.title" />
        </Typography>
        <VSpacer />
        <Typography variant="h6">
          <FormattedMessage id="paymentrunwaydialog.step4.subsection1.title" />
        </Typography>
        <VSpacer medium />
        <Typography>{data.billingAddress.line1}</Typography>
        <VSpacer medium />
        <Typography variant="h6">
          <FormattedMessage id="paymentrunwaydialog.step4.subsection2.title" />
        </Typography>
        <VSpacer medium />
        <Grid container direction="column">
          <CardInfo card={data.card} />
        </Grid>
        <VSpacer medium />
        <Typography variant="h6">
          <FormattedMessage id="paymentrunwaydialog.step4.subsection3.title" />
        </Typography>
        <VSpacer medium />
        <Typography>
          {`${moment().format('MMMM DD, YYYY')} - ${moment()
            .add(30, 'days')
            .format('MMMM DD, YYYY')}`}
        </Typography>
        <VSpacer medium />
        <Typography variant="h6">
          <FormattedMessage id="paymentrunwaydialog.step4.subsection4.title" />
        </Typography>
        <VSpacer medium />
        <Grid container>
          <Grid item md={6}>
            <Typography>
              <FormattedMessage
                id="paymentrunwaydialog.step4.subsection4.text1"
                values={{ number: data.vehicles, plan: data.plan }}
              />
            </Typography>
          </Grid>

          <Grid item md={6}>
            {/* PLAN PRICE */}
            <Typography align="right">
              <FormattedMessage
                id="paymentrunwaydialog.step4.subsection4.text2"
                values={{
                  total: planPrice(data.plan, data.vehicles),
                }}
              />
            </Typography>

            <VSpacer small />

            {/* TAXES */}
            {Object.keys(EU_COUNTRIES).includes(data.billingAddress.country) && (
              <Typography align="right">
                <FormattedMessage
                  id="paymentrunwaydialog.step4.subsection4.text2_vat"
                  values={{
                    vatValue: getTaxAmount(),
                    total: priceTaxes(planPrice(data.plan, data.vehicles), getTaxAmount()),
                  }}
                />
              </Typography>
            )}

            {/* SUBTOTAL WITHOUT COUPON */}
            {coupon && (
              <>
                <VSpacer small />
                <Typography align="right">
                  <FormattedMessage
                    id="paymentrunwaydialog.step4.subsection4.text2_total_strike"
                    values={{
                      // @ts-expect-error ts-migrate(2554) FIXME: Expected 4 arguments, but got 3.
                      total: totalPrice(data.plan, data.vehicles, getTaxAmount()),
                    }}
                  />
                </Typography>
              </>
            )}

            <VSpacer small />

            {/* TOTAL */}
            <Typography align="right">
              <FormattedMessage
                id="paymentrunwaydialog.step4.subsection4.text2_total"
                values={{
                  total: totalPrice(data.plan, data.vehicles, getTaxAmount(), coupon),
                }}
              />
            </Typography>
          </Grid>
        </Grid>
        <VSpacer small />
        <CouponCode
          onCouponApplied={(c) => {
            setCoupon(c);
          }}
        />
        <VSpacer medium />
        <Typography>
          <FormattedMessage
            id="paymentrunwaydialog.step4.subsection4.text3"
            values={{
              value: totalPrice(data.plan, data.vehicles, getTaxAmount(), coupon),
              date: moment().add(30, 'days').format('MMMM DD, YYYY'),
            }}
          />
        </Typography>
        <VSpacer medium />
        <VSpacer medium />
        <FormCheckbox
          checked={terms}
          onChange={setTerms}
          labelComponent={
            <Typography>
              <FormattedMessage
                id="paymentrunwaydialog.step4.subsection4.text4"
                values={{
                  a: (chunks: any) => (
                    <a
                      href="https://smartmonkey.io/terms/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {chunks}
                    </a>
                  ),
                }}
              />
            </Typography>
          }
          validators={['isTrue']}
          errorMessages={[
            <FormattedMessage key="register.errortermscond" id="register.errortermscond" />,
          ]}
        />
      </Grid>
      {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
      <Grid container justify="flex-end" formContainer>
        <Button variant="text" color="secondary" onClick={onPreviousStep}>
          <FormattedMessage id="paymentrunwaydialog.buttonback" />
        </Button>
        <HSpacer medium />
        <Button disabled={isLoading} variant="contained" type="submit">
          {isLoading ? (
            <FormattedMessage id="paymentrunwaydialog.buttonloading" />
          ) : (
            <FormattedMessage id="paymentrunwaydialog.buttonnext" />
          )}
        </Button>
      </Grid>
    </Form>
  );
};

export default PaymentConfirmation;
