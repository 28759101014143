import { Grid } from '@material-ui/core';
import { createStyles, Theme, withStyles } from '@material-ui/core/styles';
import { VSpacer } from '@sm-highway-web/react-components';
import { IClientData } from 'highway-api/dist/common/interfaces/clients';
import { IProjectData } from 'highway-api/dist/common/interfaces/projects';
import { IRouteDataExtended } from 'highway-api/dist/common/interfaces/routes';
import { IServiceDataExtended } from 'highway-api/dist/common/interfaces/services';
import { IVehicleData } from 'highway-api/dist/common/interfaces/vehicles';
import React from 'react';
import { connect } from 'react-redux';
import CrossIcon from '../../assets/svg/CrossIcon';
import Client, { ClientHeader } from './Client';
import Route, { RouteHeader } from './Route';
import Service, { Pickup, ServiceHeader } from './Service';
import Vehicle, { VehicleHeader } from './Vehicle';

const styles = ({ palette }: Theme) => createStyles({
  cross: {
    width: '18px',
    height: '18px',
    color: '#6A6A6A',
    padding: '4px',
    '&:hover': {
      borderRadius: '50%',
      backgroundColor: '#EEEFF2',
      color: palette.primary.main,
    },
    position: 'absolute',
    top: '8px',
    right: '10px',
  },
});

type RightPanelInfoProps = {
  classes: {
    cross: string;
  };
  onClose: (...args: any[]) => any;
  onUpdate?: (...args: any[]) => any;
  options?: React.ReactElement | any;
  data?: IRouteDataExtended | IServiceDataExtended | IVehicleData | IClientData;
  warning?: React.ReactElement;
  type?: 'client' | 'service' | 'route' | 'vehicle';
  pickups: any;
  countryCode?: string | undefined | null;
  userPublicKey: string;
  activeProject: IProjectData;
};

const RightPanelInfo = ({
  classes,
  options,
  onClose,
  onUpdate,
  type,
  warning,
  data,
  userPublicKey,
  activeProject,
  pickups,
  countryCode,
}: RightPanelInfoProps) => (
  <Grid container direction="column" style={{ width: '400px', padding: '0', height: '100%' }}>
    <CrossIcon onClick={onClose} className={classes.cross} />
    <VSpacer small />
    {/* --- PANEL HEADER */}
    {data && (
      <>
        <Grid
          container
          justify="space-between"
          style={{
            width: 'calc(100% - 44px)',
            paddingLeft: '22px',
            paddingRight: '22px',
          }}
        >
          {type === 'client' && <ClientHeader data={data as IClientData} onUpdate={onUpdate} options={options} />}
          {type === 'service' && (
            <ServiceHeader
              data={data as IServiceDataExtended}
              warning={warning}
              onUpdate={onUpdate}
              options={options}
              pickups={pickups}
            />
          )}
          {type === 'route' && (
            <RouteHeader warning={warning} data={data as IRouteDataExtended} onUpdate={onUpdate} options={options} />
          )}
          {type === 'vehicle' && (
            <VehicleHeader data={data as IVehicleData} onUpdate={onUpdate} options={options} />
          )}
        </Grid>
        <VSpacer small />
        <div style={{ width: '100%', overflowY: 'scroll', height: 'calc(100% - 52px)' }}>
          {type === 'client' && (
            <Client
              data={data as IClientData}
              onUpdate={onUpdate}
              activeProject={activeProject}
              countryCode={countryCode}
            />
          )}
          {type === 'service' && (data as IServiceDataExtended).type !== 'pickup' && (
            <Service
              data={data as IServiceDataExtended}
              onUpdate={onUpdate}
              publicKey={userPublicKey}
              activeProject={activeProject}
              countryCode={countryCode}
            />
          )}
          {type === 'service' && (data as IServiceDataExtended).type === 'pickup' && (
            <Pickup
              data={data as IServiceDataExtended}
              onUpdate={onUpdate}
              publicKey={userPublicKey}
              activeProject={activeProject}
              countryCode={countryCode}
            />
          )}
          {type === 'route' && (
            <Route
              data={data as IRouteDataExtended}
              onUpdate={onUpdate}
              activeProject={activeProject}
              countryCode={countryCode}
            />
          )}
          {type === 'vehicle' && (
            <Vehicle
              data={data as IVehicleData}
              onUpdate={onUpdate}
              activeProject={activeProject}
              countryCode={countryCode}
            />
          )}
        </div>
      </>
    )}
  </Grid>
);

const mapStateToProps = (state: any) => {
  return {
    flatClients: state.clients.flatClients,
    selectedClient: state.clients.selectedClient,
    clients: state.clients.clients,
    userPublicKey: state.user.user && state.user.user.public_key ? state.user.user.public_key : '',
    activeProject: state.projects.activeProject,
    pickups: state.activePlan.pickups,
  };
};

RightPanelInfo.defaultProps = {
  onClose: () => {},
  pickups: undefined,
  userPublicKey: undefined,
  activeProject: undefined,
};

export default connect(mapStateToProps, null)(withStyles(styles)(RightPanelInfo));
