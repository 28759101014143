import { Checkbox } from '@sm-highway-web/react-components';
import React from 'react';
import { Label } from '../form-fields/FormFields.styled';

type CustomFieldFormBooleanProps = {
  onUpdate?: (...args: any[]) => any;
  fieldValue: boolean;
  projectField: any;
  readOnly?: boolean;
  onlyValues?: boolean;
  size?: 'medium' | 'large';
};

const CustomFieldFormBoolean = ({ fieldValue, onUpdate, projectField, readOnly, onlyValues = false, size }: CustomFieldFormBooleanProps) => (
  <>
    {!onlyValues && <Label size={size}>{projectField.label}</Label>}
    <Checkbox
      disabled={readOnly}
      checked={fieldValue}
      onChange={(e: any, value: any) => {
        if(onUpdate) {
          onUpdate(value);
        }
      }}
    />
  </>
);

CustomFieldFormBoolean.defaultProps = {
  readOnly: false,
  size: 'medium',
};

export default CustomFieldFormBoolean;
