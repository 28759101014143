import { PLAN_TYPE } from './Subscriptions';
import history from '../helpers/History';
import Froged from '../services/froged';
import * as routing from './Routing';

const settingsItems = (
  isDirtyState: any,
  handleOpenCloseDialog: any,
  planType: any,
  isOrganizationAdmin: any
) => [
  {
    type: 'title',
    messageId: 'settings.leftbar.title',
    id: 'title',
  },
  { type: 'spacer', id: 'spacer1' },

  // ACCOUNT
  {
    type: 'subtitle',
    messageId: 'settings.leftbar.account',
    id: 'settings.account',
  },
  { type: 'mediumspacer', id: 'mspacer1' },
  {
    type: 'text',
    messageId: 'settings.leftbar.account.profile',
    id: 'settings.account.profile',
    goTo: () => {
      if (isDirtyState()) {
        handleOpenCloseDialog(routing.SETTINGS.ACCOUNT.PROFILE);
      } else {
        history.push(routing.SETTINGS.ACCOUNT.PROFILE);
      }
    },
  },
  { type: 'mediumspacer', id: 'mspacer2' },

  // ORGANIZATION
  {
    type: 'divider',
    id: 'divider2',
  },
  { type: 'mediumspacer', id: 'mspacer3' },
  {
    type: 'subtitle',
    messageId: 'settings.leftbar.organization',
    id: 'settings.organization',
  },
  { type: 'mediumspacer', id: 'mspacer4' },
  {
    type: 'text',
    messageId: 'settings.leftbar.organization.profile',
    id: 'settings.organization.profile',
    goTo: () => {
      if (isDirtyState()) {
        handleOpenCloseDialog(routing.SETTINGS.ORGANIZATION.PROFILE);
      } else {
        history.push(routing.SETTINGS.ORGANIZATION.PROFILE);
      }
    },
  },
  { type: 'mediumspacer', id: 'mspacer5' },
  {
    type: 'text',
    messageId: 'settings.leftbar.organization.projects',
    id: 'settings.organization.projects',
    goTo: () => {
      if (isDirtyState()) {
        handleOpenCloseDialog(routing.SETTINGS.ORGANIZATION.PROJECTS.ROOT);
      } else {
        history.push(routing.SETTINGS.ORGANIZATION.PROJECTS.ROOT);
      }
    },
  },
  { type: 'mediumspacer', id: 'mspacer6' },
  {
    type: 'text',
    messageId: 'settings.leftbar.organization.users',
    id: 'settings.organization.users',
    goTo: () => {
      if (isDirtyState()) {
        handleOpenCloseDialog(routing.SETTINGS.ORGANIZATION.USERS);
      } else {
        history.push(routing.SETTINGS.ORGANIZATION.USERS);
      }
    },
  },
  { type: 'mediumspacer', id: 'mspacer7' },

  // BILLING
  ...(isOrganizationAdmin
    ? [
        {
          type: 'divider',
          id: 'divider3',
        },
        { type: 'mediumspacer', id: 'mspacer8' },
        {
          type: 'subtitle',
          messageId: 'settings.leftbar.billing',
          id: 'settings.billing',
        },
        { type: 'mediumspacer', id: 'mspacer9' },
        {
          type: 'text',
          messageId: 'settings.leftbar.billing.info',
          id: 'settings.billing.info',
          goTo: () => {
            if (isDirtyState()) {
              handleOpenCloseDialog(routing.SETTINGS.BILLING.INFO);
            } else {
              history.push(routing.SETTINGS.BILLING.INFO);
            }
          },
        },
        { type: 'mediumspacer', id: 'mspacer10' },
        {
          type: 'text',
          messageId: 'settings.leftbar.billing.payment',
          id: 'settings.billing.payment',
          goTo: () => {
            if (isDirtyState()) {
              handleOpenCloseDialog(routing.SETTINGS.BILLING.PAYMENT);
            } else {
              history.push(routing.SETTINGS.BILLING.PAYMENT);
            }
          },
        },
        { type: 'mediumspacer', id: 'mspacer11' },
        {
          type: 'text',
          messageId: 'settings.leftbar.billing.subscription',
          id: 'settings.billing.subscription',
          goTo: () => {
            if (isDirtyState()) {
              handleOpenCloseDialog(routing.SETTINGS.BILLING.SUBSCRIPTION);
            } else {
              history.push(routing.SETTINGS.BILLING.SUBSCRIPTION);
            }
          },
        },
        { type: 'mediumspacer', id: 'mspacer12' },

        {
          type: 'text',
          messageId: 'settings.leftbar.billing.invoices',
          id: 'settings.billing.invoices',
          goTo: () => {
            if (isDirtyState()) {
              handleOpenCloseDialog(routing.SETTINGS.BILLING.INVOICES);
            } else {
              history.push(routing.SETTINGS.BILLING.INVOICES);
            }
          },
        },
        { type: 'mediumspacer', id: 'mspacer13' },
      ]
    : []),
  // INTEGRATIONS
  ...(isOrganizationAdmin && planType !== PLAN_TYPE.MONKEY
    ? [
        {
          type: 'divider',
          id: 'divider4',
        },
        { type: 'mediumspacer', id: 'mspacer14' },
        {
          type: 'subtitle',
          messageId: 'settings.leftbar.integrations',
          id: 'settings.integrations',
        },
        { type: 'mediumspacer', id: 'mspacer15' },
        {
          type: 'text',
          messageId: 'settings.leftbar.integrations.inputhooks',
          id: 'settings.integrations.keys',
          goTo: () => {
            if (isDirtyState()) {
              handleOpenCloseDialog(routing.SETTINGS.INTEGRATIONS.INPUT_HOOKS);
            } else {
              history.push(routing.SETTINGS.INTEGRATIONS.INPUT_HOOKS);
            }
          },
        },
        { type: 'mediumspacer', id: 'mspacer16' },
        {
          type: 'text',
          messageId: 'settings.leftbar.integrations.outputhooks',
          id: 'settings.integrations.webhooks',
          goTo: () => {
            if (isDirtyState()) {
              handleOpenCloseDialog(routing.SETTINGS.INTEGRATIONS.OUTPUT_HOOKS);
            } else {
              history.push(routing.SETTINGS.INTEGRATIONS.OUTPUT_HOOKS);
            }
          },
        },
        { type: 'mediumspacer', id: 'mspacer17' },
      ]
    : []),
  // HELP
  {
    type: 'divider',
    id: 'divider5',
  },
  { type: 'mediumspacer', id: 'mspacer18' },
  {
    type: 'subtitle',
    messageId: 'settings.leftbar.support',
    id: 'subtitle4',
  },
  { type: 'mediumspacer', id: 'mspacer19' },
  {
    type: 'text',
    messageId: 'settings.leftbar.support.help',
    id: 'help',
    goTo: () => {
      if (isDirtyState()) {
        Froged.open();
        // handleOpenCloseDialog(`${routing.EXTERNAL.DOCS}`);
      } else {
        Froged.open();
        // window.open(`${routing.EXTERNAL.DOCS}`);
      }
    },
  },
  { type: 'mediumspacer', id: 'mspacer20' },
];

export default settingsItems;
