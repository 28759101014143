export const vehicles_ES = [
  {
    end_location: {
      city: 'El Prat de Llobregat',
      country: 'ESP',
      county: 'Barcelona',
      label: 'Carrer Cal Pi de l\'Olla, 08820 El Prat de Llobregat (Barcelona), Espanya',
      lat: 41.31565,
      lng: 2.13586,
      postal_code: '08820',
      state: 'Catalunya',
      street: 'Carrer Cal Pi de l\'Olla',
    },
    label: 'Jose',
    max_services: 17,
    start_location: {
      city: 'El Prat de Llobregat',
      country: 'ESP',
      county: 'Barcelona',
      label: 'Carrer Cal Pi de l\'Olla, 08820 El Prat de Llobregat (Barcelona), Espanya',
      lat: 41.31565,
      lng: 2.13586,
      postal_code: '08820',
      state: 'Catalunya',
      street: 'Carrer Cal Pi de l\'Olla',
    },
    timewindow: [32400, 57600],
  },
  {
    end_location: {
      city: 'El Prat de Llobregat',
      country: 'ESP',
      county: 'Barcelona',
      label: 'Carrer Cal Pi de l\'Olla, 08820 El Prat de Llobregat (Barcelona), Espanya',
      lat: 41.31565,
      lng: 2.13586,
      postal_code: '08820',
      state: 'Catalunya',
      street: 'Carrer Cal Pi de l\'Olla',
    },
    label: 'Pablo',
    max_services: 17,
    start_location: {
      city: 'El Prat de Llobregat',
      country: 'ESP',
      county: 'Barcelona',
      label: 'Carrer Cal Pi de l\'Olla, 08820 El Prat de Llobregat (Barcelona), Espanya',
      lat: 41.31565,
      lng: 2.13586,
      postal_code: '08820',
      state: 'Catalunya',
      street: 'Carrer Cal Pi de l\'Olla',
    },
    timewindow: [39600, 64800],
  },
  {
    end_location: {
      city: 'El Prat de Llobregat',
      country: 'ESP',
      county: 'Barcelona',
      label: 'Carrer Cal Pi de l\'Olla, 08820 El Prat de Llobregat (Barcelona), Espanya',
      lat: 41.31565,
      lng: 2.13586,
      postal_code: '08820',
      state: 'Catalunya',
      street: 'Carrer Cal Pi de l\'Olla',
    },
    label: 'Pedro',
    max_services: 17,
    start_location: {
      city: 'El Prat de Llobregat',
      country: 'ESP',
      county: 'Barcelona',
      label: 'Carrer Cal Pi de l\'Olla, 08820 El Prat de Llobregat (Barcelona), Espanya',
      lat: 41.31565,
      lng: 2.13586,
      postal_code: '08820',
      state: 'Catalunya',
      street: 'Carrer Cal Pi de l\'Olla',
    },
    timewindow: [32400, 57600],
  },
  {
    end_location: {
      city: 'El Prat de Llobregat',
      country: 'ESP',
      county: 'Barcelona',
      label: 'Carrer Cal Pi de l\'Olla, 08820 El Prat de Llobregat (Barcelona), Espanya',
      lat: 41.31565,
      lng: 2.13586,
      postal_code: '08820',
      state: 'Catalunya',
      street: 'Carrer Cal Pi de l\'Olla',
    },
    label: 'Manuel',
    max_services: 17,
    start_location: {
      city: 'El Prat de Llobregat',
      country: 'ESP',
      county: 'Barcelona',
      label: 'Carrer Cal Pi de l\'Olla, 08820 El Prat de Llobregat (Barcelona), Espanya',
      lat: 41.31565,
      lng: 2.13586,
      postal_code: '08820',
      state: 'Catalunya',
      street: 'Carrer Cal Pi de l\'Olla',
    },
    timewindow: [39600, 64800],
  },
];

export const services_ES = [
  {
    duration: 300,
    label: 'La Bombeta',
    location: {
      city: 'Barcelona',
      country: 'ESP',
      county: 'Barcelona',
      district: 'La Barceloneta',
      house_number: '3',
      label: 'Carrer de la Maquinista, 3, 08003 Barcelona (Barcelona), Espanya',
      lat: 41.38054,
      lng: 2.18771,
      postal_code: '08003',
      state: 'Catalunya',
      street: 'Carrer de la Maquinista',
    },
    timewindows: [
      [32400, 50400],
      [61200, 72000],
    ],
  },
  {
    duration: 300,
    label: 'Restaurant Can Culleretes',
    location: {
      city: 'Barcelona',
      country: 'ESP',
      county: 'Barcelona',
      district: 'El Gòtic',
      house_number: '5',
      label: 'Carrer d\'en Quintana, 5, 08002 Barcelona (Barcelona), Espanya',
      lat: 41.38123,
      lng: 2.17443,
      postal_code: '08002',
      state: 'Catalunya',
      street: 'Carrer d\'en Quintana',
    },
    timewindows: [[36000, 61200]],
  },
  {
    duration: 600,
    label: 'Bar El Tomas de Sarria',
    location: {
      city: 'Barcelona',
      country: 'ESP',
      county: 'Barcelona',
      district: 'Sarrià',
      house_number: '49',
      label: 'Carrer Major de Sarrià, 49, 08017 Barcelona (Barcelona), Espanya',
      lat: 41.39758,
      lng: 2.12306,
      postal_code: '08017',
      state: 'Catalunya',
      street: 'Carrer Major de Sarrià',
    },
    timewindows: [[39600, 45000]],
  },
  {
    duration: 300,
    label: 'W Barcelona',
    location: {
      city: 'Barcelona',
      country: 'ESP',
      county: 'Barcelona',
      district: 'La Barceloneta',
      house_number: '1',
      label: 'Plaça de la Rosa dels Vents, 1, 08039 Barcelona (Barcelona), Espanya',
      lat: 41.36836,
      lng: 2.19001,
      postal_code: '08039',
      state: 'Catalunya',
      street: 'Plaça de la Rosa dels Vents',
    },
    timewindows: [
      [32400, 50400],
      [61200, 79200],
    ],
  },
  {
    duration: 360,
    label: 'KITSUne',
    location: {
      city: 'Barcelona',
      country: 'ESP',
      county: 'Barcelona',
      district: 'Vila de Gràcia',
      house_number: '13',
      label: 'Carrer del Montseny, 13, 08012 Barcelona (Barcelona), Espanya',
      lat: 41.40181,
      lng: 2.15445,
      postal_code: '08012',
      state: 'Catalunya',
      street: 'Carrer del Montseny',
    },
    timewindows: [
      [32400, 50400],
      [61200, 79200],
    ],
  },
  {
    duration: 360,
    label: 'La Vietnamita',
    location: {
      city: 'Barcelona',
      country: 'ESP',
      county: 'Barcelona',
      district: 'Vila de Gràcia',
      house_number: '78',
      label: 'Carrer del Torrent de l\'Olla, 78, 08012 Barcelona (Barcelona), Espanya',
      lat: 41.4008,
      lng: 2.1591,
      postal_code: '08012',
      state: 'Catalunya',
      street: 'Carrer del Torrent de l\'Olla',
    },
    timewindows: [
      [32400, 50400],
      [61200, 79200],
    ],
  },
  {
    duration: 660,
    label: 'Plats',
    location: {
      city: 'Cornellà de Llobregat',
      country: 'ESP',
      county: 'Barcelona',
      house_number: '5',
      label:
        'Carrer de Mossèn Joaquim Palet i Bertomeu, 5, 08940 Cornellà de Llobregat (Barcelona), Espanya',
      lat: 41.35936,
      lng: 2.07335,
      postal_code: '08940',
      state: 'Catalunya',
      street: 'Carrer de Mossèn Joaquim Palet i Bertomeu',
    },
    timewindows: [
      [32400, 50400],
      [61200, 79200],
    ],
  },
  {
    duration: 360,
    label: 'Taj Indian',
    location: {
      city: 'Barcelona',
      country: 'ESP',
      county: 'Barcelona',
      district: 'Vila de Gràcia',
      house_number: '219',
      label: 'Carrer Bailén, 219, 08037 Barcelona (Barcelona), Espanya',
      lat: 41.40265,
      lng: 2.16287,
      postal_code: '08037',
      state: 'Catalunya',
      street: 'Carrer Bailén',
    },
    timewindows: [
      [32400, 50400],
      [61200, 79200],
    ],
  },
  {
    duration: 420,
    label: 'Petit Bangkok',
    location: {
      city: 'Barcelona',
      country: 'ESP',
      county: 'Barcelona',
      district: 'Dreta de l\'Eixample',
      house_number: '106',
      label: 'Carrer de Balmes, 106, 08008 Barcelona (Barcelona), Espanya',
      lat: 41.39231,
      lng: 2.15882,
      postal_code: '08008',
      state: 'Catalunya',
      street: 'Carrer de Balmes',
    },
    timewindows: [
      [32400, 50400],
      [61200, 79200],
    ],
  },
  {
    duration: 420,
    label: 'Café Zurich',
    location: {
      city: 'Barcelona',
      country: 'ESP',
      county: 'Barcelona',
      district: 'Dreta de l\'Eixample',
      house_number: '1',
      label: 'Plaça de Catalunya, 1, 08002 Barcelona (Barcelona), Espanya',
      lat: 41.38601,
      lng: 2.16941,
      postal_code: '08002',
      state: 'Catalunya',
      street: 'Plaça de Catalunya',
    },
    timewindows: [
      [32400, 50400],
      [61200, 79200],
    ],
  },
  {
    duration: 720,
    label: 'La Fira',
    location: {
      city: 'Barcelona',
      country: 'ESP',
      county: 'Barcelona',
      district: 'l\'Antiga Esquerra de l\'Eixample',
      house_number: '171',
      label: 'Carrer de Provença, 171, 08036 Barcelona (Barcelona), Espanya',
      lat: 41.39039,
      lng: 2.15557,
      postal_code: '08036',
      state: 'Catalunya',
      street: 'Carrer de Provença',
    },
    timewindows: [
      [32400, 50400],
      [61200, 79200],
    ],
  },
  {
    duration: 420,
    label: 'Hotel Arts Barcelona',
    location: {
      city: 'Barcelona',
      country: 'ESP',
      county: 'Barcelona',
      district: 'La Barceloneta',
      house_number: '19',
      label: 'Carrer de la Marina, 19, 08005 Barcelona (Barcelona), Espanya',
      lat: 41.38708,
      lng: 2.19657,
      postal_code: '08005',
      state: 'Catalunya',
      street: 'Carrer de la Marina',
    },
    timewindows: [
      [32400, 50400],
      [61200, 79200],
    ],
  },
  {
    duration: 480,
    label: 'Restaurant Semproniana',
    location: {
      city: 'Barcelona',
      country: 'ESP',
      county: 'Barcelona',
      district: 'l\'Antiga Esquerra de l\'Eixample',
      house_number: '148',
      label: 'Carrer del Rosselló, 148, 08036 Barcelona (Barcelona), Espanya',
      lat: 41.39104,
      lng: 2.15451,
      postal_code: '08036',
      state: 'Catalunya',
      street: 'Carrer del Rosselló',
    },
    timewindows: [[39600, 45000]],
  },
  {
    duration: 480,
    label: 'La Tramoia',
    location: {
      city: 'Barcelona',
      country: 'ESP',
      county: 'Barcelona',
      district: 'Dreta de l\'Eixample',
      house_number: '15',
      label: 'Rambla de Catalunya, 15, 08007 Barcelona (Barcelona), Espanya',
      lat: 41.38809,
      lng: 2.16726,
      postal_code: '08007',
      state: 'Catalunya',
      street: 'Rambla de Catalunya',
    },
    timewindows: [[39600, 45000]],
  },
  {
    duration: 780,
    label: 'SNOOKER - Còctels i Billars',
    location: {
      city: 'Barcelona',
      country: 'ESP',
      county: 'Barcelona',
      district: 'Dreta de l\'Eixample',
      house_number: '42',
      label: 'Carrer de Roger de Llúria, 42, 08009 Barcelona (Barcelona), Espanya',
      lat: 41.39212,
      lng: 2.17035,
      postal_code: '08009',
      state: 'Catalunya',
      street: 'Carrer de Roger de Llúria',
    },
    timewindows: [[39600, 45000]],
  },
  {
    duration: 480,
    label: 'Mian restaurant',
    location: {
      city: 'Barcelona',
      country: 'ESP',
      county: 'Barcelona',
      district: 'Dreta de l\'Eixample',
      house_number: '49',
      label: 'Carrer de Girona, 49, 08009 Barcelona (Barcelona), Espanya',
      lat: 41.39354,
      lng: 2.17265,
      postal_code: '08009',
      state: 'Catalunya',
      street: 'Carrer de Girona',
    },
    timewindows: [[39600, 45000]],
  },
  {
    duration: 540,
    label: 'Restaurant El Boliche del Gordo Cabrera',
    location: {
      city: 'Barcelona',
      country: 'ESP',
      county: 'Barcelona',
      district: 'Dreta de l\'Eixample',
      house_number: '338',
      label: 'Carrer del Consell de cent, 338, 08009 Barcelona (Barcelona), Espanya',
      lat: 41.39294,
      lng: 2.16834,
      postal_code: '08009',
      state: 'Catalunya',
      street: 'Carrer del Consell de cent',
    },
    timewindows: [[39600, 45000]],
  },
  {
    duration: 540,
    label: 'Bar Amsterdam Burgers & Beers, Barcelona',
    location: {
      city: 'Barcelona',
      country: 'ESP',
      county: 'Barcelona',
      district: 'Dreta de l\'Eixample',
      house_number: '305',
      label: 'Carrer d\'Aragó, 305, 08009 Barcelona (Barcelona), Espanya',
      lat: 41.39477,
      lng: 2.16809,
      postal_code: '08009',
      state: 'Catalunya',
      street: 'Carrer d\'Aragó',
    },
    timewindows: [[39600, 45000]],
  },
  {
    duration: 840,
    label: 'Grill Restaurant El Caliu de l\'Eixample',
    location: {
      city: 'Barcelona',
      country: 'ESP',
      county: 'Barcelona',
      district: 'Dreta de l\'Eixample',
      house_number: '329',
      label: 'Carrer de València, 329, 08009 Barcelona (Barcelona), Espanya',
      lat: 41.39706,
      lng: 2.16895,
      postal_code: '08009',
      state: 'Catalunya',
      street: 'Carrer de València',
    },
    timewindows: [
      [32400, 50400],
      [61200, 79200],
    ],
  },
  {
    duration: 540,
    label: 'Restaurant Japonés Ogura',
    location: {
      city: 'Barcelona',
      country: 'ESP',
      county: 'Barcelona',
      district: 'Dreta de l\'Eixample',
      house_number: '189',
      label: 'Carrer de Nàpols, 189, 08013 Barcelona (Barcelona), Espanya',
      lat: 41.39866,
      lng: 2.17489,
      postal_code: '08013',
      state: 'Catalunya',
      street: 'Carrer de Nàpols',
    },
    timewindows: [
      [32400, 50400],
      [61200, 79200],
    ],
  },
  {
    duration: 540,
    label: 'Hotel Poblenou B&B',
    location: {
      city: 'Barcelona',
      country: 'ESP',
      county: 'Barcelona',
      district: 'El Poblenou',
      house_number: '30',
      label: 'Carrer del Taulat, 30, 08005 Barcelona (Barcelona), Espanya',
      lat: 41.39831,
      lng: 2.20438,
      postal_code: '08005',
      state: 'Catalunya',
      street: 'Carrer del Taulat',
    },
    timewindows: [
      [32400, 50400],
      [61200, 79200],
    ],
  },
  {
    duration: 300,
    label: 'Colombia Pan y Sabor',
    location: {
      city: 'Barcelona',
      country: 'ESP',
      county: 'Barcelona',
      district: 'Sagrada Familia',
      house_number: '451',
      label: 'Carrer d\'Aragó, 451, 08013 Barcelona (Barcelona), Espanya',
      lat: 41.40249,
      lng: 2.17828,
      postal_code: '08013',
      state: 'Catalunya',
      street: 'Carrer d\'Aragó',
    },
    timewindows: [
      [32400, 50400],
      [61200, 79200],
    ],
  },
  {
    duration: 300,
    label: 'Restaurant Los Bellota',
    location: {
      city: 'Barcelona',
      country: 'ESP',
      county: 'Barcelona',
      district: 'Sagrada Familia',
      house_number: '298',
      label: 'Carrer de la Marina, 298, 08025 Barcelona (Barcelona), Espanya',
      lat: 41.4052,
      lng: 2.17367,
      postal_code: '08025',
      state: 'Catalunya',
      street: 'Carrer de la Marina',
    },
    timewindows: [
      [32400, 50400],
      [61200, 79200],
    ],
  },
  {
    duration: 300,
    label: 'La Piazzenza',
    location: {
      city: 'Barcelona',
      country: 'ESP',
      county: 'Barcelona',
      district: 'Sagrada Familia',
      house_number: '27',
      label: 'Avinguda de Gaudí, 27, 08025 Barcelona (Barcelona), Espanya',
      lat: 41.40657,
      lng: 2.17426,
      postal_code: '08025',
      state: 'Catalunya',
      street: 'Avinguda de Gaudí',
    },
    timewindows: [
      [32400, 50400],
      [61200, 79200],
    ],
  },
  {
    duration: 300,
    label: 'El Trencadís',
    location: {
      city: 'Barcelona',
      country: 'ESP',
      county: 'Barcelona',
      district: 'El Baix Guinardó',
      house_number: '165',
      label: 'Carrer de Sant Antoni Maria Claret, 165, 08025 Barcelona (Barcelona), Espanya',
      lat: 41.41109,
      lng: 2.17403,
      postal_code: '08025',
      state: 'Catalunya',
      street: 'Carrer de Sant Antoni Maria Claret',
    },
    timewindows: [
      [32400, 50400],
      [61200, 79200],
    ],
  },
  {
    duration: 300,
    label: 'Miquetes Màgiques',
    location: {
      city: 'Barcelona',
      country: 'ESP',
      county: 'Barcelona',
      district: 'El Guinardó',
      house_number: '20',
      label: 'Carrer del Trobador, 20, 08041 Barcelona (Barcelona), Espanya',
      lat: 41.41484,
      lng: 2.1776,
      postal_code: '08041',
      state: 'Catalunya',
      street: 'Carrer del Trobador',
    },
    timewindows: [
      [32400, 50400],
      [61200, 79200],
    ],
  },
  {
    duration: 300,
    label: 'Els Tres Porquets',
    location: {
      city: 'Barcelona',
      country: 'ESP',
      county: 'Barcelona',
      district: 'Provençals de Poblenou',
      house_number: '165',
      label: 'Rambla del Poblenou, 165, 08018 Barcelona (Barcelona), Espanya',
      lat: 41.40708,
      lng: 2.19311,
      postal_code: '08018',
      state: 'Catalunya',
      street: 'Rambla del Poblenou',
    },
    timewindows: [
      [32400, 50400],
      [61200, 79200],
    ],
  },
  {
    duration: 300,
    label: 'Swad-The Indian Restaurant',
    location: {
      city: 'Barcelona',
      country: 'ESP',
      county: 'Barcelona',
      district: 'El Poblenou',
      house_number: '167',
      label: 'Carrer de Sancho de Ávila, 167, 08018 Barcelona (Barcelona), Espanya',
      lat: 41.4037,
      lng: 2.19649,
      postal_code: '08018',
      state: 'Catalunya',
      street: 'Carrer de Sancho de Ávila',
    },
    timewindows: [
      [32400, 50400],
      [61200, 79200],
    ],
  },
  {
    duration: 300,
    label: 'Hotel España',
    location: {
      city: 'Barcelona',
      country: 'ESP',
      county: 'Barcelona',
      district: 'El Raval',
      house_number: '9',
      label: 'Carrer de Sant Pau, 9, 08001 Barcelona (Barcelona), Espanya',
      lat: 41.37996,
      lng: 2.17282,
      postal_code: '08001',
      state: 'Catalunya',
      street: 'Carrer de Sant Pau',
    },
    timewindows: [
      [32400, 50400],
      [61200, 79200],
    ],
  },
  {
    duration: 300,
    label: 'Cafè de l\'Òpera',
    location: {
      city: 'Barcelona',
      country: 'ESP',
      county: 'Barcelona',
      district: 'El Gòtic',
      house_number: '74',
      label: 'Carrer La Rambla, 74, 08002 Barcelona (Barcelona), Espanya',
      lat: 41.38088,
      lng: 2.17375,
      postal_code: '08002',
      state: 'Catalunya',
      street: 'Carrer La Rambla',
    },
    timewindows: [
      [32400, 50400],
      [61200, 79200],
    ],
  },
  {
    duration: 300,
    label: 'El 58',
    location: {
      city: 'Barcelona',
      country: 'ESP',
      county: 'Barcelona',
      district: 'El Poblenou',
      house_number: '58',
      label: 'Rambla del Poblenou, 58, 08005 Barcelona (Barcelona), Espanya',
      lat: 41.40044,
      lng: 2.20228,
      postal_code: '08005',
      state: 'Catalunya',
      street: 'Rambla del Poblenou',
    },
    timewindows: [
      [32400, 50400],
      [61200, 79200],
    ],
  },
  {
    duration: 300,
    label: 'Horchatería El Tío Che',
    location: {
      city: 'Barcelona',
      country: 'ESP',
      county: 'Barcelona',
      district: 'El Poblenou',
      house_number: '44',
      label: 'Rambla del Poblenou, 44, 08005 Barcelona (Barcelona), Espanya',
      lat: 41.40009,
      lng: 2.20296,
      postal_code: '08005',
      state: 'Catalunya',
      street: 'Rambla del Poblenou',
    },
    timewindows: [
      [32400, 50400],
      [61200, 79200],
    ],
  },
  {
    duration: 300,
    label: 'Els Pescadors',
    location: {
      city: 'Barcelona',
      country: 'ESP',
      county: 'Barcelona',
      district: 'El Poblenou',
      house_number: '1',
      label: 'Plaça de Prim, 1, 08005 Barcelona (Barcelona), Espanya',
      lat: 41.39955,
      lng: 2.20693,
      postal_code: '08005',
      state: 'Catalunya',
      street: 'Plaça de Prim',
    },
    timewindows: [[39600, 45000]],
  },
  {
    duration: 300,
    label: 'La Mar Bella',
    location: {
      city: 'Barcelona',
      country: 'ESP',
      county: 'Barcelona',
      district: 'El Poblenou',
      house_number: '81',
      label: 'Carrer del Taulat, 81, 08005 Barcelona (Barcelona), Espanya',
      lat: 41.39846,
      lng: 2.20437,
      postal_code: '08005',
      state: 'Catalunya',
      street: 'Carrer del Taulat',
    },
    timewindows: [[39600, 45000]],
  },
  {
    duration: 300,
    label: 'Els Peixaters',
    location: {
      city: 'Barcelona',
      country: 'ESP',
      county: 'Barcelona',
      district: 'Diag. Mar i el Front Mar. del Pob.',
      house_number: '24',
      label: 'Passeig de García Faria, 24, 08005 Barcelona (Barcelona), Espanya',
      lat: 41.40215,
      lng: 2.21228,
      postal_code: '08005',
      state: 'Catalunya',
      street: 'Passeig de García Faria',
    },
    timewindows: [[39600, 45000]],
  },
  {
    duration: 300,
    label: 'Pork&Tuna',
    location: {
      city: 'Barcelona',
      country: 'ESP',
      county: 'Barcelona',
      district: 'Diag. Mar i el Front Mar. del Pob.',
      house_number: '77',
      label: 'Passeig de García Faria, 77, 08019 Barcelona (Barcelona), Espanya',
      lat: 41.405,
      lng: 2.21535,
      postal_code: '08019',
      state: 'Catalunya',
      street: 'Passeig de García Faria',
    },
    timewindows: [[39600, 45000]],
  },
  {
    duration: 300,
    label: 'Restaurante Pacific',
    location: {
      city: 'Barcelona',
      country: 'ESP',
      county: 'Barcelona',
      district: 'El Besòs i el Maresme',
      house_number: '4',
      label: 'Carrer de Bermejo, 4, 08019 Barcelona (Barcelona), Espanya',
      lat: 41.41335,
      lng: 2.21767,
      postal_code: '08019',
      state: 'Catalunya',
      street: 'Carrer de Bermejo',
    },
    timewindows: [[39600, 45000]],
  },
  {
    duration: 300,
    label: 'Port Nàutic',
    location: {
      city: 'Sant Adrià de Besòs',
      country: 'ESP',
      county: 'Barcelona',
      label: 'Moll de la Ronda, 08930 Sant Adrià de Besòs (Barcelona), Espanya',
      lat: 41.41451,
      lng: 2.22613,
      postal_code: '08930',
      state: 'Catalunya',
      street: 'Moll de la Ronda',
    },
    timewindows: [[39600, 45000]],
  },
  {
    duration: 300,
    label: 'Bar Lafuente',
    location: {
      city: 'Barcelona',
      country: 'ESP',
      county: 'Barcelona',
      district: 'La Verneda i la Pau',
      house_number: '1179',
      label: 'Gran Via de les Corts Catalanes, 1179, 08020 Barcelona (Barcelona), Espanya',
      lat: 41.42012,
      lng: 2.20969,
      postal_code: '08020',
      state: 'Catalunya',
      street: 'Gran Via de les Corts Catalanes',
    },
    timewindows: [[39600, 45000]],
  },
  {
    duration: 300,
    label: 'Hotel Passeig de Gràcia',
    location: {
      city: 'Barcelona',
      country: 'ESP',
      county: 'Barcelona',
      district: 'Dreta de l\'Eixample',
      house_number: '102',
      label: 'Passeig de Gràcia, 102, 08008 Barcelona (Barcelona), Espanya',
      lat: 41.39571,
      lng: 2.16099,
      postal_code: '08008',
      state: 'Catalunya',
      street: 'Passeig de Gràcia',
    },
    timewindows: [[39600, 45000]],
  },
  {
    duration: 300,
    label: 'Hilton Diagonal Mar Barcelona',
    location: {
      city: 'Barcelona',
      country: 'ESP',
      county: 'Barcelona',
      district: 'Diag. Mar i el Front Mar. del Pob.',
      house_number: '262',
      label: 'Passeig del Taulat, 262, 08019 Barcelona (Barcelona), Espanya',
      lat: 41.40903,
      lng: 2.21734,
      postal_code: '08019',
      state: 'Catalunya',
      street: 'Passeig del Taulat',
    },
    timewindows: [
      [32400, 50400],
      [61200, 72000],
    ],
  },
  {
    duration: 300,
    label: 'Restaurante Pacific',
    location: {
      city: 'Barcelona',
      country: 'ESP',
      county: 'Barcelona',
      district: 'El Besòs i el Maresme',
      house_number: '4',
      label: 'Carrer de Bermejo, 4, 08019 Barcelona (Barcelona), Espanya',
      lat: 41.41335,
      lng: 2.21767,
      postal_code: '08019',
      state: 'Catalunya',
      street: 'Carrer de Bermejo',
    },
    timewindows: [
      [32400, 50400],
      [61200, 72000],
    ],
  },
  {
    duration: 300,
    label: 'El Ramblero',
    location: {
      city: 'Barcelona',
      country: 'ESP',
      county: 'Barcelona',
      district: 'Sant Martí de Provençals',
      house_number: '13',
      label: 'Carrer del Pont del Treball Digne, 13, 08020 Barcelona (Barcelona), Espanya',
      lat: 41.42189,
      lng: 2.19699,
      postal_code: '08020',
      state: 'Catalunya',
      street: 'Carrer del Pont del Treball Digne',
    },
    timewindows: [
      [32400, 50400],
      [61200, 72000],
    ],
  },
  {
    duration: 300,
    label: 'La Font de la Sagrera',
    location: {
      city: 'Barcelona',
      country: 'ESP',
      county: 'Barcelona',
      district: 'La Sagrera',
      house_number: '49',
      label: 'Carrer de Mossèn Juliana, 49, 08027 Barcelona (Barcelona), Espanya',
      lat: 41.42481,
      lng: 2.18983,
      postal_code: '08027',
      state: 'Catalunya',
      street: 'Carrer de Mossèn Juliana',
    },
    timewindows: [
      [32400, 50400],
      [61200, 72000],
    ],
  },
  {
    duration: 300,
    label: 'Mesón Pahi',
    location: {
      city: 'Barcelona',
      country: 'ESP',
      county: 'Barcelona',
      district: 'La Sagrera',
      house_number: '7',
      label: 'Carrer de Torroella de Montgrí, 7, 08027 Barcelona (Barcelona), Espanya',
      lat: 41.42719,
      lng: 2.18684,
      postal_code: '08027',
      state: 'Catalunya',
      street: 'Carrer de Torroella de Montgrí',
    },
    timewindows: [
      [32400, 50400],
      [61200, 72000],
    ],
  },
  {
    duration: 300,
    label: 'Valles Francfurt',
    location: {
      city: 'Barcelona',
      country: 'ESP',
      county: 'Barcelona',
      district: 'Vila de Gràcia',
      house_number: '84',
      label: 'Carrer Gran de Gràcia, 84, 08012 Barcelona (Barcelona), Espanya',
      lat: 41.40026,
      lng: 2.15511,
      postal_code: '08012',
      state: 'Catalunya',
      street: 'Carrer Gran de Gràcia',
    },
    timewindows: [
      [32400, 50400],
      [61200, 72000],
    ],
  },
  {
    duration: 300,
    label: 'La Esquinica',
    location: {
      city: 'Barcelona',
      country: 'ESP',
      county: 'Barcelona',
      district: 'Turó de la Peira',
      house_number: '296',
      label: 'Passeig de Fabra i Puig, 296, 08031 Barcelona (Barcelona), Espanya',
      lat: 41.43053,
      lng: 2.17076,
      postal_code: '08031',
      state: 'Catalunya',
      street: 'Passeig de Fabra i Puig',
    },
    timewindows: [
      [32400, 50400],
      [61200, 72000],
    ],
  },
  {
    duration: 300,
    label: 'Restaurante Casa Castro',
    location: {
      city: 'Barcelona',
      country: 'ESP',
      county: 'Barcelona',
      district: 'La Guineueta',
      house_number: '5',
      label: 'Carrer de l\'Isard, 5, 08042 Barcelona (Barcelona), Espanya',
      lat: 41.43945,
      lng: 2.17005,
      postal_code: '08042',
      state: 'Catalunya',
      street: 'Carrer de l\'Isard',
    },
    timewindows: [
      [32400, 50400],
      [61200, 72000],
    ],
  },
  {
    duration: 300,
    label: '5 hermanos',
    location: {
      city: 'Barcelona',
      country: 'ESP',
      county: 'Barcelona',
      district: 'Canyelles',
      house_number: '31',
      label: 'Carrer de Federico García Lorca, 31, 08042 Barcelona (Barcelona), Espanya',
      lat: 41.44419,
      lng: 2.16589,
      postal_code: '08042',
      state: 'Catalunya',
      street: 'Carrer de Federico García Lorca',
    },
    timewindows: [
      [32400, 50400],
      [61200, 72000],
    ],
  },
  {
    duration: 300,
    label: 'Hotel Sagrada Familia',
    location: {
      city: 'Barcelona',
      country: 'ESP',
      county: 'Barcelona',
      district: 'Sagrada Familia',
      house_number: '541',
      label: 'Carrer de Còrsega, 541, 08025 Barcelona (Barcelona), Espanya',
      lat: 41.40591,
      lng: 2.17159,
      postal_code: '08025',
      state: 'Catalunya',
      street: 'Carrer de Còrsega',
    },
    timewindows: [
      [32400, 50400],
      [61200, 72000],
    ],
  },
  {
    duration: 300,
    label: 'Quimet & Quimet',
    location: {
      city: 'Barcelona',
      country: 'ESP',
      county: 'Barcelona',
      district: 'El Poblesec',
      house_number: '25',
      label: 'Carrer del Poeta Cabanyes, 25, 08004 Barcelona (Barcelona), Espanya',
      lat: 41.37397,
      lng: 2.16556,
      postal_code: '08004',
      state: 'Catalunya',
      street: 'Carrer del Poeta Cabanyes',
    },
    timewindows: [[39600, 45000]],
  },
  {
    duration: 300,
    label: 'Taberna Can Margarit',
    location: {
      city: 'Barcelona',
      country: 'ESP',
      county: 'Barcelona',
      district: 'El Poblesec',
      house_number: '21',
      label: 'Carrer de la Concòrdia, 21, 08004 Barcelona (Barcelona), Espanya',
      lat: 41.37318,
      lng: 2.16002,
      postal_code: '08004',
      state: 'Catalunya',
      street: 'Carrer de la Concòrdia',
    },
    timewindows: [
      [32400, 50400],
      [61200, 72000],
    ],
  },
  {
    duration: 300,
    label: 'Restaurant Cèntric 35',
    location: {
      city: 'Barcelona',
      country: 'ESP',
      county: 'Barcelona',
      district: 'El Poblesec',
      house_number: '35',
      label: 'Carrer de l\'Olivera, 35, 08004 Barcelona (Barcelona), Espanya',
      lat: 41.37359,
      lng: 2.15703,
      postal_code: '08004',
      state: 'Catalunya',
      street: 'Carrer de l\'Olivera',
    },
    timewindows: [[39600, 45000]],
  },
  {
    duration: 300,
    label: 'La Bodegueta del Poble Sec',
    location: {
      city: 'Barcelona',
      country: 'ESP',
      county: 'Barcelona',
      district: 'El Poblesec',
      house_number: '47',
      label: 'Carrer de Blai, 47, 08004 Barcelona (Barcelona), Espanya',
      lat: 41.37412,
      lng: 2.16321,
      postal_code: '08004',
      state: 'Catalunya',
      street: 'Carrer de Blai',
    },
    timewindows: [
      [32400, 50400],
      [61200, 72000],
    ],
  },
  {
    duration: 300,
    label: 'Restaurant La Perla Bcn',
    location: {
      city: 'Barcelona',
      country: 'ESP',
      county: 'Barcelona',
      district: 'El Poblesec',
      house_number: '62',
      label: 'Passeig de l\'Exposició, 62, 08004 Barcelona (Barcelona), Espanya',
      lat: 41.37127,
      lng: 2.15947,
      postal_code: '08004',
      state: 'Catalunya',
      street: 'Passeig de l\'Exposició',
    },
    timewindows: [[39600, 45000]],
  },
  {
    duration: 300,
    label: 'Restaurant Cèntric 35',
    location: {
      city: 'Barcelona',
      country: 'ESP',
      county: 'Barcelona',
      district: 'El Poblesec',
      house_number: '35',
      label: 'Carrer de l\'Olivera, 35, 08004 Barcelona (Barcelona), Espanya',
      lat: 41.37359,
      lng: 2.15703,
      postal_code: '08004',
      state: 'Catalunya',
      street: 'Carrer de l\'Olivera',
    },
    timewindows: [
      [32400, 50400],
      [61200, 72000],
    ],
  },
  {
    duration: 300,
    label: 'Casa de Tapas Cañota',
    location: {
      city: 'Barcelona',
      country: 'ESP',
      county: 'Barcelona',
      district: 'El Poblesec',
      house_number: '7',
      label: 'Carrer de Lleida, 7, 08004 Barcelona (Barcelona), Espanya',
      lat: 41.37424,
      lng: 2.15456,
      postal_code: '08004',
      state: 'Catalunya',
      street: 'Carrer de Lleida',
    },
    timewindows: [
      [32400, 50400],
      [61200, 72000],
    ],
  },
  {
    duration: 300,
    label: 'Ikibana Paralelo',
    location: {
      city: 'Barcelona',
      country: 'ESP',
      county: 'Barcelona',
      district: 'Sant Antoni',
      house_number: '148',
      label: 'Avinguda del Paral.lel, 148, 08015 Barcelona (Barcelona), Espanya',
      lat: 41.37517,
      lng: 2.15824,
      postal_code: '08015',
      state: 'Catalunya',
      street: 'Avinguda del Paral.lel',
    },
    timewindows: [
      [32400, 50400],
      [61200, 72000],
    ],
  },
  {
    duration: 300,
    label: 'MAKIYAKI',
    location: {
      city: 'Barcelona',
      country: 'ESP',
      county: 'Barcelona',
      district: 'El Poblesec',
      house_number: '18',
      label: 'Carrer de la Font Honrada, 18, 08004 Barcelona (Barcelona), Espanya',
      lat: 41.37384,
      lng: 2.15583,
      postal_code: '08004',
      state: 'Catalunya',
      street: 'Carrer de la Font Honrada',
    },
    timewindows: [
      [32400, 50400],
      [61200, 72000],
    ],
  },
  {
    duration: 300,
    label: 'Restaurant Mount Everest',
    location: {
      city: 'Barcelona',
      country: 'ESP',
      county: 'Barcelona',
      district: 'Sant Antoni',
      house_number: '80',
      label: 'Carrer de Viladomat, 80, 08015 Barcelona (Barcelona), Espanya',
      lat: 41.3785,
      lng: 2.15903,
      postal_code: '08015',
      state: 'Catalunya',
      street: 'Carrer de Viladomat',
    },
    timewindows: [
      [32400, 50400],
      [61200, 72000],
    ],
  },
  {
    duration: 300,
    label: 'Volta Al Món',
    location: {
      city: 'Barcelona',
      country: 'ESP',
      county: 'Barcelona',
      district: 'l\'Antiga Esquerra de l\'Eixample',
      house_number: '51',
      label: 'Carrer de Muntaner, 51, 08011 Barcelona (Barcelona), Espanya',
      lat: 41.38605,
      lng: 2.15999,
      postal_code: '08011',
      state: 'Catalunya',
      street: 'Carrer de Muntaner',
    },
    timewindows: [
      [32400, 50400],
      [61200, 72000],
    ],
  },
  {
    duration: 300,
    label: 'Restaurant Indochine Ly Leap',
    location: {
      city: 'Barcelona',
      country: 'ESP',
      county: 'Barcelona',
      district: 'l\'Antiga Esquerra de l\'Eixample',
      house_number: '82',
      label: 'Carrer de Muntaner, 82, 08011 Barcelona (Barcelona), Espanya',
      lat: 41.38788,
      lng: 2.15791,
      postal_code: '08011',
      state: 'Catalunya',
      street: 'Carrer de Muntaner',
    },
    timewindows: [
      [32400, 50400],
      [61200, 72000],
    ],
  },
  {
    duration: 300,
    label: 'Hotel NH Barcelona Eixample',
    location: {
      city: 'Barcelona',
      country: 'ESP',
      county: 'Barcelona',
      district: 'l\'Antiga Esquerra de l\'Eixample',
      house_number: '105',
      label: 'Carrer de València, 105, 08011 Barcelona (Barcelona), Espanya',
      lat: 41.38599,
      lng: 2.15424,
      postal_code: '08011',
      state: 'Catalunya',
      street: 'Carrer de València',
    },
    timewindows: [
      [32400, 50400],
      [61200, 72000],
    ],
  },
  {
    duration: 300,
    label: 'Oval',
    location: {
      city: 'Barcelona',
      country: 'ESP',
      county: 'Barcelona',
      district: 'l\'Antiga Esquerra de l\'Eixample',
      house_number: '199',
      label: 'Carrer de València, 199, 08011 Barcelona (Barcelona), Espanya',
      lat: 41.38972,
      lng: 2.1592,
      postal_code: '08011',
      state: 'Catalunya',
      street: 'Carrer de València',
    },
    timewindows: [[39600, 45000]],
  },
  {
    duration: 300,
    label: 'La Camarga',
    location: {
      city: 'Barcelona',
      country: 'ESP',
      county: 'Barcelona',
      district: 'l\'Antiga Esquerra de l\'Eixample',
      house_number: '117',
      label: 'Carrer d\'Aribau, 117, 08036 Barcelona (Barcelona), Espanya',
      lat: 41.39099,
      lng: 2.15572,
      postal_code: '08036',
      state: 'Catalunya',
      street: 'Carrer d\'Aribau',
    },
    timewindows: [[39600, 45000]],
  },
];
