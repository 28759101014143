import store from '../store/index';
import { OrganizationRoles } from './Organization';
import { ProjectRoles } from './Project';

export const currentUser = {
  isRoot: () => {
    try {
      const { user } = store.getState().user;
      return user.roles.includes('root');
    } catch (_) {
      return false;
    }
  },
  isAdmin: () => {
    try {
      const { user } = store.getState().user;
      return user.roles.includes('admin');
    } catch (_) {
      return false;
    }
  },

  // ORGANIZATION
  isOrganizationOwner: () => {
    try {
      const { user } = store.getState().user;
      const { organization } = store.getState().organization;
      return organization.owner_id === user.id;
    } catch (_) {
      return false;
    }
  },
  isOrganizationAdmin: () => {
    try {
      const { user } = store.getState().user;
      const organizationUsers = store.getState().organization.users;
      return !!organizationUsers.find(
        (_user: any) =>
          _user.user_id === user.id && _user.role === OrganizationRoles.organization_administrator
      );
    } catch (_) {
      return false;
    }
  },
  isOrganizationManager: () => {
    try {
      const { user } = store.getState().user;
      const organizationUsers = store.getState().organization.users;
      return !!organizationUsers.find(
        (_user: any) =>
          _user.user_id === user.id && _user.role === OrganizationRoles.organization_manager
      );
    } catch (_) {
      return false;
    }
  },

  // ACTIVE PROJECT
  isInActiveProject: () => {
    try {
      const { user } = store.getState().user;
      const { activeProject } = store.getState().projects;
      return !!activeProject.users.find((_user: any) => _user.user_id === user.id);
    } catch (_) {
      return false;
    }
  },
  isManagerInActiveProject: () => {
    try {
      const { user } = store.getState().user;
      const { activeProject } = store.getState().projects;
      return !!activeProject.users.find(
        (_user: any) => _user.user_id === user.id && _user.role === ProjectRoles.project_manager
      );
    } catch (_) {
      return false;
    }
  },
};

export const otherUser = {
  isSameAsLogged: (userId: any) => {
    try {
      const { user } = store.getState().user;
      return user.id === userId;
    } catch (_) {
      return false;
    }
  },

  // ORGANIZATION
  isOrganizationOwner: (userId: any) => {
    try {
      const { organization } = store.getState().organization;
      return organization.owner_id === userId;
    } catch (_) {
      return false;
    }
  },
  isOrganizationAdmin: (userId: any) => {
    try {
      const organizationUsers = store.getState().organization.users;
      return !!organizationUsers.find(
        (_user: any) =>
          _user.user_id === userId && _user.role === OrganizationRoles.organization_administrator
      );
    } catch (_) {
      return false;
    }
  },
};
