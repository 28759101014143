import React, { useState } from 'react';
import ImageCropDialog from '../dialogs/ImageCropDialog';
import { blobToDataURL } from '../helpers/Files';
import { generateAttachmentName, MAX_AVATAR_IMAGE_SIZE, resizeFile, UPLOAD_TYPES } from '../helpers/Images';

export type ImageUploadData = {
  fileName: string;
  imageType: string;
  image: string;
};

export const UPLOADER_ID = 'capture-image';

type ImageUploadProps = {
  crop?: boolean;
  cropDialogTitle?: React.ReactElement | string;
  onImageUploaded: (...args: any[]) => any;
};

const ImageUpload = ({ crop = false, cropDialogTitle, onImageUploaded }: ImageUploadProps) => {
  
  const [cropDialogOpen, setCropDialogOpen] = useState<boolean>(false);
  const [imageToCrop, setImageToCrop] = useState<ImageUploadData | undefined>(undefined);

  const handleFile = async (files?: FileList | null) => {
    if (
      files &&
      files.length > 0
    ) {
      const file = files[0];
      const fileToSend : Uint8Array = await resizeFile(file, MAX_AVATAR_IMAGE_SIZE);
      const fileName = generateAttachmentName(UPLOAD_TYPES.PROJECT);
      const imageType = file.type && file.type !== '' ? file.type : `image/jpeg`;
      const blob = new Blob([fileToSend], { type: imageType });
      
      if(crop) {
        const base64 = await blobToDataURL(blob);
        setCropDialogOpen(true);
        setImageToCrop({
          fileName,
          imageType,
          image: (base64 as string),
        });
      } else {
        onImageUploaded(fileName, blob.type, blob);
      }
    }
  };

  return (
    <>
      <input
        type="file"
        id={`${UPLOADER_ID}`}
        accept="image/*"
        style={{ display: 'none' }}
        onChange={e => handleFile(e.target.files)}
      />

      <ImageCropDialog
        title={cropDialogTitle}
        open={cropDialogOpen}
        image={imageToCrop?.image}
        imageType={imageToCrop?.imageType}
        onCloseDialog={() => {
          setCropDialogOpen(false);
          setImageToCrop(undefined);
        }}
        onCrop={image => {
          onImageUploaded(imageToCrop!.fileName, image.type, image);
        }}
      />
    </>    
  );
};

export default ImageUpload;
