import { Grid, withStyles } from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import React, { useEffect, useRef, useState } from 'react';
import FormattedMessage from '../FormattedMessageCustom';
import { Label, Placeholder } from './FormFields.styled';
import LocationSearch from './LocationSearch';

const styles = {
  children: {
    '&:hover': {
      backgroundColor: '#EEEFF2',
    },
    borderRadius: '2px',
    padding: '2px 4px',
    // width: 'calc(100% - 8px)'
  },
};

type FormLocationProps = {
  classes: {
    children: string;
  };
  fieldValue?: string;
  label?: string | React.ReactElement | {};
  onUpdate?: (...args: any[]) => any;
  placeholder?: string | React.ReactElement | {};
  countryCode?: string | null;
  menuPlacement?: 'bottom' | 'top';
};

const FormLocation = ({
  fieldValue,
  label,
  onUpdate,
  classes,
  placeholder,
  countryCode,
  menuPlacement,
}: FormLocationProps) => {
  const [editable, setEditable] = useState(false);

  const formRef = useRef();

  const handleKeyPress = async (event: any) => {
    switch (event.key) {
      case 'Escape':
        setEditable(false);
        break;
      default:
        break;
    }
  };

  const handleClick = (evt: any) => {
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    if (formRef.current.contains(evt.target)) {
      setEditable(true);
    } else {
      setEditable(false);
    }
  };

  const handleUpdate = (location: any) => {
    setEditable(false);
    // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    onUpdate(location);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, []);

  return (
    <Grid
      container
      direction="row"
      style={{ ...(fieldValue ? { paddingRight: '24px', position: 'relative' } : {}) }}
    >
      {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
      <Grid container direction="column" ref={formRef}>
        {label && <Label>{label}</Label>}
        {editable ? (
          <LocationSearch
            fieldValue={fieldValue}
            onKeyDown={handleKeyPress}
            onUpdate={handleUpdate}
            onBlur={() => setEditable(false)}
            countryCode={countryCode}
            menuPlacement={menuPlacement}
          />
        ) : (
          <Grid
            container
            alignItems="center"
            className={classes.children}
            style={{ padding: 0, minHeight: '32px' }}
            onClick={(e) => {
              setEditable(true);
            }}
          >
            {fieldValue || (
              <Placeholder>{placeholder || <FormattedMessage id="none" />}</Placeholder>
            )}
          </Grid>
        )}
      </Grid>
      {!editable && fieldValue && (
        <ClearIcon
          id="clear-icon"
          style={{ position: 'absolute', right: 0, top: '24px', cursor: 'pointer' }}
          onClick={(e) => {
            setEditable(false);
            // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
            onUpdate(null);
          }}
        />
      )}
    </Grid>
  );
};

FormLocation.defaultProps = {
  fieldValue: undefined,
  label: undefined,
  placeholder: undefined,
  onUpdate: () => {},
};

export default withStyles(styles)(FormLocation);
