import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Tutorial, User } from '../../redux/actions';
import OnboardingTutorial from './OnboardingTutorial';

const TUTORIALS = [
  {
    id: 'onboarding',
    route: '/plans',
    content: OnboardingTutorial,
  },
  // ....
];

const Tutorials = ({ user, tutorial, actions, location, match }: any) => {
  const [currentTutorial, setCurrentTutorial] = useState(undefined);

  useEffect(() => {
    if (location.pathname !== '') {
      const foundTutorial = TUTORIALS.find(
        (_tutorial: any) => location.pathname.indexOf(_tutorial.route) > -1
      );

      if (foundTutorial) {
        if (
          user.guide &&
          user.guide.tutorials &&
          user.guide.tutorials[foundTutorial.id] &&
          !user.guide.tutorials[foundTutorial.id].is_finished
        ) {
          if (
            !tutorial.isActive ||
            (currentTutorial &&
              // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
              (currentTutorial.id !== foundTutorial.id ||
                // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
                (currentTutorial.id === foundTutorial.id &&
                  // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
                  currentTutorial.step !== user.guide.tutorials[foundTutorial.id].current_step)))
          ) {
            // Ending tutorial if is not finished but current_step is greater than 0.
            if (!tutorial.isActive && user.guide.tutorials[foundTutorial.id].current_step > 0) {
              const newGuide = { ...user.guide };
              newGuide.tutorials[foundTutorial.id].is_finished = true;
              actions.editGuide(newGuide);
              setCurrentTutorial(undefined);
            } else {
              actions.initTutorial(
                foundTutorial.id,
                user.guide.tutorials[foundTutorial.id].current_step
              );
              setCurrentTutorial({
                ...foundTutorial,
                // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ step: any; id: string; route: ... Remove this comment to see the full error message
                step: user.guide.tutorials[foundTutorial.id].current_step,
              });
            }
          }
        }
      } else {
        setCurrentTutorial(undefined);
      }
    }
  }, [location, tutorial, user, actions, currentTutorial]);

  if (currentTutorial) {
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    return React.createElement(currentTutorial.content, {
      currentTutorial: tutorial.currentTutorial,
    });
  }

  return null;
};

Tutorials.propTypes = {
  user: PropTypes.shape({
    guide: PropTypes.shape({
      changelog_version: PropTypes.string,
      tutorials: PropTypes.shape({}),
    }),
  }).isRequired,
  tutorial: PropTypes.shape({
    isActive: PropTypes.bool,
    currentTutorial: PropTypes.shape({
      // @ts-expect-error ts-migrate(2322) FIXME: Type 'number' is not assignable to type 'Validator... Remove this comment to see the full error message
      id: PropTypes.string || PropTypes.undefined,
      step: PropTypes.number,
    }),
  }),
  actions: PropTypes.shape({
    initTutorial: PropTypes.func.isRequired,
  }),
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
};

Tutorials.defaultProps = {};

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    initTutorial: (tutorialId: any, step: any) => dispatch(Tutorial.initialize(tutorialId, step)),
    editGuide: (guide: any) => dispatch(User.editGuide(guide)),
  },
});

const mapStateToProps = (state: any) => {
  return {
    user: state.user.user,
    tutorial: state.tutorial,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Tutorials));
