import { IProjectData } from 'highway-api/dist/common/interfaces/projects';
import { IRouteDataExtended } from 'highway-api/dist/common/interfaces/routes';
import { currencyToSymbol, kmToSM } from './Units';

export const getTotalPrice = (route: IRouteDataExtended, project: IProjectData) => {
  if (!route || !project) return null;

  // Price per minute calculation (hours for human readable)
  const minutePrice = route.price_per_minute || 0;
  const totalMinutes = route.planned_end_time !== undefined && route.planned_start_time !== undefined ? route.planned_end_time / 60 - route.planned_start_time / 60 : 0;    
  const totalPricePerHour = (totalMinutes * minutePrice) / 60;
    
  // Price per distance calculation
  const distancePrice = route.price_per_distance || 0;
  const metersDistance = route.services.reduce((ps: any, ns: any) => ps + (parseInt(ns.distance_to_next_location, 10) || 0), 0);
  const totalDistance = parseFloat(kmToSM((metersDistance || 0) / 1000, project?.units?.distance));

  // Total price calculation
  const totalPricePerDistance = totalDistance * distancePrice;
  return `${(totalPricePerDistance + totalPricePerHour).toFixed(2)} ${currencyToSymbol(project.units?.currency)}`;
};

export default {};
