import { Grid, Tooltip, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Star, StarBorder } from '@material-ui/icons';
import { Button, Pill, VSpacer } from '@sm-highway-web/react-components';
import { IProjectData } from 'highway-api/dist/common/interfaces/projects';
import queryString from 'query-string';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import * as routing from '../../../constants/Routing';
import NormalDialog from '../../../dialogs/NormalDialog';
import history from '../../../helpers/History';
import NoProjects from '../../../pages/NoProjects';
import { Organization } from '../../../redux/actions';
import { projectHelpers, userHelpers } from '../../../redux/helpers';
import Avatar from '../../Avatar';
import FormattedMessage from '../../FormattedMessageCustom';
import OrganizationManageResourcesDialog from '../organization/OrganizationManageResourcesDialog';
import OrganizationNewProjectDialog from './OrganizationNewProjectDialog';

const styles = {
  listProjects: {
    maxHeight: '450px',
    overflowY: 'auto',
    flexWrap: 'unset',
  },
  listProjectsItem: {
    marginBottom: '20px',
    marginRight: '8px',
  },
  listProjectsItemPill: {
    height: '92px',
  },
};

type OwnOrganizationProjectsProps = {
  classes: {
    listProjects: string;
    listProjectsItem: string;
    listProjectsItemPill: string;
  };
  actions: {
    organizationEdit: (...args: any[]) => any;
  };
  location: {
    search?: string;
  };
  organizationSubscription?: {
    plan?: {
      type?: string;
    };
  };
  organizationLabel?: string;
  organizationDefaultProjectId?: string;
  organizationProjects?: IProjectData[];
  organizationVehicles?: number;
};

type OrganizationProjectsProps = OwnOrganizationProjectsProps;

const OrganizationProjects = ({
  classes,
  actions,
  location,
  organizationLabel,
  organizationSubscription,
  organizationDefaultProjectId,
  organizationProjects,
  organizationVehicles,
}: OrganizationProjectsProps) => {
  const [hoverElementProjectId, setHoverElementProjectId] = useState('');
  const [newProjectDialogOpen, setNewProjectDialogOpen] = useState(false);
  const [manageResourcesDialogOpen, setManageResourcesDialogOpen] = useState(false);

  useEffect(() => {
    if (location.search) {
      const searchParams = queryString.parse(location.search);
      if (searchParams.create && searchParams.create === 'true') {
        setNewProjectDialogOpen(true);
      }
    }
  }, [setNewProjectDialogOpen, location]);

  if (!organizationProjects) {
    return null;
  }

  return (
    <>
      <Grid container direction="column">
        <Grid item container style={{ padding: '8px', borderBottom: '1px solid #D7DAE2' }}>
          <Grid item container direction="row" justify="space-between" alignItems="center">
            <Grid item>
              <Typography variant="h2">
                <FormattedMessage id="settings.organization.projects.title" />
              </Typography>
            </Grid>

            {(userHelpers.currentUser.isOrganizationAdmin() ||
              userHelpers.currentUser.isOrganizationManager()) && (
              <Grid item>
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    history.push(`${routing.SETTINGS.ORGANIZATION.PROJECTS.ROOT}?create=true`);
                  }}
                >
                  <FormattedMessage id="settings.organization.projects.buttons.create" />
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>

        <VSpacer large />

        <Grid item>
          <Grid container direction="column" className={classes.listProjects}>
            {organizationProjects.length === 0 && <NoProjects />}

            {organizationProjects.length > 0 &&
              organizationProjects.map((project: IProjectData) => (
                <Grid
                  item
                  key={project.id}
                  className={classes.listProjectsItem}
                  onMouseEnter={() => setHoverElementProjectId(project.id)}
                  onMouseLeave={() => setHoverElementProjectId('')}
                >
                  <Pill
                    classes={{ root: classes.listProjectsItemPill }}
                    avatar={
                      <Avatar
                        data={project as any}
                        labelOverlayed
                        label={`${projectHelpers.getProjectLabel(
                          project.label,
                          organizationLabel
                        )}`}
                        size={50}
                      />
                    }
                    info={(
                      <Grid container direction="column">
                        <Grid item container direction="row">
                          <Tooltip
                            placement="top"
                            title={projectHelpers.getProjectLabel(project.label, organizationLabel)}
                          >
                            <Typography variant="h3" style={{ fontSize: '22px' }}>
                              {projectHelpers.getProjectLabel(project.label, organizationLabel).length > 30
                                ? `${projectHelpers.getProjectLabel(project.label, organizationLabel).substring(0, 30)}...`
                                : `${projectHelpers.getProjectLabel(project.label, organizationLabel)}`}
                            </Typography>
                          </Tooltip>

                          {project.id === organizationDefaultProjectId && (
                            <Tooltip
                              title={
                                <span style={{ textAlign: 'center' }}>
                                  <FormattedMessage id="settings.organization.projects.default_project" />
                                </span>
                              }
                            >
                              <Star style={{ color: '#DAA520' }} />
                            </Tooltip>
                          )}

                          {(userHelpers.currentUser.isOrganizationAdmin() ||
                            userHelpers.currentUser.isOrganizationManager()) &&
                            project.id !== organizationDefaultProjectId &&
                            hoverElementProjectId === project.id && (
                            <Tooltip
                              title={
                                <span style={{ textAlign: 'center' }}>
                                  <FormattedMessage id="settings.organization.projects.set_default_project" />
                                </span>
                              }
                            >
                              <StarBorder
                                style={{ color: '#DAA520', cursor: 'pointer' }}
                                onClick={() => {
                                  actions.organizationEdit({ default_project_id: project.id });
                                }}
                              />
                            </Tooltip>
                          )}
                        </Grid>
                        {project.description && (
                          <Grid item container direction="row">
                            <Typography
                              variant="h5"
                              style={{
                                fontSize: '16px',
                                color: '#90949c',
                                height: '18px',
                                maxWidth: '320px',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                whiteSpace: 'nowrap',
                              }}
                            >
                              {project.description}
                            </Typography>
                          </Grid>
                        )}
                        <Grid item>
                          <span
                            style={{
                              cursor: `${
                                userHelpers.currentUser.isOrganizationAdmin() ||
                                userHelpers.currentUser.isOrganizationManager()
                                  ? 'pointer'
                                  : 'default'
                              }`,
                              fontSize: '16px',
                              color: '#FF8200',
                              fontFamily: 'Work Sans',
                              border: 'none',
                            }}
                            onClick={() => {
                              if (
                                userHelpers.currentUser.isOrganizationAdmin() ||
                                userHelpers.currentUser.isOrganizationManager()
                              ) {
                                setManageResourcesDialogOpen(true);
                              }
                            }}
                          >
                            <FormattedMessage
                              id="settings.organization.projects.buttons.vehicles"
                              values={{
                                vehicles: project?.limits?.max_vehicles,
                              }}
                            />
                          </span>
                        </Grid>
                      </Grid>
                    )}
                    actions={[
                      <Button
                        color="primary"
                        variant="contained"
                        onClick={() => {
                          history.push(
                            `${routing.SETTINGS.ORGANIZATION.PROJECTS.ROOT}/${project.id}`
                          );
                        }}
                      >
                        {userHelpers.currentUser.isOrganizationAdmin() ||
                        userHelpers.currentUser.isOrganizationManager() ? (
                              <FormattedMessage id="settings.organization.projects.buttons.settings" />
                            ) : (
                              <FormattedMessage id="settings.organization.projects.buttons.view" />
                            )}
                      </Button>,
                    ]}
                  />
                </Grid>
              ))}
          </Grid>
        </Grid>

        {(userHelpers.currentUser.isOrganizationAdmin() ||
          userHelpers.currentUser.isOrganizationManager()) && (
          <>
            <VSpacer medium />

            <Grid item>
              <Grid item container style={{ padding: '8px', borderBottom: '1px solid #D7DAE2' }}>
                <Grid item container direction="row">
                  <Grid item>
                    <Typography variant="h2">
                      <FormattedMessage id="settings.organization.projects.resources.title" />
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>

              <VSpacer large />

              <Grid item>
                <Grid container direction="column">
                  <Grid item>
                    <Typography>
                      <FormattedMessage id="settings.organization.projects.resources.info" />
                    </Typography>
                  </Grid>

                  <VSpacer medium />

                  <Grid item>
                    <Typography>
                      <FormattedMessage
                        id="settings.organization.projects.resources.usage"
                        values={{
                          used: organizationProjects.reduce(
                            (old: any, next: any) =>
                              (next.limits ? next.limits.max_vehicles : 0) + old,
                            0
                          ),
                          total: organizationVehicles,
                        }}
                      />
                    </Typography>
                  </Grid>

                  <VSpacer medium />

                  <Grid item>
                    <Button
                      color="primary"
                      variant="contained"
                      onClick={() => setManageResourcesDialogOpen(true)}
                    >
                      <FormattedMessage id="settings.organization.projects.buttons.manage_resources" />
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </>
        )}
      </Grid>

      {organizationSubscription &&
      organizationSubscription.plan &&
      organizationSubscription.plan.type !== 'monkey' ? (
            <>
              {userHelpers.currentUser.isOrganizationAdmin() ||
          userHelpers.currentUser.isOrganizationManager() ? (
                    <OrganizationNewProjectDialog
                      isOpen={newProjectDialogOpen}
                      availableVehicles={
                        (organizationVehicles && organizationProjects
                          ? organizationVehicles - (organizationProjects.reduce(
                              (old: any, next: any) => (next.limits ? next.limits.max_vehicles : 0) + old,
                              0
                            ))
                          : 0)
                      }
                      onClose={() => {
                        setNewProjectDialogOpen(false);
                        history.push(routing.SETTINGS.ORGANIZATION.PROJECTS.ROOT);
                      }}
                    />
                  ) : (
                    <NormalDialog
                      open={newProjectDialogOpen}
                      handleOpenCloseDialog={() => setNewProjectDialogOpen(false)}
                      title={<FormattedMessage id="organization.not_privileged.title" />}
                      subtitle={<FormattedMessage id="organization.not_privileged.subtitle" />}
                      cancelText={<FormattedMessage id="organization.not_privileged.closedialog" />}
                      actions={[]}
                    />
                  )}
            </>
          ) : (
            <NormalDialog
              open={newProjectDialogOpen}
              handleOpenCloseDialog={() => setNewProjectDialogOpen(false)}
              title={<FormattedMessage id="subscription.not_privileged.title" />}
              subtitle={<FormattedMessage id="subscription.not_privileged.subtitle" />}
              cancelText={<FormattedMessage id="subscription.not_privileged.closedialog" />}
              actions={[
                {
                  label: <FormattedMessage id="subscription.not_privileged.openplans" />,
                  variant: 'contained',
                  onClick: () => {
                    history.push(routing.SETTINGS.BILLING.SUBSCRIPTION);
                  },
                },
              ]}
            />
          )}

      <OrganizationManageResourcesDialog
        // @ts-expect-error ts-migrate(2322) FIXME: Type 'number | undefined' is not assignable to typ... Remove this comment to see the full error message
        organizationTotalVehicles={organizationVehicles}
        isOpen={manageResourcesDialogOpen}
        onClose={() => setManageResourcesDialogOpen(false)}
      />
    </>
  );
};

OrganizationProjects.defaultProps = {
  organizationProjects: undefined,
  organizationVehicles: 0,
  organizationLabel: '',
  organizationSubscription: undefined,
  organizationDefaultProjectId: undefined,
};

const mapStateToProps = (state: any) => {
  return {
    organizationLabel: state.organization.organization && state.organization.organization.label,
    organizationSubscription:
      state.organization.organization && state.organization.organization.subscription,
    organizationDefaultProjectId:
      state.organization.organization && state.organization.organization.default_project_id,
    organizationProjects: projectHelpers.orderProjects(state.projects.projects),
    organizationVehicles:
      state.organization.organization &&
      state.organization.organization.subscription &&
      state.organization.organization.subscription.plan &&
      state.organization.organization.subscription.plan.vehicles,
  };
};

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    organizationEdit: (projectData: any) => dispatch(Organization.editOrganization(projectData)),
  },
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
  // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ listProjects: { maxHeight: str... Remove this comment to see the full error message
)(withStyles(styles)(OrganizationProjects));
