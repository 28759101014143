import { Step, StepLabel, Stepper, Typography } from '@material-ui/core';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { getTaxAmountByUE } from '../../constants/Countries';
import { TAX_VALUE, totalPrice } from '../../helpers/Plans';
import { calculateTimeLeft } from '../../helpers/TimeDistance';
import { IOrganizationData } from '../../interfaces/organizations';
import { InfoError, Organization, User } from '../../redux/actions';
import configuration from '../../services/configuration';
import eventsPanel from '../../services/events';
import {
  getBillingAddress,
  getPaymentCard,
  postBillingAddress,
  postPaymentCardToken,
  postSubscriptionPlan,
} from '../../services/requests';
import FormattedMessage from '../FormattedMessageCustom';
import PaymentBillingAddress from './PaymentBillingAddress';
import PaymentConfirmation from './PaymentConfirmation';
import PaymentCongratulations from './PaymentCongratulations';
import PaymentMethod from './PaymentMethod';
import PaymentPlanSelector from './PaymentPlanSelector';

type PaymentRunwayProps = {
  actions: {
    sendStatusToSnackbar: (...args: any[]) => any;
    getUserDetails: (...args: any[]) => any;
  };
  onClose: (...args: any[]) => any;
  organization: IOrganizationData;
};

const PaymentRunway = ({ actions, onClose, organization }: PaymentRunwayProps) => {
  const stripePromise = loadStripe(configuration.STRIPE_API_KEY);
  const [step, setStep] = useState(0);
  const [data, setData] = useState({});

  useEffect(() => {
    const loadData = async () => {
      const card = await getPaymentCard();
      const billingAddress = await getBillingAddress();
      setData({ card, billingAddress });
    };
    loadData();
  }, []);

  const handleSCAPayment = async (clientSecret: any) => {
    // @ts-expect-error ts-migrate(2531) FIXME: Object is possibly 'null'.
    const response = await (await stripePromise).confirmCardPayment(clientSecret);
    if (response.error) {
      actions.sendStatusToSnackbar('infoerror.highway.subscription.error.sca_fail', 'error');
    } else {
      setStep(step + 1);
    }
  };

  const onNextStep = async (values: any) => {
    const { token, ...others } = values;
    const newValues = { ...data, ...others };
    let response;
    try {
      switch (step) {
        case 1:
          await postBillingAddress(values.billingAddress);
          break;
        case 2:
          if (token) {
            await postPaymentCardToken(token);
          }
          break;
        case 3:
          response = await postSubscriptionPlan(
            newValues.vehicles,
            newValues.plan,
            newValues.coupon ? newValues.coupon.id : undefined
          );
          // Check if SCA 2 steps verification is required to proceed
          if (
            response.data.latest_invoice &&
            response.data.latest_invoice.payment_status === 'requires_action'
          ) {
            return handleSCAPayment(response.data.latest_invoice.client_secret);
          }

          eventsPanel.changeSubscriptionPlan(
            'Free',
            newValues.plan,
            newValues.vehicles,
            organization.subscription.plan.vehicles,
            organization &&
              organization.subscription.status === 'trialing' &&
              calculateTimeLeft(organization.subscription.valid) > 0
              ? calculateTimeLeft(organization.subscription.valid)
              : 0
          );
          break;
        default:
          break;
      }
      setData({ ...data, ...values });
      setStep(step + 1);
    } catch (error) {
      if (error.response && error.response.data.messageId) {
        actions.sendStatusToSnackbar(`infoerror.${error.response.data.messageId}`, 'error');
      }
    }
    return {};
  };

  const onPreviousStep = () => {
    setStep(step - 1);
  };

  const renderStep = () => {
    let price;
    switch (step) {
      case 0:
        return <PaymentPlanSelector data={data} onNextStep={onNextStep} />;
      case 1:
        return (
          <PaymentBillingAddress
            // @ts-expect-error ts-migrate(2339) FIXME: Property 'billingAddress' does not exist on type '... Remove this comment to see the full error message
            billingAddress={data.billingAddress}
            onNextStep={onNextStep}
            onPreviousStep={onPreviousStep}
          />
        );
      case 2:
        return (
          <Elements stripe={stripePromise}>
            <PaymentMethod data={data} onNextStep={onNextStep} onPreviousStep={onPreviousStep} />
          </Elements>
        );
      case 3:
        return (
          <PaymentConfirmation
            data={data}
            onNextStep={onNextStep}
            onPreviousStep={onPreviousStep}
          />
        );
      case 4:
        price = totalPrice(
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'plan' does not exist on type '{}'.
          data.plan,
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'vehicles' does not exist on type '{}'.
          data.vehicles,
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'billingAddress' does not exist on type '... Remove this comment to see the full error message
          getTaxAmountByUE(data.billingAddress.country, TAX_VALUE),
          // @ts-expect-error ts-migrate(2339) FIXME: Property 'coupon' does not exist on type '{}'.
          data.coupon
        );

        // Google Analytics
        // @ts-expect-error ts-migrate(2339) FIXME: Property 'ga_send_event_customer_license' does not... Remove this comment to see the full error message
        window.ga_send_event_customer_license(price);

        return (
          <PaymentCongratulations
            // @ts-expect-error ts-migrate(2322) FIXME: Type '{ data: {}; onNextStep: () => void; }' is no... Remove this comment to see the full error message
            data={data}
            onNextStep={() => {
              actions.getUserDetails();
              onClose();
            }}
          />
        );
      default:
        return <></>;
    }
  };
  return (
    <>
      {step < 4 && (
        <Stepper activeStep={step} alternativeLabel>
          {[0, 1, 2, 3].map((i) => (
            <Step key={i}>
              <StepLabel>
                {step === i ? (
                  <Typography>
                    <FormattedMessage id={`paymentrunwaydialog.step2.stepperslabel.index${i}`} />
                  </Typography>
                ) : (
                  <Typography>
                    <FormattedMessage id={`paymentrunwaydialog.step2.stepperslabel.index${i}`} />
                  </Typography>
                )}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
      )}
      {renderStep()}
    </>
  );
};

const mapStateToProps = (state: any) => {
  return { organization: state.organization.organization };
};

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    sendStatusToSnackbar: (msg: any, type: any) =>
      dispatch(InfoError.sendStatusToSnackbar(msg, type)),
    getUserDetails: () => {
      dispatch(User.details());
      dispatch(Organization.getOrganization());
    },
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(PaymentRunway);
