import React from 'react';
import { Label } from '../form-fields/FormFields.styled';
import FormText from '../form-fields/FormText';

type CustomFieldFormTextProps = {
  onUpdate?: (...args: any[]) => any;
  fieldValue: string | number;
  projectField: any;
  readOnly?: boolean;
  onlyValues?: boolean;
  size?: 'medium' | 'large';
};

const CustomFieldFormText = ({ fieldValue, onUpdate, projectField, readOnly, onlyValues = false, size }: CustomFieldFormTextProps) => (
  <>
    {onlyValues ? 
        <span>{fieldValue}</span>
      :
      readOnly ? (
        <>
          <Label size={size}>{projectField.label}</Label>
          <span>{fieldValue}</span>
        </>
      ) : (
        <FormText
          key={projectField.id}
          label={projectField.label}
          fieldValue={fieldValue}
          handleEnter={(val: any) => {
            if(onUpdate) {
              onUpdate(val);
            }
          }}
          type={projectField.type === 'numerical' ? 'number' : 'text'}
          size={size}
        />
      )
    }
  </>
);

CustomFieldFormText.defaultProps = {
  readOnly: false,
  size: 'medium',
};

export default CustomFieldFormText;
