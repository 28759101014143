/* eslint-disable no-shadow */
/* eslint-disable camelcase */
import axios from 'axios';
import { IPlanData } from 'highway-api/dist/common/interfaces/plans';
import { IServiceDataExtended } from 'highway-api/dist/common/interfaces/services';
import { SERVICE_STATUS } from '../constants/Services';
import { projectHelpers } from '../redux/helpers';
import store from '../redux/store/index';
import configuration from './configuration';
import eventsPanel from './events';
import getHighway from './highway';

export const checkAsUser = () => {
  return store.getState().user.asUser;
};

export async function get(call: any, params: any, headers = {}) {
  const url = `${configuration.HIGHWAY_BACK_END}${call}`;
  return axios.get(url, {
    params: { ...params, ...(checkAsUser() && { 'as_user': checkAsUser() }) },
    headers,
  });
}

export async function post(call: any, params: any, headers = {}) {
  const url = `${configuration.HIGHWAY_BACK_END}${call}`;
  return axios.post(url, params, {
    headers,
    ...(checkAsUser() && { params: { 'as_user': checkAsUser() } }),
  });
}

export async function put(call: any, json: any, headers = {}) {
  const url = `${configuration.HIGHWAY_BACK_END}${call}`;
  return axios.put(url, json, {
    headers,
    ...(checkAsUser() && { params: { 'as_user': checkAsUser() } }),
  });
}

export async function deleteQuery(call: any, headers = {}) {
  const url = `${configuration.HIGHWAY_BACK_END}${call}`;
  return axios.delete(url, {
    headers,
    ...(checkAsUser() && { params: { 'as_user': checkAsUser() } }),
  });
}

export async function getOrganizationInvitation(token: any) {
  // @ts-expect-error ts-migrate(2554) FIXME: Expected 2-3 arguments, but got 1.
  return get(`/organization/invitation/${token}`)
    .then((response) => response.data)
    .catch((error) => {
      /* eslint-disable-next-line no-console */
      console.warn('getOrganizationInvitation error:', error);
      return null;
    });
}

export async function acceptOrganizationUserInvitation(token: any, userData: any) {
  return post(`/organization/invitation/${token}`, {
    ...userData,
    language: store.getState().user.language,
  })
    .then((response) => response.data)
    .catch((error) => {
      /* eslint-disable-next-line no-console */
      console.warn('acceptOrganizationUserInvitation error:', error);
      return null;
    });
}

export async function getResetPassword(email: any) {
  return get('/user/reset-password', { email });
}

export async function getNewPasswordValidate(token: any) {
  return get('/user/new-password/validate', { token });
}

export async function postNewPassword(token: any, password: any) {
  return post('/user/new-password', { token, password });
}

export async function resendValidationMail(email: any) {
  return get('/user/resend-validation', { email });
}

export async function postGeocode(address: any, countryCode = '') {
  const dataToSend =
    countryCode && countryCode !== '' ? { address, country_code: countryCode } : { address };
  return post('/api/v1/utils/geocode', dataToSend, {
    Authorization: `Bearer ${store.getState().user.token}`,
  });
}

export const getProject = (projectId: any) => {
  const highway = getHighway(store.getState().user.token);
  return highway.project.get(projectId);
};

export async function getLogs(offset: any, limit: any, sort: any, text: any, filters: any) {
  try {
    const response = await get(
      '/logs',
      {
        ...(filters && filters.id ? { id: filters.id } : {}),
        ...(sort ? { sort } : {}),
        ...(offset ? { offset } : {}),
        ...(limit ? { limit } : {}),
        ...(filters && filters.requests ? { requests: JSON.stringify(filters.requests) } : {}),
        ...(filters && filters.key ? { key: filters.key } : {}),
      },
      { Authorization: `Bearer ${store.getState().user.token}` }
    );
    return response.data;
  } catch {
    return {
      docs: [],
      total: 0,
      offset,
      limit,
      page: 1,
      pages: 1,
    };
  }
}

export async function getAdminUsers(
  text?: any,
  offset?: any,
  limit?: any,
  sort?: any,
  subscriptionStatus?: any
) {
  const response = await get(
    '/users',
    {
      ...(text ? { text } : {}),
      ...(sort ? { sort } : {}),
      ...(offset ? { offset } : {}),
      ...(limit ? { limit } : {}),
      subscriptionStatus,
    },
    { Authorization: `Bearer ${store.getState().user.token}` }
  );
  return response.data;
}

export async function validateCoupon(coupon: any) {
  const response = await get(
    `/subscription/coupon/${coupon}`,
    {},
    { Authorization: `Bearer ${store.getState().user.token}` }
  );
  return response.data;
}

export async function getClients(text?: any, offset?: any, limit?: any, sort?: any, deleted?: any) {
  const { user } = store.getState().user;
  let projectId = projectHelpers.getCurrentProjectId();
  if (projectId === '') projectId = user.default_project_id;

  const response = await get(
    '/api/v1/clients',
    {
      project_id: projectId,
      ...(text ? { text } : {}),
      ...(sort ? { sort } : {}),
      ...(offset ? { offset } : {}),
      ...(limit ? { limit } : {}),
      ...(deleted ? { deleted } : {}),
    },
    { Authorization: `Bearer ${store.getState().user.token}` }
  );
  return response.data;
}

export async function putSubscriptionLimits(userId: string, maxCustomerEmails: number | undefined) {
  const response = await put(
    `/subscription/limits?user=${userId}`,
    {
      max_customer_emails: maxCustomerEmails,
    },
    { Authorization: `Bearer ${store.getState().user.token}` }
  );
  return response.data;
}

export async function getVehicles(text?: any, offset?: any, limit?: any, sort?: any, deleted?: any) {
  const { user } = store.getState().user;
  let projectId = projectHelpers.getCurrentProjectId();
  if (projectId === '') projectId = user.default_project_id;

  const response = await get(
    '/api/v1/vehicles',
    {
      project_id: projectId,
      ...(text ? { text } : {}),
      ...(sort ? { sort } : {}),
      ...(offset ? { offset } : {}),
      ...(limit ? { limit } : {}),
      ...(deleted ? { deleted } : {}),
    },
    { Authorization: `Bearer ${store.getState().user.token}` }
  );
  return response.data;
}

export async function getPlans(
  text: any,
  status: any,
  offset: any,
  limit: any,
  sort: any,
  deleted: any,
  fromDate: any,
  toDate: any
) {
  const highway = getHighway(store.getState().user.token);
  const { user } = store.getState().user;

  let projectId = projectHelpers.getCurrentProjectId();

  if (projectId === '') projectId = user.default_project_id;

  return highway.plan.list(projectId, text, status, fromDate, toDate, sort, offset, limit);
}

export async function getPlan(planId: any) {
  const highway = getHighway(store.getState().user.token);
  return highway.plan.get(planId);
}

/**
 * Copies a plan data depending on filters.
 * @param {*} sourcePlanId
 * @param {*} planLabel
 * @param {object} planFilters
 * {
 *    routes {boolean} - Copy all routes.
 *    services: {
 *        unassigned {boolean} - Copy all unassigned routes.
 *        pending {boolean} - Copy all not finished routes.
 *        canceled {boolean} - Copy all canceled routes.
 *        completed {boolean} - Copy all completed routes.
 *        cleanpod {boolean} - Cleans all services proof of delivery.
 *    }
 * }
 * 
 * TODO: Response of type any should be the highway error object.
 */
export const copyPlan = async (sourcePlanId: any, planLabel: any, planFilters: any) : Promise<IPlanData | any> => {
  const highway = getHighway(store.getState().user.token);

  return highway.plan
    .get(sourcePlanId)
    .then(async (response) => {
      const {
        id,
        label,
        project_id,
        organization_id,
        _version,
        created_at,
        created_by,
        updated_at,
        total_routes,
        total_services,
        completed_services,
        canceled_services,
        pending_services,
        ...cleanPlan
      } = response;

      const filterRoute = (route: any, cleanPOD = false, includeServices = false) => {
        const {
          id,
          project_id,
          plan_id,
          services,
          created_at,
          deleted_at,
          created_by,
          updated_at,
          planned_end_time,
          planned_start_time,
          ...other
        } = route;
        return {
          ...other,
          ...(includeServices
            ? {
                services: services
                  .filter((service: any) => service.type !== 'pickup')
                  .map((service: any) => filterService(service, cleanPOD)),
              }
            : {}),
        };
      };

      // @ts-expect-error ts-migrate(7024) FIXME: Function implicitly has return type 'any' because ... Remove this comment to see the full error message
      const filterService = (service: any, cleanPOD = false) => {
        const {
          id,
          type,
          order,
          pickup,
          project_id,
          plan_id,
          route_id,
          optional,
          pickup_id,
          created_at,
          deleted_at,
          created_by,
          updated_at,
          feedback,
          done_at,
          done_by,
          done_error,
          status,
          done_location,
          planned_arrival_time,
          planned_departure_time,
          distance_to_next_location,
          distance_to_previous_location,
          route_started_email_at,
          service_approaching_email_at,
          service_completed_email_at,
          service_canceled_email_at,
          ...other
        } = service;
        // @ts-expect-error ts-migrate(7022) FIXME: 'filteredPickup' implicitly has type 'any' because... Remove this comment to see the full error message
        const filteredPickup = pickup && filterService(pickup, cleanPOD);
        return {
          ...other,
          ...(!cleanPOD
            ? {
                done_at,
                status,
                done_location,
                feedback,
                // Not for the moment. This values should be generated by backend.
                // route_started_email_at,
                // service_approaching_email_at,
                // service_completed_email_at,
                // service_canceled_email_at,
              }
            : {}),
          ...(filteredPickup ? { pickup: filteredPickup } : {}),
        };
      };

      if (
        [
          planFilters.routes,
          planFilters.services.unassigned,
          planFilters.services.pending,
          planFilters.services.canceled,
          planFilters.services.completed,
        ].some((filter) => !filter)
      ) {
        cleanPlan.services = cleanPlan.services
          .filter(
            (service: IServiceDataExtended) =>
              service.type !== 'pickup' &&
              ((planFilters.services.unassigned && !service.route_id) ||
                (planFilters.services.pending && service.status === SERVICE_STATUS.pending) ||
                (planFilters.services.canceled && service.status === SERVICE_STATUS.canceled) ||
                (planFilters.services.completed && service.status === SERVICE_STATUS.completed))
          )
          .map((service: IServiceDataExtended) => filterService(service, planFilters.services.cleanpod));

        cleanPlan.routes = (planFilters.routes) ? cleanPlan.routes.map((route) => filterRoute(route)) : [];
      } else {
        cleanPlan.routes = cleanPlan.routes.map((route) =>
          filterRoute(route, planFilters.services.cleanpod, true)
        );

        cleanPlan.services = cleanPlan.services
          .filter((service: IServiceDataExtended) => service.type !== 'pickup' && !service.route_id)
          .map((service: IServiceDataExtended) => filterService(service, planFilters.services.cleanpod));
      }

      const createdPlan = await highway.plan.create({ label: planLabel, ...cleanPlan }, project_id!);

      eventsPanel.planCreated();

      return createdPlan;
    });
};

export async function getAcquisitionStats(startDate: any, endDate = new Date()) {
  const response = await get(
    '/stats/acquisition',
    { startDate, endDate },
    { Authorization: `Bearer ${store.getState().user.token}` }
  );
  return response;
}

export async function getUsageStats(startDate: any, endDate = new Date()) {
  const response = await get(
    '/stats/usage',
    { startDate, endDate },
    { Authorization: `Bearer ${store.getState().user.token}` }
  );
  return response;
}

export async function getAutocompleteGeocode(searchValue: any, countryCode: string | null = '') {
  const countryCodeQuery = `${
    countryCode && countryCode !== null && countryCode !== '' ? `&country_code=${countryCode}` : ``
  }`;
  const response = await post(
    `/api/v1/utils/geocode/autocomplete?query=${window.encodeURIComponent(
      searchValue
    )}${countryCodeQuery}`,
    {},
    { Authorization: `Bearer ${store.getState().user.token}` }
  );

  return response.status === 200 ? response.data : [];
}

export async function getLocationFromId(locationId: any) {
  const response = await post(
    `/api/v1/utils/geocode/details?location_id=${locationId}`,
    {},
    { Authorization: `Bearer ${store.getState().user.token}` }
  );

  return response.status === 200 ? response.data : [];
}

export async function getLocationFromLatLng(lat: any, lng: any) {
  const response = await post(
    `/api/v1/utils/geocode/reverse?lat=${lat}&lng=${lng}`,
    {},
    { Authorization: `Bearer ${store.getState().user.token}` }
  );

  return response.status === 200 ? { ...response.data, lat, lng } : [];
}

export async function getUploadAsBase64(url: string): Promise<string | null> {
  return axios.get(url, {
    headers: {
      Authorization: `Bearer ${store.getState().user.token}`,
    },
    params: { ...(checkAsUser() && { 'as_user': checkAsUser() }) },
    responseType: 'arraybuffer',
  })
    .then(response => {
      if (response.status === 200) {
        const image = btoa(
          new Uint8Array(response.data).reduce((data, byte) => data + String.fromCharCode(byte), '')
        );
        return `data:${response.headers['content-type'].toLowerCase()};base64,${image}`;
      }
      return null;
    })
    .catch(_error => {
      return null;
    });
}

/**
 * Uploads a picture given a public authentication.
 * @param {*} fileName
 * @param {*} mimeType
 * @param {*} file
 * @param {*} publicKey
 */
export async function uploadImagePublic(fileName: any, mimeType: any, file: any, publicKey: any) {
  const formData = new FormData();
  formData.append('name', fileName);
  formData.append('mime', mimeType);
  formData.append('data', file);
  return post(`/uploads/public?public_key=${publicKey}`, formData, {
    'content-type': 'multipart/form-data',
  });
}

/**
 * Removes a picture given a public authentication.
 * @param {*} imageId
 * @param {*} publicKey
 */
export async function deleteImagePublic(imageId: any, publicKey: any) {
  return deleteQuery(`/uploads/${imageId}/public?public_key=${publicKey}`);
}

/**
 * Creates a new payment token. If there is already an existing token,
 * it will be replaced.
 * @param {string} token
 */
export const postPaymentCardToken = async (token: any) => {
  return post(
    `/subscription/token`,
    { token },
    { Authorization: `Bearer ${store.getState().user.token}` }
  );
};

/**
 * Gets the default card for a user,
 * it will be replaced.
 * @param {string} token
 */
export const getPaymentCard = async () => {
  return (
    await get(`/subscription/card`, {}, { Authorization: `Bearer ${store.getState().user.token}` })
  ).data;
};

export const getBillingAddress = async () => {
  return (
    await get(
      `/subscription/billing-address`,
      {},
      { Authorization: `Bearer ${store.getState().user.token}` }
    )
  ).data;
};

export const postBillingAddress = async (info: any) => {
  return post(`/subscription/billing-address`, info, {
    Authorization: `Bearer ${store.getState().user.token}`,
  });
};

export const postSubscriptionPlan = async (vehicles: any, plan: any, coupon: any) => {
  return post(
    `/subscription/plan`,
    { vehicles, plan, coupon },
    { Authorization: `Bearer ${store.getState().user.token}` }
  );
};

export const getSubscriptionInvoices = async () => {
  return (
    await get(
      `/subscription/invoices`,
      {},
      { Authorization: `Bearer ${store.getState().user.token}` }
    )
  ).data;
};

export const dispatchRouteByEmail = async (routeId: any) => {
  return post(
    `/api/v1/route/${routeId}/dispatch`,
    {},
    { Authorization: `Bearer ${store.getState().user.token}` }
  );
};

export const getUserLocation = async () => {
  const url = `https://get.geojs.io/v1/ip/geo.json`;
  const response = await axios.get(url);
  return response.status === 200 ? response.data : null;
};
