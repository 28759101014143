import { Typography } from '@material-ui/core';
import React, { PureComponent } from 'react';
import { Dialog } from '@sm-highway-web/react-components';

type Props = {
  open: boolean;
  handleOpenCloseDialog: (...args: any[]) => any;
  title: string | {};
  subtitle: string | {};
  cancelText: string | {};
  actions: {}[];
};

class NormalDialog extends PureComponent<Props> {
  render() {
    const { open, handleOpenCloseDialog, title, subtitle, cancelText, actions } = this.props;
    return (
      <Dialog
        modal
        onClose={handleOpenCloseDialog}
        open={open}
        title={title}
        closeText={cancelText}
        showCancel={cancelText}
        moreActions={actions}
      >
        <Typography style={{ width: '100%' }}>{subtitle}</Typography>
      </Dialog>
    );
  }
}

export default NormalDialog;
