import { Grid, IconButton, OutlinedInput } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Delete } from '@material-ui/icons';
import React, { useEffect, useState } from 'react';
import FormattedMessage from '../FormattedMessageCustom';
import { Label, Placeholder } from './FormFields.styled';

const styles = () => ({
  container: {
    height: '100%',
    width: '100%',
  },
  noPadding: {
    padding: '2px 4px',
  },
  children: {
    '&:hover': {
      backgroundColor: '#EEEFF2',
    },
    borderRadius: '2px',
    padding: '2px 4px',
    width: 'calc(100% - 8px)',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
});

export function validateEmail(email: any) {
  /* eslint-disable-next-line */
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(String(email).toLowerCase());
}

type FormTextProps = {
  classes: {
    container?: string;
    noPadding?: string;
    children?: string;
  };
  handleEnter: (...args: any[]) => any;
  suffix?: string | React.ReactElement;
  fieldValue?: string | number;
  noWrap?: boolean;
  variant?: 'title' | 'label' | 'heading';
  label?: string | React.ReactElement | {};
  type?: 'number' | 'url' | 'integer' | 'text' | 'email';
  disabled?: boolean;
  rowNumber?: number;
  placeholder?: string | React.ReactElement | {};
  onDelete?: (...args: any[]) => any;
  size?: 'medium' | 'large';
};

const FormText = ({
  type,
  handleEnter,
  classes,
  noWrap,
  variant,
  label,
  suffix,
  fieldValue,
  disabled,
  rowNumber,
  placeholder,
  onDelete,
  size,
}: FormTextProps) => {
  const [editing, setEditing] = useState(false);
  const [value, setValue] = useState(fieldValue);

  const hasChanged = () => {
    if (type === 'number') {
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | number | undefined' is ... Remove this comment to see the full error message
      const valueNumber = Number.parseFloat(value);
      // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | number | undefined' is ... Remove this comment to see the full error message
      const fieldValueNumber = Number.parseFloat(fieldValue);
      if (Number.isNaN(valueNumber) && Number.isNaN(fieldValueNumber)) {
        return false;
      }
      return valueNumber !== fieldValueNumber;
    }

    return value !== fieldValue;
  };

  useEffect(() => {
    setValue(fieldValue);
  }, [fieldValue]);

  const handleClick = () => {
    setEditing(true);
  };

  const handleChange = (event: any) => {
    setValue(event.target.value);
  };

  const handleUpdate = async () => {
    let tmpValue;
    if (handleEnter && hasChanged()) {
      if (type === 'number' || type === 'integer') {
        if (type === 'number') {
          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | number | undefined' is ... Remove this comment to see the full error message
          tmpValue = Number.parseFloat(value);
        } else if (type === 'integer') {
          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'string | number | undefined' is ... Remove this comment to see the full error message
          tmpValue = Number.parseInt(value, 10);
        }
        if (Number.isNaN(tmpValue)) {
          tmpValue = null;
          setValue(fieldValue);
        }
        handleEnter(tmpValue);
      } else if (type === 'email') {
        handleEnter(validateEmail(value) ? `${value}` : null);
      } else {
        handleEnter(value ? `${value}` : null);
      }
    }
  };

  const handleKeyPress = async (event: any) => {
    switch (event.key) {
      case 'Enter':
        await handleUpdate();
        setEditing(false);
        break;
      case 'Escape':
        setValue(fieldValue);
        setEditing(false);
        break;
      default:
        break;
    }
  };

  const handleBlur = async () => {
    await handleUpdate();
    setEditing(false);
  };

  const style = {
    ...(variant === `title` ? { fontSize: 18 } : {}),
    ...(variant === `heading` ? { fontSize: 22, color: 'white' } : {}),
    minHeight: '32px',
    padding: 0,
    cursor: 'pointer',
    ...(noWrap ? { whiteSpace: 'nowrap' } : {}),
  };

  const textField = (
    <Grid
      container
      alignItems="center"
      className={classes.children}
      // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
      style={style}
      onClick={handleClick}
      justify="space-between"
    >
      {fieldValue ? (
        type === 'url' ? (
          <span style={{ color: '#ff8200' }}>{fieldValue}</span>
        ) : (
          <span>
            {fieldValue}
            {suffix && <span>{suffix}</span>}
          </span>
        )
      ) : (
        <Placeholder size={size}>{suffix || placeholder || <FormattedMessage id="none" />}</Placeholder>
      )}

      {onDelete && fieldValue && fieldValue !== '' && (
        // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
        <IconButton
          variant="text"
          style={{ height: '24px', width: '24px', padding: '0' }}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onDelete();
          }}
        >
          <Delete style={{ width: '20px', height: '20px' }} />
        </IconButton>
      )}
    </Grid>
  );

  return (
    <div className={classes.container}>
      {label && <Label size={size}>{label}</Label>}
      {editing ? (
        <OutlinedInput
          // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
          style={style}
          onKeyDown={handleKeyPress}
          onBlur={handleBlur}
          value={value}
          onChange={handleChange}
          autoFocus
          fullWidth
          classes={{ input: classes.noPadding }}
          type={type}
          disabled={disabled}
          multiline={Boolean(rowNumber)}
          rows={rowNumber}
          rowsMax={rowNumber}
        />
      ) : (
        textField
      )}
    </div>
  );
};

FormText.defaultProps = {
  fieldValue: undefined,
  type: 'text',
  noWrap: false,
  variant: undefined,
  label: undefined,
  suffix: undefined,
  disabled: false,
  rowNumber: undefined,
  placeholder: undefined,
  onDelete: undefined,
};

// @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ ({ type, handleEnter, classes,... Remove this comment to see the full error message
export default withStyles(styles)(FormText);
