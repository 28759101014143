import { Chip, CircularProgress, Grid, Tooltip, Typography, withStyles } from '@material-ui/core';
import { VSpacer } from '@sm-highway-web/react-components';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import WarningIcon from '../../../assets/svg/WarningIcon';
import { getSubscriptionInvoices } from '../../../services/requests';
import VirtualizedMaterialDataTable from '../../datatable/VirtualizedDataTable';
import FormattedMessage from '../../FormattedMessageCustom';

const styles = {
  root: {
    height: '100%',
    width: '100%',
    maxWidth: '800px',
  },
  content: {
    width: '100%',
    height: '100%',
    display: 'flex',
    overflowX: 'auto',
    overflowY: 'hidden',
    flex: 1,
  },
  noPaymentDiv: {
    border: '1px solid #FCCA46',
    borderRadius: '8px',
    backgroundColor: 'rgba(248,231,28,0.1)',
    padding: '8px',
    height: '42px',
  },
  billingsError: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    border: '1px solid #DD4444',
    borderRadius: '8px',
    backgroundColor: 'rgba(255, 40, 37, 0.1)',
    padding: '8px',
    height: '42px',
  },
};

const StatusColors = {
  draft: '#e3e8ee',
  open: '#cbf4c9',
  paid: '#d6ecff',
  uncollectible: '#fee3c0',
  void: '#e6e6fc',
};

type Props = {
  classes: {
    root: string;
    content: string;
    noPaymentDiv: string;
    billingsError: string;
  };
};

const BillingInvoices = ({ classes }: Props) => {
  const [invoices, setInvoices] = useState([]);
  const [loading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    (async function getInvoices() {
      setLoading(true);
      try {
        const response = await getSubscriptionInvoices();
        setInvoices(response);
        setLoading(false);
      } catch (_) {
        setHasError(true);
        setLoading(false);
      }
    })();
  }, []);

  return (
    <Grid container direction="column" className={classes.root}>
      <Grid item>
        <Typography variant="h2">
          <FormattedMessage id="settings.billing.invoices.title" />
        </Typography>
      </Grid>

      <VSpacer />

      <Grid item className={classes.content}>
        {loading && (
          <Grid container direction="column" justify="center">
            {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
            <Grid item align="center">
              <CircularProgress className="form-ignore" size={32} />
            </Grid>
            <VSpacer medium />
            {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
            <Grid item align="center">
              <FormattedMessage id="settings.billing.invoices.loading" />
            </Grid>
          </Grid>
        )}

        {!loading && (
          <>
            {hasError && (
              <div className={classes.billingsError}>
                <WarningIcon style={{ float: 'left', marginRight: '16px', color: '#DD4444' }} />
                <Typography style={{ float: 'left' }}>
                  <FormattedMessage id="settings.billing.invoices.error" />
                </Typography>
              </div>
            )}

            {!hasError && invoices.length === 0 && (
              <Grid item container alignItems="center" className={classes.noPaymentDiv} sm={4}>
                <WarningIcon style={{ float: 'left', marginRight: '16px', color: '#FCCA46' }} />
                <Typography style={{ float: 'left' }}>
                  <FormattedMessage id="settings.billing.invoices.empty" />
                </Typography>
              </Grid>
            )}

            {invoices.length > 0 && (
              <VirtualizedMaterialDataTable
                showCheckboxes={false}
                docs={invoices}
                mapping={[
                  {
                    // @ts-expect-error ts-migrate(2322) FIXME: Type '{ width: number; column: string; align: stri... Remove this comment to see the full error message
                    width: 130,
                    column: 'date',
                    align: 'left',
                    name: <FormattedMessage id="settings.billing.invoices.date" />,
                    formatter: (id: any, doc: any, i: any, hover: any) => {
                      return <span>{moment(doc.date).format('YYYY/MM/DD hh:mm')}</span>;
                    },
                  },
                  {
                    // @ts-expect-error ts-migrate(2322) FIXME: Type '{ width: number; column: string; align: stri... Remove this comment to see the full error message
                    width: 220,
                    column: 'number',
                    align: 'left',
                    name: <FormattedMessage id="settings.billing.invoices.number" />,
                    formatter: (id: any, doc: any, i: any, hover: any) => {
                      return (
                        <Tooltip title={doc.number}>
                          <Typography noWrap style={{ maxWidth: '220px' }}>
                            {doc.number}
                          </Typography>
                        </Tooltip>
                      );
                    },
                  },
                  {
                    // @ts-expect-error ts-migrate(2322) FIXME: Type '{ width: number; column: string; align: stri... Remove this comment to see the full error message
                    width: 80,
                    column: 'amount',
                    align: 'center',
                    name: <FormattedMessage id="settings.billing.invoices.amount" />,
                    formatter: (id: any, doc: any, i: any, hover: any) => {
                      return (
                        <span>
                          {(doc.amount ? doc.amount : 0).toFixed(2)}
€
                        </span>
                      );
                    },
                  },
                  {
                    // @ts-expect-error ts-migrate(2322) FIXME: Type '{ width: number; column: string; align: stri... Remove this comment to see the full error message
                    width: 120,
                    column: 'status',
                    align: 'center',
                    name: <FormattedMessage id="settings.billing.invoices.status" />,
                    formatter: (id: any, doc: any, i: any, hover: any) => {
                      return (
                        <Chip
                          // @ts-expect-error ts-migrate(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                          style={{ backgroundColor: StatusColors[doc.status] }}
                          label={
                            <FormattedMessage
                              id={`settings.billing.invoices.status.${doc.status}`}
                            />
                          }
                        />
                      );
                    },
                  },
                  {
                    // @ts-expect-error ts-migrate(2322) FIXME: Type '{ column: string; name: JSX.Element; formatt... Remove this comment to see the full error message
                    column: 'id',
                    name: <FormattedMessage id="settings.billing.invoices.actions" />,
                    formatter: (id: any, doc: any, i: any, hover: any) => {
                      return (
                        <Grid container justify="flex-end">
                          <a href={doc.invoice_pdf} target="_self" rel="noopener noreferrer">
                            <FormattedMessage id="settings.billing.invoices.actions.download" />
                          </a>
                        </Grid>
                      );
                    },
                  },
                ]}
              />
            )}
          </>
        )}
      </Grid>
    </Grid>
  );
};

// @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ root: { height: string; width:... Remove this comment to see the full error message
export default withStyles(styles)(BillingInvoices);
