import getHighway from '../../services/highway';
import constants from '../constants';
import { projectHelpers } from '../helpers';
import store from '../store/index';

export const getFlatClients = () => {
  return (dispatch: any) => {
    const highway = getHighway(store.getState().user.token);
    return highway.client
      .listFlat(projectHelpers.getCurrentProjectId())
      .then((response) => {
        dispatch({
          type: constants.Clients.CLIENTS_GET_FLAT_CLIENTS_SUCCESS,
          payload: response,
        });
      })
      .catch((error) => {
        dispatch({
          type: constants.Clients.CLIENTS_GET_FLAT_CLIENTS_ERROR,
          payload: error,
        });
      });
  };
};

export const getClients = (offset: any, limit: any, search: any, sort: any) => {
  return (dispatch: any) => {
    const highway = getHighway(store.getState().user.token);
    return highway.client
      .list(projectHelpers.getCurrentProjectId(), offset, limit, search, sort)
      .then((response) => {
        dispatch({
          type: constants.Clients.CLIENTS_GET_CLIENTS_SUCCESS,
          payload: response,
        });
      })
      .catch((error) => {
        dispatch({
          type: constants.Clients.CLIENTS_GET_CLIENTS_ERROR,
          payload: error,
        });
      });
  };
};

export const postClient = (label: any, icon: any, location: any) => {
  return (dispatch: any) => {
    const highway = getHighway(store.getState().user.token);
    highway.client
      .create({ label, icon, location }, projectHelpers.getCurrentProjectId())
      .then((response) => {
        const { offset, limit } = store.getState().clients.clients;
        dispatch(getFlatClients()); // Es pot fer sense cridar backend
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 4 arguments, but got 2.
        dispatch(getClients(offset, limit));
        dispatch({
          type: constants.Clients.CLIENTS_CREATE_CLIENT_SUCCESS,
          payload: response,
        });
      })
      .catch((error) => {
        dispatch({
          type: constants.Clients.CLIENTS_CREATE_CLIENT_ERROR,
          payload: error,
        });
      });
  };
};
export const createClients = (clients: any) => {
  return (dispatch: any) => {
    const highway = getHighway(store.getState().user.token);
    highway.client
      .createMany(clients, projectHelpers.getCurrentProjectId())
      .then((response) => {
        const { offset, limit } = store.getState().clients.clients;
        dispatch(getFlatClients()); // Es pot fer sense cridar backend
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 4 arguments, but got 2.
        dispatch(getClients(offset, limit));
        dispatch({
          type: constants.Clients.CLIENTS_CREATE_CLIENTS_SUCCESS,
          payload: response,
        });
      })
      .catch((error) => {
        dispatch({
          type: constants.Clients.CLIENTS_CREATE_CLIENTS_ERROR,
          payload: error,
        });
      });
  };
};

export const putClient = (clientId: any, data: any) => {
  return (dispatch: any) => {
    const highway = getHighway(store.getState().user.token);
    highway.client
      .update(clientId, data)
      .then((response) => {
        const { offset, limit } = store.getState().clients.clients;
        dispatch(getFlatClients());
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 4 arguments, but got 2.
        dispatch(getClients(offset, limit));
        dispatch({
          type: constants.Clients.CLIENTS_UPDATE_CLIENT_SUCCESS,
          payload: response,
        });
      })
      .catch((error) => {
        dispatch({
          type: constants.Clients.CLIENTS_UPDATE_CLIENT_ERROR,
          payload: error,
        });
      });
  };
};

export const putClients = (clients: any, data: any) => {
  return (dispatch: any) => {
    const highway = getHighway(store.getState().user.token);
    Promise.all(clients.map((clientId: any) => highway.client.update(clientId, data)))
      .then((response: any) => {
        const { offset, limit } = store.getState().clients.clients;
        dispatch(getFlatClients());
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 4 arguments, but got 2.
        dispatch(getClients(offset, limit));
        dispatch({
          type: constants.Clients.CLIENTS_UPDATE_CLIENT_SUCCESS,
          payload: response,
        });
      })
      .catch((error: any) => {
        dispatch({
          type: constants.Clients.CLIENTS_UPDATE_CLIENT_ERROR,
          payload: error,
        });
      });
  };
};

export const deleteClient = (clientId: any) => {
  return (dispatch: any) => {
    const highway = getHighway(store.getState().user.token);
    highway.client
      .delete(clientId)
      .then((response) => {
        const { offset, limit } = store.getState().clients.clients;
        dispatch(getFlatClients());
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 4 arguments, but got 2.
        dispatch(getClients(offset, limit));
        dispatch(removeClientSelection());
        dispatch({
          type: constants.Clients.CLIENTS_DELETE_CLIENT_SUCCESS,
          payload: response,
        });
      })
      .catch((error) => {
        dispatch({
          type: constants.Clients.CLIENTS_DELETE_CLIENT_ERROR,
          payload: error,
        });
      });
  };
};

export const deleteClients = (clients: any) => {
  return (dispatch: any) => {
    const highway = getHighway(store.getState().user.token);
    Promise.all(clients.map((clientId: any) => highway.client.delete(clientId))).then(
      (allResponses: any) => {
        const { offset, limit } = store.getState().clients.clients;
        dispatch(getFlatClients());
        // @ts-expect-error ts-migrate(2554) FIXME: Expected 4 arguments, but got 2.
        dispatch(getClients(offset, limit));
        dispatch(removeClientSelection());
        dispatch({
          type: constants.Clients.CLIENTS_DELETE_CLIENT_SUCCESS,
          payload: allResponses,
        });
      }
    );
  };
};

export const setSelectedClients = (clients: any) => {
  return (dispatch: any) => {
    dispatch({
      type: constants.Clients.CLIENTS_SET_SELECTED_CLIENTS,
      payload: clients,
    });
  };
};

export const removeClientSelection = () => {
  return (dispatch: any) => {
    dispatch({
      type: constants.Clients.CLIENTS_REMOVE_SELECTED,
    });
  };
};

export const selectClient = (id: any) => {
  return (dispatch: any) => {
    const highway = getHighway(store.getState().user.token);
    highway.client
      .get(id)
      .then((response) => {
        dispatch({
          type: constants.Clients.CLIENTS_GET_CLIENT_SUCCESS,
          payload: response,
        });
      })
      .catch((error) => {
        dispatch({
          type: constants.Clients.CLIENTS_GET_CLIENT_ERROR,
          payload: error,
        });
      });
  };
};
