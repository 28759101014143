import { Grid, Typography, withStyles } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { VSpacer } from '@sm-highway-web/react-components';
import { getAdminUsers } from '../services/requests';

const styles = () => ({
  containerDiv: {
    height: '100%',
    padding: '24px',
  },
});

const parseResponse = (data: any) => {
  return data.reduce(
    (p: any, n: any) => ({
      overall: p.overall + 1,
      active: n.active ? p.active + 1 : p.active,
      planned: n.plans > 0 ? p.planned + 1 : p.planned,
      optimized: n.executions > 0 ? p.optimized + 1 : p.optimized,
      pay: n.subscription && n.subscription.status === 'active' ? p.pay + 1 : p.pay,
    }),
    {
      overall: 0,
      active: 0,
      planned: 0,
      optimized: 0,
      pay: 0,
    }
  );
};

type AdministrationLast10Props = {
  classes: {
    containerDiv: string;
  };
  history: {
    push: (...args: any[]) => any;
  };
};

const AdministrationLast10 = ({ classes }: AdministrationLast10Props) => {
  const [stats, setStats] = useState(undefined);
  useEffect(() => {
    async function fetchData() {
      const response = await getAdminUsers(undefined, 0, 100, 'created:desc');
      setStats(parseResponse(response.docs));
    }
    fetchData();
  }, []);
  return (
    <div className={classes.containerDiv}>
      {stats && (
        <Grid container justify="space-between">
          <Grid item md={2}>
            <Grid container direction="column">
              <Typography align="center" variant="h4">
                Total
              </Typography>
              <VSpacer />
              <Typography align="center" variant="h6">
                {/* @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'. */}
                {stats.overall}
              </Typography>
            </Grid>
          </Grid>
          <Grid item md={2}>
            <Grid container direction="column">
              <Typography align="center" variant="h4">
                Active
              </Typography>
              <VSpacer />

              <Typography align="center" variant="h6">
                {/* @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'. */}
                {stats.active}
              </Typography>
            </Grid>
          </Grid>
          <Grid item md={2}>
            <Grid container direction="column">
              <Typography align="center" variant="h4">
                Planned
              </Typography>
              <VSpacer />
              <Typography align="center" variant="h6">
                {/* @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'. */}
                {stats.planned}
              </Typography>
            </Grid>
          </Grid>
          <Grid item md={2}>
            <Grid container direction="column">
              <Typography align="center" variant="h4">
                Optimized
              </Typography>
              <VSpacer />
              <Typography align="center" variant="h6">
                {/* @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'. */}
                {stats.optimized}
              </Typography>
            </Grid>
          </Grid>
          <Grid item md={2}>
            <Typography align="center" variant="h4">
              Status active
            </Typography>
            <VSpacer />
            <Typography align="center" variant="h6">
              {/* @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'. */}
              {stats.pay}
            </Typography>
          </Grid>
        </Grid>
      )}
    </div>
  );
};

export default withStyles(styles)(AdministrationLast10);
