import { Badge, Chip, Grid, Tab, Tabs, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import {
  Button,
  PaginatedDynamicDataTable,
  ScrollableDialog,
} from '@sm-highway-web/react-components';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import VehicleIcons from '../assets/svg/VehicleIcons';
import DataTable from '../components/datatable/DataTable';
import ExcelUpload from '../components/ExcelUpload';
import FormattedMessage from '../components/FormattedMessageCustom';
import { getRoutesFieldArray } from '../helpers/Excel';
import { parseSecondsToHM } from '../helpers/TimeDistance';
import store from '../redux/store';
import { ActivePlan } from '../redux/actions';
import eventsPanel from '../services/events';
import getHighway from '../services/highway';
import { getVehicles } from '../services/requests';
import colors, { getRouteColor } from '../constants/Colors';

const styles = {
  icons: {
    margin: '1px',
    width: '32px',
    height: '32px',
  },
  divicons: {
    width: '34px',
    height: '34px',
    borderRadius: '17px',
  },
  white: {
    color: 'white',
  },
  badge: {
    width: '100%',
  },
  padding: {
    padding: '16px 0px',
  },
  width100: {
    width: '100%',
  },
  gridnumroutes: {
    width: '24px',
    height: '24px',
    backgroundColor: '#FF8200',
    borderRadius: '12px',
  },
  typonumroutes: {
    width: '100%',
    color: 'white',
  },
  typonumroutes2: {
    color: '#FF8200',
    paddingLeft: '8px',
  },
  errorQuota: {
    backgroundColor: 'rgba(255,130,0,0.1)',
    color: '#D0021B',
    padding: '8px',
    borderRadius: '16px',
  },
};

const TabPanel = ({ value, index, children }: any) => {
  return value === index ? children : null;
};

const routesMapping = [
  {
    column: 'label',
    name: 'Name',
    sortable: true,
  },
  {
    column: 'start_location',
    name: 'Location',
    computed: (doc: any) => {
      if (doc.start_location) {
        return doc.start_location.label || `${doc.start_location.lat}, ${doc.start_location.lng}`;
      }
      return '-';
    },
  },
  {
    column: 'end_location',
    name: 'Location',
    computed: (doc: any) => {
      if (doc.end_location) {
        return doc.end_location.label || `${doc.end_location.lat}, ${doc.end_location.lng}`;
      }
      return '-';
    },
  },
];

type AddRoutesDialogProps = {
  classes: {
    icons: string;
    divicons: string;
    white: string;
    badge: string;
    padding: string;
    gridnumroutes: string;
    typonumroutes: string;
    typonumroutes2: string;
    width100: string;
    errorQuota: string;
  };
  open: boolean;
  handleOpenCloseDialog: (...args: any[]) => any;
  actions: {
    createRoutes: (...args: any[]) => any;
  };
  planId?: string;
  projectMaxVehicles: number;
  planOperationCountry: string;
  planRoutes: number;
};

const AddRoutesDialog = ({
  classes,
  open,
  handleOpenCloseDialog,
  actions,
  planId,
  projectMaxVehicles,
  planOperationCountry,
  planRoutes,
}: AddRoutesDialogProps) => {
  const [tabIndex, setTabIndex] = useState(1);
  const [routesToAdd1, setRoutesToAdd1] = useState([]);
  const [routesToAdd2, setRoutesToAdd2] = useState([]);
  const [routesToAdd3, setRoutesToAdd3] = useState([]);

  const resultingQuota = () =>
    planRoutes +
    routesToAdd1.length +
    routesToAdd2.length +
    routesToAdd3.length -
    projectMaxVehicles;

  useEffect(() => {
    if (open) {
      setRoutesToAdd1([]);
      setRoutesToAdd2([]);
      setRoutesToAdd3([]);
    }
  }, [open]);

  return (
    <ScrollableDialog
      modal
      disableBackdropClick
      onClose={handleOpenCloseDialog}
      open={open}
      dialogTitle={<FormattedMessage id="plan.routesdialog.addroutes" />}
      maxWidth="lg"
      fullWidth
      dialogContent={
        <>
          <Tabs
            value={tabIndex}
            onChange={(e, v) => setTabIndex(v)}
            indicatorColor="primary"
            textColor="primary"
          >
            <Tab
              label={
                <Badge
                  className={classes.badge}
                  badgeContent={routesToAdd2.length}
                  color="primary"
                  classes={{ colorPrimary: classes.white }}
                >
                  <Typography align="center" className={classes.width100}>
                    <FormattedMessage id="plan.routessdialog.fromvehicles" />
                  </Typography>
                </Badge>
              }
              value={1}
            />
            <Tab
              label={
                <Badge
                  className={classes.badge}
                  badgeContent={0}
                  color="primary"
                  classes={{ colorPrimary: classes.white }}
                >
                  <Typography align="center" className={classes.width100}>
                    <FormattedMessage id="plan.servicesdialog.upload" />
                  </Typography>
                </Badge>
              }
              value={2}
            />
          </Tabs>
          <div style={{ height: '70vh' }}>
            <TabPanel value={tabIndex} index={1}>
              <PaginatedDynamicDataTable
                defaultLimit={100}
                fitContainer
                search
                provider={(offset: any, limit: any, sort: any, text: any) =>
                  getVehicles(text, offset, limit, sort)
                }
                mapping={[
                  {
                    column: 'icon',
                    name: 'Type',
                    sortable: true,
                    formatter: (icon: any, doc: any, index: any) => {
                      const Icon = doc && VehicleIcons[icon] ? VehicleIcons[icon] : VehicleIcons.truck;
                      return (
                        <div>
                          <Icon style={{ color: doc.color ? getRouteColor(doc, []) : colors[index] }} />
                        </div>
                      );
                    },
                  },
                  {
                    column: 'label',
                    name: 'Name',
                    sortable: true,
                  },
                  {
                    column: 'location',
                    name: 'Default start location',
                    computed: (doc: any) => {
                      if (doc.default_start_location) {
                        return (
                          doc.default_start_location.label ||
                          `${doc.default_start_location.lat}, ${doc.default_start_location.lng}`
                        );
                      }
                      return '-';
                    },
                  },
                  {
                    column: 'default_timewindows',
                    name: 'Time window',
                    formatter: (doc: any) =>
                      doc &&
                      doc.map((d: any) => (
                        <Chip
                          key={d[0] + d[1]}
                          label={`${parseSecondsToHM(d[0])}-${parseSecondsToHM(d[1])}`}
                        />
                      )),
                  },
                  {
                    column: 'phone',
                    name: 'Phone',
                  },
                  {
                    column: 'comments',
                    name: 'Notes',
                    formatter: (doc: any) => (
                      <Typography style={{ maxWidth: '700px' }} noWrap>
                        {doc}
                      </Typography>
                    ),
                  },
                ]}
                multiSelectId={['id']}
                onSelectionChange={(routes: any) => {
                  const routesRaw = routes.map((s: any) => getHighway(store.getState().user.token).route.fromVehicle(s));
                  setRoutesToAdd2(routesRaw);
                }}
              />
            </TabPanel>
            <TabPanel value={tabIndex} index={2}>
              {routesToAdd3.length === 0 && (
                <ExcelUpload
                  sampleUrl="https://static.smartmonkey.io/examples/routes_vehicles.xlsx"
                  intlPreffix="routes.data.details"
                  fieldsArray={getRoutesFieldArray()}
                  onAdd={setRoutesToAdd3}
                  countryCode={planOperationCountry}
                />
              )}
              {routesToAdd3.length > 0 && (
                <DataTable
                  noFooter
                  docs={routesToAdd3}
                  total={routesToAdd3.length}
                  mapping={routesMapping}
                />
              )}
            </TabPanel>
          </div>
        </>
      }
      dialogActions={
        <Grid container>
          <Grid item sm={3} md={3} style={{ paddingLeft: '16px' }}>
            <Grid container direction="column" justify="center" className={classes.gridnumroutes}>
              <Typography className={classes.typonumroutes} align="center">
                {routesToAdd1.length + routesToAdd2.length}
              </Typography>
              <Typography variant="h6" className={classes.typonumroutes2} align="center">
                routes
              </Typography>
            </Grid>
          </Grid>
          <Grid item sm={7} md={7}>
            {resultingQuota() > 0 && (
              <Grid container alignItems="center" className={classes.errorQuota}>
                <FormattedMessage
                  id="plan.routesdialog.addroutes.quota_exceeded"
                  values={{
                    maxVehicles: projectMaxVehicles,
                    surplus: resultingQuota(),
                  }}
                />
              </Grid>
            )}
          </Grid>
          <Grid item sm={2} md={2}>
            <Grid container justify="flex-end">
              <Button
                variant="contained"
                style={{ boxShadow: 'none', color: 'white', width: '200px' }}
                disabled={
                  resultingQuota() > 0 || routesToAdd1.length + routesToAdd2.length + routesToAdd3.length <= 0
                }
                onClick={() => {
                  actions.createRoutes([...routesToAdd1, ...routesToAdd2, ...routesToAdd3], planId);
                  const via =
                    routesToAdd3.length > 0
                      ? 'Subir'
                      : routesToAdd2.length > 0
                        ? 'Desde Vehículos'
                        : null;
                  eventsPanel.importRoutes(via);
                  handleOpenCloseDialog();
                }}
              >
                <FormattedMessage id="plan.servicesdialog.create" />
              </Button>
            </Grid>
          </Grid>
        </Grid>
      }
    />
  );
};

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    createRoutes: (vehicles: any, planId: any) =>
      dispatch(ActivePlan.createRoutes(vehicles, planId)),
  },
});

const mapStateToProps = (state: any) => {
  return {
    projectMaxVehicles:
      state.projects.activeProject &&
      state.projects.activeProject &&
      state.projects.activeProject.limits.max_vehicles
        ? state.projects.activeProject.limits.max_vehicles
        : 0,
    planOperationCountry:
      state.activePlan.plan &&
      state.activePlan.plan.optimizer_config &&
      state.activePlan.plan.optimizer_config.operation_country
        ? state.activePlan.plan.optimizer_config.operation_country
        : '',
    planRoutes:
      state.activePlan.plan && state.activePlan.plan.routes
        ? state.activePlan.plan.routes.length
        : 0,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(AddRoutesDialog));
