import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { Button, VSpacer } from '@sm-highway-web/react-components';
import Party from '../../assets/img/party.svg';
import FormattedMessage from '../FormattedMessageCustom';

type Props = {
  onNextStep: (...args: any[]) => any;
};

const PaymentCongratulations = ({ onNextStep }: Props) => {
  return (
    <>
      <Typography variant="h1" align="center">
        <FormattedMessage id="paymentrunwaydialog.step5.title" />
      </Typography>
      <VSpacer />
      <Typography variant="h2" align="center">
        <FormattedMessage id="paymentrunwaydialog.step5.title2" />
      </Typography>
      <VSpacer />
      <Grid container justify="center">
        <img alt="Party" src={Party} />
      </Grid>
      <VSpacer />
      <Button variant="contained" onClick={onNextStep}>
        <FormattedMessage id="paymentrunwaydialog.step5.button" />
      </Button>
    </>
  );
};

export default PaymentCongratulations;
