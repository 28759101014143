import { Menu, MenuItem } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { IPlanData } from 'highway-api/dist/common/interfaces/plans';
import { IRouteDataExtended } from 'highway-api/dist/common/interfaces/routes';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import ConfirmDeleteDialog from '../../dialogs/ConfirmDeleteDialog';
import ConfirmDialog from '../../dialogs/ConfirmDialog';
import InteractServicesDialog from '../../dialogs/InteractServicesDialog';
import { Routes } from '../../redux/actions';
import FormattedMessage from '../FormattedMessageCustom';
import { routeServicesHasAnyWarnings } from '../OptimizationWarningsTooltip';
import PopoverMore3Points from '../PopoverMore3Points';

const styles = {
  rootMenu: {
    width: '240px',
  },
  menuItem: {
    height: '42px',
  },
};

type RouteContextAnchor = { screenPosition: { top: number; left: number; }, doc?: IRouteDataExtended; } | undefined;

type OwnRouteMenuProps = {
  classes: {
    rootMenu: string;
    menuItem: string;
  };
  type?: 'contextual' | 'popover';
  plan: IPlanData;
  route: IRouteDataExtended;
  contextAnchor?: RouteContextAnchor;
  actions: {
    deleteRoute: (...args: any[]) => any;
    saveRouteAsVehicle: (...args: any[]) => any;
    updateRoute: (...args: any[]) => any;
    reoptimizeRoute: (...args: any[]) => any;
  };
  setContextAnchor?: (context: RouteContextAnchor) => void;
  setRightPanelVisible?: (visible: boolean) => void;
  showSaveAsVehicle?: boolean;
  showReoptimizeRoute?: boolean;
  showExchangeServices?: boolean;
  showReverseOrder?: boolean;
  showViewDetails?: boolean;
  showLockUnlockRoute?: boolean;
  showDeleteRoute?: boolean;
};

type RouteMenuProps = OwnRouteMenuProps;

const RouteMenu = ({
  classes,
  type,
  plan,
  route,
  contextAnchor,
  setContextAnchor,
  actions,
  setRightPanelVisible,
  showSaveAsVehicle,
  showReoptimizeRoute,
  showExchangeServices,
  showReverseOrder,
  showViewDetails,
  showLockUnlockRoute,
  showDeleteRoute,
}: RouteMenuProps) => {
  const [dialog, setDialog] = useState<string | undefined>(undefined);
  return (
    <>
      {type === 'popover' && (
        <>
          <PopoverMore3Points
            idButton="routepopover"
            round
            arrayActions={[
              ...(!showSaveAsVehicle
                ? []
                : [
                    {
                      close: true,
                      disabled: Boolean(route.vehicle_id),
                      onClick: () => {
                        actions.saveRouteAsVehicle(route);
                      },
                      formattedMessage: 'routes.actions.saveasvehicle',
                    },
                  ]),
              ...(!showReoptimizeRoute
                ? []
                : [
                    {
                      close: true,
                      disabled: Boolean(route.is_locked),
                      onClick: () => {
                        if(routeServicesHasAnyWarnings(route)) {
                          setDialog('optimizationwarningsdialog');
                          return;
                        }
                        actions.reoptimizeRoute(route.id);
                      },
                      formattedMessage: 'routes.actions.reoptimize',
                    },
                  ]),
              ...(!showExchangeServices
                ? []
                : [
                    {
                      close: true,
                      disabled: Boolean(route.is_locked),
                      onClick: () => {
                        setDialog('exchangeServices');
                      },
                      formattedMessage: 'routes.actions.exchange_services',
                    },
                  ]),
              ...(!showReverseOrder
                ? []
                : [
                    {
                      close: true,
                      disabled: Boolean(route.is_locked),
                      onClick: () => {
                        actions.updateRoute(route.id, {
                          services: route.services.map((s: any) => s.id).reverse(),
                        });
                      },
                      formattedMessage: 'routes.actions.reverse_order',
                    },
                  ]),
              ...(!showLockUnlockRoute
                ? []
                : [
                    {
                      close: true,
                      onClick: () => {
                        actions.updateRoute(route.id, { is_locked: !route.is_locked });
                      },
                      formattedMessage: `${
                        !route.is_locked
                          ? `routes.actions.lock_route`
                          : `routes.actions.unlock_route`
                      }`,
                    },
                  ]),
              ...(!showViewDetails
                ? []
                : [
                    {
                      close: true,
                      onClick: () => {
                        if(setRightPanelVisible) {
                          setRightPanelVisible(true);
                        }
                      },
                      formattedMessage: 'routes.actions.view_details',
                    },
                  ]),
              ...(!showDeleteRoute
                ? []
                : [
                    {
                      close: true,
                      disabled: Boolean(route.is_locked),
                      onClick: () => {
                        setDialog('deleteRoute');
                      },
                      formattedMessage: 'routes.actions.delete_route',
                    },
                  ]),
            ]}
          />
        </>
      )}

      {type === 'contextual' && (
        <>
          {contextAnchor && (
            <Menu
              onClose={() => {
                if(setContextAnchor) {
                  setContextAnchor(undefined);
                }
              }}
              onContextMenu={(e) => {
                e.preventDefault();

                if(setContextAnchor) {
                  setContextAnchor(undefined);
                }
              }}
              open={Boolean(contextAnchor)}
              anchorReference="anchorPosition"
              anchorPosition={contextAnchor && contextAnchor.screenPosition}
              classes={{ list: classes.rootMenu }}
            >
              {showSaveAsVehicle && (
                <MenuItem
                  className={classes.menuItem}
                  disabled={Boolean(route.vehicle_id)}
                  onClick={(e) => {
                    e.stopPropagation();
                    actions.saveRouteAsVehicle(route);
                    
                    if(setContextAnchor) {
                      setContextAnchor(undefined);
                    }
                  }}
                >
                  <FormattedMessage id="routes.actions.saveasvehicle" />
                </MenuItem>
              )}

              {showReoptimizeRoute && (
                <MenuItem
                  disabled={Boolean(route.is_locked)}
                  className={classes.menuItem}
                  onClick={(e) => {
                    e.stopPropagation();

                    if(routeServicesHasAnyWarnings(route)) {                      
                      setDialog('optimizationwarningsdialog');
                      return;
                    }
                    actions.reoptimizeRoute(route.id);
                  }}
                >
                  <FormattedMessage id="routes.actions.reoptimize" />
                </MenuItem>
              )}

              {showExchangeServices && (
                <MenuItem
                  disabled={Boolean(route.is_locked)}
                  className={classes.menuItem}
                  onClick={(e) => {
                    e.stopPropagation();
                    setDialog('exchangeServices');
                  }}
                >
                  <FormattedMessage id="routes.actions.exchange_services" />
                </MenuItem>
              )}

              {showReverseOrder && (
                <MenuItem
                  disabled={Boolean(route.is_locked)}
                  className={classes.menuItem}
                  onClick={(e) => {
                    e.stopPropagation();
                    actions.updateRoute(route.id, {
                      services: route.services.map((s: any) => s.id).reverse(),
                    });

                    if(setContextAnchor) {
                      setContextAnchor(undefined);
                    }
                  }}
                >
                  <FormattedMessage id="routes.actions.reverse_order" />
                </MenuItem>
              )}

              {showLockUnlockRoute && (
                <MenuItem
                  className={classes.menuItem}
                  onClick={(e) => {
                    e.stopPropagation();
                    actions.updateRoute(route.id, { is_locked: !route.is_locked });

                    if(setContextAnchor) {
                      setContextAnchor(undefined);
                    }
                  }}
                >
                  <FormattedMessage
                    id={`${
                      !route.is_locked ? `routes.actions.lock_route` : `routes.actions.unlock_route`
                    }`}
                  />
                </MenuItem>
              )}

              {showViewDetails && (
                <MenuItem
                  className={classes.menuItem}
                  onClick={(e) => {
                    e.stopPropagation();

                    if(setRightPanelVisible) {
                      setRightPanelVisible(true);
                    }

                    if(setContextAnchor) {
                      setContextAnchor(undefined);
                    }
                  }}
                >
                  <FormattedMessage id="routes.actions.view_details" />
                </MenuItem>
              )}

              {showDeleteRoute && (
                <MenuItem
                  disabled={Boolean(route.is_locked)}
                  className={classes.menuItem}
                  onClick={(e) => {
                    e.stopPropagation();
                    setDialog('deleteRoute');
                  }}
                >
                  <FormattedMessage id="routes.actions.delete_route" />
                </MenuItem>
              )}
            </Menu>
          )}
        </>
      )}

      <ConfirmDialog
        title={<FormattedMessage id="plans.optimizationwarningsdialog.title" />}
        description={<FormattedMessage id="plans.optimizationwarningsdialog.content" />}
        open={dialog === 'optimizationwarningsdialog'}
        setDialog={setDialog}
        handleConfirm={() => {
          setDialog(undefined);

          if(setContextAnchor) {
            setContextAnchor(undefined);
          }

          actions.reoptimizeRoute(route.id);
        }}
      />

      <ConfirmDeleteDialog
        typeData="routes"
        open={dialog === 'deleteRoute'}
        setDialog={setDialog}
        data={route}
        handleDelete={() => {
          setDialog(undefined);

          if(setContextAnchor) {
            setContextAnchor(undefined);
          }

          if(setRightPanelVisible) {
            setRightPanelVisible(false);
          }

          actions.deleteRoute(route);
        }}
      />

      <InteractServicesDialog
        type="exchange"
        open={dialog === 'exchangeServices'}
        routes={plan && plan.routes}
        setDialog={setDialog}
        handleInteractServices={(destinationRoute: any) => {
          setDialog(undefined);

          if(setContextAnchor) {
            setContextAnchor(undefined);
          }
          
          if (destinationRoute === 'missing') {
            const unassignedServices = plan.services
              .filter((service: any) => !service.route_id)
              .map((s: any) => s.id);
            actions.updateRoute(route.id, { services: unassignedServices });
          } else {
            const newServicesTo = route.services.map((s: any) => s.id);
            const newServicesFrom = destinationRoute.services.map((s: any) => s.id);
            actions.updateRoute(destinationRoute.id, {
              services: newServicesTo.filter((s: any) => s !== null),
            });
            actions.updateRoute(route.id, {
              services: newServicesFrom.filter((s: any) => s !== null),
            });
          }
        }}
      />
    </>
  );
};

RouteMenu.defaultProps = {
  type: 'contextual',
  contextAnchor: {
    screenPosition: {
      top: 0,
      left: 0,
    },
  },
  setContextAnchor: () => {},
  setRightPanelVisible: () => {},
  showSaveAsVehicle: true,
  showReoptimizeRoute: true,
  showExchangeServices: true,
  showReverseOrder: true,
  showViewDetails: true,
  showLockUnlockRoute: true,
  showDeleteRoute: true,
};

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    deleteRoute: (route: any) => dispatch(Routes.deleteRoutes([route])),
    saveRouteAsVehicle: (route: any) => dispatch(Routes.saveRouteAsVehicle(route)),
    updateRoute: (routeId: any, services: any) => dispatch(Routes.updateRoute(routeId, services)),
    reoptimizeRoute: (routeId: any) => dispatch(Routes.optimizeRoute(routeId)),
  },
});

// @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ ({ classes, type, plan, route,... Remove this comment to see the full error message
export default connect(null, mapDispatchToProps)(withStyles(styles)(RouteMenu));
