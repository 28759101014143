import React from 'react';
import styled from 'styled-components';

const Container = styled.div`
  .airplane {
    animation: float 3s ease-out infinite;
  }

  @keyframes float {
    50% {
      transform: translate(0, 20px);
    }
  }
  .shadowFrame {
    width: 130px;
    margin-top: 15px;
    padding-left: 25px;
    .shadow {
      animation: shrink 3s ease-out infinite;
      transform-origin: center center;
    }
  }

  @keyframes shrink {
    0% {
      width: 90%;
      margin: 0 5%;
    }
    50% {
      width: 60%;
      margin: 0 18%;
    }
    100% {
      width: 90%;
      margin: 0 5%;
    }
  }
`;

const FloatingLogo = () => (
  <Container>
    <svg
      className="airplane"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="163.682"
      height="133.992"
      viewBox="0 0 163.682 133.992"
    >
      <defs>
        <linearGradient
          id="linear-gradient"
          y1="0.5"
          x2="1"
          y2="0.5"
          gradientUnits="objectBoundingBox"
        >
          <stop offset="0.02" stopColor="#f05b62" />
          <stop offset="1" stopColor="#ff8200" />
        </linearGradient>
      </defs>
      <path
        d="M163.253,4.5,3.692,43.764c-1.671.835-2.506,1.671,0,2.506L42.121,62.142c1.671.835,5.012.835,7.519,0l17.543-8.354,6.683,12.531L57.993,77.179a18.19,18.19,0,0,0-4.177,6.683l-8.354,52.63c0,2.506.835,2.506,2.506.835L164.924,7.006c1.671-1.671.835-2.506-1.671-2.506Zm-82.7,55.972L74.7,49.611l20.05-9.189,4.177,8.354-18.379,11.7ZM104.775,44.6l-3.342-7.519,15.873-7.519,2.506,4.177Zm39.264-26.733-20.05,13.366-2.506-4.177,22.556-9.189C145.71,16.2,145.71,17.031,144.039,17.866Z"
        transform="translate(-2.111 -4.5)"
        fillRule="evenodd"
        fill="url(#linear-gradient)"
      />
    </svg>

    <div className="shadowFrame">
      <svg
        version="1.1"
        className="shadow"
        id="Layer_1"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        x="61px"
        y="20px"
        width="122.436px"
        height="39.744px"
        viewBox="0 0 122.436 39.744"
        enableBackground="new 0 0 122.436 39.744"
        xmlSpace="preserve"
      >
        <path
          d="M6431.673,3525.5l-78.77,4.518,22.872,3.578-1.161,7.063,57.549-15.159Z"
          transform="translate(-6352.404 -3525)"
          fill="#e9e9e9"
          stroke="#e9e9e9"
          strokeLinejoin="round"
          strokeWidth="1"
        />
      </svg>
    </div>
  </Container>
);

export default FloatingLogo;
