import createSvgIcon from '@material-ui/icons/utils/createSvgIcon';
import React from 'react';

export default createSvgIcon(
  <>
    <path d="M20,12.4V16c0,2.2-1.8,4-4,4H7.9c-2.2,0-4-1.8-4-4V7.8c0-2.2,1.8-4,4-4h3.5v-2H7.9c-3.3,0-6,2.7-6,6V16c0,3.3,2.7,6,6,6H16   c3.3,0,6-2.7,6-6v-3.6H20z" />
    <path d="m22.7 5.8l-5.3-4.7c-0.4-0.4-1.1-0.3-1.4 0.1-0.4 0.4-0.3 1.1 0.1 1.4l3.4 3h-4.3c-2.8 0-5.2 2.3-5.2 5.1v5.5c0 0.6 0.5 1 1 1 0.6 0 1-0.5 1-1v-5.5c0-1.7 1.4-3.1 3.1-3.1h4.1l-3.2 2.9c-0.4 0.4-0.5 1-0.1 1.4 0.2 0.2 0.5 0.3 0.8 0.3 0.2 0 0.5-0.1 0.7-0.3l5.3-4.7c0.2-0.1 0.3-0.3 0.3-0.6s-0.1-0.6-0.3-0.8z" />
  </>,
  'ShareIcon'
);
