import { Chip, Grid, Input, Tooltip, withStyles } from '@material-ui/core';
import ErrorIcon from '@material-ui/icons/Error';
import React, { useEffect, useRef, useState } from 'react';
import { parseHMtoSeconds, parseSecondsToHM } from '../../helpers/TimeDistance';
import FormattedMessage from '../FormattedMessageCustom';
import { Label, Placeholder } from './FormFields.styled';

const styles = (theme: any) => ({
  editingChip: {
    border: `1px solid ${theme.palette.primary.main}`,
    paddingLeft: '6px',
    paddingRight: '6px',
    borderRadius: '25px',
    height: '34px',
    marginRight: '4px',
    marginTop: '4px',
  },

  chip: {
    marginRight: '4px',
    marginTop: '4px',
    height: '34px',
  },

  children: {
    minHeight: '38px',
    '&:hover': {
      backgroundColor: '#EEEFF2',
    },
    borderRadius: '2px',
    width: 'calc(100% - 8px)',
  },
});

type OwnFormTimewindowsProps = {
  classes: {
    editingChip: string;
    chip: string;
    children: string;
  };
  timewindows?: (number | any[])[];
  onTimewindowsChange?: (...args: any[]) => any;
  label?: string | React.ReactElement | {};
  single?: boolean;
};

type FormTimewindowsProps = OwnFormTimewindowsProps;

const FormTimewindows = ({
  classes,
  timewindows,
  onTimewindowsChange,
  label,
  single,
}: FormTimewindowsProps) => {
  // Values has the form [[10,20], [30, 40], ...]

  const [timeStart, setTimeStart] = useState<string>();
  const [timeEnd, setTimeEnd] = useState<string>();
  const [error, setError] = useState();
  const [focused, setFocused] = useState(false);
  const formRef = useRef();
  const activeChipRef = useRef();

  const numericToText = (timewindow: any) => `${parseSecondsToHM(timewindow[0])}-${parseSecondsToHM(timewindow[1])}`;

  const textToNumeric = (timewindow: any) => {
    if (timewindow[0] && timewindow[1] && timewindow[1] < timewindow[0]) {
      return [
        parseHMtoSeconds(timewindow[0]),
        parseHMtoSeconds(timewindow[1]) + (24 * 3600),
      ];
    } 
    return [
      parseHMtoSeconds(timewindow[0]),
      parseHMtoSeconds(timewindow[1]),
    ];    
  };

  const checkOverlapping = () => {
    const [t0, t1] = textToNumeric([timeStart, timeEnd]);
    let overlapping = false;
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    timewindows?.some(([start, end]) => {
      overlapping = overlapping || (start <= t0 && t0 < end);
      overlapping = overlapping || (start <= t1 && t1 <= end);
      overlapping = overlapping || (t0 < start && end < t1);
      overlapping = overlapping || (t1 > start && end > t1);
      return overlapping;
      // error = error || !(t0 > end);
      // error = error || !(t1 < start);
    });
    return overlapping;
  };

  const handleAddTimewindow = async () => {
    if (timeStart && timeEnd) {
      if (timeStart < timeEnd) {
        if (single || !checkOverlapping()) {
          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'false' is not assignable to para... Remove this comment to see the full error message
          setError(false);
          if (single) {
            // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
            onTimewindowsChange(textToNumeric([timeStart, timeEnd]));
          } else {
            // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
            onTimewindowsChange([...(timewindows || []), textToNumeric([timeStart, timeEnd])]);
          }
          setTimeStart('');
          setTimeEnd('');
        } else {
          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '"overlapping"' is not assignable... Remove this comment to see the full error message
          setError('overlapping');
        }
      } else {
        const timeWindowSec = textToNumeric([timeStart, timeEnd]);

        if (timeWindowSec[0] < timeWindowSec[1]) {
          if (single || !checkOverlapping()) {
            // @ts-expect-error ts-migrate(2345) FIXME: Argument of type 'false' is not assignable to para... Remove this comment to see the full error message
            setError(false);
            if (single) {
              // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
              onTimewindowsChange(timeWindowSec);
            } else {
              // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
              onTimewindowsChange([...(timewindows || []), timeWindowSec]);
            }
            setTimeStart('');
            setTimeEnd('');
          } else {
            // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '"overlapping"' is not assignable... Remove this comment to see the full error message
            setError('overlapping');
          }
        } else {
          // @ts-expect-error ts-migrate(2345) FIXME: Argument of type '"start_after_end"' is not assign... Remove this comment to see the full error message
          setError('start_after_end');
        }
      }
    }
  };

  const handleFocusedKeyPress = async (event: any) => {
    switch (event.key) {
      case 'Enter':
        handleAddTimewindow();
        break;
      default:
        break;
    }
  };

  const handleClick = (evt: any) => {
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    if (activeChipRef.current && !activeChipRef.current.contains(evt.target)) {
      handleAddTimewindow();
    }
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    if (formRef.current.contains(evt.target)) {
      setFocused(true);
    } else {
      setFocused(false);
    }
  };

  const handleRemove = (idx: any) => {
    timewindows?.splice(idx, 1);
    // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    onTimewindowsChange(timewindows?.length > 0 ? timewindows : []);
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClick);
    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  });

  return (
    <Grid container direction="column">
      {label && <Label>{label}</Label>}
      {/* @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call. */}
      <Grid
        container
        ref={formRef}
        alignItems="center"
        className={!focused ? classes.children : ''}
      >
        <div>
          {!single ? (
            // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
            timewindows?.length > 0 ? (
              timewindows?.map((timewindow: any, idx: any) => (
                <Chip
                  key={timewindow}
                  className={classes.chip}
                  onDelete={
                    focused
                      ? () => {
                          handleRemove(idx);
                        }
                      : () => {}
                  }
                  label={numericToText(timewindow)}
                />
              ))
            ) : (
              <>
                {!focused && (
                  <Placeholder>
                    <FormattedMessage id="none" />
                  </Placeholder>
                )}
              </>
            )
          ) : null}

          {single ? (
            timewindows && timewindows.length > 0 ? (
              <Chip
                // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
                key={timewindows}
                className={classes.chip}
                onDelete={
                  focused
                    ? () => {
                        // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
                        onTimewindowsChange(null);
                      }
                    : () => {}
                }
                label={numericToText(timewindows)}
              />
            ) : (
              <>
                {!focused && (
                  <Placeholder>
                    <FormattedMessage id="none" />
                  </Placeholder>
                )}
              </>
            )
          ) : null}
        </div>

        {focused && (!single || ((!timewindows || timewindows.length < 1) && single)) && (
          // @ts-expect-error ts-migrate(2769) FIXME: No overload matches this call.
          <Grid
            item
            ref={activeChipRef}
            key={`render-${timewindows ? timewindows.length : 0}`}
            className={classes.editingChip}
            onKeyDown={handleFocusedKeyPress}
            style={{ ...(error ? { borderColor: 'red' } : {}) }}
          >
            <Grid container alignItems="center">
              <Input
                type="time"
                disableUnderline
                value={timeStart}
                onChange={(evt) => {
                  setTimeStart(evt.target.value);
                }}
              />
              <Input
                disableUnderline
                value={timeEnd}
                type="time"
                onChange={(evt) => {
                  setTimeEnd(evt.target.value);
                }}
              />

              {error && (
                <Tooltip title={<FormattedMessage id={`formtimewindow.error.${error}`} />}>
                  <ErrorIcon style={{ color: 'red' }} />
                </Tooltip>
              )}
            </Grid>
          </Grid>
        )}
      </Grid>
    </Grid>
  );
};

FormTimewindows.defaultProps = {
  timewindows: [],
  label: undefined,
  onTimewindowsChange: () => {},
  single: false,
};

export default withStyles(styles)(FormTimewindows);
