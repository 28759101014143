import { Grid, Typography, withStyles } from '@material-ui/core';
import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Button, Form, FormControl, InputText, VSpacer } from '@sm-highway-web/react-components';
import logoblack from '../assets/img/logo_black.svg';
import * as routes from '../constants/Routing';
import { InfoError } from '../redux/actions';
import { getNewPasswordValidate, getResetPassword, postNewPassword } from '../services/requests';
import FormattedMessage from './FormattedMessageCustom';
import Link from './Link';

const styles = {
  field: {
    width: '100%',
  },
  logo: {
    width: '70%',
  },
};

type OwnResetPasswordContainerProps = {
  classes: {
    field: string;
    logo: string;
  };
  token?: string;
  actions: {
    sendStatusToSnackbar: (...args: any[]) => any;
  };
};

type ResetPasswordContainerState = any;

type ResetPasswordContainerProps = OwnResetPasswordContainerProps;

class ResetPasswordContainer extends Component<
  ResetPasswordContainerProps,
  ResetPasswordContainerState
> {
  static defaultProps: any;

  state = {
    email: '',
    password: '',
    password2: '',
    success: false,
    tokenValid: false,
  };

  componentDidMount() {
    const { token } = this.props;
    if (token) {
      this.validateToken(token);
    }
  }

  validateToken = async (token: any) => {
    try {
      await getNewPasswordValidate(token);
      this.setState({ tokenValid: true });
    } catch (exception) {
      this.setState({ tokenValid: false });
    }
  };

  handleResetPassword = async () => {
    const { email } = this.state;
    const { actions } = this.props;
    try {
      await getResetPassword(email);
      this.setState({ success: true });
    } catch (error) {
      actions.sendStatusToSnackbar('infoerror.usernotfound', 'error');
    }
  };

  handleNewPassword = async () => {
    const { token, actions } = this.props;
    const { password } = this.state;
    try {
      await postNewPassword(token, password);
      this.setState({ success: true });
      actions.sendStatusToSnackbar('infoerror.passchange', 'success');
    } catch (error) {
      actions.sendStatusToSnackbar('infoerror.nopasschange', 'error');
    }
  };

  handleEmailChange = (email: any) => {
    this.setState({ email });
  };

  handlePasswordChange = (password: any) => {
    this.setState({ password });
  };

  handlePassword2Change = (password2: any) => {
    this.setState({ password2 });
  };

  resetrequest = () => {
    const { classes } = this.props;
    const { email, success } = this.state;
    return (
      <>
        {!success && (
          <Form direction="column" onSubmit={this.handleResetPassword} lazyValidation>
            <Typography className="form-ignore">
              <FormattedMessage id="resetpassword.text" />
            </Typography>

            <VSpacer />
            <InputText
              className={classes.field}
              id="email"
              type="email"
              label={<FormattedMessage id="login.email" />}
              value={email}
              onChange={(mail: any) => this.handleEmailChange(mail)}
              margin="normal"
              style={{ width: '100%' }}
              validators={['required', 'email']}
            />

            <VSpacer />
            <FormControl justify="center">
              <Button type="submit" variant="contained">
                <FormattedMessage id="resetpassword.buttonsendmail" />
              </Button>
            </FormControl>
            <VSpacer />
          </Form>
        )}
        {success && (
          <>
            <Typography>
              <FormattedMessage id="resetpassword.emailsend" />
            </Typography>
          </>
        )}
      </>
    );
  };

  resetform = () => {
    const { classes } = this.props;
    const { password, password2, tokenValid, success } = this.state;
    return (
      <>
        {success && <Redirect to={routes.LOGIN} />}

        {tokenValid && !success && (
          <Form onSubmit={this.handleNewPassword} lazyValidation direction="column">
            <InputText
              className={classes.field}
              id="password"
              type="password"
              label={<FormattedMessage id="login.password" />}
              value={password}
              onChange={(mail: any) => this.handlePasswordChange(mail)}
              margin="none"
              validators={['required']}
            />
            <InputText
              className={classes.field}
              id="password2"
              type="password"
              label={<FormattedMessage id="login.confirm_password" />}
              value={password2}
              onChange={(mail: any) => this.handlePassword2Change(mail)}
              margin="none"
              customValidator={() => (password !== password2 ? 'Passwords must match' : undefined)}
            />
            <VSpacer />
            <FormControl justify="center">
              <Button type="submit" variant="contained">
                <FormattedMessage id="resetpassword.buttonresetpass" />
              </Button>
            </FormControl>
          </Form>
        )}
        {!tokenValid && (
          <Typography>
            <FormattedMessage id="resetpassword.invalidtoken" />
          </Typography>
        )}
      </>
    );
  };

  render() {
    const { token, classes } = this.props;
    return (
      <>
        <Grid container style={{ height: '100%' }} justify="space-between" direction="column">
          <Grid container justify="center">
            <img src={logoblack} alt="" className={classes.logo} />
          </Grid>
          <VSpacer small />
          <Grid item>
            <Typography variant="h2" align="center">
              <FormattedMessage id="resetpassword.buttonresetpass" />
            </Typography>
          </Grid>
          <VSpacer small />
          {!token ? this.resetrequest() : this.resetform()}

          <Grid item>
            <Typography component="span">
              <Link to={routes.REGISTER}>
                <FormattedMessage id="resetpassword.register" />
              </Link>
            </Typography>
          </Grid>
        </Grid>
      </>
    );
  }
}

ResetPasswordContainer.defaultProps = {
  token: undefined,
};

const mapDispatchToProps = (dispatch: any) => ({
  actions: {
    sendStatusToSnackbar: (message: any, type: any) =>
      dispatch(InfoError.sendStatusToSnackbar(message, type)),
  },
});

export default connect(null, mapDispatchToProps)(withStyles(styles)(ResetPasswordContainer));
