import { Typography } from '@material-ui/core';
import { Checkbox } from '@sm-highway-web/react-components';
import React from 'react';
import styled from 'styled-components';
import { dateFormatShortTime, parseSecondsToHM } from '../../helpers/TimeDistance';
import FormArray from '../form-fields/FormArray';
import { Label } from '../form-fields/FormFields.styled';
import FormLinkedElement from '../form-fields/FormLinkedElement';
import FormLocation from '../form-fields/FormLocation';
import FormPickups from '../form-fields/FormPickups';
import FormText from '../form-fields/FormText';
import FormTimewindows from '../form-fields/FormTimewindows';
import FormattedMessage from '../FormattedMessageCustom';

export const styles = {
  box: {
    paddingLeft: '32px',
    paddingRight: '14px',
    paddingBottom: '8px',
  },
  slimbutton: {
    padding: 0,
    height: '26px',
    boxShadow: 'none',
  },
  menuItem: {
    height: '42px',
  },
};

export const popoverConfig = {
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center',
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center',
  },
};

export const checkR = (value: any) => {
  if (value === true) {
    return true;
  }
  return false;
};

export const checkAllR = (value: any) => {
  if (Object.keys(value).length === 0) {
    return false;
  }
  const values = Object.values(value).map((v: any) => v);
  return values.includes(true);
};

const dataTransform = (data: any, property: any) => {
  switch (property) {
    case 'planned_arrival_time':
    case 'planned_departure_time':
    case 'planned_end_time':
    case 'planned_start_time':
      return parseSecondsToHM(data);
    case 'done_at':
      return dateFormatShortTime(new Date(data));
    case 'services':
      return data.length;
    default:
      return data;
  }
};

const InfoTypography = styled(Typography)`
  min-height: 32px;
  padding: 0px;
  font-size: 16px;
  line-height: normal;
  color: #0097fb;
`;

export const renderInfo = (preffix: any, property: any, data: any) => (
  <>
    <Label>
      <FormattedMessage id={`${preffix}.${property}`} />
    </Label>
    <InfoTypography>{dataTransform(data[property], property)}</InfoTypography>
  </>
);

export const renderCheckbox = (preffix: any, property: any, handleUpdate: any, data: any) => (
  <div>
    <Label>
      <FormattedMessage id={`${preffix}.${property}`} />
    </Label>
    <Checkbox
      checked={dataTransform(data[property], property)}
      onChange={(e: any, value: any) => {
        handleUpdate(property, value);
      }}
    />
  </div>
);

export const renderFormText = (
  preffix: any,
  property: any,
  number: any,
  handleUpdate: any,
  data: any,
  suffix?: string | React.ReactElement,
  computed = (value: any) => value
) => (
  <FormText
    label={<FormattedMessage id={`${preffix}.${property}`} />}
    fieldValue={computed(data[property])}
    handleEnter={(val: any) => {
      handleUpdate(property, val);
    }}
    type={number}
    suffix={suffix}
  />
);

const renderLikedElement = (preffix: any, property: any, handleUpdate: any, data: any, type: 'client' | 'vehicle') => (
  <FormLinkedElement
    type={type}
    label={<FormattedMessage id={`${preffix}.${property}`} />}
    fieldValue={data[property]}
    onChange={(val: any) => {
      if (val === null) {
        handleUpdate(property, null);
      } else {
        handleUpdate(property, val.id);
      }
    }}
    noOptionsMessage={<FormattedMessage id={`${preffix}.${property}.no_options`} />}
    placeholder={<FormattedMessage id={`${preffix}.${property}.placeholder`} />}
  />
);

export const renderLinkedClient = (preffix: any, property: any, handleUpdate: any, data: any) => {
  return renderLikedElement(preffix, property, handleUpdate, data, 'client');
};

export const renderLinkedVehicle = (preffix: any, property: any, handleUpdate: any, data: any) => {
  return renderLikedElement(preffix, property, handleUpdate, data, 'vehicle');
};

export const renderPickup = (preffix: any, property: any, handleUpdate: any, data: any) => (
  <FormPickups
    label={<FormattedMessage id={`${preffix}.${property}`} />}
    fieldValue={data[property]}
    onChange={(val: any) => {
      if (val === null) {
        handleUpdate(property, null);
      } else if (!val.id) {
        handleUpdate(property, { label: val.label });
      } else {
        const {
          id,
          default_duration: duration,
          default_timewindows: timewindows,
          label,
          icon,
          location,
          external_id: extId,
        } = val;
        handleUpdate(property, {
          label,
          location,
          icon,
          external_id: extId,
          duration,
          timewindows,
          client_id: id,
        });
      }
    }}
    placeholder={<FormattedMessage id={`${preffix}.${property}.placeholder`} />}
  />
);

export const renderFormTextArea = (
  preffix: any,
  property: any,
  number: any,
  handleUpdate: any,
  data: any,
  suffix?: string | React.ReactElement
) => (
  <FormText
    label={<FormattedMessage id={`${preffix}.${property}`} />}
    fieldValue={data[property]}
    handleEnter={(val: any) => {
      handleUpdate(property, val);
    }}
    type={number}
    suffix={suffix}
    rowNumber={4}
  />
);

export const renderMinutes = (preffix: any, property: any, handleUpdate: any, data: any) => (
  <FormText
    label={<FormattedMessage id={`${preffix}.${property}`} />}
    fieldValue={data[property] ? data[property] / 60 : undefined}
    handleEnter={(val: any) => {
      handleUpdate(property, val ? val * 60 : null);
    }}
    suffix="min"
    type="number"
  />
);

export const renderTimewindows = (
  preffix: any,
  property: any,
  handleUpdate: any,
  data: any,
  single = false
) => (
  <FormTimewindows
    label={<FormattedMessage id={`${preffix}.${property}`} />}
    timewindows={data[property]}
    onTimewindowsChange={(val: any) => {
      handleUpdate(property, val);
    }}
    single={single}
  />
);

export const renderLocation = (
  preffix: any,
  property: any,
  handleUpdate: any,
  data: any,
  countryCode: string | null = ''
) => {
  let value = '';
  if (property in data && data[property] !== null) {
    if (data[property].label) {
      value = data[property].label;
    } else if (data[property].lng && data[property].lat) {
      value = `${data[property].lat}, ${data[property].lng}`;
    }
  }
  return (
    <FormLocation
      label={<FormattedMessage id={`${preffix}.${property}`} />}
      fieldValue={value}
      onUpdate={(val: any) => handleUpdate(property, val)}
      countryCode={countryCode}
    />
  );
};

export const renderArray = (preffix: any, property: any, handleUpdate: any, data: any) => (
  <FormArray
    label={<FormattedMessage id={`${preffix}.${property}`} />}
    array={data[property]}
    onUpdate={(chips: any) => handleUpdate(property, chips)}
  />
);
