import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  withStyles,
} from '@material-ui/core';
import { Checkbox } from '@sm-highway-web/react-components';
import clsx from 'clsx';
import memoize from 'memoize-one';
import React, { useState } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { FixedSizeList as List } from 'react-window';
import TableCellHeader from './TableCellHeader';

const columnsStyles = () => ({
  checkboxColumn: {
    padding: '6px 12px 6px 12px',
  },
  tableHead: {
    backgroundColor: 'white',
    fontSize: '14px',
    textTransform: 'uppercase',
    color: '#8194B8',
    fontWeight: 500,
    borderBottom: 'unset',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
});

type ColumnProps = {
  domId: string;
  headerTableIsBiggestThanTableColumns: boolean;
  showCheckboxes?: boolean;
  handleSelectAll: (...args: any[]) => any;
  selectedDocs: {
    id: string;
  }[];
  classes: {
    checkboxColumn: string;
    tableHead: string;
    row: string;
  };
  mapping: {}[];
  docs?: {}[];
  sortColumn?: string;
  sortOrder?: string;
  orderBy?: string;
  onColumnSort?: (...args: any[]) => any;
  width?: string;
};

const TableColumns = ({
  domId,
  headerTableIsBiggestThanTableColumns,
  showCheckboxes,
  handleSelectAll,
  selectedDocs,
  classes,
  mapping,
  docs,
  sortColumn,
  sortOrder,
  orderBy,
  onColumnSort,
  width,
}: ColumnProps) => (
  <TableRow
    id={domId}
    component="div"
    className={classes.row}
    style={{
      ...(!headerTableIsBiggestThanTableColumns
        ? { borderBottom: '1px solid rgba(224, 224, 224, 1)' }
        : {}),
    }}
  >
    {showCheckboxes && docs && docs.length > 0 && (
      <TableCell
        component="div"
        className={classes.checkboxColumn}
        classes={{ head: classes.tableHead }}
      >
        <Checkbox
          checked={selectedDocs.length === docs.length}
          onChange={handleSelectAll}
          indeterminate={selectedDocs.length < docs.length && selectedDocs.length > 0}
        />
      </TableCell>
    )}
    {mapping.map((entry: any) => {
      entry.style = entry.style
        ? { ...entry.style, borderBottom: 'unset' }
        : { borderBottom: 'unset' };

      if (entry.width) entry.style = { ...entry.style, width: `${entry.width}px` };

      return (
        <TableCellHeader
          key={`table-head-${entry.column}`}
          entry={entry}
          // @ts-expect-error ts-migrate(2345) FIXME:
          onColumnSort={onColumnSort}
          // @ts-expect-error ts-migrate(2345) FIXME:
          order={sortColumn === entry.column ? sortOrder : undefined}
          align={entry.align}
        />
      );
    })}
  </TableRow>
);

// @ts-expect-error ts-migrate(2345) FIXME:
const StyledTableColumns = withStyles(columnsStyles)(TableColumns);

const rowsStyles = () => ({
  selected: {
    '&$selected': {
      backgroundColor: '#FDF6ED',
    },
  },
  hover: {
    '&$hover:hover': {
      backgroundColor: '#FDF6ED',
    },
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center',
    boxSizing: 'border-box',
    minWidth: '100%',
    width: '100%',
    padding: 0,
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
  },
  placeholder: {
    color: '#8194B8',
    paddingLeft: '24px',
    fontSize: '12px',
  },
  cell: {
    display: 'block',
    flexGrow: 0,
    flexShrink: 0,
    padding: '0 12px',
  },
  checkboxColumn: {
    // width: '36px'
  },
  expandingCell: {
    flex: 1,
  },
});

const ROW_SIZE = 45;

type RowProps = {
  classes: {
    selected: string;
    hover: string;
    row: string;
    cell: string;
    checkboxColumn: string;
    expandingCell: string;
  };
  index: number;
  style?: any;
  data: {
    classes?: any;
    items: any[];
    columns: IRowColumnData[];
    selectedDocs: {}[];
    showCheckboxes?: boolean;
    onRowDoubleClick: (...args: any[]) => any;
    handleRowSelect: (...args: any[]) => any;
    handleCheckboxSelect: (...args: any[]) => any;
    handleOnContextMenu: (...args: any[]) => any;
  };
};

interface IRowColumnData {
  column: string;
  numeric: boolean;
  width: number;
  align?: string;
  flex?: number;
  formatter?: (...args: any[]) => any;
  computed?: (...args: any[]) => any;
}

const Row = ({ classes, index, style, data /* , isScrolling */ }: RowProps) => {
  const {
    showCheckboxes,
    items,
    columns,
    // classes: cellClasses,
    selectedDocs,
    onRowDoubleClick,
    handleRowSelect,
    handleCheckboxSelect,
    handleOnContextMenu,
  } = data;

  const [rowHover, setRowHover] = useState(false);

  const item = items[index];

  // IMPROVEMENT: Use isScrolling for showing a placeholder on scroll.
  // if (isScrolling) {
  //   return (
  //     <TableRow component="div" className={clsx(classes.row, classes.placeholder)} style={style}>
  //       <span>Loading...</span>
  //     </TableRow>
  //   );
  // }

  return (
    <TableRow
      component="div"
      className={classes.row}
      style={style}
      onClick={(event) => {
        event.stopPropagation();
        if (event.ctrlKey || event.metaKey) {
          handleCheckboxSelect(item);
        } else {
          handleRowSelect(item);
        }
      }}
      onContextMenu={(e) => {
        handleRowSelect(item);
        if (handleOnContextMenu) handleOnContextMenu(e, item);
      }}
      onDoubleClick={() => onRowDoubleClick(item)}
      selected={selectedDocs.find((doc: any) => doc.id === item.id) !== undefined}
      classes={{ selected: classes.selected, hover: classes.hover }}
      hover={rowHover}
      onMouseEnter={() => setRowHover(true)}
      onMouseLeave={() => setRowHover(false)}
    >
      {showCheckboxes && (
        <TableCell
          component="div"
          onClick={(event) => {
            event.stopPropagation();
            handleCheckboxSelect(item);
          }}
          className={clsx(classes.cell, classes.checkboxColumn)}
          style={{
            height: '100%',

            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          <Checkbox checked={selectedDocs.find((doc: any) => doc.id === item.id) !== undefined} />
        </TableCell>
      )}

      {columns.map((columnElement: IRowColumnData, colIndex: any) => {
        let justifyContent = null;

        if (columnElement.align === 'right') justifyContent = 'flex-end';
        if (columnElement.align === 'left') justifyContent = 'flex-start';
        if (columnElement.align === 'center') justifyContent = 'center';

        return (
          <TableCell
            key={item.id + colIndex}
            component="div"
            variant="body"
            align={columnElement.numeric || false ? 'right' : 'left'}
            className={clsx(classes.cell, !columnElement.width && classes.expandingCell)}
            style={{
              width: columnElement.width ? `${columnElement.width}px` : '100%',
              height: '100%',

              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              ...(columnElement.flex ? { flex: columnElement.flex } : {}),
              ...(justifyContent ? { justifyContent } : {}),
            }}
          >
            <Typography
              component="span"
              noWrap
              style={{
                width: '100%',
                maxWidth: `${columnElement.width}px`,
                ...(justifyContent?{ display: 'flex', justifyContent } : {}),
              }}
            >
              {(() => {
                if (columnElement.formatter) {
                  return columnElement.formatter(
                    columnElement.computed
                      ? columnElement.computed(item)
                      : item[columnElement.column],
                    item,
                    index,
                    rowHover
                  );
                }
                if (columnElement.computed) {
                  return columnElement.computed(item);
                }
                return item[columnElement.column];
              })()}
            </Typography>
          </TableCell>
        );
      })}
    </TableRow>
  );
};

// @ts-expect-error ts-migrate(2345) FIXME: Argument of type '() => { selected: { '&$selected'... Remove this comment to see the full error message
const StyledRow = withStyles(rowsStyles)(Row);

const itemKey = (index: any, data: any) => data.items[index].id;

const createItemData = memoize((classes, columns, data) => ({
  classes,
  columns,
  items: data,
}));

const tableStyles = {
  table: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1',
    height: '100%',
    width: '100%',
  },
  list: {},
  thead: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  tbody: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1',
    width: '100%',
  },
};

type OwnVirtualizedMaterialDataTableProps = {
  docs?: {}[];
  classes: {
    table: string;
    list: string;
    thead: string;
    tbody: string;
  };
  mapping: {
    style?: any;
  }[];
  selectedDocs?: {
    id: string;
  }[];
  showCheckboxes?: boolean;
  onRowDoubleClick?: (...args: any[]) => any;
  onRowSelected?: (...args: any[]) => any;
  handleOnContextMenu?: (...args: any[]) => any;
  onMultipleSelection?: (...args: any[]) => any;
  sortColumn?: string;
  sortOrder?: string;
  orderBy?: string;
  onColumnSort?: (...args: any[]) => any;
};

type VirtualizedMaterialDataTableProps = OwnVirtualizedMaterialDataTableProps;

const VirtualizedMaterialDataTable = ({
  classes,
  docs,
  selectedDocs,
  mapping,
  showCheckboxes,
  onRowDoubleClick,
  onRowSelected,
  handleOnContextMenu,
  onMultipleSelection,
  sortColumn,
  sortOrder,
  orderBy,
  onColumnSort,
}: VirtualizedMaterialDataTableProps) => {
  const [dimensions, setDimensions] = useState<{ height: number; width: number } | undefined>(
    undefined
  );
  const [headerTableIsBiggestThanTableColumns, setHeaderIsBiggestThanHeaderColumns] = useState(
    false
  );

  const itemData = createItemData(classes, mapping, docs);
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'showCheckboxes' does not exist on type '... Remove this comment to see the full error message
  itemData.showCheckboxes = showCheckboxes;
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'selectedDocs' does not exist on type '{ ... Remove this comment to see the full error message
  itemData.selectedDocs = selectedDocs;
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'onRowDoubleClick' does not exist on type... Remove this comment to see the full error message
  itemData.onRowDoubleClick = onRowDoubleClick;
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'handleOnContextMenu' does not exist on t... Remove this comment to see the full error message
  itemData.handleOnContextMenu = handleOnContextMenu;

  /**
   * Function is called when a single row is selected
   * @param {Object} doc
   */
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'handleRowSelect' does not exist on type ... Remove this comment to see the full error message
  itemData.handleRowSelect = (doc: any) => {
    const newSelection = [doc];
    // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    onRowSelected(doc);
    // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    onMultipleSelection(newSelection);
  };

  /**
   * This function is called when the checkbox is clicked
   * @param {Object} doc
   */
  // @ts-expect-error ts-migrate(2339) FIXME: Property 'handleCheckboxSelect' does not exist on ... Remove this comment to see the full error message
  itemData.handleCheckboxSelect = (doc: any) => {
    let newSelection = [];
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    if (selectedDocs.find((selectedDoc: any) => selectedDoc.id === doc.id) !== undefined) {
      // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
      newSelection = selectedDocs.filter((selectedDoc: any) => selectedDoc.id !== doc.id);
    } else {
      // @ts-expect-error ts-migrate(2569) FIXME: Type '{ id: string; }[] | undefined' is not an arr... Remove this comment to see the full error message
      newSelection = [...selectedDocs, doc];
    }
    // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    onMultipleSelection(newSelection);
  };

  /**
   * Select each single element on the current client page
   */
  const handleSelectAll = () => {
    // @ts-expect-error ts-migrate(2532) FIXME: Object is possibly 'undefined'.
    const newSelection = selectedDocs.length === 0 ? [...docs] : [];
    // @ts-expect-error ts-migrate(2722) FIXME: Cannot invoke an object which is possibly 'undefin... Remove this comment to see the full error message
    onMultipleSelection(newSelection);
  };

  // TODO: On resize, set dimensions to undefined.

  return (
    <Table className={classes.table} stickyHeader component="div">
      <TableHead
        id="table-header"
        component="div"
        className={classes.thead}
        style={{
          ...(headerTableIsBiggestThanTableColumns
            ? { borderBottom: '1px solid rgba(224, 224, 224, 1)' }
            : {}),
        }}
      >
        <StyledTableColumns
          domId="table-header-columns"
          headerTableIsBiggestThanTableColumns={headerTableIsBiggestThanTableColumns}
          showCheckboxes={showCheckboxes}
          handleSelectAll={handleSelectAll}
          // @ts-expect-error ts-migrate(2345) FIXME:
          selectedDocs={selectedDocs}
          mapping={mapping}
          docs={docs}
          sortColumn={sortColumn}
          sortOrder={sortOrder}
          orderBy={orderBy}
          onColumnSort={onColumnSort}
          width={dimensions !== undefined ? `${dimensions.width}px` : '100%'}
        />
      </TableHead>

      {docs !== undefined && docs.length > 0 && (
        <TableBody
          component="div"
          className={classes.tbody}
          style={{
            height: dimensions !== undefined ? `${dimensions.height}px` : '100%',
          }}
        >
          <AutoSizer>
            {({ height, width }: any) => {
              setTimeout(() => {
                const tHeader = document.getElementById('table-header');
                const tHeaderColumns = document.getElementById('table-header-columns');

                if (tHeader && tHeaderColumns) {
                  let headerWidth: number;
                  if (tHeader.offsetWidth >= tHeaderColumns.offsetWidth) {
                    headerWidth = tHeader.offsetWidth;
                  } else {
                    headerWidth = tHeaderColumns.offsetWidth;
                  }

                  // Only updating the state if width has changed.
                  if (dimensions === undefined || dimensions.width !== headerWidth) {
                    setDimensions({
                      height,
                      width: headerWidth,
                    });
                    if (tHeader.offsetWidth >= tHeaderColumns.offsetWidth) {
                      setHeaderIsBiggestThanHeaderColumns(true);
                    } else {
                      setHeaderIsBiggestThanHeaderColumns(false);
                    }
                  }
                }
              });

              return (
                <List
                  className={classes.list}
                  height={dimensions !== undefined ? dimensions.height : height}
                  width={dimensions !== undefined ? dimensions.width : width}
                  itemCount={docs.length}
                  itemSize={ROW_SIZE}
                  itemKey={itemKey}
                  itemData={itemData}
                  useIsScrolling
                  style={{
                    overflowX: 'hidden',
                  }}
                >
                  {StyledRow}
                </List>
              );
            }}
          </AutoSizer>
        </TableBody>
      )}
    </Table>
  );
};

const defaultFunction = () => {};

VirtualizedMaterialDataTable.defaultProps = {
  docs: undefined,
  maxHeight: undefined,
  showCheckboxes: false,
  selectedDocs: [],
  onRowDoubleClick: defaultFunction,
  onRowSelected: defaultFunction,
  handleOnContextMenu: defaultFunction,
  onMultipleSelection: defaultFunction,

  sortColumn: undefined,
  sortOrder: undefined,
  orderBy: undefined,
  onColumnSort: defaultFunction,
};

// @ts-expect-error ts-migrate(2345) FIXME: Argument of type '{ table: { display: string; flex... Remove this comment to see the full error message
export default withStyles(tableStyles)(VirtualizedMaterialDataTable);
